@import 'styles/functions';
@import 'styles/variables';

.InfoTooltipInfo {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.InfoTooltip {
  [class*='ant-tooltip-inner'] {
    width: fit-content;

    padding: 10px 12px;
    border-radius: 8px;
    background-color: $text-color !important;

    @media (max-width: $desktop) {
      width: fit-content;
    }
  }
}
