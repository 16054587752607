@import 'styles/functions';
@import 'styles/variables';

.formPanel {
  font-family: $font-family;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.18px;
  color: $text-color;
  margin-bottom: 1%;

  [class*='ant-collapse-expand-icon'] {
    cursor: pointer;
    svg {
      right: 0px !important;
    }

    [class~='ant-collapse-arrow'] {
      right: 0px !important;
    }
  }
}
