@import 'styles/functions';
@import 'styles/variables';

.emoji {
  position: relative;
  margin-right: setUnit(12);
  .iconWrapper {
    transition: all 0.15s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .icon {
    cursor: pointer;
    svg {
      vertical-align: middle;
    }
  }
  .emojiWrapper {
    position: absolute;
    bottom: setUnit(100);
    z-index: 99;
  }
}
