@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.tabWrapper {
  [class='ant-tabs-nav'] {
    padding-right: 32px !important;
  }
  [class='ant-tabs-nav']::before {
    right: 33px !important;
  }

  [class~='ant-tabs-nav-list'] {
    width: 100%;
  }

  [class~='ant-tabs-tab'] {
    margin: 0 !important;
    width: calc(100% / 3);
    justify-content: center;
  }

  [class~='ant-tabs-ink-bar'] {
    width: calc(100% / 3) !important;
  }
}

.tabWrapper2 {
  [class='ant-tabs-nav'] {
    padding-right: 32px !important;
  }
  [class='ant-tabs-nav']::before {
    right: 33px !important;
  }

  [class~='ant-tabs-nav-list'] {
    width: 100%;
  }

  [class~='ant-tabs-tab'] {
    margin: 0 !important;
    width: calc(100% / 2);
    justify-content: center;
  }

  [class~='ant-tabs-ink-bar'] {
    width: calc(100% / 2) !important;
  }
}

.tabContentWrapper {
  padding-right: 32px;
}
.appointmentDetailsModal {
  [class='ant-modal-content'] {
    padding: 2rem;
    margin: 4rem 0;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }

  .header {
    .headerTitle {
      display: flex;
      justify-content: space-between;
      gap: 4px;
    }

    cursor: pointer;
  }
}

.heading {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 2rem;
}

.activeIteration {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.iterations {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;

  @include customScrollbar;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }

  .action {
    border-top: 1px solid $light-gray;
    padding: 1.5rem 0 0;
    text-align: center;

    .cancelBtn {
      width: 100%;

      .icon {
        display: inline-block;
        position: relative;
        top: 0.3rem;
        margin-right: 0.5rem;
      }

      span {
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .detailsContainer {
    flex-grow: 1;
  }

  .tabRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tabIcon {
    display: flex;
    margin-bottom: setUnit(8);
    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }
}

@media screen and (max-width: 576px) {
  .appointmentDetailsModal {
    [class='ant-modal-content'] {
      padding: 2rem 0rem 2rem 1rem;
    }

    .heading {
      margin-right: 2rem;
    }

    .content {
      padding-right: 1rem;
    }
  }
}
