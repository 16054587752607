@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.projectStatus {
  height: setUnit(32);
  padding: 0 setUnit(16);
  border-radius: setUnit(100);
  background: $background-ligh-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $white;

  @media (max-width: $tablet) {
    width: setUnit(120);
  }

  .statusText {
    font-size: 14px;
  }

  &.upcoming {
    background: $confetti-blue;
  }
  &.atRisk {
    background: $primary-color;
  }
  &.clearToClose {
    background: $green-color;
  }
  &.onHold {
    background: $primary-color;
  }
  &.listed {
    background: $confetti-blue;
  }
  &.inProgress {
    background: $orange;
  }
  &.cancelled {
    background: $text--light-gray-color;
  }
  &.closed {
    background: $text--light-gray-color;
  }
  &.underContract {
    background: $text--light-gray-color;
  }
  &.expired {
    background: $text--light-gray-color;
  }
}
