@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.new {
  position: relative;
  .btn {
    border-radius: setUnit(100);
    padding: setUnit(10) setUnit(16);
    height: setUnit(44);
    transition: $transition;
    span {
      color: $white;
    }
    .icon {
      margin-right: setUnit(8);
    }
    &:hover {
      opacity: 0.9;
      background-color: $secondary-color;
    }
  }

  .btnTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #515151;
    font-weight: 600;
  }

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(48);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;

    ul {
      list-style: none;
      margin: 0;
    }

    .item {
      padding: setUnit(10) setUnit(22);
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $background-ligh-gray;
      }

      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}

.newFolderModalTitle {
  & [class*='ant-typography'] {
    margin-bottom: 1.5em;
    line-height: 20px;
    letter-spacing: -0.33px;
  }
}
