@import 'styles/functions';
@import 'styles/variables';
@import './../NewFormModal/mixins.module.scss';

.linkFormModal {
  [class='ant-modal-content'] {
    padding-top: setUnit(36);
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }

  .header {
    position: relative;
    .previousIcon {
      position: absolute;
      top: 0;
      line-height: 0;
      cursor: pointer;
    }

    .heading {
      text-align: center;
      font-weight: 700;
      font-size: setUnit(24);
      line-height: setUnit(32);
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: setUnit(36);
      color: $text-color;
      letter-spacing: -0.47px;
    }
  }

  .progressBar {
    display: none !important;
  }

  .header {
    @include modalHeaderWithIcon;
  }

  .formName {
    display: flex;
    align-items: center;
    gap: 12px;
    .fileIcon {
      border-radius: 50%;
      padding: 0.4rem;
      background: #75747424;
      svg {
        width: 23px;
        height: 23px;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .tablePagination {
    background-color: #fff;
    margin-bottom: 0px !important;

    [class~='ant-pagination-item-active'] {
      border-color: $primary-color;

      a {
        color: $primary-color;
      }
    }

    [class~='ant-pagination-item'] {
      &:hover {
        border-color: $primary-color;

        a {
          color: $primary-color;
        }
      }
    }

    :is([class~='ant-pagination-next'], [class~='ant-pagination-prev']):not([class~='ant-pagination-disabled']) {
      &:hover {
        [class~='ant-pagination-item-link'] {
          border-color: $primary-color;

          span {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.confirmFormLinkDialog {
  .content {
    text-align: center;
    font-size: 16px;
  }
}
