@import 'styles/functions';
@import 'styles/variables';

.megaPhoneModal {
  [class='ant-modal-content'] {
    padding: setUnit(32) setUnit(40);
    margin: 4rem 0;
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
  }

  .info {
    color: $dark-color;
  }

  .textArea {
    color: $text-color;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.09px;
    border-radius: 8px;
    border-color: $border-input-middle-gray;
    transition: $transition;
    margin-bottom: 24px;
    padding: 16px;
    &:focus,
    &:hover {
      border-color: $border-focused;
      box-shadow: none;
      outline: 0;
    }
    &::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .additionalInfo {
    margin-top: -0.5rem;
  }

  .divider {
    margin: 1.5rem 0;
    border-bottom: 1px solid $light-gray;
  }

  .subHeading {
    font-weight: 600;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  .checkboxWrap {
    .checkbox {
      display: inline-flex;
      align-items: center;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .lastBox {
    margin-bottom: 0;
  }

  .actionsContainer {
    padding-top: setUnit(32);
    display: flex;
    justify-content: flex-end;

    .action {
      height: auto;
      border-radius: 8px !important;
      font-size: setUnit(16) !important;
      font-weight: 600 !important;
      line-height: setUnit(20) !important;
      padding: setUnit(12) setUnit(32);
    }

    .disabledAction {
      opacity: 0.5;
    }
  }

  [class*='ant-checkbox-wrapper'] {
    &:hover {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color !important;
      }
    }
  }

  [class*='ant-checkbox-checked'] {
    &::after {
      border-color: $primary-color;
    }

    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color !important;
    }
  }
}
