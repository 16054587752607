@import 'styles/functions';
@import 'styles/variables';

.notifications {
  .pageTitle {
    margin: 0;
    font-size: setUnit(24);
    font-weight: 600;
    line-height: setUnit(29);
    letter-spacing: -0.47px;
    color: $text-color;
    padding-bottom: setUnit(33);
    border-bottom: 2px solid $light-gray;
  }
  .notificationTitle {
    font-size: setUnit(20);
    font-weight: 600;
    line-height: setUnit(26);
    letter-spacing: -0.33px;
    margin: 0;
  }
  .accordionBody {
    padding-top: setUnit(32);
    .subTitle {
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      margin: 0 0 setUnit(20) 0;
    }
  }
}
