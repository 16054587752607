@import 'styles/functions';
@import 'styles/variables';

.commutesAndProximity {
  .scrollWrapper {
    height: calc(100vh - #{setUnit(223)});
    padding-top: setUnit(24);
    .commuteType {
      border-bottom: 1px solid $light-gray;
      &:not(:last-child) {
        margin-bottom: setUnit(40);
      }
      .sectionTitle {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        margin-bottom: setUnit(16);
      }
    }
  }
  .footer {
    .submitButton {
      width: setUnit(102);
      margin-left: auto;
      display: block;
    }
    @media (max-width: 991.99px) {
      position: fixed;
      bottom: 0px;
      max-width: setUnit(480);
      width: 100%;
    }
  }
}
