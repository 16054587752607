@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.searchBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 8px;
  width: calc(100% - #{setUnit(48)});
  background-color: $background-grey-2;
  border-radius: setUnit(24);
  padding: setUnit(10);
  .form {
    display: flex;
    width: 100%;
    button {
      border: none;
    }
  }
  .searchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $text-gray-color;
      }
    }
  }
  .input {
    border: 0;
    border-radius: 0 !important;
    background-color: transparent;
    margin-left: setUnit(8);
    width: calc(100% - #{setUnit(28)});
    padding: 0;
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(16);
    letter-spacing: -0.18px;
    color: $text-color;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $text-gray-color;
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(16);
      letter-spacing: -0.18px;
    }
  }
}
