@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  margin-bottom: 32px;
}

.answersWrapper {
  margin: 32px auto 40px !important;
}

.option {
  height: setUnit(64) !important;
  border-radius: setUnit(8) !important;
  box-shadow: none;
}

.next {
  margin: setUnit(40) auto setUnit(0) auto;
  width: 212px !important;
  height: 52px !important;
}

.counter {
  margin: setUnit(68) 0;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  width: setUnit(675) !important;

  .innerHolder {
    padding: 0 40px 40px;
    @media (min-width: $mobile-small) {
      padding: 0 80px 40px;
    }
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}
