@import 'styles/functions';
@import 'styles/variables';

.button {
  font-family: $font-family !important;
  border-radius: 8px;
}

.primary {
  color: $white !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  background-color: $primary-color !important;
  &:hover {
    background-color: $primary-color-hover !important;
  }
}

.secondary {
  color: $white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  background-color: $secondary-color !important;
  &:hover {
    background-color: $secondary-color-hover !important;
    border-color: transparent !important;
  }
}

.secondary-inverted {
  color: $secondary-color !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;

  border: 1px solid $secondary-color !important;

  &:hover {
    border-color: $secondary-color-hover !important;
  }
}

.tertiary {
  color: $black !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;

  background-color: $tertiary-50 !important;

  &:hover {
    background-color: $tertiary-50-hover !important;
  }
}

.link {
  padding: 0px !important;
}

.go-back {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  width: fit-content !important;
  height: fit-content !important;
  border: 2px solid $light-gray !important;
  padding: 14px 16px !important;
  border-radius: 100px !important;
  span {
    font-family: $font-family;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: $dark-color !important;
  }
  span:hover {
    color: $dark-color !important;
  }
}

.blank-hover-bordered {
  border: 2px solid transparent !important;
  box-shadow: none !important;
  span {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: -0.09px !important;
    color: $dark-color;
  }
  &:hover {
    border-color: $text--light-gray-color !important;
    span {
      color: $text-color !important;
    }
  }
}
.hasIcon {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
}

.text-only {
  border: 2px solid transparent !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  color: $dark-color !important;
  &:hover {
    border-color: $text--light-gray-color !important;
    color: $text-color !important;
  }
}

.green-outlined {
  border: 2px solid $hover-green !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  color: $hover-green !important;
  svg {
    path {
      transition: $transition;
    }
  }
  &:hover {
    background-color: $hover-green !important;
    border-color: $hover-green !important;
    color: $white !important;
    svg {
      path {
        stroke: $white !important;
      }
    }
  }
}

.red-outlined {
  border: 2px solid $hover-red !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  color: $hover-red !important;
  svg {
    path {
      transition: $transition;
    }
  }
  &:hover {
    background-color: $hover-red !important;
    border-color: $hover-red !important;
    color: $white !important;
    svg {
      path {
        stroke: $white !important;
      }
    }
  }
}

.hollow-bordered {
  border: 2px solid $background-ligh-gray-4 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  color: $dark-color !important;
  &:hover {
    border-color: $text--light-gray-color !important;
    color: $text-color !important;
  }
  &:focus {
    border-color: $text-gray-color !important;
    background-color: $background-grey-2 !important;
    color: $text-color !important;
  }
}

.bordered-text-only {
  border: 2px solid #d6d6d7 !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  color: $dark-color !important;
  border-radius: 12px !important;
  padding: setUnit(10) setUnit(15) !important;
  width: 100% !important;
  height: 100% !important;
}

.secondary-rounded {
  color: $white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.18px !important;
  background-color: $secondary-color !important;
  border-radius: 12px !important;
  padding: setUnit(10) setUnit(15) !important;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    background-color: $secondary-color-hover !important;
    border-color: transparent !important;
  }
}
