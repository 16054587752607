@import 'styles/functions';
@import 'styles/variables';

.actionBtn {
  position: relative;
  transition: all 0.1s;
  padding-top: 2px;
  float: inline-end;

  .actionBtnIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  &:hover {
    .actionBtnIcon {
      background-color: $light-grey-color;
    }

    .inactiveBtnIcon {
      background-color: $grey-color;
    }

    // .actionList {
    //   opacity: 1;
    //   visibility: visible;
    // }
  }
}

.popoverOverlay {
  width: setUnit(156);
  z-index: 99999 !important;
  div[class~='ant-popover-inner'] {
    border-radius: setUnit(8);
    div[class~='ant-popover-inner-content'] {
      padding: 0px;
      .actionList {
        position: absolute;
        // opacity: 0;
        // visibility: hidden;
        background-color: $white;
        width: setUnit(176);
        border-radius: setUnit(8);
        box-shadow: 0 2px setUnit(12) rgba(0, 0, 0, 0.15);
        right: 0;
        z-index: 99;
        overflow: hidden;

        // &.top {
        //   bottom: setUnit(35);
        // }

        // &.bottom {
        //   top: setUnit(45);
        // }

        .actionListBtn {
          width: 100%;
          display: flex;
          align-items: center;
          outline: none;
          border: 0;
          color: $text-color;
          font-size: setUnit(15);
          font-weight: 400;
          line-height: setUnit(16);
          padding: setUnit(15) setUnit(16);
          transition: all 0.1s;
          background-color: $white;
          text-align: left;
          cursor: pointer;

          .icon {
            padding-right: setUnit(8);
            height: setUnit(16);

            svg {
              width: setUnit(16);
              height: setUnit(16);
            }
          }

          &:hover {
            background-color: $hover-light-gray;
          }
        }
      }
    }
  }
}
