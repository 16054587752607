@import 'styles/functions';
@import 'styles/variables';

.formItemMenu {
  border-radius: 8px !important;
  padding: 0px !important;
  width: 160px !important;
}

.formItemMenu .action {
  padding: setUnit(14) setUnit(16) !important;

  gap: 8px;

  &:first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  &:last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  [class~='ant-dropdown-menu-item-icon'] {
    line-height: 0;
  }

  [class~='ant-dropdown-menu-title-content'] {
    color: $text-color;
    font-size: setUnit(14);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;
  }
}

.menuIcon {
  cursor: pointer;
}
