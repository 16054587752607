@import 'styles/variables';
@import 'styles/functions';

.backBtn {
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 12%;
  left: 40px;
  cursor: pointer;

  @media screen and (max-width: $mobile) {
    left: 25px;
  }

  &:hover {
    border: 1.5px solid rgba(255, 255, 255, 0.6);
  }
}

.heading {
  color: rgba(255, 255, 255, 0.75);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;

  margin-bottom: 12px;
}
.wrapper {
  .valuesWrapperClassName {
    background: initial !important;

    border-radius: 8px;
    border: 1px solid rgba(78, 83, 107, 0.8) !important;

    [class*='_value_'] {
      display: inline-flex;
      padding: 8px 10px 8px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 100px;
      background: var(--Grayscale-20, #e8e9ea);
      border: none;
      color: var(--REBRAND-Grayscale-100, #262626);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.18px;

      [class*='_icon_'] {
        position: initial;
        transform: initial;
      }
    }

    .valuesContainer {
      border-bottom: none !important;
      padding: 14px 16px !important;

      [class*='_value_'] {
        background: #324f8d;

        color: var(--White, #fff);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.18px;
      }

      [class*='_icon_'] {
        position: initial;
        transform: initial;
        filter: brightness(0) saturate(100%) invert(95%) sepia(99%) saturate(0%) hue-rotate(190deg)
          brightness(104%) contrast(100%);
      }
    }
  }

  .activeInputClassName input {
    background: #2a2f3d !important;
    color: #fff !important;

    border-radius: 8px;
    border: 1px solid rgba(78, 83, 107, 0.8) !important;
    border-bottom: none !important;

    padding: 14px 16px !important;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
  }

  .placeholderClassName {
    border-bottom: 1px solid rgba(78, 83, 107, 0.8) !important;
    border-radius: 8px;
    border: 1px solid rgba(78, 83, 107, 0.8);

    padding: 14px 16px !important;

    color: var(--REBRAND-Grayscale-40, #aaabab);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
  }

  .loader {
    filter: invert(1);
    top: 50% !important;
  }

  .dropdownClassName {
    width: 100%;
    [class*='suggestions'] {
      [class*='searchResults'] {
        display: none;
      }
      [class*='suggestionItem'] {
        color: var(--REBRAND-Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;

        padding: 16px 28px;

        [class*='icon'] {
          display: none;
        }
      }
    }

    [class*='_value_'] {
      display: inline-flex;
      padding: 8px 10px 8px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 100px;
      background: var(--Grayscale-20, #e8e9ea);
      border: none;
      color: var(--REBRAND-Grayscale-100, #262626);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.18px;

      [class*='_icon_'] {
        position: initial;
        transform: initial;
      }
    }
  }
}
