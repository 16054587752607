@import 'styles/functions';
@import 'styles/variables';

.closeTransactionEnd {
  height: calc(100vh - #{setUnit(157)});
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: setUnit(150);
  @media (max-height: 860px) {
    margin-top: 0;
  }

  @media (max-height: $mobile) {
  }

  .answers {
    margin-bottom: 0;
    height: 30px;
  }

  .closingHeader {
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.89px;
    color: $secondary-color;
  }

  .closingSubHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.09px;
    color: $text-color;
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .submitBtn {
    height: setUnit(52);
    max-width: setUnit(160);
    width: 100%;
    border-radius: setUnit(8);
  }
}
