@import 'styles/functions';
@import 'styles/variables';

.filter {
  width: setUnit(44);
  height: setUnit(44);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $light-gray;

  &:hover {
    border-color: $text--light-gray-color;
  }

  svg {
    vertical-align: middle;
  }
}

.active {
  background-color: $primary-color-light;
  border-color: $primary-color;

  &:hover {
    border-color: $hover-red;
  }
}
