@import 'styles/functions';
@import 'styles/variables';

.itemsWrapper {
  border-top: 1px solid $light-gray;
  padding: setUnit(16) 0;

  .inner {
    display: flex;
    align-items: center;
  }

  .title {
    margin-bottom: setUnit(16);
  }
}
