@import 'styles/functions';
@import 'styles/variables';

.title {
  font-size: setUnit(30);
  font-weight: 700;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  .arrowBackWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .backArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
    z-index: 5;
  }
  .arrowHeadingWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: -32px;

    p {
      margin: 0;
    }
  }
}

.isPreApprovedForm {
  .hiddenInputField {
    display: none;
  }
  .addAnotherWrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    p {
      margin: 0;
      margin-left: 6px;
    }
    .iconButton {
      background-color: $background-grey-2 !important;
      width: 32px;
      height: 32px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0 !important;
      &:hover {
        background-color: $background-grey-2 !important;
        border: 0 !important;
      }
      &:focus {
        border: 0 !important;
      }
    }
  }
  .attachments {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .formContainer {
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;
    transition: padding 0.3s;
    .manualEntryWrapper {
      margin-top: 10px;
      padding: 24px 26px;
      border: 1px solid $light-gray;
      border-radius: 8px;
    }

    &.expand {
      margin: 40px 0 10px;
      visibility: visible;
      opacity: 1;
      display: block;
    }

    $options-gap: setUnit(15);

    .selectType {
      margin: setUnit(16) 0 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: $options-gap;
      width: 100%;
    }

    .selectOption {
      height: setUnit(56);
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: setUnit(16) setUnit(16) setUnit(16) setUnit(16);
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: $mobile) {
        min-width: calc((100% / 4) - $options-gap);
      }

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
        @media (min-width: $mobile) {
          margin-bottom: 0;
        }
      }

      label span:last-child {
        display: none;
      }

      .labelText {
        margin-left: 0;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      span {
        font-weight: 500 !important;
      }
    }

    .subtitle {
      color: $text-color;
      font-size: 16px;
      font-weight: 600;
      margin-top: setUnit(40);
      margin-bottom: setUnit(16);
    }

    .toggleWrapper {
      margin-top: setUnit(24);
    }

    .toggle {
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: setUnit(24);
    }

    .toggleText {
      margin-left: setUnit(12);
      color: $text-color;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      user-select: none;
    }

    .inputWrapper {
      margin-top: setUnit(8);
      width: 100%;
    }
  }
}
