@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  padding-bottom: 4rem;

  .noDetails {
    height: calc(100vh - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: $text--light-gray-color;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    top: setUnit(80);
    margin-top: 0;
    position: relative;
  }
  .column {
    .title {
      margin-bottom: setUnit(24);
    }
  }
}

.selected {
  background-color: $background-blue !important;
}
.offersTab {
  @media screen and (max-width: 1380px) {
    display: block;
  }

  .column {
    margin-right: 0;
  }
}

.block {
  display: block;
}

.offers {
  padding: 2rem;

  @media screen and (max-width: $mobile) {
    padding: 0;
  }

  .pageTitle {
    font-weight: 700;
    font-size: 2rem;
    line-height: 36px;
    color: $text-color;
    display: inline-block;
    margin-bottom: 3rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }

    .headerSection {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      @media screen and (max-width: $mobile) {
        margin-bottom: setUnit(20);
      }

      .searchWrapper {
        .inputWrap {
          background: $white;
          border: 2px solid $light-gray;
          border-radius: setUnit(26);
          width: setUnit(44);
          height: setUnit(44);
          font-size: setUnit(14);
          font-weight: 500;
          color: $text--light-gray-color;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            border: 2px solid $text--light-gray-color;
          }
          @media screen and (max-width: $mobile) {
            width: setUnit(300) !important;
          }
          @media (max-width: 991px) {
            height: setUnit(36);
          }
          &:focus {
            border-width: 2px;
          }
          .icon {
            display: flex;
            cursor: pointer;
            position: relative;
            top: 0;
            left: 0;
            transform: unset;
            svg {
              width: setUnit(20);
              height: setUnit(20);
              path {
                stroke: $dark-color;
              }
            }
          }
          .searchInput {
            background-color: transparent;
            border: 0;
            padding-left: setUnit(8);
            display: none;
            width: 0;
          }
        }
      }

      .btn {
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border: 2px solid $light-gray;
        cursor: pointer;
        position: relative;
        font-weight: 600;
        color: $dark-color;

        width: setUnit(36);
        height: setUnit(36);
        @media (min-width: $tablet) {
          height: setUnit(44);
          width: setUnit(44);
        }

        svg {
          width: setUnit(20);
          height: setUnit(20);
        }

        &:hover {
          border: 2px solid $text--light-gray-color;
        }
      }

      .selected {
        background-color: $background-blue;
      }
      .multselectSelected {
        border-color: $primary-color;
        background-color: $primary-color-light;
        &:hover {
          border: 2px solid $primary-color;
        }
      }
    }
  }

  .actions {
    float: right;
    top: 0;
    right: 0;
    position: relative;

    .actionBtn {
      width: 2.5rem;
      height: 2.5rem;
      background: $background-grey-2;
      border-radius: 100px;
      padding: 0.5rem 0.6rem;
      margin-right: 0.75rem;
      display: inline-block;
      cursor: pointer;

      &:hover {
        background-color: $grey-color;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        font-weight: 500;
      }
    }

    .copyBtn {
      border: none !important;
    }

    .enableBtn,
    .copyBtn {
      width: auto;
      padding: 0.5rem 1rem;
    }
  }

  .offersCollapsibleTable {
    tbody {
      td[class~='ant-table-cell'] {
        padding: setUnit(18) setUnit(24) !important;
      }
    }
  }
}

.offersWithFooter {
  padding-bottom: setUnit(90);
}
