@import 'styles/functions';
@import 'styles/variables';

.price {
  .value {
    font-size: setUnit(20);
    font-weight: 700;
    display: flex;
    align-items: center;

    .auction {
      font-size: setUnit(14);
      font-weight: 400;
      margin-left: setUnit(5);
    }
  }
}
