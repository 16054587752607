@import 'styles/functions';
@import 'styles/variables';

.MBHInner {
  margin-bottom: setUnit(24);
  text-align: center;
  h4 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.47px;
    color: $text-color;
    margin-bottom: setUnit(6);
  }
}
