@import 'styles/variables';

.calendarLinks {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.18px;
  margin-bottom: 0;
  .link {
    color: $link-blue;
    &:not(:last-child):after {
      content: '-';
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}
