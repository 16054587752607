@import 'styles/functions';
@import 'styles/variables';

.templateCard {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: setUnit(-32);
    height: 100%;
    width: calc(100% + #{setUnit(64)});
    background-color: $background-grey-2;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }
    .tcWrap {
      .tcRight {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .tcWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: setUnit(20) 0;
    border-bottom: 1px solid $light-gray;
    .tcLeft {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: setUnit(15);
      flex: 0 0 calc(100% - #{setUnit(190)});
      .tclIcon {
        background-color: $light-gray;
        flex-basis: setUnit(32);
        padding: setUnit(6);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: setUnit(100);
        margin-right: setUnit(12);
      }
      .tclContent {
        flex: 0 0 calc(100% - #{setUnit(44)});
        .title {
          font-family: Inter;
          font-size: setUnit(16);
          font-weight: 500;
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          color: $dark-gray-3;
          margin-bottom: setUnit(4);
        }
        .description {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          letter-spacing: -0.09px;
          color: $dark-grey-color;
          margin-bottom: 0;
        }
      }
    }
    .tcRight {
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      .actionBtn {
        padding: setUnit(11) setUnit(16);
        border-radius: setUnit(100);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: setUnit(14);
        line-height: setUnit(16);
        text-align: center;
        letter-spacing: -0.09px;
        cursor: pointer;
        border: 1px solid;
        transition: $transition;
        &.preview {
          border-color: $light-gray;
          background-color: #fff;
          color: $text-color;
          &:hover {
            background-color: $light-gray;
          }
        }
        &.select {
          border-color: $secondary-color;
          background-color: $secondary-color;
          color: #fff;
          &:hover {
            background-color: $hover-blue;
          }
        }
        &:not(:last-child) {
          margin-right: setUnit(12);
        }
      }
    }
  }
}
