@import 'styles/functions';
@import 'styles/variables';

.container {
  .answerContainer {
    .financingType {
      margin: 0;
    }
    .seperator {
      width: 100%;
      height: 1px;
      background-color: $light-gray;
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      font-weight: 600;
    }
    .marginTop {
      margin-top: 20px;
    }

    .checkboxContainer {
      display: flex;
      align-items: center;
      gap: 30%;
      .checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 15px;
        .checkboxLabel {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }

  .errorValidationText {
    margin-top: 1rem;
    color: $error-color;
  }
}

.otherFeesContainer,
.commissionFieldContainer {
  .form {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
    .amountInput {
      padding-right: 82px !important;
    }
    .icon {
      cursor: pointer;
    }

    .feeAutocomplete {
      width: 100%;
      margin-bottom: 0 !important;
    }
  }

  .valueTypeContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 24px;
    border: 1px solid $light-gray;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: $white;
      color: $text--light-gray-color;
    }
    .active {
      font-weight: 700;
      color: $text-color;
      background-color: $background-grey-2;
      pointer-events: none;
    }
    .border {
      border-right: 1px solid $light-gray;
    }
  }

  .addContainer {
    .plus {
      margin-top: 3px;
    }
    cursor: pointer;
    display: flex;
    margin-top: 20px;
    gap: 5px;
    align-items: center;
    .addText {
      color: $dark-color;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }
}

.arrowdownicon {
  right: 0.75rem !important;
  path {
    stroke: $text-gray-color !important;
  }
}
