@import 'styles/functions';
@import 'styles/variables';

.widgetUnreadMessage {
  padding: 0 0 0 setUnit(30);
  &:not(:last-child) {
    margin-bottom: setUnit(24);
  }

  .itemContent {
    .itemContentTitle {
      .itemContentTitleTime {
        font-weight: 400;
        font-size: setUnit(12);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
        color: $dark-color;
      }
    }
  }
}
