@import 'styles/mixins.scss';
@import 'styles/variables';

@mixin modalHeading {
  text-align: center;
  font-weight: 700;
  font-size: setUnit(24);
  line-height: setUnit(32);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: setUnit(36);
  color: $text-color;
  letter-spacing: -0.47px;

  @media screen and (max-width: $mobile) {
    font-size: setUnit(20);
  }
}

@mixin modalHeaderWithIcon {
  position: relative;
  .previousIcon {
    position: absolute;
    top: 0;
    line-height: 0;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
      top: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .heading {
    @include modalHeading;
  }
}
