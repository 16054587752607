@import 'styles/functions';
@import 'styles/variables';

.iconText {
  display: flex;
  align-items: center;

  .icon {
    line-height: 0;
  }

  .detail {
    margin-left: setUnit(16);

    .text {
      margin-bottom: 0;
      font-size: setUnit(16);
      color: $text-color;

      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.18px;
    }

    .info {
      font-weight: 400;
      color: $text-gray-color;
      margin-bottom: 0;
      font-size: setUnit(14);
      margin-top: 2px;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
  }
}
