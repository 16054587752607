@import 'styles/functions';
@import 'styles/variables';

.needsContainer {
  .title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .preferencePillsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    scrollbar-width: none;

    .preferencePill {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: $background-ligh-gray-4;
      border-radius: 100px;
      width: fit-content;
      padding: setUnit(5) setUnit(10);
      font-size: 12.5px;
      color: $text-color;
      margin: setUnit(6) 0;
      margin-right: setUnit(6);

      svg {
        cursor: pointer;
        &:hover {
          path {
            stroke: $text-gray-color;
          }
        }
      }
    }
  }

  .addNeedWantContainer {
    position: relative;
    button {
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 14px;
      font-weight: 600;
      color: $confetti-blue;
    }
  }

  &:hover {
    .edit {
      display: flex;
    }
  }

  .edit {
    padding-top: 4px;
    z-index: 1;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 2px;
    top: 0px;
    border-radius: 50%;
    border: 2px solid $background-ligh-gray-4;
    background: #fff;
    &.active {
      display: flex;
    }
  }
}

.optionsPopoverContent {
  z-index: 9999 !important;
  [class*='ant-popover-inner'] {
    border-radius: setUnit(12);
    box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.12);
  }
  .selectionsContainer {
    height: 200px;
    width: 200px;
    background-color: $white;
    border-radius: setUnit(12);
    min-height: 350px;
    width: 280px;
    overflow-y: auto;
    scrollbar-width: none;

    .optionsContainer {
      &:first-child {
        .category {
          border-top-right-radius: setUnit(12);
          border-top-left-radius: setUnit(12);
        }
      }
      .category {
        background-color: $background-grey-2;
        font-size: 14px;
        color: $text-gray-color;
        padding: setUnit(5) setUnit(10);
        margin: 0;
      }
      .criteria {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: setUnit(10) setUnit(15);
        cursor: pointer;
        background-color: $white;
        p {
          margin: 0;
        }

        &:hover {
          background-color: $background-gray;
        }
      }
    }
  }
}
