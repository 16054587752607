@import 'styles/functions';
@import 'styles/variables';

.count {
  font-weight: 600;
  color: $dark-color;
}

.clientDetails {
  margin: setUnit(16) 0;
  .clientDetailsActions {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    float: inline-end;
    margin-top: -4.5rem;
    margin-right: 2.5rem;
    @media (min-width: $mobile-small) {
      flex-flow: row wrap;
    }
    .clientInfo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: setUnit(15);
      @media (min-width: $mobile-small) {
        margin-bottom: 0;
      }
      .backIcon {
        display: inline-block;
        position: relative;
        padding: 0 2rem 0 0;
        cursor: pointer;
      }

      .clientAvatar {
        display: inline-block;
        margin-right: 1rem;
      }

      .details {
        display: inline-block;

        .clientName {
          margin: 0;
          font-weight: bold;
        }

        .activeInstances {
          margin: 0;
          font-weight: 500;
        }
      }
    }

    .clientActions {
      display: flex;
      .actionBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 52px;
        height: 32px;
        border: 2px solid $light-gray;
        border-radius: 100px;
        margin-right: 0.5rem;
        margin-top: 7px;
        padding-right: 8px;
        padding-left: 5px;

        &:last-child() {
          margin-right: 0;
        }

        &:hover {
          border-color: $text--light-gray-color;
        }

        svg {
          vertical-align: middle;
        }
      }

      .heartBtn {
        width: auto;

        span {
        }
      }
    }
  }
}
