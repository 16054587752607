@import 'styles/functions';
@import 'styles/variables';

.offerModal {
  [class='ant-modal-content'] {
    padding: setUnit(32) setUnit(16) setUnit(32) setUnit(40);
    margin: 4rem 0;
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(32);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
  }

  .content {
    padding-bottom: setUnit(27);
    margin-right: setUnit(25);
    display: flex;
    .condoIcon,
    .details {
      display: inline-block;
      vertical-align: middle;
    }

    .details {
      margin-left: setUnit(12);
      position: relative;
      .address {
        margin: 0;
        font-size: setUnit(18);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        font-weight: 600;
        color: $text-color;
      }

      .subDetails {
        font-size: setUnit(15);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        font-weight: 400;
        color: $text-color;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        > span {
          &:not(:first-child) {
            margin-left: setUnit(8);
            margin-top: 0;
            flex: 8;
            &:before {
              content: '';
              width: setUnit(4);
              height: setUnit(4);
              border-radius: 50%;
              background: $text--light-gray-color;
              margin-right: setUnit(8);
              display: inline-block;
              margin-top: 8px;
            }
          }
          strong {
            font-weight: 600;
          }
        }
      }
    }
  }

  .progressBar {
    display: none !important;
  }

  .clientNames {
    font-size: setUnit(15);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    font-weight: 400;
    span:first-child {
      font-weight: 600;
    }
    span:not(:first-child) {
      font-weight: 400;
    }
  }

  [class*='ant-checkbox-wrapper'] {
    font-size: setUnit(16);
    font-weight: 400;
    line-height: setUnit(20);
    letter-spacing: -0.18px;
    align-items: center;
    [class*='ant-checkbox'] {
      & + span {
        padding-left: setUnit(12);
        padding-right: 0;
      }
      [class*='ant-checkbox-inner'] {
        padding-left: 0;
      }
    }
    &:hover {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color !important;
      }
    }
  }

  [class*='ant-checkbox-disabled'] {
    [class*='ant-checkbox-inner'] {
      border-color: $primary-color !important;
    }
  }

  [class*='ant-checkbox'] {
    [class*='ant-checkbox-inner'] {
      width: setUnit(24);
      height: setUnit(24);
      border-radius: setUnit(100);
      &:after {
        width: setUnit(6);
        height: setUnit(12);
        left: 25%;
      }
    }
  }

  [class*='ant-checkbox-checked'] {
    &::after {
      border-color: $primary-color;
      border-radius: 100%;
    }

    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color !important;
    }
  }

  [class*='ant-switch-checked'] {
    background-color: $primary-color;
  }
}
