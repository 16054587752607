@import 'styles/functions';
@import 'styles/variables';

.pageWrapper {
  display: flex;
  height: calc(100vh - $header-height);

  .pendingWrapper {
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
