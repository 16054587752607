@import 'styles/functions';
@import 'styles/variables';

.paywallBackground {
  background: url('../../images/paywall-background.jpg') no-repeat center/cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100;

  :global {
    .ant-modal-wrap {
      position: absolute !important;
      z-index: 0;
    }
    .ant-modal-content {
      padding: 70px 40px;
    }
  }
}

.modalLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .stepHeader {
    font-family: $font-family;
    font-weight: 700;
    font-size: setUnit(32);

    &_biggerMargin {
      margin-bottom: setUnit(32);
    }
  }

  .text {
    font-family: $font-family;
    color: $dark-color;
  }

  .highlightedEmail {
    font-weight: 500;
  }

  .continueButton {
    padding: setUnit(14) setUnit(16);
    font-size: 16px;
    margin-top: setUnit(20);
    height: unset;
    width: setUnit(242);
    border-radius: setUnit(8);
  }
}

.modalLayoutCheckout {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stepHeader {
    font-family: $font-family;
    font-weight: 700;
    font-size: setUnit(32);

    &_biggerMargin {
      margin-bottom: setUnit(32);
    }
  }
  .stepSubheading {
    color: var(--Grayscale-80, #515151);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .text {
    font-family: $font-family;
    color: $dark-color;
  }

  .highlightedEmail {
    font-weight: 500;
  }

  .continueButton {
    padding: setUnit(14) setUnit(16);
    font-size: 16px;
    margin-top: setUnit(20);
    height: unset;
    width: setUnit(242);
    border-radius: setUnit(8);
  }
}

.checkboxRounded {
  :global {
    .ant-radio-input {
      border-radius: 50%;
    }

    .ant-radio-inner {
      border-radius: 50% !important;
    }

    .ant-radio:after {
      border-radius: 50% !important;
    }
  }
}

.radioGroup {
  display: flex !important;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: setUnit(16);

  margin-top: setUnit(32) !important;
  margin-bottom: setUnit(32) !important;

  :global {
    .ant-radio-wrapper {
      margin: 0;
    }
  }
}

.radioLabel {
  width: setUnit(246);
  padding: setUnit(22) !important;
  border: 2px solid $light-gray;
  border-radius: setUnit(16);

  &_selected {
    border-color: $primary-color;
    background-color: $primary-color-light;
    transition: border-color 0.3s, background-color 0.3s;
  }

  &_selected {
    border-color: $primary-color;
    background-color: $primary-color-light;
  }
}

.radioLabel2 {
  width: setUnit(246);
  padding: setUnit(20) !important;
  border: 2px solid $light-gray;
  border-radius: setUnit(16);

  &_selected {
    border-color: $primary-color;
    background-color: $primary-color-light;
    transition: border-color 0.3s, background-color 0.3s;
  }

  &_selected {
    border-color: $primary-color;
    background-color: $primary-color-light;
  }
}

.standardIcon {
  width: 40px;
  height: 40px;
}

.radioTextMain {
  color: var(--Grayscale-100, #262626);
  font-size: 18px;
  font-weight: 600;
}

.radioTextSubtext {
  margin-left: setUnit(8);
  font-weight: 400;
  font-size: setUnit(14);
  color: $dark-grey-color;
}

.paymentStatusIcon {
  margin-bottom: setUnit(24);
}

.seatsCounter {
  margin-top: setUnit(24);
  margin-bottom: setUnit(32);
}

.seatsCounterAdd {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    fill: $dark-color;
  }
}

.seatsCounterRemove {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    stroke: $dark-color;
  }
}

.errorSection {
  background: #fff2f3;
  border-radius: 8px;
  padding: 16px;
  width: 100%;

  text-align: center;
  color: $error-color;

  &__boldText {
    font-weight: 600;
  }
}

.radioTextFlexColumn {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  input {
    border: 0px;
    border-bottom: 2px solid $border-light-gray-3;
    border-radius: 0;
    padding-left: 0;
  }

  label {
    margin: 0 setUnit(45) 0 0;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
    }
  }
}

.activationCouponWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  margin-bottom: 20px;

  .couponTextMain {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $text-color;
  }
}

.infoDisclaimer {
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008fbe;
  color: white;
  padding: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 14px;
  margin-bottom: 10px;
}
