@import 'styles/variables';

.footerContainer {
  border-top: 1px solid $light-gray;
  height: $forms-footer-height;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  background-color: $white;
  z-index: 1;

  .button {
    width: 400px;
    height: 52px;
    border-radius: 8px;
  }

  .disabledButton {
    opacity: 0.6;
  }
}

.declineDynamicFooterContainer {
  gap: 40px;

  .button {
    width: 180px;
  }
}
