@import 'styles/functions';
@import 'styles/variables';

.commentsDrawer {
  .drawerInner {
    height: 100%;
    position: relative;
  }

  .threadCreationSpinner {
    height: 100%;
  }

  .photosWrapper {
    padding-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-light-gray;
  }

  .subheaderWrapper {
    padding-top: 0;
    border-bottom: 1px solid $border-light-gray;

    .participants {
      margin-top: setUnit(32);
    }

    .prefixParticipants {
      top: setUnit(15);
      color: #747475;
    }

    .valuesClassName {
      [testid='flat_value'] {
        background: #fff2f3;
        border: 1px solid $primary-color;
        margin-right: 5px;
      }
    }
  }

  .paddingWrapper {
    margin-bottom: 0;
  }
}

.threadHeadActions {
  padding-bottom: 0;
  height: auto;
}
