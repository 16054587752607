@import 'styles/functions';
@import 'styles/variables';

.container {
  padding: setUnit(12) setUnit(8);
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  cursor: pointer;
  transition: all 0.1s;
  border: 0;

  &:first-child {
    border-radius: setUnit(8) setUnit(8) 0 0;
  }

  &:last-child {
    border-radius: 0 0 setUnit(8) setUnit(8);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $light-gray;
  }

  &:hover {
    background-color: $hover-light-gray;
  }
}

.avatarWrapper {
  margin-right: setUnit(16);
}

.avatar {
  height: setUnit(48) !important;
  width: setUnit(48) !important;

  img {
    height: setUnit(48) !important;
    width: setUnit(48) !important;
  }
}

.descTitle {
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(21);
  padding-bottom: setUnit(8);
  color: $text-color;
}

.descInfo {
  font-size: setUnit(14);
  line-height: setUnit(16);
  color: $dark-grey-color;
}

.arrow {
  margin-left: auto;
}
