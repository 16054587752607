@import 'styles/variables';

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.18px;
  text-align: center;
  color: $dark-grey-color;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .email {
    font-weight: 500;
    color: $text-color;
  }
  .link {
    font-weight: 500;
    color: $link-blue;
  }
}
