.switchActionsGo {
  margin-top: 1rem;
  .garageSwitchGo {
    display: flex;
    justify-content: flex-end;
  }
  .switchItemGo {
    margin-left: 0.5rem;
  }
}
