@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../../styles.module';

.onBoardingSelect {
  [class~='ant-select-selector'] {
    border: 0 !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
    [class~='ant-select-selection-overflow'] {
      padding: setUnit(12) 0 !important;
      [class~='ant-select-selection-overflow-item'] {
        padding: 0 !important;
        [class~='ant-select-selection-item'] {
          background-color: transparent !important;
          border: 0 !important;
          [class~='ant-select-selection-item-content'] {
            font-weight: 400 !important;
            font-size: setUnit(18) !important;
            line-height: setUnit(24) !important;
            letter-spacing: -0.09px !important;
            color: $text-color !important;
          }
        }
      }
    }
    & [class~='ant-select-selection-placeholder'] {
      font-weight: 400 !important;
      font-size: setUnit(18) !important;
      line-height: setUnit(24) !important;
      letter-spacing: -0.09px !important;
    }
  }
  &[class~='ant-select-focused'] {
    & [class~='ant-select-selector'] {
      box-shadow: none !important;
      border-bottom-color: $text--light-gray-color !important;
    }
  }
  .onBoardingDropdownPopUp {
    & [class~='ant-select-item'] {
      font-weight: 400 !important;
      font-size: setUnit(18) !important;
      line-height: setUnit(24) !important;
      letter-spacing: -0.09px !important;
      color: $text-color !important;
      padding: setUnit(12) !important;
      & [class~='ant-select-item-option-content'] {
        padding: 0 !important;
      }
      & [class~='ant-select-item-option-state'] {
        padding: 0 !important;
      }
    }
    & [class~='ant-select-item-option-selected'] {
      background-color: transparentize($hover-red, 0.6) !important;
      & [class~='ant-select-item-option-state'] {
        svg {
          fill: $hover-red !important;
        }
      }
    }
  }
}
