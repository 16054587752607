@import 'styles/functions';
@import 'styles/variables';

@mixin quoteHeader() {
  display: flex;
  align-items: center;
  width: 100%;
  padding: setUnit(20) setUnit(15);
  @media (min-width: $tablet) {
    height: $transaction-creation-header-height;
    padding: setUnit(20) setUnit(40);
  }
}

.head {
  @include quoteHeader;
  flex-wrap: wrap;
  .headerLeft {
    .goBackButton {
      height: setUnit(44) !important;
      border: 2px solid $background-ligh-gray-4 !important;
      padding: 0 setUnit(8) !important;
      &:hover {
        background-color: $background-ligh-gray !important;
      }
    }
  }

  .quotesTitle {
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: setUnit(16);
    margin-left: setUnit(-80);
    @media (min-width: $tablet) {
      margin-bottom: 0;
    }

    @media (max-width: $mobile-small) {
      margin-bottom: 0;
    }
  }

  .quotesTitlePartner {
    margin-left: unset !important;
  }

  .quotesActions {
    order: 3;
    flex: 1;
  }

  .meta {
    display: flex;
    align-items: center;
  }

  @media (max-width: $tablet) {
    .headerLeft {
      width: auto;
    }
  }
}

.pageHeader {
  .subtitle {
    margin-top: setUnit(4);
    min-height: setUnit(18);
  }

  .categoryDetailsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      text-align: center;

      &.groupByClient {
        text-align: left;
        margin-left: setUnit(16);
      }
    }

    .image {
      border-radius: 50%;
    }

    .avatar {
      width: setUnit(80);
      height: setUnit(80);
    }

    .avatarPlaceholder {
      font-size: setUnit(28);
    }
  }
}
