@import 'styles/variables';

.yesNoInput {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  [class*='ant-radio-button-wrapper'] {
    transition: none !important;
  }

  [class*='ant-radio-button-wrapper-checked'] {
    color: $text-color !important;
    border: 1px solid $primary-color !important;
    background: $primary-color-light !important;

    &::before {
      background: none !important;
    }

    &:focus-within {
      box-shadow: none !important;
    }
  }
}

.noRadioBtn {
  width: 100%;
  border-radius: 8px !important;
  line-height: 56px !important;
  height: 56px !important;

  border: 2px solid $light-gray !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: $dark-color !important;
}

.yesRadioBtn {
  @extend .noRadioBtn;

  margin-bottom: 16px !important;
}
