@import 'styles/functions';
@import 'styles/variables';

.feedbackMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  @media screen and (max-width: $mobile-small) {
    width: 100% !important;
    padding: 0 10px !important;
  }

  .mainHeading {
    width: setUnit(800);
    height: setUnit(36);
    top: setUnit(398);
    left: setUnit(320);

    @media screen and (max-width: $mobile-small) {
      width: 100% !important;
      margin-bottom: 25px !important;
    }

    h2 {
      font-weight: 600;
      font-size: setUnit(32);
      line-height: setUnit(36);
      letter-spacing: setUnit(-0.8);
      text-align: center;
    }
  }
  .feedbackDescription {
    width: setUnit(696);
    height: setUnit(42);
    top: setUnit(450);
    left: setUnit(372);

    @media screen and (max-width: $mobile-small) {
      width: 100% !important;
      margin-top: 10px !important;
    }

    p {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(20.8);
      letter-spacing: setUnit(-0.18);
      text-align: center;
      color: #515151;
    }
  }
  .footerArea {
    @media screen and (max-width: $mobile-small) {
      width: 100% !important;
      margin-top: 20px !important;
    }
    .submitBtn {
      height: setUnit(52) !important;
      width: setUnit(401) !important;
      top: setUnit(540);
      left: setUnit(520);

      transition: all 0.1s;
      margin-top: setUnit(40);
      background-color: #252d44;
      color: #fff;
      border: none;
      padding: 0.3125rem 0.9375rem;
      border-radius: 0.5rem;
      cursor: pointer;
      outline: none;

      &:hover {
        color: #fff;
        background-color: #1d4f76;
      }

      @media screen and (max-width: $mobile-small) {
        width: 100% !important;
      }
    }
    button {
      width: setUnit(101);
      height: setUnit(24);
      top: setUnit(14);
      left: setUnit(151);
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(24);
      text-align: center;
    }
  }
}
