@import 'styles/functions';
@import 'styles/variables';

.formDocumentHeader {
  display: flex;
  justify-content: space-between;

  margin-right: 95px;
  padding-left: 40px;

  @media screen and (max-width: $mobile-small) {
    flex-wrap: wrap;
    padding-left: 24px;
  }

  @media screen and (max-width: $mobile) {
    padding-left: 20px;
  }

  .formName {
    font-family: $font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 2px;
    width: 95%;

    @media only screen and (max-width: $mobile) {
      font-size: 18px;
    }
  }

  .formSent {
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $dark-color;
  }

  .formHeaderStatusPanel {
    display: flex;
    gap: 12px;

    .megaPhoneIcon {
      cursor: pointer;
    }
  }

  .disabledSection {
    opacity: 0.5;
  }
}
