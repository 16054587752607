@import 'styles/functions';
@import 'styles/variables';

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: setUnit(13) setUnit(32);
  background-color: transparent;
  transition: $transition;
  &:hover {
    background: $background-grey-2;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }
  .checkboxLabel {
    margin-bottom: 0;
    .checkboxWrapper {
      .checkbox {
        border-color: $text-color;
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    .nameHolder {
      .name {
        font-weight: 400;
        width: fit-content;
        margin-bottom: 0;
      }
    }
  }
}
