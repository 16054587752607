@import 'styles/variables';
@import 'styles/functions';

.formSearchFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  .searchWrapper {
    flex-grow: 1;
    .inputWrap {
      background: $white;
      border: 2px solid $light-gray;
      border-radius: setUnit(100) !important;
      width: 100% !important;
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;

      box-shadow: unset !important;

      &:hover {
        border: 2px solid $text--light-gray-color;
      }

      input::placeholder {
        color: $text-gray-color !important;
      }

      &:focus {
        border-width: 2px;
      }

      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }

      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
        max-width: unset;
      }

      & [class~='ant-input-prefix'] {
        margin-right: 8px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .filterActions {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border: 2px solid $light-gray;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: $dark-color;

      width: setUnit(36);
      height: setUnit(36);

      @media (min-width: $tablet) {
        height: setUnit(44);
        width: setUnit(44);
      }

      svg {
        width: setUnit(20);
        height: setUnit(20);
      }

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
    }

    .multselectSelected {
      border-color: $primary-color;
      background-color: $primary-color-light;
      &:hover {
        border: 2px solid $primary-color;
      }
    }
  }

  @media (max-width: $mobile-small) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
