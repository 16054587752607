.scheduleItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0px 24px;
  cursor: pointer;

  &:hover {
    background: rgba(244, 245, 246, 0.6);
  }

  .leftContent {
    display: flex;

    .icon {
      margin-right: 12px;

      svg {
        vertical-align: middle;
        width: setUnit(20);
        height: setUnit(20);
      }
    }

    .scheduleItem {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.33px;
      color: #262626;
    }
  }

  .rightContent {
    display: flex;
    justify-content: space-between;
    .circle {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: #4673d1;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.33px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .todayNum {
      background-color: #4673d114;
    }
    .thisWeekNum {
      border: #4673d114 solid 2px;
    }
  }
}
