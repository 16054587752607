/* Hide the native checkbox */
.hidden-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

/* Custom checkbox container */
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  border: 2px solid;
  border-radius: 3px;
}

/* Styled checkbox */
.styled-checkbox {
  display: inline-block;
  position: relative;
  background-color: #fff; /* Default background */
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.hidden-checkbox:checked + .styled-checkbox::after {
  content: '';
  position: absolute;
  top: 43%;
  left: 50%;
  width: 37%; /* Dynamic width for tick */
  height: 80%; /* Dynamic height for tick */
  border: solid #333; /* Dark tick color */
  border-width: 0 var(--tick-width) var(--tick-width) 0;
  transform: translate(-50%, -50%) rotate(40deg);
  box-sizing: border-box;
}
