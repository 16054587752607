@import 'styles/functions';
@import 'styles/variables';

.download {
  cursor: pointer;

  .icon {
    position: relative;
    top: setUnit(-2);

    svg {
      vertical-align: middle;
    }
  }
}
