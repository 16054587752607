@import 'styles/functions';
@import 'styles/variables';

.container {
  .dateInput {
    margin-bottom: 0 !important;
    [class~='flatpickr-wrapper'] {
      width: 100%;
    }
  }
}
