@import 'styles/functions';
@import 'styles/variables';

.pinIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: #eaebed;
  border-radius: 50%;
}

.optionContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.avatarCustom {
  height: 32px !important;
  width: 32px !important;
  font-size: setUnit(14);
}

.multiSelectWrapper {
  margin: 0;

  .placeholderForSelected {
    & [class~='ant-select-selector'] {
      [class~='ant-select-selection-overflow'] {
        margin-left: -3px;
      }
    }
  }

  .multiSelect {
    border-radius: 8px !important;
    position: relative;
    height: 48px !important;

    & [class~='ant-select-selector'] {
      border-radius: 8px !important;
      min-height: setUnit(44);
      height: auto;
      border-color: $border-light-gray-2 !important;
      box-shadow: none !important;
      padding-left: 38px;
      padding-right: 18px;

      [class~='ant-select-selection-overflow-item'] {
        top: 2px !important;
        .optionContainer > div {
          display: none;
        }
      }

      [class~='ant-select-selection-placeholder'] {
        span {
          color: $text--light-gray-color;
          font-weight: 300;
          padding-left: 0 !important;
        }

        margin-left: 28px;
      }

      [class~='ant-select-selection-item'] {
        align-items: center;
        background-color: $background-grey-2;
        border-color: $border-light-gray-2;
        border-radius: setUnit(100);
        padding: setUnit(6) setUnit(10) setUnit(6) setUnit(12);

        [class~='ant-select-selection-item-content'] {
          font-size: setUnit(14);
          font-weight: 400;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.09);
          color: $text-color;
        }
        [class~='ant-select-selection-item-remove'] {
          display: flex;
          align-items: center;
          svg {
            fill: $dark-color;
          }
        }
      }
    }

    & [class~='ant-select-selection-search'] {
      margin-inline-start: 0px;
    }

    & [class~='ant-select-arrow'] {
      position: absolute;
      left: 9px;
      > div {
        line-height: 0px;
      }
    }

    & [class~='ant-select-clear'] {
      margin-top: -10px;
      width: 1rem;
      right: 10px;
    }
  }

  [class~='ant-select-open'] {
    & [class~='ant-select-selector'] {
      border-color: $text-color !important;
    }
  }
}

.selectedItemsWrapper {
  margin: setUnit(16) 0px setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftItemContainer {
    display: flex;
    align-items: center;
  }

  .itemText {
    margin-left: setUnit(8);
    font-size: 1rem;
  }

  .crossIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 0px;
  }
}

.multiSelectSectionDropdown {
  border-radius: 8px !important;
}

.labelLength {
  width: 230px !important;
  overflow: hidden !important;
}
