@import 'styles/variables';
@import 'styles/functions';

.partnerDesc {
  font-weight: 400;
  font-size: setUnit(14);
  line-height: setUnit(18);
  letter-spacing: -0.18px;
  color: $text-color;
  background-color: $background-gray;
  padding: setUnit(12);
  border-radius: setUnit(8);
}
