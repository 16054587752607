@import 'styles/functions';
@import 'styles/variables';

.memberModal {
  text-align: center !important;
}

.memberTitle {
  font-size: setUnit(24);
  font-weight: 700;
  margin-top: setUnit(5);
}

.memberForm {
  margin-top: setUnit(40);

  .inputWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: setUnit(20);

    .formInput {
      flex-grow: 1;

      input {
        background: transparent !important;
        cursor: not-allowed !important;
      }
    }
  }

  .btnWrapper {
    margin-top: setUnit(20);
    text-align: right;

    .saveBtn {
      width: setUnit(152) !important;
      height: setUnit(52) !important;
      border-radius: 12px !important;
      padding: setUnit(10) setUnit(20) !important;
      span {
        font-size: setUnit(16) !important;
        font-weight: 600 !important;
      }
    }
  }
}
