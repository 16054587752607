@import 'styles/functions';
@import 'styles/variables';

.editActions {
  width: fit-content;
  margin-left: auto;
  padding: setUnit(10);

  .button {
    border-radius: 10px;
    height: setUnit(43);
    &.cancel {
      background: transparent;
      color: $text-color;
      border: 1px solid $border-middle-gray;
      margin-right: setUnit(10);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.buttonWidthMobile {
  width: auto;
}
.buttonWidthNotMobile {
  width: 5rem;
}
