@import 'styles/functions';
@import 'styles/variables';

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.33px;
  text-align: center;
  color: $text-color;
  margin-bottom: 24px;
  .count {
    color: $text--light-gray-color;
  }
}
.archiveHeader {
  display: flex;
  justify-content: flex-end;
}
.archiveWrapper {
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px #30303014;
  margin-bottom: 40px;
  overflow: hidden;
}

.archiveView {
  flex-basis: calc(100% - 218px) !important;
}

.emptyArchivetext {
  font-size: setUnit(16);
  text-align: center;
  color: #747475;
  margin-top: setUnit(80);
}

.cancelled {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.09px;
  color: $dark-grey-color;
  padding: 6px 8px;
  border-radius: 100px;
  background-color: $background-grey-2;
}

.unscheduledWrapper {
  .archiveWrapper {
    padding-top: setUnit(20);
    background-color: #fff;
  }
}
