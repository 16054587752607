@import 'styles/functions';
@import 'styles/variables';

.clientTours {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .content {
    background-color: #f8f9fa;
    padding: setUnit(24);
    flex: 1 1 auto;

    .main {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.33px;
        text-align: center;
        color: $text-color;
        margin-bottom: 24px;
        .count {
          color: $text--light-gray-color;
        }
      }
    }
  }
}
