@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.pageContent {
  display: flex;
  justify-content: center;
  padding: setUnit(24) 0;
  gap: setUnit(24);

  .activityWrapper {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: setUnit(24);

    .noDataFound {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: setUnit(8);

      margin-top: 178px;

      .noDataTitle {
        color: $dark-grey-color;
        margin: 0;
        font-family: Inter, sans-serif;
        font-size: setUnit(18);
        font-weight: 700;
        line-height: setUnit(20);
        letter-spacing: -0.09px;
      }

      .noDataDescription {
        color: $text-gray-color;
        margin: 0;
        font-family: Inter, sans-serif;
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(18);
        letter-spacing: -0.09px;
      }
    }
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    gap: setUnit(24);

    max-width: 356px;
    width: 100%;

    .shareIntelButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: setUnit(22) 0;

      width: 100%;

      color: $white;
      background-color: $secondary-color;
      box-shadow: 0 setUnit(2) setUnit(16) rgba(48, 48, 48, 0.03);
      border-radius: 12px;

      font-family: Inter, sans-serif;
      font-size: setUnit(16);
      font-weight: 700;
      line-height: setUnit(18);
      letter-spacing: -0.09px;

      outline: none;
      border: none;

      cursor: pointer;
      user-select: none;
    }

    .filterContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: setUnit(8);

      filter: drop-shadow(0 setUnit(2) setUnit(16) rgba(48, 48, 48, 0.03));
      border-radius: setUnit(16);

      background: $white;
      height: fit-content;

      .input {
        border: 1px solid $light-grey-color;
        padding: 11px !important;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.18px;

        color: $text-color;

        [class*='ant-input-prefix'] {
          margin-right: 8px !important;
        }

        & input::placeholder {
          color: $text--light-gray-color;

          font-family: Inter, sans-serif;
          font-size: setUnit(16);
          font-style: normal;
          font-weight: 400;
          line-height: setUnit(20.8);
          letter-spacing: -0.18px;
        }

        & svg path {
          stroke: $text-gray-color;
        }
      }

      .checkbox {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 10px 0;
      }

      .checkboxLabel {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 0;
      }

      .checkboxLabelText {
        flex: 1;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.18px;

        color: $text-color;
      }

      .closeIcon {
        cursor: pointer;

        & svg path {
          fill: $text-gray-color;
        }
      }

      .divider {
        margin: 0 setUnit(24);
        width: 308px;
        height: setUnit(1);

        background: #e8e9ea;
      }
    }

    .filterSection {
      width: 100%;
      padding: 0 24px;

      [class='ant-collapse-header'] {
        padding: 0;
      }

      [class='ant-collapse-header-text'] {
        padding: 23px 0;
      }

      [class*='ant-collapse-item-active'] {
        padding-top: 0 !important;
      }

      [class*='ant-collapse-content-box'] {
        padding: 0 !important;
        padding-bottom: 24px !important;

        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      [class*='ant-collapse-arrow'] {
        right: 0 !important;
      }

      .header {
        display: flex;
        gap: 6px;

        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, sans-serif;
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(18);
        letter-spacing: -0.18px;

        .title {
          color: $dark-gray-3;
        }

        .count {
          color: $text--light-gray-color;
        }
      }

      .btnWrapper {
        // TODO: fix
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: setUnit(100);
        width: 32px;
        height: 32px;
        background: #f4f5f6;
      }

      .dateRange {
        display: flex;
        align-items: center;
        gap: 8px;

        .datePicker {
          margin: 0 !important;

          & input {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.18px;

            color: $text-color;

            padding-left: 40px !important;
          }

          & input::placeholder {
            color: $text--light-gray-color;
          }
        }

        .to {
          color: $text--light-gray-color;

          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
      }
    }
  }
}

.locationWrapper {
  width: 100%;
  max-width: unset !important;

  .activeInputClassName {
    height: 48px !important;

    font-family: Inter, sans-serif;
    font-size: setUnit(16);
    font-style: normal;
    font-weight: 400;
    line-height: setUnit(20.8);
    letter-spacing: -0.18px;

    & input {
      min-height: 36px !important;
    }
  }

  .searchIcon {
    & svg path {
      stroke: $text-gray-color;
    }
  }

  .placeholderClass {
    color: $text--light-gray-color !important;

    font-family: Inter, sans-serif;
    font-size: setUnit(16);
    font-style: normal;
    font-weight: 400;
    line-height: setUnit(20.8);
    letter-spacing: -0.18px;
  }
}

.tagWrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .tag {
    border-radius: 16px;
    border: 1px solid var(--grayscale-25-outline, #d6d6d7);
    display: flex;
    padding: 7px 9px;
    justify-content: center;
    align-items: center;
    color: var(--Grayscale-50, #747475);

    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.18px;

    cursor: pointer;

    &.active {
      padding: 8px 10px;
      border: none;

      border-radius: 16px;
      color: var(--White, #fff);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.18px;

      background: linear-gradient(90deg, #ff576d, #fb913a);
    }
  }
}
