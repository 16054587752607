@import 'styles/functions';
@import 'styles/variables';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: setUnit(56);
  border-top: 1px solid $border-light-gray;

  &:last-child {
    border-bottom: 1px solid $border-light-gray;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: setUnit(18);
    width: setUnit(45);

    svg {
      vertical-align: middle;

      path,
      circle,
      rect {
        stroke: $border-middle-gray;
      }
    }
  }

  .label {
    margin-left: 0;
  }
}
