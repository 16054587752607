@import 'styles/functions';
@import 'styles/variables';

@mixin addRemoveButtons() {
  width: setUnit(48);
  height: 100%;
  border: none;
  outline: none;
  background-color: $background-ligh-gray;

  &:hover {
    background-color: $light-gray;

    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  svg {
    vertical-align: middle;

    path {
      stroke: $dark-grey-color;
    }
  }
}

.parkingSpaces {
  .holder {
    width: 100%;
    height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba($border-middle-gray, 0.5);
    border-radius: setUnit(8);
    overflow: hidden;

    .count {
      font-size: setUnit(16);
      font-weight: 400;
    }
  }
}
