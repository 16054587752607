@import 'styles/functions';
@import 'styles/variables';

.locationDetails {
  .locationName {
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(24);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: setUnit(2);
    margin-top: setUnit(18);
    text-align: center;
  }

  .locationAddress {
    font-weight: 400;
    font-size: setUnit(18);
    line-height: setUnit(24);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
    text-align: center;
  }

  @media screen and (max-width: $mobile) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    .locationName {
      font-size: setUnit(16);
    }
  }
}
