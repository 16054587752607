@import 'styles/variables';
@import 'styles/mixins';

.userRoleContainer {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: $white;
  min-width: 180px;
  width: fit-content;
  z-index: 5;

  .userRow {
    padding: 0px 20px;
    display: flex;
    width: 100%;
    margin: 3px 0px;
    min-height: 40px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $background-grey-2;
      cursor: pointer;
      border-radius: 8px;
    }

    &:first-child {
      &:hover {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    &:last-child {
      &:hover {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .userName {
    overflow-wrap: anywhere;
    display: block;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $dark-color;
  }

  .checkbox {
    margin-right: 12px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }

  .userTitle {
    overflow-wrap: anywhere;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.stickyBottom {
  position: sticky;
  bottom: 20px;
  left: 40px;
  width: fit-content;
  z-index: 999;
}
