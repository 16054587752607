@import 'styles/functions';
@import 'styles/variables';

.projectTitle {
  .title {
    width: fit-content;
    font-weight: 600;
    letter-spacing: -0.64px;
    color: $text-color;
    display: flex;
    align-items: center;
    margin-bottom: setUnit(8);
    font-size: setUnit(20);
    line-height: setUnit(28);
    @media (min-width: $tablet) {
      font-size: setUnit(20);
      line-height: setUnit(28);
    }
    &.fullAccess {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .editIcon {
      width: setUnit(32);
      height: setUnit(32);
      border-radius: 50%;
      background: $background-ligh-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: setUnit(16);
      visibility: hidden;
    }

    &:hover {
      .editIcon {
        visibility: visible;
      }
    }
  }

  .subtitle {
    font-weight: 400;
    letter-spacing: -0.64px;
    color: $dark-color;
    font-size: setUnit(18);
    line-height: setUnit(22);
    @media (min-width: $tablet) {
      font-size: setUnit(18);
      line-height: setUnit(22);
    }
  }
}
