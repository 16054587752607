@import "styles/functions";
@import "styles/variables";

.wrapper {

    .innerHolder {
        position: relative;

        .commutes {
            position: absolute;
            top: setUnit(71);
            left: setUnit(60);
            width: setUnit(300);

            @media screen and (max-width: 1100px) {
                position: static;
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .holder {
        max-width: setUnit(416);
        margin: 0 auto;
    }

    .addAnotherBtn {
        margin: 0 auto setUnit(64);
        display: block;
        width: 100%;
        background: none;
        color: $text-color;
        font-weight: 600;
        border: 1px solid $border-middle-gray;

        .addAnotherTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;

            .addIcon {
                svg {
                    width: setUnit(20);
                    height: setUnit(20);
                    vertical-align: middle;
                    margin-right: setUnit(10);
                }
            }
        }

        &:hover {
            background: none;
            opacity: .8;
        }
    }

    .btn {
        max-width: setUnit(415);
    }
}
