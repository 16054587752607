@import 'styles/functions';
@import 'styles/variables';

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $tablet) {
    justify-content: space-evenly;
  }
}
