@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.transactions {
  .fieldWrapper {
    margin-bottom: 1.675rem;
  }

  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .search {
    .value {
      &:after {
        border: none;
      }
    }
  }
}
