@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.listWrapper {
  box-shadow: 0 setUnit(3) setUnit(12) -5px rgba(0, 0, 0, 0.15);
  width: 100%;
  position: absolute;
  top: 4.1rem;
  right: 0;
  background: #ffffff;
  border-radius: setUnit(8);
  z-index: 1;
  ul {
    list-style: none;
    margin: setUnit(8) 0;
  }
  .item {
    padding: setUnit(10) setUnit(15);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: $background-ligh-gray;
    }

    img {
      height: 18px;
      width: 18px;
    }
  }
}

.userAvatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  flex-shrink: 0;

  svg {
    width: 18px;
  }

  [class~='ant-avatar-string'] {
    transform: scale(0.4) translateX(-50%) translateY(-30%) !important;
  }
}

.profileWrap {
  display: flex;
  align-items: center;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;

  ~ svg {
    @media screen and (max-width: $tablet-horizontal) {
      flex-shrink: 0;
    }
  }
}

.optionUsername {
  color: $text-color;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.00563rem;
  margin-left: 0.5rem;
  margin-bottom: 0;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: $tablet-horizontal) {
    font-size: setUnit(14);
    white-space: wrap;
  }
}

@media screen and (max-width: 475px) {
  .listWrapper {
    border-radius: 0px;
  }

  .optionUsername {
    font-size: 1rem;
    width: unset;
    max-width: unset;
  }

  .profileWrap {
    ~ svg {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
  }
}
