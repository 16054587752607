@import 'styles/functions';
@import 'styles/variables';

.splitsContainer {
  padding: setUnit(12);
  .headerContainer {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 600;
      font-size: setUnit(20);
    }

    .addSplitCon {
      display: flex;
      cursor: pointer;
      gap: 5px;
    }

    .addSplit {
      color: $link-blue;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem;
      letter-spacing: -0.01125rem;
    }
  }

  .buttonsContainer {
    margin-bottom: setUnit(20);
    display: flex;
    gap: 12px;
    button {
      border-radius: 8px;
      width: 100%;
    }
  }

  &.dullSection {
    opacity: 0.5;
  }

  .checkboxCont {
    display: flex !important;
    // align-items: flex-end !important;
    justify-content: flex-end !important;
  }
}
