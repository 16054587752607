@import 'styles/functions';
@import 'styles/variables';

.counterOffer {
  background: #f4f5f6;
  border: 2px solid #f4f5f6;
  border-radius: 16px;
  padding: 20px;
  width: 48%;
  display: inline-block;
  margin: 0px 5px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.18px;
  color: #262626;
}

.subHeading {
  font-weight: 600;
  font-size: setUnit(14);
  line-height: 16px;
  letter-spacing: -0.18px;
  color: #262626;
  margin-bottom: 20px;

  .inputHolder {
    margin-top: 8px;
  }

  .yesNoContainer {
    margin-top: 8px;
  }

  .yesContainer {
    margin-bottom: 20px;
  }

  .questionInput {
    margin-top: 8px;

    .questionInputLabel {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .questionInputLabelSecondary {
      margin-top: 12px;
      margin-bottom: 0px;
    }
  }

  .inputArea {
    font-weight: 400;
    letter-spacing: -0.18px;
    display: block;
    margin: 10px 0px;
  }

  .input {
    margin: 0px !important;
  }

  input {
    height: 25px !important;
    padding: 0px !important;
    background: none !important;
    border-radius: 0px !important;
    border-bottom: 2px solid #d6d6d7 !important;
    font-size: setUnit(16) !important;
  }

  .counterOfferOccupancyInput {
    input {
      text-align: center !important;
      padding: 0px !important;
    }
  }

  [class*='icon'] {
    display: none !important;
  }

  [class*='inputField'] {
    padding-left: 0px !important;
  }

  & [class*='ant-input'] {
    &:hover {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  & [class*='ant-select-selector'] {
    border: unset !important;
    border-bottom: 2px solid $border-input-middle-gray !important;
    height: auto;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 0px !important;
    font-size: setUnit(16);
  }

  & [class*='ant-select'] {
    display: block;
  }

  & [class*='ant-picker'] {
    background: none;
    border: none !important;
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    border-bottom: 0px solid #d6d6d7;
    display: block;
  }

  & [class*='ant-picker-input'] {
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}
.inputBlock {
  &:not(:last-child) {
    margin-bottom: setUnit(30);
  }

  .questionInput {
    border: 1px solid $background-ligh-gray-4;
    border-radius: setUnit(8);
    margin-top: setUnit(16);
    padding: setUnit(16);

    &.label {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
    &LabelSecondary {
      margin-top: setUnit(16);
    }
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 16px;
  }

  .contingencies {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .checkbox {
      [class~='ant-checkbox'] {
        & + span {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 205px;
          .static {
            width: 100%;
            color: $dark-color;
            &:first-child {
              max-width: 80px;
            }
            &:last-child {
              max-width: 37px;
            }
          }

          .daysInputHolder {
            border: none;
            width: 40px;
            padding: 0px !important;
            margin: 0 8px 0 28px;
            input {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.18px;
              color: $text-color;
              border-bottom: 2px solid $border-light-gray-3;
              padding: 0;
              text-align: center;
              &::placeholder {
                color: $text-gray-color;
              }
            }
            [class~='ant-input-number-handler-wrap'] {
              display: none;
            }
            &[class~='ant-input-number-focused'] {
              box-shadow: none;
              input {
                border-bottom-color: $text-gray-color;
              }
            }
            &.daysinputholdererror {
              input {
                border-bottom: 2px solid $error-color;
              }
            }
          }
        }
      }
      &[class~='ant-checkbox-wrapper-checked'] {
        [class~='ant-checkbox'] {
          & + span {
            .static {
              color: $text-color;
            }
          }
        }
      }
    }
  }
  .waived {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $background-grey-2;
    padding: 4px 8px;
    border-radius: 40px;
    font-weight: 500;
    font-size: 14px;
    color: $text-gray-color;
  }
}
