@import 'styles/variables';

.uploadInput {
  text-align: center;
}

.attachment {
  border: 2px solid $light-gray;
  background: $white;
  text-align: left;
  margin-top: 0;
}

.attachmentFile {
  font-size: 16px !important;
  line-height: 16px;
}

.attachmentDate {
  font-size: 14px !important;
  line-height: 16px;
  margin-top: 0.25rem !important;
  color: $dark-color;
}

.uploadBox {
  width: 100%;
  max-width: 400px;
  [class~='ant-upload'] {
    display: block !important;
    margin-bottom: 16px;
  }
  .uploadBtn {
    width: 100%;
    height: 228px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border: 2px dashed $border-light-gray-2;
    border-radius: 8px;
    background-color: $background-grey-2;
    transition: $transition;
    cursor: pointer;
    &:hover {
      border-color: $text--light-gray-color;
    }

    svg {
      margin-bottom: 16px;
    }

    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      text-align: center;
      color: $dark-grey-color;
      margin-bottom: 12px;
      &.last {
        margin-bottom: 16px;
      }
    }

    .button {
      border: 2px solid $background-blue;
      border-radius: 8px;
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 24px !important;
      letter-spacing: -0.09px !important;
      color: $background-blue !important;
      background-color: #fff !important;
      padding: 14px 26px !important;
      height: auto !important;
      &:hover,
      &:focus {
        border-color: $background-blue !important;
        color: #fff !important;
        background-color: $background-blue !important;
      }
    }
  }
}
