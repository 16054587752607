@import 'styles/functions';
@import 'styles/variables';

.formBox {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  align-items: center;

  .leftContent {
    display: flex;

    .icon {
      width: 32px;
      height: 32px;
      background-color: #74747519;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .titleContainer {
      margin-left: 12px;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-style: normal;

      .title {
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
      }

      .subtitle {
        color: $dark-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }
    }
  }

  .rightContent {
    display: flex;

    .formStatus {
      display: flex;
      width: 160px;
      padding: 6px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      margin-right: 8px;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.33px;
    }

    .hide {
      visibility: hidden;
    }
  }
}
