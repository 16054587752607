@import 'styles/mixins';
@import 'styles/variables';

$active-border-color: $primary-color;

.wrapper {
  width: 100%;
  margin: setUnit(24) 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .label {
    @include label;
  }

  .error {
    @include inputError;
  }

  .holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .customDropdownFPK {
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    input {
      padding-left: 0px !important;
    }
  }
}
