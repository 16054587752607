@import 'styles/functions';
@import 'styles/variables';

.accordion {
  border-top: 0 !important;
}

.content {
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);
  color: $dark-color;

  .dontMiss {
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-bottom: setUnit(24);
  }

  .getPreApproved {
    padding: setUnit(11) 0;
    text-align: center;
    width: 100%;
    background: $primary-color-light;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    border-radius: setUnit(10);
    outline: none;
    transition: all 0.1s;
    cursor: pointer;

    &:hover {
      background: darken($primary-color-light, 5);
    }
  }
}

.priceHistory {
  font-size: setUnit(14);
  line-height: setUnit(16);
  color: $text-color;

  .priceHistoryHeader {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: setUnit(15) setUnit(12);
    border-radius: setUnit(8);
    font-weight: 600;

    background-color: $background-ligh-gray;

    div {
      width: 33%;
    }
  }

  .priceHistoryRow {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: setUnit(15) setUnit(12);
    border-radius: setUnit(8);

    div {
      width: 33%;
    }
  }
}
