@import 'styles/functions';
@import 'styles/variables';

.modal {
  .modalHeader {
    padding: setUnit(36) setUnit(40);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .titleWrap {
      width: calc(100% - 32px);
      .titleText {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.46px;
        margin-bottom: 0;
        text-align: center;
      }
    }

    .closeIcon {
      width: setUnit(32);
      height: setUnit(32);
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        vertical-align: middle;
        width: setUnit(40);
        height: setUnit(40);

        path {
          fill: $text-color;
        }
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .modalContent {
    border-radius: setUnit(12);
    width: 100%;
    max-width: setUnit(675);
    max-height: 675px;
    overflow: hidden;
    .innerHolder {
      height: auto;
      overflow: unset;
    }
  }
}
