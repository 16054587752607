@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.listingWrapper {
  height: 540px;
  overflow-y: scroll;
}
.uploadIconWrapper {
  & [class*='ant-btn-default'] {
    border-radius: 50% !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.arrowHeadingWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: -32px;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
  }
}
.iconButton {
  background-color: $background-grey-2 !important;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  &:hover {
    background-color: $background-grey-2 !important;
    border: 0 !important;
  }
  &:focus {
    border: 0 !important;
  }
}
.crossButton {
  margin-top: 40px !important;
  margin-right: 32px !important;
}
.uploadDate {
  font-weight: 600;
  line-height: 21px;
  color: $text-color;
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0.5rem;
  }
}
.softCollapse {
  margin: 0;
  padding: 0;

  .iconsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  & [class*='ant-collapse-header'] {
    margin: 0 !important;
    padding: 0 !important;
  }
  & [class*='ant-collapse-content'] {
    margin: 0 !important;
    padding: 0 !important;
  }
  & [class*='ant-collapse-arrow'] {
    right: 0 !important;
  }
  .preApprovalDocuments {
    padding: 2rem 0 2rem;
  }
}
.expandedTransactionHeading {
  display: flex;
  align-items: center;
  .headingItemsWrapper {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .headingWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .uploadIcon {
    margin-right: 10px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 12px;
    line-height: 21px;
  }
}
.documentOptionsWrapper {
  position: relative;

  .optionsDots {
    cursor: pointer;
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(48);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;

    ul {
      list-style: none;
      margin-bottom: 0.5rem;
    }

    .item {
      padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
      }

      &:hover {
        background: $background-ligh-gray;
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}
