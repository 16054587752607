@import 'styles/functions';
@import 'styles/variables';
@keyframes open {
  from {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}

@keyframes close {
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
}

/* Box */
.box {
  @media (min-width: $mobile) {
    max-height: 50px;
  }
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  &.open {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    .text {
      animation: open 0.1s linear 0s forwards;
    }
  }
}

.timelineContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tagColumn {
    text-align: right;
    min-width: 200px;
    .tag {
      padding: setUnit(8) setUnit(10);
      height: setUnit(32);
      background-color: $background-grey-2;
      border-radius: setUnit(16);
    }
    .tag_without_round {
      color: #747475;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 16px;
      word-wrap: break-word;
    }
  }
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  animation: close 0.1s linear 0.1s forwards;
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  color: $text-color;
  strong {
    font-weight: 600;
    a {
      color: $text-color;
    }
  }
}
.expandableWrapper {
  margin-top: setUnit(8);
  margin-bottom: setUnit(8);
}
.showHideBtn {
  color: $link-blue;
  font-weight: 500;
  font-size: setUnit(14);
  line-height: setUnit(16);
  letter-spacing: -0.18px;
  cursor: pointer;
  margin-bottom: setUnit(6);
}
.strongtitle {
  margin-right: setUnit(5);
}
