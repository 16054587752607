@import 'styles/functions';
@import 'styles/variables';

.myKitsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: setUnit(48);
  padding-right: setUnit(48);
  margin: setUnit(12);

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $mobile) {
      width: 100%;
      justify-content: space-evenly;
    }

    .backLink {
      display: flex;
      justify-content: center;
      align-items: center;

      .back {
        z-index: 1;
        overflow: hidden;
        padding: 0;
        width: setUnit(44);
        height: setUnit(44);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $light-gray;
        background: #fff;
        transition: $transition;
        margin-right: setUnit(16);
        border-radius: 50%;

        &:hover {
          background-color: $light-gray;
          opacity: 1;
        }

        &:focus {
          background-color: $border-light-gray-2;
        }

        div {
          margin-right: 0;
          display: flex;

          svg {
            width: setUnit(8);
            height: setUnit(14);

            path {
              stroke: $dark-color;
            }
          }
        }

        span {
          display: none;
        }

        &:hover {
          color: $text-color;
        }
      }
    }
  }

  .heading {
    font-weight: 700;
    font-size: setUnit(32);
    line-height: setUnit(36);
    font-family: $font-family;
    letter-spacing: setUnit(-0.4);
    color: $text-color;

    @media screen and (max-width: $mobile) {
      margin-top: setUnit(24);
      margin-bottom: setUnit(24);
    }
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border: 2px solid $background-ligh-gray-4;
    border-radius: setUnit(100);
    cursor: pointer;

    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    &Text {
      margin-left: 6px;
      margin-bottom: 0;
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $dark-color;
    }

    .icon {
      cursor: pointer;
      margin-left: setUnit(16);
      display: flex;
      justify-content: center;
      align-items: center;
      height: setUnit(44);
      width: setUnit(44);
      border-radius: 50%;
      border: 2px solid $background-ligh-gray-4;

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
    }
  }
}

.myKitsContent {
  flex-grow: 1;
  background-color: $background-ligh-gray-3;
}
