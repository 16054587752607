@import 'styles/functions';
@import 'styles/variables';

.title {
  margin: 0;
  font-size: setUnit(20);
  font-weight: 600;
  color: $text-color;
  line-height: setUnit(26);
  letter-spacing: -0.33px;
}
