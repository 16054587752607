@import 'styles/functions';
@import 'styles/variables';

[class*='ant-modal-footer'] {
  .compareOfferFooter {
    padding-top: setUnit(16);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-right: setUnit(32);
    padding-left: setUnit(40);
    box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.05);
    .buttonWrapper {
      max-width: 150px;
    }
    p {
      margin-bottom: 0;
      color: $dark-color;
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(20);
      letter-spacing: -0.18px;
    }
    button {
      background-color: red;
      margin-left: 0 !important;
      height: auto !important;
      max-width: setUnit(150px) !important;
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      padding: setUnit(16) setUnit(40);
    }
  }
}
