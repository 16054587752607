@import "styles/functions";
@import "styles/variables";

.header {
    padding: setUnit(22) setUnit(32) setUnit(32);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .allNotifications {
        display: flex;
        align-items: center;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
        cursor: pointer;
        padding: setUnit(12) setUnit(16);
        border-radius: setUnit(20);
        border: 0;
        outline: none;
        background-color: transparent;
        transition: all .1s;

        .arrow {
            margin-right: setUnit(10);
        }

        &:hover {
            background-color: $hover-light-gray;
        }
    }

    .titleWrapper {
        display: flex;
        align-items: center;
    }
}
