@import 'styles/functions';
@import 'styles/variables';

.details {
  position: relative;
  padding-bottom: 1.625rem;
  border-bottom: 1px solid $grey-color;

  .detailsCollapse {
    .detailsTitle {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
      color: $text-color;
    }

    & [class*='ant-collapse-header'] {
      padding: 0 !important;
      margin-top: 1.625rem !important;

      & [class*='ant-collapse-arrow'] {
        height: 32px !important;
        top: -3px !important;
        transform: initial !important;
        right: 0 !important;

        svg {
          path {
            stroke: $dark-color;
          }
        }
      }

      &:hover {
        & [class*='ant-collapse-arrow'] {
          svg {
            circle {
              fill: $light-gray !important;
            }
          }
        }
      }
    }

    & [class*='ant-collapse-content-box'] {
      padding: 1.5rem 0 0 !important;
    }
  }

  .icon {
    position: absolute;
    top: -3px;
    right: 40px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $background-grey-2;
    border-radius: 50%;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;

      path {
        stroke: $dark-color;
      }
    }

    &:hover {
      background: $light-gray;
    }
  }
}
