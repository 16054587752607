@import 'styles/functions';
@import 'styles/variables';

.note {
  .title {
    margin-top: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
  }

  .inputBlock {
    justify-content: space-between;
    align-items: center;
    margin-bottom: setUnit(30);

    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin-top: setUnit(25);
    }

    .inputHolder {
      border: none;
      margin-top: 16px;
      width: 100%;
      padding: 0px !important;

      input {
        height: setUnit(44) !important;
        border-bottom: 2px solid $border-light-gray-3;
      }

      [class*='icon'] {
        display: none !important;
      }

      [class*='inputField'] {
        padding-left: 0px !important;
      }

      & [class*='ant-select-selector'] {
        border: unset !important;
        border-bottom: 2px solid $border-input-middle-gray !important;
        box-shadow: none !important;
        padding-left: 0px !important;
        font-size: 16px !important;
      }
    }

    .offerInfo {
      display: flex;

      .dateTime,
      .requestor {
        display: inline-block;

        h3 {
          font-weight: 600;
        }

        p,
        span {
          color: $text-color;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0.25rem;
        }
      }

      .dateTime {
        width: 55%;

        .declinedAppointment {
          color: $text--light-gray-color;
          text-decoration-line: line-through;
        }
      }

      .requestor {
        p,
        span {
          font-weight: 400;
        }

        span {
          color: $link-blue;
        }

        .phone {
          margin-top: -0.2rem;

          input {
            font-size: 16px;
          }
        }
      }
    }

    .pending {
      .approvers {
        background: $primary-color-light;
        padding: 0.75rem 1.5rem;
        text-align: center;
        margin-top: 1.5rem;

        p,
        span {
          color: $primary-color;
          line-height: 21px;
          margin: 0;
        }

        span {
          font-weight: 600;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;

        .icon {
          display: inline-block;
          position: relative;
          top: 0.3rem;
          margin-right: 0.5rem;
        }

        [class*='ant-btn'] {
          width: 17.5rem;
          height: 3.25rem;
        }

        .approve {
          border: 2px solid $hover-green;
          margin-right: 0.5rem;

          span {
            font-weight: 600;
            color: $hover-green;
            position: relative;
            top: -0.15rem;
          }
        }

        .decline {
          border: 2px solid $hover-red;

          span {
            font-weight: 600;
            color: $hover-red;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .details .appointmentInfo {
      display: block;
      margin-top: 0.5rem;

      .dateTime {
        width: 100%;
      }

      .requestor {
        display: block;
        margin-top: 1rem;
      }
    }
  }
}
