@import 'styles/variables';
@import 'styles/functions';

.gridWrapper {
  width: 100%;
}

.row {
  height: setUnit(64);
  border-bottom: 1px solid rgba($border-blue, 0.5);
}

.childRow {
  height: auto;
}

.column {
  color: $dark-color;
  font-size: setUnit(14);
  line-height: setUnit(16);
  font-weight: normal;
}

.noContentWrapper {
  color: $border-middle-gray;
  font-weight: 600;
  font-size: setUnit(16);
  height: setUnit(240);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.grid {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    padding: 0 setUnit(12);
  }
}

.gridHeader {
  .row {
    height: setUnit(40);
  }

  .column {
    white-space: nowrap;
    text-align: left;
    font-weight: 600;
    font-size: setUnit(14);
    line-height: setUnit(16);
    color: $dark-grey-color;
  }
}

.gridBody {
}

.gridFooter {
}
