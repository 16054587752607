@import 'styles/functions';
@import 'styles/variables';

.notesDetails {
  .headTitle {
    color: $text-gray-color;
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.18px;

    margin-bottom: 6px;
  }

  .noteDateTime {
    color: $text-gray-color;
    font-weight: 400;
    font-size: 12px;
  }

  .note {
    font-size: setUnit(14);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.18px;
    margin-bottom: 4px;
    word-break: break-word;
  }

  .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-gray-color;
    margin-bottom: 0;
  }

  .readMoreLess {
    color: $link-blue;
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.18px;
    margin-left: 2px;
  }

  .readMoreLess:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
