@import 'styles/functions';
@import 'styles/variables';

.mapSwitchers {
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  display: flex;
  gap: 10px;
}

.clientFeedWrapper {
  position: relative;
  z-index: 1;
  height: calc(100dvh - 80px);
  overflow: hidden;
}
