@import 'styles/functions';
@import 'styles/variables';

.participants {
  margin: 40px;

  .spinnerMarginTop {
    margin-top: 6.25rem;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: setUnit(32);
    .inner {
      display: flex;
      align-items: center;
      .text {
        font-size: setUnit(20);
        font-weight: 600;
      }
    }
  }

  .participantContainer {
    background-color: #f4f7fc;
    padding: setUnit(24) 0;
    border-radius: 8px;

    .text {
      font-size: setUnit(20);
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.33px;
      margin-bottom: 1.5rem;
      padding: 0 setUnit(24);
    }

    .emptyContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 180px;
      font-size: 1.25rem;
      color: $text--light-gray-color;
      font-weight: 500;
    }
  }

  .seperator {
    border-bottom: 1px solid $light-gray;
  }
}
