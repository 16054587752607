@import 'styles/functions';
@import 'styles/variables';

.activityFiltersIndexHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  @media (min-width: $tablet) {
    justify-content: flex-end;
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 1;
  }
  .btn {
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 0.5rem;
    font-weight: 600;
    &:last-child {
      margin-right: 0;
    }

    .filterIcon {
      margin-top: 0.25rem;
    }

    .icon {
      transition: $transition;
      &.asc {
        transform: rotate(180deg);
      }

      &.desc {
        transform: rotate(0deg);
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}
