@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.messageActionMenu {
  width: setUnit(140);
  border-radius: 8px;
  padding: 0;

  .menuItem {
    display: flex;
    align-items: center;
    padding: setUnit(10) setUnit(22) setUnit(8) setUnit(22);
    height: 40px;

    * {
      font-family: $font-family;
    }

    color: $text-color !important;

    &:hover {
      background: $background-grey-2 !important;
      color: $text-color !important;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
  }

  .menuItemSpan {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
  }
}

.actions {
  position: relative;
  margin-right: setUnit(4);

  .listHolder {
    @include box-shadow;

    width: setUnit(196);
    display: none;
    position: absolute;
    top: setUnit(42);
    right: 10;
    background: #ffffff;
    border-radius: setUnit(8);
    z-index: 99;

    .list {
      list-style: none;

      .item {
        font-size: setUnit(14);
        font-weight: 400;
        padding: setUnit(14);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-items: center;

        &:hover {
          background: $background-ligh-gray-4;
        }
      }
    }

    &.open {
      display: block;
    }
  }

  .circle {
    background: $background-ligh-gray-4;
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }
}
