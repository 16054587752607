@import 'styles/functions';
@import 'styles/variables';

.clausesTemplateHeader {
  margin-bottom: setUnit(28);
}

.clausesTabsPane {
  [class*='ant-tabs-tab-btn'] {
    text-shadow: none !important;
  }

  [class*='ant-tabs-tabpane-active'] {
    height: 100% !important;
    margin-top: 32px;
  }
}

.clausesEmptyTemplates {
  padding-top: 0px;
  height: calc(100dvh - 80px - 64px - 160px);
}
