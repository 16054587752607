@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.activeTransaction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 100%;
  padding: setUnit(18) 0;
  .homeIcon {
    padding: setUnit(12);
    background-color: $background-grey-2;
    border-radius: setUnit(8);
    width: setUnit(48);
    display: flex;
    margin-right: 16px;
  }
  .content {
    width: calc(100% - #{setUnit(112)});
    .title {
      font-weight: 500;
      font-size: setUnit(16);
      line-height: setUnit(21);
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: setUnit(5);
    }
    ul.description {
      list-style-type: none;
      margin-bottom: 0;
      li {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
        color: $dark-color;
        &:not(:first-child) {
          margin-left: setUnit(8);
          &:before {
            content: '';
            width: setUnit(3);
            height: setUnit(3);
            background-color: $text-gray-color;
            display: inline-block;
            vertical-align: middle;
            margin-right: setUnit(8);
          }
        }
      }
    }
  }
  .optionsWrapper {
    display: flex;
    position: relative;
    margin-left: 16px;
    .optionsDots {
      cursor: pointer;
      & > div {
        display: flex;
        svg {
          width: setUnit(32);
          height: setUnit(32);
          path {
            stroke: $text-gray-color;
          }
        }
      }
    }
    .options {
      @include box-shadow();
      position: absolute;
      top: setUnit(48);
      right: 0;
      background: #ffffff;
      border-radius: setUnit(8);
      min-width: setUnit(180);
      padding: setUnit(8) 0;
      z-index: 10;
      ul {
        margin-bottom: 0;
        .item {
          padding: setUnit(4) setUnit(10);
          display: flex;
          align-items: center;
          cursor: pointer;
          @include transition();
          .icon {
            margin-right: setUnit(8);
            position: relative;
            top: setUnit(-2);
          }
          &:hover {
            background: $background-ligh-gray;
          }
        }
      }
    }
  }
}
