@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.assignedTo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 100%;
  padding: setUnit(18) 0;
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - #{setUnit(104)});
    margin: 0 setUnit(16);
    .title {
      font-weight: 500;
      font-size: setUnit(16);
      line-height: setUnit(21);
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: 0;
    }
  }
  .optionsWrapper {
    display: flex;
    position: relative;
    .optionsDots {
      cursor: pointer;
      & > div {
        display: flex;
        svg {
          width: setUnit(32);
          height: setUnit(32);
          path {
            stroke: $text-gray-color;
          }
        }
      }
    }
  }
}

.assignedToOptionsPopover {
  [class*='ant-popover-content'] {
    width: 0;
    top: 0.5rem;

    [class*='ant-popover-inner'] {
      background: transparent;
      box-shadow: unset;
    }
  }

  .options {
    @include box-shadow();
    position: absolute;
    top: setUnit(32);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;
    ul {
      list-style: none;
      padding: setUnit(8) 0;
      margin-bottom: 0;
      .item {
        padding: setUnit(9) setUnit(12);
        display: flex;
        align-items: center;
        cursor: pointer;
        @include transition();
        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
        }
        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
