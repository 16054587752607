@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$question-header-height: 72px;
$question-footer-height: 100px;

.pageSection {
  border-top: 1px solid $light-gray;
  margin-top: 24px;

  max-height: 50vh;
  overflow-y: auto;
  padding-bottom: 70px;

  .pageSectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px 24px 0px;

    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;

    .pagesTitle {
      color: $text-color;
    }

    .pagesCount {
      color: $text--light-gray-color;
    }
  }

  .pages {
    margin-top: 24px;

    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .pdfDocument {
      width: fit-content;
      position: relative;
      min-height: 136px;
      width: 100%;
    }

    .documentPage {
      width: 100% !important;
      min-height: 176px;
      cursor: pointer;
      margin: 0 0 16px 0px;

      &:hover {
        border: 2px solid $text-gray-color;
        border-radius: 6px;

        .documentPageThumbnail {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        .pageNumber {
          color: $text-color;
        }
      }

      .documentPageThumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 0 2px 16px #3030301a;
        border-radius: 0px;
        height: 145px;

        background-size: contain;
        background-position: top;
      }

      .pageNumber {
        text-align: center;
        color: $text--light-gray-color;
        margin-top: 12px;

        font-size: setUnit(14);
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09;
        text-align: center;
      }
    }

    .activePage {
      border: 2px solid $text-gray-color;
      border-radius: 6px;

      .documentPageThumbnail {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      .pageNumber {
        color: $text-color;
      }
    }
  }
}

.loadingSpinner {
  font-size: 0.5em;
  margin: 30px auto;
}

.pdfPageSection {
  padding-bottom: 70px;

  .pages {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
    width: 100%;
  }
}

.openedWrapContainer {
  background-color: $white;
}

@media screen and (max-width: 1380px) {
  .pageSection {
    max-height: 40vh;
    margin-top: 0px;
  }

  .pdfDocument {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)) !important;
    gap: 20px !important;
  }
}

@media screen and (max-width: 475px) {
  .pageSection {
    margin-top: 0px;
  }

  .pdfDocument {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)) !important;
    gap: 24px !important;
  }
}
