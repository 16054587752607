@import 'styles/functions';
@import 'styles/functions';

@mixin formWidth {
  max-width: setUnit(612);
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}

@mixin formControls {
  position: absolute;
  top: 34px;
  right: 40px;
}

@mixin onBoardingHeader {
  padding: setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  min-height: $onboarding-header-height;
  max-height: $onboarding-header-height;
  .logo {
    display: flex;
  }
}

@mixin formFooter {
  max-width: setUnit(612);
  width: 100%;
  margin: 0 auto;
  padding: setUnit(14) setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.planPageFooter {
    padding: setUnit(32) setUnit(16);
  }
  .skip {
    background: transparent;
    border: setUnit(2) solid $light-gray !important;
    color: $dark-color !important;
    max-width: setUnit(142);
    width: 100%;
    height: setUnit(52);
    font-size: 16px;
    font-weight: 600;
    display: block;
    border-radius: setUnit(8);
    .skipTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $dark-color;
    }
    &:hover {
      border: 2px solid $text--light-gray-color;
      .skipTitle {
        color: $text-color;
      }
    }
  }
  .submit,
  .submitBtn {
    background-color: $background-blue;
    max-width: setUnit(142);
    width: 100%;
    height: setUnit(52);
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-left: auto;
    border-radius: setUnit(8);
    &:hover {
      background-color: $secondary-color-hover;
    }
    &.celebrateSubmit {
      min-width: 220px;
    }
    .submitTitle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .lightArrow {
      margin-left: setUnit(12);
      path {
        stroke: $white;
      }
    }
  }

  .backButton {
    border-color: $light-gray !important;
    color: $dark-color !important;
  }

  .active {
    opacity: 1;
    &:hover {
      background-color: $secondary-color-hover;
    }
  }
}
