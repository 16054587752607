@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.label {
  @include label;

  .altLabel {
    font-size: setUnit(14);
    color: $border-middle-gray;
    font-weight: 400;
  }
}
