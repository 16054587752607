@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.templateCard {
  min-height: setUnit(48);
  width: 100%;
  font-size: setUnit(16);
  line-height: setUnit(21);
  color: $text-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px $grey-color solid;
  }

  .tcRight {
    margin-left: setUnit(8);
    .dropdownTrigger {
      cursor: pointer;
      width: setUnit(32);
      height: setUnit(32);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: $background-ligh-gray;
      }
    }
    .transactionMoreOptions {
      @include box-shadow;
      background: $white;
      border-radius: setUnit(8);
      width: 11rem;
      [class*='ant-dropdown-menu'] {
        padding: 0;
        border-radius: setUnit(8);
      }
      .dropdownItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: setUnit(10) setUnit(22);
        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
          svg {
            width: setUnit(24);
            height: setUnit(24);
            path,
            circle {
              stroke: $primary-color;
            }
          }
        }
        &:hover {
          background: $background-ligh-gray;
        }
        svg {
          vertical-align: middle;
        }
      }
    }
  }
  .metaInfo {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
    font-size: 14px;
  }
}
