@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.moveItemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;

  .moveItemTitle {
    display: flex;
    gap: 12px;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09px;

      span {
        color: $text--light-gray-color;
      }
    }
  }
}
