@import 'styles/variables';

.clientNames {
  display: flex;
  align-items: flex-start;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.09px;
  .names {
    margin-left: 8px;
  }
}
