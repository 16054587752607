@import 'styles/functions';
@import 'styles/variables';

.item {
  transition: all 0.1s;
  display: flex;
  align-items: center;
  padding: setUnit(12) setUnit(25);
  margin: setUnit(15) setUnit(8);
  cursor: pointer;
  color: $text-color;
  position: relative;

  &:hover {
    background-color: $hover-light-gray;
  }

  .propertyIcon {
    margin-right: setUnit(15);
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    flex: 1;

    .itemContentTitle {
      font-size: setUnit(16);
      font-weight: 500;
      line-height: setUnit(16);
      display: flex;

      .itemContentTitleTime {
        margin-left: auto;
        color: $text-gray-color;
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        white-space: nowrap;
        padding-top: setUnit(2);
      }
    }

    .itemContentText {
      padding-top: setUnit(5);
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      color: $dark-grey-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 85%;
    }
  }
}
