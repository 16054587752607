@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$question-header-height: 72px;
$question-footer-height: 100px;

.fileNameWrapContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 20%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 20%;
  min-width: 20%;
  border-right: 1px solid $light-gray;
  position: relative;

  cursor: pointer;

  .fileNameWrap {
    display: flex;
    align-items: center;
    overflow: hidden;

    .fileIconBundle {
      line-height: 0;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .singleFileIcon {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .bundleNameTooltip {
      height: auto;
      .documentName {
        white-space: nowrap;
        overflow: hidden;
        max-width: 90%;
        text-overflow: ellipsis;
      }
    }

    .documentName {
      margin-left: 0.75rem;
      margin-bottom: 0;
      color: $text-color;
      font-family: Inter;
      font-size: setUnit(14);
      font-style: normal;
      font-weight: 600;
      line-height: 1.3rem; /* 148.571% */
      letter-spacing: -0.01125rem;

      width: fit-content;
    }

    .bundleNameCount {
      display: flex;
      align-items: center;
      width: 75%;
    }
  }

  .arrowIconWrap {
    line-height: 0px;
  }
}

.openedWrapContainer {
  background-color: $white;
}

.documentsCount {
  color: $text--light-gray-color;
  margin-left: 4px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .fileNameWrapContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 475px) {
  .fileNameWrapContainer {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    gap: 5px;
  }

  .documentName {
    display: none !important;
  }
}

@media screen and (max-width: $tablet) {
  .documentName {
    max-width: 80% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
