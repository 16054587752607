@import 'styles/functions';
@import 'styles/variables';

.showingSummary {
  overflow-y: auto;
  padding: setUnit(24);
  @media (min-width: $tablet) {
    height: 50%;
    padding: 2rem 2.5rem;
  }

  .noShowings {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: $text-gray-color;
  }
}

.showingsSummaryHead {
  display: flex;
  align-items: center;
  .actionsColumn {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .titleArea {
    display: flex;
    align-items: center;
    height: 50px;
    h2 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      color: #262626;
      margin-bottom: 0;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .actionBtn {
      background: $background-grey-2;
      border-radius: setUnit(100);
      width: setUnit(40);
      height: setUnit(40);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      &:hover {
        background-color: $grey-color;
      }
      &:not(:last-child) {
        margin-right: setUnit(12);
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: setUnit(20);
          height: setUnit(20);
        }
      }
    }

    .imgTextBtn {
      span {
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.18);
      }
      svg {
        path {
          stroke-width: setUnit(4);
        }
      }
      @media (min-width: $mobile) {
        width: auto;
        padding: 0.5rem 1rem;
      }
    }

    .enableBtn {
      width: auto;
      padding: 0.5rem 1rem;
      span {
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.18);
      }
    }
  }
}
