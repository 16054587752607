@import 'styles/functions';
@import 'styles/variables';

.clarityPage {
  min-height: calc(100vh - $header-height - $footer-height - 2px);
  color: $text-color;
  background-color: $tertiary-50;
  .clarityHeaderArea {
    background-color: $white;
    padding: setUnit(24) setUnit(12);
  }
  .containerRow {
    padding: setUnit(24) setUnit(12);
  }

  .cardStyles {
    margin: 10px !important;
    border-radius: 10px !important;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contentContainer {
    width: 72vw;
    height: 100%;
    max-width: $tablet-horizontal;
    margin: 0 auto;
    display: flex;
    flex: auto;

    @media screen and (max-width: $desktop) {
      min-width: 85vw;
    }

    @media screen and (max-width: $tablet-horizontal) {
      min-width: 90vw;
    }

    @media screen and (max-width: $tablet) {
      min-width: 100vw;
    }
  }
}
