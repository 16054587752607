@import 'styles/functions';
@import 'styles/variables';

.container {
  .wrapper {
    background: $green-color;
    border-radius: setUnit(8);
    color: $white;
    padding: setUnit(16) setUnit(24);
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-bottom: setUnit(32);

    b {
      font-weight: 700;
    }

    &:not(:last-of-type) {
      margin-bottom: setUnit(8);
    }
  }

  .wrapperMore {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: $green-color;
    border-radius: setUnit(8);
    color: $white;
    padding: setUnit(16) setUnit(24);
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-bottom: setUnit(32);
    svg {
      position: relative;
      top: setUnit(3);
    }

    b {
      font-weight: 700;
    }

    &:not(:last-of-type) {
      margin-bottom: setUnit(8);
    }
  }

  .wrapperModal {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e6f8ee;
    border-radius: setUnit(8);
    padding: setUnit(16) setUnit(24);
    font-size: 1rem;
    font-size: setUnit(16);
    line-height: setUnit(21);
    flex-direction: column;
    margin: 0 20%;
    svg {
      position: relative;
      top: setUnit(3);
    }

    b {
      font-weight: 700;
    }

    &:not(:last-of-type) {
      margin-bottom: setUnit(8);
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.addressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: setUnit(20);
  margin-bottom: setUnit(20);
  img {
    height: setUnit(50);
    width: setUnit(50);
    object-fit: cover;
    border-radius: setUnit(6);
  }
  .address {
    .lineOne {
      font-size: setUnit(18);
      font-weight: 600;
      margin: 0;
    }
    .lineTwo {
      font-size: setUnit(16);
      font-weight: 400;
      margin: 0;
    }
  }
}
