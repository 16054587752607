@import 'styles/functions';
@import 'styles/variables';

.asideNavigation {
  background: linear-gradient(0, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), $secondary-color;
  padding: setUnit(40) setUnit(16);
  height: 100%;
  overflow: auto;
  width: 100%;
  position: fixed;
  z-index: 10;
  scrollbar-width: none;
  &:hover {
    scrollbar-width: auto;
  }

  @media (min-width: $desktop) {
    max-width: setUnit(281);
    position: relative;
  }

  .overviewIconActive {
    svg {
      path {
        fill: $white;
        stroke: none;
      }
    }
  }
  .contactActive {
    svg {
      g {
        opacity: 1;
      }
      stroke: $white;
    }
  }
  .detailIconActive {
    svg {
      circle {
        fill: $white;
      }
    }
  }
  .navTogglerClose {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: transparent;
    border: 0;
    padding: 0;
    svg {
      width: 32px;
      height: 32px;
      path {
        fill: #fff;
      }
    }
  }

  .divider {
    width: 100%;
    background-color: #d6d6d7;
    opacity: 15%;
    margin-bottom: 0;
  }

  .notesContainer {
    text-decoration: none;
    color: $background-ligh-gray-5;
    font-weight: 400;
    opacity: 0.9;
    display: block;
    margin: setUnit(2) 0;
    cursor: pointer;
    margin: 0;

    .icon {
      margin-right: setUnit(20);
    }

    .notes {
      display: flex;
      align-items: center;
      padding: setUnit(18) setUnit(25);
    }

    .label {
      position: relative;

      &.isUnread {
        &:after {
          content: '';
          width: setUnit(6);
          height: setUnit(6);
          border-radius: 50%;
          background: $primary-color;
          position: absolute;
          right: setUnit(-16);
          top: setUnit(8);
        }
      }
    }
  }
}

.transactionsMenu {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 3px 15px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(163, 163, 163, 0.2);
  z-index: 9;
  cursor: pointer;
  .hamburgerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: setUnit(32);
      height: setUnit(32);
    }
  }
}
