@import 'styles/functions';
@import 'styles/variables';

.circleWrapper {
  margin-top: setUnit(20);

  .circleBackground {
    fill: none;
    stroke: $background-grey-2;
  }

  .greenProgress {
    fill: none;
    stroke: $hover-green;
  }

  .darkGreenProgress {
    fill: none;
    stroke: $hover-dark-green;
  }

  .yellowProgress {
    fill: none;
    stroke: $yellow-color;
  }

  .orangeProgress {
    fill: none;
    stroke: $orange;
  }

  .redProgress {
    fill: none;
    stroke: $radio-red;
  }

  .progressText {
    font-size: setUnit(20);
    color: $text-color;
  }
}
