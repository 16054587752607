@import 'styles/functions';
@import 'styles/variables';

.inProgressFormPill {
  height: 100%;
  min-height: setUnit(44);
  border-radius: setUnit(100);
  padding-left: 16px;
  padding-right: 16px;

  border: 1px solid $orange;

  background: rgba($orange, 0.08);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-weight: 500;
  width: fit-content;
  min-width: 138px;

  .text {
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;
    white-space: nowrap;

    color: $text-color;

    .formsCount {
      color: $text--light-gray-color;
    }
  }

  &:hover {
    background-color: rgba($orange, 0.16);
  }

  .formsCountSpinner {
    font-size: 0.3em;
    margin: 0px;
  }
}

.disabledInProgressFormPill {
  cursor: default;

  &:hover {
    background: rgba($orange, 0.08);
  }
}
