@import "styles/functions";
@import "styles/variables";

.footer {
    width: 100%;
    padding: setUnit(16) setUnit(32);
    background: $background-ligh-gray;
    border: 1px solid $grey-color;

    .submitButton {
        width: setUnit(102);
        margin-left: auto;
        display: block;
    }
}
