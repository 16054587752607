@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  position: relative;

  .openBtn {
    font-weight: normal;
    font-size: setUnit(16);
    line-height: setUnit(20);
    padding: setUnit(10) setUnit(16);
    background: $hover-light-gray;
    border-radius: setUnit(8);
    cursor: pointer;
    display: flex;
    align-items: center;

    .openBtnIcon {
      padding-left: setUnit(8);
      margin-left: auto;
      height: setUnit(16);

      svg {
        height: setUnit(16);
        width: setUnit(16);
      }
    }
  }

  .itemsList {
    z-index: 10;
    position: absolute;
    top: setUnit(44);

    display: flex;
    flex-wrap: wrap;
    color: $text-color;
    padding: setUnit(12) 0;
    background-color: $white;
    box-shadow: 0px 2px setUnit(12) rgba(0, 0, 0, 0.15);
    border-radius: setUnit(8);
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    width: setUnit(220);
    @media (min-width: $tablet) {
      width: setUnit(450);
    }
    &[data-open='true'] {
      opacity: 1;
      visibility: visible;
    }

    .item {
      width: 100%;
      @media (min-width: $tablet) {
        width: 50%;
      }
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: normal;
      padding: setUnit(10) setUnit(24);
      transition: all 0.1s;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-top-left-radius: setUnit(8);
      border-bottom-left-radius: setUnit(8);

      &:nth-child(odd) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: setUnit(8);
        border-bottom-right-radius: setUnit(8);
      }

      .itemIcon {
        margin-left: auto;
        visibility: hidden;
        opacity: 0;
        height: setUnit(16);

        svg {
          width: setUnit(16);
          height: setUnit(16);
        }
      }

      &:hover {
        background-color: $hover-light-gray;
      }

      &[data-selected='true'] {
        font-weight: 500;

        .itemIcon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
