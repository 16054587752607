@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$question-header-height: 68px;

.displayFlex {
  display: flex;
}

.border {
  border-right: 1px solid $light-gray;
}
.topBarWrap {
  @extend .displayFlex;
  height: inherit;
}

.topBarContainer {
  background: linear-gradient(354deg, rgba(255, 255, 255, 0) 5.27%, rgba(255, 255, 255, 0.4) 95.21%),
    $background-gray !important;
  width: 100% !important;
  max-width: unset !important;
  min-width: unset !important;
  flex: 0 0 268px !important;
  box-shadow: -4px 0px 12px 0px rgba(48, 48, 48, 0.15);
  height: 4.5rem;
  position: sticky !important;
  top: 0;
  z-index: 3;

  .selectRole {
    width: 12.9rem;
    height: 46px;
    margin-top: 12px;
  }

  .fileNameWrap {
    display: flex;
    align-items: center;
    width: 280px;
    padding-left: 1.5rem;
    border-right: 1px solid $light-gray;
  }

  .documentNameTooltip {
    height: auto;
  }

  .documentName {
    margin-left: 0.75rem;
    margin-bottom: 0;
    color: $text-color;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3rem; /* 148.571% */
    letter-spacing: -0.01125rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
  }

  .signerWrap {
    width: 207px;
    border-right: 1px solid $light-gray;
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;

    &:hover {
      background-color: $tertiary-50-hover;
      cursor: pointer;
    }

    .signerContent {
      display: flex;
      align-items: baseline;
      margin: auto 0px;
      gap: 3px;
    }

    .signerContentWrap {
      display: flex;
      align-items: center;
      position: relative;

      .usersList {
        left: 0;
      }
    }

    .arrowIconWrap {
      line-height: 0;
    }

    .signerNameWrap {
      display: block;
      margin-left: 0.75rem;

      .title {
        color: $text-gray-color;
        font-family: Inter;
        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        letter-spacing: -0.00563rem;
        margin-bottom: 1px;
      }

      .userName {
        color: $dark-color;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3rem;
        letter-spacing: -0.01125rem;
        max-width: 6.8vw;
        width: 100%;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;

        margin-bottom: 0px;
      }
    }

    .userAvatar {
      width: 2rem;
      height: 2rem;

      svg {
        width: 28px;
        height: 28px;
        vertical-align: text-top;
      }
    }
  }

  .groupSection {
    display: flex;
    padding: 0 17px;
    border-right: 1px solid $light-gray;
  }

  .strikeThroughGroup {
    padding: 0px;

    @media (min-width: 1880px) {
      border-right: none;
    }
  }

  .dynamicFormTopbar {
    display: flex;
  }

  .disabledTopbar {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.item {
  cursor: grab;
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: $text-gray-color;
  width: 72px;
  height: 4.5rem;

  &:hover {
    background-color: $tertiary-50-hover;
  }

  .itemContent {
    text-align: center;
    display: block;
    margin: 0 auto;

    @media (min-width: 1515px) {
    }
  }

  .iconItem {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.strikeThroughItem {
  width: 95px;
  margin: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.moreDragItem {
  width: 100%;
  justify-content: flex-start;
  padding-left: 0px;
  padding-right: 0px;

  .itemContent {
    margin: 0px;
  }

  &:hover {
    background-color: unset;

    .moreDragContent {
      div {
        color: $text-color;
      }
    }
  }

  .moreDragContent {
    display: flex;
    align-items: center;
    gap: 10px;

    div {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09000000357627869px;
      text-align: left;
    }
  }
}

.disabledItem {
  cursor: not-allowed;

  &:hover {
    background-color: unset;
  }
}

.navigationIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: absolute;
  right: 0;
  height: 4.5rem;
  width: 4.5rem;
  border-left: 1px solid $light-gray;

  @media screen and (max-width: 1350px) {
    position: static;
    border-left: 0px;
  }

  .loadingSpinner {
    font-size: 0.3em;
    margin: 0px;
  }
}

.navigationIcons:hover {
  cursor: pointer !important;
  background-color: $tertiary-50-hover !important;
}

.AddMoreSigner {
  padding: setUnit(10) setUnit(15);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: $background-ligh-gray;
  }

  .optionUsername {
    color: $text-color;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.00563rem;
    margin-left: 0.5rem;
    margin-bottom: 0;
    width: 8rem;
    max-width: 8rem;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 960px) {
  .topBarContainer {
    height: 4.2rem;

    .signerWrap {
      width: 150px;
    }

    .navigationIcons,
    .item {
      width: 4.2rem;
      height: 4.2rem;
    }

    .groupSection {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .moreDragItem {
    .moreDragContent {
      gap: 6px;
      div {
        font-size: 14px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media screen and (max-width: $tablet-horizontal) {
  .topBarContainer {
    position: fixed !important;
    top: 80px;
  }

  .webViewTopBarContainer {
    top: 0px !important;
  }

  .textFieldItem {
    svg {
      height: 14px !important;
    }
  }
}

@media screen and (width > 475px) and (max-width: $mobile) {
  .signerContent {
    .usersList {
      width: 30vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: 475px) {
  .signerNameWrap {
    display: none !important;
  }

  .signerContent {
    align-items: center !important;
    gap: 5px !important;

    .usersList {
      width: 100vw;
      left: 0px;
    }
  }

  .signerWrap {
    width: 110px !important;
    position: static !important;
  }

  .groupSection {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
