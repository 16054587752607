@import 'styles/functions';
@import 'styles/variables';

.dashboardHeader {
  background-color: $white;
  .heading {
    line-height: setUnit(36);
    font-weight: 700;
    text-align: center;
    color: $text-color;
    padding: setUnit(40) 0 setUnit(36);
    letter-spacing: -0.4px;
    margin: 0 auto;
    font-size: setUnit(20);
    @media (min-width: $tablet) {
      font-size: setUnit(32);
    }
  }
  @media (max-width: $mobile) {
    height: 120px;
  }
}
.headerInner {
  display: flex;
  padding: 38px 0px 0px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
}
.headerNameWrapper {
  color: var(--REBRAND-Grayscale-100, #262626);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.4px;
  margin-bottom: 38px;
}
.dashboardCardArea {
  padding: setUnit(48) setUnit(40) setUnit(24);
}
.tabIcon {
  display: flex;
  margin-bottom: setUnit(8);
  svg {
    width: setUnit(24);
    height: setUnit(24);
  }
}
.tab {
  height: setUnit(40);
  font-size: setUnit(14);
  font-weight: 400;
  background: $background-ligh-gray;
  padding: setUnit(12) setUnit(24);
  border-radius: setUnit(8);
  color: $text-color;
  &:hover {
    background-color: $light-gray;
  }

  &.tabActive {
    background: $secondary-color;
    color: #ffffff;
  }
}

@import 'styles/functions';
@import 'styles/variables';

.linkContainer {
  @media screen and (min-width: $responsive-menu) {
    display: block;
    width: calc(100% - 540px);
    min-width: 200px;
    margin: auto;
  }
}

$border-bottom: 3px solid $primary-color !important;

.linksHorizontal {
  margin: 0 !important;
  margin-left: -4px !important;
  display: flex !important;
  border-bottom: none !important;
  justify-content: center;
  align-items: center;
  gap: 40px;

  .menuItemContainer {
    height: $header-height;
  }
  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--Grayscale-50, #747475) !important;
    font-weight: 600;
  }

  li {
    height: 44px;
    line-height: 44px;
  }
}

.icon {
  .link:hover &,
  .link:active & {
    fill: red;
    color: red;
  }
}

.link {
  cursor: pointer;
  color: var(--Grayscale-50, #747475);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.18px;

  span {
    padding: 14px 0px;
    border-bottom: 3px solid transparent;
  }
  &.active {
    color: var(--Grayscale-100, #262626);
    span {
      border-bottom: $border-bottom;
    }
  }

  &:hover,
  &:focus,
  &.active,
  &.ant-menu-item-selected {
    .icon {
      fill: red;
      color: red;
    }
  }
}

.titleWrapper {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  gap: '15px';
}

.linksVertical {
  [class*='ant-menu-item '] {
    padding: 0px !important;
    height: 64px !important;
    line-height: 64px !important;
  }
  a {
    padding: 2px 16px !important;
    line-height: 16px;
  }
  li {
    margin: 0px !important;
    &:hover {
      background-color: $hover-light-gray;
    }
  }
  a,
  a:hover,
  a:active,
  a:visited {
    font-family: $font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }

  [class*='ant-menu-item-selected'] {
    background-color: $white !important;

    &:hover {
      background-color: $hover-light-gray !important;
    }
    a {
      color: $text-color !important;
      border-left: 3px solid $primary-color;
      padding: 0px 13px !important;
    }
  }

  [class*='ant-menu-item']:hover,
  [class*='ant-menu-item-active'],
  [class*='ant-menu-submenu-open'],
  [class*='ant-menu-submenu-active'],
  [class*='ant-menu-submenu-title']:hover {
    a {
      color: $text-color !important;
      border-left: 3px solid $primary-color;
      padding: 0px 13px !important;
    }
  }
}
