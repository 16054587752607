@import 'styles/functions';
@import 'styles/variables';

.propertyMenu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px #00000024;
  border-radius: setUnit(8);
  right: 0;
  top: setUnit(45);
  z-index: 100;
  right: setUnit(8);
  margin-top: setUnit(4);

  .menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 14px 16px;
    &:hover {
      background-color: #f4f5f6;
    }

    .icon {
      display: flex;
      margin-right: 8px;
    }

    .favorite path {
      fill: #ff576d;
    }

    &Text {
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $text-color;
      margin-bottom: 0;
    }

    &:last-child,
    &:first-child {
      border-radius: setUnit(8);
    }
  }
}

.propertyMenuLoader {
  width: setUnit(182);
}
