@import 'styles/functions';
@import 'styles/variables';

.view {
  display: flex;
  position: relative;

  .startPoint {
    min-width: setUnit(12);
    height: setUnit(12);
    background: $border-light-gray;
    border-radius: 50%;
  }

  .timelineItem {
    color: $text--light-gray-color;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.2rem;

    &.left {
      padding-left: setUnit(22);
    }

    &.right {
      padding-right: setUnit(20);
      max-width: 100%;
      .name {
        width: inherit;
        font-size: setUnit(16);
      }
    }

    &.overdue {
      background-color: #ec455e0f;
      color: $error-color;
      .name {
        color: $error-color !important;
      }
    }

    .private svg path {
      stroke: $primary-color;
    }

    .deadline,
    .name,
    .private {
      &.isAfterToday {
        color: $secondary-color;

        svg path {
          stroke: $primary-color;
        }
      }
    }

    .deadline {
      font-size: setUnit(14);
    }

    &.active {
      color: $secondary-color;

      .point {
        .innerWrapper {
          border-color: transparent;

          &:before,
          &:after {
            content: '';
            position: absolute;
            width: setUnit(3);
            height: setUnit(3);
            background: $background-ligh-gray;
          }

          &:before {
            top: setUnit(-3);
          }

          &:after {
            bottom: setUnit(-3);
          }
        }
      }
    }
  }

  .timelinePointsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 4.5rem;
    max-width: 4.5rem;

    .timelineItem {
      padding-bottom: 0;
      min-width: auto;

      &:last-child {
        .line {
          height: calc(100% - #{setUnit(35)});
          position: absolute;
          bottom: setUnit(35);

          &.addMode {
          }
        }
      }

      &.overdue {
        background-color: #ec455e0f;
        color: $error-color;
      }
    }

    .point {
      border-radius: 50%;
      position: relative;
      min-width: setUnit(32);
      height: 100%;
      margin: 0 0 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .innerWrapper {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0;
        z-index: 9;
        width: fit-content;
        height: fit-content;
      }

      .line {
        position: absolute;
        left: setUnit(15);
        height: 100%;
        width: setUnit(2);
        background: $border-light-gray;

        &.hidden {
          display: none;
        }

        &.startDate {
          margin-top: setUnit(35);
          height: calc(100% - #{setUnit(35)}) !important;
        }
        &.aggregatedMode {
          margin-top: setUnit(35);
          height: calc(100% - #{setUnit(35)}) !important;
        }
      }
    }
  }

  .active {
    background: $background-ligh-gray;
  }

  .deadlines {
    width: unset;
    .active {
      border-top-left-radius: setUnit(8);
      border-bottom-left-radius: setUnit(8);
    }

    .deadlineDatePicker {
      margin: 0;
      position: relative;
      top: setUnit(1);

      input {
        padding-left: 0;
        font-size: setUnit(14);
        height: 2.5rem;
      }
      input::placeholder {
        font-size: 0.875rem !important;
        font-weight: 400 !important;
      }
    }
    &.editModeWidth {
      width: 30%;
    }
    &.addModeWidth {
      width: 24.7%;
    }
  }

  @media (min-width: $responsive-search) {
    .deadlines {
      width: 24.7% !important;
    }
  }

  .milestoneNames {
    flex: 1;

    .active {
      border-top-right-radius: setUnit(8);
      border-bottom-right-radius: setUnit(8);
      font-weight: 600;
    }

    .milestoneName {
      position: relative;
      top: setUnit(1);
      input {
        font-size: 1rem !important;
        height: 2.5rem;
      }
    }

    .twoLines {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limit the text to 2 lines */
      -webkit-box-orient: vertical;
    }
  }

  .removePart {
    .timelineItem {
      &.active {
        border-top-right-radius: setUnit(8);
        border-bottom-right-radius: setUnit(8);
      }
    }
  }

  .close {
    min-width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $background-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: setUnit(32);
  }
}

.iconClassName {
  top: 10px !important;
}
