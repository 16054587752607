@import 'styles/functions';
@import 'styles/variables';

.assignTo {
  padding-right: setUnit(20);
  .to {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
  .nameHolder {
    display: flex;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
    &.alignStart {
      align-items: flex-start !important;
    }
    &:hover {
      .editIcon {
        opacity: 1;
        visibility: visible;
      }
    }
    .name {
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .editIcon {
      margin-left: setUnit(14);
      cursor: pointer;
      min-width: setUnit(32);
      height: setUnit(32);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.1s ease;
      visibility: hidden;
      &:hover {
        background: $background-ligh-gray;
        transform: scale(1.1);
      }
    }
  }

  .inputHolder {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    .closeIcon {
      position: absolute;
      right: 0;
      cursor: pointer;
      transition: all 0.1s ease;

      svg {
        vertical-align: middle;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .inputWrapper {
      margin: 0;
    }

    .input {
      width: 100%;
    }

    .checkmark {
      background: $green-color;
      min-width: setUnit(24);
      height: setUnit(24);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: setUnit(20);
      transition: all 0.1s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  [class*='clientInput'] {
    [class*='role'] {
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
