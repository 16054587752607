@import 'styles/variables';
@import 'styles/functions';

.activeIteration {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.iterations {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}

.subheadings {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.ellipsedContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99.9%;
  color: #1890ff;
  a {
    white-space: nowrap;
  }
}

.subHeadingArea {
  p {
    margin-bottom: 5px;
  }

  a {
    margin-top: 5px;
  }
}

.JumpToFirst {
  color: $link-blue;
  width: 106px;
  height: 40px;
  margin-right: 10px;
  border-color: $light-gray;
  border-style: solid;
  background: $white;
  border-radius: 100px;
}

.offers {
  padding: 2rem;

  .titleArea {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    .pageTitle {
      font-weight: 700;
      font-size: 2rem;
      line-height: 36px;
      color: $text-color;
      display: inline-block;
      margin-bottom: 0;
    }

    .actions {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .offerSwitcher {
        width: 122px;
        margin-right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: rgba(0, 177, 82, 0.2);
        border-radius: 100px;
        padding: 4px 12px;
        &:hover,
        &[class~='ant-select-open'],
        &[class~='ant-select-focused'] {
          [class~='ant-select-selector'] {
            border: 0;
            box-shadow: none;
          }
        }
        [class~='ant-select-selector'] {
          border: 0;
          background-color: transparent;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          [class~='ant-select-selection-search'] {
            input {
              height: auto;
            }
          }
          [class~='ant-select-selection-item'] {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.18px;
            color: $green-color;
            display: flex;
            align-items: center;
          }
        }
        [class~='ant-select-arrow'] {
          width: 16px;
          height: 16px;
          svg {
            width: 100%;
          }
        }
        .offerSwitcherDropdown {
          width: 196px !important;
          box-shadow: 0px 4px 16px 0px #00000024;
          border-radius: 8px;
          padding: 0;
          [class~='ant-select-item'] {
            padding: 14px 16px;
            background-color: $white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            &[class~='ant-select-item-option-selected'] {
              background-color: $background-grey-2;
            }
            [class~='ant-select-item-option-content'] {
              .optionItem {
                .offerLabel {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: -0.09px;
                  color: $text-color;
                  &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    border-radius: 100px;
                    margin-right: 10px;
                  }
                  &.accepted {
                    &:before {
                      background-color: $hover-green;
                    }
                  }
                  &.canceled {
                    &:before {
                      background-color: $error-color;
                    }
                  }
                }
              }
            }
            [class~='ant-select-item-option-state'] {
              svg {
                width: 10px;
                height: 6.67px;
                path {
                  stroke: $primary-color;
                }
              }
            }
          }
        }
      }
      .actionBtn {
        width: 2.5rem;
        height: 2.5rem;
        background: $background-grey-2;
        border-radius: 100px;
        padding: 0.5rem 0.6rem;
        margin-right: 0.75rem;
        display: inline-block;
        cursor: pointer;

        &:hover {
          background-color: $grey-color;
        }

        &:last-child {
          margin-right: 0;
        }

        span {
          font-weight: 500;
        }
      }

      .enableBtn {
        width: auto;
        padding: 0.5rem 1rem;
      }
    }
  }

  .acceptedOfferHistory {
    max-height: 22rem;
  }
}

.btn {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 2px solid $light-gray;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: $dark-color;

  width: setUnit(36);
  height: setUnit(36);
  @media (min-width: $tablet) {
    height: setUnit(44);
    width: setUnit(44);
  }

  svg {
    width: setUnit(20);
    height: setUnit(20);
  }

  &:hover {
    border: 2px solid $text--light-gray-color;
  }
}

.selected {
  background-color: $background-blue;
}
