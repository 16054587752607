@import 'styles/functions';
@import 'styles/variables';

.popoverAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $text-color;
  margin: 0px 0px 0px 8px !important;
}

.popoverAvatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: setUnit(40);
  [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .userNavigationMenu {
    width: 284px;
    border-radius: 5px;
  }
}

.avatarRow {
  display: flex;
  align-items: center;
  padding: 16px;
}

.helpAndSupport {
  margin-bottom: 8px !important;
  border-bottom: 1px solid $light-gray;
}

.userNavigationRow {
  li {
    padding: 0px !important;
    .iconColumn {
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
      }
    }
    .link {
      padding: 17px !important;

      &:not(.icon) {
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }
    }
  }
}

.userNavigationMenu {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    .iconColumn {
      display: flex;
      .icon {
        margin-right: 13px;
        opacity: 0.5;
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .link {
      &:not(.icon) {
        color: $text-color !important;
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
      }

      padding: 12px 18px;
      width: 100%;
    }

    &:hover {
      background-color: $hover-light-gray;
      .iconColumn {
        .icon {
          opacity: 1;
        }
      }
    }
  }
}
