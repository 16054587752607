@import 'styles/mixins';
.inputWrapper {
  @include inputFull;

  &.light {
    margin: 0;
    position: relative;
    width: 100%;
    @include inputLight;

    label {
      font-size: setUnit(12);
      line-height: setUnit(16);
      color: $text-gray-color;
      font-weight: 400;
      margin-bottom: setUnit(8);
    }

    .error {
      color: $error-color;
      font-size: setUnit(12);
      padding-top: setUnit(8);
      display: flex;
      align-items: center;
      &::before {
        content: '\0021';
        font-weight: bold;
        color: $white;
        width: 1.2em;
        height: 1.2em;
        background: $error-color;
        padding: 0.2em;
        border-radius: 100%;
        font-size: 0.8em;
        margin-right: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &::after {
        content: '\00D7';
        font-size: 14px;
        color: $white;
        width: 18px;
        height: 18px;
        background: $error-color;
        top: 14px;
        right: 0;
        position: absolute;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
      }
    }
  }

  .inputField {
    resize: none;
  }

  .error {
    @include inputError;
  }
}
.editableDiv {
  border: none !important;
  font-weight: 400;
  font-size: setUnit(14);
  line-height: setUnit(16);
  font-family: $font-family;
  letter-spacing: setUnit(-0.18);
  outline: none;
  margin: 0;
  width: 100%;
  vertical-align: middle;
  color: #262626;
  font-size: 1.125rem;
  font-weight: 400;
}
.placeholder {
  color: $text--light-gray-color;
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(18);
  letter-spacing: -0.18px;
}
