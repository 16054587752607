@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  .clientRole {
    input {
      @include lightRounded;
      @media screen and (max-width: 767px) {
        width: setUnit(140);
        min-width: setUnit(140);
      }
    }
    .inputWrapper {
      margin: 0;
    }
  }
  .arrowdownicon {
    right: 0.75rem !important;
    path {
      stroke: $text-gray-color !important;
    }
  }

  .checkbox {
    margin-top: setUnit(16);
    .label {
      font-size: setUnit(16);
    }
  }
}
