@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.formLinkContainer {
  margin: setUnit(32) 0;
  margin-right: setUnit(32);

  [class*='fieldContainer'] {
    width: 100%;
  }

  .fieldContainerWrapper {
    display: flex;
    gap: setUnit(16);
    justify-content: space-between;

    .content {
      display: flex;
      gap: setUnit(16);
    }
    svg {
      min-width: 32px;
    }
    [class*='fieldLabel'] {
      margin-bottom: 5px !important;
    }
    .options {
      [class*='title'] {
        width: 32px !important;
        height: 32px !important;
        border-radius: 50% !important;
      }
    }
  }

  .multipleSelectInput {
    .listBoxInput {
      width: 100%;
      max-width: 380px;
      margin-top: setUnit(7);
      [class~='ant-select-selector'] {
        padding-left: 4px !important;
        [class~='ant-select-selection-search'] {
          left: 8px;
          margin-inline-start: 3px;
        }
      }

      [class~='ant-select-selection-placeholder'] {
        left: 12px;
      }
    }
    .transactionListInput {
      [class~='ant-select-selector'] {
        padding-left: 8px !important;
      }
      [class~='ant-select-selection-placeholder'] {
        left: 8px !important;
      }
    }
    .buttonsContainer {
      margin: setUnit(20) 0;
      display: flex;
      gap: 12px;
      button {
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  .marginTop {
    margin-top: setUnit(16);
  }

  .unlinkFormDialog {
    .content {
      text-align: center;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 650px) {
    margin-right: 0px;
    margin-bottom: 1rem;
  }
}

.transactionOptionDropdown {
  border-radius: 12px;

  :is([class~='ant-select-item-option-active'], [class~='ant-select-item-option-selected']) {
    background-color: #f5f5f5 !important;

    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }

  [class~='rc-virtual-list-holder'] {
    overflow-y: auto !important;
  }
}

.transactionOption {
  padding-top: 13px;
  padding-bottom: 0px;
  padding-left: 20px;

  [class~='ant-select-item-option-content'] {
    padding-bottom: 14px;
    border-bottom: 1px solid $background-ligh-gray-4;
  }

  .transactionOptionStatus {
    background-color: $background-grey-2;
    color: $dark-color;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.18px;
    text-align: left;
    border-radius: 100px;

    padding: 6px 7px;
    width: fit-content;

    text-transform: capitalize;
    margin-top: 3px;
  }

  &:hover {
    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }
}
