@import 'styles/variables';

.headerRow {
  background-color: transparent;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 16px;

  width: 100%;
  height: 80px;
  padding: 0px;

  &.sticky {
    background-color: $white;
    transition: $transition;
    z-index: 2;

    .headerContentRow {
      background-color: $white;
    }
  }

  .headerContentRow {
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 32px 15px 30px;
    height: 80px;
    right: 8px;
    flex-wrap: nowrap;
    top: 80px;

    &.showBorder {
      box-shadow: 0px 1px 3px 1px rgba(163, 163, 163, 0.3);
    }

    .formPill {
      @media (min-width: $tablet) {
        margin: 0 auto;
      }

      > div {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .navigationIcons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      z-index: 5;

      .navigationButton {
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        border: 2px solid $light-gray;
        z-index: 5;

        &:hover {
          cursor: pointer;
          background-color: $light-gray;
        }
      }
    }

    &.isCompleted {
      background-color: $secondary-color-hover !important;
      height: 64px;
    }

    .previewTitle {
      font-family: $font-family;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      color: $white;
    }

    .editForm {
      display: flex;
      width: 95px;
      align-items: center;
      height: 44px;
      justify-content: center;
      z-index: 5;
      border-radius: 100px;
      background-color: $white;
      border: 2px solid $light-gray;

      svg {
        margin-right: 5px;
      }
      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }

    .completedRightHeader {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media screen and (min-width: 401px) and (max-width: 475px) {
      padding: 15px 30px 15px 20px;

      .editForm {
        height: 40px;
      }

      .navigationIcons {
        .navigationButton {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }

    @media screen and (max-width: 400px) {
      padding: 15px 30px 15px 20px;

      .editForm {
        height: 40px;
        width: 80px;
      }

      .navigationIcons {
        .navigationButton {
          width: 38px !important;
          height: 38px !important;
        }
      }

      .previewTitle {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 350px) {
      .previewTitle {
        font-size: 16px;
      }
    }
  }

  .fullWidthHeader {
    right: 0px;
    padding-right: 40px;
  }

  .webViewHeaderContentRow {
    top: 0px;
  }

  .documentPopoverColumn {
    flex-grow: 1;
  }
}

.completedHeader {
  height: 64px;
}

.closeIcon {
  cursor: pointer;
  line-height: 0px;
  svg {
    width: 34px;
    height: 34px;
    vertical-align: middle;

    &:hover {
      transform: scale(1.1);
    }
  }
}
