@import 'styles/variables';

.ServiceBoxContainer {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin-bottom: 16px;
  justify-content: space-between;

  &.isChecked {
    background-color: $primary-color-light;
    border-color: $primary-color;
  }

  .leftBlock {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: 10px;

    .serviceCheckbox {
      margin: 10px 12px 0 20px;
    }

    .serviceTitle {
      font-weight: 400;
      line-height: 24px !important;
      letter-spacing: -0.09px;
      color: $dark-color;
      font-size: 18px;
      font-family: Inter;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0;

      &.isChecked {
        font-weight: 500;
      }
    }
  }

  .closeIcon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
  }
}

.serviceBoxToolTip {
  [class*='ant-tooltip-inner'] {
    width: setUnit(375);
    padding: setUnit(20);
    border-radius: 1rem;
    background-color: $text-color;
  }
}
