.add {
  height: 44px;
  width: fit-content;
  padding: 12px 16px;
  border-radius: 100px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 4px;
  }
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1360px;
  td {
    cursor: pointer;
  }
  [class~='ant-table'] {
    border-radius: 16px;
    [class='ant-table-thead'] {
      & > tr > th {
        background: white !important;
      }
    }

    [class='ant-table-container'] table {
      & > thead > tr:first-child th:first-child {
        border-top-left-radius: 16px;
      }

      & > thead > tr:first-child th:last-child {
        border-top-right-radius: 16px;
      }
    }

    [class='ant-table-tbody'] {
      tr:last-child {
        &:hover {
          td:first-child {
            border-bottom-left-radius: 16px !important;
          }
          td:last-child {
            border-bottom-right-radius: 16px !important;
          }
        }
      }
    }
  }

  .tableWrapperHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-bottom: 16px;

    .heading {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.33px;
      &.cursor {
        cursor: pointer;
      }

      span {
        color: var(--Grayscale-40, #aaabab);
      }
    }

    .newBtn {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.33px;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 12px;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .tableCol {
    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.18px;

    [class='ant-table-column-sorters'] {
      justify-content: flex-start;
      [class='ant-table-column-title'] {
        flex: none;
      }
    }
  }

  .toggleWrapper {
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.tableNormalCell {
  color: var(--Grayscale-100, #262626);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.33px;
  [class='ant-table-column-sorters'] {
    justify-content: flex-start;
    [class='ant-table-column-title'] {
      flex: none;
    }
  }
}
