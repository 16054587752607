@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  margin-right: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 52px;
  width: 52px;
  svg {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.content {
  width: 100%;
  max-width: 1340px;
}

.documents {
  height: calc(100dvh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      margin: 0;
      color: $text-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20.8px; /* 104% */
      letter-spacing: -0.18px;
      span {
        color: $text--light-gray-color;
      }
    }
  }

  .noData {
    margin-top: 16px;
    display: flex;
    width: 100%;
    height: 275px;
    min-height: 275px;
    padding-bottom: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid $background-grey-2;
    background: $white;
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
    color: $text--light-gray-color;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.33px;
  }

  .documentsCollapse {
    margin-top: 26px;
    background-color: $white;
    border-radius: 16px !important;
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03) !important;
    border: none !important;
    padding: 20px 32px !important;

    * {
      font-family: $font-family;
    }

    div[class='ant-collapse-item'] {
      div[class='ant-collapse-expand-icon'] {
        .expandIconWrap {
          svg {
            transform: rotate(0deg);
            @include transition;
          }
        }
      }
    }

    div[class='ant-collapse-item ant-collapse-item-active'] {
      div[class='ant-collapse-expand-icon'] {
        .expandIconWrap {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    div[class*='ant-collapse-item'] {
      &:last-child {
        border-bottom: 0;
      }

      div[class='ant-collapse-expand-icon'] {
        .expandIconWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: setUnit(32);
          height: setUnit(32);
          border-radius: setUnit(100);
          background-color: $background-grey-2;
          right: 0;
        }
      }

      div[class='ant-collapse-header'] {
        padding: setUnit(24) 0;

        .headerLeft {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          svg {
            margin-right: setUnit(8);
          }

          h3 {
            font-weight: 600;
            font-size: setUnit(16);
            line-height: setUnit(20);
            letter-spacing: -0.18px;
            margin-bottom: 0;
            color: $text-color;
            span {
              color: $text--light-gray-color;
            }
          }
        }
      }
    }

    div[class*='ant-collapse-content'] {
      border-top: 0;

      div[class='ant-collapse-content-box'] {
        padding: 0;
      }
    }
  }

  .documentsContainer {
    width: 100%;
    background-color: $white;
    margin-top: setUnit(16);
    border-radius: setUnit(12);
    padding: setUnit(24);

    .documentTypeHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem;
      border-radius: 12px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &:hover {
        background-color: $background-ligh-gray-3;
      }

      .leftSide {
        display: flex;
        align-items: center;
        svg {
          margin-right: setUnit(8);
        }

        h3 {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          margin-bottom: 0;
          color: $text-color;
          span {
            color: $text--light-gray-color;
          }
        }
      }

      .arrowRight {
        svg {
          height: 26px;
          width: 26px;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: $background-ligh-gray-4;
      margin-bottom: 1rem;
    }
  }
}
