@import 'styles/variables';

.wrapper {
  .partnerTitle {
    margin-bottom: 20px;
  }
  .partnerSubheader {
    max-width: 400px;
    color: $text-color;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.09px;
    margin: 0 auto 40px auto;
  }
  .partnerHeading {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 12px;

    span {
      color: $text--light-gray-color;
    }
  }
  .partnerFormInput {
    label {
      color: $text-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;
      margin-bottom: 12px;
    }

    input {
      border-radius: 8px !important;
      border: 1px solid $light-grey-color !important;
      padding: 14px 16px !important;
      width: 100% !important;

      &::placeholder {
        color: $text-gray-color !important;
        font-feature-settings: 'clig' off, 'liga' off !important;
        /* Mobile/Desktop/Body/Regular */
        font-family: Inter !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20.8px !important; /* 130% */
        letter-spacing: -0.18px !important;
      }

      &:focus {
        border-bottom-color: $light-grey-color !important;
      }
    }
  }
}
