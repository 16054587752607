@import 'styles/functions';
@import 'styles/variables';

@mixin quoteHeader() {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: setUnit(20) setUnit(15);
  @media (min-width: $tablet) {
    height: $transaction-creation-header-height;
    padding: setUnit(20) setUnit(40);
  }
}

.head {
  @include quoteHeader;
  flex-wrap: wrap;
  .headerLeft {
    order: 2;
    @media (min-width: $tablet) {
      order: 1;
      flex: 1;
    }
    .goBackButton {
      height: setUnit(44) !important;
      border: 2px solid $background-ligh-gray-4 !important;
      padding: 0 setUnit(8) !important;
      &:hover {
        background-color: $background-ligh-gray !important;
      }
    }
  }

  .quotesTitle {
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: setUnit(16);
    @media (min-width: $tablet) {
      order: 2;
      flex: 1;
      margin-bottom: 0;
    }
  }

  .quotesActions {
    order: 3;
    flex: 1;
  }

  .meta {
    display: flex;
    align-items: center;
  }

  @media (max-width: $tablet) {
    .headerLeft {
      width: auto;
    }
  }
}

.wrapper {
  height: unset;
  min-height: unset;
  .contentContainer {
    width: auto;
    max-width: initial;
    margin: 0;
    padding: setUnit(40) setUnit(0);
    overflow: auto;
    background-color: $background-ligh-gray-3;
    @media (min-width: $tablet) {
      height: calc(
        100vh - $header-height - $footer-height - $transaction-creation-header-height - 2px
      );
    }
  }
}

.footer {
  min-width: 0;
  width: 90%;
}

.contentWrapper {
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .contentWrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .contentWrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .contentWrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .contentWrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .contentWrapper {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .contentWrapper {
    max-width: 720px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .contentWrapper {
    max-width: 960px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .contentWrapper {
    max-width: 1140px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .contentWrapper {
    max-width: 1340px;
    width: 100%;
  }
}
