@import 'styles/functions';
@import 'styles/variables';

.info {
  .featureWrapper {
    display: flex;
    align-items: center;
    font-size: setUnit(14);
    font-weight: 400;
    margin: 0.25rem 0 0.5rem;

    .accent {
      font-weight: 600;
    }

    .amount {
      display: inline-block;
      margin-right: 0.25rem;
    }

    .separator {
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      margin: 0 0.5rem;
      border-radius: 50%;
      background: $dark-color;
    }
  }
}
