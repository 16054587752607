$desktop: 1200px;
$responsive-menu: 768px;
$responsive-search: 1400px;
$responsive-avatar: 1040px;
$tablet-horizontal: 1024px;
$tablet: 992px;
$mobile: 768px;
$mobile-small: 565px;

$black: #000;
$white: #fff;

$black-light: #231f20;

$dark-color: #515151;
$light-black: #7a7a7a;
$secondary-color: #252d44;
$primary-color: #ff576d;
$primary-color-lighter: #ff576d14;
$primary-slightly-light: #ffc0c8;
$primary-color-light: #fff2f3;
$grey-color: #e8ecef;
$light-gray: #e8e9ea;
$dark-gray: #f3f4f4;
$dark-gray-2: #3d3d3d;
$dark-gray-3: #303030;
$light-gray-2: #747474;
$dark-grey-color: #676767;
$light-grey-color: #d6d6d7;
$text-gray-color: #747475;
$text--light-gray-color: #aaabab;
$green: #219653;
$tertiary-50: #f6f7f7;
$tertiary-50-hover: #eef0ef;
$radio-red: #ff2e18;

$tertiary-grey: #e3e4e5;
$yellow-color: #ffe15c;
$warning-color: #fff0ae;

$error-color: #ec455e;

$link-blue: #2b59da;
$link-color: #17507d;

$background-ligh-gray: #f6f7f7;
$background-ligh-gray-2: #fbfbfb;
$background-ligh-gray-3: #f8f9fa;
$background-ligh-gray-4: #e8e9ea;
$background-ligh-gray-5: #fdfdfd;
$background-gray: #f4f6fb;
$background-blue: #252d44;
$background-grey-2: #f4f5f6;

$hover-light-gray: #f6f7f7;
$hover-blue: #1d4f76;
$hover-peach: #ff8670;
$hover-red: #ec455e;
$hover-green: #00b152;
$hover-dark-green: #b6ff60;

$border-focused: #717172;
$border-middle-gray: #adadad;
$border-light-gray: #e8ecef;
$border-light-gray-2: #d6d6d7;
$border-light-gray-3: #d6d6d7;
$border-light-gray-3: #d9d9d9;
$border-input-middle-gray: #d6d6d7;
$border-light-green: #406464;
$border-blue: #1f4b6e;
$green-color: #04a451;
$text-color: #262626;
$text-color-middle: #333333;
$text-color-light: #545556;

$font-family: 'Inter', sans-serif;

$header-height: 80px;
// $footer-height: 80px;
$footer-height: 64px;
$responsive-footer-height: 80px;
$workshop-header-height: 108px;
$onboarding-header-height: 110px;
$transaction-creation-header-height: 92px;

$primary-color-hover: #ed4f64;
$secondary-color-hover: #181d2c;

$blue: #2246aa;
$light-blue: #51bfe1;
$light-blue-lighter: #51bfe114;
$light-blue-2: #468bff;
$purple: #928cda;
$purple-lighter: #928cda14;
$orange: #fb913a;
$orange-lighter: #fb913a14;
$orange-2: #e28234;
$alert: #dc7826;
$confetti-blue: #4673d1;
$confetti-blue-lighter: #4673d114;
$green-2: #00b070;

$silver-badge: #eaebec;

$transition: 0.3s ease all;
$light-green: #46d182;

$box-shadow-1: #3030301a;

$forms-footer-height: 100px;

$success-color: #04a451;
$dark-warning-color: #ffe019;
