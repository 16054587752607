@import 'styles/mixins';
@import 'styles/variables';

$active-border-color: $primary-color;

.wrapper {
  width: 100%;
  margin: setUnit(64) auto 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .option {
      $border-radius: setUnit(16);

      width: setUnit(160);
      height: setUnit(160);
      margin-right: setUnit(16);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-middle-gray;
      border-radius: setUnit(8);
      padding: setUnit(15);
      cursor: pointer;

      &:nth-child(1n + 3) {
        margin-right: 0;
      }

      .optionInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          font-weight: 400;
          font-size: setUnit(14);
          color: $dark-color;
        }

        .icon {
          margin-bottom: setUnit(20);
        }

        svg {
          vertical-align: middle;
        }
      }

      &.active {
        background: #fff4f3;
        border-color: $active-border-color;

        svg {
          vertical-align: middle;

          path {
            stroke: $active-border-color;
          }
        }
      }
    }
  }
}
