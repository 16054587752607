@import 'styles/functions';
@import 'styles/variables';

.kitSwitcher {
  background-color: $white;
  padding: 4px 10px 4px 12px;
  transition: $transition;
  border: 2px solid $background-ligh-gray-4;
  border-radius: 100px;
  height: 44px;
  width: 120px;
  padding-top: 5px !important;
  padding-left: 10px !important;
  font-weight: 600 !important;
  font-size: setUnit(14) !important;
  line-height: setUnit(20) !important;
  font-family: $font-family;
  letter-spacing: setUnit(-0.09) !important;
  color: $text-color !important;

  &:hover {
    border: 2px solid $text--light-gray-color;
  }

  &.ant-select-focused {
    .ant-select-selector {
      .ant-select-selection-item {
        color: $text-color;
      }
    }
  }

  .ant-select-selector {
    padding: 0 20px 0 0 !important;
    height: auto !important;
    width: auto !important;
    font-size: 32px;

    @media (max-width: 991px) {
      font-size: 20px;
    }

    .ant-select-selection-item {
      font-weight: bold;
      line-height: 38px !important;
      padding-right: 0 !important;
    }
  }

  .ant-select-arrow {
    transition: $transition;

    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  .kitSwitcherDropdown {
    min-width: 150px !important;
    border-radius: 8px !important;

    .ant-select-item {
      padding: 12px 24px;
      background-color: $white;
      border-color: $white;
      text-align: left;
      align-items: center;

      &:hover {
        background-color: $background-grey-2 !important;

        .ant-select-item-option-content {
          color: $text-color;
        }
      }

      .ant-select-item-option-content {
        padding: 0;
        font-weight: 600 !important;
        font-size: 22px !important;
        line-height: 28px !important;
        background-color: transparent;
        color: $dark-color;
      }

      .ant-select-item-option-state {
        display: none;
      }
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }
}
