@import 'styles/mixins';
@import 'styles/variables';

.uploadDragger {
  background-color: $background-grey-2 !important;
  border: 2px dashed $light-grey-color !important;

  [class~='ant-upload'] {
    padding: 24px 0px 28px !important;
  }

  &:hover {
    border-color: $light-grey-color !important;
  }
}

.uploadContainer {
  border-radius: 8px;

  [class*='ant-upload'] {
    border-radius: 8px !important;
  }

  .uploadFileIcon {
    margin-bottom: 12px;
  }

  .uploadButton {
    border-radius: 0.5rem;
    padding: 0.25rem;
    width: 8.875rem;
    height: 3.25rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 2px solid $secondary-color;
    background: $white;
    cursor: pointer;
  }

  .uploadText {
    font-weight: 600;
    color: $secondary-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 1rem;
    line-height: 1.5rem;
    /* 150% */
    letter-spacing: -0.00563rem;
  }

  .uploadHint,
  .uploadSecondHint {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $dark-grey-color;
  }

  .uploadSecondHint {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.uploadedFilesContainer {
  margin-bottom: 20px;
}

.fileBox {
  width: 100%;
  border: 2px solid $light-gray;
  height: 72px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 16px;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .fileNameBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    .fileIcon {
      margin-right: 12px;
    }
  }

  .deleteIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .fileName {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    width: 90%;
  }
}

.invalidFile {
  border: 1px solid $error-color;
}
