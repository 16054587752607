@import 'styles/functions';
@import 'styles/variables';

.headerBlock {
  width: 100%;
  position: absolute;
  left: 0;
  border-bottom: setUnit(2) solid #e8ecef;
  height: setUnit(160);
  display: flex;
  align-items: center;
  justify-content: center;

  .headerBlockAvatar {
    width: setUnit(80);
    height: setUnit(80);
    margin-right: setUnit(24);
    border-radius: 100%;
    overflow: hidden;

    img {
      width: setUnit(80);
      height: setUnit(80);
      object-fit: cover;
    }
  }

  .headerBlockTitleName {
    font-weight: 700;
    font-size: setUnit(30);
    line-height: setUnit(36);
    color: $text-color;
  }

  .headerBlockTitleCategory {
    font-size: setUnit(16);
    line-height: setUnit(24);
    margin-top: setUnit(2);
    color: $text-gray-color;
  }
}

.content {
  padding-top: setUnit(200);
  display: flex;

  .contentLeft {
    flex: 2;
    color: $text-color;
    margin-right: setUnit(30);
    font-size: setUnit(16);
    line-height: setUnit(24);

    .contentTitle {
      margin-bottom: setUnit(24);
      line-height: setUnit(21);
      font-weight: 700;
    }
  }

  .contentRight {
    margin-left: setUnit(30);
    flex: 1;
  }
}

.recommendedBlock {
  background: $primary-color-light;
  border-radius: setUnit(8);
  padding: setUnit(16);
  display: flex;
  align-items: center;
  margin-bottom: setUnit(30);

  .recommendedBlockLeft {
    margin-right: setUnit(12);
    width: setUnit(24);
    height: setUnit(24);

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  .recommendedBlockRight {
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $text-color;

    b::after:not(:last-child) {
      content: ', ';
    }
  }
}

.addressLine {
  margin-bottom: setUnit(30);
}

.mapWrapper {
  margin-bottom: setUnit(30);
  border-radius: setUnit(8);
  overflow: hidden;
  position: relative;
  height: setUnit(164);

  .map {
    height: setUnit(164);

    .mapControls {
      margin: setUnit(18);
    }
  }
}
