@import 'styles/variables';
@import 'styles/functions';

.offersPage {
  background-color: $background-ligh-gray-3;
  padding: 0px setUnit(40) setUnit(20);

  .offersWrapper {
    padding-bottom: setUnit(20);
  }
}
