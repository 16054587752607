@import 'styles/functions';
@import 'styles/variables';

.circleBackground {
  fill: none;
}

.circleBackground {
  stroke: $light-gray;
}

.greenProgress {
  fill: none;
  stroke: $green;
}

.orangeProgress {
  fill: none;
  stroke: $orange-2;
}

.grayProgress {
  fill: none;
  stroke: $border-middle-gray;
}

.progressText {
  font-size: setUnit(20);
  color: $text-color;
}
