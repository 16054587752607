@import 'styles/functions';
@import 'styles/variables';

.inputWrapper {
  position: relative;
  &.light {
    border: none;
    .input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 16px;
      border-bottom: 2px solid $border-input-middle-gray;
      padding: 12px 16px 16px 0;
      transition: $transition;
      &::placeholder {
        color: $text-gray-color;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.09px;
      }
      &:focus {
        border-bottom-color: $text-gray-color;
      }
    }
    .loader {
      font-size: 2px;
      margin: 0;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &.full {
    .input {
      width: 100%;
      border: none;
      outline: none;
      padding: 12px 32px 12px 16px;
      border-radius: 8px;
      height: 56px;
    }
    .loader {
      font-size: 2px;
      margin: 0;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &.lightFull {
    .input {
      padding: setUnit(12) 0 setUnit(16);
      outline: none;
      margin: 0;
      width: 100%;
      vertical-align: middle;
      color: $text-color;
      font-size: setUnit(18);
      font-weight: 400;
      line-height: setUnit(24);
      letter-spacing: -0.09px;
      height: setUnit(52);
      border: 0;
      border-bottom: 2px solid $border-input-middle-gray;
      transition: $transition;
      &:focus {
        border-bottom-color: $text-gray-color;
      }
      &::placeholder {
        color: $text--light-gray-color;
        font-weight: 400;
        font-size: setUnit(16);
      }
    }
    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: setUnit(20);
      right: setUnit(20);
    }
  }

  &.lightRound {
    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: 60px;
      right: 24px;
    }
  }
}
