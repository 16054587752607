@import 'styles/functions';
@import 'styles/variables';

.content {
  width: 100%;
  @media (min-width: $desktop) {
    width: calc(100% - 281px);
  }
  height: calc(100vh - $header-height);
  overflow: auto;

  .pendingWrapper {
    min-height: 100%;

    &.pending {
      height: 100%;
      min-height: auto;
    }
  }
}
