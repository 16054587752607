@import 'styles/functions';
@import 'styles/variables';

.baseQuoteDetails {
  min-height: 622px !important;
  height: 622px !important;
  width: 675px !important;

  & [class*='ant-modal-content'] {
    padding: 4rem 2rem 2.5rem;
  }

  & [class*='ant-modal-header'] {
    margin-top: 2rem;
  }

  .quoteDetailsInner {
    max-height: 415px;
    overflow-y: auto;
    padding: 0 0.5rem;
  }

  .quoteDetailsBottom {
    border-top: 1px solid $grey-color;
    padding-top: 1.5rem;
    margin: 0 0.5rem;

    .quoteDetailsUpload {
      label {
        height: 52px;
        font-weight: 600;
        font-size: 16px;
        color: $white;
        background: $background-blue;
        border: none;
        border-radius: 0.5rem;
        margin-left: 0;

        &:hover {
          background: $secondary-color-hover;
        }
      }
    }

    .quoteDetailsUploadIcon {
      display: flex;

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}

.quoteDetailsTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;

  & [class*='ant-typography'] {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
  }
}
