@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.showingLink {
  padding: 1.25rem setUnit(32) 0 0;
  max-height: calc(100vh - #{setUnit(432)});
  overflow-y: auto;
  @include customScrollbar;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }
  .title {
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    color: $text-color;

    .timeIcon {
      background-color: $background-grey-2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(32);
      height: setUnit(32);
      border-radius: setUnit(50);
    }
  }

  .info {
    font-weight: 400;
    color: $text-gray-color;
  }
  .content {
    margin-top: setUnit(32);
    .infoWrapper {
      margin-top: setUnit(12);
      padding: setUnit(15);
      border: 1px solid $light-gray;
      border-radius: 8px;
      p {
        color: $dark-grey-color;
        font-size: setUnit(14);
        margin: 0 0 0 10px;
      }
    }
    .checkboxesRow {
      margin-top: setUnit(32);
      padding: setUnit(24);
      border: 1px solid $light-gray;
      border-radius: 8px;

      .container {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: setUnit(24);
        }
        .startDate {
          border: 0;
          border-bottom: 1px solid $border-input-middle-gray;
          padding: 0.25rem 0.25rem 0.25rem 0;
          width: 94px;
          margin-left: 12px;
          input {
            font-weight: 400;
            font-size: setUnit(16);
            line-height: setUnit(20);
            letter-spacing: -0.18px;
            color: $text-color;
            &::placeholder {
              color: $text-gray-color;
            }
          }
          @include customDatepicker;
          &[class*='ant-picker-focused'] {
            border-bottom-color: $text-gray-color;
          }
        }
        .static {
          margin: 0 0.5rem;
        }
        .activeDays {
          input {
            font-weight: 400;
            font-size: setUnit(16);
            line-height: setUnit(20);
            letter-spacing: -0.18px;
            color: $text-color;
            &::placeholder {
              color: $text-gray-color;
            }
          }
        }
        .indefinite {
          [class~='ant-checkbox'] {
            & + span {
              color: $text-gray-color;
              transition: $transition;
            }
          }
          [class~='ant-checkbox-checked'] {
            & + span {
              color: $text-color;
            }
          }
        }

        [class*='ant-input-number'] {
          border: 0;
          border-radius: 0;
          width: 50px;

          input {
            border-bottom: 1px solid $border-input-middle-gray;
            &::placeholder {
              font-weight: 400;
              color: $text-gray-color;
            }
          }

          [class*='ant-input-number-handler-wrap'] {
            display: none;
          }
        }
      }
    }

    .container {
      display: flex;
      align-items: center;
      .rangePicker {
        border: 0;
        padding: 0.25rem 0.25rem 0.25rem 0;
        width: 232px;
        margin-left: 12px;
        @include customRangePicker;
        input {
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          color: $text-color;
          &::placeholder {
            color: $text-gray-color;
          }
        }
        [class*='ant-picker-input'] {
          padding: 0.25rem 0.25rem 0.25rem 0;
          border-bottom: 1px solid $border-input-middle-gray;
        }
      }
    }

    [class*='ant-picker-focused'],
    [class*='ant-input-number-focused'] {
      box-shadow: none;
    }

    [class*='ant-picker-suffix'],
    [class*='ant-picker-clear'],
    [class*='ant-picker-active-bar'] {
      display: none;
    }
  }
}
