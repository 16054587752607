@import 'styles/mixins';
@import 'styles/variables';

.formWrapper {
  width: min(100%, 600px);
  position: relative;

  .locations {
    box-sizing: border-box;
    font-weight: normal;
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-bottom: setUnit(15);
    @media (min-width: $mobile) {
      margin-bottom: 0;
    }

    .placeholder {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .searchWrapper {
      display: flex;
      align-items: center;
    }

    .locationsInput {
      input {
        height: initial !important;
        border: transparent;
        border-radius: 0 !important;
        padding: 0 !important;
        padding-left: 38px !important;
        margin-left: 1px;
      }
    }
    .locationsValues {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      border: 1px solid #fff;
      height: setUnit(56);
      & > div + div {
        border: 0;
      }

      .locationsValue {
        margin-left: 6px !important;
      }
    }

    .locationsPopup {
      .locationsDropdown {
        margin-top: setUnit(8);
        border-radius: setUnit(8);
        border: none;
        left: 0;
      }
      input {
        border-radius: setUnit(100);
        height: setUnit(56);
      }
    }
    .locationsIcon {
      position: absolute;
      top: 1rem;
      left: 1.25rem;
    }
  }
  .subText {
    margin-top: 42px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    .wrapper {
      display: flex;
      align-items: center;
      .mainText {
        color: $dark-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
      }
      .mutedText {
        color: $text--light-gray-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
    }
  }

  .locationsValuesContainer {
  }

  .savedSearchesWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 40px 0px;
    border-radius: 16px;
    background: $white;
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.06);
    max-width: 600px;
    max-height: 390px;
    width: 100%;
    row-gap: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 32px;

      div:first-child {
        color: $text-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
        letter-spacing: -0.09px;
      }

      div:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0;
        cursor: pointer;
      }
    }
    .pillsContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;
      padding-inline: 32px;

      .pill {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1.5px solid $light-grey-color;
        background: var(--White, #fff);
        color: $text-color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        cursor: pointer;

        /* Mobile/Desktop/Caption/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;

        &.active {
          border: 1.5px solid $primary-color;
          background: $primary-color-light;
        }
      }
    }
    .itemsContainer {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      overflow-y: scroll;
      padding-inline: 32px;

      .CardsContainer {
        width: 100%; // Ensure the container is full width
        border-radius: 8px;
        justify-content: space-between;

        .infoDesc {
          overflow: hidden;
        }
      }
    }
    .itemsContainer::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Webkit browsers (Chrome, Safari) */
    }
  }
  .suffixIcon {
    position: absolute;
    top: 50%;
    right: 6px !important;
    transform: translateY(-50%);
    z-index: 1000;
    & > button {
      border-radius: 10px !important;
      height: 44px;
      width: 44px;
    }
  }
}

.loader {
  right: 4.25rem !important;
  top: initial !important;
}
