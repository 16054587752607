@import 'styles/functions';
@import 'styles/variables';

.offerComments {
  padding-right: 30px;

  .holder {
    position: relative;
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .pendingWrapper {
    height: 100%;

    .loader {
      font-size: setUnit(5);
      margin: setUnit(75) auto;
    }
  }

  .CommentsViewWrapper {
    height: 11rem;
    overflow-y: scroll;

    .offerCommentsMessage {
      padding-right: 0.2rem;

      .offerCommentsMessageHolder {
        padding-right: 1.5rem;

        .offerCommentMessageSender {
          white-space: nowrap;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .offerMessageText {
          div {
            font-size: 14px;
            padding-right: 0.8rem;
          }
        }
      }
    }
  }

  .messages,
  .pendingWrapper {
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $border-light-gray-2;
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10.7rem;
    .message {
      font-size: setUnit(16);
      font-weight: 100;
      color: $text--light-gray-color;
    }
  }

  .commentsForm {
    .footer {
      max-width: 100%;
      padding: 1rem 0 0 0 !important;
      background: none;
      position: relative;
      align-items: flex-start;
      .footerInner {
        padding: 0 !important;
        height: 0px !important;
      }

      .inputGroup {
        max-width: 100% !important;
        & > div {
          padding-left: 0;
          padding-right: 0;
          & > div {
            height: auto;
            .editableContentWrapper {
              width: 100%;

              .commentPlaceholder {
                padding-top: 8px !important;
              }

              p {
                font-size: 14px;
                margin-bottom: 5px !important;
                max-height: 50px;
                min-height: 50px;
                overflow: auto;
                padding: 6px 0px 0px !important;
                > br {
                  display: none;
                }

                @media (max-width: 768px) {
                  width: 19rem !important;
                  min-width: 19rem !important;
                }
              }
            }
          }
        }
      }
    }
    .footer::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 0.5px;
      background-color: $light-gray;
      box-sizing: border-box;
    }
  }

  .sendButton {
    background: none !important;
    bottom: 0;
    align-items: flex-end !important;
    svg {
      path {
        stroke: $dark-grey-color !important;
      }
    }
  }
}
