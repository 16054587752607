@import 'styles/functions';
@import 'styles/variables';

.image {
  border-radius: setUnit(16);
  width: setUnit(305);
  background: darken($background-ligh-gray, 10);
  position: relative;

  .imagePendingWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: setUnit(180);
    border-radius: setUnit(16);
    object-fit: cover;
    vertical-align: middle;
  }

  .imageLoader {
    color: darken($border-middle-gray, 20);
  }
}

// add media query for mobile screen size 375px
@media (max-width: 375px) {
  .image {
    width: setUnit(255);
  }
}
