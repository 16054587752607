@import 'styles/variables';

.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto;
  width: 640px;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
  }
}

.navigationFooter {
  display: flex;
  justify-content: right;
}

.formNavigation {
  position: absolute;
  bottom: 50px;
  right: 50px;

  display: flex;
  border: 1px solid $light-gray;
  border-radius: 8px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: 40px;
  width: 40px;
}

.navigationBtn {
  height: 40px;
  width: 40px;
  border-radius: 8px;

  :hover {
    background-color: $light-gray;
  }
}

.continueBtn {
  background-color: $white !important;
  border: none !important;
  display: flex !important;
  margin-top: 40px;
  width: 138px !important;
  height: 52px !important;
  justify-content: center;

  span {
    align-self: center;
  }

  div {
    align-self: center;
    margin-left: 13px;
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}
