.headerContainer {
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .header {
    color: var(--White, #fff);
    font-family: Sora, Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: -0.8px;
  }

  .subHeader {
    display: flex;
    align-items: center;
    column-gap: 4px;

    color: rgba(214, 214, 215, 0.8);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */

    .link {
      color: #b9d0ff;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
