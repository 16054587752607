@import 'styles/variables';
@import 'styles/functions';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  border-radius: 50%;
  width: setUnit(32);
  height: setUnit(32);
  background-color: $background-ligh-gray;
  color: $text-color-light;
  font-size: setUnit(14);
  font-weight: 600;
  margin-right: setUnit(4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $light-gray;
  }

  &.active {
    background-color: $secondary-color;
    color: $white;

    &:hover {
      background-color: $secondary-color;
    }
  }
}

.nextButton {
  margin-left: setUnit(12);
}

.prevButton {
  margin-right: setUnit(16);
}

.prevButton,
.nextButton {
  border-radius: 50%;
  width: setUnit(32);
  height: setUnit(32);
  background-color: transparent;
  border: 1px solid $text-color;
  color: $text-color;
  font-size: setUnit(14);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    border: 1px solid $light-grey-color;
    .controlIcon {
      svg {
        path {
          stroke: $light-grey-color;
        }
      }
    }
    &:hover {
      background-color: transparent;

      .controlIcon {
        svg {
          path {
            stroke: $light-grey-color;
          }
        }
      }
    }
  }

  .controlIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  &:hover {
    background-color: $text-color;

    .controlIcon {
      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}
