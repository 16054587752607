@import 'styles/functions';
@import 'styles/variables';

.propertyAddressInput {
  .unit {
    max-width: setUnit(280);
    margin-top: 54px;
    input {
      font-weight: 400;
      padding: 10px 14px !important;
    }
    @media (max-width: $mobile) {
      max-width: 100%;
    }

    .altLabel {
      color: $text--light-gray-color !important;
      font-weight: 500 !important;
      position: relative;
      left: -65px;
    }
  }

  .locationValues {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .locationsDropdown {
    top: setUnit(3);
  }

  .locationsSearch {
    input {
      padding: 10px 14px !important;
    }
  }
}
