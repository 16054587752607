@import 'styles/functions';
@import 'styles/variables';

.clientsFooter {
  height: setUnit(92);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: -1.5rem 0 0;

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $dark-color;
    margin: 0 0 1rem;
  }

  .actions {
    .btn {
      height: setUnit(52);
      border-radius: 8px;
    }

    .share {
      margin-right: 1rem;
      border: 2px solid $light-gray !important;

      &:hover {
        border-color: $border-light-gray-2 !important;
      }
    }
  }
}
