@import 'styles/functions';
@import 'styles/variables';

.iconImage {
  height: 48px;
  width: 48px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: top;
  overflow: hidden;

  .icon {
    height: 48px;
    width: 48px;
    border-radius: 100%;
  }
}

.detailsArea {
  display: inline-block;

  p {
    margin-bottom: 0.25rem;
  }
}

.title {
  font-weight: 600;
}

.subTitle {
  font-weight: 400;
  font-size: 14px;
}

.calendarLinks {
  font-size: 14px;
}

.link {
  color: $link-blue;
}

.link:not(:last-child):after {
  content: '-';
  padding-right: 8px;
  padding-left: 8px;
}
