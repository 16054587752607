@import 'styles/functions';
@import 'styles/variables';

.deadlineDetails {
  .headTitle {
    color: $text-gray-color;
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.18px;

    margin-bottom: 6px;
  }

  .date {
    font-size: setUnit(16);
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    margin-bottom: 2px;
  }

  .subTitle {
    font-size: setUnit(14);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.18px;
    margin-bottom: 0;
  }
}
