@import 'styles/functions';
@import 'styles/variables';

.gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  border-radius: setUnit(16);

  .gm-style-iw-d {
    padding: 0;
    overflow: hidden !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

.gm-style .gm-style-iw-t::after {
  top: -2px;
}
