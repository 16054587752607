@import 'styles/functions';
@import 'styles/variables';

.sortMenu {
  .sortIcon {
    cursor: pointer;

    &:hover {
      svg {
        rect {
          fill: $background-grey-2;
        }
      }
    }
  }
  .sortIconAlphabet {
    padding: 0.25rem 0.5rem;
    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background-color: $background-grey-2;
    }
  }
}

.sortPopup {
  .sortByTitle {
    font-size: 14px;
    font-weight: 600;
  }
  .ascDesc {
    margin-bottom: 12px;
  }
  .alphabetSortOption {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    margin-left: -8px;
    margin-right: -8px;
    border-radius: 8px;
    cursor: pointer;

    p {
      margin: 0;
    }
    .checkMark {
      margin-left: 1rem;
    }
    &:hover {
      background-color: $background-grey-2;
    }
  }
}
