@import 'styles/functions';
@import 'styles/variables';

.compareContent {
  height: 100%;

  .inner,
  .header {
    padding-left: setUnit(32);
    padding-right: setUnit(32);
  }

  .inner {
    max-height: calc(100vh - #{setUnit(113)});
    overflow: auto;
    margin-top: setUnit(32);
  }
}
