@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../styles.module';

.controls {
  width: auto !important;
  position: absolute;
  top: -5px;
  left: 20px;
  @media (max-width: 600px) {
    left: -15px;
  }
}

.question {
  padding: 0 70px;
}

.form {
  padding: 32px;
  max-width: 700px;
  .container {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    .button {
      width: 163px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .titleClassName {
      display: flex;
      justify-content: center;
      gap: 10px;
      min-width: 133px;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.09px;
        position: relative;
      }
    }
  }
}

.buyerHeading {
  max-width: 540px;
  display: inline-block;
  text-align: center;
}

.stageWrap {
  flex-basis: 100%;
  padding: 0 40px;
  height: calc(100vh - $header-height);

  .row {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  .partnerQuestion {
    text-align: center;
    & span {
      max-width: 540px;
      display: inline-block;
    }
  }
  .partnerRow {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .textCol {
    align-self: center;
  }
  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
  }
  .partnerHeading {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }
  .sideNote {
    color: $dark-grey-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;
  }
  .partnerPhoneWrapper {
    div {
      & input {
        border-radius: 8px !important;
      }
    }
  }
  .partnerFormInput {
    input {
      border-radius: 8px !important;
      border: 1px solid $light-grey-color !important;
      padding: 14px 16px !important;
      width: 100% !important;

      &::placeholder {
        color: $text-gray-color !important;
        font-feature-settings: 'clig' off, 'liga' off !important;
        /* Mobile/Desktop/Body/Regular */
        font-family: Inter !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20.8px !important; /* 130% */
        letter-spacing: -0.18px !important;
      }

      &:focus {
        border-bottom-color: $light-grey-color !important;
      }
    }
  }
  .partnerbtnContainer {
    margin: 40px auto 0;
  }
}
