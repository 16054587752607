@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.commentWrapper {
    @include action-icon();
    border-radius: setUnit(16);
    cursor: pointer;
    margin: 0 setUnit(4);
    height: setUnit(32);
    min-width: setUnit(32);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(8);

    .count {
        font-size: setUnit(14);
        font-weight: 600;
        margin-left: setUnit(3);
    }

    .icon {
        position: relative;
        display: flex;
        align-items: center;

        svg {
            vertical-align: middle;
        }
    }

    &.isUnread {
        .icon {
            &:before {
                content: '';
                width: setUnit(6);
                height: setUnit(6);
                border-radius: 50%;
                background: $primary-color;
                position: absolute;
                top: setUnit(-0.7);
                right: setUnit(-0.7);
                border: 2px solid $background-ligh-gray;
            }
        }
    }
}
