@import 'styles/functions';
@import 'styles/variables';

.scrollable {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 3px;
}

.blocksContainer {
  position: relative;
  display: block;

  .matchIcon {
    display: flex;
    align-items: center;
  }

  .matchTitle {
    font-size: setUnit(14);
    line-height: setUnit(16);
  }

  .matchItemsTitle {
    color: $text-color;
    font-weight: 600;
    font-size: setUnit(12);
    line-height: setUnit(16);
    margin-bottom: setUnit(12);
  }
}

.hintHeader {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: setUnit(14);
  padding-top: setUnit(5);
  margin-bottom: setUnit(12);
  border-bottom: 1px solid $light-gray;

  .hintAvatar {
    height: setUnit(32);
    margin-right: setUnit(12);

    img {
      width: setUnit(32);
      height: setUnit(32);
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .hintNameMatch {
    width: setUnit(315);

    .hintName {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      color: $text-color;
      margin-bottom: 2px;
    }

    .hintMatch {
      font-size: setUnit(12);
      line-height: setUnit(16);
      color: $dark-color;
    }

    .hintSearchInstance {
      display: flex;
      .searchInstanceIcon {
        color: $text-gray-color;
        border-width: 2px;
      }
      .searchInstanceName {
        font-size: setUnit(12);
        font-weight: 500;
        color: $dark-color;
      }
    }
  }

  .hintScore {
    display: flex;
    margin-left: auto;
    .hintScorePill {
      padding: setUnit(6) setUnit(10);
      border-radius: setUnit(16);
      color: white;
    }
  }
}

.greenProgress {
  background-color: $green-color;
}

.orangeProgress {
  background-color: $primary-color;
}

.grayProgress {
  background-color: $border-middle-gray;
}
