@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.modalContent {
  max-width: 420px !important;
  width: 100%;
  border-radius: setUnit(24);
}

.modalInnerHolder {
  padding: 40px 40px 32px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 24px;
  margin-top: setUnit(32);
  .button {
    flex: 1;
    transition: $transition;
    height: 52px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
    &.rejectButton {
      background-color: $background-grey-2;
      &:hover {
        background-color: $light-gray;
      }
      .title {
        color: $text-color;
      }
    }
    &.confirmButton {
      &:hover {
        background-color: $secondary-color-hover;
      }
    }
  }
}

.centered {
  justify-content: center;
}

.unsetOverflow {
  overflow: unset;
}
