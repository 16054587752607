@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  position: relative;

  .listHolder {
    @include box-shadow;

    width: setUnit(196);
    display: none;
    position: absolute;
    top: setUnit(42);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    z-index: 99;

    .list {
      list-style: none;
      margin: setUnit(0) 0;
      .item {
        font-size: setUnit(14);
        font-weight: 400;
        padding: setUnit(14);
        cursor: pointer;

        &:hover {
          background: $background-ligh-gray;
        }
      }
    }

    &.open {
      display: block;
    }
  }

  .circle {
    background: $background-ligh-gray;
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }
}
