@import 'styles/functions';
@import 'styles/variables';

.taskEmpty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    font-size: setUnit(20);
    font-weight: 600;
  }
}
