@import 'styles/functions';
@import 'styles/variables';

.button {
  background-color: $background-blue;
  min-width: 142px;
  height: 52px;
  min-height: 52px !important;
  max-height: 52px !important;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.35 !important;
  display: block;
  margin-left: auto;

  &:hover {
    background-color: $background-blue !important;
  }

  .buttonTitle {
    display: flex;
    justify-content: center;

    svg {
      margin-left: setUnit(12);

      path {
        stroke: $white;
      }
    }
  }

  &.active {
    opacity: 1 !important;

    &:hover {
      background-color: $secondary-color-hover !important;
    }
  }
}
