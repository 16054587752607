@import 'styles/mixins';
@import 'styles/variables';

$active-border-color: $primary-color;

.wrapper {
  width: 100%;
  max-width: setUnit(864);
  margin: setUnit(48) auto;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .holder {
    .optionHolder {
      display: flex;
      .option {
        border-radius: setUnit(8);
        border: 2px solid $background-ligh-gray-4;
        width: 100%;
        height: 124px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: setUnit(8);
        padding: 20px 14px 14px;
        cursor: pointer;
        text-align: center;
        line-height: setUnit(16);
        transition: $transition;
        .edit {
          position: absolute;
          top: setUnit(5);
          right: setUnit(5);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: setUnit(20);
            height: setUnit(20);
            vertical-align: middle;
          }

          &:hover {
            background-color: $active-border-color;

            svg {
              path {
                stroke: $white;
              }
            }
          }
        }

        .optionInner {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .label {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.09px;
            color: $dark-grey-color;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
          }

          .icon {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            svg {
              height: 44px;
              width: 44px;
            }
          }

          svg {
            vertical-align: middle;

            path,
            rect,
            circle,
            ellipse {
              stroke: $dark-grey-color;
            }
          }
        }

        &:hover {
          background: #ffffff;
          border-color: $text--light-gray-color;
        }

        &.active {
          background: $primary-color-light;
          border: 1px solid $active-border-color !important;

          .labelActive {
            color: $text-color !important;
            font-weight: 500 !important;
          }

          svg {
            vertical-align: middle;

            mask {
              & + path {
                fill: $active-border-color;
              }
            }

            path,
            rect,
            circle,
            ellipse {
              stroke: $active-border-color;
            }
          }

          &:hover {
            border: 1px solid $primary-color-hover !important;
            background-color: #ffebed !important;
          }
        }
      }
    }
  }
}
