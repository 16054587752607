@import 'styles/variables';

.tableContainer {
  height: 500px;

  .btnRow {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .positive {
    color: $green-color;
  }

  .negative {
    color: $error-color;
  }

  .statsTable {
    & [class*='ant-table-body'] {
      height: 408px;
      overflow: auto;
      padding-right: 6px;
    }

    & [class*='ant-table-thead'] {
      background-color: $white;
      border-bottom: 1px solid $light-grey-color;
      position: sticky;
      top: 0;
      background-color: white;
      height: 20px;
      z-index: 1;

      & tr > th {
        background-color: $white;
        color: $text-gray-color;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        letter-spacing: -0.18px;
      }
      &
        tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none !important;
      }
    }

    tr > td {
      color: $dark-color;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.18px;
    }
    tr > td:nth-child(2n) {
      color: $text-color;
    }

    tr:nth-child(2n-1) td {
      background-color: #f4f5f6;
    }
  }
}
