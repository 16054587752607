.container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  &.client {
    width: 80vw;
    @media only screen and (max-width: 600px) {
      width: auto;
    }
    max-width: fit-content;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
