@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.notes {
  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .checkboxSquare {
    [class*='ant-checkbox'] {
      [class*='ant-checkbox-inner'] {
        border-radius: setUnit(4);
      }
    }

    [class~='ant-checkbox-disabled'] {
      [class*='ant-checkbox-inner'] {
        border-color: $border-light-gray-3 !important;
      }
    }

    &:hover {
      [class~='ant-checkbox-disabled'] {
        [class*='ant-checkbox-inner'] {
          border-color: $border-light-gray-3 !important;
        }
      }
    }
  }
  .content {
    margin-top: setUnit(20);
    .title {
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-color;

      .feedbackIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
    }

    .checkBoxWrapper {
      margin-top: setUnit(32);
      padding: setUnit(24);
      border: 1px solid $light-gray;
      border-radius: 8px;
    }
    .container {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      .checkbox {
        margin: 0;
      }
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}
