@import 'styles/functions';
@import 'styles/variables';

.memberContainer {
  padding: 0.625rem 0;
  display: flex;
  align-items: flex-start;
  .details {
    padding-right: 1.25rem;
    display: inline-block;

    h4 {
      margin: 0;
      line-height: 20px;
    }

    p {
      margin: 0;
      color: $dark-grey-color;
    }
  }

  .selectBox {
    float: left;
    margin-right: 10px;
    margin-top: 4px;
    width: 24px;
    height: 24px;
    border: 1.5px solid $text--light-gray-color;
    border-radius: 4px;
    cursor: pointer;
    color: $white;
    text-align: center;

    svg {
      margin-top: -0.2rem;
    }
  }

  .selected {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .disabled {
    opacity: 0.5;
  }

  .disabledBox {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
