@import 'styles/functions';
@import 'styles/variables';

.preApprovalsPage {
  padding-top: 100px;
  padding-right: 112px;
  padding-left: 70px;

  .optionsColumn {
    display: flex;
    justify-content: flex-end;
    .bottomOffset {
      bottom: -9rem;
    }
  }

  @media (max-width: $mobile) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.pagecontentStyles {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}
