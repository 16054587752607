@import 'styles/variables';

$question-header-height: 68px;

.quoteLeftSidebar {
  background: $white !important;

  & [class*='ant-layout-sider-children'] {
    width: 240px;
  }
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .addQuestionContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: $question-header-height;

    .questionText {
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }

  .noEntries {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text--light-gray-color;
    height: calc(100vh - $header-height - $question-header-height);
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0px 44px;
  }

  .dragMenuList {
    height: calc(100vh - $header-height - $question-header-height);
    overflow: auto;
  }
}

.questionMenuItem {
  display: flex;
  align-items: center;
  min-height: 56px;

  .questionIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    min-height: 56px;
  }
  .questionName {
    width: 150px;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    border: none;
    min-height: unset !important;
  }
}
