@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.pageWrapper {
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  flex: 1 1 auto;
}

.address {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  padding: 4px 10px 4px 12px;
  cursor: pointer;
  &:hover {
    background: $background-grey-2;
  }
}

.content {
  min-width: 600px;
  .search {
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 20px;
    align-items: center;
    .input {
      flex: 1;
      input {
        width: 100% !important;
        all: unset;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        &::placeholder {
          color: $text--light-gray-color;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
      }
    }
    .searchButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 44px;
      border-radius: 12px;
      background: $primary-color;
    }
  }
  .divider {
    margin: 7px 0;
    background: $light-gray;
    width: 100%;
    height: 1px;
  }
  .title {
    padding: 14px 20px;
    margin: 0;
    color: $text-gray-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.09px;
  }
  .item {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-bottom: 0;
    &:hover {
      background: $background-grey-2;
    }
    .addressText {
      flex: 1;
      .line1 {
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 4px;
      }
      .line2 {
        color: $dark-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 0;
      }
    }
  }
}
