@import 'styles/variables';

.stageWrap {
  flex-basis: 100%;
  padding: 0 40px;
  height: calc(100vh - $header-height);
  .formInput {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
