@import 'styles/functions';
@import 'styles/variables';

.back {
  display: inline-flex;
  text-decoration: none;
  font-weight: 400;
  outline: none;
  font-size: setUnit(16);
  align-items: center;
  cursor: pointer;
  margin-left: setUnit(62);
  border: 0;
  background: transparent;
  padding: setUnit(14) setUnit(20);
  border-radius: setUnit(50);
  transition: all 0.1s;

  &:hover {
    opacity: 0.7;
  }

  .arrow {
    margin-right: setUnit(10);
  }
}
