@import 'styles/functions';
@import 'styles/variables';

.dateControlsModal {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    @media (max-width: $mobile) {
      text-align: left;
    }
  }

  .content {
    padding-top: 1rem;
    padding-bottom: 2rem;

    .container {
      width: 100%;
      padding-bottom: 35px;
      .datePicker,
      .customDatePicker {
        width: 95%;
      }

      .customDatePicker > div > div > div {
        top: -13rem;
      }
    }

    .title {
      margin-top: 0.75rem;

      .prefix {
        width: setUnit(35);
        height: setUnit(28);
        border: 2px solid rgba(232, 233, 234, 0.62);
        border-radius: 100px;
        text-align: center;
        padding: 0.1rem 0.25rem;
        font-weight: 600;
        color: $dark-color;
        display: inline-block;
        margin-right: 1rem;
      }

      p {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }

  .actions {
    width: 95%;

    .saveBtn {
      width: setUnit(152);
      height: setUnit(52);
      text-align: center;
    }
  }
}
