@import 'styles/variables';
@import 'styles/functions';

.voidConfirmationModal {
  .icon {
    display: block;
    margin: 0 auto;
  }

  .voidConfirmationContent {
    font-size: setUnit(20);
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33000001311302185px;
    text-align: center;

    margin-top: 24px;
  }
}
