@import 'styles/functions';
@import 'styles/variables';

.footer {
  padding: setUnit(13) setUnit(24);
  background: $background-ligh-gray;

  .submitButton {
    width: setUnit(89);
    height: setUnit(39);
    display: block;
    margin-left: auto;
  }
}
