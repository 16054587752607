@import 'styles/functions';
@import 'styles/variables';

.workshopHeader {
  position: absolute;
  top: setUnit(160);
  left: 0;
  right: 0;
  padding: 12px 40px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 0px;
  }

  .workshopHeaderLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;

    @media (min-width: $tablet) {
      justify-content: flex-start;
    }
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  .searchWrapper {
    .inputWrap {
      background: $white;
      border: 2px solid $light-gray;
      border-radius: setUnit(26);
      width: setUnit(44);
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      @media (max-width: 991px) {
        height: setUnit(36);
      }
      &:focus {
        border-width: 2px;
      }
      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }
      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
      }
    }
  }

  .multiSelectBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $light-gray;
    margin-left: 0.625rem;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    cursor: pointer;

    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    &.selected {
      border-color: $primary-color;
      background-color: $primary-color-light;
    }
  }

  .filterWrapper {
    display: flex;
    align-items: center;
  }

  .actionsRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;

    @media screen and (max-width: 991px) {
      position: absolute;
      margin: 0 setUnit(15) 0 0;
      top: setUnit(30);
      right: 0;
      justify-content: flex-end;
    }

    .btn {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border: 2px solid $light-gray;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: $dark-color;

      &.newBtn {
        background-color: $secondary-color;
        color: $white;
        border: none;

        &:hover {
          border: none;
          background-color: 2px solid $secondary-color-hover;
        }
      }

      .addIcon {
        svg {
          path {
            stroke: $white;
          }
        }
      }

      .iconText {
        margin-left: setUnit(5);
      }

      &.filterBtn {
        width: fit-content;
        padding: 0 1rem;
      }

      width: setUnit(36);
      height: setUnit(36);
      @media (min-width: $tablet) {
        height: setUnit(44);
        width: setUnit(44);
      }
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      &.btnText {
        width: auto;
        padding: 0 setUnit(16);
      }
    }

    .selected {
      background-color: $background-blue;
    }

    .disabledArchive {
      opacity: 0.5;
      cursor: default;
    }

    @media (max-width: $mobile) {
      right: unset;
      justify-content: center;
      width: 100%;
      margin-top: -20px;
    }
  }
}

.popover {
  background-color: $white;
  border-radius: 8px;

  [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .newMenu {
    width: 205px;

    .menuLink {
      height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 24px;

      &:hover {
        background-color: $hover-light-gray;
        cursor: pointer;
      }

      .text {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09000000357627869px;
        color: $text-color;
      }
    }
  }
}

.actions {
  width: calc(100% - 70px);
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
  margin-bottom: setUnit(24);
  margin-top: 15px;
  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .searchWrapper {
      .inputWrap {
        background: $white;
        border: 2px solid $border-light-gray-2;
        border-radius: setUnit(26);
        width: setUnit(44);
        height: setUnit(44);
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        @media (max-width: 991px) {
          height: setUnit(36);
        }
        &:focus {
          border-width: 2px;
        }
        .icon {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          transform: unset;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $dark-color;
            }
          }
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: setUnit(8);
          display: none;
          width: 0;
        }
      }
    }
  }

  @media (min-width: $tablet) {
    position: absolute;
    top: setUnit(160);
    right: setUnit(40);
    margin-bottom: 0;
  }

  .newBtn {
    background: $secondary-color !important;
    border: none !important;
    color: $white !important;
    .addIcon {
      margin-right: 5px;
    }
  }

  .btn {
    height: setUnit(44);
    width: setUnit(44);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    color: $dark-color;
    margin-left: 10px;

    @media (max-width: 991px) {
      width: setUnit(36);
      height: setUnit(36);
    }

    svg {
      @media (max-width: 991px) {
        width: setUnit(20);
      }
    }

    .icon-text {
      margin-left: setUnit(5);
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:not(:last-child) {
      margin-right: setUnit(8);
    }

    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    &.btnText {
      width: auto;
      padding: 0 setUnit(16);
    }
  }

  .selected {
    background-color: $background-blue;
  }
}

.activityFiltersIndexHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
  margin-right: 3rem;

  .btn {
    height: 44px;
    width: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
    position: relative;
    margin-right: 0.5rem;
    font-weight: 600;
    color: $dark-color;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    .filterIcon {
      margin-top: 0.25rem;
    }
  }
}

.viewSelect {
  margin-right: setUnit(8) !important;

  [class*='ant-select-selector'] {
    [class*='ant-select-selection-item'] {
      font-weight: 600;
    }

    border: 2px solid $background-ligh-gray-4 !important;
    border-radius: 100px !important;
    color: $dark-color;
    font-size: 14px;
  }
}

.formsFilterHeader {
  display: flex;
  align-items: center;
}
