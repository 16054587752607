@import 'styles/functions';
@import 'styles/variables';

.emptyStateDocuments {
  margin-top: setUnit(200);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $text--light-gray-color;
}
