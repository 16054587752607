@import "styles/functions";
@import "styles/variables";

.footer {
    padding: setUnit(28) 0;
    display: flex;
    border-top: 1px solid $grey-color;
    align-items: center;
    justify-content: center;
    width: calc(100% - #{setUnit(64)});
    margin: 0 auto;
    background: #FFFFFF;

    .removeArea {
        width: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: setUnit(14);
        font-weight: 500;
        color: $error-color;
        margin-left: setUnit(13);
    }
}
