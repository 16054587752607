@import 'styles/functions';
@import 'styles/variables';

.ClientCard {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  padding: 0.5rem 2rem 0.5rem;
  border-radius: 16px;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
  cursor: pointer;

  .ClientAvatar {
    display: inline-block;
    margin-right: 1rem;
    // top: -2.75rem;
  }

  .Details {
    display: inline-block;

    .ClientName {
      margin: 0;
      position: relative;
      top: 3px;
      font-weight: 600;
      .numberOfSearches {
        color: $text--light-gray-color;
      }
    }

    .ActiveInstances {
      margin: 0 0 0.5rem;
      font-weight: 500;
    }

    .Results {
      margin: 0;
      color: $dark-color;
    }
  }
  .expandButton {
    float: inline-end;
    cursor: pointer;
    margin-top: 12px;
  }
  .accordion {
    border-bottom: 0;
    .inactiveTitle {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(16);
      letter-spacing: -0.18px;
      text-align: center;
      margin: 0;
      @media (min-width: $mobile-small) {
        text-align: left;
      }
    }
  }
}
