@import 'styles/variables';

.partnerTitle {
  margin-bottom: 12px;
}
.partnerSubheader {
  max-width: 540px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.18px;
  color: $dark-color;
  margin: 0 auto 40px;
  text-align: center;
}
