@import 'styles/functions';
@import 'styles/variables';
@import '../../mixins.module.scss';

.offerExpiration {
  .offerExpirationBox {
    padding: 0;

    .headerIcon {
      padding: setUnit(24) setUnit(24) 0px;
    }

    .offerExpirationWrapper {
      padding: 0px setUnit(24) setUnit(32);
      margin-top: setUnit(28);

      .inputBlock {
        .offerExpiration {
          display: flex;
          flex-flow: column wrap;

          @include checkedRadio;

          .checkbox {
            margin-left: 0;
            width: fit-content;

            &:not(:last-child) {
              margin-bottom: 21px;
            }

            [class~='ant-checkbox'] {
              [class~='ant-checkbox-inner'] {
                border-radius: 50px;
              }

              & + span {
                .static {
                  font-size: setUnit(16);
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: -0.18px;
                  color: $dark-color;
                  // margin: 0px 12px;
                }

                .daysInputHolder {
                  @include daysInputHolder;
                  margin: 0 12px;
                }
              }

              &[class~='ant-checkbox-checked'] {
                &:after {
                  border-radius: 50px;
                }
                & + span {
                  .static {
                    color: $text-color;
                  }
                }
              }
            }
          }

          .datePickerCheckbox {
            margin-bottom: 17px !important;
          }

          .fieldsContainer {
            padding-left: 35px;

            .datePickerHolder {
              @include datePickerHolder;
              margin-bottom: 16px;

              [class*='ant-picker-input'] {
                height: 100%;

                input {
                  padding: setUnit(10) setUnit(12) setUnit(9);

                  &::placeholder {
                    color: $text--light-gray-color;
                  }
                }
              }
              .preposition {
                margin: 0px 12px;
              }

              .timeInputWrapper {
                .timeInputField {
                  border: 1px solid $border-light-gray-2 !important;
                  width: 4.5rem;
                  font-size: setUnit(16);
                  line-height: 20.8px;
                  border-radius: 8px !important;
                  padding: setUnit(10) setUnit(12) setUnit(9);
                  margin-right: 12px;

                  &:focus {
                    border-color: $text--light-gray-color !important;
                  }
                }

                .timeSelect {
                  .timeDropdown {
                    padding: 0;
                    background: $white;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
                    border-radius: 8px;
                    width: 71px !important;
                    div[class~='ant-select-item-option'] {
                      padding: 12px 16px;
                      display: flex;
                      flex-flow: row-reverse;
                      position: relative;
                      div[class~='ant-select-item-option-content'] {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: -0.09px;
                        color: $text-color;
                        display: flex;
                        align-items: center;
                      }
                      &[class~='ant-select-item-option-selected'] {
                        background-color: $background-grey-2;
                        [class~='ant-select-item-option-state'] {
                          border-color: $primary-color;
                          background-color: $primary-color;
                          color: $white;
                        }
                        [class~='ant-select-item-option-content'] {
                          &:before {
                            content: none;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .popupCalender {
                [class*='ant-picker-cell-inner'] {
                  font-weight: 500 !important;
                }

                [class*='ant-picker-header'] {
                  [class*='ant-picker-header-super-next-btn'] {
                    display: inline-block;
                  }
                }
              }
            }

            .expiryTimeZoneArea {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
