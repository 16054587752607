@import 'styles/variables';
@import 'styles/functions';

@mixin input-styles-important {
  height: 46px !important;
  line-height: 1.6rem !important;
  font-size: 1rem !important;
}

@mixin lightRounded {
  border-radius: 8px !important;
  padding: setUnit(8) setUnit(13) !important;
  outline: none !important;
  margin: 0 !important;
  width: 100% !important;
  vertical-align: middle !important;
  color: $text-color !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.3rem !important;
  letter-spacing: -0.09px !important;
  height: setUnit(48) !important;
  border: 0 !important;
  border: 1px solid $light-grey-color !important;
  transition: $transition !important;
  &:focus {
    border: 1px solid $text-gray-color !important;
  }
  &::placeholder {
    color: $text-gray-color !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

@mixin inputLabelContainer {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1rem; /* 114.286% */
  letter-spacing: -0.00563rem;
}

@mixin input-styles {
  font-weight: 400;
  letter-spacing: -0.01125rem;
  font-family: Inter;
  font-style: normal;
}

@mixin custom-small-input {
  width: setUnit(172);
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: $white;
  border: 1px solid $light-grey-color;
}

@mixin custom-large-input {
  width: 100%;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: $white;
  border: 1px solid $light-grey-color;
}

@mixin layout {
  display: flex;
  align-items: center;
}

@mixin label() {
  font-weight: 600;
  margin-bottom: setUnit(12);
  color: $text-color;
  font-size: setUnit(14);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin inputLight() {
  label {
    @include label;
  }

  input {
    @include inputLightInnerInput();
  }

  textarea,
  select {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    padding: setUnit(10) 0;
    outline: none;
    margin: 0;
    vertical-align: middle;
    width: 100%;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    border-bottom: 2px solid $border-input-middle-gray;

    &:focus {
      border-bottom-color: $text-gray-color;
    }

    &::placeholder {
      color: $border-middle-gray;
    }
  }
}

@mixin inputLightInnerInput() {
  padding: setUnit(12) 0 setUnit(16);
  outline: none;
  margin: 0;
  width: 100%;
  vertical-align: middle;
  color: $text-color;
  font-size: setUnit(18);
  font-weight: 400;
  line-height: setUnit(24);
  letter-spacing: -0.09px;
  height: setUnit(52);
  border: 0;
  border-bottom: 2px solid $border-input-middle-gray;
  transition: $transition;
  &:focus {
    border-bottom-color: $text-gray-color;
  }
  &::placeholder {
    color: $text--light-gray-color;
    font-weight: 400;
    font-size: setUnit(16);
  }
}

@mixin lightRoundDate() {
  padding-left: 43px !important;
  outline: none;
  margin: 0;
  width: 100%;
  vertical-align: middle;
  color: $text-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: setUnit(24);
  letter-spacing: -0.09px;
  height: setUnit(48);
  border-radius: 8px;
  border: 1px solid $light-grey-color;
  transition: $transition;
  &:focus {
    border: 1px solid $text-gray-color;
  }
  &::placeholder {
    color: $text-gray-color;
    font-weight: 400;
    font-size: 1rem;
  }
}

@mixin lightRound() {
  label {
    @include label;
  }
  input,
  textarea {
    border-radius: 8px;
    padding: 8px 13px;
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    color: $text-color;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.3rem;
    letter-spacing: -0.09px;
    height: setUnit(48);
    border: 0;
    border: 1px solid $light-grey-color;
    transition: $transition;
    &:focus {
      border: 1px solid $text-gray-color;
    }
    &::placeholder {
      color: $text-gray-color;
      font-weight: 400;
      font-size: 1rem !important;
    }
  }
  textarea {
    resize: none;
    height: auto !important;
  }
}

@mixin inputLightRound() {
  background: $white;
  label {
    @include label;
  }
  input {
    padding: setUnit(12) setUnit(16);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    color: $text-color;
    font-size: setUnit(18);
    font-weight: 400;
    line-height: setUnit(24);
    letter-spacing: -0.09px;
    height: setUnit(52);
    border-radius: 8px;
    border: 1px solid $light-grey-color;
    transition: $transition;
    &:focus {
      border: 1px solid $text-gray-color;
    }
    &::placeholder {
      color: $text-gray-color !important;
      font-weight: 400;
      font-size: setUnit(16);
    }
  }

  textarea,
  select {
    padding: setUnit(5);
    border: 1px solid darken($grey-color, 10);
    border-radius: setUnit(8);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
      color: $text--light-gray-color;
    }
  }
}

@mixin inputLightFull() {
  label {
    @include label;
  }
  input {
    padding: setUnit(12) 0 setUnit(16);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    color: $text-color;
    font-size: setUnit(18);
    font-weight: 400;
    line-height: setUnit(24);
    letter-spacing: -0.09px;
    height: setUnit(52);
    border: 0;
    border-bottom: 2px solid $border-input-middle-gray;
    transition: $transition;
    &:focus {
      border-bottom-color: $text-gray-color;
    }
    &::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: setUnit(16);
    }
  }

  textarea,
  select {
    padding: setUnit(5);
    border: 1px solid darken($grey-color, 10);
    border-radius: setUnit(5);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
      color: $text--light-gray-color;
    }
  }
}

@mixin inputFull() {
  margin: setUnit(24) 0;

  label {
    @include label;
  }

  input,
  textarea {
    padding: setUnit(12) 0 setUnit(16);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    color: $text-color;
    font-size: setUnit(18);
    font-weight: 400;
    line-height: setUnit(24);
    letter-spacing: -0.09px;
    height: setUnit(52);
    border: 0;
    border-bottom: 2px solid $border-input-middle-gray;
    border-radius: 0;
    transition: $transition;
    &:focus {
      border-bottom-color: $text-gray-color;
    }
    &::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: setUnit(16);
    }
  }

  input {
    height: setUnit(52);
  }

  textarea {
    padding-top: setUnit(16);
    padding-bottom: setUnit(16);
  }

  select {
    padding: setUnit(5);
    border: 1px solid darken($grey-color, 10);
    border-radius: setUnit(5);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
  }
}

@mixin inputError() {
  font-size: setUnit(14);
  color: $error-color;
  margin-top: setUnit(3);

  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin button($bg: $secondary-color, $color: #fff, $hover-color: lighten($bg, 20)) {
  background-color: $bg;
  color: $color;
  border: none;
  padding: setUnit(5) setUnit(15);
  border-radius: setUnit(8);
  cursor: pointer;
  outline: none;
  height: setUnit(48);

  &:hover {
    background-color: $hover-color;
  }
}

@mixin box-shadow($color: #000000) {
  box-shadow: 0 setUnit(3) setUnit(12) rgba($color, 0.15);
}

@mixin box-shadow-left($color: rgb(48, 48, 48)) {
  box-shadow: -4px 0px 16px rgba($color, 0.15);
}

@mixin box-shadow-hover($color: #000000) {
  box-shadow: 3px 5px 3px -3px rgba(0, 0, 0, 0.2), 3px 3px 3px 0px rgba(0, 0, 0, 0.14),
    3px 3px 5px 0px rgba(0, 0, 0, 0.12);
}

@mixin action-icon() {
  background-color: $background-ligh-gray;

  &:hover {
    background-color: $light-gray;
  }
}

@mixin grid-action-button(
  $bg-color: $background-ligh-gray,
  $hover-color: $light-gray,
  $color: $text-color
) {
  cursor: pointer;
  border-radius: 50%;
  width: setUnit(24);
  margin-right: setUnit(8);
  height: setUnit(24);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-color;
  color: $color;

  &:hover {
    background-color: $hover-color;
  }

  svg {
    width: setUnit(18);
    height: setUnit(18);

    path {
      stroke: $color !important;
      stroke-width: 2px !important;
    }
  }
}

@mixin form-title() {
  font-weight: 400 !important;
  font-size: setUnit(48) !important;
  line-height: setUnit(48) !important;
  letter-spacing: -0.89px !important;
  color: $secondary-color !important;
}

@mixin step-title() {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.8px;
  text-align: center;
  color: $background-blue;
}

@mixin step-title-small() {
  font-weight: 500 !important;
  font-size: setUnit(26) !important;
  line-height: setUnit(34) !important;
  letter-spacing: -0.89px !important;
  color: $secondary-color !important;
  text-align: left !important;
}

@mixin link() {
  color: $link-color;
  font-weight: 600;
  font-size: setUnit(16);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: lighten($link-color, 10);
  }
}

@mixin responsiveContentLayout() {
  min-height: calc(100vh - $header-height - $footer-height) !important;
}

@mixin transition() {
  transition: $transition;
}

@mixin customScrollbar() {
  &::-webkit-scrollbar {
    width: setUnit(7);
  }
  &::-webkit-scrollbar-thumb {
    background: $border-light-gray-2;
  }
}

@mixin customCalendar() {
  table[class~='ant-picker-content'] {
    tbody {
      tr {
        td {
          div[class~='ant-picker-calendar-date-value'] {
            font-weight: 600;
            font-size: setUnit(16);
            line-height: setUnit(20);
            letter-spacing: setUnit(-0.18);
            color: $secondary-color;
          }
          &[class~='ant-picker-cell-disabled'] {
            div[class~='ant-picker-calendar-date-value'] {
              color: $text--light-gray-color;
              font-weight: 400;
            }
          }
          &[class~='ant-picker-cell-today'] {
            div[class~='ant-picker-calendar-date-today'] {
              &:before {
                border-bottom: 0;
                width: setUnit(4);
                height: setUnit(4);
                bottom: setUnit(-2);
                top: auto;
                left: calc(50% - #{setUnit(2)});
                border-radius: setUnit(50);
                background-color: $secondary-color;
              }
            }
          }
          &[class~='ant-picker-cell-selected'] {
            &:before {
              background-color: $secondary-color;
            }
            div[class~='ant-picker-calendar-date-value'] {
              color: $white;
            }
          }
        }
      }
    }
  }
}

@mixin customDatepicker() {
  [class*='ant-picker-dropdown'] {
    [class*='ant-picker-header'] {
      [class*='ant-picker-header-view'] {
        button {
          font-weight: 600;
          font-size: setUnit(16);
          color: $text-color;
        }
      }
      [class*='ant-picker-header-super-prev-btn'],
      [class*='ant-picker-header-super-next-btn'] {
        display: none;
      }
      [class*='ant-picker-header-prev-btn'],
      [class*='ant-picker-header-next-btn'] {
        span {
          &::before {
            border-color: $text-color;
            width: setUnit(10);
            height: setUnit(10);
            border-width: 2px 0 0 2px;
          }
        }
      }
    }
    [class*='ant-picker-body'] {
      [class*='ant-picker-content'] {
        border-collapse: separate;
        border-spacing: 0 setUnit(2);
        thead {
          tr {
            th {
              font-weight: 400;
              font-size: setUnit(12);
              color: $text--light-gray-color;
            }
          }
        }
        tbody {
          tr {
            [class*='ant-picker-cell-today'] {
              &:before {
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 0 setUnit(50) setUnit(50) 0;
              }
              [class*='ant-picker-cell-inner'] {
                background-color: $light-gray;
                &:before {
                  border-radius: setUnit(50);
                  border: 0;
                }
              }
            }
            [class*='ant-picker-cell'] {
              padding: setUnit(0);
              vertical-align: middle;
              &:before {
                width: setUnit(36);
                height: setUnit(36);
              }
              [class*='ant-picker-cell-inner'] {
                width: setUnit(36);
                height: setUnit(36);
                font-weight: 700;
                font-size: setUnit(14);
                line-height: setUnit(16);
                letter-spacing: -0.18px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: setUnit(50);
              }
            }
            [class*='ant-picker-cell-disabled'] {
              &:first-child {
                &:before {
                  border-radius: setUnit(50) 0 0 setUnit(50);
                }
              }
              &:last-child {
                &:before {
                  border-radius: 0 setUnit(50) setUnit(50) 0;
                }
              }
            }
            [class*='ant-picker-cell-selected'] {
              [class*='ant-picker-cell-inner'] {
                background-color: $secondary-color;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class*='ant-picker-footer'] {
      border-top: 0;
      margin-bottom: setUnit(8);
      a {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        text-align: center;
        letter-spacing: -0.18px;
        color: $dark-color;
      }
    }
  }
}

@mixin customRangePicker() {
  [class*='ant-picker-input'] {
    padding: 0.25rem;
    border-bottom: 2px solid $border-input-middle-gray;
    @include transition;
  }

  &[class*='ant-picker-focused'] {
    [class*='ant-picker-input'] {
      border-bottom-color: $text-gray-color;
    }
  }

  [class*='ant-picker-dropdown'] {
    [class*='ant-picker-header'] {
      [class*='ant-picker-header-view'] {
        button {
          font-weight: 600;
          font-size: setUnit(16);
          color: $text-color;
        }
      }
      [class*='ant-picker-header-super-prev-btn'],
      [class*='ant-picker-header-super-next-btn'] {
        display: none;
      }
      [class*='ant-picker-header-prev-btn'],
      [class*='ant-picker-header-next-btn'] {
        span {
          &::before {
            border-color: $text-color;
            width: setUnit(10);
            height: setUnit(10);
            border-width: 2px 0 0 2px;
          }
        }
      }
    }
    [class*='ant-picker-body'] {
      [class*='ant-picker-content'] {
        border-collapse: separate;
        border-spacing: 0 setUnit(2);
        thead {
          tr {
            th {
              font-weight: 400;
              font-size: setUnit(12);
              color: $text--light-gray-color;
            }
          }
        }
        tbody {
          tr {
            [class*='ant-picker-cell'] {
              padding: setUnit(0);
              vertical-align: middle;
              &:before {
                width: setUnit(36);
                height: setUnit(36);
                border: 0;
              }
              [class*='ant-picker-cell-inner'] {
                width: setUnit(36);
                height: setUnit(36);
                font-weight: 700;
                font-size: setUnit(14);
                line-height: setUnit(16);
                letter-spacing: -0.18px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: setUnit(50);
              }
            }
            [class*='ant-picker-cell-disabled'] {
              &:first-child {
                &:before {
                  border-radius: setUnit(50) 0 0 setUnit(50);
                }
              }
              &:last-child {
                &:before {
                  border-radius: 0 setUnit(50) setUnit(50) 0;
                }
              }
            }
          }
        }
        [class*='ant-picker-cell-in-range'] {
          &:before {
            background-color: $background-grey-2;
            width: setUnit(36);
            height: setUnit(36);
          }
          &:first-child {
            &:before {
              border-radius: 50px 0 0 50px;
            }
          }
          &:last-child {
            &:before {
              border-radius: 0 50px 50px 0;
            }
          }
        }
        [class*='ant-picker-cell-today'] {
          &:before {
            left: 0;
            border-radius: 0 setUnit(50) setUnit(50) 0;
          }
        }
      }
      [class*='ant-picker-cell-range-hover-start'],
      [class*='ant-picker-cell-range-hover-end'] {
        [class*='ant-picker-cell-inner'] {
          &:after {
            background-color: $light-gray;
            border-radius: setUnit(50);
            right: 0;
          }
        }
      }
      [class*='ant-picker-cell-range-hover-end'] {
        [class*='ant-picker-cell-inner'] {
          &:after {
            left: 0;
          }
        }
      }
      [class*='ant-picker-cell-range-start'] {
        &:before {
          background-color: $background-grey-2;
          width: setUnit(36);
          height: setUnit(36);
          border-radius: 50px 0 0 50px;
          left: 0;
        }
      }
      [class*='ant-picker-cell-range-end'] {
        &:before {
          background-color: $background-grey-2;
          width: setUnit(36);
          height: setUnit(36);
          border-radius: 0 50px 50px 0;
        }
      }
      [class*='ant-picker-cell-today'] {
        &:before {
          border-radius: 0 !important;
        }
      }
      [class*='ant-picker-cell-in-view ant-picker-cell-range-start'],
      [class*='ant-picker-cell-in-view ant-picker-cell-range-end'] {
        [class*='ant-picker-cell-inner'] {
          background-color: $secondary-color;
        }
      }
    }
  }
}

@mixin customSuccessToast {
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 9999999;
  pointer-events: none;

  .customToast {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiByeD0iMTQiIGZpbGw9IiMwNEE0NTEiLz4KPHBhdGggZD0iTTguNSAxNC41TDEyIDE4TDE5IDExIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') !important;
    background-repeat: no-repeat;

    position: relative;
    pointer-events: auto;
    overflow: hidden;
    padding: 20px 20px 20px 60px;
    background-position: 20px 20px;
    color: #ffffff;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.14));

    background-color: $secondary-color-hover;
    border-radius: 12px;
    width: 360px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile-small) and (min-width: 400px) {
      width: 315px;
      padding: 15px 15px 15px 55px;
      background-position: 15px 15px;
    }

    @media (max-width: 399px) {
      width: 297px;
      padding: 15px 15px 15px 55px;
      background-position: 15px 15px;
    }

    .successMessage {
      font-size: setUnit(16);
      font-weight: 600;
      letter-spacing: -0.18px;
      line-height: unset;

      > div {
        font-weight: 400;
        margin-top: 6px;
        font-size: setUnit(14);
        cursor: pointer;
      }
    }

    .toastCloseButton {
      position: relative;
      font-size: setUnit(26);
      padding: 0;
      line-height: 0px;
      cursor: pointer;
      border: 0;
      order: 1;
      background: transparent;
      margin-top: 0px;
    }
  }
}
