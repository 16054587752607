@import 'styles/functions';
@import 'styles/variables';

.radiusTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      max-width: 251px;
      padding: 16px;

      p {
        color: white !important;
        margin: 0;
        white-space: auto;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.tooltipIcon {
  max-height: 19px;
  position: relative;
  top: 2px;
}

.content {
  .icon {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
  }
  .heading {
    color: $text-color;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
  }
  .row {
    display: flex;
    gap: 20px;
  }
  .inputContainer {
    margin-top: 24px;
    width: 100%;
    .iconRight {
      margin: 0;
      color: $text-gray-color;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.09px;
    }
  }
  .submit {
    margin-top: 32px;
    width: 100%;
    height: 52px;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
}

.border {
  border-radius: 8px 0 0 8px !important;
  .placeholder {
    border-radius: 8px 0 0 8px !important;
  }
  input {
    border-radius: 8px 0 0 8px !important;
  }
}

.checkbox {
  padding: 0 !important;
  padding-top: 20px !important;
}
