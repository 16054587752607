@import 'styles/functions';
@import 'styles/variables';

.reviewer {
  padding: setUnit(8) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .avatar {
    width: setUnit(32);
    height: setUnit(32);
  }

  .reviewerNameWrapper {
    display: flex;
    gap: 6px;

    .name {
      margin-left: setUnit(12);
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $text-color;
    }

    .role,
    .subText {
      font-size: setUnit(14);
      color: $text--light-gray-color;
      font-weight: 400;
      margin-top: 2px;
    }

    .ownerMember {
      height: 100%;

      border-radius: 100px;
      background: $background-grey-2;
      padding: 2px 6px;
      color: $dark-color;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.33px;
      border: 1px solid $border-light-gray-3;
    }
  }

  .alignNameCenter {
    align-items: center;
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .nameRow {
    display: flex;
    align-items: center;
  }

  .reviewerCheckmarkLabel {
    margin-bottom: 0px;

    &:hover {
      .reviewerCheckbox {
        border-color: $primary-color !important;
      }
    }

    .checkboxWrapper {
      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
}

.disabledCheckbox {
  cursor: not-allowed;
  .disabledCheckboxWrapper {
    opacity: 0.4 !important;
  }
}
