@import 'styles/variables';

.fileSizeInformation {
  display: flex;
  align-items: center;
  gap: 6px;
  color: $text-gray-color;
  margin: 20px 0px;
  line-height: 0px;
}
