@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.footer {
  border-top: 1px solid $border-light-gray-2;
  margin-right: setUnit(16);
  .saveBtnDiv {
    text-align: center;
    margin: 1.675rem 0 0 0;

    .saveBtn {
      color: $white;
      background-color: $background-blue;
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      border-radius: 8px;
      padding: 0.3125rem 0.9375rem;
      width: 8.6875rem;
      height: 3.25rem;
      border: 0;
      cursor: pointer;

      &:hover {
        background-color: $hover-blue;
      }
    }

    .createBtn {
      float: right;
    }

    .saveBtnDisabled {
      cursor: not-allowed;

      &:hover {
        background-color: $background-blue;
      }
    }
  }

  .createBtnDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .saveAsTemplate {
      cursor: pointer;
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 0.75rem;

        > label {
          margin-bottom: 0;
        }

        .checkmark {
          left: 0% !important;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
