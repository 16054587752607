@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .inputContainer {
      display: flex;
      column-gap: 17px;

      .input {
        position: relative;

        label {
          color: rgba(255, 255, 255, 0.75);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.09px;
        }

        input {
          background: transparent;
          color: $white;

          border-radius: 8px;
          border: 1px solid rgba(78, 83, 107, 0.8);
          background: rgba(255, 255, 255, 0);

          &:focus {
            border-radius: 8px;
            border: 1px solid #a0a3b0;
            background: rgba(255, 255, 255, 0);
            box-shadow: 0px 0px 0px 3px rgba(224, 229, 233, 0.12);
          }
        }
      }

      .error {
        position: absolute;
        bottom: -24px;
      }
    }

    .link {
      color: #b9d0ff;
      font-weight: 500;
    }
  }
}
