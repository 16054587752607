@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskModal {
  [class='ant-modal-content'] {
    padding: 40px;
    padding-bottom: 0;
  }
}
