@import 'styles/functions';
@import 'styles/variables';

.dateTime {
  font-size: setUnit(16);
  font-weight: 400;
  line-height: setUnit(16);
  letter-spacing: -0.33px;

  h3 {
    margin: 0 0 4px 0;
    font-size: setUnit(16);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.33px;
  }

  p {
    color: $text-gray-color;
    margin: 0;
  }

  @media (min-width: $tablet) {
    width: 12vw;
  }
}
