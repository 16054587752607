@import 'styles/functions';
@import 'styles/variables';

.confirmationModal {
  text-align: center;

  [class*='ant-modal'] {
    text-align: center;
  }

  [class*='ant-modal-title'] {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $text-color;
  }

  .actions {
    display: inline-flex;
    margin-top: 2rem;

    .cancelBtn {
      margin-right: 0.25rem;

      .okBtn {
        margin-left: 0.25rem;

        .activateBtn {
          background-color: $blue;
        }
      }
    }
  }

  [class*='ant-btn-primary'] {
    background-color: $primary-color;

    &:hover {
      background-color: $hover-red;
    }
  }

  [class*='ant-modal-body'] {
    & [class*='ant-typography'] {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.33px;
      text-align: center;
      color: $dark-color;
    }
  }

  [class*='ant-modal-close'] {
    display: none;
  }
}

.text {
  display: block;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
