@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.pageWrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  padding-top: 24px;
  gap: 24px;

  .leftWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 24px;

    max-width: 600px;
    width: 100%;
    .noProperties {
      background-color: $white;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 416px;
      color: $text-gray-color;
      .title {
        color: $dark-grey-color;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
        letter-spacing: -0.09px;
        margin-bottom: 8px;
      }
      .subtitle {
        margin: 0;
        color: $text-gray-color;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.09px;
      }
    }
  }

  .rightWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    max-width: 356px;
    width: 100%;
  }
}

.card {
  cursor: pointer;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid $background-grey-2;
  background: $white;
  box-shadow: 0 2px 16px 0 rgba(48, 48, 48, 0.03);
  display: flex;
  flex-direction: column;
  gap: 12px;

  .heading {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .typeWrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        .iconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }

        .type {
          overflow: hidden;
          color: $text-color;
          text-overflow: ellipsis;
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.09px;
        }
      }

      .timeFromNow {
        color: $text-gray-color;
        text-align: right;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .price {
        color: $text-color;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.09px;
        margin-bottom: 0;

        display: flex;
        align-items: center;
        gap: 4px;

        .arrow {
          color: $text--light-gray-color;
          letter-spacing: -0.09px;

          font-feature-settings: 'liga' 1;
        }

        .previousPrice {
          color: $text--light-gray-color;
          text-decoration-line: line-through;
        }
      }

      .subDetails {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 4px;

        .address {
          color: $text-color;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.09px;
          margin-bottom: 0;
        }

        .addressSecondary {
          color: $text-gray-color;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.09px;
          margin-bottom: 0;
        }

        .distanceContainer {
          display: flex;
          justify-content: flex-end;
          gap: 6px;

          .distance {
            color: $text-gray-color;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.09px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .imageWrapper {
    display: flex;
    grid-gap: 4px;
    width: 100%;
    height: 260px;
  }

  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .img6 {
    width: 100%;
    height: 100%;
  }

  .img1 img,
  .img2 img,
  .img3 img,
  .img4 img,
  .img5 img,
  .img6 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img2 {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .innerWrapper {
    display: flex;
    gap: 4px;
  }
}
