@import "styles/functions";
@import "styles/variables";

.header {
    padding: setUnit(22) setUnit(32) setUnit(32);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
        display: flex;
        align-items: center;

        .editIcon {
            margin-right: setUnit(12);
            cursor: pointer;
            width: setUnit(40);
            height: setUnit(40);
            border-radius: 50%;
            background: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                opacity: .9;
            }

            .svg {
                vertical-align: middle;
            }
        }
    }

    .titleWrapper {
        display: flex;
        align-items: center;

        .commentIcon {
            svg {
                width: setUnit(32);
                height: setUnit(32);

                path {
                    stroke: $primary-color;
                }
            }
        }

        svg {
            vertical-align: middle;
            width: setUnit(35);
            height: setUnit(35);

            path {
                stroke: $primary-color;
            }
        }
    }
}
