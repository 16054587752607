@import 'styles/functions';
@import 'styles/variables';

.tasksActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.multiSelectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $light-gray;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &:hover {
    border: 2px solid $text--light-gray-color;
  }
}
