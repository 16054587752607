@import 'styles/variables';
@import './mixins.module.scss';

$createNewMenuItem: 83px;

.alignToCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeMenu {
  ul {
    & [class*='ant-menu-submenu-active'] {
      background-color: $background-ligh-gray !important;
    }

    li {
      :hover {
        background-color: $background-ligh-gray !important;
      }
    }
  }
}

.scrollableMenuItems {
  height: calc(100vh - $header-height - $createNewMenuItem);
  overflow: auto;
}

.createNewMenuItem {
  @extend .activeMenu;
  height: $createNewMenuItem;
  border-bottom: 1px solid $light-gray;

  & [class*='ant-menu-submenu-arrow'] {
    display: none;
  }

  & [class*='ant-menu-submenu'] {
    margin: 0px !important;
    padding: 0px !important;
  }

  & [class*='ant-menu-submenu-active'] {
    background-color: $background-ligh-gray !important;
  }

  & [class*='ant-menu-submenu-title'] {
    height: 82px !important;
    line-height: 82px !important;
  }

  .createNewMenuIcon {
    margin-right: 28px;
  }

  .menuItemCollection {
    height: 82px;
  }
}

.plainMenuItem {
  margin: 0px !important;
  padding: 0px 0px 0px 24px !important;

  font-size: 14px;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuItemCollection {
  display: flex;
  justify-content: left;
  align-items: center;
}

.menuItemIcon {
  @extend .menuItemCollection;
  margin: 0px 15px 0px 27px;
}

.menuItemOptions {
  @extend .menuItemCollection;

  padding-right: 10px !important;
  padding-left: 2px !important;

  background-color: transparent !important;

  & [class*='ant-menu-submenu-active'] {
    background-color: $light-gray !important;
  }
}

.menuItemContent {
  @extend .plainMenuItem;

  width: 100%;
  padding: 0px !important;
}

.menuPopup {
  @extend .activeMenu;

  ul {
    border-radius: 8px !important;

    & [class*='ant-menu-item-selected'],
    & [class*='ant-menu-item-active'] {
      background-color: $white !important;
    }
  }
}

.subMenuTitle {
  & [class*='ant-menu-submenu-title'] {
    @include subMenu(303px, 68px, 16px, 600);
  }
}

.subMenuItem {
  @include subMenu(303px, 68px, 16px, 600);
}

.nestedSubMenuItem {
  @include subMenu(200px, 64px, 16px, 600);
}

.folderSubMenu {
  border-radius: 100%;

  .folderSubMenuTitle {
    @extend .alignToCenter;
  }

  :hover {
    background-color: $light-gray !important;
  }

  & [class*='ant-menu-submenu'] {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    width: 32px !important;
    height: 32px !important;
  }

  & [class*='ant-menu-submenu-arrow'] {
    display: none;
  }

  & [class*='ant-menu-submenu-title'] {
    margin: 0px !important;
    padding: 0px !important;
  }
}

.folderSubMenuItem {
  @include subMenu(176px, 40px, 14px, 400);

  .menuItemIcon {
    margin-left: 22px;
    width: 16px;
    height: 16px;
  }
}
