@import "styles/functions";
@import "styles/variables";

.avatars {
    $offset: 5;

    .avatarsHolder {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;
        height: 100%;
    }

    .avatarWrapper {

        &.isNotSingleAvatar {
            margin-left: setUnit(-30);
        }

        &:first-child {
            margin-left: 0;
            position: relative;
            z-index: 1;

            &.isNotSingleAvatar {
                top: setUnit(-$offset);
            }
        }

        &:last-of-type {
            position: relative;
            z-index: 2;

            &.isNotSingleAvatar {
                top: setUnit($offset);
            }
        }
    }

    .overflowCount {
        width: setUnit(41);
        height: setUnit(41);
        background: rgba(0,0,0,.5);
        border: 2px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: setUnit($offset);
        z-index: 3;
    }

    .avatar {
        border: 2px solid #ffffff;
        width: setUnit(56);
        height: setUnit(56);

        &.isNotSingleAvatar{
            width: setUnit(42);
            height: setUnit(42);
        }
    }
}
