@import 'styles/mixins';
@import 'styles/variables';

.fileBox {
  width: 100%;
  border: 2px solid $light-gray;
  height: 72px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 16px;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .fileNameBox {
    display: flex;
    align-items: center;
    width: 90%;

    .fileNameWrapper {
      width: 95%;
    }
    .fileIcon {
      margin-right: 12px;
    }

    .fileSize {
      line-height: 16px;
      color: $text--light-gray-color;
      font-weight: 400;
    }
  }

  .deleteIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .fileName {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    width: 90%;
  }
}
