@import 'styles/functions';
@import 'styles/variables';

.emptyPlaceholderChartsContainer {
  background-color: $background-grey-2;
  height: 435px;
  margin-top: 18px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;

  span {
    font-size: 16px;
    color: $text--light-gray-color;
    margin-left: 8px;
  }
}
