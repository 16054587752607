@import 'styles/functions';
@import 'styles/variables';

.feesContainer {
  padding: setUnit(12) 0 setUnit(12) setUnit(14);
  position: relative;
}

.headerCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.dullSection {
    opacity: 0.5;
  }
}

.addFeeCon {
  display: flex;
  cursor: pointer;
  gap: 5px;
}

.addFee {
  margin-right: 21px;
  color: $link-blue;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.01125rem;
}

.title {
  font-weight: 600;
  font-size: setUnit(20);
}
.content {
  padding-right: setUnit(14);
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
  &.dullSection {
    opacity: 0.5;
  }
}

.footer {
  position: relative;
  padding-right: 0.875rem;
  margin-right: 6px;
  .fadeContainer {
    position: absolute;
    width: 100%;
    height: 0px;
    top: -15px;
    background-color: #ffffff8f;
  }
  .seperator {
    margin-top: 0;
  }
  &.dullSection {
    opacity: 0.5;
  }
}

.buttonsContainer {
  margin-top: setUnit(20);
  display: flex;
  gap: 12px;
  button {
    border-radius: 8px;
    width: 100%;
  }
}

.bold {
  font-weight: 600 !important;
}
.marginBottom {
  margin-bottom: setUnit(20);
}
.marginTop {
  margin-top: setUnit(20);
}
.inputHeight {
  height: 48px !important;
}
.addContainer {
  padding: setUnit(12) setUnit(14);
}

.disabledSection {
  opacity: 0.3;
}

.applyLabel {
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: $text-color;
  font-size: 0.875rem;
}

.checkboxCont {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}
