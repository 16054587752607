.formContainer {
  row-gap: 16px;
}

.backBtn {
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 12%;
  left: 40px;
  cursor: pointer;
}
