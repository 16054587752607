@import 'styles/variables';
@import 'styles/functions';

.button {
  $border-radius: setUnit(8);
  $border-color: $border-input-middle-gray;
  $active-border-color: $primary-color;

  border: 1px solid $border-color;
  background: none;
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: setUnit(14) 0;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: $mobile) {
    min-width: setUnit(70);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-right: 1px solid $border-color;
  }

  &.active {
    background: #fff4f3;
    border-color: $active-border-color;

    & + button {
      border-left-color: $active-border-color;
    }
  }
}
