@import "styles/functions";
@import "styles/variables";

.editActions {
    margin-top: setUnit(24);
    display: flex;
    align-items: center;

    .btn {
        width: setUnit(96);
        height: setUnit(36);
        font-weight: 600;

        .loader {
            font-size: setUnit(3);
        }

        &.cancel {
            margin-left: setUnit(12);
            background: none;
            color: $secondary-color;
            border: 1px solid $secondary-color;
        }
    }
}
