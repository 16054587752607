@import 'styles/functions';
@import 'styles/variables';

.itemWrapper {
  min-width: setUnit(256);
  max-width: setUnit(256);
  margin-right: setUnit(32);

  .pendingWrapper {
    height: 100%;
  }

  .loader {
    font-size: setUnit(3);
    margin-top: setUnit(5);
    margin-bottom: setUnit(14);
  }

  &:last-child {
    margin-right: 0;
  }
}
