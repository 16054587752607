@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.footer {
  @include box-shadow;

  height: setUnit(76);
  padding: 0 setUnit(40);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .doneBtn {
    font-weight: 600;
    font-size: 16px;
    width: setUnit(108);
    height: setUnit(44);
    border-radius: setUnit(8);
  }

  .count {
    color: $dark-color;
    font-size: 16px;
    font-weight: 600;
  }
}
