@import 'styles/functions';
@import 'styles/variables';

.common {
  padding: 0;

  [class*='ant-modal-content'] {
    padding: 0 !important;
  }
}
