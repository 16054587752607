@import 'styles/functions';
@import 'styles/variables';
.pageDescription {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: $dark-color;
  text-align: center;
}

.viewSelectorText {
  display: flex !important;
  flex-direction: row;
  font-size: 20px !important;
  align-items: center;
  justify-content: center !important;
  font-weight: 300;
  @media screen and (max-width: $desktop) {
    flex-direction: column;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $desktop) {
      display: block;
    }

    &:not(:last-child) {
      margin-right: 16px;

      @media screen and (max-width: $desktop) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    .viewSelectorSelects {
      font-size: 20px !important;
      font-weight: 800 !important;
      border-bottom: 2px solid #d6d6d7;
      min-width: 200px;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.iconContainer {
  background-color: $background-gray;
  width: setUnit(32);
  height: setUnit(32);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background: $border-light-gray;
  }

  button {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-top: 3px;
  }
}
