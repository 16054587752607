@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.documentDots {
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: $light-gray;
  }
  &:hover {
    background-color: $light-gray;
  }
}

.documentPopover {
  [class*='ant-popover-inner'] {
    border-radius: 8px !important;
  }
  z-index: 99 !important;
}

.documentOptions {
  @include box-shadow;
  background: #ffffff;
  border-radius: setUnit(8);
  min-width: setUnit(170);
  padding: 0;
  z-index: 1;

  ul {
    list-style: none;
    margin-bottom: 0.5rem;
  }

  .item {
    padding: setUnit(10) setUnit(22) setUnit(10) setUnit(22);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background: $background-ligh-gray;
      &:first-child {
        &:last-child {
          border-top-right-radius: setUnit(8) !important;
          border-top-right-radius: setUnit(8) !important;
        }
      }
      &:last-child {
        border-bottom-right-radius: setUnit(8) !important;
        border-bottom-right-radius: setUnit(8) !important;
      }
    }
  }

  svg {
    vertical-align: middle;
  }
}

.deleteDocumentDialog {
  .content {
    font-size: 16px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 700;
    }
  }
}
