@import 'styles/functions';
@import 'styles/variables';

.modalContainer {
  & [class*='ant-modal-content'] {
    padding: 56px 80px 40px 80px !important;
    text-align: center;

    & [class*='ant-modal-header'] {
      margin-bottom: 24px;
    }
  }

  .modalIcon {
    width: 100%;
    text-align: center;
  }

  .modalTitle {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $text-color;
    text-align: center;
    display: block;
    letter-spacing: -0.47px;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  .modalSubTitle {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
    text-align: center;
    display: block;
    margin-top: 4px;
    margin-bottom: 24px;
    letter-spacing: -0.33px;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  .locationInput {
    max-width: unset !important;
  }

  .modalButton {
    margin-top: 48px;
    border-radius: 8px !important;
    font-size: 16px !important;
    letter-spacing: -0.187px;
    padding: 14px 42px !important;
    height: fit-content !important;
  }
}
