@import 'styles/variables';
@import 'styles/functions';

.activityLogWrapper {
  background: $white;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.03);
  border-radius: setUnit(12);
  overflow: hidden;

  .noDataFound {
    text-align: center;
    font-size: 20px;
    margin: 5rem;
  }
}
