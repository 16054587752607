@import 'styles/functions';
@import 'styles/variables';

.documentHeader {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: setUnit(32) setUnit(40);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  z-index: 10;

  .headerTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    min-height: 32px;
  }

  .leftSide {
    position: absolute;
    left: 40px;
  }

  .rightSide {
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    gap: setUnit(16);
  }

  .iconBtn {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .secondaryBtn {
    border-radius: setUnit(100);
    padding: setUnit(10) setUnit(16);
    height: setUnit(44);
    transition: $transition;
    display: flex;
    align-self: center;
    gap: 8px;

    &:hover {
      opacity: 0.9;
      background-color: $secondary-color;
    }
  }
}
