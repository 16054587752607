@import 'styles/functions';
@import 'styles/variables';

.titleFreeText {
  .search {
    padding-bottom: 0 !important;
    .value {
      &:after {
        border: none;
      }
      input {
        border: 1px solid $border-light-gray-2;
        border-radius: 8px;
        padding-left: 16px;
        &:focus {
          border-color: $text-gray-color;
        }
      }
    }
    .arrowIcon {
      right: 12px;
    }
  }

  .option {
    height: setUnit(52);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: transparent;
    text-align: left;
    font-size: setUnit(16);
    line-height: setUnit(20);

    padding: setUnit(10) setUnit(15);

    .optionInnerHolder {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: setUnit(32);
      height: setUnit(32);
      margin-right: setUnit(15);

      .avatarPlaceholder {
        font-size: setUnit(14);
      }
    }

    &:hover {
      background-color: $grey-color;
    }

    &.is-highlighted {
      background-color: $grey-color;
    }

    &.is-selected {
      background-color: $grey-color;
    }
  }
}
