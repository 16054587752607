.quillEditorWrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0 5px 0;

  .label {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }

  .editor {
    .ql-toolbar {
      background: #f0f0f0;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    .ql-container {
      border: 1px solid #ccc;
      min-height: 200px;
    }
  }

  .errorText {
    color: red;
    margin-top: 5px;
    font-size: 12px;
  }

  &.error {
    .editor {
      .ql-toolbar {
        border-color: red;
      }

      .ql-container {
        border-color: red;
      }
    }
  }
}
