@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.main {
  height: calc(100vh - 225px);
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    .heading {
      color: $text-color;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;
      margin: 0;
    }
    .subHeading {
      margin: 0;
      margin-top: 6px;
      text-align: center;
      color: $text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      letter-spacing: -0.33px;
    }
    .button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;
      background: $white;
      border-radius: 100px;
      background: $white;
      &.active {
        background: $background-blue;
      }
      svg {
        position: relative;
        top: 3px;
      }
    }
  }
  .tableContainer {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    max-width: 980px;
  }
}
