@import 'styles/functions';
@import 'styles/variables';

.cta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &Content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &Heading {
      color: #000000;
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-family: $font-family;
      letter-spacing: setUnit(-0.4);
    }

    &Area {
      background-color: $background-gray;
      border-radius: setUnit(16);
      padding: setUnit(32);
      width: setUnit(320);

      .text {
        font-weight: 400;
        font-size: setUnit(20);
        line-height: setUnit(32);
        font-family: $font-family;
        letter-spacing: setUnit(-0.18);
        color: #252d44;
        margin: 0;
      }

      .contact {
        cursor: pointer;
        color: $link-blue;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        margin-bottom: setUnit(8);
        margin: 0;
      }

      .bold {
        font-weight: 500;
      }

      .marginShort {
        margin-bottom: setUnit(20);
      }

      .marginLong {
        margin-bottom: setUnit(32);
      }

      .messageButton {
        color: $link-blue;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
      }
    }
  }
}
