@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/functions';

.selectWrapper {
  .fieldHolder {
    position: relative;
  }

  &.full {
    @include inputFull;

    .input {
      border-width: 1px;
      border-radius: setUnit(5);
    }
  }

  &.lightFull {
    @include inputLightFull;

    .fieldHolder {
    }
  }

  &.light {
    @include inputLight;

    .searchIcon {
      position: absolute;
      top: setUnit(13);
    }

    .input {
      padding: setUnit(10) setUnit(10) setUnit(10) setUnit(30);
    }
  }

  .altLabel {
    font-size: setUnit(14);
    color: $light-black;
    font-weight: 400;
  }

  .error {
    @include inputError;
  }
}

.container {
  width: 100%;

  &.light {
    margin-bottom: 0;
  }

  .value {
    width: 100%;
    position: relative;

    &.light {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(50% - #{setUnit(5)});
      right: setUnit(15);
      width: setUnit(7);
      height: setUnit(7);
      transform: rotate(45deg);
      border-right: 2px solid $dark-grey-color;
      border-bottom: 2px solid $dark-grey-color;
      pointer-events: none;
    }

    .input {
      cursor: pointer;
      position: relative;
      width: 100%;
      padding-right: setUnit(30);
      text-overflow: ellipsis;
    }
  }

  &.has-focus {
  }

  .select {
    position: relative;
    width: 100%;

    &.light {
      margin-top: 0;
    }

    .options {
      $options-min-height: setUnit(20);

      @include box-shadow;

      margin-top: setUnit(2);
      border: 1px solid $grey-color;
      border-radius: setUnit(5);
      width: 100%;
      list-style: none;
      padding: 0;
      position: absolute;
      background-color: #ffffff;
      z-index: 99;
      max-height: setUnit(200);
      min-height: $options-min-height;
      overflow: auto;

      .emptyMessage {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $options-min-height;
        padding: setUnit(13);
      }

      li {
        &.light {
          margin: 0;
        }

        &.lightFull {
        }

        &.full {
        }
      }

      .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        cursor: pointer;
        background-color: transparent;
        text-align: left;

        &.light {
          padding: setUnit(15) setUnit(15);
          cursor: pointer;

          &:hover {
            background-color: $grey-color;
          }

          &.is-highlighted {
            background-color: $grey-color;
          }

          &.is-selected {
            background-color: $grey-color;
          }
        }

        &.lightFull {
          padding: setUnit(15) setUnit(15);
          cursor: pointer;

          &:hover {
            background-color: $grey-color;
          }

          &.is-highlighted {
            background-color: $grey-color;
          }

          &.is-selected {
            background-color: $grey-color;
          }
        }

        &.full {
          padding: setUnit(15) setUnit(15);
          cursor: pointer;

          &:hover {
            background-color: $grey-color;
          }

          &.is-highlighted {
            background-color: $grey-color;
          }

          &.is-selected {
            background-color: $grey-color;
          }
        }
      }

      .checkbox {
        margin-bottom: 0;
      }

      &.is-loading {
        display: block;
      }

      .group {
        display: block;

        &-header {
          background-color: $grey-color;
          padding: setUnit(5) setUnit(10);
        }
      }
    }
  }
}
