@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.statusContainer {
  display: flex;
  justify-content: flex-end;

  @media (max-width: $mobile) {
    justify-content: center;
    margin-top: 12px;
  }
}

.status {
  height: setUnit(40);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(100);
  position: relative;
  background-color: $white;
  transition: $transition;
  &:hover {
    background-color: $background-grey-2;
  }
  &.fullAccess {
    cursor: pointer;
  }

  .accent {
    font-weight: 600;
    display: inline-block;
    margin-left: setUnit(4);
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(16);
    height: 100%;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    font-weight: 400;
    .arrowIcon {
      margin-left: setUnit(4);
    }
  }
}
