@import 'styles/functions';
@import 'styles/variables';

.timelineComponent {
  display: flex;
  position: relative;
  .inputsWrapper {
    width: 100%;
  }

  .timelineItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    gap: 0 16px;
    &:not(:last-child) {
      padding-bottom: 32px;
      @media (max-width: $responsive-menu) {
        padding-bottom: 25px;
      }
    }
    .inputsWrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 24px;
      max-width: calc(100% - 96px);
      width: 100%;
      .timelineName {
        max-width: calc(60% - 12px);
        width: 100%;
      }
      .datePickerWrap {
        position: relative;
        display: flex;
        align-items: center;
        max-width: calc(40% - 12px);
        width: 100%;
        .timelineDateControlPicker {
          border-bottom: 0;
          position: absolute;
          top: 49%;
          left: 0.5rem;
          transform: translateY(-50%);
          z-index: 1;
          cursor: pointer;
          svg {
            position: relative;
            top: unset;
          }
        }
        .datePickerInput {
          input {
            padding-left: 39px !important;
          }
        }
        .errorMsg {
          position: absolute;
          bottom: -1.3rem;
          right: 0rem;
          width: fit-content;
          color: $error-color;
        }
      }
    }

    &.afterLine {
      &::after {
        content: '';
        border: 1px solid $background-grey-2;
        height: 100%;
        position: absolute;
        top: 40px;
        left: 15px;
        z-index: 1;
      }
    }

    &.beforeLine {
      &::before {
        content: '';
        border: 1px solid $background-grey-2;
        height: calc(100% + 10px);
        position: absolute;
        top: -43px;
        left: 15px;
        z-index: 1;
      }
    }

    .enterToAddText {
      display: flex;
      align-items: center;
      cursor: default;
    }

    .enterToAddTextDisabled {
      opacity: 0.5;
    }

    .deselectIconSpace {
      input {
        padding-right: 25px;
      }
    }

    .deselectInitialIcon {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 1px;
      right: 20px;

      .icon {
        cursor: pointer;
      }
    }

    .deadlineOffset {
      margin: 0;
      width: 100% !important;
    }

    .deadlineOffsetHolder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100% !important;
    }

    .deadlineOffsetInput {
      padding-left: setUnit(36) !important;
      width: 100% !important;
    }

    .deadlineOffsetIcon {
      position: absolute;
      left: 0;
    }

    .add {
      display: flex !important;
      align-items: center !important;
      cursor: pointer;
      width: fit-content;
      color: $secondary-color;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: baseline;
      .addPointWrapper {
        margin-right: 16px;
      }
    }

    .addMilestoneDisabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      div {
        cursor: not-allowed !important;
      }
    }

    .addPoint {
      background: $background-ligh-gray;
      margin-right: setUnit(10);
    }

    .tip {
      font-size: setUnit(14);
      color: $dark-grey-color;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .accent {
        font-weight: 600;
        margin: 0 setUnit(4);
        display: inline-block;
      }

      .enterIcon {
        margin-left: setUnit(8);
      }
    }
  }
  .lastRow {
    justify-content: space-between;
  }
}
