@import 'styles/variables';

.formSearchBox {
  background: $background-ligh-gray !important;
  border: none !important;

  & [class*='ant-input-prefix'] {
    font-size: 18px !important;
    margin-right: 10px !important;
  }
  & [class*='ant-input'] {
    background: $background-ligh-gray !important;
    color: $text-color !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.filterButton {
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }
}

.formFilterRow {
  & [class*='ant-popover-arrow'] {
    display: none;
  }

  & [class*='ant-popover-inner'] {
    border-radius: 8px !important;
  }
}

.formFilterRow > span {
  display: contents;
}

.formFilterIcon {
  height: 100%;
  margin-top: 7px;
  margin-right: 10px;
}

.formSearchFilter {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
