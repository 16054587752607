@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.filterWrapper {
  background: white;
  padding: 4px 8px;
  max-width: 356px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  height: fit-content;

  .checkboxWrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 2px;
    overflow: hidden;
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.09px;

    .flex {
      display: flex;
      gap: 14px;
      align-items: center;

      .iconWrapper {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 50%;
      }
    }

    .checkbox {
      [class*='ant-checkbox'] {
        [class*='ant-checkbox-inner'] {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border-color: $text--light-gray-color;
        }
      }

      [class*='ant-checkbox-checked'] {
        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color;
        }
        [class*='ant-checkbox-inner']::after {
          width: 7px;
          height: 12px;
        }
      }
      &:hover,
      &:focus {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color;
        }
      }
    }
  }
  .tagWrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .tag {
      border-radius: 16px;
      border: 1px solid var(--grayscale-25-outline, #d6d6d7);
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.18px;
      cursor: pointer;

      &.active {
        border-radius: 16px;
        background: linear-gradient(90deg, #ff576d -92.22%, #fb913a 99.9%);
        color: var(--White, #fff);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 123.077% */
        letter-spacing: -0.18px;
      }
    }
  }
}
