@import 'styles/functions';
@import 'styles/variables';

.areaGraph {
  [class~='g2-tooltip'] {
    box-shadow: 0px 2px 12px 0px #00000026 !important;
    border-radius: 12px !important;
    padding: 16px !important;
    opacity: 1 !important;
  }
}
