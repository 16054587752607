@import 'styles/variables';

.commentBox {
  width: 300px;
  display: flex;
  flex-direction: column;

  .comment {
    font-size: 16px;
    margin-top: 10px;

    .viewComment {
      cursor: default;
      overflow-wrap: break-word;

      .mentionedUser {
        color: $link-blue;
      }
    }

    .editingMode {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .inputContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 40px;
        width: 250px;
        margin-right: 10px;
      }

      .sendContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.panelComment {
  width: 100% !important;

  .panelCommentText {
    margin-left: 44px;
  }
}

.sendIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }
}

.sendIconActive {
  @extend .sendIcon;
  background: $background-blue;
  cursor: pointer;

  &:hover {
    background: $background-blue;
  }
}

.sendingLoader {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
