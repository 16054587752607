@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  margin-bottom: 72px;
}

.counter {
  margin-bottom: 48px;
}

.add {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    fill: $dark-color;
  }
}

.remove {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    stroke: $dark-color;
  }
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: setUnit(675) !important;
  width: 100%;
  .innerHolder {
    padding: 16px 80px 40px;
    @media (min-width: $mobile) {
      padding: 16px 138px 40px;
    }
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}

.bottom {
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  .saveButton {
    background-color: $background-blue !important;
    min-width: 154px;
    min-height: 52px;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: block;
    border-radius: 0.5rem;

    &:hover {
      background-color: $secondary-color-hover !important;
    }
  }
}

.garageSwitch {
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin-right: 8px;
    margin-bottom: 0;
  }
}
