@import 'styles/functions';
@import 'styles/variables';

.transactions {
  background-color: $background-ligh-gray-3;

  .transactionContainer {
    min-height: calc(100vh - $header-height - $footer-height - $workshop-header-height - 2px);
    padding: 16px 0;
  }
}
