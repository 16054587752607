@import 'styles/functions';
@import 'styles/variables';

.extraPadding {
  padding: 10px 14px !important;
}

.headerTag {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);

  color: #fff;
  font-family: Sora, Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.35px;

  padding: 28px 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & span {
    font-weight: 600;
    letter-spacing: -0.35px;
    margin-right: 4px;
  }

  @media screen and (max-width: $mobile) {
    display: unset;
  }
}

.heading {
  color: var(--White, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Sora, Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  letter-spacing: -0.8px;
}

.info {
  color: rgba(255, 255, 255, 0.8);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 172.222% */
  letter-spacing: -0.18px;

  span {
    color: white;
    font-weight: 500;
  }

  .actionBtn {
    a {
      color: white !important;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: -0.18px;
      text-decoration-line: underline;
    }
  }
}

.btnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  row-gap: 20px;
  flex-direction: column;

  .continue {
    border-radius: 12px;
    background: linear-gradient(90deg, #ff5d69 13.49%, #fc8545 138.32%);
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    padding-block: 14px;
    min-width: 355px;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
      min-width: 100%;
    }
  }

  .skip {
    border-radius: 12px;
    border: 1.5px solid #ff576d;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    padding: 14px 58px;

    min-width: 150px;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
      min-width: 100%;
    }
  }
}

.inputWrapper {
  display: flex;
  column-gap: 12px;

  .input {
    border-radius: 16px;
    border: 1.5px solid rgba(79, 83, 102, 0.8);
    background: #252938;
    height: 90px;
    width: 83.5px;
    text-align: center;

    color: var(--White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 90% */
    letter-spacing: -0.8px;
    caret-color: transparent;

    transition: all;
    transition-duration: 0.3s;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input:focus,
  .input:focus-visible {
    outline: none !important;
    border: 1.5px solid #a0a3b0;
  }

  .invalid {
    border: 1.5px solid rgba(255, 134, 150, 0.8);
  }

  .invalidCodeText {
    color: rgba(255, 134, 150);
  }
}
