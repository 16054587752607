@import "styles/functions";
@import "styles/variables";

.linksInfo {
    .links {
        align-items: end;
    }

    .link {
        display: inline-block;
        color: $link-blue;
        text-decoration: none;
    }

    .linkHolder {
        .link {
            margin-bottom: setUnit(4);
        }

        &:last-child {
            .link {
                margin-bottom: 0;
            }
        }
    }
}
