@import 'styles/functions';
@import 'styles/variables';

.myKits {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-left: setUnit(48);
    padding-right: setUnit(48);
    margin: setUnit(12);

    @media screen and (max-width: $desktop) {
      flex-direction: column;
      align-items: start;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      .back {
        z-index: 1;
        overflow: hidden;
        padding: 0;
        width: setUnit(44);
        height: setUnit(44);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $light-gray;
        background: #fff;
        transition: $transition;
        margin-right: setUnit(16);

        &:hover {
          background-color: $light-gray;
          opacity: 1;
        }

        &:focus {
          background-color: $border-light-gray-2;
        }

        div {
          margin-right: 0;
          display: flex;

          svg {
            width: setUnit(8);
            height: setUnit(14);

            path {
              stroke: $dark-color;
            }
          }
        }

        span {
          display: none;
        }

        &:hover {
          color: $text-color;
        }
      }
    }

    .heading {
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-family: $font-family;
      letter-spacing: setUnit(-0.4);
      color: $text-color;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .options {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $desktop) {
        margin-top: setUnit(24);
      }

      .icon {
        cursor: pointer;
        margin-left: setUnit(16);
        display: flex;
        justify-content: center;
        align-items: center;
        height: setUnit(44);
        width: setUnit(44);
        border-radius: 50%;
        border: 2px solid $background-ligh-gray-4;

        &:hover {
          border: 2px solid $text--light-gray-color;
        }
      }
    }
  }

  &Content {
    padding-left: setUnit(48);
    padding-right: setUnit(48);
    flex-grow: 1;
    background-color: $background-ligh-gray-3;
  }

  &Footer {
    padding-left: setUnit(48);
    padding-right: setUnit(48);
  }
}
