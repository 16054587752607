@import 'styles/functions';
@import 'styles/variables';

.partner {
  .addName {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
  }
  .locations {
    padding-left: 0 !important;
  }
  .input {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
