@import 'styles/variables';

.manualAddressContainer {
  .addressLineWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .inputSpacing {
      margin-bottom: 15px;
    }
  }

  .autoCompleteInputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 20px;

    [class*='ant-space-item'] {
      width: 100%;
    }

    .searchWrapperExternal {
      width: 100% !important;
      input {
        padding-left: 0px !important;
        font-size: 18px !important;
        color: $text-color;
        transition: $transition;
        &:focus {
          border-bottom-color: $text-gray-color;
        }
        &::placeholder {
          font-size: 18px !important;
          color: $text-gray-color;
        }
      }
    }
  }
}
