@import 'styles/functions';
@import 'styles/variables';

.attachment {
  height: setUnit(60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $background-ligh-gray;
  border-radius: setUnit(8);
  padding: setUnit(32);
  margin: setUnit(8) setUnit(32);
  cursor: pointer;
  color: $text-color;
  position: relative;

  .info {
    flex: 1;

    .filename {
      font-weight: 600;
      font-size: setUnit(14);
    }

    .date {
      font-size: setUnit(12);
      margin-top: setUnit(4);
    }
  }

  .icon {
    margin-right: setUnit(19);

    svg {
      vertical-align: middle;
    }
  }
}
