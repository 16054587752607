@import 'styles/variables';

.termsAndConditionsModal {
  [class*='ant-modal-header'] {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    [class*='ant-modal-title'] {
      font-weight: 700;
      font-size: 24px;
    }
  }
}

.terms {
  overflow: auto;
  max-height: 300px;
  margin-top: 30px;

  p {
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    color: $text-color;
  }
}

.footer {
  border-top: 1px solid $light-gray;
  padding-top: 26px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $mobile-small) {
    display: block;
  }

  .agreement {
    text-align: left;
    width: 290px;
    font-weight: 500;
    font-size: 16px;
  }

  .agreeBtn {
    height: 52px;

    @media screen and (max-width: $mobile-small) {
      margin-top: 20px;
      text-align: center;
    }

    button {
      width: 180px;
    }
  }
}
