@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/functions';

.selectWrapper {
  position: relative;

  .required {
    color: $primary-color;
  }

  .selectHolder {
    position: relative;
    .customIcon {
      position: absolute;
      left: 20px;
      top: 15px;
    }
    .arrowIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      cursor: pointer;
      path {
        stroke: $dark-color;
      }
    }
  }

  &.full {
    @include inputFull;

    .input {
      border-width: 1px;
      border-radius: setUnit(5);
    }
  }

  &.lightRound {
    @include inputLightRound;
  }
  &.lightFull {
    @include inputLightFull;
  }
  &.darkHalf {
    @include inputLightFull;
  }

  &.light {
    @include inputLight;

    .input {
      padding: setUnit(10) setUnit(10) setUnit(10) 0;
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
        color: $text--light-gray-color;
      }
    }

    .selectHolder {
      &.isSearchIcon {
        .input {
          padding: setUnit(10) setUnit(10) setUnit(10) setUnit(30);
        }
      }
    }
  }

  .altLabel {
    font-size: setUnit(14);
    color: $border-middle-gray;
    font-weight: 400;
  }

  .error {
    @include inputError;

    position: absolute;
  }
}

.container {
  width: 100%;
  &.light {
    margin-bottom: 0;
  }
  .value {
    width: 100%;
    position: relative;

    &.light {
      margin-bottom: 0;
    }

    .input {
      cursor: pointer;
      position: relative;
      z-index: 1;
      background-color: transparent;
      width: 100%;
      padding-right: setUnit(30);
      text-overflow: ellipsis;
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
        color: $text--light-gray-color;
      }
    }
  }
  .select {
    position: relative;
    width: 100%;
    z-index: 99999;
    &.light {
      margin-top: 0;
    }
    .options {
      $options-min-height: setUnit(20);
      @include box-shadow;
      margin-top: setUnit(2);
      border: 1px solid $background-grey-2;
      border-radius: setUnit(5);
      width: 100%;
      list-style: none;
      padding: 0;
      position: absolute;
      background-color: $white;
      z-index: 99;
      min-height: $options-min-height;
      overflow: auto;
      max-height: setUnit(264);
      .emptyMessage {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $options-min-height;
        padding: setUnit(13);
      }
      li {
        &.light {
          margin: 0;
        }
      }

      .option {
        height: setUnit(52);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        cursor: pointer;
        background-color: transparent;
        text-align: left;
        font-size: setUnit(16);
        line-height: setUnit(20);
        &.light {
          padding: setUnit(10) setUnit(16);
          cursor: pointer;
          &:hover {
            background-color: $background-grey-2;
          }
          &.is-highlighted {
            background-color: $background-grey-2;
          }
          &.is-selected {
            background-color: $background-grey-2;
          }
        }

        &.lightRound {
          padding: setUnit(10) setUnit(16) !important;
          cursor: pointer;

          &:hover {
            background-color: $background-grey-2;
          }

          &.is-highlighted {
            background-color: $background-grey-2;
          }

          &.is-selected {
            background-color: $background-grey-2;
          }
        }

        &.lightFull {
          padding: setUnit(10) setUnit(16);
          cursor: pointer;

          &:hover {
            background-color: $background-grey-2;
          }

          &.is-highlighted {
            background-color: $background-grey-2;
          }

          &.is-selected {
            background-color: $background-grey-2;
          }
        }

        &.full {
          padding: setUnit(16);
          cursor: pointer;

          &:hover {
            background-color: $background-grey-2;
          }

          &.is-highlighted {
            background-color: $background-grey-2;
          }

          &.is-selected {
            background-color: $background-grey-2;
          }
        }

        &.darkHalf {
          justify-content: flex-start;
          padding: setUnit(16);
          cursor: pointer;

          &:hover {
            background-color: $background-grey-2;
          }

          &.is-highlighted {
            background-color: $background-grey-2;
          }

          &.is-selected {
            background-color: $background-grey-2;
          }
        }
      }

      .checkbox {
        width: setUnit(24);
        height: setUnit(24);
        border-radius: 4px;
        &.checkboxEnd {
          margin: 0 0 0 setUnit(8);
        }
        &.checkboxStart {
          margin: 0 setUnit(8) 0 0;
        }
      }

      &.is-loading {
        display: block;
      }

      .group {
        display: block;

        &-header {
          background-color: $background-grey-2;
          padding: setUnit(5) setUnit(10);
        }
      }
    }
  }
}

.clearButton {
  border-radius: 50%;
  background-color: $white !important;
  width: setUnit(16);
  height: setUnit(16);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: setUnit(8);
  position: absolute;
  top: setUnit(-2);
  right: setUnit(-2);
  padding: 0 !important;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(14);

    path {
      fill: $border-input-middle-gray;
    }

    &:hover {
      path {
        fill: $text-color;
      }
    }
  }
}

.flexWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectedOptionHeading {
  font-weight: 500;
}
