@import 'styles/variables';
@import 'styles/functions';

.popoverContent {
  [class*='ant-popover-inner'] {
    border-radius: 8px;

    [class*='ant-popover-inner-content'] {
      padding: 0px !important;
    }
  }

  .popoverOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px !important;
    padding: 0px 16px;

    .optionLabel {
      margin-left: 8px;
    }

    &:hover {
      background-color: $hover-light-gray;
    }
  }
}

.strokeToolTipContainer {
  width: 156px !important;

  .strikeLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 22px;
    &:hover {
      cursor: pointer;
      background-color: $background-grey-2;
    }

    .strikeLabelColumn {
      display: flex;
      align-items: center;

      .stroke {
        background-color: $text-color;
        width: 20px;
        margin-right: 12px;
      }

      .strokeNumber {
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  [class~='ant-tooltip-arrow'] {
    display: none;
  }

  [class~='ant-tooltip-inner'] {
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 8px;
  }
}

.lineStrokeContainer {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: rgba($dark-color, 0.5);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.strokePopoverWrapper {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
