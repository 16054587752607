@import 'styles/functions';
@import 'styles/variables';

.actions {
  display: flex;
  align-items: center;

  .btn {
    width: setUnit(87);
    height: setUnit(40);
    border-radius: setUnit(100);

    .loader {
      font-size: setUnit(3);
      margin: 0;
    }

    .btnTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: setUnit(14);
        font-weight: 500;
      }
    }

    .btnIcon {
      margin-right: setUnit(5);
    }

    &.add {
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }

    &.cancel {
      background: $background-ligh-gray;
      color: $dark-color;
      margin-left: setUnit(12);

      &:hover {
        background: darken($background-ligh-gray, 5);
      }
    }

    &.edit {
      width: unset !important;
      height: setUnit(40);
      border-radius: setUnit(50);
      background: $background-ligh-gray;
      color: $dark-color !important;
      margin-left: setUnit(12);
      padding: 0px 5px 1px 10px !important;
      &:hover {
        background: darken($background-ligh-gray, 5);
      }
    }
  }
}
