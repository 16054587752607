@import 'styles/functions';
@import 'styles/variables';

.preForm {
  padding: 0 40px;
  @media (min-width: $tablet) {
    position: relative;
  }

  .head {
    flex: 0 0 100%;
    margin: 34px 0 54px;

    @media (min-width: $tablet) {
      padding: 34px 0 54px;
      margin: unset;
      z-index: 2;
      position: relative;
    }
  }
}
