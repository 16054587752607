@import 'styles/variables';

.container {
  background: $white !important;
  margin: auto;
  // height: 488px;
  // overflow: auto;
}

.disabledNotification {
  opacity: 50%;
  cursor: not-allowed !important;
}

.deferNotification {
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  .deferNotificationText {
    margin-left: 5px;
  }

  [class*='ant-popover-inner'] {
    border-radius: 8px;

    [class*='ant-popover-inner-content'] {
      padding: 0px !important;
    }
  }

  .popoverOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px !important;
    padding: 0px 16px;

    .optionLabel {
      margin-left: 8px;
    }

    &:hover {
      background-color: $hover-light-gray;
    }
  }
}

.adminContainer {
  width: 820px;
  box-shadow: 0px 2px 16px 0px #30303008;
}

.content {
  margin: 120px 120px 120px 120px;
}

.questionInput {
  font-size: 32px !important;
  font-weight: 600;
  line-height: 36px !important;
  letter-spacing: -0.8px;
  text-align: center;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
}

.questionView {
  @extend .questionInput;
  cursor: default !important;
}

.commentWrapper {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 40px;
  margin-top: 10px;
}

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  [class*='ant-tooltip-arrow-content'] {
    display: none;
  }
}
