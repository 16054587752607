@import 'styles/variables';

.documentContent {
  .Heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 1%;
  }

  .documentDiv {
    background: #f4f5f6;
    border-radius: 8px;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .fileNameDiv {
      display: flex;
      gap: 12px;

      .svgContainer {
        line-height: 0px;
      }
    }

    .alignFileName {
      align-items: center;
      .documentName {
        margin-bottom: 0px;
      }
    }

    .formDocumentNameDiv {
      align-items: center;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      .voidPill {
        background: $background-ligh-gray-4;
        border-radius: 40px;
        padding: 8px;
        font-weight: 500;
        color: $dark-grey-color;

        font-size: 14px;
        font-family: Inter;
        line-height: 16px;
        letter-spacing: -0.09px;

        width: 46px;
        height: 32px;
      }
    }
  }

  .documentName {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin-bottom: 4px;
    text-align: left;
    word-break: break-all;
  }

  .documentStatus {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $dark-color;
    margin-bottom: 0;
  }

  .miscDocName {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: $mobile) {
    .documentName,
    .Heading {
      font-size: 14px;
    }
    .documentStatus {
      font-size: 12px;
    }
  }
}
