@import 'styles/functions';
@import 'styles/variables';

.buttonsWrapper {
  display: flex;

  .buttonWithText {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #262626;

    background: $white;
    box-shadow: 0 setUnit(8) setUnit(16) rgba(61, 61, 61, 0.1);
    border-radius: setUnit(8);
    padding: setUnit(10);

    .icon {
      display: flex;
      align-items: center;
      svg {
        width: setUnit(24);
        height: setUnit(24);
        path {
          stroke: $text-color;
        }
      }
    }
    .iconWrapper {
      display: flex;
      svg {
        width: setUnit(24);
        height: setUnit(24);
        path {
          stroke: $text-color;
        }
      }
    }
    .textWrapper {
      display: none;
      margin-left: 0.5rem;
      white-space: nowrap;
      @media (min-width: $mobile) {
        display: inline;
      }
    }

    .divider {
      height: 100%;
    }
  }
}
