@import 'styles/functions';
@import 'styles/variables';

.subheading {
  color: $text--light-gray-color;
  font-size: 14px;
  margin: setUnit(15) 0;
}

.checkbox {
  [class*='ant-checkbox'] {
    [class*='ant-checkbox-inner'] {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border-color: $text--light-gray-color;
    }
  }

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    [class*='ant-checkbox-inner']::after {
      width: 7px;
      height: 12px;
    }
  }
  &:hover,
  &:focus {
    [class*='ant-checkbox-inner'] {
      border-color: $primary-color;
    }
  }
}

.statusField {
  display: flex;
  align-items: center;
  margin-bottom: setUnit(10);
  .statusLabel {
    margin-left: setUnit(10);
    font-size: 16px;
  }
}
