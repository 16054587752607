@import 'styles/functions';
@import 'styles/variables';

.clientsList {
  .label {
    font-size: setUnit(16);
    font-weight: 600;
  }

  .emptyState {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        text-align: center;
        color: $text-gray-color;
      }
      .assignButton {
        border-radius: 100px;
        width: 100px;
        font-size: 14px !important;
        font-weight: 400 !important;
        display: flex;
        align-items: center;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .clientsListSection {
    max-height: setUnit(350);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: setUnit(24);

    margin-right: -2.5rem;
    padding-right: 2.5rem;
  }
}
