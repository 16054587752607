@import 'styles/functions';
@import 'styles/variables';

.actions {
  display: flex;
  align-items: center;

  & > div {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .active {
    border-color: $primary-color;
    background-color: $primary-color-light;
  }
}
