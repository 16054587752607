@import 'styles/functions';
@import 'styles/variables';

.search {
  .searchField {
    width: 100%;
    input {
      font-size: setUnit(16);

      &::placeholder {
        color: $text--light-gray-color;
      }
    }
  }
}
