@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.dropdownWrap {
  position: absolute;
  top: setUnit(24);
  right: setUnit(24);
}
.dropdownTrigger {
  cursor: pointer;
  width: setUnit(32);
  height: setUnit(32);
  border-radius: 50%;
  background: $background-ligh-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}
.taskMoreOptions {
  @include box-shadow;
  background: #ffffff;
  border-radius: setUnit(8);
  [class*='ant-dropdown-menu'] {
    padding: 0;
  }
  .dropdownDelete {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: setUnit(10) setUnit(22);
    .icon {
      margin-right: setUnit(8);
      position: relative;
      top: setUnit(-2);
    }
    &:hover {
      background: $background-ligh-gray;
    }
    svg {
      vertical-align: middle;
    }
  }
}
