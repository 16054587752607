@import 'styles/variables';
@import 'styles/functions';

.add {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    fill: $dark-color;
  }
}

.remove {
  border: 2px solid $light-gray;
  border-radius: 50%;
  path {
    stroke: $dark-color;
  }
}

.number {
  font-size: 18px !important;
}

.itemsWrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  .item {
    width: 100%;
    padding: 20px 16px;
    border-radius: 12px;
    border: 1.5px solid;
    border-color: $light-gray;
    background-color: $white;
    cursor: pointer;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .label {
        color: $text-color;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.09px;
        margin: 0;
      }
      .title {
        color: $text-color;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.09px;
        margin: 0;
      }
      .purple {
        button {
          background-color: #928cda !important;
        }
      }
      .buttonsContainer {
        display: flex;
        gap: 20px;
        align-items: center;
        .button {
          cursor: pointer;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid;
          border-color: $border-middle-gray;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            border-color: $border-focused;
          }
          svg {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
