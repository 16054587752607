@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  margin: 0;
  @media (min-width: $mobile) {
    margin: 0 setUnit(24) 0 0;
  }
}

.title {
  margin-bottom: setUnit(16);
  @media (min-width: $mobile) {
    padding: setUnit(40) 0 setUnit(24) setUnit(12);
  }
}

.menu {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  gap: setUnit(8);
  @media (min-width: $mobile) {
    gap: 0;
  }
  li {
    flex: 1 0 calc(50% - #{setUnit(4)});
    @media (min-width: $mobile) {
      flex: 0 0 100%;
      padding: 0;
      &:not(:last-child) {
        margin-bottom: setUnit(8);
      }
    }
  }
}

.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.18px;
  color: $text-color;
  background-color: transparent;
  @include transition;
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    transition: all 0.1s;
    color: $text-color;
    text-decoration: none;
    width: 100%;
    height: setUnit(44);
    border-radius: setUnit(8);
    padding: setUnit(8);
  }

  .linkIcon {
    padding-right: setUnit(8);
    transition: all 0.1s;
    display: flex;
    align-items: center;
    path {
      stroke: $text--light-gray-color;
      @include transition;
    }
  }

  &:hover,
  &.linkActive {
    background-color: $background-grey-2;
    font-weight: 600;
    .linkIcon {
      path {
        stroke: $text-color;
      }
    }
  }
}
