@import 'styles/functions';
@import 'styles/variables';

.showings {
  padding: 2rem;

  .title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 36px;
    color: $text-color;
    display: inline-block;
    margin-bottom: 3rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;

    .searchWrapper {
      .inputWrap {
        background: $white;
        border: 2px solid $light-gray;
        border-radius: setUnit(26);
        width: setUnit(44);
        height: setUnit(44);
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        @media (max-width: 991px) {
          height: setUnit(36);
        }
        &:focus {
          border-width: 2px;
        }
        .icon {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          transform: unset;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $dark-color;
            }
          }
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: setUnit(8);
          display: none;
          width: 0;
        }
      }
    }

    .btn {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border: 2px solid $light-gray;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: $dark-color;
      width: setUnit(36);
      height: setUnit(36);
      @media (min-width: $tablet) {
        height: setUnit(44);
        width: setUnit(44);
      }
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
    }
    .selected {
      background-color: $background-blue;
    }
  }

  .transactionShowingsTable {
    padding: unset !important;
  }

  .actions {
    float: right;
    top: 0;
    right: 0;
    position: relative;

    .selected {
      background-color: $background-blue !important;
    }

    .actionBtn {
      width: 2.5rem;
      height: 2.5rem;
      background: $background-grey-2;
      border-radius: 100px;
      padding: 0.5rem 0.6rem;
      margin-right: 0.75rem;
      display: inline-block;
      cursor: pointer;

      &:hover {
        background-color: $grey-color;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        font-weight: 500;
      }
    }

    .copyBtn {
      border: none !important;
    }

    .enableBtn,
    .copyBtn {
      width: auto;
      padding: 0.5rem 1rem;
    }
  }
}
