@import 'styles/functions';
@import 'styles/variables';

.dropdown {
  & [class*='ant-select-selector'] {
    border-radius: 32px !important;
    padding: 8px 8px 8px 10px;
    align-items: center;

    & [class*='ant-select-selection-item'] {
      font-family: $font-family !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
}
.statusDropdownActive {
  & [class*='ant-select-selector'] {
    background: $light-grey-color !important;
    color: $green !important;
  }
}
.statusDropdownDraft {
  & [class*='ant-select-selector'] {
    background: $background-ligh-gray !important;
    color: $dark-color !important;
  }
}
.statusDropdownInactive {
  & [class*='ant-select-selector'] {
    background: $background-ligh-gray !important;
    color: $text-gray-color !important;
  }
}
