@import "styles/variables";
@import "styles/functions";

.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    transition: all .2s;
    z-index: 9999;

    &.isOpen {
        pointer-events: auto;
        opacity: 1;

        .opacityLayer {
            opacity: 1;
        }
    }
}

.counter {
    display: inline-flex;
    align-items: center;
    min-width: setUnit(100);

    .counterIcon {
        height: setUnit(22);
        width: setUnit(24);
        margin-right: setUnit(6);
    }
}

.opacityLayer {
    background-color: rgba($white, .84);
    backdrop-filter: blur(7px);
    opacity: 0;
    transition: opacity .2s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9998;
}

.content {
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
    z-index: 9999;
    transition: all .3s;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: setUnit(50) setUnit(56);
    }
}

.innerHolder {
    margin: 0 auto;

    .imageOriginalClass {
        min-height: calc(100vh - #{setUnit(152)} - #{setUnit(152)}) !important;

        img {
            max-height: calc(100vh - #{setUnit(152)} - #{setUnit(152)}) !important;
            width: auto !important;
            border-radius: 8px;
        }
    }
    
    .thumbnailClass {
        cursor: pointer;
        margin-top: setUnit(32);
        border-radius: 6px;
        border-width: 2px !important;
        width: auto;
        overflow: hidden;

        img {
            max-height: setUnit(75);
            min-height: setUnit(75);
            width: auto;
        }
    }
}

.leftNav {
    position: absolute;
    left: setUnit(56);
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    cursor: pointer;
    transition: all .1s;
    width: setUnit(56);
    height: setUnit(56);
    background: $white;
    border: 1px solid $border-middle-gray;
    box-sizing: border-box;
    border-radius: 50%;

    &:hover {
        border-color: $text-color;
    }
}

.rightNav {
    position: absolute;
    right: setUnit(56);
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    cursor: pointer;
    transition: all .1s;
    width: setUnit(56);
    height: setUnit(56);
    background: $white;
    border: 1px solid $border-middle-gray;
    box-sizing: border-box;
    border-radius: 50%;

    &:hover {
        border-color: $text-color;
    }
}

.navIcon {
    height: setUnit(24);

    svg {
        height: setUnit(24);
        width: setUnit(24);

        path {
            stroke: $dark-grey-color;
        }
    }
}

.navIconLeft {
    height: setUnit(15);

    svg {
        height: setUnit(15);
        width: setUnit(15);
        padding-right: 3px;

        path {
            stroke: $dark-grey-color;
        }
    }
}

.middleBlock {
    > span {
        &:not(:last-of-type) {
            &::after {
                content: "\00B7";
                font-weight: bold;
                padding: 0 setUnit(16);
            }
        }

        b {
            font-weight: 600;
        }
    }
}