@import 'styles/functions';
@import 'styles/variables';

.price {
  border: none;
  outline: none;
  pointer-events: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.33px;
  color: $text-color;
  font-family: $font-family;
}
