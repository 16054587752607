@import 'styles/functions';
@import 'styles/variables';

[class*='ant-popover-inner-content'] {
  padding: 0px !important;
}

.recommended {
  svg {
    fill: $orange;
    path {
      stroke: $orange;
    }
  }
}

.userNavigationMenu {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      .column {
        padding: setUnit(30) setUnit(15);
        display: flex;
        align-items: center;
        font-size: setUnit(16);
        font-weight: 500;
        height: setUnit(40);
        width: setUnit(243);
        p {
          margin-bottom: 0;
          position: relative;
          left: setUnit(10);
        }
        .icon {
          svg {
            margin-top: setUnit(5);
          }
        }
        &:hover {
          background: #f4f5f6;
        }
      }
    }
  }
}
