@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.actionBtn {
  position: relative;
  transition: all 0.1s;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  .actionBtnIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  &:hover {
    .actionBtnIcon {
      background-color: $light-grey-color;
    }

    .inactiveBtnIcon {
      background-color: $grey-color;
    }
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #f4f5f6;

  .tabWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    column-gap: 40px;

    .tabItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      column-gap: 8px;
      padding: 14px 8px 12px 0;
      cursor: pointer;

      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;

      border-bottom: 3px solid transparent;
      .iconWrapper {
        display: flex;
      }

      &.active {
        color: var(--Grayscale-100, #262626);
        border-bottom: 3px solid var(--Primary-50, #ff576d);
        div {
          color: #ff576d;
        }
      }
    }
  }

  .titleInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;

      /* Page Title */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;

      margin-bottom: 8px;
    }

    .description {
      text-align: center;
      color: var(--Grayscale-40, #aaabab);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20.8px; /* 115.556% */
      letter-spacing: -0.18px;
    }
  }

  .pageContent {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    padding-block: 24px;
    gap: 24px;
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
  max-width: 1340px;
}

.popoverOverlay {
  width: setUnit(156);
  z-index: 99999 !important;
  div[class~='ant-popover-inner'] {
    border-radius: setUnit(8);
    div[class~='ant-popover-inner-content'] {
      padding: 0px;
      .actionList {
        position: absolute;
        // opacity: 0;
        // visibility: hidden;
        background-color: $white;
        width: setUnit(176);
        border-radius: setUnit(8);
        box-shadow: 0 2px setUnit(12) rgba(0, 0, 0, 0.15);
        right: 0;
        z-index: 99;
        overflow: hidden;

        // &.top {
        //   bottom: setUnit(35);
        // }

        // &.bottom {
        //   top: setUnit(45);
        // }

        .actionListBtn {
          width: 100%;
          display: flex;
          align-items: center;
          outline: none;
          border: 0;
          color: $text-color;
          font-size: setUnit(15);
          font-weight: 400;
          line-height: setUnit(16);
          padding: setUnit(15) setUnit(16);
          transition: all 0.1s;
          background-color: $white;
          text-align: left;
          cursor: pointer;

          .icon {
            padding-right: setUnit(8);
            height: setUnit(16);

            svg {
              width: setUnit(16);
              height: setUnit(16);
            }
          }

          &:hover {
            background-color: $hover-light-gray;
          }
        }
      }
    }
  }
}

.tableColNameWrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    color: #515151;

    border-radius: 50%;
    background: #f5f5f5;
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .title {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 100% */
      letter-spacing: -0.33px;
    }
    .info {
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      letter-spacing: -0.33px;
    }
  }
}

.statusPillGreen {
  padding: 6px 20px;
  border-radius: 20px;
  background: var(--Tertiary-Green-Success-100, #04a451);
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.33px;
}

.statusPillGray {
  padding: 6px 20px;
  border-radius: 20px;
  background: var(--Grayscale-40, #aaabab);
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.33px;
}
