@import 'styles/functions';
@import 'styles/variables';

.mainContentWrapper {
  max-width: 100vw;
  background-color: $background-ligh-gray-3;
  .dashboardHeader {
    background-color: $white;
    .heading {
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-weight: 700;
      text-align: center;
      color: $text-color;
      padding: setUnit(40) 0 setUnit(36);
      letter-spacing: -0.4px;
      margin: 0 auto;
    }
  }
  .dashboardCardArea {
    padding: setUnit(48) setUnit(40) setUnit(24);
  }
}
.quoteSpace {
  display: flex !important;
}
