@import 'styles/functions';
@import 'styles/variables';

.propertyDocumentCard {
  background-color: $white;
  padding: setUnit(16);
  border-radius: setUnit(16);
  border: 1px solid $light-gray;
  margin-bottom: setUnit(24);

  .pdfCard {
    height: 200px;
    cursor: pointer;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    .left {
      .docName,
      .clientName,
      .date {
        font-size: 13px;
        line-height: 16px;
      }

      .docName {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 600;
        margin-bottom: 4px;
        min-height: 13px;
      }

      .clientName,
      .date {
        font-weight: 400;
        margin-bottom: 0px;
        margin-top: setUnit(2);
        color: $text-gray-color;
      }
    }
  }
}
