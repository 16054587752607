.formContainer {
  max-width: 823px !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.05) !important;

  backdrop-filter: blur(75px) !important;
  margin-top: 0px !important;

  @media screen and (max-height: 1000px) {
    margin-top: 80px !important;
  }
}
.messageContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .messageWrapper {
    display: flex;
    padding: 20px;
    column-gap: 20px;

    border-radius: 8px;
    background: #2d3342;

    .text {
      color: rgba(255, 255, 255, 0.9);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 175% */
      letter-spacing: -0.3px;

      display: flex;
      align-items: center;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .title {
    color: var(--White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Sora, Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: -0.8px;
  }

  .btn {
    border-radius: 12px;
    background: linear-gradient(90deg, #ff5d69 13.49%, #fc8545 138.32%);
    width: 100%;
    padding: 16px;

    height: initial !important;

    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
}
