@import 'styles/variables';
@import 'styles/functions';

.formsWrapper {
  background-color: $background-ligh-gray-3;
  min-height: calc(100vh - $header-height - $footer-height - $onboarding-header-height);
  padding-bottom: 2.5rem;
  padding-top: setUnit(16);
}

.formContainer {
  padding: 0px setUnit(40) setUnit(20);
}

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    background: $white;
    border-radius: 16px;
    color: $text-color;
  }

  .container {
    min-width: 230px;
    padding: 10px;
    padding-bottom: 0px;

    .title {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    .row {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .userName {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0px !important;
    }

    .sequenceBox {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $text--light-gray-color;
      border: 1px solid $text--light-gray-color;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      display: inline-block;
      text-align: center;
    }
    .editorRow {
      width: 100%;
    }
    .sequenceBoxColumn {
      align-self: baseline;
      margin-right: 5px;
    }

    .formStatus {
      font-family: $font-family;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }

    .column {
      width: 80% !important;
    }
  }
}
