@import 'styles/functions';
@import 'styles/variables';

.infoWithinSubInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.09px;
  margin-bottom: 4px;
  color: $text-color;
  font-family: $font-family;
}
