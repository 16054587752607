@import 'styles/functions';
@import 'styles/variables';

.noSaved {
  text-align: center;
  font-size: setUnit(20);
  color: $text-gray-color;
  padding-top: setUnit(10);
  width: 100%;
}
