@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.statistics {
  max-width: 1199px;
  width: 100%;
  margin: auto;
  padding: setUnit(20) 0 setUnit(25);
}
