@import 'styles/functions';
@import 'styles/variables';

.answersContainer {
  width: fit-content;
  margin: 0 auto;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background-color: $white;
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 35px 35px 0;
    @media screen and (max-width: 700px) {
      padding: 0 12px 12px 0;
    }
    .button {
      width: 163px;
    }
    .titleClassName {
      display: flex;
      justify-content: center;
      gap: 10px;
      span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.09px;
        position: relative;
        top: 3px;
      }
    }
  }
}
