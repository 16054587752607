@import 'styles/functions';
@import 'styles/variables';

.cancel {
  outline: none;
  cursor: pointer;
  width: setUnit(40);
  height: setUnit(40);
  background-color: $background-ligh-gray;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: setUnit(100);
  transition: $transition;
  &:hover {
    background-color: $light-gray;
  }
}
