@import 'styles/functions';
@import 'styles/variables';

.header {
  padding: setUnit(22) setUnit(32) setUnit(32);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0;
    .actionItem {
      &:not(:last-child) {
        margin-right: setUnit(12);
      }
    }
  }
}
