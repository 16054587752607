@import 'styles/variables';

.mentionEdit {
  width: 100%;

  [class*='__control'] {
    font-size: 16px;
  }
  [class*='__input'] {
    border: none;
    line-height: 22px;

    &:focus-visible {
      outline: none;
    }
  }

  [class*='__suggestions__list'] {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 12px;
    border-radius: 8px;
  }

  [class*='__suggestions__item'] {
    padding: 5px 2px;
  }

  [class*='__suggestions__item--focused'] {
    background-color: $light-gray;
  }
}
