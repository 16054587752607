@import 'styles/variables';

.modalHeader {
  display: flex;
  justify-content: space-between;
  height: 42px;
  margin-top: 43px;
  z-index: 20;
  position: relative;

  .title {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.5px;
  }

  .closeButton {
    border: 2px solid $light-gray;
    border-radius: 50%;
    padding: 7px;
    height: 42px;
    width: 42px;
    cursor: pointer;
    background-color: $white;
  }
}

.countContainer {
  display: flex;
  font-family: Inter;
  font-weight: 700;
  line-height: 62px; /* 96.875% */

  .textLarge {
    font-weight: 700;
    font-size: 64px;
    line-height: 48px;
    letter-spacing: -0.35px;

    @media screen and (max-width: $mobile) {
      font-size: 34px;
      line-height: 42px;
    }
  }
  .textSmall {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.35px;
    display: flex;
    align-items: flex-end;
    margin-left: 4px;

    @media screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 34px;
    }
  }
}
