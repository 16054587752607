@import 'styles/functions';
@import 'styles/variables';

.commutes {
  .border {
    border-radius: 8px !important;
    .placeholder {
      border-radius: 8px !important;
    }
    .round {
      border-radius: 8px !important;
    }
    input {
      border-radius: 8px !important;
    }
  }

  .inputWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .inputfieldMinutes {
      width: 120px;
      margin: 0;
      margin-right: 20px;
      margin-bottom: 20px;

      input {
        height: 52px !important;
        width: 56px !important;
      }
    }

    .innerLabel {
      margin-right: 10px;
      font-weight: normal;
      font-size: 16px;
    }

    .inputfieldMinutes {
      @media (min-width: $mobile-small) {
        width: 56px;
      }
      margin: 0;

      input {
        border-radius: 8px 0 0 8px;
        text-align: center;
        padding: 16px 0 12px;
        width: 100% !important;
      }
    }

    .timeSelectWrapper {
      margin: 0;
      width: 125px;
      input {
        border-radius: 0 8px 8px 0;
      }
    }
  }
}
.divider {
  height: 1px;
  background: $light-gray;
  margin: 24px 0;
}
.preference {
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.09px;
  margin: 16px 0;
}
.outerWrapper {
  margin-bottom: 32px;
  .inputLabel {
    margin-bottom: 8;
  }
}
