@import 'styles/functions';
@import 'styles/variables';

.photo {
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .propertyImage {
    width: 100%;
    height: 100%;
    background: $background-grey-2;
  }
}
