@import 'styles/functions';
@import 'styles/variables';

.detailsContainer {
  .detailsPanes {
    [class*='ant-tabs-tab-btn'] {
      text-shadow: none !important;
    }

    [class*='ant-tabs-tabpane-active'] {
      height: 100% !important;
      margin-top: 32px;
    }
  }
}
