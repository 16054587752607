@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  padding-right: 32px;
}
.popmenu {
  justify-content: center;
  display: flex;
  align-content: center;
  background-color: $secondary-color-hover;
  border-radius: 8px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;

  &.disabled {
    background-color: $light-black;
    cursor: not-allowed;
  }

  .popmenuButton {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: setUnit(16);
      line-height: setUnit(24);
      font-weight: 600;
      letter-spacing: -0.09px;
      margin-right: setUnit(8);
      margin-left: setUnit(8);
    }
    .menuIcon {
      transform: rotate(0deg);
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition;
      &.menuOpen {
        transform: rotate(180deg);
      }
    }
  }

  .actionPopover {
    border-radius: setUnit(8);
    max-width: setUnit(420);
    width: 100%;
    padding-bottom: setUnit(2);
    padding-top: setUnit(2);
    [class~='ant-popover-inner'] {
      border-radius: 10px;
      [class~='ant-popover-inner-content'] {
        padding: setUnit(8) 0 !important;
        border-radius: inherit;

        [class~='ant-menu'] {
          border-radius: inherit;
          border-right: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;

          [class~='ant-menu-item'] {
            margin: 0;
            height: auto;
            padding: setUnit(16) 0px;
            width: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            @include transition;
            &:hover {
              background-color: $background-grey-2;
            }
            .menuIcon {
              display: flex;
              align-items: center;
              span {
                font-weight: 600;
                font-size: setUnit(16);
                line-height: setUnit(16);
                margin-left: setUnit(8);
                margin-right: setUnit(8);
                color: $text-color;
              }
            }
            &[class~='ant-menu-item-disabled'] {
              .menuIcon {
                span {
                  color: rgba(0, 0, 0, 0.25);
                }
              }
            }
          }
        }
      }
    }
  }
}
