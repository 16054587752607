@import 'styles/functions';
@import 'styles/variables';

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: setUnit(51);
}

.btn {
  width: 100%;
  max-width: setUnit(416);
  margin: 0 auto;
  display: block;
}

.modalInnerHolder {
  padding: setUnit(60) setUnit(20);
  @media (min-width: $mobile) {
    padding: setUnit(60) setUnit(45);
  }
}

.title {
  font-size: 1.5rem !important;
  letter-spacing: setUnit(-0.47);
}
