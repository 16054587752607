@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  position: relative;
  width: 100%;
}

.headWrapper {
  display: block;
  padding: 2rem 0.75rem 3.5rem 0.75rem;
  text-align: center;
  width: 100%;

  .headTitle {
    font-weight: bold;
    font-size: setUnit(30);
    color: $text-color;
  }

  .headTitleDescription {
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $dark-grey-color;
    max-width: 500px;
    margin: 0 auto;
  }
}

.pageWrapper {
  display: block;
  width: 100%;
}

.locationArea {
  display: inline-flex;
  width: 256px;
}

.mainArea {
  width: 744px;
  display: inline-block;
}

.dateTimeArea {
  display: block;
  width: 100%;
}

.formArea {
  display: block;
  width: 100%;
}

.confirmationArea {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.selectedDate {
  display: block;
  color: $text-color;
  font-size: 16px;
  padding: 1.125rem 2.5rem 1.5rem 2rem;

  h4 {
    font-weight: 700;
    margin-bottom: 1rem;
    display: inline-flex;
  }

  .iconImage {
    height: 30px;
    width: 30px;
    vertical-align: top;
    float: right;
    margin-right: 2rem;

    .icon {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  .cancelBtn {
    font-size: 12px;
    font-weight: 600;
    background-color: $background-ligh-gray;
    padding: 0.75rem 1.125rem;
    border-radius: 25px;
    cursor: pointer;
  }
}
