.container {
  display: flex;
  gap: 24px;
  padding: 24px 0px;

  .card {
    border-radius: 12px;
    border: 1.5px solid var(--Grayscale-20, #e8e9ea);
    background: #fff;
    padding: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .select {
      border-radius: 8px;
      background: #2d5ec6;

      [class*='_value_'] {
        input {
          color: #fff;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 111.111% */
          letter-spacing: -0.18px;
          text-align: center;
          padding: none !important;
        }

        input::placeholder {
          color: #fff;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 111.111% */
          letter-spacing: -0.18px;
          text-align: center;
        }
      }

      [class*='_arrowIcon_'] {
        filter: invert(1) brightness(100);
        right: 100px;
      }
    }

    .heading {
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
    }

    .headingBold {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.18px;
    }

    .headingSemiBold {
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
    }

    .textLight {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.18px;
    }

    .btn {
      width: 100%;
      display: flex;
      min-width: 344px;
      padding: 12px 16px;
      justify-content: center;
      align-items: flex-end;
      height: initial;
      color: #fff;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
      border-radius: 8px;
      background: var(--Tertiary-Error-Red, #ec455e);
      margin-top: 11px;
      margin-bottom: 8px;
    }
  }
  .leftContainer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .seats {
      padding: 30px 0px;
      display: flex;
      flex-direction: row;

      & > div:first-child {
        border-right: 0.75px solid var(--Grayscale-20, #e8e9ea);
        flex-grow: 1;
        padding-inline: 24px;
      }

      & > div:last-child {
        border-left: 0.75px solid var(--Grayscale-20, #e8e9ea);
        flex-grow: 1;
        padding-inline: 24px;
      }

      .seatsHeading {
        color: var(--Grayscale-50, #747475);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
        margin-bottom: 10px;
      }

      .seatsCount {
        color: var(--REBRAND-Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 75% */
        letter-spacing: -0.18px;
      }
    }
  }

  .rightContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .header {
      display: flex;
      justify-content: space-between;

      .heading {
        color: var(--REBRAND-Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 90% */
        letter-spacing: -0.18px;
      }
    }
  }
}

.innerHolder {
  padding: 0 40px 40px 40px !important;

  .heading {
    color: var(--REBRAND-Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Sora, Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: -0.5px;
  }

  .prompt {
    margin-top: 30px;
    color: var(--REBRAND-Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.5px;
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .confirmBtn {
      border-radius: 8px;
      background: var(--Primary-Navy, #252d44) !important;
      display: flex;
      min-width: 158px;
      height: initial;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      display: inline;

      color: white !important;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off !important;
      font-family: Inter !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 20px !important; /* 125% */
      letter-spacing: -0.18px;
    }
  }
}

.loader {
  font-size: 4px;
  color: #ffffff;
  width: 1em;
  height: 1em;
  margin: 11px;
}
