@import 'styles/functions';
@import 'styles/variables';

.myReports {
  padding: setUnit(16) setUnit(24);
  border-radius: setUnit(12);
  background-color: #fff;
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Text {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(24);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
    }

    &Link {
      cursor: pointer;
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(24);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      transition: all 0.2s;
      border-bottom: 1.5px solid transparent;

      &:hover {
        border-bottom: 1.5px solid $text-color;
      }
    }
  }

  &Content {
    height: setUnit(192);
    overflow-y: scroll;

    &Flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .noReports {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(24);
      letter-spacing: setUnit(-0.33);
      color: $text--light-gray-color;
      margin-bottom: 0;
    }
  }

  &Footer {
    &New {
      cursor: pointer;
      display: flex;
      align-items: center;

      &Text {
        display: flex;
        align-items: center;
        margin-left: setUnit(10);
        height: setUnit(32);
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.18);
        color: $text-color;
        padding-bottom: 6px;
      }
    }
  }
}
