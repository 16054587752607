@import 'styles/functions';
@import 'styles/variables';

.item {
  transition: all 0.1s;
  display: flex;
  align-items: center;
  padding: setUnit(12) setUnit(32);
  cursor: pointer;
  color: $text-color;
  position: relative;

  &:hover {
    background-color: $hover-light-gray;
  }
  .avatarContainer {
    position: relative;
  }

  .onlineStatus {
    position: absolute;
    top: 62%;
    left: 55%;
    display: flex;
    z-index: 2;
    padding: 5px;
    background: white;
    border-radius: 50%;

    div {
      width: setUnit(9);
      height: setUnit(9);
      background-color: $green-color;
      border-radius: 50%;
    }
  }

  .personalTitleSpan,
  .offerTitleSpan {
    padding: setUnit(3) setUnit(6);
    margin-left: setUnit(8);
    color: $text-color;
    border-radius: setUnit(20);
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-weight: 500;
  }

  .offerTitleSpan {
    background: $yellow-color;
  }

  .personalTitleSpan {
    background: $light-gray;
  }

  &.unread {
    &::before {
      content: '';
      position: absolute;
      background: $primary-color content-box;
      border-radius: 50%;
      width: setUnit(8);
      height: setUnit(8);
      left: setUnit(12);
    }

    .itemContentTitle {
      font-weight: 600;
    }

    .itemContentText,
    .itemContentTitleTime {
      font-weight: 600;
      color: $dark-color;
    }
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    flex: 1;

    .itemContentTitle {
      font-size: setUnit(16);
      line-height: setUnit(21);
      display: flex;

      .itemContentSubTitle {
        font-size: setUnit(14);
        font-weight: 500;
        line-height: setUnit(16);
        color: $dark-grey-color;
        padding-top: setUnit(3);
      }

      .itemContentTitleTime {
        margin-left: auto;
        color: $text-gray-color;
        font-size: setUnit(12);
        line-height: setUnit(16);
        white-space: nowrap;
        padding-top: setUnit(2);
      }
    }

    .itemContentText {
      padding-top: setUnit(5);
      font-size: setUnit(14);
      line-height: setUnit(16);
      color: $dark-grey-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .tagHyperLink {
        background-color: #2b59da1a;
        padding: setUnit(2);
        border-radius: setUnit(4);

        span {
          color: $link-blue;
          font-weight: 500;
          font-size: setUnit(14);
          line-height: setUnit(16);
        }
      }
    }
  }

  .avatars {
    position: relative;
    margin-right: setUnit(16);

    .avatarWrapper {
      margin-left: setUnit(-35);

      &:first-child {
        margin-left: 0;
      }
    }

    .avatar {
      width: setUnit(56);
      height: setUnit(56);

      &.isNotSingle {
        width: setUnit(45);
        height: setUnit(45);
      }
    }

    .overflow {
      width: setUnit(45);
      height: setUnit(45);
      top: setUnit(5);
    }
  }
}
