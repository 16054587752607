@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.menuItem {
  display: flex;
  align-items: center;

  * {
    font-family: $font-family;
  }

  color: $text-color !important;

  &:hover {
    background: $background-grey-2 !important;
    color: $text-color !important;
  }

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.menuOverlay {
  @include box-shadow;
  min-width: 176px !important;

  ul {
    border-radius: 8px !important;
  }

  [class*='ant-menu-item-selected'] {
    background: $background-grey-2 !important;
  }
}
