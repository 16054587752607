@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.previewModalHeader {
  display: flex;
  justify-content: center;

  .modalTitle {
    text-align: center;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(32);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
  }
}

.previewModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-right: 6px;
  .selectAgent {
    width: 320px;
    & [class*='ant-select-selection-item'] {
      align-items: center !important;
    }

    & [class*='ant-select-selection-placeholder'] {
      align-items: center !important;
      display: flex !important;
    }
  }
  .newServicesSpan {
    color: $confetti-blue;
    font-size: setUnit(16);
    text-decoration: underline;
    margin-left: 1rem;
  }
  .submitButton {
    border-radius: 8px;
    background-color: $secondary-color;
    color: $white;
    font-size: setUnit(16);
    font-weight: 600;
    padding: 5px 10px;
    width: 130px;
    &:hover {
      background: $secondary-color-hover;
    }
    .submitButtonLoader {
      font-size: setUnit(3);
      margin: 0;
    }
  }
}

.newServicesContentPopover {
  padding: 12px 12px 1px 12px;
  color: $confetti-blue;
  p {
    line-height: 0.5rem;
  }
}
