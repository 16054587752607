@import 'styles/variables';
@import 'styles/functions';

$header-height: 64px;

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: $transition;
  z-index: 9999;

  &.isOpen {
    pointer-events: auto;
    opacity: 1;

    .opacityLayer {
      opacity: 1;
    }
  }
}

.counter {
  display: inline-flex;
  align-items: center;
  min-width: setUnit(100);

  .count {
    color: $white;
  }

  .counterIcon {
    height: setUnit(22);
    width: setUnit(24);
    margin-right: setUnit(6);

    svg {
      path,
      rect {
        stroke: $white;
      }
    }
  }
}

.opacityLayer {
  background-color: rgba($black, 0.5);
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9998;
}

.content {
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: 9999;
  transition: all 0.3s;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 setUnit(56);
    background: $black;
    height: setUnit(64);
    margin-bottom: $header-height;
    color: $white;

    .actions {
      display: flex;
      align-items: center;
    }
  }
}

.innerHolder {
  margin: 0 auto;

  .imageOriginalClass {
    min-height: calc(100vh - #{setUnit(152)} - #{setUnit(152)}) !important;

    img {
      max-height: calc(100vh - #{setUnit(152)} - #{setUnit(152)}) !important;
      width: auto !important;
      border-radius: 8px;
    }
  }

  .thumbnailClass {
    cursor: pointer;
    margin-top: setUnit(32);
    border-radius: 6px;
    border-width: 2px !important;
    width: auto;
    overflow: hidden;

    img {
      max-height: setUnit(75);
      min-height: setUnit(75);
      width: auto;
    }
  }
}

.leftNav,
.rightNav {
  $width: 56;
  $height: 56;

  position: absolute;
  top: calc(50% + $header-height);
  transform: translateY(-50%);
  z-index: 4;
  cursor: pointer;
  transition: all 0.1s;
  width: setUnit($width);
  height: setUnit($height);
  background: #231f20;
  border: 1px solid #231f20;
  box-sizing: border-box;
  border-radius: 50%;

  &:hover {
    background: $black;
  }
}

.leftNav {
  left: setUnit(56);
}

.rightNav {
  right: setUnit(56);
}

.navIcon {
  height: setUnit(24);

  svg {
    height: setUnit(24);
    width: setUnit(24);

    path {
      stroke: $white;
    }
  }
}

.navIconLeft {
  height: setUnit(15);

  svg {
    height: setUnit(15);
    width: setUnit(15);
    padding-right: 3px;

    path {
      stroke: $white;
    }
  }
}

.middleBlock {
  > span {
    &:not(:last-of-type) {
      &::after {
        content: '\00B7';
        font-weight: bold;
        padding: 0 setUnit(16);
      }
    }

    b {
      font-weight: 600;
    }
  }
}
