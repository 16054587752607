@import 'styles/functions';
@import 'styles/variables';

.headerButtons {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    color: $text-color;
    min-width: setUnit(119);
    width: 100%;
    &:not(:last-child) {
      margin-right: setUnit(8);
    }
    .button {
      width: 100%;
      cursor: pointer;
      outline: none;
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(20);
      height: setUnit(44);
      border-radius: 8px;
      text-decoration: none;
      transition: $transition;
      &.loginBtn {
        background: $white;
        color: $text-color;
        border: 2px solid $border-light-gray;
        &:hover {
          background: $hover-light-gray;
        }
      }
      &.tryMosaikBtn {
        background-color: #252d44;
        color: $white;
        border: none;
        &:hover {
          background-color: #1d4f76;
        }
      }
    }
  }
}

.drawarNavHeader {
  padding: 5px 10px;
  a {
    max-width: 8rem;
  }
}
