@import 'styles/variables';
@import 'styles/functions';

.footer {
  display: flex;
  padding: 43px 91px 61px 80px;
  justify-content: space-between;
  align-items: center;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Sora, Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */

  @media screen and (max-width: $mobile) {
    padding: 43px 25px 25px;
  }

  .companyText {
    color: rgba(255, 255, 255, 0.6);
  }

  .links {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;

    a {
      padding: 24px 30px;
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      letter-spacing: -0.2px;
    }
  }

  .socialBtns {
    display: flex;
    column-gap: 28px;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
    }
  }
}
