@import 'styles/functions';
@import 'styles/variables';

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background: rgba(0, 177, 82, 0.1);
  width: 32px;
  height: 32px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.descInfo {
  padding: 10px 4px;
  color: var(--White, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;

  p {
    margin-bottom: 0 !important;
  }
  .heading1 {
    font-weight: 600;
  }
  .heading2 {
    font-weight: 500;
  }
  .heading3 {
    font-weight: 400;
    opacity: 0.8;
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 12px 16px;
    border-radius: 100px;
    background: var(--Primary-Navy, #252d44);

    color: var(--White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;

    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.errorTableText {
  color: var(--Tertiary-Error-Red, #ec455e);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.33px;
}

.iconButton {
  background-color: transparent !important;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  &:hover {
    background-color: transparent !important;
    border: 0 !important;
  }
  &:focus {
    border: 0 !important;
  }
}
