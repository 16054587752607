@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
}

.filtersFormWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: setUnit(32);
  align-items: center;
  height: setUnit(46);
}

.filterWrapper {
  margin-right: setUnit(32);

  &:last-child {
    margin-right: 0;
  }
}

.searchButton {
  width: setUnit(46);
  height: setUnit(46);
  margin-left: setUnit(32);
  background-color: $background-ligh-gray !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(24);

    path {
      stroke: $text-color;
    }
  }
}
