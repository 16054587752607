@import 'styles/functions';
@import 'styles/variables';

.container {
  .answerContainer {
    // height: calc(100vh - 500px);
    // overflow-y: auto;
    // padding-right: 20px;
    // overflow-x: auto;
    scrollbar-gutter: stable;
    .financingType {
      margin: 0;
    }

    .label {
      font-size: 14px;
      font-weight: 600;
    }
    .marginTop {
      margin-top: 20px;
    }

    .addContainer {
      .plus {
        margin-top: 3px;
      }
      cursor: pointer;
      display: flex;
      margin: 20px 0;
      gap: 5px;
      align-items: center;
      .addText {
        color: $dark-color;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
}

.arrowdownicon {
  right: 0.75rem !important;
  path {
    stroke: $text-gray-color !important;
  }
}
