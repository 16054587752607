@import 'styles/functions';
@import 'styles/variables';

.feeItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.feeItemEdit {
    &:hover {
      .editIcon {
        display: flex;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
  }

  .editIcon {
    position: absolute;
    cursor: pointer;
    top: setUnit(6);
    right: setUnit(0);
    width: 55px;
    display: none;
    align-items: center;
    justify-content: flex-end;

    .fadeEffect {
      opacity: 0.5;
      width: 25px;
      height: 38px;
      background: linear-gradient(90deg, rgba(252, 252, 252, 0) 0%, #fcfcfc 32.35%);
    }

    .icon {
      background-color: #fffcfa;
    }
  }
  .labelContainer {
    display: flex;
    align-items: center;
    gap: setUnit(8);
    div {
      display: flex;
    }

    .label {
      font-size: setUnit(16);
      font-weight: 500;
      margin: 0;
    }
  }

  .secondaryLabel {
    color: $dark-color;
    margin: 0;
  }

  .amounts {
    display: flex;
    align-items: center;
    gap: setUnit(5);

    .amount,
    .percentage {
      font-size: setUnit(16);
      position: relative;
      padding-bottom: 5px;
    }

    .amount {
      font-weight: 600;
    }

    .percentage {
      color: $dark-color;
    }
  }

  .buttonsContainer {
    margin-top: setUnit(20);
    display: flex;
    gap: 12px;
    button {
      border-radius: 8px;
      width: 100%;
    }
  }

  .marginTop {
    margin-top: setUnit(20);
  }
  .applyLabel {
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: $text-color;
    font-size: 0.875rem;
  }

  .inputHeight {
    height: 48px !important;
  }
}

.checkboxCont {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.deleteFeeDialog {
  .content {
    text-align: center;
    font-size: 16px;
  }
}
