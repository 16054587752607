@import 'styles/functions';
@import 'styles/variables';

.mainContentWrapper {
  max-width: 100vw;
  background-color: $background-ligh-gray-3;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .dashboardHeader {
    background-color: $white;
    .heading {
      line-height: setUnit(36);
      font-weight: 700;
      text-align: center;
      color: $text-color;
      padding: setUnit(40) 0 setUnit(36);
      letter-spacing: -0.4px;
      margin: 0 auto;
      font-size: setUnit(20);
      @media (min-width: $tablet) {
        font-size: setUnit(32);
      }
    }
    @media (max-width: $mobile) {
      height: 120px;
    }
  }
  .headerInner {
    display: flex;
    padding: 38px 0px 0px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
  }
  .headerNameWrapper {
    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.4px;
    margin-bottom: 38px;
  }
  .dashboardCardArea {
    padding: setUnit(48) setUnit(40) setUnit(24);
  }
  .tabIcon {
    display: flex;
    margin-bottom: setUnit(8);
    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }
  .tab {
    height: setUnit(40);
    font-size: setUnit(14);
    font-weight: 400;
    background: $background-ligh-gray;
    padding: setUnit(12) setUnit(24);
    border-radius: setUnit(8);
    color: $text-color;
    &:hover {
      background-color: $light-gray;
    }

    &.tabActive {
      background: $secondary-color;
      color: #ffffff;
    }
  }
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: setUnit(250);
  font-size: setUnit(16);
  font-weight: 400;
  color: $text--light-gray-color;
}

.marginLeft {
  width: 200px;
  margin-left: -15px;
}
