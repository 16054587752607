@import 'styles/variables';
@import 'styles/functions';

.formCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  border-radius: 8px;
  border: 1px solid $light-gray;
  padding: 20px;
  margin-bottom: 16px;

  .menuIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .formData {
    display: flex;
    justify-content: center;
    align-items: center;

    .formIcon {
      margin-right: 20px;
    }

    .formName {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $text-color;
      display: block;
      margin-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
      width: 240px;
      text-overflow: ellipsis;
    }

    .toolTip {
      [class*='ant-tooltip-inner'] {
        width: setUnit(375);
        padding: setUnit(20);
        border-radius: 1rem;
        background-color: $text-color;
      }
    }
  }

  .formOptions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .formDueDateError {
      color: $error-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }

    .formDueDate {
      display: flex;
      width: 120px;
      height: 32px;
      background-color: $background-grey-2;

      align-items: center;
      margin-right: 20px;
      position: relative;
      justify-content: center;

      .templateControlPicker {
        width: 2rem !important;
        margin: 0;
      }

      .formDueDateError {
        color: $error-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        position: absolute;
        bottom: -17px;
      }

      .datePickerInputClass {
        background-color: $white;
      }
    }

    .formSwitch {
      margin-right: 20px;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-gray-color;

      > span {
        margin-left: 8px;
      }
    }
  }
}

.entityOptionsDropDown {
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .icon {
    margin-right: 10px;
    svg {
      path,
      circle {
        stroke: $primary-color;
      }
    }
  }

  .entityOption {
    &:hover {
      cursor: pointer;
      background-color: $background-ligh-gray-3;
    }

    width: 176px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    padding: 10px 15px;

    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
