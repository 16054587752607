@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.row {
    transition: background 0.1s ease;

    &.hasAction:hover {
        background: $background-ligh-gray 90%;
    }
}

.childRow {}

.rowCell {
    white-space: nowrap;
}

.flexCell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.collapseButton {
    @include action-icon();
    margin-right: setUnit(8);
    width: setUnit(10);
    height: setUnit(10);
    border-radius: 50%;
    cursor: pointer;

    svg {
        width: setUnit(10);
        height: setUnit(10);
    }
}

.hasAction {
    cursor: pointer;
}
