@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.backgroundClassName {
  background-color: #d6d6d7 !important;
}

.main {
  width: 367px;
  max-width: 367px;
  height: calc(100dvh - 80px);
  overflow-y: auto;
  background: #161b29;
  padding: 58px 24px;
  position: relative;

  .switch {
    height: 24px;
    width: 44px;
    [class*='ant-switch'] {
      background-color: #777c91;
    }
    [class*='ant-switch-handle'] {
      border-radius: 50%;
      top: 1px;
      left: 0px !important;
      width: 20px;
      height: 20px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  [class*='ant-switch-checked'] {
    background-color: $primary-color;

    [class*='ant-switch-handle'] {
      border-radius: 50%;
      left: calc(100% - 21px) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .back {
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 31px;
    height: 44px;
    width: 44px;
    align-items: center;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profileAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #262626 !important;
  }
  .name {
    color: $white;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
    letter-spacing: -0.33px;
    margin-top: 11px;
    .icon {
      display: none;
      margin-left: 5px;
      cursor: pointer;
    }
    &:hover {
      .icon {
        display: inline;
      }
    }
  }
  .actionButtons {
    display: flex;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    span {
      color: rgba(255, 255, 255, 0.8);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
    .notes {
      width: 100%;
      height: 44px;
      padding: 10px 14px 10px 12px;
      gap: 6px;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.15);
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 6px;
      }
    }
    .highlights {
      width: fit-content;
      height: 44px;
      padding: 10px 20px;
      gap: 6px;
      border-radius: 24px;
      background: linear-gradient(0deg, rgba(255, 87, 109, 0.4) 0%, rgba(255, 87, 109, 0.4) 100%),
        linear-gradient(90deg, rgba(255, 0, 0, 0.4) -56.08%, rgba(251, 145, 58, 0.4) 82.17%);
      svg {
        position: relative;
        bottom: 2px;
        min-width: 16px;
        min-height: 16px;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 6px;
      }
    }
    .message {
      width: 75%;
      padding: 10px 14px 10px 12px;
      gap: 6px;
      border-radius: 24px;
      background: #161b29;
      border: 1px solid #e8e9ea80;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 6px;
      }
    }
  }
  .rowsContainer {
    margin: 20px 0;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      gap: 10px;
      .label {
        color: rgba(244, 245, 246, 0.7);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
        margin: 0;
        min-width: 65px;
        .icon {
          margin-left: 5px;
          display: none;
          cursor: pointer;
        }
      }
      &:hover {
        .label {
          .icon {
            display: inline;
          }
        }
      }
      .value {
        margin: 0;
        color: $white;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        div,
        input {
          background: #161b29;
          border: none !important;
          width: fit-content;
          color: $white !important;
          text-align: right;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          letter-spacing: -0.18px;
          max-width: 200px;
        }
        &.email {
          color: #abc8ff;
        }
      }
    }
    .card {
      border-radius: 8px;
      background: #282d3a;
      padding: 14px 16px;
      margin: 16px 0;
      .editIcon {
        display: none;
      }
      &:hover {
        .editIcon {
          display: block;
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        min-height: 25px;
        svg {
          cursor: pointer;
        }
        .title {
          color: rgba(244, 245, 246, 0.7);
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
          margin: 0;
        }
      }
      .address1 {
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin: 0;
      }
      .address2 {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin: 0;
      }
    }
  }
}

.compareOfferTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      background-color: #262626 !important;
      padding: 16px 16px 10px 16px;
      max-width: 50vw !important;

      p {
        color: white !important;
        margin-bottom: 4px;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.descInfoWrapper {
  display: flex;
  gap: 8px;
  .descInfo {
    color: $dark-grey-color;
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(16);
  }
  .descInfoWrapped {
    max-width: 25vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .emptyDescInfo {
    height: 14px;
  }
}
