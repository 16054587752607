@import 'styles/variables';

.quoteRightSidebar {
  background: $white !important;

  .noQuestionSelected {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text--light-gray-color;
    height: calc(100vh - $header-height);
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0px 44px;
  }

  & [class*='ant-layout-sider-children'] {
    width: 300px;
  }

  .sidebarContainer {
    padding: 24px;
    height: calc(100vh - $header-height - 45px - 48px);

    .helperText {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      display: flex;
      align-items: center;
      text-align: center;

      color: $text--light-gray-color;
    }

    .fileName {
      padding: 0px !important;
      color: $dark-color;
      cursor: auto;
    }
  }

  overflow-y: auto;

  & [class*=' ant-tabs-top'] {
    height: 100% !important;

    & [class*=' ant-tabs-content'] {
      height: 100% !important;
    }
  }
}

.noDocumentHint {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.expandedRowBtn {
  width: 100%;
}

.invalidFormFields {
  font-weight: 600;
}
