@import 'styles/variables';
@import 'styles/functions';

.acceptToursModal {
  button[class~='ant-modal-close'] {
    top: setUnit(32);
  }
  div[class~='ant-modal-header'] {
    margin-bottom: setUnit(40);
    div[class~='ant-modal-title'] {
      font-size: setUnit(24);
      font-weight: 700;
      line-height: setUnit(32);
      letter-spacing: setUnit(-0.46);
      text-align: center;
      color: $text-color;
    }
  }
  .modalBody {
    .clientInfo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: setUnit(12) 0;
      margin-bottom: setUnit(16);
      .ciAvatar {
        margin-right: setUnit(12);
        width: setUnit(40);
        height: setUnit(40);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: setUnit(17);
        font-weight: 600;
        line-height: setUnit(23);
        letter-spacing: setUnit(-0.128);
      }
      .ciRight {
        .name {
          font-size: setUnit(18);
          font-weight: 600;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.18);
          color: $text-color;
          margin-bottom: 0;
        }
        .about {
          font-size: setUnit(14);
          font-weight: 400;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.18);
          color: $text-gray-color;
          margin-bottom: 0;
        }
      }
    }
    .propertyFormWrap {
      margin-bottom: setUnit(12);
      .propertyForm {
        div[class~='ant-collapse-header'] {
          padding: setUnit(12) 0;

          .headerWrap {
            display: flex;
            flex-wrap: wrap;
            .icon {
              width: setUnit(40);
              height: setUnit(40);
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $background-grey-2;
              border-radius: setUnit(100);
              margin-right: setUnit(12);
              svg {
                width: setUnit(24);
                height: setUnit(24);
                path {
                  stroke: $dark-color;
                }
              }
            }
            .headerRight {
              .address {
                font-size: setUnit(16);
                font-weight: 400;
                line-height: setUnit(20);
                letter-spacing: setUnit(-0.18);
                margin-bottom: 0;
                color: $text-color;
                .addressOne {
                  font-weight: 600;
                }
              }
              .mls {
                font-size: setUnit(14);
                font-weight: 400;
                line-height: setUnit(20);
                letter-spacing: setUnit(-0.18);
                margin-bottom: 0;
                color: $dark-color;
              }
            }
          }
        }
        div[class~='ant-collapse-content'] {
          margin-top: setUnit(8);
          div[class~='ant-collapse-content-box'] {
            padding: 0;
            .tourData {
              padding: setUnit(24);
              border: 2px solid $light-gray;
              border-radius: setUnit(8);
              .fieldWrap {
                &:not(:last-child) {
                  margin-bottom: setUnit(24);
                }
                .label {
                  font-size: setUnit(14);
                  font-weight: 600;
                  line-height: setUnit(20);
                  letter-spacing: setUnit(-0.09);
                  color: $text-color;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-bottom: setUnit(16);
                  width: 100%;
                  .timezone {
                    font-size: setUnit(14);
                    font-weight: 400;
                    line-height: setUnit(20);
                    letter-spacing: setUnit(-0.09);
                    margin-left: setUnit(16);
                    color: $text-gray-color;
                  }
                  .required {
                    color: $primary-color;
                  }
                  .icon {
                    display: inline-flex;
                    margin-left: setUnit(8);
                    svg {
                      width: setUnit(16);
                      height: setUnit(16);
                      path {
                        stroke: $text--light-gray-color;
                      }
                    }
                  }
                }
                .dateAndTime {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  .toursDatePicker {
                    max-width: setUnit(164);
                    margin-top: setUnit(2);
                    width: 100%;
                    .toursDatePicker::placeholder {
                      padding-top: setUnit(8);
                    }
                  }
                  .text {
                    font-size: setUnit(16);
                    font-weight: 400;
                    line-height: setUnit(22);
                    letter-spacing: setUnit(-0.09);
                    color: $text-gray-color;
                    margin: 0 setUnit(24);
                  }
                  .toursTimePicker {
                    max-width: setUnit(88);
                    width: 100%;
                    margin-top: 14px;
                    padding-bottom: 18px;
                  }
                }
                .toursNotes,
                .tdpInput,
                .tdpInput[class~='active'],
                .toursTimePicker {
                  border: 0;
                  border-bottom: 2px solid $border-light-gray-2;
                  border-radius: 0 !important;
                  font-size: setUnit(16);
                  font-weight: 400;
                  line-height: setUnit(22);
                  letter-spacing: setUnit(-0.09);
                  color: $text-color;
                  padding: 0 0 setUnit(12) 0;
                  transition: $transition;
                  &::placeholder {
                    color: $text--light-gray-color;
                    padding-top: setUnit(8);
                  }
                  &:focus {
                    box-shadow: none;
                    outline: 0;
                    border-color: $text-gray-color;
                  }
                }
                .assignTo {
                  width: 100%;
                  [class~='ant-select-selector'] {
                    border: 0;
                    border-bottom: 2px solid $border-light-gray-2;
                    padding-bottom: setUnit(18);
                    border-radius: 0 !important;
                    box-shadow: none !important;
                    [class~='ant-select-selection-placeholder'] {
                      left: 0;
                    }
                    [class~='ant-select-selection-item'] {
                      align-items: center;
                      background-color: $background-grey-2;
                      border-color: $border-light-gray-2;
                      border-radius: setUnit(100);
                      padding: setUnit(6) setUnit(10) setUnit(6) setUnit(12);
                      [class~='ant-select-selection-item-content'] {
                        font-size: setUnit(14);
                        font-weight: 400;
                        line-height: setUnit(20);
                        letter-spacing: setUnit(-0.09);
                        color: $text-color;
                      }
                      [class~='ant-select-selection-item-remove'] {
                        display: flex;
                        align-items: center;
                        svg {
                          fill: $dark-color;
                        }
                      }
                    }
                  }
                  .assignToDropdown {
                    div[class~='ant-select-item'] {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      padding: setUnit(14) setUnit(16);
                      div[class~='ant-select-item-option-content'] {
                        font-size: setUnit(16);
                        font-weight: 500;
                        line-height: setUnit(21);
                        letter-spacing: setUnit(-0.18);
                        color: $text-color;
                        padding: 0;
                      }
                      &:after {
                        content: '';
                        width: setUnit(24);
                        height: setUnit(24);
                        border: 1.5px solid $text--light-gray-color;
                        border-radius: setUnit(4);
                      }
                      span[class~='ant-select-item-option-state'] {
                        display: flex;
                        align-items: center;
                      }
                      &[class~='ant-select-item-option-selected'] {
                        background-color: $background-grey-2;
                        &:after {
                          content: none;
                        }
                      }
                    }
                  }
                }
                .notesToggle {
                  font-size: setUnit(14);
                  font-weight: 500;
                  line-height: setUnit(16);
                  letter-spacing: setUnit(-0.09);
                  color: $text-color;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  cursor: pointer;
                  .addIcon {
                    margin-right: setUnit(10);
                    display: flex;
                    svg {
                      width: setUnit(20);
                      height: setUnit(20);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      .pendingWrapper {
        display: flex;
        flex: 1;
        .button {
          flex: 1;
        }
        .spinner {
          height: setUnit(44);
          .loader {
            font-size: setUnit(4);
          }
        }
      }
      .submit {
        font-size: setUnit(16) !important;
        font-weight: 600 !important;
        line-height: setUnit(24) !important;
        letter-spacing: 0px;
        width: setUnit(152);
        height: setUnit(52);
      }
    }
  }
}
