@import 'styles/variables';
@import 'styles/mixins';

.documentPopoverContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 0.75rem;
  text-align: center;
  border: 2px solid $light-gray;
  border-radius: 100px;
  height: 52px;
  width: 250px;
  padding: 10px;
  background-color: $white;
  min-width: max-content;

  margin: 0 auto;

  .itemContent {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;

    .arrowIcon {
      line-height: 0;
      svg {
        transition: all 0.3s;
      }
    }
  }

  .openedDropdown {
    svg {
      transform: rotate(-180deg);
    }
  }

  .iconItem {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;

    @media screen and (max-width: 450px) {
      white-space: nowrap;
      overflow: hidden;
      max-width: 35vw;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 375px) {
      max-width: 25vw;
    }
  }

  &:hover {
    border-color: $text--light-gray-color;
  }

  @media screen and (max-width: $mobile) {
    min-width: unset !important;
  }

  @media screen and (max-width: 475px) {
    height: 40px !important;
    width: 240px !important;
  }

  @media screen and (max-width: 400px) {
    width: 150px !important;
  }
}

.openedPopover {
  border-color: $text--light-gray-color;
}

.morePopover {
  width: setUnit(240);

  .optionsItems {
    width: 100%;

    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      padding: setUnit(8) 0;
      max-height: 300px;
      overflow-y: auto;

      li {
        padding: 0;

        label[class~='ant-radio-wrapper'] {
          display: flex;
          margin-right: 0;
          align-items: center;
          transition: $transition;
          padding: setUnit(14) setUnit(20);
          background-color: $white;
          min-height: setUnit(48);

          &:hover {
            background-color: $background-grey-2;

            span[class~='ant-radio'] {
              & + span {
                .option {
                  .optionValue {
                    color: $text-color;
                  }
                }
              }
            }
          }

          span[class~='ant-radio'] {
            display: none;
            & + span {
              flex: 0 0 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              gap: 20px;

              .option {
                display: flex;
                align-items: center;
                gap: 12px;
                overflow: hidden;

                .optionIcon {
                  line-height: 0;
                }

                .optionValue {
                  font-weight: 400;
                  font-size: setUnit(14);
                  line-height: setUnit(16);
                  letter-spacing: -0.09px;
                  color: $dark-color;
                }
              }

              .checkIcon {
                transition: $transition;
                height: setUnit(20);
                color: $green-color;

                svg {
                  width: setUnit(20);
                  height: setUnit(20);
                  path {
                    stroke: $green-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: inherit !important;
    max-width: 80%;
  }
}
