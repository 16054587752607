@import 'styles/functions';
@import 'styles/variables';

.images {
  margin-top: 0 !important;
  margin-bottom: 0;
  border-top: none !important;
  padding: 0 !important;

  .image {
    vertical-align: middle;
    width: 100%;
    height: setUnit(168);
    border-radius: setUnit(16);
    margin-right: setUnit(32);
    object-fit: cover;

    &:last-child {
      margin-right: 0;
    }
  }
}
