.filterContainer {
    padding: 9px 12px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
}

.inputContainer {
    margin-bottom: 8px;
}
