@import 'styles/functions';
@import 'styles/variables';

.transactions {
  .transactionStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1.25rem 0;
    cursor: pointer;

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $text-color;
      margin-bottom: 0;

      span {
        color: $text--light-gray-color;
      }
    }

    .arrowIcon {
      width: setUnit(32);
      height: setUnit(32);
      background: $background-grey-2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rotate {
      rotate: 180deg;
    }
  }

  .firstChild {
    margin-top: 0.5rem;
  }

  .transaction {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .details {
      display: flex;
      align-items: center;

      .icon {
        width: setUnit(40);
        height: setUnit(40);
        background: $background-grey-2;
        margin-right: 0.75rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .titleClients {
        margin-right: 16px;
        display: inline-block;
        cursor: pointer;

        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $text-color;
          margin-bottom: 0.25rem;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $dark-color;
          margin-bottom: 0;
        }
      }
    }

    .closing {
      font-size: 14px;
      font-weight: 400;
      color: $dark-color;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}
