@import 'styles/mixins';
@import 'styles/variables';

.optionHolder {
  .checkboxOption {
    width: 100%;
    border-radius: setUnit(8);
    border: 2px solid $light-gray;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    padding: 10px 16px 10px 20px;
    transition: $transition;
    height: 56px;
    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    &.checked {
      background-color: #4673d114 !important;
      border: 1px solid #4673d1 !important;
      .checkbox {
        background: #4673d1 !important;
      }
      &:hover {
        background-color: #ffebed;
        border: 1px solid #4673d1 !important;
        .checkbox {
          background: #4673d1 !important;
        }
      }

      .label {
        .labelText {
          font-weight: 500;
          color: $text-color;
        }
      }
    }

    .label {
      display: inline-flex;
      margin-bottom: 0;
      pointer-events: none;
      width: calc(100% - 26px);
      margin-right: 6px;
      .labelText {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.18px;
        margin-left: 12px;
        color: $dark-color;
        width: calc(100% - 36px);
      }
    }
  }

  .checkboxWrapper {
    height: 1.5rem;
    width: 1.5rem;

    .checkmark {
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: initial !important;
      top: 0 !important;
      left: 0 !important;

      &.unchecked {
        display: none !important;
      }

      svg {
        height: 60% !important;
        width: 60% !important;
      }
    }
  }

  .unchecked {
    border: 2px solid $text--light-gray-color !important;
  }
}

.label {
  margin-bottom: 10px;
  color: $dark-color;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.09px;
}
