@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.cardWrap {
  background: $background-grey-2;
  border-radius: setUnit(8);
  padding: setUnit(20);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: setUnit(8);
  }
  .cardIcon {
    width: setUnit(26);
    height: setUnit(26);
    margin-right: setUnit(12);
  }
  .cardContent {
    margin-right: setUnit(12);
    max-width: calc(100% - #{setUnit(74)});
    width: 100%;
    .cardTitle {
      font-family: $font-family;
      font-weight: 500;
      font-size: setUnit(16);
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: setUnit(4);
    }
    .cardMeta {
      font-family: $font-family;
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.09px;
      color: $dark-color;
      margin-bottom: 0;
      &.pending {
        color: $hover-red;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
