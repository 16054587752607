@import 'styles/variables';
@import 'styles/functions';

.tourCards {
  &.disabled {
    position: relative;
    pointer-events: none;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .tourCard {
    box-shadow: 0px 2px 16px 0px #30303014;
    background-color: $white;
    border-radius: setUnit(16) !important;
    padding-top: setUnit(8);
    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
    div[class~='ant-collapse-header'] {
      align-items: center;
      flex-wrap: wrap;
      padding: setUnit(13) setUnit(24);
      height: setUnit(64);
      div[class~='ant-collapse-expand-icon'] {
        order: 2;
        div[class~='ant-collapse-arrow'] {
          position: relative;
          top: unset;
          right: unset;
          left: unset;
          transform: translateY(0);
        }
      }
      span[class~='ant-collapse-header-text'] {
        .headerWrap {
          .headerLeft {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .avatar {
              margin-right: setUnit(12);
            }
            .title {
              font-size: setUnit(16);
              font-weight: 600;
              line-height: setUnit(24);
              letter-spacing: setUnit(-0.33);
              margin-bottom: 0;
            }
          }
        }
        .headerRight {
        }
      }
    }
    div[class~='ant-collapse-content'] {
      div[class~='ant-collapse-content-box'] {
        padding: 0 0 setUnit(20);
      }
    }
    .cancelledInfo {
      flex: 0 0 calc(100% - #{setUnit(205)});
    }
    .cancelled {
      font-size: setUnit(14);
      font-weight: 500;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.09);
      color: $dark-grey-color;
      background-color: $background-grey-2;
      border-radius: setUnit(100);
      padding: setUnit(6) setUnit(8);
    }
  }
}
