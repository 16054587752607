@import 'styles/functions';
@import 'styles/variables';

.phoneItem {
  .phone {
    input {
      font-size: setUnit(16);
      text-align: right;
      cursor: text;
      max-width: 7rem;
    }
  }

  .phoneContainer {
    display: flex;

    .editIcon {
      display: none;
      cursor: pointer;
      position: relative;
      top: 0.1rem;
      right: 0.5rem;
    }
  }

  &:hover {
    .phoneContainer .editIcon {
      display: block;
    }
  }

  .editPhoneContainer {
    display: flex;

    .editPhone {
      margin: -0.8rem 0 0;
      height: 1.5rem;

      .editPhoneInput {
        border-radius: 0;
        max-width: unset;
      }
    }
  }
}

.expanded {
  padding: setUnit(14) 0;
}
