.mainWrapper {
  margin-top: 24px;
  position: relative;
  border-radius: 16px;
  background: #fff;
  padding: 20px 24px 16px 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding: 20px 0px 16px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .title {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.33px;
    margin-bottom: 8px;
  }
  .list {
    width: 100%;
    padding-right: 24px;
  }
  .needsAttentionCardCardWrapper {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .details {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .detailsInner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .main {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 111.111% */
        letter-spacing: -0.18px;
      }
      .closeWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        gap: 16px;
        .close {
          color: var(--Grayscale-100, #262626);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.18px;
        }
      }
    }
    .detailsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .rightIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .countBadgeRed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(var(--Primary-Red, 255, 87, 109), 0.12);
    color: var(--Primary-Red, #ff576d);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: -0.33px;
  }

  .countBadgeYellow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(254, 112, 88, 0.12);
    color: #fe7058;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: -0.33px;
  }

  .flexCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;

    .checkMark {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #04a45114;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    & p {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.33px;
      text-align: center;
      color: #aaabab;
    }
  }
}
