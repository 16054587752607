@import 'styles/functions';
@import 'styles/variables';

.shareDrawer {
  .drawerInner {
    height: calc(100vh - #{setUnit(93)});
  }

  .autocompleteInputHolder {
    margin: 0 setUnit(32);
    position: relative;
  }

  .prefixParticipants {
    top: setUnit(15);
    color: #747475;
  }

  .participantsValues {
    height: calc(100vh - #{setUnit(296)});
    overflow: auto;
  }

  .participantsValue {
    padding-left: setUnit(32);
    padding-right: setUnit(32);
  }

  .scrollWrapper {
    flex: 1;
  }

  .photosWrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .searchInput {
    margin: 0;
  }

  .submitButton {
    width: setUnit(105);
    min-width: setUnit(105);
    height: setUnit(39);
    margin-left: auto;
    display: block;
  }

  .valuesWrapper {
    div[class*='values'] {
      display: block !important;
      margin-top: setUnit(30) !important;
    }
  }
}
