@import "styles/functions";
@import "styles/variables";

.bioInfo {
    .infoPart {
        margin-top: setUnit(45);
    }

    .description {
        line-height: setUnit(21);
        margin-top: setUnit(22);
    }
}
