@import 'styles/variables';

.addNewIcon {
  line-height: 0;
  display: flex;
  align-items: center;
  padding: 1.125rem 0.75rem;
  margin-top: 5px;
  gap: 8px;

  cursor: pointer;

  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;
    color: $dark-color;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: $background-ligh-gray;
    .label {
      color: $text-color;
      font-weight: 500;
    }

    svg {
      path {
        stroke: $text-color;
        stroke-width: 2.2;
      }
    }
  }
}
