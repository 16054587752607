@import 'styles/functions';
@import 'styles/variables';

.scrollWrapper {
  &.isCompares {
    height: calc(100vh - #{setUnit(342)});
  }
}

.pendingWrapper {
  height: calc(100vh - #{setUnit(287)});
  @media (min-width: $tablet) {
    height: 100%;
  }
}

.noResults {
  height: 100%;
}
