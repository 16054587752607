@import 'styles/functions';
@import 'styles/variables';

.documents {
  padding-bottom: 1rem;
  border-bottom: 1px solid $light-gray;

  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .disclaimer {
    text-align: center;
    margin-bottom: 0;
    min-height: 80px;
    color: $text--light-gray-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
