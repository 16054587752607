@import 'styles/variables';
@import 'styles/functions';

.compareOfferTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      padding: 16px;
      .tooltipData {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}
