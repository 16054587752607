@import 'styles/functions';
@import 'styles/variables';

.addLinkBtn {
  border: 0;
  outline: none;
  font-size: setUnit(14);
  cursor: pointer;
  background-color: transparent;
  margin-bottom: setUnit(10);
  display: flex;
  align-items: center;
  margin-top: 12px;
  .addLinkBtnIcon {
    height: setUnit(20);
    svg {
      width: setUnit(20);
      height: setUnit(20);
      margin-right: setUnit(8);
      display: inline-block;
    }
  }

  &:hover {
    opacity: 0.85;
  }
}

.input {
  margin: 0 0 setUnit(10) 0 !important;

  input {
    font-size: setUnit(16) !important;
    font-weight: normal !important;
  }
}

.first {
  margin-top: setUnit(16);
}
