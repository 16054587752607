@import 'styles/functions';
@import 'styles/variables';

.clientSearchArea,
.spinner,
.error,
.add {
  margin-top: setUnit(32);
}

.clientSearchArea {
  display: flex;
  justify-content: center;
  margin-bottom: setUnit(32);

  .clientSearch {
    width: setUnit(320);
    position: relative;

    &Input {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .selectedClient {
        &Image {
          border-radius: 50%;
          height: setUnit(32);
          width: setUnit(32);
        }

        &Placeholder {
          border-radius: 50%;
          height: setUnit(32);
          width: setUnit(32);
          background-color: $secondary-color;
          color: $white;
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.1);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &Name {
          flex-grow: 1;
          margin-left: setUnit(16);
          margin-right: setUnit(8);

          &User {
            font-weight: 600;
            font-size: setUnit(16);
            line-height: setUnit(20);
            font-family: $font-family;
            letter-spacing: setUnit(-0.18);
            color: $text-color;
          }

          &Instance {
            font-weight: 400;
            font-size: setUnit(14);
            line-height: setUnit(16);
            font-family: $font-family;
            letter-spacing: setUnit(-0.09);
            color: $dark-color;
          }
        }
      }

      &Text {
        height: setUnit(36);
        margin-left: setUnit(16);
        margin-right: setUnit(8);
        flex-grow: 1;
        border: none;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: setUnit(-0.18);

        &::placeholder {
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $text--light-gray-color;
        }

        &::-ms-input-placeholder {
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $text--light-gray-color;
        }

        &:focus {
          outline: none;
        }
      }

      &Close {
        cursor: pointer;
      }
    }

    &Results {
      width: 100%;
      position: absolute;
      max-height: setUnit(328);
      box-shadow: 0px 2px 12px 0px #00000026;
      border-radius: setUnit(8);
      padding-top: setUnit(16);
      padding-left: setUnit(16);
      overflow-y: auto;
      top: setUnit(40);
      background: $white;

      &Heading {
        font-weight: 500;
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $text-gray-color;
      }

      .dropdown {
        &Item {
          display: flex;
          cursor: pointer;
          margin-bottom: setUnit(16);

          &Image {
            border-radius: 50%;
            height: setUnit(32);
            width: setUnit(32);
          }

          &Placeholder {
            border-radius: 50%;
            height: setUnit(32);
            width: setUnit(32);
            background-color: $secondary-color;
            color: $white;
            font-weight: 600;
            font-size: setUnit(14);
            line-height: setUnit(16);
            font-family: $font-family;
            letter-spacing: setUnit(-0.1);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &Name {
            margin-right: setUnit(8);
            font-weight: 600;
            line-height: setUnit(16);
            font-family: $font-family;
            letter-spacing: setUnit(-0.09);
            margin-left: setUnit(12);

            &Client {
              font-size: setUnit(16);
              color: $text-color;
              margin-bottom: setUnit(6);
            }

            &Instance {
              font-size: setUnit(14);
              color: $dark-color;
            }
          }
        }
      }
    }
  }
}

.add {
  &Header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: setUnit(24);
    cursor: pointer;

    &Text {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: -0.18px;
      margin-left: setUnit(8);

      @media screen and (max-width: $mobile) {
        font-size: setUnit(14);
      }
    }
  }

  &Footer {
    display: flex;
    justify-content: center;
    align-items: center;

    &Text {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: -0.09px;
      color: $text-gray-color;

      @media screen and (max-width: $mobile) {
        font-size: setUnit(14);
      }
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  letter-spacing: -0.09px;
  color: $hover-red;
}
