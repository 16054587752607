@import 'styles/variables';

.noNotifications {
  height: calc(100vh - 94px);
  display: flex;
  align-items: center;
  justify-content: center;
  .wrap {
    padding: 0 32px;
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.09px;
      text-align: center;
      color: $text--light-gray-color;
      margin-bottom: 24px;
    }
    .button {
      border: 2px solid $background-ligh-gray-4;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 218px;
      width: 100%;
      margin: auto;
      padding: 12px;
      transition: $transition;
      &:hover {
        border-color: $text--light-gray-color;
      }
      .icon {
        display: flex;
        margin-right: 6px;
        svg {
          path {
            stroke: $primary-color;
          }
        }
      }
      .buttonText {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.09px;
        color: $background-blue;
      }
    }
  }
}
