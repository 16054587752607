@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filterWrap {
  position: relative;
  margin-left: 10px;
  &.filtersAppliedWrapper {
    background-color: $primary-color-light !important;
    border-radius: setUnit(100) !important;
    &:hover {
      border-color: $primary-color !important;
    }
    a {
      border: 2px solid $primary-color !important;
      &:hover {
        border-color: transparent;
      }
    }
  }
  .filterButton {
    height: setUnit(44);
    padding: setUnit(10) setUnit(12);

    .selectedValue {
      color: $dark-color;
      padding: 0 setUnit(5);
      font-weight: 600;
    }

    .optionIcon {
      margin-right: setUnit(4);
      display: flex;
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }
    }
    .icon {
      display: flex;
      transition: $transition;
      svg {
        width: setUnit(12);
        height: setUnit(6);
        path {
          stroke: $dark-color;
        }
      }
    }
    &[class~='ant-dropdown-open'] {
      border-color: $text-gray-color;
      background-color: $background-grey-2;
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: $mobile) {
    margin: setUnit(10) 0;
  }
}

.teamFilterDropdown {
  width: setUnit(240);
  .optionsItems {
    width: 100%;
    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      padding: setUnit(8) 0;
      li {
        padding: 0;
        label[class~='ant-radio-wrapper'] {
          display: flex;
          margin-right: 0;
          align-items: center;
          transition: $transition;
          padding: setUnit(14) setUnit(24);
          background-color: $white;
          height: setUnit(56);
          &:hover {
            background-color: $background-grey-2;
          }
          span[class~='ant-radio'] {
            display: none;
            & + span {
              flex: 0 0 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              .option {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .dropdownOptionIcon {
                  margin-right: setUnit(8);
                  display: flex;
                }
                .optionValue {
                  font-weight: 500;
                  font-size: setUnit(16);
                  line-height: setUnit(16);
                  letter-spacing: -0.09px;
                  color: $text-color;
                }
              }
              .checkIcon {
                transition: $transition;
                height: setUnit(20);
                color: $green-color;
                svg {
                  width: setUnit(20);
                  height: setUnit(20);
                  path {
                    stroke: $green-color;
                  }
                }
              }
            }
          }
          &[class~='ant-radio-wrapper-checked'] {
            span[class~='ant-radio-checked'] {
              & + span {
                .checkIcon {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}
