@import 'styles/functions';
@import 'styles/variables';

.uploadDocuments {
  .customDocumentUploadButton {
    height: setUnit(52) !important;
    width: 100%;
    margin-top: 12px;

    display: flex !important;
    align-items: center;
    justify-content: center;

    background-color: transparent !important;
    border: 1px solid $light-grey-color !important;
    border-radius: 8px !important;

    font-size: setUnit(16) !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -0.18px;

    gap: 8px;
    color: $text-color !important;
    &:hover {
      background-color: $background-grey-2 !important;
    }
  }

  .iconButton {
    background-color: $background-grey-2 !important;
    width: 100%;
    height: 40px !important;
    border-radius: 100px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 0 !important;
    color: $text-color !important;
    font-weight: 500 !important;
    gap: 6px;

    &:hover {
      background-color: $background-grey-2 !important;
      border: 0 !important;
    }
    &:focus {
      border: 0 !important;
    }
  }

  .icon {
    line-height: 0;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 12px !important;
  }
}
