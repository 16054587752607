@import 'styles/functions';
@import 'styles/variables';

.searchWrapper {
  width: 344px;
  .icon {
    display: flex;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;
      }
    }
  }

  .search {
    .searchField {
      width: 100%;
      input {
        background: $white;
        border: 2px solid $border-light-gray-2;
        font-size: setUnit(16);
        height: 44px;
        border-radius: 100px !important;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        &::placeholder {
          color: $text--light-gray-color;
        }
      }
    }
  }
}

.searchInput {
  border-radius: 1.25rem !important;
}
