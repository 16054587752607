@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  color: $text-color;
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: setUnit(15) setUnit(12);
  border-radius: setUnit(8);
  font-size: setUnit(14);
  line-height: setUnit(16);
  color: $dark-color;

  &:nth-child(odd) {
    background-color: $background-ligh-gray;
  }

  .value {
    text-align: right;
    word-break: break-word;
  }
  .value :last-child {
    margin-bottom: 0px;
  }
}

span {
  .correctIcon {
    color: $success-color;
  }

  .mismatchIcon {
    color: $dark-gray-2;
  }

  .missingIcon {
    color: $error-color;
  }
}

.statusIcon {
  margin-right: setUnit(8);
}

.subValue {
  margin-left: 1rem;
}

.headerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.headerItem p {
  margin: 0;
  margin-right: setUnit(8);
}

.bold {
  color: $text-color;
  font-weight: 600;
  word-break: normal;
}
