@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  text-align: center !important;
  margin-bottom: setUnit(40);
}

.answersWrapper {
  margin: 32px auto 20px !important;
  padding: 0 16px;
  max-height: 432px;
  overflow-y: auto;
}

.option {
  height: setUnit(64) !important;
  border-radius: setUnit(8) !important;
  box-shadow: none;
}

.next {
  margin: 20px auto setUnit(0) auto;
  width: 212px !important;
  height: 52px !important;
}

.counter {
  margin: setUnit(68) 0;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  width: setUnit(675) !important;

  .innerHolder {
    padding: setUnit(70) setUnit(20) setUnit(20) !important;
    @media (min-width: $mobile-small) {
      padding: setUnit(60) setUnit(80) setUnit(40) setUnit(80) !important;
    }
  }
}

.close {
  background: none !important;
  width: setUnit(58) !important;
  height: setUnit(58) !important;
  right: 0 !important;

  span {
    height: setUnit(3) !important;
    width: 40% !important;
    background: $border-input-middle-gray !important;
    border-radius: setUnit(2) !important;
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}
