@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  margin-bottom: 40px;
}

.counter {
  margin: setUnit(68) 0;
}

.next {
  margin: setUnit(48) auto 0 auto;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: setUnit(675) !important;
  width: 100% !important;
  .innerHolder {
    padding: setUnit(40);
    max-height: setUnit(600);
    @media (min-width: $mobile-small) {
      padding: 0 setUnit(80) setUnit(40) !important;
    }
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}

.bottom {
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: center;

  .saveButton {
    background-color: $background-blue !important;
    min-width: 212px;
    min-height: 52px;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: block;
    border-radius: 0.5rem;

    &:hover {
      background-color: $secondary-color-hover !important;
    }
  }
}
