@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.signupText {
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);
  text-align: center;
  font-weight: 400;
  color: $dark-color;
  @media (min-width: 992px) {
    text-align: left;
  }
}

.signupLink {
  @include link();
  margin-left: setUnit(8);
  font-weight: 500;
  color: $link-blue;
}
