@import 'styles/functions';
@import 'styles/variables';

.mainContentWrapper {
  max-width: 100vw;
  background-color: $background-ligh-gray-3;
  padding: setUnit(48) setUnit(40) setUnit(24);
  @media (max-width: $tablet) {
    padding: setUnit(48) setUnit(24) setUnit(24);
  }
}
.cardWrapperStyles {
  height: setUnit(395) !important;
  margin-top: setUnit(24) !important;
}

.dashboardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: $tablet) {
    flex-direction: column-reverse;
  }
}

.sliderColumn {
  margin-right: 24px;
  width: 600px;

  @media (max-width: $tablet) {
    margin-right: 0;
    width: 100%;
    margin-top: 20px;
  }

  .sliderColumnInner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.timelineColumn {
  width: 356px;

  @media (max-width: $tablet) {
    width: 100%;
  }
}
