@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.editButton {
  @include grid-action-button();
}

.deleteButton {
  @include grid-action-button($error-color, $hover-red, $white);
}

.deactivateButton {
  @include grid-action-button($primary-color, $hover-peach, $white);
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deactivateButton {
  .buttonIcon {
    & > svg {
      path {
        stroke-width: 3px !important;
      }
    }
  }
}
