@import 'styles/functions';
@import 'styles/variables';

.propertyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  padding: 0 setUnit(24) setUnit(40);
  justify-content: center;
}

.propertyListWrapper {
  margin-bottom: 3rem;
}
