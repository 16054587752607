@import 'styles/functions';
@import 'styles/variables';

.newMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $background-ligh-gray-4;
  max-width: setUnit(121);
  height: setUnit(40);
  border-radius: setUnit(20);
  cursor: pointer;
  background-color: transparent;
  transition: $transition;
  &:hover {
    background-color: $background-ligh-gray;
    .text {
      color: $text-color;
    }
    svg {
      path {
        fill: $text-color;
      }
    }
  }
  .text {
    font-size: setUnit(14);
    line-height: setUnit(24);
    letter-spacing: setUnit(-0.18);
    font-weight: 500;
    color: $dark-color;
    transition: $transition;
  }
  .icon {
    display: flex;
    margin-right: setUnit(8);
    svg {
      width: setUnit(24);
      height: setUnit(24);
      transition: $transition;
      path {
        fill: $dark-color;
      }
    }
  }
}
