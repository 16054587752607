@import 'styles/variables';

.cardContentWrapper {
  position: relative;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;

  & > * {
    margin-left: 5px;
  }
}

.eyeIcon {
  color: $green !important;
}

.deleteIcon {
  color: $radio-red !important;
}

.editIcon {
  color: $light-blue-2 !important;
}
