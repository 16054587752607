@import 'styles/functions';
@import 'styles/variables';

.imagesStatus {
  .imagesStatusTable {
    .imagesStatusTableRow {
      .imagesStatusTableColumn {
        .content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $text-gray-color;
          height: 40vh;

          .text {
            margin-top: setUnit(32);
          }
        }
      }
    }
  }
}
