@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.customPhoneNumber {
  padding: setUnit(12) 0 setUnit(16);
  outline: none;
  margin: 0;
  width: 100%;
  vertical-align: middle;
  color: $text-color;
  font-size: setUnit(18);
  font-weight: 400;
  line-height: setUnit(24);
  letter-spacing: -0.09px;
  height: setUnit(52);
  border: 0;
  border-bottom: 2px solid $border-input-middle-gray;
  transition: $transition;
  &:focus {
    border-bottom-color: $text-gray-color;
  }
  &::placeholder {
    color: $text--light-gray-color;
    font-weight: 400;
    font-size: setUnit(16);
  }
}

.error {
  @include inputError;
  margin-top: 0.3rem;
}
