@import 'styles/functions';
@import 'styles/variables';

@import '../../mixins.module.scss';

.formCategoriesWrapper {
  margin-bottom: 20px;

  .heading {
    @include modalHeading;
  }

  .categoryCard {
    width: 286px;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;

    cursor: pointer;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .disabledCategory {
    cursor: no-drop;
    opacity: 0.4;
  }

  .upload {
    background-color: rgba($light-blue, 0.1);
  }

  .library {
    background-color: rgba($purple, 0.1);
  }

  .recentUploads {
    background-color: rgba($confetti-blue, 0.1);
  }

  .template {
    background-color: rgba($orange, 0.1);
  }

  .categoryIcon {
    line-height: 0;
  }

  .categoryLabel {
    color: $text-color;
    text-align: center;
    font-size: setUnit(18);
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.33px;

    margin-top: 16px;
  }
}
