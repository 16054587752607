@import "styles/functions";
@import "styles/variables";

.title {
    padding: setUnit(23) setUnit(32) setUnit(25) setUnit(32);
    color: $text-color;
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(26);
    display: flex;
    align-items: center;
    height: setUnit(80);

    .titleIcon {
        margin-right: setUnit(8);
        height: setUnit(32);

        svg {
            width: setUnit(32);
            height: setUnit(32);
        }
    }
}

.searchMessagesInput {
    margin: 0 setUnit(32) setUnit(28) setUnit(32) !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .scrollWrapper {
        flex: 1;
        overflow: auto;
    }
}