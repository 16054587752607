@import 'styles/functions';
@import 'styles/variables';

.profileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: setUnit(22) setUnit(32);

  .back {
    margin-left: 0;
    padding-left: 0;
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    color: $text-color;
  }
}
