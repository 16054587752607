@import "styles/functions";
@import "styles/variables";

.field {
    display: flex;
    align-items: center;
    margin-top: setUnit(16);

    &:first-child {
        margin-top: 0;
    }

    .label {
        min-width: setUnit(140);
        font-size: setUnit(14);
        color: $dark-grey-color;
    }

    .input {
        width: 100%;
    }
}
