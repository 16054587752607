@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  min-height: calc(100vh - $header-height);
  color: $text-color;
}

.container {
  height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  max-width: $tablet-horizontal;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  flex: auto;
}

.leftMenu {
  position: sticky;
  top: 80px;
  background-color: #fff;
  padding: setUnit(10) setUnit(30);
  margin: setUnit(15) 0;
  border: 2px solid $border-light-gray;
  box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 0.2);
  @media (min-width: $mobile) {
    flex: 0 0 setUnit(296);
    position: relative;
    top: 0;
    background-color: transparent;
    margin: 0;
    box-shadow: none;
    border: 0;
  }
}

.rightContent {
  flex: 1 0 100%;
  @media (min-width: $mobile) {
    flex: 0 0 calc(100% - #{setUnit(296)});
  }
  padding: setUnit(40);
  border-right: 1px solid $background-grey-2;
  border-left: 1px solid $background-grey-2;
  background-color: $white;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
}

.title {
  font-size: setUnit(24);
  font-weight: bold;
  color: $text-color;
}
