@import 'styles/functions';
@import 'styles/variables';

.scrollWrapper {
  height: calc(100vh - #{setUnit(223)});
}

.description {
  margin: setUnit(8) 0 setUnit(12);
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.block {
  padding: setUnit(24) 0;
  &:not(:first-child) {
    padding-top: 0;
  }
}

.footer {
  @media (max-width: 991.99px) {
    position: fixed;
    bottom: 0px;
    max-width: setUnit(480);
    width: 100%;
  }
}
