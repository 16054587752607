@import 'styles/variables';

.footerContainer {
  text-align: center;
  width: fit-content;
  margin: auto;
  background-color: $white;
  padding: 6px 12px;
  border-radius: 8px;

  .autoSaveText,
  .savingText {
    color: $text-gray-color;
    font-family: $font-family;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .loading {
    .savingText {
      margin-right: 10px;
    }
  }
  .saveButton {
    color: $link-blue;
    font-family: $font-family;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
