@import 'styles/functions';
@import 'styles/variables';

.label {
  font-weight: 600;
  font-size: setUnit(16);
  text-align: center;
  line-height: setUnit(21);
  color: $border-middle-gray;
  padding: setUnit(50) 0;
}
