@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
}

.editButton {
  @include grid-action-button();
}

.deleteButton {
  @include grid-action-button($error-color, $hover-peach, $white);
}

.deactivateButton {
  @include grid-action-button($primary-color, $hover-peach, $white);
}

.activateButton {
  @include grid-action-button($secondary-color, $hover-blue, $white);
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activateButton,
.deactivateButton {
  .buttonIcon {
    & > svg {
      path {
        stroke-width: 3px !important;
      }
    }
  }
}

.link {
  a {
    color: $text-color;
    transition: all 0.1s;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.modalInnerHolder {
  padding: setUnit(32) !important;
  width: setUnit(640);
}

.modalContent {
  width: auto !important;
}

.filter {
  max-width: setUnit(280);
  font-size: setUnit(16);
  height: setUnit(40);
  margin-left: auto !important;
}

.inputHolder {
  input {
    height: setUnit(40) !important;
  }
}

.multilineText {
  white-space: normal;
  min-width: setUnit(190);
}

@media screen and (max-width: $tablet) {
  .modalContent {
    div:first-child {
      right: setUnit(8);
      top: setUnit(8);
    }
  }
}

.descriptionCell {
  max-width: setUnit(460);
  white-space: normal;
  margin-bottom: 0;
}
