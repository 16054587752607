@import 'styles/variables';

.footerContainer {
  width: 100%;

  margin-top: 25vh;
  .footerButton {
    width: 142px;
    height: 52px;
    border-radius: 8px;
  }

  .separator {
    width: 100%;
    display: block;
    height: 1px;
    background-color: $light-gray;
    margin: 23px auto;
  }
}
