@import 'styles/variables';
@import 'styles/functions';

.inputSelectModal {
  .formTitle {
    text-align: center;

    .formHeaderIcon {
      line-height: 0;
      margin-bottom: 24px;
    }

    h4 {
      margin-bottom: 24px;
    }
  }

  & [class*='ant-btn-primary'] {
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    height: auto;

    background-color: $secondary-color !important;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }

    &:focus {
      border-color: $secondary-color;
    }
  }

  & [class~='ant-btn-default'] {
    font-weight: 500 !important;
    color: $dark-color !important;

    span {
      font-weight: 500 !important;
    }
  }
}
