@import 'styles/functions';
@import 'styles/variables';

.row {
  height: 182px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid $light-gray;

  .image {
    height: 100%;
    width: 180px;

    > div {
      width: 100% !important;
      height: 100% !important;

      > span {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .description {
    display: flex;
    padding: 0 15px;
    align-items: center;
    width: calc(100% - 180px);
    justify-content: space-between;

    .data {
      overflow: hidden;
      width: 240px;
      .data1 {
        margin-bottom: 8px;
        width: 180px;
        min-width: 155px;
        height: 26px;
        display: block;
      }

      .data2 {
        margin-bottom: 12px;
        width: 160px;
        min-width: 140px;
        height: 16px;
        display: block;
      }

      .data3 {
        margin-bottom: 4px;
        width: 140px;
        min-width: 100px;
        height: 16px;
        display: block;
      }

      .data4 {
        margin-bottom: 4px;
        width: 120px;
        min-width: 80px;
        height: 16px;
        display: block;
      }
    }

    .icons {
      overflow: hidden;
      text-align: right;

      .icon1 {
        > span {
          border-radius: 16px;
          width: 50px;
          min-width: 50px;
          height: 32px;
        }

        margin-bottom: 8px;
        display: block;
      }

      .icon2 {
        > span {
          border-radius: 16px;
          width: 50px;
          min-width: 50px;
          height: 32px;
        }

        margin-bottom: 30px;
        display: block;
      }

      .icon3 {
        margin-right: 4px;
      }

      .icon3,
      .icon4 {
        > span {
          height: 32px;
          width: 32px;
          min-width: 32px;
        }
      }
    }
  }
}
