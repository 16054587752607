@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.feeOptionsWrapper {
  position: relative;
  .optionsDots {
    cursor: pointer;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.feeOptionsPopover {
  [class*='ant-popover-content'] {
    width: 0;
    left: 2rem;

    [class*='ant-popover-inner'] {
      background: transparent;
      box-shadow: unset;
    }
  }

  .options {
    @include box-shadow;
    position: absolute;
    top: setUnit(40);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;
    ul {
      list-style: none;
      padding: setUnit(8) 0;
      margin-bottom: 0;
      li {
        padding: setUnit(9) setUnit(12);
        display: flex;
        align-items: center;
        cursor: pointer;
        @include transition();
        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
        }
        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
