@import 'styles/variables';
@import 'styles/functions';

.memberSearchDropdownWrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  .searchWrapper {
    flex-grow: 1;
    .inputWrap {
      background: $white;
      border: 1px solid $border-light-gray-2;
      border-radius: 12px !important;
      width: 100% !important;
      height: 48px;
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;

      box-shadow: unset !important;

      &:hover {
        border: 1px solid $text--light-gray-color;
      }

      input::placeholder {
        color: $text--light-gray-color !important;
      }

      &:focus {
        border-width: 2px;
      }

      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }

      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
        max-width: unset;
      }

      & [class~='ant-input-prefix'] {
        margin-right: 8px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
