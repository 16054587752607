@import 'styles/functions';
@import 'styles/variables';

.messagesSubheader {
  padding: 0 setUnit(32) 0;
  width: 100%;
  border-bottom: 2px solid $border-light-gray;
  display: flex;
  align-items: center;
  min-height: setUnit(90);

  .chatHeaderUserRole {
    background: rgba(70, 115, 209, 0.1);
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    padding: 0px 9px;
    margin: 0px 10px;
    color: $confetti-blue;
  }

  .avatars {
    .avatarWrapper {
      margin-left: setUnit(-35) !important;

      &:first-child {
        margin-left: 0 !important;
      }
    }
  }

  .avatar {
    width: setUnit(56);
    height: setUnit(56);

    &.isNotSingle {
      width: setUnit(50) !important;
      height: setUnit(50) !important;
    }
  }

  .overflow {
    width: setUnit(50) !important;
    height: setUnit(50) !important;
  }
}
