@import 'styles/functions';
@import 'styles/variables';

.offerLink {
  padding: 1.25rem 0;
  .content {
    .container {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .checkbox {
        [class~='ant-checkbox'] {
          [class~='ant-checkbox-inner'] {
            border-radius: 50px;
          }
          &[class~='ant-checkbox-checked']:after {
            border-radius: 50px;
          }

          & + span {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .static {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.18px;
              color: $dark-color;
            }

            .daysInputHolder {
              border: none;
              width: 40px;
              padding: 0px !important;
              margin-right: 8px;
              input {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.18px;
                color: $text-color;
                border-bottom: 2px solid $border-light-gray-3;
                padding: 0;
                text-align: center;

                &::placeholder {
                  color: $text-gray-color;
                }
              }
              [class~='ant-input-number-handler-wrap'] {
                display: none;
              }
              &[class~='ant-input-number-focused'] {
                box-shadow: none;
                input {
                  border-bottom-color: $text-gray-color;
                }
              }
            }
          }
        }
        &[class~='ant-checkbox-wrapper-checked'] {
          [class~='ant-checkbox'] {
            & + span {
              .static {
                color: $text-color;
              }
            }
          }
        }
      }
      .deadlineDate {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    .rangePicker {
      border: 0;
      padding: 0.25rem;
      width: 120px;
      margin-right: 8px;
      [class*='ant-picker-input'] {
        padding: 0.25rem;
        border-bottom: 2px solid $border-input-middle-gray;
      }
    }
    .timePicker {
      margin-left: 8px;
      margin-right: 0;
    }
    .offerExpiration__lastRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    [class*='ant-picker-focused'],
    [class*='ant-input-number-focused'] {
      box-shadow: none;
    }

    [class*='ant-picker-suffix'],
    [class*='ant-picker-clear'],
    [class*='ant-picker-active-bar'] {
      display: none;
    }
  }
}
.checkboxExpiration {
  margin-right: 8px;
}
