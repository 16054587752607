@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parties {
  .contentWrapper {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    margin-top: setUnit(18);
    .title {
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 1.5rem;
    }

    .checkBoxes {
      margin: 2rem 0 0;

      .container {
        margin-bottom: 1.5rem;

        .bufferIcon {
          position: absolute;
          right: 0;
          top: 0;
          @media (max-width: $mobile) {
            position: relative;
            margin-top: setUnit(15);
          }
        }
      }
    }

    [class*='ant-input-number'] {
      border: 0;
      border-radius: 0;
      width: 125px;

      input {
        border-bottom: 2px solid $border-input-middle-gray;
      }

      [class*='ant-input-number-handler-wrap'] {
        display: none;
      }
    }

    [class*='ant-input-number-focused'] {
      box-shadow: none;
    }

    [class*='ant-input-number-disabled'] {
      background-color: unset;
      color: $text--light-gray-color;
      opacity: 0.6;

      input {
        border-bottom: 2px solid $border-input-middle-gray;
      }
    }

    .subTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $dark-color;
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}
