@import 'styles/functions';
@import 'styles/variables';

.status {
  display: flex;
  align-items: center;
  .icon {
    width: setUnit(32);
    height: setUnit(32);
  }
  .info {
    span {
      padding-left: setUnit(10);
      font-size: setUnit(16);
      font-weight: 600;
    }
  }
}

.newCount {
  font-weight: 600;
  color: $primary-color;
}
