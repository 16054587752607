@import "styles/functions";
@import "styles/variables";

.organization {
    padding-top: setUnit(60);

    .input {
        margin-bottom: setUnit(48) !important;
    }

    .locations {
        padding-left: 0 !important;

        &> div {
            font-size: setUnit(16) !important;
        }
    }
}
