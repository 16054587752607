@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../styles.module';

.formWrapper {
  justify-content: center;

  .formGroup {
    height: calc(100vh - $onboarding-header-height - $responsive-footer-height);
    .title {
      @include step-title();
      margin-bottom: setUnit(24);
      @media (min-width: $mobile) {
        margin-bottom: 32px;
      }
    }
  }
  .bottom {
    padding: 14px 16px;
  }
}
