@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.templateCard {
  display: flex;
  justify-content: space-between;

  padding-bottom: setUnit(20);
  border-bottom: 1px solid $background-ligh-gray-4;

  &:not(:last-child) {
    margin-bottom: setUnit(14);
  }

  .templateInfo {
    width: 100%;

    .templateInfoHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-weight: 600;
        letter-spacing: -0.18px;
        color: $text-color;
        margin-bottom: setUnit(6);
      }

      .dropdownTrigger {
        cursor: pointer;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: $background-ligh-gray;
        }
      }

      .moreOptions {
        @include box-shadow;
        background: $white;
        border-radius: setUnit(8);
        width: 11rem;

        [class*='ant-dropdown-menu'] {
          padding: 0;
          border-radius: setUnit(8);
        }

        .dropdownItem {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: setUnit(12) setUnit(16);

          .optionIcon {
            margin-right: setUnit(8);
            position: relative;
            line-height: 0px;

            svg {
              path,
              circle {
                stroke: $primary-color;
              }
            }
          }

          &:hover {
            background: $background-ligh-gray;
          }

          svg {
            vertical-align: middle;
          }
        }

        [class~='ant-dropdown-menu-item'] {
          &:first-child {
            .dropdownItem {
              &:hover {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }
            }
          }

          &:last-child {
            .dropdownItem {
              &:hover {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }

    .description {
      font-size: setUnit(14);
      line-height: setUnit(18);
      font-weight: 400;
      letter-spacing: -0.18px;
      color: $dark-grey-color;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0px;
    }

    .publishToTeamClause {
      color: $text-gray-color;

      font-size: setUnit(14);
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.18px;
      text-align: left;
      margin-bottom: 0px;
      margin-top: setUnit(6);
    }
  }
}
