@import 'styles/functions';
@import 'styles/variables';

.kitsUnsubscribed {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      margin-bottom: setUnit(16);
      max-width: setUnit(620);
    }

    .bold {
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-family: $font-family;
      letter-spacing: setUnit(-0.4);
      color: $text-color;
    }

    .text {
      font-weight: 500;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $dark-color;
    }

    .link {
      text-decoration: underline;
    }
  }

  .tiles {
    background-image: url('../../../../public/images/mosaik-tiles.png');
    background-repeat: repeat-x;
    height: setUnit(200);
    width: 100%;
  }
}
