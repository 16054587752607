@import 'styles/functions';
@import 'styles/variables';

.hintTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 12px;
  margin-bottom: 16px;
  .text {
    margin-left: setUnit(8);
    display: inline-block;
  }
}
.scrollable {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 3px;

  .preferences {
    display: table;

    .prefItems {
      display: table-row;
      align-items: center;
      padding-bottom: 12px;

      .item {
        font-size: setUnit(14);
        font-weight: 600;
        display: table-cell;
        white-space: nowrap;
        &:not(:first-child) {
          padding-left: 20px;
        }
        .innerItemHolder {
          display: flex;
          height: 32px;
        }
      }
    }
  }
}

.matchIcon {
  margin-right: 8px !important;
  &.matchFullIcon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
