@import 'styles/functions';
@import 'styles/variables';

.document {
  background-color: $background-gray;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0.5rem 0;

  .docIcon {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .info {
    display: inline-block;

    h4 {
      margin: 0;
      line-height: 1rem;
    }

    P {
      color: $dark-color;
      margin: 0;
      font-size: 13px;
    }
  }

  .dropdown {
    float: right;

    .moreIcon {
      margin-top: 0.25rem;
      cursor: pointer;
    }
  }
}

.preApprovalMenu .action {
  padding: 0.5rem 1.75rem !important;
}

@media screen and (max-width: 576px) {
  .document {
    padding: 1rem;

    .time {
      display: none;
    }
  }
}
