@import 'styles/functions';
@import 'styles/variables';

.coBuyer {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  .pageTitle {
    font-size: setUnit(24);
    line-height: setUnit(29);
    font-weight: 600;
    letter-spacing: -0.47px;
    border-bottom: 2px solid $light-gray;
    padding-bottom: setUnit(33);
    margin-top: 0;
  }
}
