@import 'styles/functions';
@import 'styles/variables';

.editForm {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: setUnit(24);
  .editIcon {
    cursor: pointer;
    line-height: 0px;
  }
}
