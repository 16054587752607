@import 'styles/functions';
@import 'styles/variables';

.allSubFeedScroll {
  height: calc(100vh - #{setUnit(175)});
}

.pendingWrapper {
  height: 100%;
  position: relative;
  .field input,
  .locationsValues {
    height: setUnit(48);
  }

  .field {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.pseudo {
  position: relative;

  .pseudoPlaceholder {
    position: absolute;
    top: calc(50% + 5px);
    right: setUnit(16);
    font-weight: 400;
    color: $border-middle-gray;
    margin-bottom: 0;
  }

  .hoa {
    input {
      padding-right: setUnit(100);
    }
  }
}

.buttonGroup {
  margin: 0 !important;
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.resetBlock {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100px;
  .resetIcon {
    margin-right: 8px;
    display: flex;
  }
  .resetSpan {
    display: flex;
    align-items: center;
    &:hover {
      color: $hover-blue;
    }
  }
  .resetAllText {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $dark-color;
  }
}

.filterPropertyWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .filterItem {
    flex: 0 0 50%;
    min-height: setUnit(114);
    &:first-child {
      border-top-left-radius: setUnit(8);
    }
    &:nth-child(2) {
      border-top-right-radius: setUnit(8);
    }
    &:last-child {
      border-bottom-right-radius: setUnit(8);
      border-bottom-left-radius: setUnit(8);
      flex: 0 0 100%;
    }
    @media (min-width: $mobile) {
      flex: 0 0 33.3333%;
      &:nth-child(2) {
        border-top-right-radius: 0;
      }
      &:nth-child(3) {
        border-top-right-radius: setUnit(8);
      }
      &:nth-last-child(3) {
        border-bottom-left-radius: setUnit(8);
      }
      &:last-child {
        border-bottom-right-radius: setUnit(8);
        border-bottom-left-radius: 0;
        flex: 0 0 33.333%;
      }
    }
    & > div {
      min-height: setUnit(80);
    }
  }
}

.radioGroupCustom {
  width: 100%;
  margin-bottom: 32px !important;
  display: flex !important;
  flex-wrap: wrap;
  [class*='ant-radio-button-wrapper-checked'] {
    color: #262626 !important;
    border: 1px solid $primary-color !important;
    background: #fff4f3 !important;

    &:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $primary-color;
    }
    &:not(.ant-radio-button-wrapper-disabled):is(:active, :hover)::before {
      background-color: $primary-color;
    }
    &:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none !important;
    }
  }
  label[class~='ant-radio-button-wrapper'] {
    flex: 1;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    span:last-child {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09px;
    }
    &:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
    &:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
    &:hover {
      color: $primary-color;
    }
  }
}
