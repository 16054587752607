@import 'styles/variables';

.detailsContainer {
  .detailsHeader {
    font-weight: 700;
    font-size: 32px;
    margin-left: 40px;
  }

  .noDetails {
    height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: $text--light-gray-color;
  }

  .detailsContent {
    margin-top: 20px;

    .contentHeader {
      margin-left: 40px;
      font-size: 20px;
      font-weight: 600;
    }

    .contentWrapper {
      height: calc(100vh - $header-height - 154px);
      overflow: auto;
      margin-top: 12px;

      .contentSpacing {
        margin: 0px 30px;
      }
    }
  }
}

.disabledSection {
  opacity: 0.5;
  pointer-events: none;
}
