@import 'styles/functions';
@import 'styles/variables';

.InfoProperties {
  .container {
    height: 30vh;
    @media screen and (max-height: 830px) {
      height: 20vh;
    }
    @media screen and (max-height: 760px) {
      height: 15vh;
    }
    @media screen and (max-height: 580px) {
      height: 10vh;
    }
    max-height: fit-content;
    overflow-y: scroll;
  }
  .propertyCard {
    margin: 0 !important;
    border: none !important;
    height: fit-content !important;
    min-height: auto !important;
    min-width: 300px;
  }
  .photo {
    @media screen and (max-height: 700px) {
      height: 100px !important;
    }
  }
  .footer {
    border-top: 1px solid $light-gray;
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    .arrow {
      all: unset;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      cursor: pointer;
      transition: $transition;
      &:disabled {
        &:hover {
          background: none !important;
          svg {
            path {
              stroke: $text--light-gray-color !important;
            }
          }
        }
        svg {
          path {
            opacity: 0.5 !important;
          }
        }
      }
      &:hover {
        background: $background-grey-2;
        svg {
          path {
            stroke: $dark-color;
          }
        }
      }
    }
    .pagination {
      color: $text-gray-color;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09px;
      margin: 0;
    }
  }
}
