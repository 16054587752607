@import 'styles/functions';
@import 'styles/variables';

.labelSplit {
  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.applyLabel {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: $text-color;
  font-size: 0.875rem;
}

.inputHeight {
  height: 48px !important;
}
