@import 'styles/functions';
@import 'styles/variables';

.wrapper {
    font-size: setUnit(14);
    line-height: setUnit(16);
    color: $text-gray-color;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(transparent 0, 3%, #fff 35% 100%);
    padding-bottom: setUnit(30);
    padding-top: setUnit(24);
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    transition: all .1s;

    &:hover {
        opacity: 0.9;
    }

    .icon {
        height: setUnit(20);
        padding-right: setUnit(8);

        svg {
            width: setUnit(20);
            height: setUnit(20);
        }
    }
}
