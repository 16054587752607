@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.uploadPartnersContainer {
  display: flex;

  .uploadButton {
    @include button($secondary-color, $white, $hover-blue);
    border-radius: setUnit(100);
    margin-left: setUnit(16);
    width: fit-content;
    height: setUnit(40);
    color: $white;
    display: flex;
    align-items: center;
    font-size: setUnit(16);
    line-height: setUnit(21);
    font-weight: normal;
    text-decoration: none;

    & svg {
      width: setUnit(24);
      margin-right: setUnit(8);
      vertical-align: middle;

      path {
        stroke: $white;
        stroke-width: 2px;
      }
    }
  }

  .downloadButton {
    @include button($secondary-color, $white, $hover-blue);
    border-radius: setUnit(100);
    width: setUnit(40);
    height: setUnit(40);
    color: $white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;

    & svg {
      display: flex;
      width: setUnit(24);
      vertical-align: middle;

      path {
        stroke: $white;
        stroke-width: 2px;
      }
    }
  }
  input[type='file'] {
    display: none;
  }
}
