@import 'styles/variables';

.smartQuestionListContainer {
  margin-left: -48px !important;
  margin-top: -14px !important;
}

.questionListContainer {
  width: 606px;
  .questionListColumn {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 20px;
    .questionName {
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      margin-left: 26px;
    }
  }
  .questionLogo {
    display: flex;
  }
  .questionListColumn:hover {
    background: $background-ligh-gray;
  }
  .questionDisabled {
    opacity: 0.5;
    background: $white;
    cursor: not-allowed;
    &:hover {
      background: $white;
    }
  }
}

.cascaderQuestionList {
  [class*='ant-select-selector'] {
    background: transparent !important;
    border: 0px !important;
    width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
  }

  [class*='ant-popover-placement-bottomLeft'] {
    width: 0px !important;
  }

  [class*='ant-cascader-menu'] {
    ul {
      height: 200px !important;
    }
    li {
      width: 200px;
      height: 50px;
    }
  }
  [class*='ant-select-arrow'] {
    display: none;
  }
}

.questionItem {
  display: flex;
  align-items: center;
}

.questionItemIcon {
  margin-right: 10px;
  height: 28px;
}

.pdfIcon {
  margin-right: 10px;
  width: 28px !important;
  height: 28px !important;
}
