@import 'styles/variables';
@import 'styles/functions';
@import '../../mixins.module.scss';

.confirmationWithReasonModal {
  max-width: 420px !important;
  width: 100% !important;
  & [class*='ant-modal-close-x'] {
    display: none;
  }

  & [class*='ant-btn-primary'] {
    @include modalButton($primary-color, $primary-color-hover, $white, 600);
    text-transform: capitalize;
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }

  & [class*='ant-typography'] {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $text-color;
    margin-bottom: 12px;
  }

  .label {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $text-color;
    span {
      margin-left: 4px;
      font-weight: 400;
      color: $text-gray-color;
    }
  }

  .reasonInput {
    border-width: 1px !important;
  }
}
.confirmationWithReasonModalForAccept {
  max-width: 420px !important;
  width: 100% !important;
  & [class*='ant-modal-close-x'] {
    display: none;
  }

  & [class*='ant-btn-primary'] {
    @include modalButton($secondary-color, $secondary-color-hover, $white, 600);
    text-transform: capitalize;
  }

  & [class*='ant-btn-primary']:disabled {
    opacity: 0.3;
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }

  & [class*='ant-typography'] {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $text-color;
    margin-bottom: 12px;
  }

  .label {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $text-color;
    span {
      margin-left: 4px;
      font-weight: 400;
      color: $text-gray-color;
    }
  }

  .reasonInput {
    border-width: 1px !important;
  }
}

.icon {
  text-align: center;
  margin-bottom: 25px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: $text-color;
  margin-bottom: 12px;
}
