@import 'styles/functions';
@import 'styles/variables';

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .editIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: setUnit(16);
    display: none;
  }

  &:hover {
    .editIcon {
      display: block;
    }
  }
}
