@import 'styles/variables';
@import 'styles/functions';

.insight {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  color: $dark-grey-color;
}
