@import 'styles/functions';
@import 'styles/variables';

.template {
  padding: 0 setUnit(40);
  overflow: hidden;
  cursor: pointer;
  transition: $transition;
  .templateInner {
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 94px;
  }
  .names {
    margin-left: setUnit(16);
    .name {
      font-weight: 600;
    }

    .description {
      font-size: setUnit(14);
      color: $dark-grey-color;
    }

    .description,
    .propertyType,
    .financing {
      max-width: setUnit(520);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;

    .icon {
      width: setUnit(56) !important;
      height: setUnit(56) !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light-gray;
    }
  }

  .actions {
    width: setUnit(171);
    display: none;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    .btn {
      height: setUnit(40);
      width: setUnit(74);
      border-radius: setUnit(100);
      font-size: setUnit(14);
      font-weight: 500;
      transition: $transition;
      &.preview {
        width: setUnit(85);
        background: #ffffff;
        color: $text-color;
        border: 1px solid $border-light-gray-2;
        margin-right: setUnit(12);
        &:hover {
          background: $border-light-gray-2;
        }
      }
    }
  }

  &:hover {
    background: $background-ligh-gray;
    .description,
    .propertyType,
    .financing {
      max-width: setUnit(336);
    }
    .actions {
      display: flex;
      opacity: 1;
      visibility: visible;
    }
  }
}
