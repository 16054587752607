@import 'styles/functions';
@import 'styles/variables';

.preApprovalRequests {
  margin-top: 1rem;

  .subTitle {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $black;
    margin: 0.5rem 0 1rem;
  }
}
