@import 'styles/functions';
@import 'styles/variables';

.content {
  padding: 20px 35px;
  max-height: calc(100vh - 435px);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 700px) {
    padding: 20px 15px !important;
  }

  .hoaContainer {
    display: flex;
    gap: 20px;
    align-items: center;
    .checkboxContainer {
      min-width: 100px;
      margin-bottom: 5px;
      &.padding {
        padding-top: 27px;
      }
      .label {
        color: $dark-color;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.09px;
      }
    }
  }

  .selectWrapper {
    width: 100%;
  }

  @media screen and (max-height: 1000px) {
    max-height: calc(100vh - 210px);
  }

  .divider {
    width: 100%;
    height: 1px;
    background: $light-gray;
    margin: 32px 0;
  }
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  border-top: 1px solid $silver-badge;
  @media screen and (max-width: 700px) {
    padding: 20px 15px !important;
  }
  .reset {
    cursor: pointer;
    color: $dark-color;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.18px;
    padding-left: 10px;
    margin: 0;
  }
  .submitButton {
    width: fit-content;
    height: 52px;
    padding: 5px 20px;
    white-space: nowrap;
  }
}

.savedSearchSelect {
  margin-bottom: 20px;
  border-radius: 8px;
  input {
    padding-left: 55px !important;
    color: $text-color !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

.attributes {
  margin-top: 30px;
}

.border {
  border-radius: 8px !important;
  .placeholder {
    border-radius: 8px !important;
  }
  input {
    border-radius: 8px !important;
  }
}

.borderDraw {
  border-radius: 8px 0 0 8px !important;
  .placeholder {
    border-radius: 8px 0 0 8px !important;
  }
  input {
    border-radius: 8px 0 0 8px !important;
  }
}

.wrapper {
  height: fit-content;
  max-height: calc(100vh - 510px);
  margin: 20px 0;
  padding: 0 10px;
}

.pseudo {
  position: relative;

  .pseudoPlaceholder {
    position: absolute;
    top: setUnit(43);
    right: setUnit(16);
    font-weight: 400;
    color: $border-middle-gray;
  }

  .hoa {
    input {
      padding-right: setUnit(100);
    }
  }
}

.buttonGroup {
  margin: 0 !important;
}

.parkingSpaces {
  margin-bottom: 10px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.propertyWrapper {
  margin: 0;
  label {
    margin-bottom: setUnit(12) !important;
  }
  .filterPropertyWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filterItem {
      flex: 0 0 50%;
      &:first-child {
        border-top-left-radius: setUnit(8);
      }
      &:last-child {
        border-bottom-right-radius: setUnit(8);
      }
      &:nth-child(2) {
        border-top-right-radius: setUnit(8);
      }
      &:nth-child(9) {
        border-bottom-right-radius: setUnit(8);
        border-bottom-left-radius: setUnit(8);
        flex: 0 0 100%;
      }
      @media (min-width: $mobile) {
        flex: 0 0 33.3333%;
        &:nth-child(2) {
          border-top-right-radius: setUnit(0);
        }
        &:nth-child(3) {
          border-top-right-radius: setUnit(8);
        }
        &:nth-child(4) {
          border-bottom-left-radius: setUnit(0);
        }
        &:nth-child(7) {
          border-bottom-left-radius: setUnit(8);
        }
        &:nth-child(9) {
          border-bottom-left-radius: setUnit(0);
          flex: 0 0 33.333%;
        }
      }
      & > div {
        min-height: setUnit(80);
      }
    }
  }
}

.radioGroupCustom {
  width: 100%;
  margin-bottom: 32px !important;
  display: flex !important;
  flex-wrap: wrap;
  [class*='ant-radio-button-wrapper-checked'] {
    color: #262626 !important;
    border: 1px solid $primary-color !important;
    background: #fff4f3 !important;

    &:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $primary-color;
    }
    &:not(.ant-radio-button-wrapper-disabled):is(:active, :hover)::before {
      background-color: $primary-color;
    }
    &:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none !important;
    }
  }
  label[class~='ant-radio-button-wrapper'] {
    flex: 1;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    span:last-child {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09px;
    }
    &:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
    &:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
    &:hover {
      color: $primary-color;
    }
  }
}
