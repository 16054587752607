@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.formContainer {
  max-width: 693px;
  flex-shrink: 0;
  border-radius: 24px;
  backdrop-filter: blur(75px);
  padding: 20px;

  .heading {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'clig' off, 'liga' off;
    // font-family: Sora;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: -0.8px;
    text-align: center;
  }

  .subHeading {
    margin-bottom: 8px;
    color: var(--Grayscale-60, #676767);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
  }
  .inputWrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > [class*='StripeElement--focus'] {
      border-color: #000 !important;
    }
  }

  .cardElement {
    background: transparent;
    padding: 16px 14px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
    border: 1px solid var(--Grayscale-20, #e8e9ea);
    border-radius: 4px;
    &:visited {
      border-color: #000 !important;
    }
    &:focus {
      border-color: #000 !important;
    }
    &:placeholder {
      color: var(--Grayscale-40, #aaabab);
    }
    [class='StripeElement--focus'] {
      border-color: #000 !important;
    }
  }

  .formLabel {
    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }
  .payBtn {
    width: 136px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Primary-Navy, #252d44);
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    gap: 17px;
  }
  .promoInput {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > [class*='StripeElement--focus'] {
      border-color: #000 !important;
    }
  }
  .promoWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    .addPromo {
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 12px;
      color: var(--Grayscale-80, #515151);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.18px;
    }
  }
  .stripeSecure {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    opacity: 0.5;
    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: -0.1px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .stripeIcon {
    margin-top: 10px;
  }
}

.divider {
  margin-bottom: 16px;
  margin-top: 32px;
}

.priceBlur {
  display: inline-flex;
  width: 40px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 3px;
  opacity: 0.2;
  background: var(
    --Blur-BG,
    linear-gradient(180deg, #fff 0%, rgba(249, 250, 250, 0.77) 68.5%, rgba(244, 245, 246, 0.4) 100%)
  );
}

.price {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.loader {
  color: antiquewhite;
  fill: antiquewhite;
}

.spinner {
  font-size: 20px;
  color: antiquewhite;
}
