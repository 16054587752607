@import 'styles/functions';
@import 'styles/variables';

.clientActivityLogSection {
  .clientActivityLogWrap {
    .noActivityFound {
      height: 150px;
      margin: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: $text--light-gray-color;
    }
    .clientActivityCard {
      &:first-child {
      }
      div[class~='ant-card-head'] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .btn {
    height: setUnit(40);
    width: setUnit(40);
    padding: setUnit(8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4px;
    z-index: 1;
    font-weight: 600;
    border: 2px solid $tertiary-grey;
    background-color: #fff;
    transition: $transition;
    color: $dark-color;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border-color: $text--light-gray-color;
    }
    &[class~='ant-popover-open'] {
      border-color: $text-gray-color;
      background-color: $background-grey-2;
    }
    .filterIcon {
      margin-top: 0.25rem;
    }
    .icon {
      transition: transform 0.15s;
      &.asc {
        transform: rotate(180deg);
      }

      &.desc {
        transform: rotate(0deg);
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}
