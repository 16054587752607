@import 'styles/variables';

.menuListItem {
  height: 44px;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $background-grey-2;
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .menuListIcon {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  svg {
    height: 20px;
    width: 20px;

    path {
      stroke: $primary-color;
      stroke-width: 2;
    }
  }
}
