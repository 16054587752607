@import 'styles/variables';
@import 'styles/functions';

.footerActions {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  height: 80px;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: -100px;
  display: flex;
  align-items: center;
  transition: $transition;
  z-index: 9;
  &.active {
    bottom: 0;
  }
  .footerInner {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .faLeft {
      .button {
        border-radius: 8px;
        width: 132px !important;
        height: 52px !important;
      }
    }
    .faRight {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .button {
        border-radius: 8px;
        width: 132px !important;
        height: 52px !important;
        &:not(:last-child) {
          margin-right: 12px;
        }
        .icon {
          display: flex;
          margin-right: 6px;
        }
      }
    }
  }
}
