@import 'styles/functions';
@import 'styles/variables';

.emailItem {
  .email {
    text-decoration: none;
    color: $link-blue !important;
    font-weight: 400;
  }

  .defaultCursor {
    cursor: default;
  }

  .emailContainer {
    display: flex;

    .editIcon {
      display: none;
      cursor: pointer;
      position: relative;
      top: 0.1rem;
      right: 0.5rem;
    }
  }

  &:hover {
    .emailContainer .editIcon {
      display: block;
    }
  }

  .editEmail {
    margin: -0.8rem 0 0;
    height: 1.5rem;

    .inputHolder {
      display: flex;

      & [class*='icon'] {
        top: initial !important;
        right: -26px !important;
      }
    }
  }

  .close {
    margin-top: 0.75rem;
  }
}

.expanded {
  padding: setUnit(14) 0;
}
