@import 'styles/functions';
@import 'styles/variables';

.headerArea {
  margin-bottom: setUnit(56);
  text-align: center;
  padding: 38px 0 24px;
  background-color: $white;

  .headTitle {
    font-weight: 700;
    font-size: setUnit(32);
    line-height: setUnit(36);
    letter-spacing: -0.4px;
    color: $text-color;
    margin: 0;
  }
}

.infoIcon {
  cursor: pointer;
  display: none;
}

@media (max-width: $mobile) {
  .headerArea {
    position: relative;

    padding-top: 30px;

    .headTitle {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
    .infoIcon {
      display: block;
      position: absolute;
      bottom: 40px;
      right: 20px;
    }
  }
}
