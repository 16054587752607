@import 'styles/variables';
@import 'styles/mixins';

.textFitInputField {
  border: 0 !important;
  background-color: unset !important;
  width: 100% !important;
  border-radius: 0px;
  padding-left: 5px;

  input {
    width: 100% !important;
    border: 0 !important;
    background-color: transparent !important;
    height: 100%;
    display: flex;

    &:focus-visible {
      outline: 0;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: transparent !important;
  }
}

.addressTextFitField {
  height: fit-content !important;
  line-height: 0px !important;

  input {
    margin-top: 6px;
    height: auto;
  }
}

.smallAddressTextFitField {
  input {
    margin-top: 3px;
  }
}
