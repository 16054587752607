@import "styles/functions";
@import "styles/variables";

.breadcrumbs {
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
    }

    .link {
        text-decoration: none;
        font-size: setUnit(14);
        font-weight: 400;
        color: $dark-grey-color;
        padding: setUnit(8);
        border-radius: setUnit(16);

        &:hover {
            background: $background-ligh-gray;
        }

        &.active {
            font-weight: 600;
            color: $text-color;
        }
    }

    .icon {
        svg {
            vertical-align: middle;
            width: setUnit(14);
        }
    }
}
