@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../../styles.module';

.stageWrap {
  flex-basis: 100%;
  padding: 0 40px;
  height: calc(100vh - $header-height);
  .celebrate {
    height: 5rem;
    font-size: 64px;
    text-align: center;
    margin-bottom: setUnit(40);
  }
  .button {
    width: 100%;
    height: 52px !important;
    border-radius: 8px !important;
    transition: $transition;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;
    }
    .submit {
      .title {
        color: $white;
      }
    }
    .skip {
      border: 0 !important;
      .title {
        color: $dark-color;
      }
      &:hover {
        border-color: $text--light-gray-color;
        .title {
          color: $text-color;
        }
      }
    }
  }
}
