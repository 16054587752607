@import 'styles/functions';
@import 'styles/variables';

.shareDrawer {
  .drawerInner {
    .photoSection {
      padding: 0 setUnit(32) setUnit(32);
    }
    .inputSection {
      padding: 0 setUnit(32) setUnit(30);
      position: relative;
      .to {
        position: absolute;
        left: 41px;
        top: 8px;
        z-index: 10;
        font-size: 15px;
        color: #747475;
      }
      .valuesContainer {
        height: 40px;
        width: 100%;
        padding: 0 setUnit(32) setUnit(30);
        background-color: $background-grey-2;
        border-radius: 8px;
        display: flex;
        gap: 5px;
        padding-left: 40px;
        .value {
          height: 40px;
          cursor: pointer;
          width: fit-content;
          position: relative;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0.375rem 1.875rem 0.375rem 0.75rem;
          border: 1px solid $primary-color;
          border-radius: setUnit(30);
          background: $primary-color-light;
          font-size: 16px;
          font-weight: 400;
          .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.5rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
              vertical-align: middle;
              width: setUnit(20);
              height: setUnit(20);

              path {
                fill: $dark-color;
              }
            }
          }
        }
      }
      .searchInput {
        input {
          height: 40px;
          border: none !important;
          background-color: $background-grey-2;
          padding-left: 40px !important;
        }
      }
      & > div {
        margin: 0 !important;
      }
    }
    .scrollWrapper {
      height: calc(100vh - #{setUnit(342)});
    }

    .pendingWrapper {
      height: calc(100% - #{setUnit(80)});
    }

    position: relative;
  }
}

.footer {
  padding: setUnit(16) setUnit(32);
  background: $background-ligh-gray;
  border: 1px solid $grey-color;
  display: flex;
  align-items: center;

  .clientsNames {
    padding-right: setUnit(32);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .submitButton {
    width: setUnit(102);
    min-width: setUnit(102);
    margin-left: auto;
    display: block;
  }
}
