@import 'styles/functions';
@import 'styles/variables';

.areaOperations {
  font-size: setUnit(20);
  font-weight: 600;
  margin-top: setUnit(40);
}

.linksTitle {
  font-weight: 600;
  margin-top: setUnit(32);
}

.areaOfOperations {
  margin-top: setUnit(24);
  text-align: left;
}

.valuesWrapper,
.activeInput input {
  padding-left: 0 !important;
}
