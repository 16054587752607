@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parties {
  .contentWrapper {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    margin-top: setUnit(18);
    .title {
      font-weight: 600;
      line-height: 20px;
      font-size: setUnit(16);
      letter-spacing: -0.18px;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-color;

      .notifyIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
    }

    .checkBoxes {
      margin-top: setUnit(32);
      padding: setUnit(24);
      border: 1px solid $light-gray;
      border-radius: 8px;

      .checkboxSquare {
        [class*='ant-checkbox'] {
          [class*='ant-checkbox-inner'] {
            border-radius: setUnit(4);
          }
          [class~='ant-checkbox-input']:focus + [class~='ant-checkbox-inner'] {
            border-color: $primary-color;
          }
        }
      }
    }
  }
  .checkboxArea {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .infoIcon {
      margin-left: setUnit(8);
      svg {
        path {
          stroke: $text--light-gray-color;
        }
      }
    }
  }
  .selectorContainer {
    margin-top: 0.5rem;
    margin-left: setUnit(36);
    .selector {
      margin-bottom: 0.5rem;
      width: 100%;
      div[class~='ant-select-selector'] {
        border: unset;
        border-bottom: 2px solid $border-input-middle-gray;
        box-shadow: none !important;
        span[class~='ant-select-selection-item'] {
          border: 1px solid $border-light-gray-2;
          border-radius: setUnit(100);
          height: setUnit(32);
          padding: setUnit(6) setUnit(12);
          margin: 0 setUnit(8) setUnit(6) 0;
          span[class~='ant-select-selection-item-content'] {
            color: $text-color;
            font-size: setUnit(16);
            font-weight: 400;
            line-height: setUnit(16);
            letter-spacing: setUnit(-0.18);
          }
          span[class~='ant-select-selection-item-remove'] {
            color: $text--light-gray-color;
            font-size: setUnit(16);
            font-weight: 400;
            line-height: setUnit(16);
            letter-spacing: setUnit(-0.18);
          }
        }
      }
      &[class~='ant-select-focused'] {
        div[class~='ant-select-selector'] {
          border-color: $border-focused;
        }
      }
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}

.selectPopup {
  [class~='ant-select-item-option-selected']:not(ant-select-item-option-disabled) {
    background-color: $primary-color-light;

    [class~='ant-select-item-option-state'] {
      color: $primary-color;
    }
  }
}
