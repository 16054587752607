@import '../../../../../../../../../../../styles/functions';
@import '../../../../../../../../../../../styles/variables';

.loading {
  color: $dark-grey-color;
}

.itemContainer {
  height: setUnit(80);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  cursor: pointer;
  border-radius: 0.5rem;

  .inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    .nameHolder {
      margin-left: setUnit(16);

      .name {
        font-weight: 600;
        font-size: 16px;
        width: fit-content;
        margin-bottom: 0px !important;
      }

      .favorites {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0px !important;
        color: $dark-grey-color;
      }
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      vertical-align: middle;
      background: $background-ligh-gray;
      object-fit: cover;

      .avatarInner {
        width: 48px;
        height: 48px;
      }
    }
  }

  .iconArrow {
    height: 24px;

    svg {
      path {
        stroke: $dark-color;
      }
    }
  }

  &:hover {
    background: #f6f7f7;
  }
}
