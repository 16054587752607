@import 'styles/functions';
@import 'styles/variables';

.card {
  height: 270px;
  display: flex;
  flex-direction: column;
  padding: setUnit(25) setUnit(25) setUnit(0) setUnit(25);
  border: 1.5px solid $light-gray;
  border-radius: setUnit(12);

  .data {
    display: flex;
    flex-direction: column;
    margin-bottom: setUnit(25);
  }

  .label {
    color: $text-gray-color;
    margin-bottom: setUnit(6);
    font-size: setUnit(16);
    font-weight: 500;
    line-height: setUnit(20);
    letter-spacing: -0.18px;
  }

  .value {
    font-size: setUnit(18);
    font-weight: 600;
    line-height: setUnit(20);
    letter-spacing: -0.18px;
  }
}
