@import 'styles/variables';
@import 'styles/functions';

.actionBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: setUnit(24);
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 12px;
    border-radius: 8px;
    border: 2px solid $light-gray;
    transition: $transition;
    &:hover {
      border-color: $text--light-gray-color;
    }
    &:is(:active, :focus) {
      border-color: $primary-color;
      color: $primary-color;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .actionIcon {
      display: inline-flex;
      align-items: center;
      gap: 0 8px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $text-color;
    }
  }
}
