@import 'styles/variables';

.wrapper {
  position: relative;
  .buttonContainer {
    position: absolute;
    bottom: 2rem;
    left: calc(50vw - 90px);
    z-index: 1;

    .hidden {
      display: none;
    }

    .button {
      background: linear-gradient(160.52deg, #fb913a -35.45%, #ff576d 82.33%);
      border-radius: 24px !important;
      height: auto;
      padding: 22px 16px 0 16px;
      &:hover {
        border-color: transparent;
        background: linear-gradient(160.52deg, #fb913a -35.45%, #ff576d 82.33%),
          linear-gradient(0deg, rgba(236, 69, 94, 0.5), rgba(236, 69, 94, 0.5));
      }
      .badge {
        background-color: transparent;
        .homeIcon {
          color: white !important;
          font-size: 24px;
        }

        sup {
          background: white;
          font-weight: bold;
          color: black;
        }
      }
      p {
        font-size: setUnit(14);
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.09000000357627869px;
        text-align: left;
        color: white;
      }
    }
  }
}

.propertiesActions {
  width: 290px;
  .actionsContainer {
    width: min(100%, 226px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    background-color: $white;
    padding: 0;
    cursor: auto;

    .list {
      padding: 20px;
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 14px 16px;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &:hover {
        background-color: $background-ligh-gray;
      }
      p {
        color: #262626;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.09px;
        margin: 0;
      }
    }
    .disabled {
      cursor: not-allowed;
    }
  }
}

.modal {
  display: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: -80px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  overflow: hidden;
  .container {
    width: min(100%, 675px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 24px;
    padding: 20px 40px;
    min-height: 300px;
    background-color: $white;
    cursor: auto;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      letter-spacing: -0.18px;
      position: relative;
      .title {
        color: #262626;
        font-size: 24px;
        margin: 0;
        text-align: center;
        flex: 1;
      }
      .closeWrapper {
        position: absolute;
        top: 0;
        right: 0px;
        z-index: 1000;
      }
      .clear {
        color: #2b59da;
        text-align: right;
        font-size: 14px;
        margin: 0;
        cursor: pointer;
      }
      .close {
        cursor: pointer;
      }
    }
    .deleteIcon {
      background: transparent;
      padding: 0;
      width: 32px;
      &:hover {
        background: transparent;
      }
    }
    .itemsWrapper {
      flex: 1;
      overflow-y: auto;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
          }
          .infoContainer {
            .line1 {
              color: #262626;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: -0.18px;
              margin: 0;
            }
            .line2 {
              color: #515151;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.09px;
              margin: 0;
            }
          }
        }
        .close {
          cursor: pointer;
          position: relative;
          bottom: 5px;
        }
      }
    }
    .arrow {
      position: relative;
      top: 2px;
    }
    .fullWidth {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: min(100%, 226px);
      margin: auto;
      align-self: flex-end;
    }
    .button {
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.18px;
    }
  }
}

.visible {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
}
