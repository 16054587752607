@import 'styles/functions';
@import 'styles/variables';

.headerTitle {
  display: flex;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.kitsPreviewModal {
  overflow-y: auto;

  .content {
    border-style: none;
  }
}
