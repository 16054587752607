@import 'styles/functions';
@import 'styles/variables';

.compareOfferTooltip {
  div[class~='ant-tooltip-content'] {
    width: auto !important;
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 50vw;

      p {
        color: white !important;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.descInfoWrapper {
  display: flex;
  gap: 8px;
  .descInfo {
    color: $dark-grey-color;
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(16);
  }
  .descInfoWrapped {
    max-width: 25vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .emptyDescInfo {
    height: 14px;
  }
}

.containerActiveSearch {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e8e9ea;
  cursor: pointer;
  &.hideBorder {
    border-bottom: 1px solid transparent !important;
  }
  .searchName {
    font-weight: 500;
    font-size: 16px;
    margin-left: 16px;
    .inactive {
      font-size: 12px;
      margin-left: 12px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
  .footer {
    color: $text--light-gray-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }
}
.clickable {
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    opacity: 0.95;
  }
}

.images {
  width: 72px;
  height: 72px;
  border-radius: 10px;
  background-color: $hover-light-gray;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .noImages {
    display: flex;
    height: 100%;
    svg {
      width: 24px;
      height: 24px;
      margin: auto;
      path {
        stroke: $dark-color;
      }
    }
  }

  img {
    width: 50%;
    height: 50%;
    border: 1px solid transparent;
    object-fit: cover;
  }
}

.infoBlock {
  padding: setUnit(16) setUnit(24) setUnit(12);
  background-color: $background-blue;
  color: $light-grey-color;
}

.searchTitle {
  color: $text-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.8px; /* 130% */
  letter-spacing: -0.18px;
  margin-bottom: 8px;
}

.descInfo {
  color: $dark-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;
  margin-bottom: 8px;
}

.emptyDescInfo {
  height: 14px;
}

.descTitle {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.1rem;
  color: $white;
  display: inline-block;
}

.descStatus {
  float: right;
  color: $white;
  font-size: 14px;
  background-color: #468bff33;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
}

.descAgentName {
  font-size: 14px;
  padding-bottom: 0.5rem;
  color: $white;
}

.descFooter {
  margin-top: setUnit(18);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
}
.menuActions {
  display: block;
  margin-left: auto;
}
