@import 'styles/functions';
@import 'styles/variables';

.header {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(21);
  color: $text-color;
  margin-bottom: setUnit(24);

  .headerTitle {
    margin-right: setUnit(16);
  }

  .changeModeBtn {
    margin-left: auto;
    border: 0;
    outline: none;
    transition: all 0.1s;
    background-color: $hover-light-gray;
    border-radius: setUnit(8);
    width: setUnit(40);
    height: setUnit(40);
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    .changeModeBtnIcon {
      height: setUnit(24);

      svg {
        width: setUnit(24);
        height: setUnit(24);
      }
    }
  }
}

.listMode {
  .noData {
    font-size: setUnit(14);
    line-height: setUnit(16);
    color: $dark-grey-color;
  }

  div:not(:last-child) {
    margin-bottom: setUnit(16);
  }

  .listModeItem {
    display: flex;

    &Content {
      transition: all 0.2s;
      padding-left: setUnit(8);
      padding-right: setUnit(8);
      flex-grow: 1;

      div:not(:last-child) {
        margin-bottom: setUnit(6);
      }

      &Top {
        display: flex;
        justify-content: space-between;
        align-self: center;

        p {
          margin: 0;
        }

        .category {
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $text-color;
        }

        .distance {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $dark-grey-color;
        }
      }

      &Middle {
        display: flex;
        align-self: center;

        p {
          margin: 0;
        }

        .rating {
          font-weight: 500;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $text-color;
          margin-right: setUnit(6);
        }

        .count {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $dark-grey-color;
          margin-left: setUnit(6);
        }
      }

      &Last {
        p {
          margin: 0;
          margin-right: setUnit(6);
        }

        display: flex;
        align-self: center;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $dark-grey-color;
      }
    }
  }
}

.mapWrapper {
  border-radius: setUnit(8);
  overflow: hidden;
  position: relative;
  height: setUnit(250);
  @media (min-width: $mobile) {
    height: setUnit(380);
  }
  .map {
    width: 100%;
  }
}

.yelpLogo {
  width: setUnit(50);
  height: setUnit(25);
  margin-left: auto;

  svg {
    width: setUnit(50);
    height: setUnit(25);
  }
}

.popupClassName {
  width: setUnit(176) !important;
}

.propertyPreview {
  .info {
    margin-top: setUnit(8);
  }
}
