@import "styles/functions";
@import "styles/variables";

.text {

    .inputField {
        position: relative;
        top: setUnit(-9);

        .input {
            font-size: setUnit(18);
        }
    }
}
