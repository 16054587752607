@import 'styles/functions';
@import 'styles/variables';

.partner {
  background: $white;
  box-shadow: 0 setUnit(2) setUnit(16) rgba(48, 48, 48, 0.1);
  border-radius: setUnit(16);
  padding: 32px 10px;
  display: flex;
  flex-direction: column;
  color: $text-color;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $mobile) {
    flex-direction: row;
    padding: 32px 24px;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &.checked {
    border: 1px solid $primary-color;
  }

  .partnerDetails {
    display: flex;
    column-gap: 16px;
    align-items: center;
    cursor: pointer;
  }

  .avatar {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: setUnit(15);
    @media (min-width: $mobile) {
      margin-bottom: 0;
    }
    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }

  .partnerInfo {
    text-align: center;
    margin-bottom: setUnit(15);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    @media (min-width: $mobile) {
      text-align: left;
      margin-bottom: 0;
    }
    .partnerInfoTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.09px;
      font-feature-settings: 'clig' off, 'liga' off;
      color: $text-color;

      @media (max-width: $mobile) {
        text-align: left;
      }
    }

    .partnerInfoDesc {
      color: $dark-color;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.09px;
      max-width: 449px;
      display: flex;
      column-gap: 4px;
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: flex-start;

      .title {
        display: flex;
        align-items: flex-start;
        column-gap: 4px;
      }

      .itemsWrapper {
        display: flex;
        column-gap: 8px;
        flex-wrap: wrap;
      }

      .servicesOffered {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 225px;
        display: inline-block;
      }
    }

    .partnerInfoViewDetailsLink {
      background-color: transparent;
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(20);
      color: $link-blue;
      padding: 0;
      height: auto;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .multipleActions {
    display: flex;
    align-items: center;

    .multipleRecommendedFlag {
      display: flex;
      align-items: center;
      margin-right: setUnit(24);
      background: $primary-color-light;
      padding: 8px;
      border-radius: 50%;

      .recommended {
        font-size: setUnit(14);
        font-weight: 500;
        margin-right: setUnit(12);
      }
    }

    .checkboxLabelWrapper {
      margin-bottom: 0;
    }
  }

  .partnerButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    @media (min-width: $mobile) {
      justify-content: space-between;
      align-items: flex-end;
      margin-right: 0;
    }
    .recommended div {
      display: flex;

      svg {
        path {
          stroke: $dark-color;
        }
      }
    }

    .messages {
      color: $text-gray-color;

      div {
        display: flex;
        align-items: center;
        column-gap: 4px;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    button {
      font-weight: 500;
      color: $dark-color;
      font-size: setUnit(14);
      line-height: setUnit(16);
      background: $white;
      border-radius: setUnit(100);
      padding: 8px;
      height: initial !important;
      &:hover {
        background: $background-ligh-gray;
      }

      &[data-recommended='true'] {
        color: $text-color;
        background: $primary-color-light;
        border: none;

        svg {
          path {
            stroke: $primary-color;
          }
        }

        &:hover {
          background: #ffebed;
        }
      }
    }
  }
}

.invitePendingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  width: max-content;

  .status {
    display: inline-flex;
    padding: 8px 12px 8px 10px;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: $background-grey-2;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & div:first-child {
      padding: 4px;
    }

    & div:last-child {
      color: $dark-grey-color;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.18px;
    }
  }
}

.resend {
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 20px;
  background: rgba(70, 115, 209, 0.08);
  cursor: pointer;
}
