@import 'styles/mixins';
@import 'styles/variables';

.myLibraryWrapper {
  .myLibraryContent {
    .loadingSpinner {
      font-size: 0.5em;
      margin: 50px auto;
    }

    .myLibraryTable {
      .formLibraryName {
        display: flex;
        align-items: center;
        gap: 12px;

        font-size: setUnit(16);
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;

        .multiSelect {
          .checkbox {
            [class*='ant-checkbox'] {
              [class*='ant-checkbox-inner'] {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                border-color: $text--light-gray-color;
              }
            }

            [class*='ant-checkbox-checked'] {
              [class*='ant-checkbox-inner'] {
                background-color: $primary-color;
                border-color: $primary-color;
                border-radius: 4px;
              }
              [class*='ant-checkbox-inner']::after {
                width: 7px;
                height: 12px;
                left: 25%;
                top: 45%;
              }

              &::after {
                border-color: $primary-color;
                border-radius: 4px;
              }
            }
          }

          &:hover,
          &:focus {
            [class*='ant-checkbox-inner'] {
              border-color: $primary-color;
            }
          }
        }

        .fileName {
          cursor: pointer;
          position: relative;
          width: 180px;
          -webkit-line-clamp: 2;
          flex-grow: 1;
        }
      }

      .actionIcons {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: -6px;
      }

      tbody {
        tr {
          td {
            &:last-child {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  [class~='ant-table-selection-col'] {
    width: 1%;
  }

  [class~='ant-table-column-sort'] {
    background-color: transparent;
  }

  [class~='ant-table-column-sorter'] {
    display: none;
  }
}
