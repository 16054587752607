@import 'styles/functions';
@import 'styles/variables';

.fullWidth {
  width: 100%;
  position: relative;
}

.dropdown {
  position: fixed !important;
  max-width: 590px;
  top: auto !important;
}

.checkbox {
  padding-top: 20px;
}

.radiusTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      max-width: 251px;
      padding: 16px;

      p {
        color: white !important;
        margin: 0;
        white-space: auto;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.formContainer {
  padding: 20px 40px;

  .addContainer {
    position: absolute;
    bottom: 13px;
    left: 40px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    &.disabled {
      pointer-events: none;
      .addText {
        color: $text--light-gray-color;
      }
    }
    .addIcon {
      height: 24px;
    }
    .addText {
      color: $text-color;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.18px;
      margin: 0 12px;
    }
  }

  .pill {
    display: flex;
    width: 100%;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    border-radius: 12px;
    background: $background-grey-2;
    margin-bottom: 20px;
    .primary {
      color: $confetti-blue;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.09px;
      margin: 0;
      margin-bottom: 3px;
    }
    .address {
      color: $text-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.09px;
      margin: 0;
    }
    .pillIcons {
      display: flex;
      align-items: center;
      .pillIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        svg {
          position: relative;
          top: 3px;
        }
        &:hover {
          background: $light-gray;
        }
      }
    }
  }

  .titleHeading {
    font-weight: 600;
    font-size: setUnit(20);
    color: $text-color;
    margin-bottom: 24px;
  }

  .border {
    border-radius: 8px 0 0 8px !important;
    .placeholder {
      border-radius: 8px 0 0 8px !important;
    }
    input {
      border-radius: 8px 0 0 8px !important;
    }
  }

  &.lightFull {
    padding-left: 0;
    font-size: setUnit(16);
  }

  .tooltipIcon {
    max-height: 19px;
  }

  .inputContainer {
    margin-top: 24px;
    width: 100%;
    .iconRight {
      margin: 0;
      color: $text-gray-color;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.09px;
    }
  }
  .inputContainerAddress {
    @extend .inputContainer;
    input {
      width: 100%;
    }
  }

  .propertyContract {
    margin: setUnit(48) 0 setUnit(32);

    .selectType {
      margin-top: 16px;
      display: flex;
    }
    .selectOption {
      width: 100%;
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: 20px 0 20px 20px;

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-right: 24px;
      }

      label {
        span {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      color: $text-color;
      padding: 21px 1px 21px 21px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      color: $text-color;
      margin-bottom: 16px;
    }
  }

  .agentNotes {
    margin-top: setUnit(32);
    .title {
      margin-bottom: setUnit(32);
    }
    .altLabel {
      font-weight: normal;
      color: $border-middle-gray;
      margin-right: auto;
      margin-left: setUnit(8);
    }
  }

  .invitationInformation {
    display: flex;
    flex-direction: column;
    gap: setUnit(16);
    & > * {
      border-radius: setUnit(8);
      border: 2px solid $background-ligh-gray-4;
      background: $background-ligh-gray-5;
      flex-shrink: 0;
      padding: setUnit(25);
      gap: setUnit(16);
    }
    .infoMessage {
      font-weight: 600;
      font-size: 16px;
      display: grid;
      grid-template-columns: setUnit(25) 1fr;
      .icon {
        grid-column: 1;
      }
      .title {
        grid-column: 2;
      }
    }
    .addresses {
      display: grid;
      grid-template-columns: setUnit(25) 1fr;
      .icon {
        grid-column: 1;
        grid-row: 1;
      }
      .title {
        grid-column: 2;
        grid-row: 1;
      }
      ul {
        list-style-type: none;
        font-size: setUnit(14);
        letter-spacing: setUnit(-0.09);
        line-height: setUnit(24);
        grid-column: 2;
        grid-row: 2;
        margin-bottom: 0;
        b {
          font-weight: 600;
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.18px;
    }
  }
}

.yesNoInput {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  [class*='ant-radio-button-wrapper'] {
    transition: none !important;
  }

  [class*='ant-radio-button-wrapper-checked'] {
    color: $text-color !important;
    border: 1px solid $primary-color !important;
    background: $primary-color-light !important;

    &::before {
      background: none !important;
    }

    &:focus-within {
      box-shadow: none !important;
    }
  }
}

.noRadioBtn {
  width: 100%;
  border-radius: 8px !important;
  line-height: 56px !important;
  height: 56px !important;

  border: 2px solid $light-gray !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: $dark-color !important;
}

.yesRadioBtn {
  @extend .noRadioBtn;

  margin-bottom: 16px !important;
}

.yesRadioBtn,
.noRadioBtn {
  &:hover {
    cursor: pointer;
    color: $text-color;
    border: 2px solid $border-light-gray-2;
  }
}

.remove {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.propertyInformation {
  padding: 20px 16px;
  border-radius: 12px;
  background: $background-grey-2;
  position: relative;
  .row {
    display: flex;
    gap: 20px;
  }
}
