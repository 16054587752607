@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.forgotPasswordLink {
  text-decoration: none;
  font-size: setUnit(14);
  color: $dark-grey-color;
  font-weight: 400;
}

.signupPassword {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 0;
  margin-bottom: 48px;
  .inputWrapper {
    flex: 0 0 100%;
  }
}

.loginLink {
  margin-top: 32px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.8px;
  margin-bottom: 32px;
  color: $secondary-color;
}

.form {
  max-width: setUnit(400) !important;
  margin: auto 0 !important;
}

.inputsRow {
  display: flex;
  align-items: flex-end;
  gap: 0 32px;
  margin-bottom: 32px;
}

.backArrow {
  display: inline-block;
  transform: rotate(180deg);
  font-size: setUnit(26);
  margin-right: setUnit(10);
}

.signUpButtonsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
}

.terms {
  margin-bottom: 32px;
}
