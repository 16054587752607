@import 'styles/variables';

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInBlock {
  animation: fadeInAnimation 0.3s linear;
}
