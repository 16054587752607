@import 'styles/variables';

.basePartnerProfile {
  min-height: 622px !important;
  // height: 622px !important;
  width: 675px !important;

  & [class*='ant-modal-content'] {
    padding: 0;
    overflow: hidden;
  }

  & [class*='ant-modal-header'] {
    margin-top: 32px;
  }
}

.avatarWrap {
  margin-bottom: 12px;
  .avatar {
    height: 80px;
    width: 80px;
    font-size: 32px;
    line-height: 36px;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  flex-direction: column;

  .businessName {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
    color: $text-color;
    margin-bottom: 4px;
  }

  .categoriesParagraph {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
    margin-bottom: 0;
    &.isClient {
      margin-bottom: 16px;
    }
  }
}

.headerBtnWrapper {
  display: flex;
  column-gap: 8px;
  margin-top: 16px;
}

.messageBtn,
.profileBtn {
  background-color: transparent !important;
  border: 1px solid $border-light-gray-2 !important;
  color: $text-color-middle !important;
  padding: 8px 16px !important;

  border-radius: 20px !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.18px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: initial !important;
  cursor: pointer;

  &:hover {
    background-color: transparent !important;
    border: 1px solid $text--light-gray-color !important;
  }

  div {
    height: 24px;
    width: 24px;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  span {
    margin-left: 0.25rem;
    font-weight: 500;
  }
}

.messageBtn {
  svg {
    path {
      fill: $dark-color;
    }
  }
}

.profileBtn {
  svg {
    path {
      stroke: $dark-color;
      stroke-width: 1.5;
    }
  }
}

.actionBtn {
  padding: 8px 16px !important;
  border-radius: 20px !important;
  height: initial !important;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 171.429% */
  letter-spacing: -0.18px !important;
}

.recommendedBar,
.unRecommendedBar {
  margin-top: 1.5rem !important;
  height: 48px;
  width: 100%;
  border: none !important;
  border-radius: 0.5rem !important;

  & [class*='ant-alert-message'] {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: center !important;
    white-space: pre;
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;

    .recommendedIcon {
      display: flex;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 0.875rem;
        margin-bottom: 2px;
      }
    }
  }

  .recommendedName {
    font-weight: 500;
  }
}

.unRecommendedBar {
  background: $background-grey-2 !important;
  cursor: pointer;

  .unRecommendedIcon {
    display: flex;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 0.875rem;
      margin-bottom: 2px;

      path {
        stroke: $dark-color;
        stroke-width: 2;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.partnerBody {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-height: 800px;
  overflow-y: auto;
  padding: 40px 0;
  row-gap: 24px;

  > div {
    padding: 0 40px;
  }

  .bodyHeader {
    color: $text-color;
    line-height: 20px;
    letter-spacing: -0.18px;
    margin-bottom: 12px !important;
  }

  .noMargin {
    margin-bottom: 0 !important;
  }
}

.contactContainer {
  // margin: 24px 0 0;

  .contactParagraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    word-break: break-word !important;
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;
    padding: 12px 0;
    margin-bottom: 0;
    .contactInner {
      display: flex;
      align-items: center;
      flex: 0 0 setUnit(100);
      margin-right: setUnit(15);
      .contactIcon {
        height: 20px;
        width: 20px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $dark-color;
      }
    }

    .contactPhoneText {
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-color;
        @media (min-width: $mobile) {
          text-align: right;
        }
      }
    }

    .contactAddressText {
      text-align: left;
      color: $text-color;
      @media (min-width: $mobile) {
        text-align: right;
      }
    }
  }
  .cpDetail {
    flex: 0 0 100%;
    margin-top: 10px;
    max-width: 425px;
    text-align: right;
    a {
      font-size: 16px;
      color: $text-color;
      letter-spacing: -0.18px;
      line-height: 20px;
    }
    @media (min-width: $mobile) {
      flex: 0 0 auto;
      margin-top: 0;
    }
    &.website {
      width: 350px;
      text-align: right;
    }
  }
}

.descriptionContainer {
  width: 100%;

  .descriptionParagraph {
    max-height: 360px;
    overflow: auto;
    font-size: 16px;
    color: $text-color;
    line-height: 24px;
    letter-spacing: -0.18px;
    margin-bottom: 0;

    &.descriptionEmpty {
      color: $text-gray-color;
    }
  }

  .requestHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }
}

.areasServerContainer {
  .areasTags {
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;
  }
}

.servicesContainer {
  margin-inline: 24px;
  padding: 16px !important;
  border-radius: 12px;
  background: $background-gray;

  .servicesTags {
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;

    span:not(:first-of-type) {
      &:before {
        color: $link-blue;
        content: ', ';
      }
    }
  }
}

.bodyHeaderSmallMargin {
  margin-top: 0.25rem;
}

.requestQuoteContainer {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $grey-color;
}

.quoteAttachment {
  border: 2px solid $light-gray;
  background: $white;
  margin-bottom: 10px;
  padding: 0 1rem;
  height: 68px;

  .fileName {
    font-size: 16px !important;
    line-height: 16px;
  }

  .quoteDate {
    font-size: 14px !important;
    line-height: 16px;
    margin-top: 0.25rem !important;
    color: $dark-color;
  }
}

.showMore {
  display: flex;
  align-items: center;
  margin: 1rem 0 0.25rem;
  width: fit-content;

  .showMoreIcon {
    height: 32px;
  }

  .showAllText {
    margin-left: 1rem;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &:hover {
    .showMoreIcon {
      svg {
        circle {
          fill: $light-gray;
        }
      }
    }
  }
}

.goBackButton {
  position: absolute;
  top: 40px;
  left: 40px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    height: 18px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
}
