@import 'styles/functions';
@import 'styles/variables';

.notDualTransTitle {
  font-size: 1rem;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 14px;
  margin-top: 16px;
  border-bottom: 1px solid #f7f7f7;
}
