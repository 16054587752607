@import 'styles/mixins';
@import 'styles/variables';

.header {
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(32);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
    letter-spacing: -0.47px;

    @media screen and (max-width: $mobile) {
      font-size: setUnit(20);
    }
  }
}
