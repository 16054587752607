@import 'styles/functions';
@import 'styles/variables';

@import 'styles/functions';
@import 'styles/variables';

$drawer-header-height: 64px;

.drawer {
  [class*='ant-drawer-body'] {
    padding: 0px !important;
  }
  .drawerHeaderContainer {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: $drawer-header-height;
    background: $white;
    padding: 0px 24px;
    border-bottom: 1px solid $light-gray;
  }
  .drawerBody {
    max-height: calc(100vh - $drawer-header-height);
    overflow-y: auto;
  }
}

.drawerContent {
  min-height: calc(100vh - $drawer-header-height - $footer-height);
}

.drawerContentWithFooter {
  min-height: calc(100vh - $drawer-header-height - $footer-height);
}
