@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.spinnerWrapper {
  margin-right: setUnit(24);
  .loader {
    font-size: setUnit(3);
    margin: 0;
  }
}

.actions {
  cursor: pointer;
  position: relative;
}

.attachmentOptions {
  &.options {
    min-width: setUnit(180);
    padding: 0;
    border-radius: setUnit(8);
    overflow: hidden;

    ul {
      list-style: none;
      margin-bottom: 0rem;
    }

    .list {
      list-style: none;

      .item {
        padding: setUnit(10) setUnit(22) setUnit(8) setUnit(22);
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
        }

        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
