@import "styles/functions";
@import "styles/variables";

.agent {
    .infoPart {
        margin-top: setUnit(45);
    }

    .areasOfOperation {
        align-items: end;
    }

    .placeName {
        text-align: right;
        margin-bottom: setUnit(8);
    }

    .description {
        line-height: setUnit(21);
        margin-top: setUnit(22);
    }
}
