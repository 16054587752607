@import 'styles/mixins';
@import 'styles/variables';

.agentMembersTable {
  margin-top: 24px;

  [class*='ant-table-thead'] {
    tr {
      th {
        background-color: #fff;
        font-size: setUnit(14);
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.18px;
        text-align: left;
        color: $text-gray-color;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0;
        &::before {
          display: none;
        }
      }

      [class~='ant-table-cell-scrollbar'] {
        box-shadow: unset;
      }

      [class~='ant-table-selection-column'] {
        [class~='ant-table-selection'] {
          display: none;
        }
      }
    }
  }

  [class~='ant-table-body'] {
    @media screen and (max-width: $mobile) {
      colgroup {
        col {
          &:first-child {
            width: 40% !important;
          }
        }
      }
    }
  }

  [class~='ant-table-tbody'] {
    [class~='ant-table-row-selected'] {
      td {
        background-color: $white;
      }

      &:hover {
        td {
          background-color: $primary-color-light;
        }
      }
    }

    tr {
      td {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      [class~='ant-table-selection-column'] {
        padding-right: 0px;
        text-align: right;
      }
    }

    tr:has(+ [class~='ant-table-expanded-row']) {
      td {
        border-bottom: 0px;
      }

      &:hover > td {
        background: $background-grey-2;
      }
    }

    [class~='ant-table-expanded-row'] {
      td {
        padding: 0px;
        background-color: unset;
      }
    }
  }

  [class~='ant-table-container'] {
    &::before,
    &::after {
      box-shadow: unset !important;
    }

    @media screen and (max-width: $mobile) {
      [class='ant-table-content'] {
        overflow-x: scroll;
      }
    }
  }

  .memberInformation {
    display: flex;
    align-items: center;
    gap: 12px;

    .memberAvatar {
      width: 2rem;
      height: 2rem;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.33px;
      color: $background-grey-2;
    }

    .memberNameWrapper {
      display: flex;
      gap: 6px;

      .memberName {
        color: $text-color;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: -0.33px;
      }

      .memberTitle {
        color: $text-gray-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.33px;
      }

      .ownerMember {
        height: 100%;

        border-radius: 100px;
        background: $background-grey-2;
        padding: 2px 6px;
        color: $dark-color;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: -0.33px;
      }
    }
  }

  .columnInfoTooltipWrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    .columnTitle {
      color: $text-gray-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.33px;
    }

    .columnTooltip {
      width: fit-content;
    }
  }

  [class~='ant-pagination'] {
    display: none;
  }
}

// TransactionModal.module.scss
.modal {
  :global(.ant-modal-content) {
    padding: 24px;
    border-radius: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  color: #000;
  font-weight: 600;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  :global(.ant-radio-button-wrapper) {
    border: none !important;
    height: auto;
    padding: 0;

    &::before {
      display: none !important;
    }
  }
}
