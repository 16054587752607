@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.details {
  margin-top: setUnit(25);

  .cardRow {
    display: flex;
    justify-content: space-between;
  }
}
