@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$footer-height: 110px;

.assignClientModal {
  [class*='ant-modal-content'] {
    padding: 40px 20px 32px !important;
  }

  .header {
    text-align: center;
    .title {
      font-weight: 700;
      margin-bottom: setUnit(0);
    }
    .subHeading {
      font-size: 1rem;
      margin: 0;
    }
  }

  .secondaryHeader {
    display: flex;
    align-items: center;
    gap: setUnit(20);
    margin: 1.5rem;

    .searchInput {
      [class*='inputHolder'] {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        background-color: $background-grey-2;
        border-radius: 8px;
        svg {
          margin-left: setUnit(15);
          margin-top: setUnit(5);
        }
      }
      input {
        height: 52px;
        background-color: $background-grey-2;
        border: none;
      }
    }

    .sortSelectContainer {
      display: flex;
      align-items: center;
      background-color: $background-grey-2;
      border-radius: setUnit(8);
      height: 52px;
      width: 180px;
    }
  }

  .content {
    max-height: 550px;
    min-height: 400px;
    overflow-y: scroll;
    margin-bottom: calc(#{$footer-height} - 20px);

    .teamCollapse {
      background-color: $white;
      [class*='ant-collapse-header-text'] {
        font-weight: 600;
        font-size: 1rem;
        .count {
          color: $text--light-gray-color;
          margin-left: setUnit(5);
        }
      }

      .panelContent {
        .allOption {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;

          .left {
            display: flex;
            align-items: center;
            span {
              font-size: 1rem;
              margin-left: setUnit(10);
            }
          }
        }
      }
    }

    .clientsContainer {
      padding: 0 20px;
    }

    .clientItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .leftSection {
        display: flex;
        align-items: center;
        .clientName {
          font-size: 1rem;
        }
        .assignedTo {
          font-size: 14px;
          color: $dark-color;
          opacity: 0.8;
        }
        .clientName,
        .assignedTo {
          margin-left: setUnit(10);
          margin-bottom: 0;
          line-height: 20px;
        }
        .avatar {
          width: 1.5rem;
          height: 1.5rem;
          .avatarPlaceholder {
            font-size: 10px;
          }
        }
      }
    }

    .clientItem,
    .allOption {
      .checkbox {
        label {
          margin: 0;
        }
        margin-right: 0.25rem;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: $footer-height;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: setUnit(10);

    .doneBtn {
      width: 150px;
      height: 52px;
      border-radius: setUnit(8);
    }

    .disabledButton {
      opacity: 0.5;
    }
  }
}

.reassignShareModal {
  .reassignShareConfirmText {
    text-align: center;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: $text-color;
      margin-bottom: 0.75rem;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.33px;
      text-align: center;
      color: $dark-color;
      margin-bottom: 0;
    }

    .textBold {
      font-weight: 600;
    }
  }
}
