@import 'styles/functions';
@import 'styles/variables';

.offerSubmission {
  color: $text-color;
  font-size: 16px;
  width: 100%;

  min-height: inherit;
  display: flex;
  flex-direction: column;

  .wrapper {
    padding: setUnit(24) setUnit(70) setUnit(80) 0px;
    flex-grow: 1;

    .containerRow {
      align-items: flex-end;
      max-width: 1005px;
    }

    .submissionRow {
      max-width: 1005px;
    }

    .initialLandingPage {
      margin-left: -3.8vw !important;

      @media (min-width: 1600px) {
        margin-right: 0 !important;
      }
    }

    .submissionSideDetails {
      position: sticky;
      bottom: 40px;
    }
    .submissionDetails {
      align-self: flex-end;
    }

    @media (max-width: $mobile) {
      padding: setUnit(20) setUnit(20) setUnit(32);
    }

    @media (min-width: $mobile) and (max-width: $tablet-horizontal) {
      padding: setUnit(24) setUnit(40) setUnit(50) setUnit(40);
    }
  }

  .errorWrapper {
    padding-left: setUnit(70);
    display: flex;
    justify-content: center;

    .submissionRow {
      width: 100%;
      max-width: 1200px;
    }

    .linkExpiredCard {
      border-radius: 16px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    @media (max-width: $mobile) {
      padding: setUnit(20) setUnit(20) setUnit(32);
    }
  }

  .formContainer {
    width: 25rem;
    margin-left: 56px;
    padding: 15px 15px;
  }
}

.spinner {
  min-height: inherit;
}
