@import 'styles/functions';
@import 'styles/variables';

.transactionContainer {
  .transactionPanes {
    [class*='ant-tabs-tabpane-active'] {
      height: 100% !important;
      margin-top: 32px;
    }
  }
}

.paneName {
  font-family: $font-family;
  display: flex;
  align-items: center;
  justify-content: center;

  .emptySpanIcon {
    margin: 0px 5px;
  }

  .errorIcon {
    display: flex;
    align-items: center;
    color: $error-color;
    font-size: 17px;
    line-height: 17px;
    margin-right: unset !important;
    margin: 0px 5px;
  }
}
