@import 'styles/functions';
@import 'styles/variables';

.btnWrapper {
  border-radius: 100%;
  background: $background-grey-2;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
}

.timelineCard {
  background: var(--White, #fff);
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  display: flex;
  flex-direction: column;

  .mainHeading {
    color: var(--Black-100, #303030);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.18px;
  }

  .countHeading {
    color: var(--Grayscale-40, #aaabab);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.18px;
  }

  .headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 6px;

    .titleWrapper {
      margin-top: 20px;
      margin-bottom: 10px;
      margin-left: 24px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .title {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.33px;
      }
    }

    .link {
      margin-top: 24px;
      margin-right: 24px;
      color: var(--REBRAND-Secondary-Link-Blue, #2b59da);
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.18px;
    }
  }
}
.timelineList {
  position: relative;

  div[class~='ant-list-item'] {
    border-bottom: none;
  }

  .timelineItem {
    display: flex;
    align-items: self-start;
    position: relative;
    padding: 10px 0;
    justify-content: flex-start;

    &:before {
      content: '';
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      width: 2px;
      background: var(--Grayscale-20, #e8e9ea);
      z-index: 0;
    }

    &:first-child:before {
      top: 50%;
    }

    &:last-child:before {
      bottom: 50%;
    }
  }

  .iconWrapper {
    z-index: 1;
    background: white;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
  }

  .details {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    .top {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.18px;
    }
    .main {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.18px;
    }
    .sub {
      margin-top: 4px;
      color: var(--Grayscale-80, #515151);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.18px;
    }
  }
}

.collapse {
  div[class~='ant-collapse-header'] {
    padding: 16px 24px !important;
  }
  li[class~='ant-list-item'] {
    border-bottom: none !important;
  }
  li[class~='ant-list-split'] {
    border-bottom: none !important;
  }
  div[class~='ant-collapse-content-box'] {
    padding: 0px 24px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.emptySchedule {
  height: 292px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .checkMark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #04a45114;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  & p {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.33px;
    text-align: center;
    color: #aaabab;
  }
}

.divider {
  height: 1px;
  background: $light-gray;
  margin: 10px 24px;
}

.lastDivider {
  height: 0px;
}
