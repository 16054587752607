@import 'styles/functions';
@import 'styles/variables';

.listPriceCollapse {
  padding: setUnit(12);

  .priceHistoryPanel {
    margin-top: setUnit(5);
    background-color: $white !important;
  }
  .panel {
    padding-bottom: 0;
    [class*='ant-collapse-content-box'] {
      padding-bottom: 0 !important;
      padding-left: 0 !important;
    }
    [class*='ant-collapse-header'] {
      padding: 0 !important;
    }
    [class*='ant-collapse-arrow'] {
      margin-top: setUnit(2) !important;
      left: 100px !important;
      right: auto !important;
    }

    .subSectionTitle {
      color: $dark-grey-color;
      margin-bottom: 0;
    }
  }

  .header {
    p {
      color: $dark-grey-color;
      margin: 0;
    }
    .price {
      color: $text-color;
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  .marginTop {
    margin-top: setUnit(30);
  }

  .priceHistoryContainer {
    margin-bottom: setUnit(5);
    display: flex;
    align-items: center;
    .date {
      color: $dark-grey-color;
      width: 85px;
      font-size: setUnit(13);
    }
    .price {
      color: $dark-color;
      font-weight: 600;
    }
    .point {
      position: relative;
      border-radius: 100px;
      border: 1px solid $light-grey-color;
      width: setUnit(12);
      height: setUnit(12);
      margin: 0 setUnit(20);

      .line {
        position: absolute;
        top: setUnit(9.6);
        left: setUnit(4.6);
        width: 1px;
        height: setUnit(17);
        background: $light-grey-color;
      }
    }
  }

  .priceContainer {
    .subSectionTitle {
      margin-bottom: 0;
    }
    .price {
      font-weight: 700;
      font-size: setUnit(20);
    }
    .notAvailable {
      color: $text--light-gray-color;
      font-size: setUnit(16);
    }
  }
}

.historyPrice {
  color: $text-color;
  font-size: 1rem !important;
  font-weight: 700 !important;
}
