@import 'styles/mixins';
@import 'styles/variables';

$active-border-color: $primary-color;

.wrapper {
  max-height: calc(100vh - 380px);
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 42px 32px 20px 32px;
  padding: 0 10px;
  @media (max-width: 600px) {
    margin: 20px 5px;
    max-height: calc(100vh - 430px);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .holder {
    .optionHolder {
      .checkboxOption {
        width: 100%;
        border-radius: 12px;
        border: 1.5px solid $light-gray !important;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding: 10px 16px 10px 20px;
        transition: $transition;
        height: 60px;
        gap: 8px;
        &:hover {
          border: 1.5px solid $text--light-gray-color;
        }

        &.checked {
          background-color: #4673d114 !important;
          border: 1.5px solid #4673d1 !important;
          .checkbox {
            background: #4673d1 !important;
          }
          &:hover {
            background-color: #ffebed;
            border: 1.5px solid #4673d1 !important;
            .checkbox {
              background: #4673d1 !important;
            }
          }

          .label {
            gap: 8px !important;
            .labelText {
              font-weight: 500;
              color: $text-color;
            }
          }
        }

        .label {
          display: inline-flex;
          margin-bottom: 0;
          pointer-events: none;
          width: calc(100% - 26px);
          .labelText {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            max-height: 44px;
            letter-spacing: -0.18px;
            color: $dark-color;
            width: calc(100% - 36px);
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .checkboxWrapper {
        height: 1.5rem;
        width: 1.5rem;

        .checkmark {
          display: flex !important;
          justify-content: center;
          align-items: center;
          position: initial !important;
          top: 0 !important;
          left: 0 !important;

          &.unchecked {
            display: none !important;
          }

          svg {
            height: 60% !important;
            width: 60% !important;
          }
        }
      }

      .unchecked {
        border: 2px solid $text--light-gray-color !important;
      }
    }
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
  .add {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $background-grey-2;
    svg {
      position: relative;
      top: 3px;
    }
  }
  .label {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.33px;
    margin: 0;
  }
}
