@import 'styles/variables';

.spinner {
  position: fixed;
  top: $header-height;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Darker background with some transparency */
  z-index: 99;
  backdrop-filter: blur(2px);
  width: 100vw;
  height: calc(100vh - $header-height - $forms-footer-height);
  display: flex;
  align-items: center;
  justify-content: center;
}
