@import 'styles/functions';
@import 'styles/variables';

.tasks {
  .task {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .details {
      display: flex;
      align-items: center;

      .icon {
        width: setUnit(40);
        height: setUnit(40);
        background: $background-grey-2;
        margin-right: 0.75rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .titleAddress {
        display: inline-block;
        cursor: pointer;

        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $text-color;
          margin-bottom: 0.25rem;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $dark-color;
          margin-bottom: 0;
        }
      }
    }

    .dueStatus {
      display: flex;
      align-items: center;

      .due {
        font-size: 14px;
        font-weight: 400;
        color: $dark-color;
        margin-right: 1.5rem;
        margin-bottom: 0;
        min-width: setUnit(100);
      }

      .alert {
        font-weight: 500;
        color: $error-color;
      }

      .status {
        width: setUnit(81);
        height: setUnit(32);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        border-radius: 20px;
        line-height: 0;
      }

      .new {
        background-color: $confetti-blue;
      }

      .inProgress {
        background-color: $orange;
      }

      .stuck {
        background-color: $primary-color;
      }
      .overdue {
        background-color: $error-color;
      }
    }
  }
}
