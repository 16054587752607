@import 'styles/variables';
@import 'styles/functions';

.accessLevelModal {
  [class~='ant-modal-header'] {
    display: none;
  }

  [class~='ant-btn-primary'] {
    margin-left: 16px !important;
  }
}

.titlePlaceholder {
  color: $light-gray-2;
}

.titlePlaceholder,
.accessLevelLabel {
  font-weight: 400 !important;
}
