@import 'styles/functions';
@import 'styles/variables';

.download {
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s;

  svg {
    vertical-align: middle;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.loadingSpinner {
  margin: 0;
  font-size: 0.3rem;
  color: #fff;
}
