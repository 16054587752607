.toastContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 16px;
  display: inline-flex;
  padding: 24px 28px 24px 24px;
  border-radius: 12px;
  margin-left: 10px;
  border-radius: 12px;
  background: #fff;
  gap: 16px;
  box-shadow: 0px 8px 26px 0px rgba(93, 93, 93, 0.16);
  z-index: 500; /* Ensure the toast appears above other elements */

  @media (min-width: 768px) {
    /* For screens larger than 768px */
    width: 624px;
  }

  .iconWrapper {
  }

  .toastTitle {
    color: var(--Grayscale-100, #262626);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 150% */
    letter-spacing: -0.35px;
  }

  .toastMessage {
    color: var(--Grayscale-100, #262626);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.35px;
  }

  .toastButton {
    margin-top: 19px;
    cursor: pointer;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 4px;
    color: white;
    border-radius: 8px;
    background: var(--Primary-Navy, #252d44);
    box-shadow: 0px 0px 32px 0px rgba(79, 79, 79, 0.13);
  }
}
