@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: calc(100% - #{setUnit(72)});
  overflow: auto;

  .showMore {
    display: flex;
    align-items: center;
    margin: 1rem 2rem;
    width: fit-content;

    .showMoreIcon {
      height: 32px;
    }

    .showAllText {
      margin-left: 1rem;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    &:hover {
      .showMoreIcon {
        svg {
          circle {
            fill: $light-gray;
          }
        }
      }
    }
  }

  .chatHeaderInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: setUnit(8) setUnit(32) setUnit(8) setUnit(32);

    .chatHeaderAvatars {
      margin-bottom: setUnit(8);
      .avatars {
        .avatarWrapper {
          margin-left: setUnit(-35);

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .avatar {
        width: setUnit(56);
        height: setUnit(56);

        &.isNotSingle {
          width: setUnit(50);
          height: setUnit(50);
        }
      }

      .overflow {
        width: setUnit(50);
        height: setUnit(50);
      }
    }

    .chatHeaderTitles {
      margin-left: setUnit(16);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .chatHeaderTitle {
      font-weight: 600;
      font-size: setUnit(20);
      line-height: setUnit(26);
      color: $text-color;
    }

    .chatHeaderThirdPartyCategory {
      margin-right: setUnit(8);
      margin-top: setUnit(8);
      outline: none;
      color: #747475;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    color: $text-color;
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(20);
    padding: 0 setUnit(24) setUnit(25);
    height: setUnit(56);
    margin-top: setUnit(40);
  }

  .item {
    height: setUnit(80);
    transition: all 0.1s;
    display: flex;
    padding: setUnit(12) setUnit(32);
    cursor: pointer;
    color: $text-color;
    position: relative;

    .activityLogCardWrapper {
      min-width: 100% !important;
      background-color: $white;
      background: $white;
      border-style: none;
      max-width: setUnit(600);
      padding: 0 0 setUnit(6) 0;
      & [class*='ant-card-head'] {
        border-bottom: setUnit(0) solid $light-gray !important;
      }

      & [class*='ant-card-body'] {
        padding: setUnit(16) setUnit(24);
      }

      & [class*='ant-card-head-title'] {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        /* identical to box height, or 150% */

        letter-spacing: -0.33px;
        /* REBRAND/Grayscale/100 */
        color: $dark-color;
      }
      & [class*='ant-timeline-item-label'] {
        // width: calc(50% - 35px) !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: setUnit(12px);
        line-height: setUnit(14px);
        /* identical to box height, or 114% */

        letter-spacing: -0.09px;

        /* REBRAND/Grayscale/80 */
        color: $dark-color;
      }
      & [class*='ant-avatar-string'] {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: setUnit(10.45);
        line-height: setUnit(14);
        color: $white;
      }
      & [class*='ant-timeline-item-tail'] {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: setUnit(12);
        line-height: setUnit(16);
        /* or 125% */

        letter-spacing: -0.18px;

        /* REBRAND/Grayscale/100 */

        color: $text-color;
      }

      & [class*='ant-timeline-item-content'] {
        font-family: 'Inter';
        font-style: normal;
        font-size: setUnit(14);
        line-height: setUnit(16);
        /* or 125% */

        letter-spacing: -0.18px;

        /* REBRAND/Grayscale/100 */

        color: $text-color;
      }
      & [class*='ant-timeline-item'] {
        min-height: setUnit(72) !important;
      }
      & [class*='ant-col-3'] {
        max-width: 8% !important;
      }

      .strongListtitle {
        margin-right: setUnit(5);
      }
    }

    .timelineTimeLabel {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: setUnit(12);
      line-height: setUnit(20);
      letter-spacing: -0.09px;
      color: $dark-color;
      padding-left: setUnit(0) !important;
      padding-right: setUnit(0) !important;
    }
    .timelineContent {
      font-family: 'Inter';
      font-style: normal;
      font-size: setUnit(14);
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      color: $text-color;
      word-break: break-word;
    }
    .timelineItemWrapper {
      min-height: setUnit(72) !important;
      padding-top: setUnit(14);
    }
    .avatarWrapper {
      border-left: setUnit(2) solid $light-gray;
      position: absolute;
      left: calc(1vw);
      width: setUnit(5);
      height: setUnit(72);
      top: 1rem;
    }
  }
}
