@import 'styles/functions';
@import 'styles/variables';

.headerTitle {
  display: flex;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.kitsPublishModal {
  .form {
    display: block;
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: auto;

    .permissionTitle {
      font-family: 'Inter', sans-serif;
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(21);
      margin-bottom: setUnit(24);
      display: block;
    }

    .permissionCheckboxes {
      .allPermission {
        margin-bottom: setUnit(16);
        display: flex;
        align-items: center;

        .checkboxLabel {
          margin-left: setUnit(8);
          margin-bottom: 0;
          font-size: setUnit(16);
        }
      }
    }

    .warning {
      margin-bottom: setUnit(16);
      display: block;
      color: $error-color;
    }
  }

  [class*='ant-col ant-form-item-label'] > label {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .formName,
  [class*='ant-input-number-group-addon'],
  [class*='ant-input-number-lg'],
  [class*='ant-select-selector'] {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
  }

  [class*='ant-input-number-group-addon'] {
    padding: 0 3px !important;
    background-color: $white !important;
  }

  .submitButton {
    margin-top: 30px;
    height: 56px;
    width: 277px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
  }
}
