@import 'styles/functions';
@import 'styles/variables';

.searchesScroll {
  height: calc(100vh - #{setUnit(94)});

  .pendingWrapper {
    height: 100%;
  }
}
