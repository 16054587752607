@import 'styles/functions';
@import 'styles/variables';

.dropzone {
  width: 100%;
  .text {
    color: $dark-grey-color;
  }
  .wrapper {
    width: 100%;
    border: 2px dashed $border-input-middle-gray;
    border-radius: setUnit(8);
    cursor: pointer;

    &.noClick {
      cursor: auto;
    }
  }

  .innerHolder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-ligh-gray;
    border-radius: setUnit(8);
    padding: 24px 24px 32px;
  }
}
