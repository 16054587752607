@import "styles/functions";
@import "styles/variables";

.commute {
    border: 1px solid $border-middle-gray;
    border-radius: setUnit(10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: setUnit(16) setUnit(27);
    margin-bottom: setUnit(16);
    background: $background-ligh-gray;
    cursor: pointer;
    height: setUnit(72);

    &:hover {
        background: #ffffff;
    }

    .icon {
        margin-right: setUnit(20);

        svg {
            vertical-align: middle;
        }
    }

    .info {
        flex-grow: 1;
        max-width: setUnit(152);
        white-space: nowrap;
    }

    .name {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .commuteTime {
        font-weight: 400;
        font-size: setUnit(14);
    }
}
