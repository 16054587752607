@import 'styles/functions';
@import 'styles/variables';

.export {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  .pageTitle {
    font-size: setUnit(24);
    line-height: setUnit(29);
    font-weight: 600;
    letter-spacing: -0.47px;
    border-bottom: 2px solid $light-gray;
    padding-bottom: setUnit(33);
    margin-top: 0;
  }
  .contentWrap {
    display: flex;
    flex-flow: column wrap;
    margin: auto;
    .icon {
      text-align: center;
      margin-bottom: setUnit(24);
      svg {
        width: setUnit(64);
        height: setUnit(64);
        path {
          stroke: $text--light-gray-color;
          stroke-width: 1px;
        }
      }
    }
    .content {
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: 400;
      letter-spacing: -0.18px;
      color: $text-gray-color;
      margin-bottom: 0;
      a {
        font-weight: 500;
        color: $link-blue;
      }
    }
  }
}
