@import 'styles/functions';
@import 'styles/variables';

.file {
  display: flex;
  align-items: center;
  background: $background-ligh-gray;
  padding: setUnit(14) setUnit(20) setUnit(14) setUnit(10);
  border-radius: setUnit(16);

  .fileIconHolder {
    width: setUnit(50);
    height: setUnit(40);
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .icon {
    margin-right: setUnit(10);
  }

  .filename {
    font-size: setUnit(16);
    font-weight: 600;
    white-space: nowrap;
  }

  .size {
    font-size: setUnit(14);
    font-weight: 400;
    color: $dark-grey-color;
    margin-top: setUnit(4);
  }

  .fileLoader {
    font-size: setUnit(3);
    color: #ffffff;
    margin: 0;
  }

  &.isOwnMessage {
    background: rgba(70, 115, 209, 0.12);
    color: $text-color;

    svg {
      path {
        stroke: $dark-grey-color;
      }
    }
  }
}
