@import 'styles/functions';
@import 'styles/variables';

.commutes {
  .commute {
    font-weight: 400;
    display: flex;
    align-items: center;

    .icon {
      margin-right: setUnit(10);
      svg {
        width: setUnit(30);
        height: setUnit(30);
        vertical-align: middle;
      }
    }
  }
}
