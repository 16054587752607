@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  max-height: 375px;
  overflow: auto;
  padding: setUnit(0) setUnit(40);
  .milestonesWrapper {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  .title {
    font-size: setUnit(20);
    font-weight: 600;
    color: $text-color;
    margin-bottom: setUnit(4);
  }

  .description {
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-weight: normal;
    color: $text-color;
  }

  .milestonesWrapper {
    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 24px;
    }
    .wrapperTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.18px;
      margin-bottom: 16px;
    }
    .milestones {
      display: flex;
      .pointsWrapper {
        width: setUnit(32);
        .point {
          display: flex;
          flex-direction: column;
          align-items: center;
          .dot {
            width: setUnit(32);
            height: setUnit(32);
            border-radius: 50%;
            border: 2px solid $light-gray;
            font-size: setUnit(14);
            color: $text-color;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .private {
          position: relative;
          top: -0.75rem;
          left: 1rem;
        }

        .line {
          width: 2px;
          background-color: $light-gray;
          height: setUnit(40);
        }
      }

      .milestonesInfoBlocksWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: setUnit(16);

        .milestoneInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: setUnit(32);
          &:not(:last-child) {
            margin-bottom: setUnit(40);
          }

          .milestoneTitle {
            font-size: setUnit(16);
            font-weight: 600;
          }

          .milestoneDeadline {
            font-size: setUnit(16);
            color: $dark-color;
          }
        }
      }
    }
  }

  .tasks {
    .task {
      padding: 12px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .iconWrap {
        background-color: $background-grey-2;
        border-radius: 100px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      .data {
        flex: 0 0 calc(100% - 44px);
        .taskTitle {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.18px;
          margin-bottom: 0;
        }

        .taskInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .assignee {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.18px;
            color: $dark-gray-3;
            span {
              font-weight: 500;
            }
          }
          .dueDate {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.09px;
            color: $dark-color;
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.transactionHeader {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-bottom: 1px solid $light-gray;
  padding-bottom: setUnit(24);
  margin-bottom: setUnit(24);
  .thBack {
    position: absolute;
    top: setUnit(16);
    left: 0;
    button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      .thbIcon {
        display: flex;
        svg {
          width: setUnit(24);
          height: setUnit(24);
        }
      }
    }
  }
  .thContent {
    text-align: center;
    .thTitle {
      font-size: setUnit(24);
      line-height: setUnit(32);
      font-weight: 700;
      color: $text-color;
    }
    .thDesc {
      font-size: setUnit(16);
      line-height: setUnit(18);
      font-weight: 400;
      color: $dark-color;
    }
  }
}

.transactionFooter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .selectBtn {
    font-size: setUnit(16);
    line-height: setUnit(24);
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: $background-blue;
    border-radius: setUnit(8);
    padding: setUnit(14) setUnit(50);
    border: 0;
    cursor: pointer;
    &:hover {
      background-color: $hover-blue;
    }
  }
}
