@import 'styles/functions';
@import 'styles/variables';

.attachment {
  width: 100%;
  height: auto;
  padding: setUnit(8) setUnit(22);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed $light-grey-color;
  border-radius: setUnit(8);
  margin-top: setUnit(8);
  @media (min-width: $mobile) {
    padding: setUnit(20);
  }

  .info {
    flex: 1;

    .filename {
      font-weight: 600;
      font-size: setUnit(14);
    }

    .date {
      font-size: setUnit(12);
      margin-top: setUnit(4);
      .requiredDoc {
        color: #ec455e;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 0.875rem;
        margin-top: 2px;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
    }
  }

  .icon {
    margin-right: setUnit(19);

    svg {
      vertical-align: middle;
    }
  }

  .fileNew {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 25px;
    background-color: $background-ligh-gray-2;

    .uploadFileText {
      font-size: setUnit(14);
      margin-left: setUnit(11);
      color: $secondary-color;
    }

    .uploadFileIcon {
      width: setUnit(18);
      height: setUnit(18);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fileNew:hover {
    transition: all 0.1s;
    background-color: $background-ligh-gray;
  }
}
