@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.directoryPreference {
  display: flex;
  justify-content: space-between;
  gap: 62px;
  margin-top: 40px;

  .preferenceDisclaimer {
    .loadingSpinner {
      font-size: 0.3em;
      margin: 0px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $black;
    }

    .description {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.8px;
      letter-spacing: -0.18px;
      color: $dark-grey-color;
      margin-top: 16px;
      margin-bottom: 0px;
    }
  }

  .switch {
    height: 24px;
    width: 44px;

    [class*='ant-switch-handle'] {
      top: 2px;
      left: 3px;
      width: 20px;
      height: 20px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  [class*='ant-switch-checked'] {
    background-color: $primary-color;

    [class*='ant-switch-handle'] {
      left: calc(100% - 20px - 3px) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
