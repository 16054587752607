@import 'styles/functions';
@import 'styles/variables';

.imagesCollage {
  border-radius: setUnit(16);
  width: setUnit(305);
  height: setUnit(180);
  background: darken($background-ligh-gray, 10);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  &.three {
    flex-direction: column;
  }

  .imagePendingWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .imageHolder {
    position: relative;

    &.two {
      &:nth-child(1) {
        border-right: 2px solid $white;
      }

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
        height: 100%;
      }
    }

    &.three {
      &:nth-child(1) {
        width: 50%;
        height: 100%;
        border-right: 2px solid $white;
      }

      &:nth-child(2) {
        border-bottom: 2px solid $white;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 50%;
        height: 50%;
      }
    }

    &.four {
      width: 50%;
      height: 50%;

      &:nth-child(1) {
        border-bottom: 2px solid $white;
      }

      &:nth-child(2) {
        border-bottom: 2px solid $white;
      }

      &:nth-child(odd) {
        border-right: 2px solid $white;
      }
    }

    .overflowCount {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: setUnit(18);
      font-weight: 600;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }

  .imageLoader {
    color: darken($border-middle-gray, 20);
  }
}
@media (max-width: 375px) {
  .imagesCollage {
    width: setUnit(255);
  }
}
