@import 'styles/variables';

.commentHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .commentData {
    display: flex;
    align-items: flex-start;

    .commentBoxAvatar {
      z-index: 1;
    }
    .commentInfo {
      display: flex;
      flex-direction: column;
      padding: 0px 12px;

      .commentName {
        font-weight: 600;
        overflow-wrap: anywhere;
      }

      .commentDate {
        display: flex;
        flex-direction: column;
        color: $text-gray-color;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .commentAction {
    width: 72px;
    display: flex;
    justify-content: right;

    .editActionsBtn {
      cursor: pointer;
      width: 32px;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 32px;
      border-radius: 100%;

      &:hover {
        background-color: $background-ligh-gray-4;
      }
    }

    .editActionsBtn[class~='ant-popover-open'] {
      background-color: $background-ligh-gray-4;
      circle {
        fill: $text-color;
      }
    }

    .resolveIcon {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unresolved {
      border: 2px solid $light-gray;

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
    }

    .resolved {
      background: $green-color;

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), $green-color;
      }
    }

    .resolvingLoader {
      width: 32px;
      height: 32px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.editActions {
  padding: 0px !important;

  [class*='ant-popover-inner'] {
    border-radius: 8px;
  }

  [class*='ant-popover-inner-content'] {
    padding: 0px;
  }

  .actionCollection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .action {
    display: flex;
    align-items: center;
    padding: 14px;
    height: 44px;
    width: 196px;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: $background-grey-2;
    }

    .actionName {
      margin-left: 8px;
    }
  }
}
