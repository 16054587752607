@import 'styles/functions';
@import 'styles/variables';

.insertClauseModal {
  [class='ant-modal-content'] {
    padding-top: setUnit(36) !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    [class='ant-modal-close'] {
      top: setUnit(37);

      @media screen and (max-width: $mobile) {
        right: 20px;
      }
    }
  }

  .progressBar {
    display: none !important;
  }
}
