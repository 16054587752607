@import 'styles/variables';

.offerSelectionFooterContainer {
  display: flex;
  padding: 0px 40px;
  height: 80px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);

  @media (min-width: $desktop) {
    left: 281px;
    padding: 0px 24px 0px 18px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .offerCountText {
    font-size: 16px !important;
    line-height: 20px;
    font-weight: 600;
  }

  .continueBtn {
    @extend .offerCountText;
    height: 52px;
    width: 134px;
    border-radius: 8px;
  }

  .disabledBtn {
    opacity: 0.5;
  }
}
