@import 'styles/functions';
@import 'styles/variables';

.activeTransactions {
  margin-top: 1rem;
  padding: 0 setUnit(15);
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 21px;
  }
  .expandedTransaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .expandedTransactionHeading {
      display: flex;
      h3 {
        margin-left: 12px;
      }
    }
  }

  .transaction {
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: setUnit(12);
    cursor: pointer;
    .iconName {
      display: flex;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-left: 16px;
    }
    .tasks {
      color: #525252;
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;
    }
  }
  .feedSelectDropdown {
    width: 150px !important;
  }
  .noResults {
    min-height: 40px;
    display: flex;
    align-items: center;
    color: #747475;
    justify-content: center;
    text-align: center;
  }
}
