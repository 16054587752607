@import 'styles/functions';

.stages {
  width: 100%;
  min-height: 100%;
  position: relative;

  .progress {
    position: sticky;
    top: 0;
  }

  .controls {
    margin-top: auto;
  }
}
