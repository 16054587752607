@import 'styles/functions';
@import 'styles/variables';

.propertyContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 setUnit(40) setUnit(15);
  @media (min-width: 1000px) {
    &.listOnly {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}

.propertyListWrapper {
  padding-bottom: 3rem;
}

.rowContainer {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem 1rem;
  height: auto;
}

.listView {
  border-right: 1px solid $tertiary-50-hover;
  width: 100%;
  &:nth-child(2),
  &:nth-child(3) {
    border-top: 1px solid $tertiary-50-hover;
  }
}
