@import 'styles/variables';
@import 'styles/mixins';

.pdfContainer {
  width: 100% !important;
  min-height: calc(100vh - $transaction-creation-header-height) !important;
}

.pdfDocument {
  width: fit-content;
  margin: 20px auto;
  position: relative;
  min-height: calc(100vh - $header-height);
}

.documentPage {
  margin: 0 0 16px 0px;
}

.renderFieldsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
}

.dynamicViewerZoomWidget {
  z-index: 3;
}

.blackWrapper {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.09px;
  text-align: right;
  margin-right: 15px;
  margin-top: 5px;

  color: $dark-color;
}

.pdfSpinner {
  height: fit-content;
}
.hideSpinner {
  display: none;
}
.loadingSpinner {
  font-size: 0.3em;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.pagePlaceholder {
  width: 892px;
  height: 1015px;
  background-color: #fff;
  border-radius: 8px;
}

.pdfPagePlaceholder {
  width: 892px;
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
}

.documentContainerPDF {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
