@import 'styles/functions';
@import 'styles/variables';

.header {
  padding: setUnit(32) setUnit(32) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .close {
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
