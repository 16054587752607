@import 'styles/functions';
@import 'styles/variables';

.pageDivider {
  border-right: 1px solid $border-light-gray;
  min-height: calc(100vh - #{$header-height}) !important;
}

.financialsContent {
  padding: setUnit(40);
  .pageTitle {
    font-weight: 700;
    font-size: setUnit(30);
    margin: 0;
    margin-bottom: setUnit(30);
  }
  .rightHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: setUnit(20);
    margin-bottom: setUnit(38);

    .status {
      background-color: #fff2f3;
      color: $primary-color;
      padding: setUnit(8) setUnit(20);
      border-radius: 100px;
      font-size: setUnit(16);
    }

    .downloadIcon {
      width: 40px;
      height: 40px;
      background-color: $background-grey-2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path {
          stroke: $text-color;
        }
      }
    }
  }
}
