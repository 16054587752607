@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.modal {
  width: 1000px !important;
  [class*='ant-modal-body'] {
    max-height: 70vh !important;
    overflow-y: scroll;
  }
}
