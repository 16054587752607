@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.inboundOutboundContainer {
  padding: setUnit(24) setUnit(40);

  display: flex;
  gap: 24px;
  align-items: flex-start;

  .iterationStagesBox,
  .iterationActionBox {
    max-width: 285px;
    width: 100%;

    @media (max-width: $mobile) {
      max-width: 100%;
      width: 100%;
    }

    @media (min-width: $mobile) and (max-width: $desktop) {
      max-width: 250px;
    }
  }

  @media (max-width: $mobile) {
    padding: setUnit(12) setUnit(20);
    gap: 12px;
  }

  @media (max-width: $mobile-small) {
    flex-flow: wrap;
  }

  .iterationStagesBox {
    padding: 24px 0px 12px;

    .statusCardTitle {
      font-size: setUnit(16);
      font-style: normal;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
      padding: 0px 24px;

      @media (max-width: $desktop) {
        padding: 0px 15px;
      }
    }

    @media (max-width: $desktop) {
      padding: 15px 0px;
    }
  }

  .iterationContentBox {
    flex-grow: 1;
    padding: 40px;

    @media (max-width: $desktop) {
      padding: 24px;
    }
  }

  .iterationActionBox {
    @media (max-width: $desktop) {
      padding: 15px;
    }
    .icons {
      line-height: 0px;
    }
  }
}

.iterationsTimeline {
  padding: 15px 24px 0px !important;

  &:not(:last-child) {
    padding-bottom: 24px;
  }

  @media (max-width: $desktop) {
    padding: 15px;
  }

  .iterationTimelineItem {
    width: fit-content;
    padding-bottom: 0px;

    &:not(:last-child) {
      padding-bottom: 27px;
    }

    [class*='ant-timeline-item-tail'] {
      border-color: $light-gray !important;
      left: 11px;
      border-width: 2px !important;
    }

    [class*='ant-timeline-item-head'] {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border-color: $light-gray;
      color: $light-gray;
      top: -5.5px;
    }

    [class*='ant-timeline-item-head-custom'] {
      left: 11px;
      padding: 0px;
      top: 5.5px;
    }

    [class*='ant-timeline-item-content'] {
      top: -5px;
      left: 10px;
      color: $dark-color;

      font-size: setUnit(16);
      font-weight: 500;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
      cursor: pointer;

      .timelineContent {
        text-transform: capitalize;
        .timelineSubContent {
          font-size: setUnit(14);
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.18px;
          color: $text--light-gray-color;
          margin-top: 2px;
        }
      }

      &:hover {
        .timelineContent {
          color: $text-color;
          .timelineSubContent {
            color: $dark-color;
          }
        }
      }
    }

    .disabledItem {
      cursor: not-allowed;
      color: $text--light-gray-color !important;
    }

    .lastIteration {
      top: -12px;
    }
  }

  .initialIterationsItem {
    &:not(:last-child) {
      padding-bottom: 37px;
    }
  }

  .dotItem {
    padding-bottom: 27px !important;

    [class~='ant-timeline-item-tail'] {
      height: 100%;
      top: -6px;
    }

    [class~='ant-timeline-item-head'] {
      width: unset;
      height: unset;
      top: 0px;
      left: 11.5px;
    }

    [class~='ant-timeline-item-content'] {
      top: -10px;
    }
  }

  .iterationTimelineItem *:has(+ [class*='ant-timeline-item-content']:hover) {
    border-color: $text--light-gray-color;
  }

  .activeTimelineItem {
    [class*='ant-timeline-item-content'] {
      color: $text-color;
      font-weight: 600;

      .timelineContent {
        .timelineSubContent {
          color: $dark-color;
          font-weight: 400;
        }
      }
    }
  }
}

.iterationActionButton {
  margin-top: 0 !important;
}

.submitBtnContainer {
  margin-top: setUnit(24);
  text-align: center !important;
}

.submitBtn {
  cursor: pointer;
  outline: none;
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(16);
  letter-spacing: -0.09px;
  color: $text-color;
  background-color: $white;
  border: 2px solid $light-gray;
  border-radius: setUnit(8);
  width: 100%;
  padding: setUnit(12);
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    margin-right: setUnit(8);
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;
      }
    }
  }
  a {
    color: $white;
  }
}

.cardTitle {
  margin: 0;
  text-align: center;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: -0.18px;
  color: $text-color !important;
  margin-bottom: setUnit(32) !important;
}

.metaWrap {
  .offerIterationText {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.8px; /* 130% */
    letter-spacing: -0.18px;
    margin-bottom: 12px;
  }

  .meta {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    &:not(:last-child) {
      margin-bottom: setUnit(20);
    }

    .icon {
      background-color: $background-grey-2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: setUnit(6);
      border-radius: 50%;
      margin-right: 10px;
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: #515151;
        }
      }
    }

    h6 {
      color: $text-color;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.18px;
      margin-bottom: 0;
    }

    .expirationContent {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }

    .expirationDetails,
    p {
      color: $text-color;
      font-size: setUnit(14);
      font-weight: 400;
      letter-spacing: -0.18px;
      line-height: 20px;
      margin-bottom: 0;
    }

    .expirationDetails {
      .expiryTime {
        color: $error-color;
        font-weight: 600;
        font-size: setUnit(16);
        margin-bottom: 4px;

        display: flex;
        gap: 6px;

        .expiryDetails {
          margin-top: 0.5px;

          .expiryDuration {
            display: flex;
            gap: 2px;

            .expiryCount {
              font-weight: 700;
            }

            .smallText {
              font-size: setUnit(11);
              color: $text-gray-color;
              font-weight: 600;
              line-height: 8px; /* 72.727% */
              letter-spacing: -0.18px;
              display: block;
            }
          }
        }
      }
    }
  }

  :is(.accepted, .rejected) {
    align-items: center;
  }
}

.acceptedContent {
  color: $green-color !important;
}

.customIcon {
  line-height: 0px;
}

.emptyBox {
  min-height: 152px;
  display: flex;
  align-items: center;
  color: $text--light-gray-color;
  text-align: center;
  width: 184px;
  margin: 0 auto;
}
