@import 'styles/variables';

.declinedButtonContainer {
  .declineButton {
    border: 2px solid $light-gray !important;

    width: 180px;
    height: 52px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &:hover {
      border-color: $text--light-gray-color !important;
    }
  }

  .svgMessage {
    margin-right: 8px;
  }
}
