@import 'styles/variables';

.pdfContainer {
  width: 100%;
  min-height: calc(100vh - $transaction-creation-header-height) !important;

  canvas {
    margin: auto;
  }
}

.previewResponseWrapper {
  border: none !important;
  outline: none !important;

  &:hover {
    border: 1px solid $primary-color !important;
    outline: 1px solid $primary-color !important;
  }
}

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0px;
  }

  [class*='ant-tooltip-arrow-content'] {
    display: none;
  }
}

.helpMessageContainer {
  padding: 6px 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: $primary-color;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
  border-radius: 20px;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  svg {
    margin-right: 4px;
  }

  .calendarPopup {
    margin-top: 10px;
  }

  .helpOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;

    .calendar {
      background: unset;
      border: none;
      padding: 0;
      height: unset;
      min-height: unset;

      [class*='ant-picker-suffix'] {
        margin-left: 0px;
      }

      .calendarSuffixIcon {
        color: $white;
        font-family: $font-family;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      > div {
        display: flex;
        flex-direction: row-reverse;
        color: $white;

        input {
          display: none;
        }
      }
    }
  }
}

.loader {
  z-index: 999 !important;
  right: 50%;
  margin: auto;
  position: absolute !important;
  text-align: right !important;
  top: 50% !important;
}

.toolTipContainer {
  z-index: 99999;
}

.loading {
  opacity: 30% !important;
}

.renderFieldsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
}

.pdfFieldTextFont {
  font-family: Helvetica;
}

.PDFFieldOverlay {
  position: relative;
}

.PDFOverlayPosition {
  position: absolute !important;
  z-index: 9999 !important;
}

.PDFZIndex {
  z-index: 9999;
}

.documentPage {
  margin: 0 0 16px 0px;

  canvas {
    box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
  }
}

.zoomWidgets {
  width: 40px;
  position: sticky;
  bottom: 20px;
  left: calc(100% - 80px);
  text-align: center;

  background: transparent;

  & > div {
    border-bottom: 1px solid $light-gray;
  }

  .zoomWidget,
  .strikeThroughIcon,
  .commentsIcon {
    box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $light-gray;
  }

  .strikeThroughIcon,
  .commentsIcon {
    border-radius: 6px;
    margin-bottom: 12px;
  }

  .commentsIcon {
    .commentsBadge {
      [class~='ant-badge-count'] {
        min-width: 18px;
        width: 20px;
        height: 20px;
        font-size: 11px;
        font-weight: 700;
        line-height: 17px;
        text-align: center;
        padding: 0px;
        border: 1.5px solid $white;

        top: 1.5px;
        right: 2px;
        background: $primary-color;
      }
    }
  }

  .upperZoomWidget {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    border-bottom: unset;
  }

  .lowerZoomWidget {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .zoomWidget:hover {
    background-color: $light-gray;
  }

  :is(.commentsIcon, .strikeThroughIcon, .zoomWidget):hover {
    cursor: pointer;
  }
}

.PDFFieldNotActive {
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid $text-gray-color;
  outline: 1px solid $text-gray-color;

  .pdfFieldText {
    border: none !important;

    &:focus {
      border: none !important;
    }
  }
}

.PDFFieldDisabled {
  cursor: not-allowed !important;
}

.missingRequired {
  border-radius: 4px;
  outline: 2px solid $error-color !important;
  outline-offset: 2px;
}

.PDFFieldActive {
  border-radius: 4px;
  border: 1px solid $primary-color-hover;
  outline: 1px solid $primary-color-hover;
}

.iframe {
  width: 100%;
  height: calc(100vh - $header-height - 80px);
}

.pdfDocument {
  width: fit-content;
  margin: auto;
  position: relative;
}

.pdfFieldWrapper {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0px;
  opacity: 0.2;
}

.inputDisabledPDF {
  width: 100%;
  height: 100%;
  z-index: 9998;
  position: absolute;
  top: 0px;
}

.deferAvatarContainer {
  [class*='ant-tooltip-inner'] {
    min-width: 277px;
    height: 128px;
    border-radius: 16px;
    border: 1px;
    background-color: $white;
    padding: 20px;
  }

  [class*='ant-tooltip-arrow-content'] {
    display: none;
  }

  .deferTooltipContainer {
    .deferUser {
      display: flex;
      align-items: center;

      .deferAvatarUser {
        margin-right: 12px;
      }

      .deferUserName {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: $text-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .deferRemoveRow {
      display: flex;
      align-items: center;
      margin-top: 28px;

      &:hover {
        cursor: pointer;
      }

      .cancelIcon {
        margin-right: 8px;
        path {
          stroke: $primary-color;
        }
      }

      .deferRemoveText {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: $text-color;
      }
    }
  }
}

.popoverContent {
  [class~='ant-popover-inner'] {
    border: 1px solid $border-light-gray-2;
    padding: 20px 20px 12px !important;
    border-radius: 16px;
    width: 312px;

    textarea {
      &::placeholder {
        color: $text--light-gray-color;
      }

      border-radius: 0px;
    }
  }
}
