@import 'styles/functions';
@import 'styles/variables';

.informationText {
  display: flex;

  border: 1px solid $light-gray;
  border-radius: 8px;

  padding: setUnit(15) setUnit(16);

  background-color: $background-ligh-gray-5;

  .text {
    color: $dark-grey-color;
    font-weight: 400;
    margin-left: setUnit(8);
    margin-bottom: 0;
  }
}
