@import 'styles/functions';
@import 'styles/variables';

.item {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  cursor: pointer;
  border-radius: 0.5rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .info {
    margin-left: setUnit(16);
    flex: 1;

    .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }

    .details {
      font-size: 14px;
      line-height: 16px;
      color: $dark-grey-color;
    }
  }

  .select {
    display: none;
    width: setUnit(74);
    height: setUnit(40);
    background: #ffffff;
    border-radius: setUnit(100);
    border: 2px solid $light-gray;
    font-size: setUnit(14);
    font-weight: 500;
  }

  &:hover {
    background: $background-ligh-gray;

    .select {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .photo {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: setUnit(8);
    }
  }
}
