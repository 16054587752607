@import 'styles/variables';

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.pageContent {
  max-width: 400px;
  margin: 0px auto !important;
}

.listBoxInput {
  width: 100%;
  border: 1px solid $border-light-gray-2 !important;
  border-radius: 8px;
  background-color: $white;

  [class*='ant-select-selector'] {
    height: 48px !important;
    align-items: center;
    border: none !important;

    [class*='ant-select-selection-placeholder'] {
      font-weight: 400;
      font-size: setUnit(16);
      color: $text--light-gray-color !important;
    }

    [class*='ant-select-selection-search'] {
      font-weight: 400;
      font-size: setUnit(16);
    }

    [class~='ant-select-selection-item'] {
      color: $text-color;
      font-size: setUnit(16);
    }

    [class*='ant-select-selection-search-input'] {
      height: 48px !important;
      color: $text-color;
    }
  }

  &:focus {
    border-color: $text--light-gray-color !important;
  }
}

.label {
  margin-bottom: 12px;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;
  display: block;
}

.fieldWrapper {
  border: 1px solid $light-gray;
  border-radius: 8px;

  padding: 20px 20px 24px;

  background-color: $background-ligh-gray-5;
  width: 100%;
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }
}
