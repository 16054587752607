@import 'styles/functions';
@import 'styles/variables';

.commute {
  margin-top: setUnit(24);

  .destination {
    .table {
      .column {
        width: setUnit(224);
        min-width: max-content;
        padding-top: setUnit(6);
        padding-bottom: setUnit(6);
        vertical-align: top;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: -0.09px;
        overflow-wrap: anywhere;

        @media screen and (max-width: $mobile) {
          font-size: setUnit(14);
        }

        &Heading {
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: -0.09px;
          //SVG width + SVG margin-right
          padding-left: setUnit(42);

          @media screen and (max-width: $mobile) {
            font-size: setUnit(14);
          }
        }
      }
    }

    .place {
      display: flex;
      align-items: center;
      margin-bottom: setUnit(10);

      svg {
        margin-right: setUnit(10);
        margin-left: setUnit(16);
      }

      .placeName {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: -0.09px;
        margin-right: setUnit(10);

        @media screen and (max-width: $mobile) {
          font-size: setUnit(14);
        }
      }

      .placeAddress {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: -0.09px;
        color: $text-gray-color;

        @media screen and (max-width: $mobile) {
          font-size: setUnit(12);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
  }
}
