@import 'styles/functions';
@import 'styles/variables';

$padding: 8;

.spinner {
  font-size: 3px !important;
}

.loader {
  position: absolute;
  right: 20px;
  width: 20px;
}

.custom {
  color: $link-blue;
}

.values {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 2px solid #d6d6d7;
  height: 52px;
  width: 100%;
  &.grouped {
    flex-wrap: wrap;
  }
  .valuesGroup {
    display: flex;
    overflow: hidden;
    &:not(:first-child) {
      margin-left: setUnit($padding);
    }
  }

  .value {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.375rem 1.875rem 0.375rem 0.75rem;
    border-radius: setUnit(30);
    background: $background-grey-2;
    font-size: 16px;
    font-weight: 400;
    margin-left: setUnit($padding);
    height: 36px;
    color: $text-color;
    &:first-child {
      margin-left: 0;
    }
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        vertical-align: middle;
        width: setUnit(20);
        height: setUnit(20);

        path {
          fill: $dark-color;
        }
      }
    }
  }

  .more {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: setUnit(6) 0 setUnit(6) 0;
    border-radius: setUnit(30);
    background: $background-grey-2;
    font-size: setUnit(14);
    font-weight: 400;
    margin-left: setUnit($padding);
    width: setUnit(50);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color;
  }

  .anotherText {
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $text-gray-color;
    margin-left: 12px;
    margin-bottom: 0;
  }
}
