@import 'styles/variables';
@import 'styles/functions';

.itemsWrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  .item {
    width: 100%;
    padding: 20px 16px;
    border-radius: 12px;
    border: 1.5px solid;
    border-color: $light-gray;
    background-color: $white;
    margin-bottom: 12px;
    .label {
      color: $dark-color;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.09px;
    }
    .chipsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .chip {
        cursor: pointer;
        display: flex;
        width: 83.75px;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1px solid;
        border-color: $light-gray;
        &.blue {
          background-color: $light-blue-lighter !important;
          border-color: $light-blue !important;
        }
        p {
          color: $dark-color;
          font-size: 12.5px;
          font-weight: 400;
          letter-spacing: -0.09px;
        }
      }
    }
  }
}
