@import 'styles/functions';
@import 'styles/variables';

.avatar {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 225px;
  &.Two {
    width: 410px;
  }

  .photoDiv {
    position: relative;

    .noPhoto {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: setUnit(8);
      border: 0.0625rem solid $dark-gray-3;
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $mobile-small) {
        flex-direction: column;
      }

      &Text {
        font-weight: 500;
        font-family: $font-family;
        color: $dark-grey-color;
        font-size: 0.7rem;
        line-height: 2px;
      }
    }

    .empty {
      border: none;
    }

    .photo {
      height: 45px;
      width: 45px;
      border-radius: setUnit(8);
      object-fit: cover;
    }

    .photoFive {
      @media screen and (max-width: $desktop) {
        height: setUnit(150);
        width: setUnit(150);
      }

      @media screen and (max-width: $tablet) {
        height: setUnit(130);
        width: setUnit(130);
      }

      @media screen and (max-width: $mobile) {
        height: setUnit(100);
        width: setUnit(100);
      }

      @media screen and (max-width: $mobile-small) {
        height: setUnit(70);
        width: setUnit(70);
      }
    }

    .photoFour {
      @media screen and (max-width: $tablet) {
        height: setUnit(160);
        width: setUnit(160);
      }

      @media screen and (max-width: $mobile) {
        height: setUnit(130);
        width: setUnit(130);
      }

      @media screen and (max-width: $mobile-small) {
        height: setUnit(100);
        width: setUnit(100);
      }
    }

    .photoThree {
      @media screen and (max-width: $mobile) {
        height: setUnit(160);
        width: setUnit(160);
      }

      @media screen and (max-width: $mobile-small) {
        height: setUnit(130);
        width: setUnit(130);
      }
    }
  }

  .address {
    margin-top: setUnit(8);
    font-weight: 600;
    max-width: setUnit(210);
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-family: $font-family;
    margin-bottom: setUnit(4);
  }

  .addressFive {
    @media screen and (max-width: $desktop) {
      max-width: setUnit(150);
    }

    @media screen and (max-width: $tablet) {
      max-width: setUnit(130);
    }

    @media screen and (max-width: $mobile) {
      max-width: setUnit(100);
    }
  }

  .addressFour {
    @media screen and (max-width: $tablet) {
      max-width: setUnit(160);
    }

    @media screen and (max-width: $mobile) {
      max-width: setUnit(130);
    }
  }

  .addressThree {
    @media screen and (max-width: $mobile) {
      max-width: setUnit(160);
    }
  }

  .zip {
    font-weight: 400;
    max-width: setUnit(210);
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-family: $font-family;
    color: $dark-grey-color;
  }

  .zipFive {
    @media screen and (max-width: $desktop) {
      max-width: setUnit(150);
    }

    @media screen and (max-width: $tablet) {
      max-width: setUnit(130);
    }

    @media screen and (max-width: $mobile) {
      max-width: setUnit(100);
    }
  }

  .zipFour {
    @media screen and (max-width: $tablet) {
      max-width: setUnit(160);
    }

    @media screen and (max-width: $mobile) {
      max-width: setUnit(130);
    }
  }

  .zipThree {
    @media screen and (max-width: $mobile) {
      max-width: setUnit(160);
    }
  }
}

.photoDivWrap {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
