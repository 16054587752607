@import 'styles/functions';
@import 'styles/variables';

.recommendedBy {
  font-size: setUnit(14);
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: setUnit(10);

  .icon {
    margin-right: setUnit(5);

    svg {
      vertical-align: middle;
    }
  }

  .name {
    font-weight: 500;
    margin-left: setUnit(5);
  }
}
