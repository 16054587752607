@import 'styles/functions';
@import 'styles/variables';

.iconText {
  display: flex;
  align-items: center;
  .detail {
    margin-left: setUnit(12);

    .text {
      margin-bottom: 0;
      font-size: setUnit(16);
      color: $text-color;
      font-weight: 600;
    }

    .info {
      font-weight: 400;
      color: $text-color;
      margin-bottom: 0;
      font-size: 13px;
      margin-top: 2px;
    }
  }
}
