@import 'styles/variables';

.controls {
  width: auto !important;
  position: absolute;
  top: -5px;
  left: 20px;
  @media (max-width: 600px) {
    left: -15px;
  }
}

.question {
  padding: 0 70px;
}
