@import 'styles/functions';
@import 'styles/variables';

.attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $light-gray;
  border-radius: setUnit(8);
  min-height: setUnit(80);
  max-height: setUnit(100);
  padding: setUnit(24);
  margin-top: setUnit(16);
  transition: $transition;
  &:hover {
    background: $background-ligh-gray;
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .icon {
      margin-right: setUnit(16);
    }
    .names {
      .documentName {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        margin-bottom: setUnit(8);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260px;
      }
      .filename {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        margin-bottom: 0;
        color: $dark-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260px;
      }
    }
  }

  .removeIcon {
    cursor: pointer;
    transition: transform 0.1s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}
