@import 'styles/functions';
@import 'styles/variables';

.commuteWrapper {
  margin-top: setUnit(30);
  font-size: setUnit(14);
  line-height: setUnit(16);

  .commuteTitle {
    color: $border-middle-gray;
    margin-bottom: setUnit(12);

    b {
      font-weight: 600;
      color: $text-color;
      padding-right: setUnit(8);
      word-break: normal;
    }

    span {
      word-break: break-word;
    }
  }

  .commuteVariants {
    color: $dark-color;

    .commuteVariant {
      display: flex;
      align-items: center;
      height: setUnit(32);
      margin-bottom: setUnit(4);

      .commuteVariantText {
        word-break: break-word;
      }

      .commuteVariantIcon {
        margin-right: setUnit(16);
        width: setUnit(32);
        height: setUnit(32);

        svg {
          width: setUnit(32);
          height: setUnit(32);

          circle,
          rect,
          path {
            stroke: $primary-color;
          }
        }
      }
    }
  }
}
