@import 'styles/variables';
@import 'styles/mixins';

.zoomWidgets {
  width: 40px;
  position: sticky;
  bottom: 20px;
  left: calc(100% - 80px);
  text-align: center;
  z-index: 9999;
  background: transparent;

  & > div {
    border-bottom: 1px solid $light-gray;
  }

  .zoomWidget {
    box-shadow: 0px 2px 16px $box-shadow-1;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $light-gray;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .zoomWidget:hover {
    background-color: $light-gray;
  }

  .zoomWidget:hover,
  .strikeThroughIcon:hover {
    cursor: pointer;
  }

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.disabledZoomWidget {
  background-color: $hover-light-gray !important;
  &:hover {
    cursor: not-allowed !important;
    background-color: $hover-light-gray !important;
  }
}
