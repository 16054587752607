@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskModalForm {
  .content {
    max-height: calc(100vh - 22.5rem);
    overflow-y: auto;
    padding-right: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-16);

      .fieldHalf {
        width: 50%;
        padding: 0 setUnit(20);
      }
    }

    .moreOptionsContainer {
      position: relative;
    }

    .moreOptionsBtn {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
    }

    .options {
      @include box-shadow;
      position: absolute;
      background: #ffffff;
      border-radius: 0.5rem;
      min-width: 11.25rem;
      padding-top: 14px;
      z-index: 99999;
      bottom: 30px;
      padding-left: 16px;
      left: 1px;
      padding-right: 16px;
      bottom: 36px;

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
      }
    }
  }
}

.transactionTaskForm .content {
  max-height: unset;
}

.taskModalForm,
.taskModal {
  .content,
  .taskModalBody {
    .tagSelectorContainer {
      margin-bottom: 2rem;

      > label {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.taskModal {
  .taskModalBody {
    max-height: calc(100vh - 22.5rem);
    overflow-y: auto;
    padding-right: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-16);

      .fieldHalf {
        width: 50%;
        padding: 0 setUnit(20);
      }
    }

    .moreOptionsContainer {
      position: relative;
    }

    .moreOptionsBtn {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
    }

    .options {
      @include box-shadow;
      position: absolute;
      background: #ffffff;
      border-radius: 0.5rem;
      min-width: 11.25rem;
      padding-top: 14px;
      z-index: 99999;
      bottom: 30px;
      padding-left: 16px;
      left: 1px;
      padding-right: 16px;
      bottom: 36px;

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
      }
    }
  }

  [class*='ant-modal-title'] {
    text-align: center !important;
    font-family: $font-family !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }
}

.taskModalFooter {
  border-top: 1px solid $border-light-gray-2;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  margin-top: 30px;

  .footerButton {
    height: 52px;
    width: 152px;
    border-radius: 8px;
  }

  .disabledButton {
    opacity: 0.4;
  }
}
.multiSelectWrapper {
  margin: 0 0 setUnit(24);
  .multiSelect {
    & [class*='ant-select-selector'] {
      min-height: setUnit(40);
      height: auto;
      border: unset !important;
      border-bottom: 2px solid $border-input-middle-gray !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
  }
}
