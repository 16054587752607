@import 'styles/functions';
@import 'styles/variables';

.propertyAttributes {
  margin-top: 15px;
  max-height: calc(100vh - 520px);
  overflow-y: auto;
  padding-right: 10px;

  @media screen and (max-height: 1000px) {
    max-height: calc(100vh - 400px);
  }
}

.locationAttributes {
  padding: 0 10px;
  margin-top: 15px;
  max-height: calc(100vh - 520px);
  overflow-y: auto;

  @media screen and (max-height: 1000px) {
    max-height: calc(100vh - 400px);
  }
}

.wishListContainer {
  padding: 0 40px;
  .panelHeader {
    font-weight: 600;
    font-size: setUnit(20);
  }

  .tabsContainer {
    margin: 20px 0;
    padding: 10px 10px 20px 20px;
    border-radius: setUnit(12);
    border: 1px solid $light-gray;
    background-color: $background-ligh-gray-5;

    [class*='ant-tabs-tab-btn'] {
      text-shadow: none !important;
    }
  }
}
