@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  min-width: setUnit(50);
  position: relative;

  @media (min-width: $tablet) {
    width: 3vw;
  }

  .actionsWrapper {
    margin-left: auto;
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
      background: $background-ligh-gray;
    }
  }

  .options {
    @include box-shadow;

    min-width: setUnit(176);
    position: absolute;
    right: 0;
    top: setUnit(40);
    background: #ffffff;
    z-index: 99;
    border-radius: setUnit(8);

    ul {
      list-style: none;
      margin-bottom: 0;
    }

    .item {
      display: flex;
      align-items: center;
      padding: setUnit(12) setUnit(18);
      font-size: setUnit(14);

      &:hover {
        background: $background-ligh-gray;
      }

      .icon {
        margin-right: setUnit(10);
        line-height: 0px;
      }
    }
  }
}

.allFoldersWrapper {
  border-left: 2px solid $light-gray;
  margin-left: 53px;
  border-radius: 0 10px;
}
.moveButton {
  margin-bottom: setUnit(30);
  margin-top: setUnit(20);
  display: flex;
  justify-content: center;

  button {
    padding: 0 30px;
  }
}

.noFolderFound {
  text-align: center;
  p {
    margin-top: setUnit(15);
  }
}

.documentsModal {
  .multiFoldersWrapper {
    max-height: 370px;
    overflow-y: auto;
  }
}
