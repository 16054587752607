@import 'styles/functions';
@import 'styles/variables';

.stepHeader {
  font-family: $font-family;
  font-weight: 700;
}

.text {
  font-family: $font-family;
  color: $dark-color;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  a {
    color: $link-blue;
  }
}

.inputOtpContainer {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  margin-top: 32px;

  .input {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.2);
    background: white;
    height: 64px !important;
    width: 56px !important;
    text-align: center;

    color: var(--Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
    caret-color: transparent;

    transition: all;
    transition-duration: 0.3s;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input:focus,
  .input:focus-visible {
    outline: none !important;
    border: 1px solid var(--Grayscale-50, #747475);
  }

  .invalid {
    border: 1.5px solid rgba(255, 134, 150, 0.8);
  }

  .invalidCodeText {
    color: rgba(255, 134, 150);
  }
}

.inputWrapper {
  width: setUnit(280) !important;
  margin-top: setUnit(40) !important;
}

.input {
  height: auto !important;
  font-family: $font-family;
  font-size: setUnit(28) !important;
  text-align: center;
}

.input::placeholder {
  height: auto !important;
  font-family: $font-family;
  font-size: setUnit(28) !important;
  text-align: center;
}

.codeInput {
  letter-spacing: setUnit(32);
  width: setUnit(256) !important;
  padding-left: setUnit(32) !important; // to compensate letter-spacing
}

.continueButton {
  font-size: 16px;
  margin-top: setUnit(66);
  height: 52px !important;
  width: 200px !important;
  border-radius: 12px !important;
}

.errorTextWrapper {
  height: 0;
  width: 100%;
  position: relative;
}

.invalidCodeText {
  position: absolute;
  width: 100%;
  top: setUnit(12);
  left: 50%;
  transform: translateX(-50%);
  color: $error-color;
}

.inlinedPhoneNumber {
  max-width: setUnit(
    135
  ); // formatted phone is rendered using input with fixed width, which we don't need here
  display: inline-block;
}

.blockedVerificationContent {
  margin-bottom: setUnit(30);
}
