@import 'styles/variables';
@import 'styles/functions';

.contentWrapper {
  .contactFileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 20px;
  }

  .inputsWrapper {
    overflow: auto;

    margin-top: setUnit(28);
    margin-bottom: setUnit(48);

    h4 {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .contactInformationFooter {
    margin: 0px;
  }
}

.lineSeparator {
  height: 1px;
  background-color: $light-gray;
  margin-top: setUnit(28);
  margin-bottom: setUnit(24);
}

.inputBlock {
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(24);

  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 12px;
    color: $text-color;
  }

  .inputPhoneWrapper {
    margin: 0;

    [class*='react-tel-input'] {
      font-family: $font-family;
      font-size: setUnit(16);

      input {
        border-color: $border-light-gray-2;
        height: setUnit(48) !important;
        font-size: setUnit(16);
      }
    }
  }

  .inputHolder {
    input {
      border: 1px solid $border-light-gray-2 !important;
      font-size: setUnit(16);

      &:focus {
        border-color: $text--light-gray-color !important;
      }
    }
  }
}

.checkboxBlock {
  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 20px;
    color: $text-color;
    white-space: normal;
  }

  .checkboxWrap {
    display: flex;
    flex-flow: column wrap;
    .checkbox {
      margin-left: 0;
      width: fit-content;
      color: $dark-color;

      &:hover [class*='ant-checkbox-inner'] {
        border-color: $primary-color !important;
      }

      & [class*='ant-checkbox'] {
        & [class*='ant-checkbox-inner'] {
          &:after {
            transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
            left: 25%;
            width: setUnit(6);
            height: setUnit(12);
          }
        }
      }
      & [class*='ant-checkbox-checked'] {
        &:after {
          border-color: $primary-color;
        }
        & [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.offsetHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    padding-left: setUnit(40) !important;
    height: setUnit(44);
  }
}

.offsetIcon {
  position: absolute;
  left: 0;

  svg {
    vertical-align: middle;
    width: setUnit(32) !important;
    height: setUnit(32) !important;
  }
}

.description {
  height: auto;
}

.label {
  white-space: nowrap;
}

.links,
.documents {
  min-height: setUnit(44);
  height: auto;
}

.documents {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    color: $dark-color;
    margin-left: 0;
  }
}

.input {
  width: 100%;
  padding: 0px;
}

.inputPhone {
  width: 100%;
  padding: 0px;
  border-width: 2px;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-color: #e8ecef;
  outline: none;
  margin: 0;
  vertical-align: middle;
  color: #262626 !important;
  font-size: 1rem;
  line-height: 1.3125rem;
  border-radius: 0rem !important;
  background: none !important;
  border: none !important;
}

.continueBtn {
  border: none;
  outline: none;
  background-color: $background-blue;
  color: $white;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.1s;
  padding: 15px 50px;
}

.continueBtn:hover {
  background-color: #1d4f76;
}
