@import 'styles/functions';
@import 'styles/variables';

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: setUnit(40);

  .stepHeader {
    font-family: $font-family;
    font-weight: 700;
  }

  .text {
    font-family: $font-family;
    color: $dark-color;
  }

  .highlightedEmail {
    font-weight: 500;
  }

  .continueButton {
    padding: setUnit(14) setUnit(16);
    font-size: 16px;
    margin-top: setUnit(40);
    height: unset;

    &_disabled {
      opacity: 0.3;
    }
  }

  .selectorWrapper {
    width: 100%;
    .mlsDropdown {
      min-height: setUnit(52);
      span[class~='ant-select-arrow'] {
        transition: $transition;
      }
      &[class~='ant-select-focused'] {
        div[class~='ant-select-selector'] {
          box-shadow: none;
          border-bottom-color: $text-gray-color;
        }
      }
      div[class~='ant-select-selector'] {
        border: 0;
        border-bottom: 2px solid $border-light-gray-2;
        border-radius: 0 !important;
        span[class~='ant-select-selection-item'] {
          border-radius: setUnit(30);
          padding: setUnit(4) setUnit(12);
          span[class~='ant-select-selection-item-content'] {
            font-weight: 500;
            font-size: setUnit(14);
            line-height: setUnit(24);
            letter-spacing: setUnit(-0.09);
            color: $text-color;
          }
          span[class~='ant-select-selection-item-remove'] {
            display: flex;
            align-items: center;
            svg {
              fill: $text--light-gray-color;
            }
          }
        }
      }
      &[class~='ant-select-focused'] {
        span[class~='ant-select-arrow'] {
          transform: rotate(180deg);
        }
      }
      div[class~='rc-virtual-list-scrollbar-show'] {
        display: block !important;
      }
    }
  }
}
