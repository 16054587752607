@import 'styles/variables';

.mainWrapper {
  position: relative;
  border-radius: 16px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .title {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.33px;
  }
  .list {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 24px;

    &::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Webkit browsers (Chrome, Safari) */
    }
  }
  .myTransactionCardWrapper {
    width: 600px;
    height: 116px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 16px;
    padding: 0 24px;

    .details {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .detailsInner {
        display: flex;
        flex-direction: column;
      }
      .main {
        color: $text-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 111.111% */
        letter-spacing: -0.18px;
        cursor: pointer;
        margin-bottom: 2px;
      }
      .address {
        color: $text-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.18px;
        cursor: pointer;
        display: flex;
      }
      .closeWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;
        gap: 6px;
        .close {
          color: $text-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.18px;
        }
        .daysBadge {
          display: inline-flex;
          padding: 4px 8px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 30px;
          background: rgba(70, 115, 209, 0.1);
          color: #4673d1;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.18px;
        }
        .ellipseMargin {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .detailsWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 102px;
    }
  }

  .rightIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .daysBadge {
    display: inline-flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    border-radius: 30px;
    background: rgba(70, 115, 209, 0.1);
    color: #4673d1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.18px;
  }
}
