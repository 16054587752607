@import 'styles/variables';
@import 'styles/mixins';

.navigationInfoRow {
  background-color: transparent;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  padding: 33px 40px;
  justify-content: space-between !important;
  z-index: 1;

  .navigationIcons {
    display: flex;
    position: relative;

    z-index: 5;

    .navigationButton {
      background-color: $white;
      @include flexCenter;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      border: 2px solid $light-gray;
      z-index: 5;
      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}

.formPill {
  .preFormPdfInfo {
    border: unset;

    .pdfNameInformation {
      color: $dark-color;
      max-width: 400px;
    }

    &:hover {
      background-color: $background-grey-2;
      .pdfNameInformation {
        color: $text-color;
      }
    }
  }

  .editablePdfInfo {
    border: 1px solid $text-gray-color;

    &:hover {
      background-color: #fff;
    }

    input {
      text-align: left;
      max-width: 400px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }

    svg {
      width: 24px;
    }
  }
}
