@import 'styles/functions';
@import 'styles/variables';

.featureItem {
  display: flex;
  align-content: center;
  border-bottom: 1px solid $grey-color;
  padding-top: setUnit(12);
  padding-bottom: setUnit(12);

  &:last-child {
    border-bottom: none;
  }

  .label {
    font-size: setUnit(14);
    font-weight: 400;
    margin-right: setUnit(8);
  }
}
