@import 'styles/variables';

.toolBar {
  .zoomIcon {
    cursor: pointer;
  }

  .toolBarButton {
    border-radius: 5px !important;
  }

  .mr5 {
    margin-right: 5px;
  }
}
