@import 'styles/variables';

.numberBox {
  width: 100%;
  height: 38px;

  .prefix {
    display: inline;
    margin-right: -20px;
  }

  .inputBoxWithPrefix {
    padding-left: 25px;
    padding-right: 10px;
  }

  .inputBox {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 2px solid $border-light-gray-3;
    border-radius: 0px;
    height: 100%;
    background-color: transparent;
    &:not(.inputBoxWithPrefix) {
      padding: 0px 10px;
    }
  }

  .prefix,
  .inputBox {
    color: $text-color;
    font-family: $font-family;
    font-size: 18px;
    line-height: 24px;
  }
}
