@import 'styles/functions';
@import 'styles/variables';

.formDetailModal {
  .subHeadingItems {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .subHeading {
      margin-bottom: 10px;
    }
    .trackingDivider {
      margin: 0px 0px 0px 11px;
      padding: 0px;
      position: relative;
      height: 16px;
      border-left: 2px solid $light-gray;
    }

    .declineTrackingdDivider {
      height: unset;
      padding-bottom: 12px;
      margin-bottom: 9px;
      padding-top: 12px;

      .declinedReason {
        margin-bottom: unset !important;
        margin-top: unset !important;
        margin-left: 30px !important;
      }
    }

    .trackingDivider:nth-last-child(1) {
      display: none !important;
    }

    .declineTrackingdDivider:nth-last-child(1) {
      display: block !important;
      border-left: unset;
    }

    .emptyDivider {
      margin-bottom: 12px;
    }

    .declinedReason {
      background-color: $primary-color-light;
      border-radius: 8px;
      color: $primary-color;
      margin-top: 12px;

      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09000000357627869px;
      text-align: center;
      padding: 8px 0px;
      margin-left: 38px;
      margin-right: 42px;
      margin-bottom: 12px;
    }
  }

  .formEditorHeading {
    margin-bottom: 10px;
    font-family: $font-family;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    max-height: 700px;
  }

  .transactionContainer {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .clientAvatar {
      width: 32px;
    }
    display: flex !important;
    gap: 20px !important;
    align-items: flex-start !important;
  }

  .transHeading {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;

    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    margin-bottom: 4px;

    ~ p {
      font-size: 16px;
      margin-bottom: 0px;
      line-height: 20px;
      color: $text-color;
    }
  }

  [class*='ant-modal-title'] {
    font-weight: 600;
    font-size: 20px;
    text-align: start !important;
  }

  [class*='ant-modal-content'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
    height: auto;
    overflow-y: hidden !important;
    scrollbar-width: none !important;

    [class*='ant-modal-close'] {
      top: 28px;

      [class*='ant-modal-close-x'] {
        top: 16px !important;
        right: 0px !important;
      }
    }
  }

  .ant-modal-centered .ant-modal {
    width: 80% !important;
  }

  .formDetailModalBody {
    padding: 10px 40px 16px 40px;
    overflow-y: auto;

    .formDetailFlexPanel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .voidButton,
      .editButton {
        margin-top: 16px;
      }
    }

    .formDetailHeader {
      margin-bottom: 18px !important;
      color: $dark-gray-3;
      font-size: 16px !important;
    }

    .formDetailLeftPanel {
      border-right: 1px solid $light-gray;

      .formDetailButtons {
        margin-right: 32px;
      }
    }

    .modalBodyDivider {
      height: 100%;
      border-right: 2px solid #e8e8e8;
    }

    .viewFormBtn {
      width: 100%;
      height: 52px;
      border-radius: 10px !important;
    }

    .viewFormFixBtn {
      width: 100%;
      height: 52px;
      border-radius: 10px !important;
    }

    .voidButton,
    .editButton {
      width: 100%;
      height: 52px;
      border-radius: 10px !important;

      border: 2px solid $light-grey-color;
      color: $dark-color;

      font-size: setUnit(16);
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;

      &:hover {
        border-color: $text--light-gray-color;
      }

      .editButtonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }

    .formDetailRightPanel {
      max-height: 560px;
      overflow-y: hidden;
      overflow-x: hidden;
      padding-top: 5px;

      [class~='ant-divider-horizontal'] {
        border-color: $light-gray;
      }

      &:hover {
        overflow-y: auto;
      }
    }

    .righPanelPadding {
      padding-left: 40px !important;
    }
  }

  .disabledSection {
    opacity: 0.5;
  }

  .formTransction,
  .formClient {
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .clientTransactionRow {
    margin-top: 5%;
  }

  .fillersName {
    font-family: $font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin-bottom: 0px;
    text-align: left;
  }

  .fillerEmail {
    color: $text-gray-color;
    font-size: setUnit(12);
    margin-bottom: 0px;
    text-align: left;
  }

  .fillersRow {
    justify-content: space-between;
    font-family: $font-family;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $text-gray-color;
    display: flex;
    align-items: center;

    .fillerInfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 50%;

      .signatureCount {
        height: 24px;
        min-width: 24px;
        font-family: $font-family;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.18px;
        color: $text--light-gray-color;
        background: #ffffff;
        border: 2px solid $text--light-gray-color;
        border-radius: 4px;
        padding-top: 8%;
        padding-bottom: 8%;
        cursor: pointer;
      }
      .pendingSignature {
        display: flex;
        align-items: center;
        justify-content: center;

        color: white !important;
        border: none !important;

        svg {
          rect,
          path {
            stroke: $hover-red;
          }
        }
      }
      .avatarWrapper {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: setUnit(24);
          transform: translateX(-50%);
          border-left: setUnit(2) solid $light-gray;
          width: 1px;
          height: calc(100% + 24px);
        }
      }
      .signatureCountRow {
        margin-right: 14px;
        display: flex;
        align-items: center;
      }
    }

    .fillerActions {
      @extend .fillerInfo;
      justify-content: flex-end;

      .fillersStatus {
        font-family: $font-family;
        margin-right: 10px;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: 16px;
        text-align: right;
        color: $text-gray-color;

        p {
          margin-bottom: 0px;
          font-size: inherit;

          &:last-child {
            font-size: setUnit(12);
          }
        }

        .colorClass {
          color: $hover-red;
        }
      }
    }

    margin-bottom: 8px;
  }

  .ccRow {
    margin-bottom: 12px;
  }
  .iconPositions {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }

  [class*='ant-collapse-header'] {
    padding: 0 !important;
    color: $dark-gray-3;
  }

  [class*='ant-collapse-content-box'] {
    padding: 20px 0px 7px !important;
  }

  .buttonDot {
    background: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .cancelledDiv {
    background: #fff2f3;
    border-radius: 8px;
    padding: 5%;
    margin-bottom: 2%;
  }
  .cancelledError {
    font-family: $font-family;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.09px;
    color: $primary-color;
    margin-bottom: 1%;
  }

  .showAll {
    font-family: $font-family;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin: 0;
  }
  .showRow {
    display: flex;
    align-items: center;
    padding-top: 5%;

    .showAllBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .fillersHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .headerStatus {
      display: flex;
      align-items: center;
      margin-right: 42px;
      font-family: $font-family;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $text-gray-color;
    }
  }

  .editorHeader {
    font-weight: 600;
  }

  .modalFlexButtons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .hideFooterButtons {
    display: none;
  }

  @media only screen and (max-width: $mobile) {
    .formDetailModalBody {
      padding: 16px 20px;
    }

    .documentFormName,
    .formTransction,
    .formClient,
    .formHeading,
    .formSent,
    .fillersName {
      font-size: 14px;
    }
    .documentFormStatus {
      font-size: 12px;
    }
    .fillersStatus {
      text-align: left;
      font-size: 12px;
      justify-content: flex-start;
      margin-bottom: 5%;
    }
    .buttonDot {
      justify-content: flex-end;
    }
  }

  @media only screen and (max-width: 650px) {
    [class~='ant-modal-content'] {
      padding-bottom: 0px !important;
      padding-top: 1.5rem;
    }

    [class~='ant-modal-close'] {
      right: 20px;
      top: 20px !important;
    }

    .formDetailModalBody {
      padding: 0px;
    }

    .modalContentContainer {
      padding: 16px 20px 16px 20px;
      max-height: 50vh;
      overflow-y: auto;

      .formDetailLeftPanel,
      .formDetailRightPanel {
        width: 100%;
        max-width: 100%;
        border-right: 0px;
        flex: 0 0 100%;
      }

      .formDetailLeftPanel {
        .formDetailButtons {
          margin-right: 0px;

          display: none;
        }
      }

      .formDetailRightPanel {
        max-height: unset !important;
        overflow-y: unset !important;
        margin-top: 10px;
        padding-left: 8px !important;
      }
    }

    .hideFooterButtons {
      display: none;
    }

    .formDetailFooterButtons {
      display: block !important;

      box-shadow: 0px 4px 34px 0px #00000014;
      padding: 20px;
    }
  }
}

.smallFormDetailModal {
  [class~='ant-modal-content'] {
    padding-bottom: 0px;
  }

  .formDetailRightPanel {
    max-height: 500px !important;
  }

  .smallFormDetailModalBody {
    padding: 0px !important;

    .smallFormDetailContentContainer {
      padding: 0px 40px 16px 40px;
    }

    .formDetailFooterButtons {
      box-shadow: 0px 4px 34px 0px #00000014;
      padding: 18px 40px 32px;
    }
  }
}

.downloadIcon {
  line-height: 0px;
  margin-right: 40px;
  cursor: pointer;
}

.modalLoading {
  @media screen and (max-width: $mobile-small) {
    [class~='ant-modal-close'] {
      top: 0px !important;
    }

    [class~='ant-modal-content'] {
      padding-bottom: 1.5rem !important;
    }
  }
}
