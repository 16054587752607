@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.row {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
  margin-left: setUnit(12);
}

.showingsTable {
  padding: 0px setUnit(40);
}

.tableView {
  border-radius: 1rem;
  margin-bottom: setUnit(20);

  .inner {
    display: flex;
    justify-content: space-between;
    padding: 0 setUnit(10);
  }
  .header {
    display: flex;
    align-items: center;

    .title {
      font-size: setUnit(20);
      font-weight: 600;
      margin-left: setUnit(12);
    }
  }

  span[class~='ant-table-column-sorter-full'] {
    display: none;
  }

  .sortedColumnTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sorterIcon {
      margin-left: setUnit(8);
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(20);
      height: setUnit(20);
      background-color: $background-grey-2;
      border-radius: setUnit(100);
      cursor: pointer;
      transform: rotate(0deg);
    }

    .sorterUp {
      transform: rotate(180deg);
    }
  }

  .view {
    div[class~='ant-table-content'] {
      border-radius: setUnit(16);
      table {
        border-radius: setUnit(16);
        background-color: #fff;
      }
    }
  }

  .transactionShowingsView {
    .transactionShowingsCollapsibleTable {
      &:first-child {
        margin-top: setUnit(24);
      }
    }
  }

  thead[class~='ant-table-thead'] {
    th {
      height: 48px !important;
      padding: setUnit(13) setUnit(24) !important;
      line-height: setUnit(16) !important;
    }
  }
  tbody[class~='ant-table-tbody'] {
    tr:hover > td {
      background: $background-grey-2;
    }
    tr {
      height: setUnit(0.5) !important;
    }

    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
      font-size: setUnit(14);
      &.ant-table-cell::before {
        display: none;
      }
    }

    th::before {
      display: none !important;
      width: 0;
    }

    th,
    td {
      align-items: center;
      line-height: setUnit(16);
      letter-spacing: -0.33px;
      font-size: setUnit(16);
      cursor: pointer;

      &:first-child {
        border-right: unset !important;
      }
      &:last-child {
        border-right: unset !important;
      }
      .test {
        display: flex;
        align-items: center;
      }

      .upcoming {
        display: flex;
        justify-content: flex-end;

        .upcomingText {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .upcomingActions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          right: 0;

          [class*='ant-btn'] {
            height: 2.25rem;
            padding-top: 0.4rem;
          }

          .reject {
            border: 1px solid $error-color;
            border-radius: 36px !important;
            svg {
              fill: $error-color;
            }
          }
        }
      }

      .pending {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        padding-top: 0 !important;

        .pendingText {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .pendingActions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          right: 0;

          .approve {
            margin-right: setUnit(7);
            border: 1px solid $hover-green !important;
            border-radius: 36px !important;
          }
          .reject {
            border: 1px solid $error-color;
            border-radius: 36px !important;
            svg {
              fill: $error-color;
            }
          }
        }
      }

      &[class~='ant-table-column-sort'] {
        background-color: transparent;
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
      font-size: setUnit(16);
    }

    th {
      & [class*='ant-table-cell']::before {
        display: none !important;
      }
    }

    td {
      padding: setUnit(18) setUnit(24) !important;
    }

    th {
      &.ant-table-cell::before {
        display: none !important;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
    }

    table {
      border: unset !important;
    }

    .condoIcon {
      margin-right: setUnit(8);
    }
  }

  thead[class~='ant-table-thead'] {
    th {
      background-color: transparent;
      color: $dark-grey-color;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
    &.ant-table-cell::before {
      display: none;
    }
  }

  th::before {
    display: none !important;
    width: 0;
  }
}
.modalBtn {
  margin-left: 16rem !important;
  margin-top: 9% !important;
}

.inlineRowContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dateTime {
    margin-left: setUnit(12);
    gap: 4px;
    flex-direction: column;
    display: flex;
    font-weight: 500;
  }
  label {
    padding-left: 16px;
  }
}

.bold {
  font-weight: 600;
}
.line2 {
  color: $text-gray-color;
  margin-top: setUnit(4);
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 80px;
}

.feedBackbuttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-inline-end: setUnit(12);
}
.newFeedback {
  background: transparent;
  border: none;
  color: transparent;
}

.past {
  justify-content: flex-end;
}

.youText {
  color: $error-color;
}

.approvalUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  span {
    margin-bottom: 5px;
  }
}

.formModal {
  .title {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
  }
}

.pendingActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: setUnit(10);

  .approve {
    border: 1px solid $hover-green !important;
    border-radius: 12px !important;
    width: setUnit(60) !important;
    height: setUnit(40) !important;
  }
  .reject {
    border: 1px solid $error-color !important;
    border-radius: 12px !important;
    width: setUnit(60) !important;
    height: setUnit(40) !important;
    svg {
      fill: $error-color;
    }
  }
}

.pendingTooltipContainer {
  [class='ant-tooltip-inner'] {
    border-radius: 8px !important;
    padding: 10px;
    max-width: 250px;
  }
}

.tooltipTitle {
  display: flex;
  margin: 0;
  .pendingIcon {
    margin-right: 0.5rem;
  }
}

.rowPendingIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: setUnit(10);
}
