@import 'styles/functions';
@import 'styles/variables';

.priceHighlighter {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom: 1px dashed $black;
}
