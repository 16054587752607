@import 'styles/functions';
@import 'styles/variables';

.KitsTable {
  margin-top: setUnit(32);
  margin-bottom: setUnit(32);
  border-radius: setUnit(16);
  box-shadow: 0px 2px 16px 0px #30303008;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .table {
    border-radius: setUnit(16);
    flex: 1;

    .kitsRowHeading {
      height: setUnit(38);

      .kitsCellHeading {
        overflow-wrap: anywhere;
        border-bottom: 1px solid $background-ligh-gray-4;

        &:not(:last-child) {
          border-right: 1px solid $background-ligh-gray-4;
        }

        div {
          //padding-left: setUnit(16);
        }

        .heading {
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $dark-grey-color;
          padding-left: setUnit(16);
        }
      }
    }
  }
}
