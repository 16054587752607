@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.optionsDropDown {
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  [class*='ant-popover-placement-bottomRight'] {
    padding-top: 0px !important;
  }

  .icon {
    margin-right: 10px;
    svg {
      path,
      circle {
        stroke: $primary-color;
      }
    }
  }

  .option {
    &:hover {
      cursor: pointer;
      background-color: $background-ligh-gray-3;
    }

    width: 176px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    padding: 0px 15px;

    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
