@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.roleInputWrapper {
  .roleInputContainer {
    .clientRole {
      margin-top: 12px;
      position: relative;
      input {
        @include input-styles-important;
        @include input-styles;
        @include custom-small-input;
        z-index: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        color: $text-color;
        padding-left: 10px !important;
        height: 48px !important;
        width: 100%;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          @include input-styles-important;
          @include input-styles;
          color: $text-gray-color !important;
          padding: 10px 0 16px !important;
          margin-left: 10px;
        }
      }
    }

    .viewModeRole {
      input {
        cursor: default !important;
      }

      .arrowdownicon {
        display: none;
      }
    }

    .inputWrapper {
      margin: 0;
    }

    .inputLabel {
      @include inputLabelContainer;
      color: $text-color;
      margin-bottom: 0;
      font-weight: 600;
    }

    .arrowdownicon {
      right: 0.75rem !important;
      path {
        stroke: $text-gray-color !important;
      }
    }
  }
}
