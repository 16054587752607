@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.listWrapper {
  @include box-shadow;
  position: absolute;
  top: setUnit(44);
  right: 0;
  background: #ffffff;
  border-radius: setUnit(8);
  z-index: 1;
  ul {
    list-style: none;
    margin: 0;
    li {
      padding: setUnit(8) 1.6rem !important;
    }
  }
  .item {
    padding: setUnit(10) setUnit(15);

    &:hover {
      background: $background-ligh-gray;
    }
  }
}
