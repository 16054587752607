@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../styles.module';

.form {
  padding: 32px;
  .note {
    color: $dark-grey-color;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.18px;
    margin: 0;
    margin-top: 20px;
  }
  .suiteUnit {
    margin-top: 20px;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    .button {
      width: 163px;
    }
    .titleClassName {
      display: flex;
      justify-content: center;
      gap: 10px;
      min-width: 133px;
      span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.09px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.border {
  border-radius: 8px !important;
  .placeholder {
    border-radius: 8px !important;
  }
  input {
    border-radius: 8px !important;
  }
}

.dropdown {
  position: fixed !important;
  max-width: 435px;
  top: auto !important;
  @media (max-width: 500px) {
    max-width: 87vw;
  }
  @media (max-width: 400px) {
    max-width: 80vw;
  }
}
