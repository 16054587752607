@import 'styles/functions';
@import 'styles/variables';

.noPrefsWrapper {
  .noItemWrap {
    padding: setUnit(24) 0;
    .noItemContent {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      color: $text--light-gray-color;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .accordionTitle {
    font-weight: 600;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: setUnit(-0.18);
    color: $text-color;
    .count {
      color: $text--light-gray-color;
    }
  }
}
