@import 'styles/functions';
@import 'styles/variables';

.sort {
  width: setUnit(44);
  height: setUnit(44);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: $white;
  border: 2px solid $light-gray;

  &:hover {
    border-color: $text--light-gray-color;
  }

  .icon {
    transition: transform 0.15s;
    &.asc {
      transform: rotate(180deg);
    }

    &.desc {
      transform: rotate(0deg);
    }

    svg {
      vertical-align: middle;
    }
  }
}

.popup {
  padding: 0 !important;
  left: 10.75rem;

  .popupInner {
    padding: setUnit(20) setUnit(24) 0;
  }
}
