@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.offerStatus {
  height: setUnit(32);
  border-radius: setUnit(100);
  background: $background-ligh-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $white;
  font-size: setUnit(14);
  text-align: center;

  @media screen and (max-width: $tablet) {
    width: setUnit(110);
  }

  .statusText {
    font-size: 12px;
  }

  &.accepted {
    background: $green-2;
  }
  &.pending {
    background: $confetti-blue;
  }
  &.rejected {
    background: $error-color;
  }
  &.counterRecieved {
    background: $orange;
  }
  &.counterSent {
    background: $light-blue;
  }
  &.expired {
    background: $text--light-gray-color;
  }
  &.withdrawn {
    background: $text--light-gray-color;
  }
  &.new {
    background: $green-color;
  }
}
