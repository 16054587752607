@import 'styles/variables';

.ServiceBoxContainer {
  display: flex;
  width: 100%;
  height: 72px;
  align-items: center;
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin-bottom: 16px;

  &.isChecked {
    background-color: $primary-color-light;
    border-color: $primary-color;
  }

  .serviceCheckbox {
    margin: 10px 12px 0 20px;
  }

  .serviceSelector {
    display: flex;
    align-items: center;
    width: 292px;
    height: 48px;
    border: 1px solid $text-gray-color;
    border-radius: 8px;

    [class='ant-select-dropdown'] {
      z-index: 1;
      top: 52px;
      left: 0;
    }
  }
  .serviceTitle {
    font-weight: 400;
    line-height: 24px !important;
    letter-spacing: -0.09px;
    color: $dark-color;
    font-size: 18px;
    font-family: Inter;

    &.isChecked {
      font-weight: 500;
    }
  }

  .closeIcon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
  }
}

.servicesPopupContainer {
  & [class*='ant-select-item-option-content'] {
    width: 250px;
  }
}

.ellipsisOption {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0 !important;
}
