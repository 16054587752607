@import 'styles/functions';
@import 'styles/variables';

.card {
  display: flex;
  max-width: 100%;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .avatarWrapper {
    margin-right: 12px;
    .avatar {
      width: 32px;
      height: 32px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 100px;
      color: #ffffff;
    }
  }

  div[class~='ant-row'] {
    width: calc(100% - 138px);
  }

  .timestamp {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-gray-color;
    margin-bottom: 0;
  }

  .name {
    font-weight: 600;
    margin-right: 4px;
    width: 6rem;
  }

  .activity {
    display: flex;
    width: 26.75rem;
    margin-right: 0.75rem;
  }

  @keyframes open {
    from {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
    to {
      line-clamp: initial;
      -webkit-line-clamp: initial;
    }
  }

  @keyframes close {
    from {
      line-clamp: initial;
      -webkit-line-clamp: initial;
    }
    to {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
  }
}

/* Box */
.box {
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  &.open {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    .text {
      animation: open 0.1s linear 0s forwards;
    }
  }
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  animation: close 0.1s linear 0.1s forwards;
}
.expandableWrapper {
  margin-top: setUnit(8);
  margin-bottom: setUnit(14);
}
// #2B59DA;
.showHideBtn {
  color: #2b59da;
}
