@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  border-bottom: 2px solid $border-light-gray;
  padding-bottom: setUnit(28);
  margin-bottom: setUnit(32);
}

.sectionTitle {
  font-size: setUnit(16);
  line-height: setUnit(21);
  font-weight: 600;
  letter-spacing: -0.18px;
  margin-bottom: setUnit(16);
  color: $text-color;
}

.partnerDesc {
  font-weight: 400;
  font-size: setUnit(14);
  line-height: setUnit(18);
  letter-spacing: -0.18px;
  color: $text-color;
  background-color: $background-gray;
  padding: setUnit(12);
  border-radius: setUnit(8);
}

.profileWrapper {
  display: flex;
  margin: 0 setUnit(-20);
  .leftBlock {
    min-width: setUnit(152);
    padding: 0 setUnit(20);
    .updateAvatar {
      margin-bottom: 0;
      span {
        font-size: setUnit(14);
        display: block;
        margin-top: setUnit(16);
        text-align: center;
        transition: all 0.1s;
      }

      div {
        font-size: setUnit(28);
        width: setUnit(112);
        height: setUnit(112);
      }

      &:hover span {
        text-decoration: underline;
      }
    }

    img {
      width: setUnit(112);
      height: setUnit(112);
      border-radius: 50%;
    }
  }
  .rightBlock {
    width: 100%;
    padding: 0 setUnit(20);
    .name {
      font-weight: 600;
      font-size: setUnit(16);
      padding-bottom: setUnit(19);
    }
    .bio {
      padding-bottom: setUnit(32);
    }
  }
}

.saveBtn {
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  height: setUnit(44) !important;
  width: setUnit(78) !important;
  border-radius: setUnit(100) !important;
  border: 2px solid $secondary-color !important;
  @include transition;
  &:hover {
    background-color: transparent !important;
    color: $secondary-color;
  }
}

.editBtn {
  border: 0;
  outline: none;
  border-radius: 100%;
  width: setUnit(40);
  height: setUnit(40);
  background-color: $primary-color;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.label {
  font-size: setUnit(14);
  line-height: setUnit(16);
  font-weight: 400;
  letter-spacing: -0.09px;
  color: $dark-grey-color;
  margin-bottom: setUnit(8);
  @media (min-width: $mobile) {
    flex: 1;
    margin-bottom: 0;
  }
}

.editLabel {
  font-size: setUnit(14);
  font-weight: 400 !important;
  color: $dark-grey-color !important;
  line-height: setUnit(16);
  letter-spacing: -0.09px;
  margin-bottom: setUnit(8) !important;
  @media (min-width: $mobile) {
    flex: 1;
    margin-bottom: 0 !important;
  }
}

.inputMultiple {
  .editLabel {
    margin-bottom: setUnit(24);
  }
}

.inputBlock {
  display: flex;
  flex-wrap: wrap;
  min-height: setUnit(44);
  align-items: center;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: setUnit(16);
  }

  .rightBlock {
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding: 0;
    flex: 1 0 100%;
    @media (min-width: $mobile) {
      flex: 3;
    }
    .input {
      margin: 0;
    }
  }

  .infoBlockForOneLine {
    flex: 1 0 100%;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    font-weight: 400;
    letter-spacing: -0.18px;
    border-bottom: 2px solid $light-gray;
    padding-bottom: setUnit(12);
    @media (min-width: $mobile) {
      flex: 3;
    }
  }

  .infoBlockForMultipleLines {
    flex: 1 0 100%;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    border-bottom: 2px $light-gray solid;
    @media (min-width: $mobile) {
      flex: 3;
    }
  }

  .nonEditableValue {
    padding: setUnit(10) 0;

    &:not(:last-child) {
      margin-bottom: setUnit(2);
    }

    a {
      color: $text-color;
      transition: all 0.1s;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .locationsBlock {
    max-width: 75%;
    .inputFieldPlaceholder {
      height: 100%;
    }
  }
}

.subTitle {
  font-weight: 600;
  font-size: setUnit(20);
  line-height: setUnit(26);
  margin-bottom: setUnit(22);
  margin-top: 0;
  display: block;
}

.companyWrapper {
  margin-top: setUnit(40);
}

.links {
  margin-top: setUnit(26);
}

.linkInput {
  margin-bottom: setUnit(16);
}

.avatar {
  width: setUnit(120);
  height: setUnit(120);
  div {
    font-size: setUnit(28);
    width: setUnit(112);
    height: setUnit(112);
  }
}

@media screen and (max-width: $tablet) {
  .profileWrapper {
    flex-wrap: wrap;
  }

  .leftBlock {
    margin-left: auto;
    margin-right: auto;
  }
}

.areasOfOperationWrapper {
  margin-top: setUnit(40);
}

.updateAvatar {
  span {
    font-size: setUnit(14);
    display: block;
    margin-top: setUnit(16);
    text-align: center;
    transition: all 0.1s;
  }

  &:hover span {
    text-decoration: underline;
  }
}

.locationError {
  flex-basis: 100%;
  padding-left: 25%;
  display: flex;
}

.avatar {
  width: setUnit(120);
  height: setUnit(120);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eef0ef;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.brokerageWrap {
  margin-top: setUnit(24);
}
