@import 'styles/variables';
@import 'styles/functions';

.cancelToursModal {
  .modalBody {
    .title {
      font-size: setUnit(20);
      font-weight: 600;
      line-height: setUnit(26);
      letter-spacing: setUnit(-0.33);
      text-align: center;
      margin-bottom: setUnit(12);
    }
    .tourDetail {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(22);
      letter-spacing: setUnit(-0.33);
      text-align: center;
      margin-bottom: setUnit(24);
      span {
        display: block;
      }
    }
    .fieldWrap {
      margin-bottom: setUnit(32);
      label {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        margin-bottom: setUnit(12);
        display: block;
      }
      .input {
        border: 1px solid $border-light-gray-2;
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(21);
        letter-spacing: setUnit(-0.18);
        padding: setUnit(12) setUnit(16);
        height: setUnit(48);
        &::placeholder {
          color: $text--light-gray-color;
        }
        &:focus {
          box-shadow: none;
          outline: 0;
          border-color: $text-gray-color;
        }
      }
    }
    .modalActions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 setUnit(12);
      .button {
        flex: 0 0 calc(50% - 6px);
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        height: setUnit(44);
        border-radius: 8px;
        border-width: 0;
        &.submit {
          color: $white;
        }
        &.reject {
          color: $dark-color;
          background-color: $background-grey-2;
          &:focus,
          &:hover {
            background-color: $background-ligh-gray-4;
          }
        }
      }
    }
  }
}
