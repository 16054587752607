@import 'styles/functions';
@import 'styles/variables';
.wrapper {
  display: flex;
  flex-direction: column;
  gap: setUnit(10);
  .agentMatchScore {
    color: white;
    border-radius: setUnit(16);
    padding: setUnit(8) setUnit(10);
    height: setUnit(32);
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    text-align: center;
  }
  .loadingWrapper {
    border: 1px solid #d6d6d7;
    padding: setUnit(4) setUnit(12) setUnit(4) setUnit(8);
    border-radius: setUnit(16);
    height: setUnit(32);
    min-width: setUnit(54);
    .loader {
      margin: 0.6875rem;
      font-size: 2px;
    }
    .favMatch {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        display: inline-flex;
        height: setUnit(24);
        width: setUnit(24);
      }
      & > span {
        line-height: setUnit(16);
        font-size: setUnit(14);
        font-weight: 600;
      }
    }
  }
}
.greenProgress {
  background-color: $green-color;
}

.orangeProgress {
  background-color: $primary-color;
}

.grayProgress {
  background-color: $border-middle-gray;
}
