@import "styles/functions";
@import "styles/variables";

.messagesSubheader {
    padding: 0 0 setUnit(23);
    width: 100%;
    display: flex;
    align-items: center;
    height: setUnit(98);
    margin-top: setUnit(-12);

    .avatars {
        .avatarWrapper {
            margin-left: setUnit(-35);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .avatar {
        width: setUnit(56);
        height: setUnit(56);

        &.isNotSingle {
            width: setUnit(50);
            height: setUnit(50);
        }
    }

    .overflow {
        width: setUnit(50);
        height: setUnit(50);
    }
}
