@import "styles/functions";
@import "styles/variables";

.header {
    padding: setUnit(22) setUnit(32) setUnit(32);

    .allMessagesBtn {
        display: flex;
        align-items: center;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
        cursor: pointer;
        padding: setUnit(12) setUnit(16);
        border-radius: setUnit(20);
        border: 0;
        outline: none;
        background-color: transparent;
        transition: all .1s;
        position: relative;
        left: setUnit(-12);
        margin-bottom: setUnit(20);

        .arrow {
            margin-right: setUnit(10);
        }

        &:hover {
            background-color: $hover-light-gray;
        }
    }

    .titleWrapper {
        display: flex;
        align-items: center;

        .shareIcon {
            svg {
                width: setUnit(32);
                height: setUnit(32);

                path {
                    stroke: $primary-color;
                }
            }
        }

        svg {
            vertical-align: middle;
        }
    }
}
