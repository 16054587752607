@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.table {
  padding-bottom: 25px;
}

.actionBtn {
  position: relative;
  transition: all 0.1s;
  padding-top: 2px;
  float: inline-end;

  .actionBtnIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  &:hover {
    .actionBtnIcon {
      background-color: $light-grey-color;
    }

    .inactiveBtnIcon {
      background-color: $grey-color;
    }
  }
}

.popoverOverlay {
  width: setUnit(156);
  z-index: 99999 !important;
  div[class~='ant-popover-inner'] {
    border-radius: setUnit(8);
    div[class~='ant-popover-inner-content'] {
      padding: 0px;
      .actionList {
        position: absolute;
        // opacity: 0;
        // visibility: hidden;
        background-color: $white;
        width: setUnit(176);
        border-radius: setUnit(8);
        box-shadow: 0 2px setUnit(12) rgba(0, 0, 0, 0.15);
        right: 0;
        z-index: 99;
        overflow: hidden;

        // &.top {
        //   bottom: setUnit(35);
        // }

        // &.bottom {
        //   top: setUnit(45);
        // }

        .actionListBtn {
          width: 100%;
          display: flex;
          align-items: center;
          outline: none;
          border: 0;
          color: $text-color;
          font-size: setUnit(15);
          font-weight: 400;
          line-height: setUnit(16);
          padding: setUnit(15) setUnit(16);
          transition: all 0.1s;
          background-color: $white;
          text-align: left;
          cursor: pointer;

          .icon {
            padding-right: setUnit(8);
            height: setUnit(16);

            svg {
              width: setUnit(16);
              height: setUnit(16);
            }
          }

          &:hover {
            background-color: $hover-light-gray;
          }
        }
      }
    }
  }
}

.main {
  height: calc(100dvh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 40px;
  display: flex;
  justify-content: center;

  .portfolioContent {
    width: 100%;
    max-width: 1340px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    align-items: center;
    .heading {
      margin: 0;
      color: $text-color;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20.8px; /* 104% */
      letter-spacing: -0.18px;
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      .add {
        height: 44px;
        width: fit-content;
        padding: 12px 16px;
        border-radius: 100px;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row-reverse;
          gap: 4px;
        }
      }
      .archive {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: $transition;
        background: $white;
        border-radius: 100px;
        box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
        background: $white;
        &.active {
          background: $background-blue;
        }
        svg {
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .cards {
    padding: 32px;
    border-radius: 16px;
    background: $white;
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .row {
      display: flex;
      gap: 12px;
      align-items: center;
      .inputWrap {
        background: $white;
        border: 2px solid $light-gray;
        border-radius: setUnit(26);
        max-width: 344px;
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        &:focus {
          border-width: 2px;
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: 40px;
        }
      }
    }
  }

  .client {
    display: flex;
    gap: 8px;
    .avatarSmall {
      width: 24px;
      height: 24px;
      font-size: 10.286px;
      font-weight: 600;
      line-height: 13.714px;
      letter-spacing: -0.077px;
      text-transform: uppercase;
    }
  }
  .avatar {
    width: 32px;
    height: 32px;
    font-size: 13.714px;
    font-weight: 600;
    line-height: 18.286px;
    letter-spacing: -0.103px;
    text-transform: uppercase;
  }
  .text {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.33px;
    margin: 0;
  }
  .value {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.33px;
    margin: 0;
  }
  .propertyContainer {
    display: flex;
    gap: 12px;
    align-items: center;
    .iconContainer {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $background-grey-2;
      svg {
        position: relative;
        top: 2px;
      }
    }
    .textContainer {
      .line1 {
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.33px;
        margin: 0;
        line-height: 16px;
        margin-bottom: 4px;
      }
      .line2 {
        color: $text-gray-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.33px;
        margin: 0;
        line-height: 16px;
      }
    }
  }
}
