@import 'styles/functions';
@import 'styles/variables';

.commuteList {
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .details {
    display: flex;
    align-items: center;
    h4,
    p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
    h4 {
      color: $text-color;
    }
    p {
      color: $text-gray-color;
      font-weight: 400;
    }
  }
  svg {
    margin-right: 20px;
  }
  .dotsImage {
    display: block;
    cursor: pointer;
    height: 32px;
    width: 32px;
  }
}
.commutePopover {
  width: 156px;
  p {
    cursor: pointer;
  }
}

.addAnother {
  display: flex;
  margin: 24px 0 44px auto;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 130px;
  .addIcon {
    height: 24px;
  }
  span {
    margin-left: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.popoverOverlay {
  width: 196px;
  border-radius: 8px;
  padding: 0px !important;

  [class*='ant-popover-inner'] {
    border-radius: 8px;
  }

  [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }
}
