@import 'styles/functions';
@import 'styles/variables';

.subtitle {
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;

    .dueDate {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: 0;
    }
    .category {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin: 0;
    }
  }

  .separator {
    display: inline-block;
    width: setUnit(3);
    height: setUnit(3);
    border-radius: 50%;
    background: $border-middle-gray;
    margin: 0 setUnit(8);
  }
}
