@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.bundleFormsSection {
  max-height: 200px;
  overflow-y: auto;
  .formItem {
    padding: setUnit(18) setUnit(12);
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background: $background-ligh-gray;
      cursor: pointer;

      .itemContent {
        .formTitle {
          font-weight: 600;
          color: $text-color;
        }

        path {
          stroke: $dark-color;
        }
      }
    }

    .itemContent {
      display: flex;
      align-items: center;
      gap: 10px;

      .dragDotsIcon {
        cursor: grab;
      }

      .formTitle {
        margin-left: 2px;
        font-size: setUnit(16);
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18000000715255737px;
        text-align: left;
        color: $text-gray-color;
      }
    }
  }

  .selectedForm {
    background: $background-ligh-gray;
    cursor: pointer;

    .itemContent {
      .formTitle {
        font-weight: 600;
        color: $text-color;
      }

      path {
        stroke: $dark-color;
      }
    }
  }
}
