.templateCheckboxFields {
  .checkboxWrap {
    display: flex;
    flex-flow: column wrap;
    margin-top: 24px;
  }
}

.newTemplateCheckboxFields {
  margin: 28px 0px;

  .checkboxWrap {
    margin-top: 0px;
  }
}
