@import 'styles/functions';
@import 'styles/variables';

.point {
  cursor: pointer;
  border-radius: 50%;
  width: setUnit(32);
  height: setUnit(32);
  text-align: center;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;

  .inner {
    position: inherit;
    z-index: 1;
    background-color: $white;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    border: 2px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: setUnit(14);
    font-weight: 500;
  }

  //   &:after {
  //     content: '';
  //     height: calc(100% + 6px);
  //     width: setUnit(2);
  //     background: $background-gray;
  //   }

  .private {
    z-index: 2;
    position: relative;
    top: 1.15rem;
    left: -0.95rem;
  }
}
