@import 'styles/functions';
@import 'styles/variables';

$active-border-color: $primary-color;

.optionHolder {
  display: flex;
  .option {
    position: relative;
    max-width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 2px solid $border-input-middle-gray;
    padding: setUnit(15);
    cursor: pointer;
    text-align: center;
    line-height: setUnit(16);
    .edit {
      position: absolute;
      top: setUnit(5);
      right: setUnit(5);
      width: setUnit(25);
      height: setUnit(25);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: setUnit(20);
        height: setUnit(20);
        vertical-align: middle;
      }
    }

    .optionInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: setUnit(12);

      .icon {
        height: 75px;
        display: flex;
        align-items: center;

        svg {
          height: 48px;
          width: 48px;
        }
      }

      svg {
        vertical-align: middle;

        path,
        rect,
        circle {
          stroke: $dark-grey-color;
          stroke-width: 1.5px;
        }
      }

      .title {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $dark-grey-color;
      }
    }

    &:hover {
      background: #ffffff;
    }

    &.active {
      background: $primary-color-light;
      border: 1px solid $active-border-color !important;

      .labelActive {
        color: $text-color !important;
        font-weight: 500 !important;
      }

      svg {
        vertical-align: middle;

        mask {
          & + path {
            fill: $active-border-color;
          }
        }

        path,
        rect,
        circle {
          stroke: $active-border-color;
        }
      }

      &:hover {
        border: 1px solid $primary-color-hover !important;
        background-color: #ffebed !important;
      }
    }

    &:hover {
      border: 2px solid $text--light-gray-color !important;
    }
  }
}
