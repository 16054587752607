@import 'styles/functions';
@import 'styles/variables';

.searchModalWrapper {
  z-index: 999 !important;
}

.drawerInner {
  position: relative;
  margin-top: 24px;
  .tabs {
    justify-content: space-between;
    div[class~='ant-tabs-nav'] {
      &:before {
        content: none;
      }
      div[class~='ant-tabs-nav-wrap'] {
        margin: 0 setUnit(32);
        div[class~='ant-tabs-tab'] {
          padding: 18px;
        }
      }
    }
  }
}

.tabs {
  justify-content: space-between;
  border-bottom: 2px solid $light-gray;
  div[class~='ant-tabs-nav'] {
    &:before {
      content: none;
    }
    div[class~='ant-tabs-nav-wrap'] {
      margin: 0 setUnit(32);
      border-bottom: 2px solid $light-gray;
    }
  }
}

.opacityLayer {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 0.8) 0%,
    rgba(244, 245, 246, 0.4) 50%,
    rgba(244, 245, 246, 0.6) 75%,
    rgba(244, 245, 246, 0.9) 100%
  );
  backdrop-filter: blur(20px);
  background-color: transparent !important;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: 700px !important;
  max-height: 85vh !important;
  @media screen and (max-height: 1000px) {
    max-height: 95vh !important;
  }
}

.header {
  justify-content: center !important;
}

.back {
  position: absolute;
  top: -20px;
  left: 0;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .avatar {
    width: 72px;
    height: 72px;
    font-size: 16px;
  }
  .heading {
    color: $dark-gray-3;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.33px;
    margin-top: 12px;
  }
  .text {
    color: $text-gray-color;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
  }
}

.innerHolder {
  padding: 0 35px !important;
  overflow: hidden !important;
  @media screen and (max-width: 700px) {
    padding: 0 15px !important;
  }
}
