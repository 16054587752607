@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.buttonCheckbox {
  user-select: none;
  label[class~='ant-checkbox-wrapper'] {
    min-width: setUnit(68);
    span[class~='ant-checkbox'] {
      span[class~='ant-checkbox-inner'] {
        display: none;
      }
      & + span {
        color: $dark-color;
        padding: setUnit(11) setUnit(6);
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(22);
        letter-spacing: -0.09px;
        border: 2px solid $light-gray;
        border-radius: setUnit(8);
        background-color: transparent;
        display: block;
        width: 100%;
        text-align: center;
        @include transition;
      }
    }
    span[class~='ant-checkbox-checked'] {
      & + span {
        color: $text-color;
        font-weight: 500;
        border-color: $primary-color;
        background-color: #fff2f3;
      }
    }
  }
}
