@import 'styles/functions';
@import 'styles/variables';

.avatar {
  color: $white;
  font-size: setUnit(16) !important;
  line-height: setUnit(32) !important;
  font-weight: 600;
}

.defaultBackgroundColor {
  background-color: $secondary-color !important;
}
