@import 'styles/functions';
@import 'styles/variables';

.dueDate {
  display: flex;
  .closeIcon {
    display: flex;
    align-items: center;
    margin-left: setUnit(12);
    cursor: pointer;
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;
      }
    }
  }
  .fieldWrapper {
    padding: 0 0 0 24px;
    display: flex;
    height: setUnit(32);
    align-items: center;
    border-radius: setUnit(18);
    font-weight: 600;
    font-size: 18px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    input {
      font-weight: 400;
      font-size: 0.875rem !important;
      line-height: 1rem;
      letter-spacing: -0.18px;
      color: $text-color;
      height: 2.5rem !important;
    }

    input::placeholder {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      line-height: 1rem !important;
    }

    .label {
      font-size: 18px !important;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: 0;
      font-weight: 400;
    }

    &:hover {
      .editIcon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 1280px) {
    .fieldWrapper {
      padding-left: 7px !important;
      input {
        font-size: 13px !important;
      }
    }
  }

  .dateHolder {
    display: flex;
    align-items: center;

    span {
      padding-left: setUnit(6);
    }
  }

  .editIcon {
    margin-left: setUnit(8);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
  }
}

.taskDueDatePicker {
  display: flex;
  align-items: center;
  .input {
    padding: 0rem !important;
    border-bottom: 0;
  }

  .templateControlPicker {
    width: 2rem !important;
    height: 1.5rem;
    display: flex;
    .datePickerInput {
      height: 2rem !important;
      padding-bottom: 0.75rem !important;
    }
    svg[class~='icon'] {
      top: calc(50% - 16px);
    }
  }

  .errorMsg {
    position: absolute;
    bottom: -1.3rem;
    right: 0rem;
    width: fit-content;
    color: $error-color;
  }

  .loader {
    position: absolute;
    right: -1.5rem;
  }

  .spinner {
    font-size: 18px;
    margin-left: 10px;
    color: $light-gray-2;
  }
}

.viewTaskDueDatePickerPopover {
  [class*='ant-popover-content'] {
    top: 2.3rem;
    left: 6rem;

    [class*='ant-popover-inner'] {
      background: $white;
      box-shadow: unset;

      [class*='ant-popover-inner-content'] {
        padding-left: 0;
      }
    }
  }
}

.flatpickr-calendar {
  margin-left: -1rem;
}
