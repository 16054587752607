@import 'styles/functions';
@import 'styles/variables';

.addCommute {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  height: setUnit(64);
  .icon {
    margin-right: setUnit(12);
    display: flex;
    svg {
      width: setUnit(20);
      height: setUnit(20);
    }
  }
  .text {
    font-weight: 500;
    font-size: setUnit(16);
    line-height: setUnit(20);
    letter-spacing: setUnit(-0.18);
  }
}
