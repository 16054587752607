@import 'styles/functions';
@import 'styles/variables';

.profile {
  .isPreApprovedWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    p {
      margin: 0 !important;
    }
  }
  .profileAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .actions {
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    gap: 0 8px;
    .messageButton {
      cursor: pointer;
      display: flex;
      font-size: 14px;
      border: 2px solid $light-gray;
      border-radius: 24px !important;
      color: $dark-color;
      justify-content: center;
      align-items: center;
      padding: 0.45rem 0.75rem;
      height: 44px;
      &:hover {
        border: 2px solid $border-light-gray-2;
      }

      span {
        margin-left: 6px;
        font-weight: 500;
        color: $dark-color;
      }
    }
  }

  .name {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: setUnit(20);
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0;

    span {
      position: relative;
    }

    &:hover .hoverEdit {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 1;
      width: 20px;
      height: 20px;
      margin-left: 12px;
      position: absolute;
      right: -32px;
      top: 0;
      cursor: pointer;
    }

    .hoverEdit {
      display: none;
    }
  }
  .agentTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    span {
      margin-bottom: 0;
    }
    .editIcon {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
  .joinedIn {
    color: $text-gray-color;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .profileTabs {
    display: flex;

    & > div {
      width: 100%;
      padding: setUnit(14) 0;
      text-align: center;
      border-top: 1px solid $border-light-gray;
      border-left: 1px solid $border-light-gray;
      margin-right: 0;
    }
  }
  .clientLoading {
    padding: 16px;
  }
}

.agentTitleContent {
  text-align: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
  }
}
