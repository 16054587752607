@import 'styles/variables';
@import 'styles/functions';

.uploadIcon {
  rect {
    fill: transparent;
  }

  path {
    stroke: $text-color;
  }
}
.mobile {
  display: none;
}
@media (max-width: $mobile) {
  .mobile {
    display: block;
  }
  .mobileCenter {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .desktop {
    display: none;
  }
  .actions {
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border: 2px solid #e8e9ea;
    background: white;
  }
}

.mainActions {
  background-color: white;
  border: 2px solid #e8e9ea;
  border-radius: 50%;
}

.offers {
  padding: 32px 40px;
  .header {
    .headerTopRow {
      min-height: setUnit(44);
      .backBtn {
        border-radius: setUnit(100);
        width: 44px;
        height: setUnit(44);
        margin-left: 0;
        padding: 10px;
        border: 2px solid $light-gray;
        transition: $transition;
        &:hover {
          border-color: $text--light-gray-color;
          opacity: 1;
        }
        &:focus {
          border-color: $text-gray-color;
        }
        .icon {
          margin-right: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 8px;
            height: 14px;
            path {
              stroke: $dark-color;
            }
          }
        }
      }

      .pageTitle {
        font-weight: 700;
        font-size: 2rem;
        @media (max-width: $mobile) {
          font-size: 1rem;
        }
        line-height: 36px;
        color: $text-color;
        display: inline-block;
        margin-bottom: 0;
      }

      .actions {
        float: right;
        top: 0;
        right: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media print {
          display: none;
        }

        .iconBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: setUnit(44);
          width: setUnit(44);
          gap: 6px;
          cursor: pointer;
          border: setUnit(2) solid $background-ligh-gray-4;
          border-radius: setUnit(100);
          margin-left: setUnit(8);

          [class*='anticon-eye'] {
            margin-left: 0;

            svg {
              width: 24px;
              height: 22px;
            }
          }

          .eyeIconInvisible {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #262626;
            width: 100%;
            height: 100%;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .iconTxtBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 16px;
          height: setUnit(44);
          gap: 6px;
          cursor: pointer;
          border: setUnit(2) solid $background-ligh-gray-4;
          border-radius: setUnit(100);
        }
      }
    }

    .pageSubTitle {
      text-align: center;
      font-weight: 600;
      font-size: setUnit(18);

      [class*='anticon'] {
        font-size: setUnit(14);
        margin-left: setUnit(8);
      }
    }
  }

  .pageTitleAlign {
    text-align: center;
  }

  .eyeInvisible {
    width: 10px;
    height: 10px;
  }

  .uploadCon {
    .conRow {
      .conCol {
        margin: auto;
      }
    }
  }
  .compareTableParent {
    overflow: hidden;

    .compareTable {
      @media (max-width: $tablet-horizontal) {
        overflow-y: auto;
        &::-webkit-scrollbar {
          height: 8px;
        }
      }

      .cTRow {
        flex-wrap: nowrap;

        &:nth-child(1) {
          .cTCol {
            border-bottom: 2px solid $background-ligh-gray-4;
            height: setUnit(72);
            font-size: setUnit(18);
            @media (max-width: $mobile) {
              font-size: setUnit(14);
            }
            &:not(:first-child) {
              font-weight: 700;
            }

            &:nth-child(even) {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }

        &:last-child {
          .cTCol {
            &:nth-child(even) {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }

        .cTCol {
          padding: setUnit(28) setUnit(16) !important;

          .contentBold {
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
          }

          .content {
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
          }

          &:nth-child(even) {
            background-color: $background-grey-2;
          }

          .actionBtn {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: setUnit(100);
            padding: 0.3rem;
            margin-right: 0.75rem;
            display: inline-block;
            margin-top: setUnit(-10);
            cursor: pointer;

            &:hover {
              background-color: $grey-color;
            }

            &:last-child {
              margin-right: 0;
            }

            span {
              font-weight: 500;
            }

            rect {
              fill: transparent;
            }
          }

          .arrowRight {
            height: setUnit(24);
            width: setUnit(24);
            border-radius: setUnit(100);
            background-color: $background-grey-2;
            padding: setUnit(4);
            display: inline;
            float: right;
            cursor: pointer;
          }

          .negative {
            color: $primary-color;
          }

          .positive {
            color: $green-color;
          }
        }

        .status {
          font-size: setUnit(16);
        }

        &:nth-child(1) {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .overflowCompareTable {
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 8px;
      }
    }
  }

  .uploadIcon {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
  }
}

.seperator {
  margin-right: 5px;
}

.document {
  margin-right: 5px;
  display: block;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.compareOfferOptionPopover {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.compareOfferPopover {
  [class~='ant-popover-inner'] {
    border-radius: setUnit(8);
    [class~='ant-popover-inner-content'] {
      padding: 0;
      .offerMenu {
        border-right: none;
        border-radius: setUnit(8);
        overflow: hidden;
        li {
          margin: 0;
          padding: 12px 14px;
          height: 44px;
          background-color: #fff;
          transition: $transition;
          &:hover {
            background-color: $background-grey-2;
            .menuIcon {
              span,
              a {
                color: $text-color;
              }
            }
          }
        }
        .menuIcon {
          display: flex;
          align-items: center;
          svg {
            margin-right: 8px;
          }
          span,
          a {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.09px;
            color: $text-color;
          }
        }
      }
    }
  }
}
