@import 'styles/functions';
@import 'styles/variables';

.values {
  margin-top: setUnit(16);

  .value {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: setUnit(6) setUnit(25) setUnit(6) setUnit(12);
    border: 1px solid #d6d6d6;
    border-radius: setUnit(30);
    background: $background-ligh-gray;
    font-size: setUnit(14);
    font-weight: 400;
    margin-right: setUnit(8);
    margin-bottom: setUnit(5);
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      position: absolute;
      right: setUnit(3);
      top: setUnit(4);
      cursor: pointer;

      svg {
        vertical-align: middle;
        width: setUnit(20);
        height: setUnit(20);

        path {
          fill: $border-middle-gray;
        }
      }
    }
  }
}
