@import 'styles/functions';
@import 'styles/variables';

.feedSubFilters {
  max-width: 100%;
  width: 100%;
  .subfeedSlider {
    position: relative;
    margin: setUnit(4) setUnit(16) setUnit(16);
    div[class~='slick-list'] {
      overflow: hidden;
      div[class~='slick-track'] {
        display: flex;
        gap: 0 8px;
      }
    }

    div[class~='slick-arrow'] {
      opacity: 1;
      visibility: visible;
      width: setUnit(40);
      height: setUnit(40);
      border-radius: setUnit(100);
      background-color: $white;
      border: 2px solid $light-gray;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      .icon {
        display: flex;
        svg {
          width: setUnit(16);
          height: setUnit(16);
          path {
            stroke: $dark-color;
          }
        }
      }
    }
    div[class~='slick-prev'] {
      left: 0;
    }
    div[class~='slick-next'] {
      right: 0;
      svg {
        transform: scaleX(-1);
      }
    }
    div[class~='slick-disabled'] {
      opacity: 0;
      visibility: hidden;
      display: none;
      transition: $transition;
    }
    .buttonCheckbox {
      position: relative;
      display: inline-flex !important;
      justify-content: center;
      margin-right: 0;
      span[class~='ant-radio'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $background-grey-2;
        border-radius: setUnit(18);
        transition: $transition;
        span[class~='ant-radio-inner'] {
          display: none;
        }
        & + span {
          position: relative;
          z-index: 1;
          color: $dark-grey-color;
          font-size: setUnit(12);
          font-weight: 500;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.09);
          padding: setUnit(8) setUnit(14);
          display: block;
          transition: $transition;
        }
      }
      span[class~='ant-radio-checked'] {
        background-color: $background-blue;
        & + span {
          color: $white;
        }
        &:after {
          content: none;
        }
      }
      &:hover {
        span[class~='ant-radio'] {
          background-color: $background-ligh-gray-4;
          & + span {
            color: $dark-grey-color;
          }
        }
      }
    }
  }
}
