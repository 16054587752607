@import 'styles/functions';
@import 'styles/variables';

.item {
  margin-bottom: setUnit(30);

  .tagOptions {
    max-height: setUnit(110) !important;
  }

  .innerTop {
    display: flex;
    align-items: center;
  }

  .innerBottom {
    display: flex;
    align-items: center;
    margin-left: setUnit(72);

    .tagInput {
      max-width: setUnit(240);
    }
    .tagSelect {
      [class*='ant-select-selection-item'] {
        display: inline-block !important;
      }
      [class~='ant-select-focused'] {
        border: 1px solid $text-gray-color !important;
      }
      [class~='ant-select-selection-placeholder'] {
        color: $text-gray-color !important;
        padding: 17px 15px 16px 0px !important;
      }
      [class~='ant-select-selector'] {
        border-radius: 8px !important;
        padding: setUnit(8) setUnit(13) !important;
        outline: none;
        margin: 0;
        vertical-align: middle;
        color: $text-color;
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: -0.09px;
        height: setUnit(48);
        border: 0;
        border: 1px solid $light-grey-color !important;
        transition: $transition;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          color: $text-gray-color !important;
          font-weight: 400;
          font-size: 1rem !important;
        }
      }
      [class~='ant-select-arrow'] {
        right: 0.7rem !important;
        color: $text-gray-color !important;
      }
      [class~='ant-select-selection-search'] {
        padding-left: 13px !important;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .filename {
    flex: 1;
    padding-left: setUnit(40);
    font-weight: 600;
  }

  .fileSize {
    display: inline-block;
    font-size: setUnit(14);
    margin-left: setUnit(12);
    color: $border-middle-gray;
  }

  .icon {
    min-width: setUnit(56);
    height: setUnit(56);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $background-ligh-gray;
    margin-right: setUnit(16);
  }

  .input {
    margin-right: setUnit(25) !important;
  }

  .iconDelete {
    cursor: pointer;
    transition: transform 0.1s ease;
    margin-right: setUnit(5);

    &:hover {
      transform: scale(1.1);
    }
  }
}
