@import 'styles/variables';
@import 'styles/functions';

.days-from-today-plugin {
  .flatpickr-months {
    position: relative;
  }

  .flatpickr-months,
  .flatpickr-month {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .flatpickr-current-month input.cur-year[disabled] {
    color: $text-color !important;
  }
}

.flatpickr-calendar {
  padding: setUnit(12) 0;
  background-color: white;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08), 0px 32px 80px 0px rgba(0, 0, 0, 0.06);

  border-radius: 8px;
  $width: 376px;
  width: $width;
  min-height: 421px;

  &:after,
  &:before {
    border: none;
  }

  .flatpickr-innerContainer {
    border: none;
    border-radius: setUnit(8);
    max-height: setUnit(288px);
    .flatpickr-rContainer,
    .flatpickr-days,
    .dayContainer {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .flatpickr-day {
      $dayWidth: calc(($width - 24px * 2) / 7);
      max-width: $dayWidth;
      width: $dayWidth;
      height: $dayWidth;
      line-height: $dayWidth;
    }
  }

  .dayContainer {
    padding: 0 setUnit(24) 0 setUnit(24);
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    position: unset;
  }

  .flatpickr-current-month {
    display: flex;
    align-items: center;
    justify-content: center;

    .numInputWrapper {
      margin-left: 8px;
    }
  }

  .flatpickr-month {
    color: $text-color;
    background-color: white;
    .cur-year {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
    }
    .cur-month {
      @extend .cur-year;
      margin-left: 2.5ch;
    }
  }

  .flatpickr-monthDropdown-month {
    color: $text-color;
    background-color: white !important;
  }

  .flatpickr-day {
    color: $text-color;
    font-size: setUnit(16);
    border: none;

    &:hover {
      color: $text-color;
      border-color: $background-ligh-gray;
      background-color: $background-ligh-gray;
    }

    &.selected {
      font-weight: 700;
      background-color: $secondary-color;
      color: white !important;
      border-radius: 50%;
      border-color: unset !important;
      &:hover {
        border-color: white;
      }
    }

    &.nextMonthDay {
      color: $border-middle-gray;
    }

    &.today {
      font-weight: 700;
      color: $secondary-color;
      border: none;
    }
  }

  .flatpickr-monthDropdown-months {
    background-color: white;

    &:hover {
      color: $text-color;
      background-color: $background-ligh-gray !important;
    }
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  .flatpickr-next-month {
    padding-right: setUnit(24);
  }

  .flatpickr-prev-month {
    padding-left: setUnit(24);
  }

  .flatpickr-days {
    border: none;
  }

  .flatpickr-weekdays {
    height: setUnit(48);
    padding: 0 setUnit(24);
  }

  .flatpickr-weekdaycontainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flatpickr-weekdays,
  .flatpickr-weekday {
    background-color: white;
    color: $border-middle-gray;
    font-weight: normal;
  }

  .flatpickr-day.flatpickr-disabled {
    color: $border-middle-gray;
  }
}
