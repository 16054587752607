@import 'styles/functions';
@import 'styles/variables';

.performedBy {
  background: $primary-color-light;
  padding: 0.75rem 1.5rem;
  text-align: center;
  border-radius: 0.5rem;
  margin-top: 1.5rem;

  p,
  span {
    color: $primary-color;
    font-weight: 500;
    line-height: 21px;
    margin: 0;
  }

  span {
    font-weight: 600;
  }
}
