@import 'styles/variables';
@import '../../mixins.module.scss';

.errorModal {
  .headerTitle {
    display: flex;
    .headerText {
      font-family: $font-family;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-left: 14px;
    }
  }
  .errorIcon {
    display: flex;
    align-items: center;
    color: $error-color;
    font-size: 24px;
    line-height: 32px;
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & [class*='ant-typography'] {
    text-align: center;
    font-weight: 600;
    margin: 0px;
    overflow: auto !important;
  }

  .listItems {
    padding: 20px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 500;
  }
}
