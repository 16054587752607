@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.searchWrapper {
  position: relative;
  border: none;
  border-radius: 0.5rem;
  width: 100%;

  &.light,
  &.lightRound {
    label {
      font-size: 12px !important;
      line-height: 1rem !important;
      color: $text-gray-color !important;
      font-weight: 400 !important;
      margin-bottom: 0.5rem !important;
    }
  }
}

.block {
  position: relative;
  display: flex;
  align-items: center;
}
.customLocationToggleContainer {
  display: flex;
  justify-content: space-between;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.valuesWrapper {
  cursor: text;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background: $white;
  transition: $transition;
  .geocoder {
    position: absolute;
    top: 0;
  }
  &.hasIcon {
    .geoTagIcon {
      position: absolute;
      top: 44%;
      transform: translateY(-50%);
      left: 0;
    }
    .placeholder {
      padding-left: 42px !important;
    }
    .valuesHasIcon {
      padding-left: 36px;
    }
  }
  &.hasSuffix {
    .suffixIcon {
      position: absolute;
      top: 44%;
      right: 0px;
      transform: translateY(-50%);
      & > button {
        border-radius: 12px !important;
        height: 44px;
        width: 44px;
      }
    }
  }

  &.square {
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;
    & > svg,
    & > [class*='geoTagIcon'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      z-index: 97;
    }
    & > [class*='suffixIcon'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.focus {
      .placeholder {
        border-color: $text-gray-color;
      }
    }
    .placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: setUnit(-0.09);
      color: $border-middle-gray;
      width: 100%;
      border: 1px solid $border-light-gray-2;
      height: 48px;
      padding: 10px 16px 10px 44px !important;
      border-radius: 8px;
      transition: $transition;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.rounded {
      .round {
        border-radius: 8px !important;
      }
    }
    &.enableDraw {
      .round {
        border-radius: 8px 0 0 8px !important;
      }
    }
    .round {
      border-bottom: 0;
      border: 1px solid $border-light-gray-2;
      height: 48px;
      padding: 0 16px 0 44px;
      border-radius: 8px;
    }
  }

  &.light,
  &.lightRound {
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;

    &.focus {
      background: $white;
    }

    .placeholder {
      font-size: setUnit(16);
      line-height: setUnit(24);
      letter-spacing: setUnit(-0.09);
      color: $border-middle-gray;
      padding: setUnit(12) 0 setUnit(16);
      width: 100%;
      border-bottom: 2px solid $border-input-middle-gray;
      height: 52px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .searchIcon {
      position: relative;
      top: setUnit(-1);
      z-index: 1;
      margin-right: 0.625;

      svg {
        vertical-align: middle;
      }
    }
  }

  &.round {
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;
    & > svg,
    & > [class*='geoTagIcon'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      z-index: 97;
    }
    &.focus {
      .placeholder {
        border-color: $text-gray-color;
      }
    }
    .placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: setUnit(-0.09);
      color: $border-middle-gray;
      width: 100%;
      border: 1px solid $border-light-gray-2;
      height: 48px;
      padding: 10px 16px 10px 44px !important;
      border-radius: 100px;
      transition: $transition;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.rounded {
      .round {
        border-radius: 8px !important;
      }
    }
    &.enableDraw {
      .round {
        border-radius: 8px 0 0 8px !important;
      }
    }
    .round {
      border-bottom: 0;
      border: 1px solid $border-light-gray-2;
      height: 48px;
      padding: 0 16px 0 44px;
      border-radius: 100px;
    }
  }

  &.lightFull {
    padding: 0.375rem 0.375rem 0.375rem 0.9375rem;
    border-bottom: 2px solid $border-light-gray-2;
    border-radius: setUnit(0);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;

    &.focus {
      background: $white;
    }

    .placeholder {
      font-size: setUnit(16);
      line-height: setUnit(24);
      letter-spacing: setUnit(-0.09);
      color: $border-middle-gray;
      padding: setUnit(12) 0 setUnit(16);
      width: 100%;
      border-bottom: 2px solid $border-input-middle-gray;
      height: 52px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .searchIcon {
      position: relative;
      top: setUnit(-1);
      z-index: 1;
      margin-right: 0.625rem;

      svg {
        vertical-align: middle;
      }
    }
  }

  &.full {
    height: 3.5rem;
    border-radius: 0.5rem;
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    transition: $transition;
    font-weight: 400;

    &:focus {
      background: $white;
    }

    &::placeholder {
      font-weight: 400;
      color: $border-middle-gray;
    }

    .placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: $border-middle-gray;
      padding: 12px 0 16px;
      width: 100%;
      border-bottom: 2px solid $border-input-middle-gray;
      height: 52px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .searchIcon {
      position: relative;
      top: -1px;
      z-index: 1;
      margin-right: 0.625rem;

      svg {
        vertical-align: middle;
      }
    }
    .hasSuffix {
      position: absolute;
      right: 0;
      transform: translateY(-50%);
      top: 44%;
    }
    & > svg,
    & > [class*='geoTagIcon'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      z-index: 97;
    }
    & > [class*='suffixIcon'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .geoTagIcon {
    display: flex;
    align-items: center;
  }
}

.error {
  @include inputError;
}

.toggleButton {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 48px;
  background-color: #ffffff;
  margin: setUnit(0);
  padding: 3px 10px;
  border: 1px solid #d6d6d7 !important;
  border-radius: 0 8px 8px 0;
  border-left: 0;
  cursor: pointer;
  .draw {
    color: $dark-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.33px;
    margin: 0;
  }
  &.active {
    .draw {
      color: $primary-color;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
}
