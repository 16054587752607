@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  height: calc(100dvh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .content {
    width: 100%;
    max-width: 1340px;
  }
}
