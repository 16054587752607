@import 'styles/functions';
@import 'styles/variables';

.suggestion {
  display: flex;
  align-items: center;
  padding: setUnit(10);
  cursor: pointer;
  font-weight: normal;

  &.active {
    background: $background-ligh-gray;
  }

  &:first-child {
    padding-top: setUnit(15);
  }

  &:last-child {
    padding-bottom: setUnit(15);
  }

  .icon {
    margin-right: setUnit(10);
    display: flex;
    align-items: center;
    svg {
      vertical-align: middle;
    }
  }
  .description {
    text-align: left;
  }
}
