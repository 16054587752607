@import 'styles/functions';
@import 'styles/variables';

.preApprovalsUploadModal {
  max-width: 675px !important;
  width: 100% !important;
  [class~='ant-modal-content'] {
    padding: 36px 40px 40px;
    [class~='ant-modal-close'] {
      position: relative;
      top: unset;
      right: unset;
      display: block;
      margin-left: auto;
    }
  }
}
