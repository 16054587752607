@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  .question {
    margin-bottom: 0;
  }
  .button {
    width: 100%;
    height: 52px !important;
    border-radius: 8px !important;
  }
}
