@import "styles/functions";
@import "styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - #{setUnit(72)});
    width: 100%;

    .scrollWrapper {
        flex: 1;
        overflow: auto;
    }

    .client {
        padding: setUnit(16) setUnit(32) setUnit(22) setUnit(32);
        display: flex;
        align-items: center;
        border-bottom: 2px solid $light-gray;

        .clientInfoTitle {
            font-weight: 600;
            font-size: setUnit(20);
            line-height: setUnit(26);
            margin-bottom: setUnit(4);
            color: $text-color;
            position: relative;

            .clientInfoTitleLabel {
                padding: setUnit(3) setUnit(6);
                border-radius: setUnit(20);
                color: $white;
                margin-left: setUnit(12);
                background-color: $background-blue;
                font-weight: 500;
                font-size: setUnit(14);
                line-height: setUnit(16);
                position: absolute;
                margin-top: 2px;
            }
        }

        .clientInfoRole {
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $text-gray-color;
        }

        .clientAvatar {
            overflow: hidden;
            width: setUnit(80);
            height: setUnit(80);
            border-radius: 50%;
            margin-right: setUnit(16);

            img {
                width: setUnit(80);
                height: setUnit(80);
            }
        }
    }

    .block {
        .blockTitle {
            color: $text-color;
            font-weight: 600;
            font-size: setUnit(16);
            line-height: setUnit(21);
            border-bottom: 1px solid $light-gray;
            padding: setUnit(28) 0 setUnit(16) 0;
            margin: 0 setUnit(32);
        }
    }
}
