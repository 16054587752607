@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.documentsFilterPopover {
  padding-top: 0;

  & [class*='ant-popover-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(12);
  }

  .filterHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: setUnit(24);

    .filterIcon {
      width: setUnit(18);
      height: setUnit(16);
      margin-right: setUnit(8);
    }

    .titleText {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }

  .rangePicker {
    border: none !important;
    border-bottom: 2px solid $border-input-middle-gray !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  .resetText {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }

  .separator {
    color: $text--light-gray-color;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

.filterPopoverContainer {
  max-width: setUnit(316);
  width: 100%;

  .documentsMultiselect {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    .documentsDrawerMultiselect {
      & [class~='ant-select-selector'] {
        min-height: setUnit(40);
        height: auto;
        border: unset !important;
        border-bottom: 2px solid $border-input-middle-gray !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }
}

.submitButton {
  width: setUnit(81) !important;
  margin-left: auto;
  height: setUnit(40) !important;
  float: right;
}

.formItem {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.resetButton {
  width: setUnit(44) !important;
  margin-left: auto;
  height: setUnit(40) !important;
  border: none;
  padding: 0;
  text-align: left;

  &:hover {
    color: $hover-blue;
  }
}

.filterBtn {
  width: setUnit(40);
  height: setUnit(40);
  background-color: $background-grey-2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(100);
  cursor: pointer;
  @include transition;

  &:hover {
    background-color: $light-gray;
  }

  .filterIcon {
    path {
      stroke: $text-color;
    }
  }
}
