@import 'styles/functions';
@import 'styles/variables';

.inviteCheckbox {
  display: flex;
  margin-right: 8px;
  margin-bottom: -10px;
  margin-top: 19px;
}

.inviteCheckboxLabel {
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.00563rem;
  color: $text-color;
}
