@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  input {
    padding: 0px !important;
  }
  input,
  textarea {
    &::placeholder {
      color: $text--light-gray-color !important;
      font-weight: 400;
    }
    border-bottom: 2px solid $light-gray !important;
    &:focus {
      border-bottom: 2px solid $text--light-gray-color !important;
    }
  }
}

.inputsWrapper {
  padding: setUnit(24);
  overflow: auto;
  background-color: $white;
  border-radius: 16px;
  margin-top: 24px;
  box-shadow: 0px 2px 16px 0px #30303008;

  .marginBottom {
    margin-bottom: 28px;
  }

  .formTitleWrapper {
    display: flex;
    justify-content: space-between;

    .formTitle {
      display: flex;
      align-items: center;
      gap: 16px;
      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin: 0;
      }
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-gray-color;
      }
    }
  }
}

.inputBlock {
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(30);

  input {
    border: 1px solid black;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 12px;
  }
  .tagInput {
    width: 100%;
  }

  &.description {
    align-items: end;

    .label {
      color: $dark-color;
      margin-top: setUnit(25);
    }
  }
}

.checkboxBlock {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: setUnit(40);
  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 16px;
  }
  .checkboxArea {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    display: inline-flex;
    align-items: center;
  }
}

.inputHolder {
  input {
    height: setUnit(44) !important;
  }
}

.offsetHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    padding-left: setUnit(40) !important;
    height: setUnit(44);
  }
}

.offsetIcon {
  position: absolute;
  left: 0;

  svg {
    vertical-align: middle;
    width: setUnit(32) !important;
    height: setUnit(32) !important;
  }
}

.description {
  height: auto;
}

.label {
  white-space: nowrap;
}

.links,
.documents {
  min-height: setUnit(44);
  height: auto;
}

.documents {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    color: $dark-color;
    margin-left: 0;
  }
}

.input {
  width: 100%;
  padding: 0px;
}

.phone {
  margin: 0 !important;
  .inputPhone {
    height: 44px;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .selectedDateTime {
    background-color: $white;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 16px 0px #30303008;

    .dateTime {
      display: flex;
      gap: 16px;
      align-items: center;

      svg {
        height: 40px;
        width: 40px;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-color;
        margin: 0;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.18px;
        font-weight: 400;
        color: $text-gray-color;
        margin: 0;
      }
    }
  }
  .calendarEditIcon {
    cursor: pointer;
  }

  .appointmentDocuments {
    box-shadow: 0px 2px 16px 0px #30303008;
    padding: 24px;
    background-color: $white;
    border-radius: 16px;
    margin-top: 24px;

    .docTitleWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;

      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-color;
        margin: 0;
      }
    }
  }
}

.uploadDocument {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin-top: setUnit(5);

  .icon {
    width: setUnit(32);
    height: setUnit(32);
    background: $background-ligh-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: setUnit(16);

    svg {
      vertical-align: middle;

      path {
        stroke: $secondary-color;
      }
    }
  }

  .text {
    color: $secondary-color;
    font-size: setUnit(14);
  }

  .fileNew {
    display: flex;
    align-items: center;
    margin-top: setUnit(20);
    cursor: pointer;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 25px;
    background-color: $background-ligh-gray-2;

    .uploadFileText {
      font-size: setUnit(14);
      margin-left: setUnit(11);
      color: $secondary-color;
    }

    .uploadFileIcon {
      width: setUnit(18);
      height: setUnit(18);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fileNew:hover {
    transition: all 0.1s;
    background-color: $background-ligh-gray;
  }
}

.formFooter {
  border-top: 1px solid $light-gray;
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .continueBtn {
    border: none;
    outline: none;
    background-color: $background-blue;
    color: $white;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.1s;
    padding: 22px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .continueBtn:hover {
    background-color: $secondary-color-hover;
    color: $white;
  }
}
