@import 'styles/functions';
@import 'styles/variables';

.formModal {
  .title {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
  }
}
