@import 'styles/functions';
@import 'styles/variables';

.actions {
  display: flex;
  align-items: center;

  .action {
    width: setUnit(32);
    height: setUnit(32);
    background: $background-grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    svg {
      path {
        stroke: $dark-color;
      }
    }

    &:last-of-type {
      margin-left: 0.75rem;
    }

    &:hover {
      background: $light-gray;
    }
  }
}
