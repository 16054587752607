@import 'styles/functions';
@import 'styles/variables';

.emptyKit {
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: setUnit(14);
  line-height: setUnit(20);
  font-family: $font-family;
  letter-spacing: setUnit(-0.33);
  color: $text--light-gray-color;
  background-color: #fff;
}
