@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parkingsSelector {
  padding-left: setUnit(48);
  padding-bottom: setUnit(20);
  .counter {
    border: 1px solid $border-light-gray-2;
    border-radius: setUnit(8);
    justify-content: space-between;
    margin-bottom: setUnit(20);
    .counterHolder {
      width: 100%;
      justify-content: space-between;
      .number {
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        text-align: center;
      }
      .action {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: setUnit(12);
        transition: $transition;
        svg {
          width: setUnit(32);
          height: setUnit(32);
        }
        &:hover {
          background-color: $border-light-gray-2;
        }
        &.add {
          border-left: 1px solid $border-light-gray-2;
          border-top-right-radius: setUnit(8);
          border-bottom-right-radius: setUnit(8);
        }
        &.remove {
          border-right: 1px solid $border-light-gray-2;
          border-top-left-radius: setUnit(8);
          border-bottom-left-radius: setUnit(8);
        }
      }
    }
  }
  .additionalOptions {
    display: flex;
    flex-wrap: wrap;
    .checkbox {
      flex: 0 0 100%;
      margin-left: 0;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: setUnit(20);
      }
      span[class~='ant-checkbox'] {
        order: 2;
        & + span {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.09);
          padding: 0;
        }
      }
    }
  }
}
