@import 'styles/functions';
@import 'styles/variables';

$active-border-color: $primary-color;

.option {
  $border-radius: setUnit(16);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-input-middle-gray;
  padding: setUnit(6) setUnit(20);
  cursor: pointer;
  @media (max-width: $mobile-small) {
    padding: setUnit(15) setUnit(20);
  }
  .optionInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: $transition;
    min-height: setUnit(118);
    .title {
      margin-bottom: 0;
      text-align: center;
      transition: $transition;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.transactionCreation {
    .icon {
      svg {
        width: setUnit(56);
        height: setUnit(56);
      }
    }
    .title {
      font-weight: 500;
      font-size: setUnit(16);
      line-height: setUnit(20);
      letter-spacing: -0.09px;
      color: $dark-color;
    }
    &.active {
      .title {
        color: $text-color;
      }
    }
  }

  &.active {
    background: #fff4f3;
    border: 1px solid $active-border-color !important;
  }
  &.light {
    padding: 16px;
    .optionInner {
      height: 100%;
      .title {
        color: $dark-grey-color;
        font-size: setUnit(16);
        line-height: setUnit(24);
        margin: 0 !important;
        text-align: center;
      }
    }

    &.active {
      .optionInner {
        .title {
          font-weight: 500 !important;
          color: $text-color;
        }
      }

      &:hover {
        border: 1px solid $primary-color-hover !important;
        background-color: #ffebed !important;
      }
    }

    &:hover {
      border: 2px solid $text--light-gray-color !important;
    }
  }
}
