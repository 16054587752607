@import 'styles/functions';
@import 'styles/variables';

.actions {
  .favoritesBtn {
    border: 2px solid $light-gray !important;
    width: 100%;
    height: 52px;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: -0.09px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    &:hover {
      border-color: $text--light-gray-color !important;
      span {
        color: $text-color;
      }
      svg {
        svg {
          path {
            stroke: $text-color;
          }
        }
      }
    }
    span {
      color: $dark-color;
      transition: $transition;
    }
    svg {
      margin-right: 8px;
      svg {
        path {
          stroke: $dark-color;
          transition: $transition;
        }
      }
    }
  }
}
