@import 'styles/functions';
@import 'styles/variables';

.sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow: auto;

  width: setUnit(260);
  background-color: $secondary-color;
  height: 100%;
  color: #fff;

  .sidebarHead {
    background-color: #fff;
    height: setUnit(80);
    padding-left: setUnit(40);
    display: flex;
    align-items: center;
  }

  .logo > svg {
    height: setUnit(40);
    margin: auto;
    width: auto;
  }

  .points {
    list-style-type: none;

    .point {
      transition: background-color 0.1s;

      .pointContentWrapper {
        height: setUnit(56);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 setUnit(8) 0 setUnit(40);
        cursor: pointer;
      }

      &:hover {
        background-color: $hover-blue;
      }

      .pointContent {
        font-size: setUnit(16);
      }

      .pointIcon {
        margin-right: setUnit(20);
      }
    }

    .pointActive {
      .pointContentWrapper {
        font-weight: 600;
        color: $secondary-color;
        background-color: #fff;
        height: setUnit(56);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 setUnit(8) 0 setUnit(40);
        margin-right: setUnit(40);
        cursor: pointer;
        border-radius: 0 setUnit(80) setUnit(80) 0;

        &:hover {
          background-color: #fff;
        }
      }

      &:hover {
        background-color: $secondary-color;
      }
    }

    .links {
      list-style-type: none;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-in;

      &.open {
        height: auto;
      }

      .link {
        position: relative;

        &:link,
        &:visited {
          display: flex;
          align-items: center;
          transition: all 0.1s;
          color: #fff;
          text-decoration: none;
          width: 100%;
          height: setUnit(44);
          padding: 0 setUnit(8) 0 setUnit(40);
          font-weight: 300;
        }

        &:hover {
          &::before {
            content: '';
            position: absolute;
            left: setUnit(16);
            background-color: $primary-color;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            transition: all 0.1s;
          }

          .linkIcon {
            opacity: 1;

            path {
              stroke: $white;
            }
          }
        }
      }
    }

    .link {
      position: relative;

      &:link,
      &:visited {
        color: #fff;
        text-decoration: none;
      }

      &.linkActive {
        &::before {
          content: '';
          position: absolute;
          background-color: $primary-color;
          border-radius: 50%;
          left: setUnit(15);
          width: 8px;
          height: 8px;
          transition: all 0.1s;
        }

        .linkIcon {
          opacity: 1;

          path {
            stroke: $white;
          }
        }
      }
    }
  }
}
