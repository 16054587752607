@import 'styles/functions';
@import 'styles/variables';

.scrollWrapper {
  height: calc(100vh - #{setUnit(308)});
}

.pendingWrapper {
  height: 100%;
}

.propertyContainerClassName {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.propertyListWrapperClassName {
  padding-bottom: 0 !important;
}
