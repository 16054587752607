@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
.wrapper {
  border-radius: 0.5rem;
  border: 2px solid #e8eaeb;
  margin-top: 5px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.leftSection {
  display: flex;
  align-items: center;
  max-width: setUnit(340);
}

.subtitle {
  color: $text-gray-color;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: setUnit(10);
  width: setUnit(45);

  svg {
    vertical-align: middle;

    path,
    circle,
    rect {
      stroke: $primary-color;
    }
  }
}

.actionButton {
  border-radius: 50%;
  width: setUnit(32);
  height: setUnit(32);
  position: relative;
  font-size: setUnit(20);
  outline: none;
  border: none;
  cursor: pointer;

  .dots {
    line-height: 0;
    position: absolute;
    top: setUnit(9);
    left: setUnit(5.5);
    font-size: setUnit(26);
  }
}
