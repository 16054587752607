@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.isPreApprovedForm {
  .formContainer {
    padding: 40px 64px 40px 40px;
    position: relative;
    transition: padding 0.3s;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    .title {
      font-weight: 600;
      font-size: 20px;
      color: $text-color;
      margin-bottom: setUnit(16);
    }

    &.expand {
      margin: 40px 0 10px;
      visibility: visible;
      opacity: 1;
      display: block;
    }

    $options-gap: setUnit(15);

    .selectType {
      margin: setUnit(16) 0 0;
      display: flex;
      flex-direction: row;
      gap: $options-gap;
      width: 100%;
    }

    .selectOption {
      width: 50%;
      height: setUnit(56);
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: setUnit(16) setUnit(16) setUnit(16) setUnit(16);
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: $mobile) {
        min-width: calc((100% / 4) - $options-gap);
      }

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
        @media (min-width: $mobile) {
          margin-bottom: 0;
        }
      }

      label span:last-child {
        display: none;
      }

      .labelText {
        margin-left: 0;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      span {
        font-weight: 500 !important;
      }
    }

    .subtitle {
      color: $text-color;
      font-size: 16px;
      font-weight: 600;
      margin-top: setUnit(40);
      margin-bottom: setUnit(16);
    }

    .toggleWrapper {
      margin-top: setUnit(24);
    }

    .toggle {
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: setUnit(24);
    }

    .toggleText {
      margin-left: setUnit(12);
      color: $text-color;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      user-select: none;
    }

    .inputWrapper {
      margin-top: setUnit(8);
    }
  }

  .inputHolder {
    width: 100%;
    box-shadow: none !important;
    padding-left: 0 !important;
    border: 1px solid #d6d6d7 !important;
    border-radius: 8px !important;
    color: #262626 !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: 3rem !important;
    letter-spacing: -0.09px !important;
    line-height: 1.3rem !important;
    margin: 0 !important;
    outline: none !important;
    padding: setUnit(8) setUnit(13) setUnit(8) setUnit(8) !important;
    transition: all 0.3s ease !important;
    vertical-align: middle !important;
    margin-top: 12px !important;

    [class*='ant-picker-input'] {
      input {
        border: unset !important;
        order: 1;
        padding: setUnit(8) setUnit(13) setUnit(8) setUnit(8);
        font-size: 1.125rem !important;
      }

      [class*='ant-picker-suffix'] {
        svg {
          height: 18px !important;
          width: 18px !important;
          fill: $text-gray-color;
        }
      }

      [class*='ant-picker-clear'] {
        span {
          display: block !important;
          margin-right: setUnit(8) !important;
        }
      }
      [class*='ant-picker-focused'] {
        box-shadow: none !important;
        [class*='ant-picker-input'] {
          border: 1px solid $text--light-gray-color !important;
        }
      }
    }

    .closingDate {
      input {
        @include inputLightInnerInput();
      }
    }

    .popupCalender {
      border: 1px solid $border-light-gray-2;
      left: 1px !important;
      [class*='ant-picker-cell-inner'] {
        font-weight: 500 !important;
        font-size: 16px;
      }

      [class*='ant-picker-header'] {
        [class*='ant-picker-header-super-next-btn'] {
          display: inline-block;
        }
      }
    }
  }
}

.labelDate {
  font-weight: 600;
}
