.ownerWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px 0;
  p {
    margin: 0;
  }
}

.avatarCustom {
  height: 24px !important;
  width: 24px !important;
  font-size: 10px;
}
