@import 'styles/functions';
@import 'styles/variables';

.controls {
  display: flex;
  align-items: center;

  .separator {
    width: 1px;
    height: setUnit(24);
    background: $border-middle-gray;
  }

  .prev,
  .next {
    border-radius: 50%;
    border: 2px solid $light-gray;
    background: none;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: 2px solid $text--light-gray-color;

      svg {
        opacity: 1;

        path {
          stroke: $text-color;
        }
      }
    }

    svg {
      height: 23px;
      width: 23px;
    }
  }
  .prev {
    svg {
      transform: rotate(-90deg);
    }
  }
  .next {
    margin-left: 0.5rem;

    svg {
      transform: rotate(90deg);
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
