@import 'styles/mixins';
@import 'styles/variables';

.shareDocumentsModal {
  .content {
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: setUnit(32);
    }

    .shareAssigneesContainer {
      .assigneeContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: setUnit(16);
        .left {
          display: flex;
          align-items: center;
          gap: setUnit(16);

          .avatar {
            width: setUnit(32);
            height: setUnit(32);
            font-size: setUnit(14);
            font-weight: 600;
            line-height: setUnit(32);
            letter-spacing: setUnit(-0.33);
          }
          .name {
            font-size: setUnit(16);
            font-weight: 500;
          }
        }
        .right {
          .checkboxDisabled {
            [class*='ant-checkbox-checked'] {
              opacity: 0.5;
              :after {
                border-color: white !important;
              }
            }
          }
        }
      }
    }
    .seperator {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
      height: 1px;
      background-color: $light-gray;
    }

    .shareAssigneesContainer,
    .shareViaContainer {
      .heading {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: 20.8px;
        text-align: left;
        margin-bottom: setUnit(24);
      }
    }

    .shareViaContainer {
      .appMsgShare {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        border-radius: 8px;
        cursor: pointer;

        .left {
          display: flex;
          align-items: center;
          gap: setUnit(16);
        }

        .text {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        .arrowRight {
          svg {
            height: 18px;
            width: 18px;
          }
        }

        &:hover {
          background-color: $background-ligh-gray-2;
        }
      }
    }

    .doneBtnContainer {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .doneBtn {
        text-align: center;
        width: 152px;
        height: 52px;
        border-radius: 12px;
      }
    }
  }
}
