@import 'styles/functions';
@import 'styles/variables';

.markerCluster {
  color: #000000;
}

.countBoxOnMap {
  width: 200px;
  border-radius: 0.375rem;
  background-color: #333333;
  opacity: 75%;
  color: white;
  padding: 10px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.countBoxOnMap span {
  font-size: 0.9rem;
  font-weight: 500;
}
.googleMap {
  position: absolute !important;
}
