@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.editForm {
  padding: 1.25rem 0;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .infoWrap {
    flex: 0 0 calc(100% - calc(2rem + 15px));
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .title {
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
    font-size: setUnit(16);
    letter-spacing: -0.18px;
    color: $dark-color;
  }

  .info {
    font-weight: 400;
    color: $text-gray-color;
    margin-bottom: 0;
    font-size: setUnit(14);
    letter-spacing: -0.18px;
  }

  .calendarIcon {
    background-color: $background-grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: setUnit(50);
  }

  .editIcon {
    background-color: $background-grey-2;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    @include transition;
    &:hover {
      background-color: $light-gray;
    }
  }
}
