@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  position: relative;

  .edit {
    @include action-icon();
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;

        &:hover {
        }
      }
    }
  }

  .popup {
    @include box-shadow;

    position: absolute;
    top: setUnit(34);
    right: 0;
    background: #ffffff;
    z-index: 99;
    width: setUnit(196);
    border-radius: setUnit(8);
    overflow: hidden;

    .option {
      padding: setUnit(12) setUnit(16);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        svg {
          vertical-align: middle;
        }
      }

      &:hover {
        background: $light-gray;
      }
    }
  }
}
