@import "styles/functions";
@import "styles/variables";

.form {
    .field {
        margin-top: setUnit(40) !important;
        margin-bottom: 0;

        &.name {
            margin-top: 0 !important;
        }

        &:last-child {
            padding-bottom: setUnit(54);
        }

        &.tag {
            input {
                padding-left: 0;
            }
        }
    }

    .inline {
        display: flex;
        align-items: center;

        .dueDate {
            width: 100%;
        }

        .assignTo {
            width: 100%;
            margin-left: setUnit(32);
        }
    }

    .uploadDocument {
        display: flex;
        align-items: center;
        padding-bottom: setUnit(40);
        width: fit-content;
        cursor: pointer;
        margin-top: setUnit(24);

        .icon {
            width: setUnit(32);
            height: setUnit(32);
            background: $background-ligh-gray;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: setUnit(16);
        }

        .text {
            color: $secondary-color;
        }
    }
}
