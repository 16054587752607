@import 'styles/functions';
@import 'styles/variables';

.approveBtn {
  background-color: $green-color !important;
  width: setUnit(100) !important;
  height: setUnit(28) !important;
  border-radius: setUnit(100) !important;
  padding: 0 !important;

  &:hover {
    background-color: $green-color !important;
  }
}

.rejectBtn {
  background-color: $primary-color !important;
  width: setUnit(100) !important;
  height: setUnit(28) !important;
  border-radius: setUnit(100) !important;
  padding: 0 !important;
}

.inactiveBtn {
  background-color: $primary-color !important;
  width: setUnit(100) !important;
  height: setUnit(28) !important;
  border-radius: setUnit(100) !important;
  padding: 0 !important;
}
