@import 'styles/variables';
@import 'styles/functions';

.item {
  padding: setUnit(0) setUnit(24) setUnit(0) setUnit(40);
  background-color: $white;
  transition: $transition;
  display: flex;
  flex-wrap: wrap;
  &:hover {
    background-color: $background-grey-2;
  }
  .separator {
    width: setUnit(2);
    background-color: $light-gray;
    position: relative;
    &:after {
      content: '';
      width: setUnit(19);
      height: setUnit(58);
      border-left: 2px solid transparent;
      border-bottom: 2px solid $light-gray;
      display: block;
    }
  }
  .lastSeparator {
    height: setUnit(58);
  }

  .info {
    width: 50% !important;
    margin-left: setUnit(27);
    .streetInfo {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: column;
      color: $text-color;
      margin-bottom: setUnit(4);
      &:hover {
        text-decoration: underline;
      }
      .streetOne {
        margin-top: setUnit(12);
        font-weight: 600;
      }
    }
    .agentInfo {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin-bottom: setUnit(4);
      span {
        font-weight: 500;
      }
    }
    .mlsInfo {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin-bottom: setUnit(4);
    }
    .notes {
      height: 0;
      opacity: 0;
      visibility: visible;
      display: none;
      transition: $transition;
      background-color: $background-gray;
      padding: setUnit(8) setUnit(10);
      margin: setUnit(4) 0;
      &.show {
        height: auto;
        opacity: 1;
        visibility: visible;
        display: block;
      }
      .note {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.18);
        border-radius: setUnit(8);
        margin-bottom: setUnit(4);
        word-wrap: break-word;
        overflow-wrap: break-word;
        span {
          font-weight: 500;
        }
        &.privateNote {
          font-style: italic;
        }
        &:not(:last-child) {
          margin-bottom: setUnit(6);
        }
      }
    }
    .notesToggler {
      font-size: setUnit(14);
      font-weight: 500;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $link-blue;
      cursor: pointer;
      margin-top: setUnit(4);
    }
  }
  .action {
    margin-top: setUnit(16);
    margin-left: auto;
  }
}
