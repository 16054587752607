@import 'styles/functions';
@import 'styles/variables';

.button {
  font-family: $font-family !important;
  border-radius: 8px;
}

.primary {
  color: $white !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important;

  background-color: $primary-color !important;

  &:hover {
    background-color: $primary-color-hover !important;
  }
}

.secondary {
  color: $white !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;

  background-color: $secondary-color !important;

  &:hover {
    background-color: $secondary-color-hover !important;
  }
}

.secondary-inverted {
  color: $secondary-color !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;

  border: 1px solid $secondary-color !important;

  &:hover {
    border-color: $secondary-color-hover !important;
  }
}

.tertiary {
  color: $black !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;

  background-color: $tertiary-50 !important;

  &:hover {
    background-color: $tertiary-50-hover !important;
  }
}

.link {
  padding: 0px !important;
}

.go-back {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  width: fit-content !important;
  height: fit-content !important;
  border: 2px solid $light-gray !important;
  padding: 14px 16px !important;
  border-radius: 100px !important;
  span {
    font-family: $font-family;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: $dark-color !important;
  }
  span:hover {
    color: $dark-color !important;
  }
}
