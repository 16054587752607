@import 'styles/functions';
@import 'styles/variables';

.dateControls {
  background: #fdfdfd;
  padding: setUnit(16);
  border: 2px solid #e8e9ea;
  border-radius: 8px;
  margin-bottom: 2.5rem;
  position: relative;

  h4 {
    font-weight: 600;
    line-height: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    margin-right: 0.5rem;
  }

  .editIcon {
    position: absolute;
    cursor: pointer;
    top: 0.75rem;
    right: 1em;
    padding: 0.25rem;
    border-radius: 50%;

    &:hover {
      background-color: #e8e9ea;
    }
  }

  .controls {
    margin-bottom: setUnit(15);
    .prefix {
      width: setUnit(35);
      height: setUnit(28);
      background: rgba(232, 233, 234, 0.62);
      border-radius: 100px;
      text-align: center;
      padding: 0.25rem;
      font-weight: 600;
      color: $dark-color;
      display: inline-block;
      margin-right: 0.5rem;
    }

    p {
      display: inline-block;
      margin-bottom: 0;
    }

    span {
      color: $text-gray-color;
    }
  }
}
