@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.compareOffersModal {
  [class='ant-modal-content'] {
    padding: setUnit(32) 0;
    margin: 4rem 0;
    [class*='ant-modal-header'] {
      [class*='ant-modal-title'] {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: setUnit(32);
        color: $text-color;
      }
      [class='ant-modal-close'] {
        top: setUnit(32);
      }
    }
  }
  [class*='ant-checkbox-inner::hover'] {
    border: 1px solid red;
  }
  .ant-checkbox-inner:hover {
    border: 1px solid red;
  }
  .compareOffersModalBody {
    max-height: 585px;
    overflow-y: auto;
    padding: 0 setUnit(32) 0 setUnit(40);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  @media only screen and (max-width: $mobile) {
    .compareOffersModalBody {
      padding: 16px 20px 16px 20px;
    }
  }
}
