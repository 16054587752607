@import 'styles/functions';
@import 'styles/variables';

.commissionRow {
  position: relative;
  &.commissionRowEdit {
    &:hover {
      .editIcon {
        display: block;
      }
    }
  }
  &.disabled {
    opacity: 0.3;
  }
  .value {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    text-overflow: ellipsis;
    font-size: setUnit(16);
    color: $text-color;
    width: fit-content;
    position: relative;
    padding-bottom: 5px;
  }
  .boldLabel {
    font-weight: 600;
    font-size: setUnit(16);
  }
  .editIcon {
    position: absolute;
    background-color: $white;
    cursor: pointer;
    top: setUnit(-4);
    right: setUnit(5);
    display: none;
  }
  .buttonsContainer {
    margin-top: setUnit(20);
    display: flex;
    gap: 12px;
    button {
      border-radius: 8px;
      width: 100%;
    }
  }
  .responsibilityEditStates {
    margin-top: setUnit(16);
    padding: setUnit(16);
    border-radius: 8px;
    border: 1px solid $light-grey-color;
    background-color: $background-ligh-gray-5;

    .financialInputField {
      margin-top: 1rem;
    }
  }
}

.deleteCommissionConfirmation {
  .deleteConfirmationContent {
    text-align: center;
    font-size: 14px;
    .title {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
