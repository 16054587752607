@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  padding-block: 24px;
  row-gap: 24px;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: $white;
    cursor: pointer;
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
    border-radius: 16px;

    max-width: 720px;
    width: 100%;
  }

  .addAddressWrapper {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
      }
      .title {
        color: $text-color;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
      }
    }
  }
}
