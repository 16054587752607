@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  width: 100%;

  .option {
    &.light {
      border-bottom: 1px solid $grey-color;
      padding: setUnit(12) 0;
    }

    &.full {
      border-radius: setUnit(8);
      border: 2px solid #e8eaeb;
      padding: setUnit(20);
      margin-bottom: setUnit(16);
      font-weight: 500;
      color: $dark-grey-color;

      &.checked {
        background-color: $primary-color-light;
        border-color: $primary-color-light;
        color: $text-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      display: inline-flex;
      margin-bottom: 0;

      .labelText {
        margin-left: setUnit(15);
      }
    }
  }

  .checkbox {
    border: 2px solid $text--light-gray-color !important;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
.error {
  @include inputError;
}
