@import 'styles/functions';
@import 'styles/variables';

.metaInfo {
  .metaInfoTitle {
    margin-top: 1.625rem;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    color: $text-color;
  }

  .uploads {
    margin-bottom: 1.5rem;

    .groupDate {
      margin-top: 1.5rem;
      margin-bottom: setUnit(12);
      line-height: 16px;
    }

    .attachment {
      border: 2px solid $light-gray;
      background: $white;
    }

    .attachmentFile {
      font-size: 16px !important;
      line-height: 16px;
    }

    .attachmentDate {
      font-size: 14px !important;
      line-height: 16px;
      margin-top: 0.25rem !important;
      color: $dark-color;
    }

    .noQuotesContainer {
      height: 100px;
      display: flex;

      .noQuotes {
        margin: auto;
        color: $text-gray-color;
      }
    }
  }

  .button {
    width: 100%;
    height: setUnit(44);

    &.messages {
      .messageButtonTitle {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          vertical-align: middle;
          position: relative;
          left: setUnit(-12);
        }
      }
    }

    &.viewProfile {
      background: none;
      border: 1px solid $border-middle-gray;
      color: $text-color;

      &:hover {
        background-color: $background-ligh-gray;
      }
    }
  }

  .accordion {
    border-bottom: none;
  }
}
