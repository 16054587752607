@import 'styles/mixins';
@import 'styles/variables';
@import '../../mixins.module.scss';

.uploadFileInFormWrapper {
  .uploadFileContent {
    max-height: 60vh;
    overflow-y: auto;
    margin-right: -12px;
    padding-right: 12px;
  }

  .uploadDragger {
    background-color: $background-grey-2 !important;
    border: 2px dashed $light-grey-color !important;

    [class~='ant-upload'] {
      padding: 24px 0px 28px !important;
    }

    &:hover {
      border-color: $light-grey-color !important;
    }
  }

  .uploadContainer {
    height: 216px;
    border-radius: 8px;

    [class*='ant-upload'] {
      border-radius: 8px;
    }

    .uploadFileIcon {
      margin-bottom: 12px;
    }

    .uploadButton {
      border-radius: 0.5rem;
      padding: 0.25rem;
      width: 8.875rem;
      height: 3.25rem;
      flex-shrink: 0;
      border-radius: 0.5rem;
      border: 2px solid $secondary-color;
      background: $white;
      cursor: pointer;
    }

    .uploadText {
      font-weight: 600;
      color: $secondary-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Inter';
      font-size: 1rem;
      line-height: 1.5rem;
      /* 150% */
      letter-spacing: -0.00563rem;
    }

    .uploadHint,
    .uploadSecondHint {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $dark-grey-color;
    }

    .uploadSecondHint {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

  .uploadedFilesContainer {
    margin-bottom: 20px;
  }

  .invalidFile {
    border: 1px solid $error-color;
  }

  .templateFieldsList {
    margin-top: 24px;
  }
}
