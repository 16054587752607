@import 'styles/functions';
@import 'styles/variables';

.connection {
  padding: setUnit(8) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .avatar {
    width: setUnit(32);
    height: setUnit(32);
  }

  .name {
    margin-left: setUnit(8);
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .name {
    display: flex;
    align-items: center;
  }

  .checkbox {
    left: 0% !important;
  }

  // copied from Client Drawer
  .tag {
    display: inline-block;
    padding: 0.125rem 0.5rem;
    background: $silver-badge;
    border-radius: 40px;
    color: $dark-color;
    font-weight: 600;
    font-size: 12px;
    margin-left: 0.5rem;
  }
}
