@import 'styles/functions';
@import 'styles/variables';

.cardWrapper {
  min-height: 72px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  padding: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fieldContainer {
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
    .value {
      font-size: 1rem;
    }
    .noneText {
      font-style: italic;
    }
  }

  &:hover {
    background: $background-grey-2;
  }
  .editIcon {
    display: flex;
    align-items: center;
  }
  .hideIcon {
    visibility: hidden;
  }
  &.disabledSection {
    opacity: 0.5;
    pointer-events: none;
  }
}

.editWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 14px;
  min-height: 72px;
  margin-top: 1rem;

  .dropdownWrapper {
    margin-top: 0 !important;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      border-radius: setUnit(8);
      font-weight: 500;
      border-width: 1px !important;
      &.ant-btn[disabled] {
        opacity: 0.7 !important;
      }
    }
  }
}
