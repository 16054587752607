@import 'styles/functions';
@import 'styles/variables';

.accessInfo {
  display: inline-block;
  cursor: pointer;
  margin-left: 0.75rem;
  position: relative;
  top: -0.05rem;
}

.accessTooltip {
  [class*='ant-tooltip-inner'] {
    width: setUnit(375);
    padding: setUnit(20);
    border-radius: 1rem;
    background-color: $text-color;

    .content {
      p {
        margin-bottom: 0;
        margin-right: 0 !important;
      }

      .limited {
        margin-top: 1rem;
      }

      span {
        font-weight: 700;
      }

      .inline {
        display: inline-block;
        margin-right: 0.75rem;
      }

      .tasks {
        .taskCheckMark {
          position: relative;
          top: -1.35rem;
        }

        p {
          width: 86%;
        }
      }
    }
  }
}
