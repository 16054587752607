@import 'styles/functions';
@import 'styles/variables';

.avatarTableColumn,
.documentTableColumn {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatarTableColumn {
  .avatar {
    width: setUnit(26);
    height: setUnit(26);
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(26);
    letter-spacing: setUnit(-0.33);
  }
  span {
    font-size: 1rem;
  }
}

.documentTableColumn {
  .name {
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 34px;
    flex-shrink: 0;
    path {
      stroke: $dark-color;
    }
  }
}

.dateTimeColumn {
  font-size: 1rem;
  p {
    margin-bottom: 0;
  }
  .time {
    color: $light-gray-2;
  }
}
