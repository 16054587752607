@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  min-height: calc(100vh - $header-height) !important;
}

.servicesContainer {
  width: 100% !important;
  max-width: 100% !important;
  min-height: calc(100vh - $header-height - $footer-height) !important;
  overflow: unset !important;
  @media (max-width: 991px) {
    min-width: unset !important;
  }

  .mainAreaWrapper {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    padding-bottom: 100px;

    @media screen and (max-width: $tablet-horizontal) {
      grid-template-columns: 0fr 1fr 1fr;
    }

    @media screen and (max-width: $tablet) {
      grid-gap: 30px;
    }

    @media screen and (max-width: $mobile) {
      display: block;
      padding: 0px 20px 50px;
    }

    @media screen and (max-width: 390px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.pageWrapper {
  display: block;
  width: 100%;
  background-color: $background-ligh-gray-3;
}

.dateTimeArea {
  display: block;
  width: 100%;
}

.formArea {
  display: block;
  width: 100%;
}

.confirmationArea {
  display: inline-flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: $white;
  height: 100%;
}

.selectedDate {
  margin-bottom: setUnit(32);
  h4 {
    font-weight: 700;
    font-size: setUnit(16);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
  }
  .selectedDateWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .iconImage {
      height: 30px;
      vertical-align: top;
      margin-left: auto;
      .icon {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        cursor: pointer;
      }
    }
    .cancelBtn {
      font-size: 12px;
      font-weight: 600;
      background-color: $background-ligh-gray;
      padding: 6px 12px;
      border-radius: 25px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .leftPanelArea {
    text-align: center;
    width: 100%;
  }
  .mainArea {
    width: 100%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .leftPanelArea {
    top: 0rem;
    text-align: center;
    width: 100%;
  }

  .dateTimeArea {
    text-align: center;
  }
  .selectedDate {
    text-align: center;
  }
  .mainArea {
    width: 100%;
  }
}
