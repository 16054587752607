@import 'styles/functions';
@import 'styles/variables';

.leadSourceContainer {
  padding: setUnit(13);

  .title {
    font-size: setUnit(20);
    font-weight: 600;
    color: $text-color;
  }
  .optionSelectedContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .optionSelected {
      display: flex;
      align-items: center;
      gap: setUnit(15);

      .text {
        font-weight: 600;
        font-size: setUnit(16);
      }

      .shareIcon {
        margin-top: setUnit(5);
        cursor: pointer;
      }
    }

    .editIcon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .hideIcon {
      visibility: hidden;
    }
  }

  .marginBottom {
    margin-bottom: 1.5rem;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      border-radius: setUnit(8);
      font-weight: 500;
      border-width: 1px !important;
      &.ant-btn[disabled] {
        opacity: 0.7 !important;
      }
    }
  }
}
