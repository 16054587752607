@import 'styles/functions';
@import 'styles/variables';

.spinnerContainer {
  height: 20px;
  width: 20px;
  .loaderInverseColor {
    font-size: setUnit(4);
    color: #515151;
  }
}

.buttonWrap {
  order: 2;
  @media (min-width: $desktop) {
    order: 3;
  }
  .close {
    background-color: transparent;
    border: 2px solid $light-gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
    width: setUnit(44);
    height: setUnit(44);
    background: #fff;
    box-sizing: border-box;
    border-radius: 50%;
    &:hover {
      border-color: $text--light-gray-color;
    }
    .icon {
      height: setUnit(24);

      svg {
        height: setUnit(24);
        width: setUnit(24);
      }
    }
  }
}
