@import 'styles/functions';
@import 'styles/variables';

.highlights {
  padding-bottom: 1rem;

  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .disclaimer {
    text-align: center;
  }

  .content {
    display: flex;
  }
}
