@import 'styles/mixins';
@import 'styles/variables';

.formPage {
  min-height: calc(100vh - $header-height - $footer-height - $workshop-header-height - 2px);
  padding-bottom: 16px;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 16px !important;
  box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.1);
  &:not(:last-child) {
    margin-bottom: setUnit(24);
  }
  :hover {
    cursor: pointer;
  }
}

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    background: $white;
    border-radius: 16px;
    color: $text-color;
  }

  .container {
    min-width: 230px;
    padding: 10px;
    padding-bottom: 0px;

    .title {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    .row {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .userName {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0px !important;
    }

    .sequenceBox {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $text--light-gray-color;
      border: 1px solid $text--light-gray-color;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      display: inline-block;
      text-align: center;
    }
    .editorRow {
      width: 100%;
    }
    .sequenceBoxColumn {
      align-self: baseline;
      margin-right: 5px;
    }

    .formStatus {
      font-family: $font-family;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }

    .column {
      width: 80% !important;
    }
  }
}

.formDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.formAvatar {
  margin-right: 16px;
}

.formInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  color: $text-color;

  .address {
    font-weight: 600;
    font-size: 16px;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
  }

  .labelValue {
    display: flex;
    flex-direction: column;
  }

  .client {
    font-weight: 600;
    font-size: 18px;
  }

  .status {
    display: flex;
    align-items: center;
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: $text-gray-color;

    .labelWithIcon {
      display: flex;
      align-items: center;

      .warningIcon,
      .label {
        margin-right: 5px;
      }
    }
  }

  .statusWarning {
    color: $primary-color;
  }
}

.columnHeader {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: setUnit(16);
  .headerCount {
    color: $border-middle-gray;
  }
}

.headerRow {
  margin-left: 4px;
  margin-right: 10px;
}

.contentRow {
  display: flex;
  flex-wrap: wrap;
  .noForms {
    font-weight: 400;
    font-size: 14px;
    color: $text-gray-color;
    text-align: center;
    display: block;
  }
}

.workshopTabs {
  width: 100%;
  div[class~='ant-tabs-nav'] {
    position: sticky;
    top: 0;
    background-color: $white;
    &:before {
      content: none;
    }
    div[class~='ant-tabs-nav-wrap'] {
      justify-content: center;
      div[class~='ant-tabs-tab'] {
        .tabtitle {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.18);
          color: $text--light-gray-color;
          transition: $transition;
          .count {
            margin-left: setUnit(4);
            color: $text--light-gray-color;
          }
        }
        &[class~='ant-tabs-tab-active'] {
          .tabtitle {
            color: $text-color;
          }
        }
      }
    }
  }
}
