@import 'styles/functions';
@import 'styles/variables';

.flooringOptions {
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin: 0.5rem 0 1rem;
  padding: 20px;

  .wrapper {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .selectInput {
    padding-left: 0 !important;
  }
}
