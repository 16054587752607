@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.item {
  margin-bottom: setUnit(30);

  .tagOptions,
  .participantsOptions {
    max-height: setUnit(110) !important;
  }
  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.18);
    color: $dark-color;
    margin-bottom: setUnit(12);
  }
  .contentWrapper {
    border: 2px solid $light-gray;
    border-radius: 8px;
    padding: 1.5rem;
  }
  .option {
    height: setUnit(52);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: transparent;
    text-align: left;
    font-size: setUnit(16);
    line-height: setUnit(20);

    padding: setUnit(10) setUnit(15);

    .optionInnerHolder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .checkbox {
        margin-bottom: 0;
      }
    }

    &:hover {
      background-color: $grey-color;
    }

    &.is-highlighted {
      background-color: $grey-color;
    }

    &.is-selected {
      background-color: $grey-color;
    }
  }
  .spacing {
    margin-top: setUnit(20);
  }

  .tagInput > div,
  .clientsWrapper > div {
    margin-top: 0 !important;
  }

  .clientsWrapper {
    input {
      &::placeholder {
        color: $text-color;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .fileHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: setUnit(20);
    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: setUnit(12);
      .icon {
        width: setUnit(32);
        height: setUnit(32);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $background-grey-2;
        margin-right: setUnit(12);
        svg {
          width: setUnit(24);
          height: setUnit(24);
          path {
            stroke: $dark-color;
          }
        }
      }
      .filename {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        .fileSize {
          margin-left: setUnit(8);
          color: $text--light-gray-color;
          font-weight: 400;
        }
      }
    }
    .iconDelete {
      cursor: pointer;
      transition: $transition;
      width: setUnit(24);
      height: setUnit(24);
      transform: scale(1);
      display: flex;
      path {
        stroke: $dark-color;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .form {
    .tagSelect {
      [class~='ant-select-clear'] {
        right: 30px !important;
      }
      [class~='ant-select-selection-item'] {
        display: inline-block !important;
        max-width: calc(100% - 17px) !important;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      [class~='ant-select-focused'] {
        border: 1px solid $text-gray-color !important;
      }
      [class~='ant-select-selection-placeholder'] {
        color: $text-gray-color !important;
        padding: 17px 0 16px !important;
      }
      [class~='ant-select-selector'] {
        border-radius: 8px !important;
        padding: setUnit(8) setUnit(13) !important;
        outline: none;
        margin: 0;
        vertical-align: middle;
        color: $text-color;
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: -0.09px;
        height: setUnit(48);
        border: 0;
        border: 1px solid $light-grey-color !important;
        transition: $transition;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          color: $text-gray-color !important;
          font-weight: 400;
          font-size: 1rem !important;
        }
      }
      [class~='ant-select-arrow'] {
        right: 0.7rem !important;
        color: $text-gray-color !important;
      }
      [class~='ant-select-selection-search'] {
        padding-left: 13px !important;
      }
    }

    .permissionSelect {
      [class~='ant-select-focused'] {
        border: 1px solid $text-gray-color !important;
      }
      [class~='ant-select-selection-placeholder'] {
        color: $text-gray-color !important;
        padding: 17px 0 16px !important;
        margin-left: 10px;
      }
      [class~='ant-select-selector'] {
        border-radius: 8px !important;
        padding: setUnit(8) setUnit(13) !important;
        outline: none;
        margin: 0;
        vertical-align: middle;
        color: $text-color;
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: -0.09px;
        height: setUnit(48);
        border: 0;
        border: 1px solid $light-grey-color !important;
        transition: $transition;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          color: $text-gray-color !important;
          font-weight: 400;
          font-size: 1rem !important;
        }
      }
      [class~='ant-select-arrow'] {
        right: 0.7rem !important;
        color: $text-gray-color !important;
      }
      [class~='ant-select-selection-overflow'] {
        position: absolute;
        [class~='ant-select-selection-overflow-item'] {
          [class~='ant-select-selection-item'] {
            height: auto;
            margin: 0 setUnit(4) 0 0;
            font-size: setUnit(16);
            font-weight: 400;
            line-height: setUnit(20);
            letter-spacing: setUnit(-0.18);
          }
        }
      }
    }
  }
}
