@import 'styles/mixins';
@import 'styles/variables';

.documentsModal {
  position: fixed;
  z-index: 10;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f9faf2;
  backdrop-filter: blur(200px);
  transform: scale(1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow-y: auto;

  .documentsContainer {
    margin: 1rem;
    padding: 5rem 7rem;

    .modalCloseIcon {
      position: absolute;
      right: 40px;
      top: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchInput {
        width: 212px;
        border-radius: 100px !important;
        border-color: $text--light-gray-color;
        box-shadow: none;

        &:focus,
        &:hover,
        &:active {
          border-color: $dark-color;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      gap: 5px;
      p {
        margin-bottom: 0;
      }
      .count {
        color: $text--light-gray-color;
      }
      &.marginBottom {
        margin-bottom: 2rem;
      }
      &.marginTop {
        margin-top: 2rem;
      }
      &.cursorPointer {
        cursor: pointer;
      }
    }

    .tableContainer {
      margin-top: setUnit(32);

      .seperator {
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        height: 1px;
        background-color: $light-grey-color;
      }

      .cursorPointer {
        tr {
          cursor: pointer;
        }
      }

      div[class~='ant-table-wrapper'] {
        div[class~='ant-table-content'] {
          table {
            border-radius: setUnit(16);
            background-color: #fff;
          }
        }

        th {
          background-color: unset;
          color: $text-gray-color;
          font-weight: 600;
          font-size: setUnit(14);
          height: 55px;
          padding: 1rem 1.5rem;
          &.ant-table-cell::before {
            display: none;
          }
          span[class='ant-table-column-title'] {
            color: $text-gray-color;
          }
        }

        [class~='ant-table'] {
          border-radius: 1rem;
          box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
        }

        [class~='ant-table-column-sort'] {
          background-color: transparent;
        }

        tr {
          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 1rem;
              }
            }
          }
          th {
            &:first-child {
              border-top-left-radius: 1rem !important;
            }
            &:hover {
              background-color: #fafafa;
            }
          }
          td {
            padding: 1.5rem;
          }
          td,
          th {
            border-right: unset !important;
          }
          &:hover {
            td {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}
