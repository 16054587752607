@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.showingStatus {
  height: setUnit(32);
  padding: 0 setUnit(16);
  border-radius: setUnit(100);
  background: $background-ligh-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $white;

  .statusText {
    font-size: 14px;
  }

  &.upcoming {
    background: $green-color;
  }
  &.pending {
    background: $primary-color;
  }
  &.newFeedback {
    background: $confetti-blue;
  }
  &.archived {
    background: $text--light-gray-color;
  }
}
