@import 'styles/functions';
@import 'styles/variables';

.agentBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkbox {
    border-color: $text-color;
    border-width: 2px;
  }
  .pointerEventsNone {
    pointer-events: none;
  }

  .applicantWrapper {
    padding: 0.25rem 0.25rem 0.25rem 0;

    .applicants {
      margin-left: 0 !important;
    }
  }

  .blockPart {
    display: flex;
    align-items: center;
  }

  .favorite {
    border: 1px solid $border-light-gray-2;
    background: $white !important;

    &:hover {
      border-color: $text--light-gray-color;
    }
  }
}
