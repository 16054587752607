@import 'styles/functions';
@import 'styles/variables';

.searchList {
  position: relative;

  #searchProperties {
    width: 50%;
  }

  #searchMap {
    width: 50%;
  }
}

.mapSwitchers {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}
