@import 'styles/functions';
@import 'styles/variables';

.stepHeader {
  font-family: $font-family;
  font-weight: 700;
}

.text {
  font-family: $font-family;
  color: $dark-color;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.inputWrapper {
  width: setUnit(280) !important;
  margin-top: setUnit(40) !important;
}

.input {
  height: auto !important;
  font-family: $font-family;
  font-size: setUnit(28) !important;
  text-align: center;
}

.input::placeholder {
  height: auto !important;
  font-family: $font-family;
  font-size: setUnit(28) !important;
  text-align: center;
}

.codeInput {
  letter-spacing: setUnit(32);
  width: setUnit(256) !important;
  padding-left: setUnit(32) !important; // to compensate letter-spacing
}

.continueButton {
  font-size: 16px;
  margin-top: setUnit(66);
}

.invalidCodeText {
  position: absolute;
  width: 100%;
  top: setUnit(12);
  left: 50%;
  transform: translateX(-50%);
  color: $error-color;
}

.inlinedPhoneNumber {
  max-width: setUnit(
    135
  ); // formatted phone is rendered using input with fixed width, which we don't need here
  display: inline-block;
}

.blockedVerificationContent {
  margin-bottom: setUnit(30);
}
