@import 'styles/variables';
@import 'styles/functions';

.toursHeader {
  position: relative;
  padding: setUnit(20) setUnit(40);
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .header {
    margin: 0;
    font-weight: 700;
    font-size: setUnit(32);
  }

  .headerActions {
    display: flex;
    align-items: center;
    gap: setUnit(8);
    position: absolute;
    right: setUnit(32);

    .closeBtn {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
      width: 44px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 2px solid $light-gray;
      border-radius: 100px;
      transition: $transition;
      background-color: transparent !important;

      &:hover {
        border-color: $text-gray-color;
      }
      .icon {
        svg {
          path {
            stroke: $dark-color;
          }
        }
      }
    }

    .thLeft {
      display: flex;
      align-items: center;
      gap: setUnit(16);
      .teamFilter {
        margin-left: 0;
      }
      .toursFilter {
        .toursFilterDropdown {
          width: setUnit(221) !important;
        }
      }
    }
    .tourSort {
      width: setUnit(44);
      height: setUnit(44);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 2px solid $light-gray;
      border-radius: setUnit(100);
      margin-right: setUnit(8);
      transition: $transition;
      &:hover {
        border-color: $text--light-gray-color !important;
      }
      &[class~='ant-dropdown-open'] {
        background-color: $background-grey-2;
      }
      .sortIcon {
        svg {
          display: flex;
          align-items: center;
          path {
            stroke: $dark-color;
          }
        }
      }
      .sortDropdown {
        max-width: setUnit(200);
        border-radius: setUnit(8);
        width: 100%;
        background-color: $white;
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
        padding: setUnit(8) 0;
        .title {
          font-size: setUnit(16);
          font-weight: 600;
          line-height: setUnit(21);
          letter-spacing: setUnit(-0.18);
          padding: setUnit(20) setUnit(24) setUnit(8);
          margin-bottom: 0;
        }
        .sdOptions {
          display: flex;
          flex-wrap: wrap;
          .sdOption {
            flex: 0 0 100%;
            margin-right: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: setUnit(12) setUnit(24);
            background-color: transparent;
            transition: $transition;
            &:after {
              width: setUnit(10);
              height: setUnit(5);
              border-left: 2px solid $green-color;
              border-bottom: 2px solid $green-color;
              transform: rotate(-45deg);
              margin-left: auto;
              opacity: 0;
              visibility: hidden;
              transition: $transition;
            }
            span[class~='ant-radio'] {
              span[class~='ant-radio-inner'] {
                display: none;
              }
              & + span {
                font-weight: 400;
                line-height: setUnit(20);
                letter-spacing: setUnit(-0.18);
                font-size: setUnit(16);
                color: $text-color;
                padding: 0;
              }
            }
            &[class~='ant-radio-wrapper-checked'] {
              background-color: $background-grey-2;
              &:after {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
    .toursFilter {
      .toursFilterDropdown {
        width: setUnit(221) !important;
      }
    }
  }
}
