@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../../styles.module';

.stageWrap {
  .groupedInputsContainer {
    display: flex;
    align-items: center;
    position: relative;
  }
  .errorContainer {
    color: $error-color;
    font-size: 0.875rem;
    white-space: nowrap;
  }
  .title {
    max-width: 400px;
    margin: 0 auto 54px auto;
  }
  .groupedInputs {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;
    position: relative;
    width: 100%;

    & label {
      color: $text-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;
      margin-bottom: 12px;
    }

    .inputContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 286px;
    }
    .radiusBlock {
      max-width: 96px;
      display: flex;
      flex-direction: column;
      min-width: 80px;

      & div {
        margin: initial;
      }
    }
  }
  .removeLocationGroupInput {
    position: absolute;
    right: -40px;

    .removeBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: none;
      border: none;
      background: transparent;
      padding: 8px 2px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: $primary-color;
        border-radius: 50%;

        svg {
          width: 14px;
          height: 14px;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(316deg) brightness(106%)
            contrast(101%);
        }
      }
    }
  }

  .locationsBlock {
    border-radius: 8px !important;
    border: 1px solid $light-grey-color;
    background: $white;
    width: 100%;

    input {
      border-bottom: none !important;
      height: initial !important;
      padding: 14px 0px 14px 44px !important;
      // background: transparent!important;
      border-radius: 8px !important;
    }

    .searchIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50% !important;
      left: 10px !important;
    }
  }
  .placeholder {
    border-bottom: none !important;
    padding: 14px 0px 14px 44px !important;
    padding-left: 0 !important;
    border-radius: 8px !important;
  }
  .valuesWrapper {
    display: flex;
    align-items: center;
    border-radius: 8px !important;
    border-bottom: none !important;
  }
  .radius {
    border-radius: 8px !important;
    border: 1px solid $light-grey-color;
    background: $white;

    input {
      border-radius: 8px !important;
      border-bottom: none !important;
      padding: 14px 16px !important;
      overflow: hidden;
      color: $text-color;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
    }
  }
  .addBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: none;
    border: none;
    background: transparent;
    padding: 8px 2px;
    cursor: pointer;
    margin-top: 20px;

    span {
      color: $dark-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.18px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px;
      svg {
        width: 14px;
        height: 14px;
        color: $dark-color;
      }
    }
  }
  .inputBlockWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .submitBtn {
    margin-bottom: 20px;
  }
}
