@import "styles/functions";
@import "styles/variables";

.close {
    position: absolute;
    top: setUnit(32);
    right: setUnit(32);
    width: setUnit(25);
    height: setUnit(25);
    cursor: pointer;
    transition: all .2s;
    display: flex;
    align-items: center;
    justify-content: center;

    .closeLineOne,
    .closeLineTwo {
        position: absolute;
        width: 100%;
        height: setUnit(2);
        background: $text-color;
        display: inline-block;
        border-radius: setUnit(50);
    }

    .closeLineOne {
        transform: rotate(-45deg);
    }

    .closeLineTwo {
        transform: rotate(45deg);
    }
}
