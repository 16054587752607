@import 'styles/functions';
@import 'styles/variables';

.bookingCalendarHeader {
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0px;
  margin-bottom: 20px;

  p {
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0px;
  }

  .prevIcon,
  .nextIcon {
    display: inline-block;
    cursor: pointer;
  }
}
