@import 'styles/variables';
@import 'styles/functions';

.emptyCard {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: setUnit(260);
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  border-radius: 16px;
  background-color: white;

  .text {
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-family: $font-family;
    letter-spacing: setUnit(-0.18);
    color: $text--light-gray-color;
  }
}
