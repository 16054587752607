@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.main {
  .inputHolder {
    width: 100%;
    border-radius: 8px;
    height: 48px;
  }

  .actionButtons {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    gap: 8px;
    span {
      color: rgba(255, 255, 255, 0.8);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
    .update {
      width: 90px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.15);
    }
    .cancel {
      width: 90px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: #161b29;
      border: 1px solid #e8e9ea80;
    }
  }
}

.birthdayCalender {
  width: 100%;
  [class*='ant-picker-panel'],
  [class*='ant-picker-date-panel'],
  [class*='ant-picker-month-panel'],
  [class*='ant-picker-content'] {
    width: 100% !important;
  }
  [class*='ant-picker-year-btn'] {
    display: none;
  }
  [class*='ant-picker-header-super-prev-btn'] {
    display: none;
    min-width: 0px;
  }
  [class*='ant-picker-header-prev-btn'] {
    color: black;
    span::before {
      width: 10px;
      height: 10px;
      border-width: 2px 0 0 2px;
    }
  }
}
