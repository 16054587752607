@import "styles/functions";
@import "styles/variables";

.item {
    height: setUnit(80);
    transition: all .1s;
    display: flex;
    padding: setUnit(12) setUnit(32);
    cursor: pointer;
    color: $text-color;
    position: relative;

    &:hover {
        background-color: $hover-light-gray;
    }

    &.unread {
        &::before {
            content: "";
            position: absolute;
            background: $primary-color content-box;
            border-radius: 50%;
            width: setUnit(8);
            height: setUnit(8);
            margin-top: setUnit(23);
            left: setUnit(12);
        }

        .itemContentTitle {
            font-weight: 600;
        }

        .itemContentText,
        .itemContentTitleTime {
            font-weight: 600;
            color: $dark-color;
        }
    }

    .itemContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .itemContentTitle {
            font-size: setUnit(16);
            line-height: setUnit(21);
            display: flex;
            align-items: center;

            .itemContentTitleName {

            }

            .itemContentTitleTime {
                margin-left: auto;
                color: $text-gray-color;
                font-size: setUnit(12);
                line-height: setUnit(16);
                white-space: nowrap;
                padding-top: setUnit(2);
            }
        }

        .itemContentText {
            padding-top: setUnit(5);
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $dark-grey-color;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .itemImage {
        width: setUnit(56);
        height: setUnit(56);
        border-radius: 50%;
        background: $light-gray;
        position: relative;
        margin-right: setUnit(16);
        overflow: hidden;

        .itemImageAvatar {
            width: setUnit(56);
            height: setUnit(56);

            img {
                width: setUnit(56);
                height: setUnit(56);
            }
        }
    }
}
