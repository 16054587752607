@import 'styles/functions';
@import 'styles/variables';

.dueDate {
  width: setUnit(190);
  .label,
  .assignToInputLabel > div > label > span,
  .dueDateWrapper > label,
  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
  .dueDateWrapper {
    margin: 0 !important;
  }
}
