@import "styles/functions";
@import "styles/variables";

.wrapper {
    height: setUnit(160);
    width: 100%;

    &.hidden {
        height: setUnit(100);
    }

    border-bottom: 2px solid $light-gray;


    .chatHeaderInfo {
        display: flex;
        align-items: center;
        padding: setUnit(8) setUnit(32) 0 setUnit(32);

        &.hidden {
            display: none;
        }

        .chatHeaderAvatars {
            .avatars {
                .avatarWrapper {
                    margin-left: setUnit(-35);
        
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        
            .avatar {
                width: setUnit(56);
                height: setUnit(56);
        
                &.isNotSingle {
                    width: setUnit(50);
                    height: setUnit(50);
                }
            }
        
            .overflow {
                width: setUnit(50);
                height: setUnit(50);
            }
        }


        .chatHeaderTitles {
            margin-left: setUnit(16);
        }

        .chatHeaderTitle {
            font-weight: 600;
            font-size: setUnit(20);
            line-height: setUnit(26);
            color: $text-color;
            display: flex;
            align-items: center;
        }

        .chatHeaderSubTitle {
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $text-gray-color;
            padding-top: setUnit(4);
        }

        .offer {
            background-color: $yellow-color;
        }

        .chatHeaderTitleSpan {
            margin-right: setUnit(8);
        }        

        .chatType {
            font-weight: 500;
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $text-color;
            margin-left: setUnit(10);
            padding: setUnit(3) setUnit(6);
            border-radius: setUnit(17);
            background-color: $light-gray;
        }
    }
}
