@import 'styles/functions';
@import 'styles/variables';

.popup {
  width: setUnit(156);
  padding: 0;
  position: relative;
  z-index: 9;
}

.image {
  img {
    width: 100%;
    height: setUnit(80);
    object-fit: cover;
  }
}

.rating {
  color: $dark-grey-color;
  font-size: setUnit(14);
  line-height: setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .yelpIcon {
    width: setUnit(50);
    height: setUnit(25);

    svg {
      width: setUnit(50);
      height: setUnit(25);
    }
  }
}

.title {
  font-size: setUnit(14);
  line-height: setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: normal;

  b {
    font-weight: 600;
    color: $text-color;
    padding-right: setUnit(5);
  }

  span {
    flex: 1;
    font-weight: 300;
    color: $dark-grey-color;
    white-space: nowrap;
  }
}

.propertyPreview {
}

.customMapMarkerLabel {
  font-family: $font-family !important;
  color: white !important;
  font-size: setUnit(12) !important;
  font-weight: 600;
  line-height: setUnit(16);
}
