@import 'styles/variables';
@import 'styles/functions';

.activityLogWrapper {
  background: $white;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.03);
  border-radius: setUnit(12);
  overflow: hidden;
  padding: 12px 0;
  position: relative;
  .noDataFound {
    text-align: center;
    font-size: 20px;
    margin: 9rem;
  }
  .clarityActivityLogCard {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
