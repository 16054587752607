@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.clientsHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 setUnit(32);
  margin-top: setUnit(41);
  align-items: center;

  .title {
    margin: auto;
    font-weight: 600;
    font-size: 20px;
    margin-left: setUnit(12);
  }

  .titleContainer {
    display: flex;

    .selectIcon {
      svg {
        width: 32px;
        height: 32px;

        path,
        circle {
          stroke: $primary-color;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    height: setUnit(40);

    .icon {
      cursor: pointer;
    }
  }
}

.drawerHeaderSelect {
  & [class*='ant-select-selector'] {
    height: 40px !important;
    min-height: 40px !important;
    border: none !important;
    color: $text-color;
    border-radius: 40px !important;
    box-shadow: none !important;
    padding: 0px 13px !important;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $background-grey-2 !important;
      border: none !important;
    }

    & [class*='ant-select-selection-item'] {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      line-height: 40px;
      display: flex;
      align-items: center;
      padding-right: 20px;

      div {
        display: flex;
        margin-right: 8px;
      }
    }
  }

  & [class*='ant-select-arrow'] {
    color: $text-color;
    right: 14px;
    top: 51%;

    svg {
      height: 8px;
      width: 13.71px;
    }
  }

  & [class*='ant-select-open'] {
    border: none !important;

    & [class*='ant-select-selector'] {
      background-color: $background-grey-2 !important;

      &:hover {
        background-color: $background-grey-2 !important;
      }
    }

    & [class*='ant-select-arrow'] {
      top: 49%;
      transform: rotate(180deg);
    }
  }
}

.drawerHeaderDropdown {
  width: 235px !important;
  border-radius: 8px !important;

  & [class*='ant-select-item'],
  & [class*='ant-select-item-option-selected']:not(.ant-select-item-option-disabled) {
    padding: 7.5px;
    background-color: $white;
    border-color: $white !important;

    &:hover {
      background-color: $background-grey-2;
    }

    & [class*='ant-select-item-option-state'],
    & [class*='ant-select-item-option-content'] {
      background-color: transparent !important;
      display: flex;
      align-items: center;
      color: $dark-color;
      font-size: 16px;
    }

    .selectIcon {
      display: flex;
      margin-right: 12px;

      svg {
        width: 24px;
        height: 24px;

        path,
        circle {
          stroke: $dark-color;
        }
      }
    }
  }

  & [class*='ant-select-item-option-selected']:not(.ant-select-item-option-disabled) {
    & [class*='ant-select-item-option-content'] {
      color: $text-color;
    }

    .selectIcon {
      svg {
        path,
        circle {
          stroke: $primary-color;
        }
      }
    }
  }
}
