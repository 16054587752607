@import 'styles/variables';
@import 'styles/mixins';

.offerInformationModal {
  [class*='ant-modal-content'] {
    padding: 20px;
  }

  [class*='ant-modal-header'] {
    padding-bottom: 24px;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: 1px solid $light-gray;
  }

  .infoTitle {
    display: flex;
    justify-content: space-between;

    .addressInfo {
      .title {
        font-size: setUnit(18);
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.2px;
      }

      .subTitle {
        font-size: setUnit(16);
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.2px;
      }
    }
  }

  .agentDetails,
  .offerDeadline {
    padding: 16px 0px;
    border-bottom: 1px solid $light-gray;
  }

  .submissionNotes {
    padding: 16px 0px 20px;
  }
}

@media (min-width: $mobile-small) and (max-width: $mobile) {
  .offerInformationModal {
    width: 70% !important;
  }
}

@media (min-width: 390px) and (max-width: $mobile-small) {
  .offerInformationModal {
    width: 90% !important;
  }
}
