@import 'styles/functions';
@import 'styles/variables';

.parkingSpaces {
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 10px !important;
  margin: 15px 0 !important;

  .row {
    margin: 10px 0px 0px 2px !important;
  }

  h4 {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px !important;
  }
  .counterWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 10px;
    .icon {
      width: 4rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background: $background-grey-2;
      }
    }
    .add {
      border: 2px solid $light-gray;
      border-radius: 50%;
      path {
        fill: $dark-color;
      }
    }

    .remove {
      border: 2px solid $light-gray;
      border-radius: 50%;
      path {
        stroke: $dark-color;
      }
    }

    .number {
      font-size: 18px !important;
    }
    p {
      margin: 0;
    }
    svg {
      cursor: pointer;
    }
  }
  .switchActions {
    margin-top: -2rem;
    .garageSwitch {
      display: flex;
      justify-content: flex-end;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $text-color;
        margin-right: 0.75rem;
        margin-bottom: 1rem;
      }
    }
    button {
      margin: 0 !important;
    }
  }
}
