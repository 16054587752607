@import 'styles/functions';
@import 'styles/variables';

.addAddressFlowCard {
  padding: 40px 90px 60px 90px !important;
  flex-direction: column;
  cursor: initial !important;
  position: relative;

  .backWrapper {
    position: absolute;
    left: 30px;
    top: 30px;
    border-radius: 100px;
    border: 2px solid var(--Grayscale-20, #e8e9ea);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    cursor: pointer;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .continue {
    width: 100%;
    margin-top: 32px;
  }
  .title {
    margin-top: 20px;
    color: $text-color;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.33px;
    max-width: 401px;
  }
  .address {
    margin-top: 24px;
    width: 100%;
    background: $white;
    .popup {
      height: 100%;
    }
    .border {
      border-radius: 8px 0 0 8px !important;
      .placeholder {
        border-radius: 8px 0 0 8px !important;
      }
      input {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }
  .addBtn {
    background: #252d44;
    color: var(--REBRAND-Secondary-White, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.09px;

    width: 100%;
    border-radius: 8px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
  }

  .disabled {
    opacity: 0.3;
  }
}
