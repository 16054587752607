@import 'styles/variables';
@import 'styles/functions';

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: $transition;
  z-index: 9999;

  &.isOpen {
    pointer-events: auto;
    opacity: 1;

    .opacityLayer {
      opacity: 1;
    }
  }
}

.opacityLayer {
  background-color: rgba(#163c5a, 0.9);
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9998;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.content {
  background-color: #ffffff;
  max-width: setUnit(600);
  width: 100%;
  position: relative;
  z-index: 9999;
  transition: all 0.3s;
  margin: auto setUnit(10);
  border-radius: setUnit(24);
}

.innerHolder {
  max-height: 97vh;
  padding: setUnit(70) setUnit(90);
  overflow-y: auto;

  @media (max-width: $mobile-small) {
    padding: setUnit(60) setUnit(45);
  }

  @media (max-width: 400px) {
    padding: setUnit(50) setUnit(35);
  }
}
