@import 'styles/functions';
@import 'styles/variables';

.searchIcon {
  display: flex;
  margin-right: setUnit(8);
}

.searchIconWrapper {
  display: flex;
  align-items: center;
}

.schoolsDistrictSelect {
  & [class*='ant-select-selector'] {
    border: unset !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
    padding: 0.65rem 0;
    box-shadow: none !important;
    height: fit-content !important;
    & [class*='ant-select-selection-placeholder'] {
      left: 0;
    }
  }
}
.multiSelectStyling {
  width: 100%;
  & [class*='ant-select-selector'] {
    border: unset !important;
    border: 2px solid $border-light-gray-2 !important;
    padding: 8px 12px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    width: 100% !important;

    & [class*='ant-select-selection-placeholder'] {
      left: 10px;
    }
  }
}
