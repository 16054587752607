@import 'styles/variables';
@import 'styles/functions';

.item {
  padding: setUnit(12) setUnit(24);
  background-color: $white;
  transition: $transition;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  &:hover {
    background-color: $background-grey-2;
  }
  .date {
    font-size: setUnit(14);
    font-weight: 400;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
    color: $dark-color;
    margin-bottom: 0;
    margin-right: setUnit(14);
    flex: 0 0 setUnit(70);
  }
  .separator {
    margin-right: setUnit(8);
    position: relative;
    .icon {
      background-color: $background-grey-2;
      width: setUnit(32);
      height: setUnit(32);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: setUnit(100);
      svg {
        width: setUnit(20);
        height: setUnit(20);
        path {
          stroke: $dark-color;
        }
      }
    }
    &:after {
      content: '';
      width: setUnit(2);
      height: calc(100% - #{setUnit(32)});
      background-color: $light-gray;
      position: absolute;
      top: setUnit(32);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .separatorUnlined {
    margin-right: setUnit(8);
    position: relative;
    .icon {
      background-color: $background-grey-2;
      width: setUnit(32);
      height: setUnit(32);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: setUnit(100);
      svg {
        width: setUnit(20);
        height: setUnit(20);
        path {
          stroke: $dark-color;
        }
      }
    }
  }
  .info {
    flex: 0 0 calc(100% - #{setUnit(176)});
    .streetInfo {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      display: inline-flex;
      flex-flow: row wrap;
      color: $text-color;
      margin-bottom: setUnit(4);
      &:hover {
        text-decoration: underline;
      }
      .streetOne {
        font-weight: 600;
      }
      .streetTwo {
        padding-left: 2px;
      }
    }
    .agentInfo {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin-bottom: setUnit(4);
      span {
        font-weight: 500;
      }
    }
    .mlsInfo {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin-bottom: 0;
    }
    .notes {
      height: 0;
      opacity: 0;
      visibility: visible;
      display: none;
      transition: $transition;
      background-color: $background-gray;
      padding: setUnit(8) setUnit(10);
      margin: setUnit(4) 0;
      &.show {
        height: auto;
        opacity: 1;
        visibility: visible;
        display: block;
      }
      .note {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.18);
        border-radius: setUnit(8);
        span {
          font-weight: 500;
        }
        &.privateNote {
          font-style: italic;
        }
        &:not(:last-child) {
          margin-bottom: setUnit(12);
        }
      }
    }
    .notesToggler {
      font-size: setUnit(14);
      font-weight: 500;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      color: $link-blue;
      cursor: pointer;
      margin-top: setUnit(4);
    }
  }
  .action {
    margin-left: auto;
  }
  &:not(:last-child) {
    .separator {
      &:after {
        height: calc(100% + 24px);
      }
    }
  }
}

.pending {
  color: $primary-color;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: $primary-color-light;
  margin-left: 15px;
}
