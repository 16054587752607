@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  max-width: 600px;
  .text {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.8px;
    text-align: center;
    color: $background-blue;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}
