@import "styles/functions";
@import "styles/variables";

.typeForm {
    height: 100%;
    width: 100%;

    iframe {
        height: 100%;
        width: 100%;
    }
}
