@import 'styles/variables';
@import '../../mixins.module.scss';

.inputSelectModal {
  & [class*='ant-btn-primary'] {
    @include modalButton($secondary-color, $secondary-color-hover, $white, 600);
  }

  label {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .selectContainer {
    margin-top: 24px !important;
  }

  .select {
    width: 100%;
  }
}
