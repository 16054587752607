@import 'styles/variables';

.tagCountContainer {
  display: flex;

  .countContainer {
    background: $text-gray-color;
    color: white;
    min-width: 20px;
    height: 14px;
    font-family: $font-family;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    color: $white;

    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
  .pointer {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 4px solid $text-gray-color;
    border-bottom: 7px solid transparent;
  }
}
