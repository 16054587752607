@import 'styles/functions';
@import 'styles/variables';

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #f4f5f6;
  padding: 0 !important;

  .titleInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;

      /* Page Title */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;

      margin-bottom: 8px;
    }
    .subHeading {
      display: flex;
      align-items: center;
      gap: 4px;
      p {
        color: $text-color;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin: 0;
        span {
          cursor: pointer;
          color: $link-blue;
        }
      }
      .tooltipIcon {
        position: relative;
        bottom: 2px;
      }
    }
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    padding: 24px 40px;
    gap: 24px;
    .container {
      display: flex;
      gap: 24px;
      width: 100%;
      max-width: 1360px;
      .detailsRow {
        width: 356px;
        .detailsCard {
          position: sticky;
          top: 20px;
          .heading {
            width: 100%;
            text-align: center;
            color: $text-color;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.33px;
          }
          .tableRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            &.column {
              flex-direction: column;
              align-items: flex-start;
            }
            .editIcon {
              cursor: pointer;
              display: none;
              &.notes {
                position: absolute;
                bottom: 35px;
                right: 20px;
              }
            }
            &:hover {
              .editIcon {
                display: flex;
              }
            }
            .iconRow {
              display: flex;
              gap: 12px;
              align-items: center;
              .label {
                color: $dark-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.18px;
                margin: 0;
              }
            }
            .valueContainer {
              display: flex;
              gap: 8px;
              align-items: center;
              &.column {
                padding-left: 33px;
                padding-top: 4px;
              }
              .value {
                color: $text-color;
                text-align: right;
                &.column {
                  text-align: left;
                }
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.18px;
                margin: 0;
              }
            }
          }
        }
      }
      .cardsRow {
        flex: 1;
        .card {
          margin-bottom: 24px;
          .header {
            display: flex;
            justify-content: space-between;
            .headerText {
              .title {
                color: $text-color;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.33px;
                margin: 0;
                padding-bottom: 12px;
              }
              .value {
                color: $text-color;
                font-size: 28px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.09px;
                margin: 0;
                padding-bottom: 12px;
              }
              .change {
                color: $dark-color;
                font-size: 15px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: -0.09px;
                margin: 0;
                padding-bottom: 12px;
                span {
                  color: $hover-green;
                  font-weight: 600;
                }
              }
            }
            .headerActions {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .dropdown {
                border: none !important;
                padding: 0 10px !important;
                height: 35px !important;
                label[class~='ant-radio-wrapper'] {
                  padding: 14px 16px !important;
                  height: 44px !important;
                }
                span {
                  font-weight: 400 !important;
                  font-size: 14px !important;
                  line-height: 16px !important;
                  letter-spacing: -0.09px !important;
                  color: $text-color !important;
                }
              }
              .addButton {
                margin-top: 5px;
                cursor: pointer;
                color: $link-blue;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.33px;
                padding: 6px 10px;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                background: rgba(43, 89, 218, 0.08);
                span {
                  font-size: 16px;
                }
              }
            }
          }
          .tableRow {
            width: 100%;
            padding: 10px 12px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 12px;
            &:nth-child(odd) {
              background: $background-grey-2;
            }
            .column {
              flex: 1;
              .labelHeader {
                color: $text-gray-color;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                margin: 0;
              }
              .label {
                margin: 0;
                color: $dark-color;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.18px;
              }
              .value {
                margin: 0;
                color: $text-color;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.18px;
                span {
                  color: $green-color;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.18px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
