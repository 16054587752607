@import 'styles/functions';
@import 'styles/variables';
.wrapper {
  min-width: setUnit(244) !important;
  display: flex;
  flex-direction: column;
  gap: setUnit(20);
  .section {
    .title {
      display: flex;
      align-items: center;
      gap: setUnit(1);
      .icon {
        display: flex;
        & > svg > path,
        circle {
          stroke: $text-gray-color;
        }
      }
      & > span {
        color: $text-gray-color;
        font-weight: 600;
        font-size: setUnit(12);
      }
    }
    .list {
      list-style: none;
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: setUnit(10);
        white-space: nowrap;

        &:first-child {
          margin-top: 0;
        }
      }

      .name {
        font-size: setUnit(14);
        font-weight: 400;
      }

      .matchPercent {
        font-size: setUnit(12);
        font-weight: 600;
        width: setUnit(41);
        height: setUnit(24);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#6fcf97, 0.1);
        color: #6fcf97;
        border-radius: setUnit(12);
        margin-left: setUnit(20);

        &.green {
          background: rgba($green, 0.1);
          color: $green;
        }

        &.orange {
          background: rgba($primary-color, 0.1);
          color: $primary-color;
        }

        &.gray {
          background: rgba($dark-color, 0.1);
          color: $dark-color;
        }
      }
    }
  }
}
