@import 'styles/variables';

.noFavorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20% auto auto;

  .noFavoritesText {
    margin-top: 1.25rem;
    color: $text--light-gray-color;
    font-size: 16px;
  }
}
