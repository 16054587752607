@import 'styles/functions';
@import 'styles/variables';

.filterPopover {
  padding-top: 0;
}
.clientActivityCardFilterPopover {
  padding-top: 0;
  & [class*='ant-popover-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(12);
  }
  .filterHeader {
    display: flex;
    vertical-align: middle;
    margin-bottom: 20px;
  }
  .filterIcon {
    width: setUnit(18);
  }
  .titleText {
    margin-left: setUnit(11);
    margin-top: setUnit(2);
  }
  .dateRangePickerWrapperClientActivity {
    margin: 0 0 20px 0;
    max-width: 364px;
    padding: 0 6px;
    position: relative;
    right: 5px;
    & [class*='ant-picker-clear'] {
      svg {
        width: 24px;
        height: 19px;
      }
    }
  }
}

.filterPopoverContainer {
  width: setUnit(364);
  .clientActivityMultiselect {
    margin-left: 0;
    margin-right: 0;
    .activityDrawerMultiselect {
      & [class~='ant-select-selector'] {
        min-height: setUnit(40);
        height: auto;
        border: unset !important;
        border-bottom: 2px solid $border-input-middle-gray !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        & [class~='ant-select-selection-placeholder'] {
          left: 0;
        }
      }
    }
  }
}
.submitButton {
  width: fit-content !important;
  height: setUnit(40) !important;
  float: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.18px;
  border: 1px solid $secondary-color;
}

.resetButton {
  width: fit-content !important;
  height: setUnit(40) !important;
  border: 1px solid $secondary-color;
  color: $secondary-color;
  background-color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.18px;
  &:hover {
    background-color: #f8f9fa;
  }
}
