@import 'styles/functions';
@import 'styles/variables';

.list {
  list-style: none;
  margin-bottom: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: setUnit(10);
    white-space: nowrap;

    &:first-child {
      margin-top: 0;
    }
  }

  .name {
    font-size: setUnit(14);
    font-weight: 400;
  }

  .matchPercent {
    font-size: setUnit(12);
    font-weight: 600;
    width: setUnit(41);
    height: setUnit(24);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#6fcf97, 0.1);
    color: #6fcf97;
    border-radius: setUnit(12);
    margin-left: setUnit(20);

    &.green {
      background: rgba($green, 0.1);
      color: $green;
    }

    &.orange {
      background: rgba($primary-color, 0.1);
      color: $primary-color;
    }

    &.gray {
      background: rgba($dark-color, 0.1);
      color: $dark-color;
    }
  }
}
