@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  max-width: 540px;
  margin-bottom: 20px;
}

.answersContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.partnerSubheader {
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.09px;
}

.button {
  border-radius: 8px !important;
  max-width: 400px;
  width: 100%;
  padding: 16px 0px !important;
  height: fit-content !important;
  background: #fff !important;
  border: 2px solid #e8e9ea !important;
  color: #515151 !important;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.09px;
  &:hover {
    background: #fff2f3 !important;
    border: 2px solid $primary-color !important;
    color: #262626 !important;
  }
  &.active {
    background: #fff2f3 !important;
    border: 2px solid $primary-color !important;
    color: #262626 !important;
  }
}
