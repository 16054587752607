@import 'styles/functions';
@import 'styles/variables';

.numberOfSearches {
  color: $text--light-gray-color;
}

.CardsContainer {
  width: 100%; // Ensure the container is full width
  padding: 8px 4px;
  border-radius: 8px;
  justify-content: space-between;
  &:hover {
    background-color: $hover-light-gray;
  }
}

.ClientActiveSearchBlock,
.ClientInactiveSearchBlock {
  h3 {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(16);
    letter-spacing: -0.18px;
    text-align: center;
    margin-bottom: setUnit(32);
    @media (min-width: $mobile-small) {
      text-align: left;
    }
  }
  .ContentWrapper {
    height: 100%;
    overflow: auto;
    padding: 0 setUnit(32);
  }
  .loader {
    display: inline-flex;
    margin: setUnit(25);
    font-size: setUnit(8);
  }

  .noSaved {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    color: $text--light-gray-color;
  }
}

.ClientInactiveSearchBlock {
  .accordion {
    overflow: visible !important;
    border-bottom: 0;
    .inactiveTitle {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(16);
      letter-spacing: -0.18px;
      text-align: center;
      margin: 0;
      @media (min-width: $mobile-small) {
        text-align: left;
      }
    }
  }
}
