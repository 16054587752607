@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.signSequence {
  margin-top: 32px !important;
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 20px 16px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start !important;
  background-color: $background-ligh-gray-5;

  [class~='ant-radio'] {
    [class~='ant-radio-inner'] {
      border-width: 2px;
      box-shadow: unset !important;
    }
  }

  [class~='ant-radio-checked'] {
    [class~='ant-radio-inner'] {
      &::after {
        height: 12px !important;
        top: 46% !important;
        left: 25% !important;
      }
    }

    ~ span {
      color: $text-color;
    }
  }
}
