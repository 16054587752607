@import 'styles/functions';
@import 'styles/variables';

.border {
  border-radius: 8px !important;
  .placeholder {
    border-radius: 8px !important;
  }
  input {
    border-radius: 8px !important;
  }
}

.inviteModal {
  width: 800px !important;
  @media (min-width: 1600px) {
    width: 1200px !important;
    height: 800px !important;
  }
  & [class*='ant-modal-content'] {
    @media (min-width: 1600px) {
      width: 1200px !important;
      height: 800px !important;
    }
  }
  .inviteHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    svg {
      path {
        stroke: $hover-red;
      }
    }
  }
  .mapContainerHeading {
    font-weight: bold;
  }
  .wrapper {
    max-height: 80vh;
    overflow-y: auto;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: setUnit(24);
    width: 100%;
    margin-top: setUnit(32);

    .button {
      max-width: setUnit(158);
      width: 100%;
      padding: setUnit(16), setUnit(54);
      border-radius: setUnit(8);
      font-size: setUnit(16);
      font-weight: 600;
    }
    .cancelButton {
      background-color: $background-grey-2;
      color: $text-color-light;
    }
  }
  .map {
    height: setUnit(164);

    .mapControls {
      margin: setUnit(18);
    }
  }
}

.inviteHeading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.mapWrapper {
  border-radius: setUnit(8);
  margin-top: setUnit(10);
  overflow: hidden;
  position: relative;
  height: setUnit(250);
  @media (min-width: 1600px) {
    height: 530px !important;
  }

  @media (min-width: $mobile) {
    height: setUnit(380);
  }
  .map {
    width: 100%;
  }
  .mapControls {
    position: absolute;
    bottom: setUnit(10);
    right: 50%;
    transform: translateX(50%);
    .buttonWithText {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $hover-red;

      background: $white;
      box-shadow: 0 setUnit(8) setUnit(16) rgba(61, 61, 61, 0.1);
      border-radius: setUnit(8);
      padding: setUnit(10);

      .icon {
        display: flex;
        align-items: center;
        svg {
          width: setUnit(24);
          height: setUnit(24);
          path {
            stroke: $hover-red;
          }
        }
      }
    }
    .mapControlContainer {
      display: flex;
    }
  }
}
