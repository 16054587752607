@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.tooltip {
  div[class~='ant-tooltip-content'] {
    width: auto !important;
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 50vw;

      p {
        color: white !important;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.info {
  margin-bottom: 4px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: setUnit(10);
  position: relative;
  width: fit-content;
  gap: 15px;

  .pendingWrapper {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .loader {
    font-size: setUnit(1.7);
    margin: 0;
    color: $secondary-color;

    &.isChecked {
      color: #ffffff;
    }
  }

  &.right {
    flex-direction: row-reverse;
  }

  label {
    @include label;

    margin-bottom: 0;
    display: inline-flex;
  }
}

.closeIcon {
  display: flex;
  &.isChecked {
    svg {
      path {
        fill: $dark-grey-color;
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $text--light-gray-color;
    }
  }
}

.checkboxDom {
  position: absolute;
  left: -10000px;
  display: none;

  & + .customCheckboxWrapper {
    position: relative;
    &.checkbox {
      width: setUnit(24);
      height: setUnit(24);
      .customCheckbox {
        width: 100%;
        height: 100%;
        border: 1.5px solid $light-grey-color;
        border-radius: setUnit(4);
      }

      .checkmark {
        display: none;
      }
    }
    &.radio {
      width: setUnit(24);
      height: setUnit(24);
      .customCheckbox {
        width: 100%;
        height: 100%;
        border: 1.5px solid $text--light-gray-color;
        border-radius: 50%;
      }
      .checkmark {
        display: none;
      }
    }
  }

  &:checked {
    & + .customCheckboxWrapper {
      &.checkbox {
        .customCheckbox {
          width: 100%;
          height: 100%;
          border: none;
          background: $primary-color;
          position: relative;
          &.dark {
            background: $secondary-color;
          }
          &.confettiBlue {
            background: $confetti-blue;
          }
        }

        .checkmark {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 60%;
            height: 60%;
          }
        }
      }

      &.radio {
        .customCheckbox {
          width: 100%;
          height: 100%;
          border: none;
          background: $primary-color;

          &.dark {
            background: $secondary-color;
          }
        }

        .checkmark {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 70%;
            height: 70%;
          }
        }
      }

      &:hover {
        opacity: 0.7;
        &.dark {
          background: $secondary-color-hover;
        }
      }
    }
  }
}
