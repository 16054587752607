@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$question-header-height: 72px;
$question-footer-height: 100px;

.bundleFormslistWrapper {
  background: $white;
  max-width: 19.85%;
  min-width: 19.85%;
  position: fixed;
  z-index: 1;

  height: calc(100vh - $question-footer-height - $question-header-height);

  box-shadow: 0 setUnit(3) setUnit(12) -5px rgba($black, 0.15);
  cursor: default;

  @media screen and (max-width: $mobile) {
    position: fixed;
    max-width: unset;
    min-width: none;
    width: 100vw;
    z-index: 3;
    height: 100vh;
  }
}
