@import 'styles/functions';
@import 'styles/variables';
.attachmentTime {
  margin-top: 0.3125rem !important;
  position: absolute;
  z-index: 5;
  bottom: 12px;
  color: white;
  right: 12px;
}
.attachmentFileTime {
  color: $dark-grey-color !important;
}
.attachedFilePreview {
  min-width: setUnit(255);
}
.propertyHolder {
  font-size: setUnit(12);
  color: $text-gray-color;
  line-height: 0;
  font-weight: 500;
  bottom: setUnit(2);
  margin-right: auto;
}
.messageWrapper {
  margin-bottom: 24px;

  .messageText {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.18px;
    position: relative;
    color: $text-color;
    margin-bottom: 15px;

    .emoji {
      font-size: setUnit(25);
      height: setUnit(25);
      vertical-align: middle;
      display: inline-block;
      padding-top: setUnit(2);
    }

    .tagHyperLink {
      color: #2b59da;

      &:hover {
        text-decoration: underline;
        text-decoration-color: #2b59da;
      }
    }

    // only applied to tooltip inside message text wrapper
    :global {
      .ant-tooltip-inner {
        border-radius: 16px;
        padding: setUnit(12) setUnit(16) setUnit(12) setUnit(16);
      }
    }
  }

  .attachmentHolder {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .attachmentInner {
    display: flex;
    position: relative;
  }

  .actions {
    visibility: hidden;
  }

  .messageHolder {
    display: flex;
    align-items: flex-end;
    .avatarWrapper {
      bottom: 0px;
    }
  }

  .messageInner {
    background: $background-ligh-gray;
    padding: 8px 12px 11px;
    border-radius: setUnit(16);
    word-break: break-word;

    .propertyList {
      display: flex;
      flex-direction: row;
      row-gap: 10px;
    }

    .participant {
      font-size: setUnit(16);
      color: $text-gray-color;
      font-weight: 600;
      margin-bottom: 0px;
    }
  }

  .avatarWrapper {
    position: relative;
    bottom: 24px;
    font-size: setUnit(14);
    margin-right: 8px;
  }

  .avatar {
    width: setUnit(32);
    height: setUnit(32);
    visibility: visible;

    &.hideAvatar {
      visibility: hidden !important;
    }
  }

  .messageOuterHolder {
    display: flex;
    justify-content: left;
  }

  .time {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;

    float: right;
    margin-top: setUnit(-15);

    .groupedTime {
      color: $dark-grey-color;
    }
    .name {
      font-weight: 600;
      margin-right: 4px;
    }
  }

  .editedMessageIndicator {
    margin-right: setUnit(4);
    margin-left: setUnit(4);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #676767;
  }

  &:last-child {
    .avatar {
      visibility: visible;
    }
  }

  .propertyWrapper {
    width: max-content;
    margin-left: 0;
    margin-right: auto;
  }
  .rightAlign {
    margin-left: auto;
    margin-right: 0;
  }

  &.isEditMessage {
    opacity: 0.5;
  }

  .messageHolder,
  .attachmentHolder {
    &:hover {
      .actions {
        visibility: visible;

        &.noAction {
          visibility: hidden;
        }
      }
    }
  }

  &.isOwnMessage {
    margin-left: auto;

    .attachmentOuter {
      margin-left: auto;
      justify-content: right;

      &.withActions {
        cursor: pointer;
      }
    }

    .messageInner {
      background: rgba(70, 115, 209, 0.12);
      display: flex;
      justify-content: space-between;
    }

    .messageOuter {
      margin-left: auto;

      &.withActions {
        cursor: pointer;
      }
    }

    .messageOuterHolder {
      display: flex;
      justify-content: right;
    }

    .avatarWrapper {
      margin-right: 0;
      margin-left: setUnit(8);
    }

    .time {
      text-align: right;
      float: right;

      margin-top: setUnit(-15);

      .groupedTime {
        color: $dark-grey-color;
      }
      .sentTick {
        align-items: flex-end !important;
      }
    }
  }
}
