@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins.scss';

.lineSeparator {
  background: $light-gray;
  height: 1px;
  margin: 0 2rem;
}

.submissionForm {
  .progressBar {
    display: none;
  }
  height: 100%;
}

.wrapper {
  width: 100%;

  input {
    padding: 0px !important;
  }

  input,
  textarea {
    &::placeholder {
      color: $text--light-gray-color !important;
      font-weight: 400;
    }

    border-bottom: 2px solid $light-gray !important;

    &:focus {
      border-bottom: 2px solid $text--light-gray-color !important;
    }
  }
}

.inputsWrapper {
  overflow: auto;

  h4 {
    font-weight: 700;
    font-size: 16px;
  }
}

.inputBlock {
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(30);

  .tagInput {
    width: 100%;
  }

  &.description {
    align-items: end;

    .label {
      color: $dark-color;
      margin-top: setUnit(25);
    }
  }
}

.checkboxBlock {
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(40);

  .checkboxArea {
    margin-top: setUnit(25);

    .checkbox {
      width: 30px;
      display: inline-block;
      vertical-align: top;
    }

    .checkboxLabel {
      color: $dark-color;
      display: inline-block;
    }
  }
}

.inputHolder {
  input {
    height: setUnit(44) !important;
  }
}

.offsetHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    padding-left: setUnit(40) !important;
    height: setUnit(44);
  }
}

.offsetIcon {
  position: absolute;
  left: 0;

  svg {
    vertical-align: middle;
    width: setUnit(32) !important;
    height: setUnit(32) !important;
  }
}

.description {
  height: auto;
}

.label {
  white-space: nowrap;
}

.links,
.documents {
  min-height: setUnit(44);
  height: auto;
}

.documents {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    color: $dark-color;
    margin-left: 0;
  }
}

.input {
  width: 100%;
  padding: 0px;
}

.inputPhone {
  width: 100%;
  padding: 0px;
  border-width: 2px;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-color: #e8ecef;
  outline: none;
  margin: 0;
  vertical-align: middle;
  color: #262626 !important;
  font-size: 1rem;
  line-height: 1.3125rem;
  border-radius: 0rem !important;
  background: none !important;
  border: none !important;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uploadDocument {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin-top: setUnit(5);

  .icon {
    width: setUnit(32);
    height: setUnit(32);
    background: $background-ligh-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: setUnit(16);

    svg {
      vertical-align: middle;

      path {
        stroke: $secondary-color;
      }
    }
  }

  .text {
    color: $secondary-color;
    font-size: setUnit(14);
  }

  .fileNew {
    display: flex;
    align-items: center;
    margin-top: setUnit(20);
    cursor: pointer;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 25px;
    background-color: $background-ligh-gray-2;

    .uploadFileText {
      font-size: setUnit(14);
      margin-left: setUnit(11);
      color: $secondary-color;
    }

    .uploadFileIcon {
      width: setUnit(18);
      height: setUnit(18);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fileNew:hover {
    transition: all 0.1s;
    background-color: $background-ligh-gray;
  }
}

.continueBtn {
  border: none;
  outline: none;
  background-color: $background-blue;
  color: $white;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.1s;
  padding: 15px 50px;
}

.continueBtn:hover {
  background-color: #1d4f76;
}
