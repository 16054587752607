@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.notes {
  margin-right: setUnit(24);

  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    padding: setUnit(16) setUnit(24);

    background-color: $background-ligh-gray-5;

    .container {
      margin-bottom: setUnit(12);

      .checkbox {
        margin: 0;

        & [class*='ant-checkbox'] {
          top: 0px;
          & [class*='ant-checkbox-inner'] {
            border: 1.5px solid $text--light-gray-color;
            border-radius: 4px;
            width: setUnit(24);
            height: setUnit(24);
            background-color: #fff;
            &:after {
              transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
              left: 25%;
            }
          }
        }
        & [class*='ant-checkbox-checked'] {
          &:after {
            border-color: $primary-color;
          }
          & [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color;
          }
        }
      }

      .noteTitle {
        font-size: setUnit(16);
        color: $dark-color;
      }

      .noteSubtitle {
        font-size: setUnit(14);
        color: $text--light-gray-color;
      }

      .textArea {
        margin-top: 12px;
        margin-bottom: 12px;
        color: $text-color;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.09px;
        border-radius: 8px;
        border-color: $border-input-middle-gray;
        transition: $transition;
        padding: 14px 16px;
        &:focus,
        &:hover {
          border-color: $border-focused;
          box-shadow: none;
          outline: 0;
        }
        &::placeholder {
          color: $text--light-gray-color;
          font-weight: 400;
          font-size: 16px;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
