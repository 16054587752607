@import 'styles/functions';
@import 'styles/variables';

.cardWrap {
  background-color: $white;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.03);
  border-radius: setUnit(12);
  padding: setUnit(20) setUnit(24);
  min-height: setUnit(170);
  .cardheader {
    margin-bottom: setUnit(20);
    h3 {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(24);
      letter-spacing: -0.33px;
      color: $text-color;
      span {
        color: $text--light-gray-color;
      }
    }
  }
  .cardBody {
    min-height: calc(#{setUnit(170)} - #{setUnit(84)});
  }
  .cardFooter {
    .viewAll {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.09px;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      color: $text-color;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        color: $dark-color;
        .viewAllIcon {
          path {
            stroke: $dark-color;
          }
        }
      }
      .viewAllIcon {
        margin-right: setUnit(12);
        width: setUnit(32);
        height: setUnit(32);
        background-color: $background-grey-2;
        border-radius: setUnit(100);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
