@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  border-bottom: 2px solid $border-light-gray;
  padding-bottom: setUnit(28);
  margin-bottom: setUnit(32);
}

.updateAvatar {
  margin-bottom: 0 !important;
  span {
    font-size: setUnit(14);
    display: block;
    margin-top: setUnit(16);
    text-align: center;
    transition: all 0.1s;
  }
  div {
    font-size: setUnit(28);
    width: setUnit(112) !important;
    height: setUnit(112) !important;
  }

  &:hover span {
    text-decoration: underline;
  }
}

.profileWrapper {
  display: flex;
  margin: 0 setUnit(-20);
  .leftBlock {
    min-width: setUnit(152);
    padding: 0 setUnit(20);
    img {
      width: setUnit(112);
      height: setUnit(112);
      border-radius: 50%;
    }
  }

  .rightBlock {
    width: 100%;
    padding: 0 setUnit(20);
    .name {
      font-weight: 600;
      font-size: setUnit(16);
      padding-bottom: setUnit(19);
    }
    .bio {
      padding-bottom: setUnit(32);
    }
    .antdSelect {
      width: 100% !important;
      max-width: setUnit(350);
    }
  }
}

.saveBtn {
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  height: setUnit(44) !important;
  width: setUnit(78) !important;
  border-radius: setUnit(100) !important;
  border: 2px solid $secondary-color !important;
  @include transition;
  &:hover {
    background-color: transparent !important;
    color: $secondary-color;
  }
}

.editBtn {
  border: 0;
  outline: none;
  border-radius: 100%;
  width: setUnit(40);
  height: setUnit(40);
  background-color: $primary-color;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.label {
  flex: 1;
  font-size: setUnit(14);
  color: $dark-grey-color;
  align-self: flex-start;
  padding-top: setUnit(14);
  padding-right: setUnit(18);
}

.editLabel {
  font-size: setUnit(14);
  font-weight: 400;
  color: $dark-grey-color;
  line-height: setUnit(16);
  letter-spacing: -0.09px;
  margin-bottom: setUnit(8);
  @media (min-width: $mobile) {
    flex: 1;
    margin-bottom: 0;
  }
}

.inputBlock {
  display: flex;
  min-height: setUnit(44);
  align-items: center;
  margin-bottom: setUnit(10);

  .errorContainer {
    color: $error-color;
    font-size: setUnit(14);
  }
  .rightBlock {
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding: 0;
    flex: 1 0 100%;
    margin-left: setUnit(15);
    @media (min-width: $mobile) {
      flex: 3;
    }
    .input {
      margin: 0 0 0 0 !important;
      max-width: setUnit(350);
    }
    .inputField {
      background-color: transparent;
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid $background-ligh-gray-4;
      padding: setUnit(12) setUnit(50) setUnit(12) 0;
      font-weight: 400;
      line-height: setUnit(21);
      letter-spacing: -0.18px;
      color: $text-color;
      height: setUnit(44);
      &:focus {
        border: 0 !important;
        border-bottom: 2px solid $background-ligh-gray-4 !important;
      }
    }
    .inputTextarea {
      background-color: transparent;
      height: setUnit(48);
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid $background-ligh-gray-4;
      padding: setUnit(12) setUnit(50) setUnit(12) 0;
      font-weight: 400;
      line-height: setUnit(21);
      letter-spacing: -0.18px;
      color: $text-color;
    }
    .inputFieldLocations {
      background-color: transparent;
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid $background-ligh-gray-4;
      padding: setUnit(12) setUnit(50) setUnit(12) 0;
      height: auto;
    }
  }

  .phone,
  .officeAddress {
    max-width: setUnit(340);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .infoBlockForOneLine {
    flex: 1 0 100%;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    font-weight: 400;
    letter-spacing: -0.18px;
    border-bottom: 2px solid $light-gray;
    padding-bottom: setUnit(12);
    @media (min-width: $mobile) {
      flex: 3;
    }
    a {
      color: $text-color;
      transition: all 0.1s;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .infoBlockForMultipleLines {
    flex: 1 0 100%;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    border-bottom: 2px $light-gray solid;
    @media (min-width: $mobile) {
      flex: 3;
    }
  }

  .nonEditableValue {
    padding: setUnit(10) 0;

    &:not(:last-child) {
      margin-bottom: setUnit(2);
    }

    a {
      color: $text-color;
      transition: all 0.1s;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.subTitle {
  font-weight: 600;
  font-size: setUnit(20) !important;
  line-height: setUnit(26);
  margin-bottom: setUnit(16);
  display: block;
}

.companyWrapper {
  margin-top: setUnit(40);
}

.links {
  margin-top: setUnit(26);
}

.linkInput {
  margin-bottom: setUnit(16);
}

.avatar {
  width: setUnit(120);
  height: setUnit(120);

  div {
    font-size: setUnit(28);
    width: setUnit(120);
    height: setUnit(120);
  }
}

.locationError {
  flex-basis: 100%;
  padding-left: 25%;
  display: flex;
}

@media screen and (max-width: $tablet) {
  .profileWrapper {
    flex-wrap: wrap;
  }

  .leftBlock {
    margin-left: auto;
    margin-right: auto;
  }
}

.geoBlocks {
  display: flex;
  & > div:first-child {
    width: 100%;
  }
}
