@import 'styles/functions';
@import 'styles/variables';

.filesFilterPopover {
  font-size: setUnit(16) !important;
  padding-top: setUnit(0) !important;
  width: 384px !important;
  z-index: 99999 !important;

  @media screen and (max-width: $mobile-small) {
    width: 80dvw !important;
    left: -70px !important;
  }

  & [class*='ant-popover-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(24);
    padding-right: 0;
  }

  .header {
    display: flex;
    vertical-align: middle;
    font-size: setUnit(16);

    .icon {
      width: setUnit(20);
      height: setUnit(20);

      line-height: 23px;
    }

    .title {
      margin-left: setUnit(11);
      font-weight: 600;
      color: $text-color;
    }
  }

  .container {
    max-height: 75vh;
    overflow-y: auto;
    padding-right: 1.5rem;

    .multiSelectWrapper {
      margin-left: 0;
      margin-right: 0;

      .multiSelect {
        [class~='ant-select-selector'] {
          border: 1px solid $border-light-gray-2 !important;
          box-shadow: none !important;
          padding: setUnit(7) setUnit(13) !important;
          border-radius: 8px !important;
          font-size: setUnit(16) !important;
          height: setUnit(48) !important;

          [class~='ant-select-selection-overflow'] {
            margin-top: -5px;
          }
          [class~='ant-select-selection-search'] {
            margin-inline-start: 0;
          }

          &:focus {
            border-color: $text--light-gray-color !important;
          }

          [class~='ant-select-selection-placeholder'] {
            color: $light-gray-2;
            left: 14px;
            span {
              padding-left: 0px !important;
            }
          }
        }
      }
    }

    .dateRangePicker {
      margin-left: 0;
      margin-right: 0;

      & [class*='ant-picker'] {
        padding-top: 0;
        padding-bottom: 0;
        padding: 0px;
      }

      [class*='ant-picker-input'] {
        border-radius: 8px;
        height: unset;
        padding: 0 setUnit(14) !important;
        gap: 4px;
        align-items: flex-end;

        input {
          border: unset !important;
          order: 1;
          font-size: setUnit(16);

          &::placeholder {
            font-size: setUnit(16);
          }
        }

        [class*='ant-picker-suffix'] {
          margin-left: 0px;
          span {
            display: block !important;
            color: $text--light-gray-color !important;
          }
        }

        [class*='ant-picker-clear'] {
          span {
            display: block !important;
            margin-right: setUnit(8) !important;
          }
        }
      }

      [class~='ant-picker-focused'] {
        box-shadow: none !important;
      }

      .icon {
        line-height: 0px;
      }

      .columnsContainer {
        gap: 3px;
      }
    }

    .inputContainer {
      margin-bottom: setUnit(8);

      input {
        border: 0px;
        border-bottom: 2px solid $border-light-gray-3;
        border-radius: 0;
      }

      label {
        margin: 0 setUnit(45) 0 0;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $text-color;
        }
      }
    }
  }

  .footer {
    padding-right: 1.5rem;

    & [class*='ant-btn'] {
      margin-left: auto;
      height: setUnit(40) !important;
      font-size: setUnit(16);
    }

    .submitButton {
      width: setUnit(81) !important;
      float: right;
      border-radius: 0.5rem;
    }

    .resetButton {
      width: setUnit(44) !important;
      margin-left: auto;
      border: none;
      padding: 0;
      text-align: left;

      &:hover {
        color: $hover-blue;
      }
    }
  }
}
