@import 'styles/functions';
@import 'styles/variables';

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachmentsPreview {
  padding: setUnit(8) setUnit(15) setUnit(15);
  align-items: center;
  display: flex;
  width: 100%;
  overflow: auto;
  position: relative;
  //top: setUnit(20);
  max-width: calc(100% - #{setUnit(65)});

  .attachment {
    margin-right: setUnit(10);
    font-size: setUnit(14);
    font-weight: 600;

    .closeIcon {
      position: absolute;
      right: setUnit(-8);
      top: setUnit(-8);
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    .file {
      padding: 0 setUnit(16);
      height: setUnit(52);
      border: 1px solid $border-middle-gray;
      border-radius: setUnit(8);
      max-width: setUnit(235);
      display: flex;
      align-items: center;
      position: relative;

      .icon {
        margin-right: setUnit(16);
      }
    }

    .image {
      border-radius: setUnit(8);
      position: relative;

      img {
        width: setUnit(70);
        height: setUnit(52);
        object-fit: cover;
        border-radius: setUnit(8);
        vertical-align: middle;
      }
    }
  }
}
