@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.downloadFilesModal {
  [class~='ant-modal-title'] {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.47px;
    color: $text-color;
  }

  [class~='ant-modal-content'] {
    padding-top: 37px !important;

    [class~='ant-modal-header'] {
      margin-bottom: 37px;
    }

    [class*='ant-modal-close'] {
      [class*='ant-modal-close-x'] {
        top: 3px;

        path {
          stroke: $text-color;
        }
      }
    }

    .downloadFilesModalContent {
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        letter-spacing: -0.18px;
        text-align: left;
        width: fit-content;
      }

      .blackText {
        color: $text-color;
      }

      .combineAllCheckbox {
        color: $dark-color;
      }

      .filesCheckboxes {
        margin-top: 16px;

        .checkboxGroup {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }
      }

      .allFormInFile {
        opacity: 0.5;
        pointer-events: none;
      }

      .lineSeparator {
        height: 1px;
        background-color: $border-light-gray-2;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .downloadButton {
        width: 152px;
        height: 52px;
        border-radius: 8px !important;
        display: block;
        margin: 40px auto 0px;

        font-size: setUnit(16) !important;
        line-height: 24px !important;
      }

      .disabledButton {
        opacity: 0.3;
      }

      & [class*='ant-checkbox-wrapper'] {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        margin-left: 0px;

        margin-right: 0;
        gap: 12px;

        & [class*='ant-checkbox'] {
          top: 0px;

          & [class*='ant-checkbox-inner'] {
            border: 1.5px solid $text--light-gray-color;
            border-radius: 4px !important;
            width: setUnit(24);
            height: setUnit(24);

            &:after {
              transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
              left: 25%;
              top: 47%;
              width: setUnit(6);
              height: setUnit(12);
            }
          }

          & + span {
            padding-right: 0;
            padding-left: 0px;

            .formName {
              font-weight: 500px;
              color: $dark-color;
              line-height: 20.8px;
              font-size: setUnit(16);
              letter-spacing: -0.18px;
            }

            .fileCount {
              color: $text-gray-color;
              line-height: 16px;
              margin-top: 2px;
              font-size: setUnit(14);
              letter-spacing: -0.18px;
            }
          }
        }

        & [class*='ant-checkbox-checked'] {
          &:after {
            border-color: $primary-color;
            border-radius: 100% !important;
          }

          & [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color !important;

            &::after {
              border-color: $white !important;
            }
          }

          & + span {
            color: $text-color;

            .formName {
              color: $text-color;
            }

            .fileCount {
              color: $text-gray-color;
            }
          }
        }

        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .progressBar {
    display: none !important;
  }
}
