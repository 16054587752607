@import 'styles/functions';
@import 'styles/variables';

.errorSockets {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);

    .errorSocketsIcon {
        padding-bottom: setUnit(24);

        svg {
            height: setUnit(32);
            width: setUnit(32);
        }
    }
    
    .errorSocketsTitle {
        color: $border-middle-gray;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
    }

    .apiErrorStyle {
        color: $border-middle-gray;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding: 0;
        background-color: $white;
    }
}