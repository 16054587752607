@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.shareWrapper {
  @include action-icon();
  border-radius: setUnit(16);
  cursor: pointer;
  margin: 0 setUnit(4);
  height: setUnit(32);
  min-width: setUnit(32);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    vertical-align: middle;
  }
}
