@import 'styles/functions';
@import 'styles/variables';

.popup {
  width: setUnit(156);
  padding: 0;
  position: relative;
  z-index: 9;
}

.rating {
  color: $dark-grey-color;
  font-size: setUnit(14);
  line-height: setUnit(16);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .yelpIcon {
    width: setUnit(50);
    height: setUnit(25);

    svg {
      width: setUnit(50);
      height: setUnit(25);
    }
  }
}

.title {
  font-size: setUnit(14);
  line-height: setUnit(16);
  display: flex;
  justify-content: space-between;
  white-space: normal;

  b {
    font-weight: 600;
    color: $text-color;
    padding-right: setUnit(5);
  }

  span {
    flex: 1;
    font-weight: 300;
    color: $dark-grey-color;
    white-space: nowrap;
  }
}

.propertyPreview {
  max-width: setUnit(276) !important;
  width: 100%;
}

.customMapMarkerLabel {
  font-family: $font-family !important;
  color: white !important;
  font-size: setUnit(12) !important;
  font-weight: 600;
  line-height: setUnit(16);
  &.neighborhoodActivity {
    position: relative;
    left: 4px;
  }
}

.markerCard {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: setUnit(276);
  width: 100%;
  .propertyImg {
    flex: 0 0 100%;
    img {
      width: 100%;
      height: setUnit(140);
      object-fit: cover;
    }
  }
  .popupContainer {
    padding: setUnit(12) setUnit(16) setUnit(20);
    width: calc(100% - 32px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .agentPropertyInfo {
      width: 100%;
    }
    .propertyInfo {
      .featureWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 8px 0 12px;
        li {
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-family: $font-family;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: $text-color;
          &:not(:last-child) {
            &:after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 6px;
              border-radius: 50%;
              background: $text-color;
            }
          }

          span {
            font-weight: 600;
            margin-right: 4px;
          }
        }
      }
      .city {
        color: $text-gray-color;
        margin-bottom: 0;
      }
    }
    .priceWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .price {
        width: calc(100% - #{setUnit(85)});
      }
      .matchScore {
        font-size: setUnit(12);
        min-width: unset;
        width: auto;
        height: auto;
        padding: 4px;
        padding: setUnit(4) setUnit(8);
        font-weight: 400;
      }
    }
    .meta {
      width: 110px;
      margin-top: 12px;
      .metaTop {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;
        .agentFavorite {
          cursor: pointer;
          background: none;
          border: 1px solid $border-light-gray-2;
          &:hover {
            border-color: $text--light-gray-color;
          }
        }
      }
    }
    .metaBottom {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: auto;
    }
  }
}
