@import 'styles/functions';
@import 'styles/variables';

.changeQuestion {
  opacity: 0;
  z-index: -1;
  display: none;
  transition: $transition;
  &.show {
    opacity: 1;
    z-index: 1;
    display: block;
  }
}
