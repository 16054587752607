@import 'styles/variables';
.MegaPhoneModal {
  [class*='ant-modal-content'] {
    [class*='ant-modal-close'] {
      top: 28px !important;

      [class*='ant-modal-close-x'] {
        top: 0px !important;
      }
    }
  }

  [class*='ant-modal-title'] {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .MegaPhoneModalBody {
    padding: 37px 20px 16px 20px;
    height: 700px;
    overflow-y: auto;
  }
  .messageInput {
    border-radius: 8px;
    &:hover {
      border-color: $primary-color !important;
      box-shadow: none !important;
    }
  }
  .sendTo {
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: $text-color;
  }
  .signatureCheckbox,
  .editorCheckbox,
  [class*='ant-checkbox-group'] > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
  [class*='ant-checkbox-inner'] {
    transform: scale(1.2);
    &:hover {
      border: none !important;
    }
  }
  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }
  [class*='ant-checkbox-indeterminate'] {
    [class*='ant-checkbox-inner'] {
      &:after {
        background-color: $white !important;
      }
    }
  }
  .ant-checkbox-inner::after [class*='ant-checkbox-group'] {
    display: table;
  }
  [class*='ant-checkbox-group'] > label {
    display: flex;
    padding-bottom: 15%;
  }
  [class*='ant-collapse-header'] {
    padding: 0 !important;
  }
  .sendBtnDiv {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
  }
  .sendBtn {
    height: 52px;
    width: 154px;
    background: $secondary-color;
    border-radius: 8px;
    cursor: pointer;
    border: none;
  }
  .sendBtn > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $white;
  }
  .disabledBtn {
    opacity: 50%;
  }
}
