@import 'styles/functions';
@import 'styles/variables';

.tasksTab {
  div[class='ant-tabs-nav'] {
    &:before {
      content: none;
    }
    & [class='ant-tabs-nav-wrap'] {
      &:before,
      &:after {
        content: none;
      }
      & [class='ant-tabs-nav-list'] {
        & [class='ant-tabs-tab'],
        & [class='ant-tabs-tab ant-tabs-tab-active'] {
          & [class='ant-tabs-tab-btn'] {
            font-weight: 600;
            font-size: setUnit(14);
            line-height: setUnit(20);
            letter-spacing: -0.33px;
            color: $text-gray-color;
            text-shadow: none;
          }
        }
        & [class='ant-tabs-tab ant-tabs-tab-active'] {
          & [class='ant-tabs-tab-btn'] {
            color: $text-color;
          }
        }
        & [class='ant-tabs-ink-bar ant-tabs-ink-bar-animated'] {
          background: $primary-color;
        }
      }
    }
  }
}
