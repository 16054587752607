@import 'styles/functions';
@import 'styles/variables';

.counterOffers {
  width: '100%';

  .counterOfferView {
    margin-left: 50px;
    left: 14.22%;
    right: 78.22%;
    top: 68.63%;
    bottom: 15.69%;
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: #2b59da;
  }

  .offereName {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 1%;
  }

  .offerReceivedAndPrice {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $dark-color;
  }

  .offerTreeViewHeightFirstNode {
    height: 40px;
    width: 28px;
    left: 15px;
    bottom: 50px;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    position: absolute;
  }

  .offerTreeViewHeight {
    height: 62px;
    width: 28px;
    left: 15px;
    bottom: 50px;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    position: absolute;
  }

  .checkbox {
    [class*='ant-checkbox-wrapper'] {
      &:hover {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color !important;
        }
      }

      &:focus {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color !important;
        }
      }
    }

    [class*='ant-checkbox-checked'] {
      border-color: $primary-color !important;

      &:after {
        border-color: $primary-color !important;
      }

      [class*='ant-checkbox-inner'] {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
      }
    }
  }

  .counterOfferChecbox {
    display: flex;
    justify-content: flex-end;

    .checkbox {
      [class*='ant-checkbox-inner'] {
        width: setUnit(24);
        height: setUnit(24);
        border-radius: setUnit(4);
        &:after {
          width: setUnit(6);
          height: setUnit(12);
        }
      }
      &:hover {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color !important;
        }
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    .compareOffersModalBody {
      padding: 16px 20px 16px 20px;
    }

    .offereName {
      font-size: 16px;
    }

    .offerTreeViewHeightFirstNode {
      height: 55px;
      bottom: 65px;
      width: 20px;
    }

    .offerTreeViewHeight {
      height: 82px;
      bottom: 65px;
      width: 20px;
    }

    .offerStatusHeading,
    .offerReceivedAndPrice {
      font-size: 14px;
    }
  }
}
