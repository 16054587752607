@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-small) {
    padding-bottom: setUnit(16);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
