@import "styles/functions";
@import "styles/variables";

.wrapper {
    padding: setUnit(26) 0;
    background: $hover-light-gray;
    border-radius: setUnit(8);
    width: 100%;
    font-size: setUnit(16);
    line-height: setUnit(20);
    color: $dark-grey-color;

    display: flex;
    align-items: center;
    justify-content: center;

    .retryBtn {
        color: $primary-color;
        background-color: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: all .1s;
        display: flex;
        align-items: center;
        padding-left: setUnit(16);

        &:hover {
            text-decoration: underline;
            opacity: 0.85;
        }

        .retryBtnIcon {
            height: setUnit(24);
            margin-right: setUnit(4);

            svg {
                width: setUnit(24);
                height: setUnit(24);
            }
        }
    }
}