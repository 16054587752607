@import 'styles/functions';
@import 'styles/variables';

.disabledRow {
  opacity: 0.5;
}

.avatarCustom {
  height: 24px !important;
  width: 24px !important;
  font-size: 10px;
}

.clientWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;

  p {
    margin: 0;
  }
}

.ownerWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px 0;
  p {
    margin: 0;
  }
}

.formsStatus {
  height: 100%;
  min-height: setUnit(32);
  padding: setUnit(6) setUnit(12);
  border-radius: setUnit(100);
  background: $background-ligh-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $white;
  width: fit-content;
  min-width: 110px;

  .statusText {
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.33px;
    white-space: nowrap;
  }

  &.waitinOnYou {
    background: $primary-color;
  }
  &.complete {
    background: $green-color;
  }
  &.finalizing {
    background: $confetti-blue;
  }
  &.waitinOnOthers {
    background: $orange;
  }

  &.declined {
    background: $primary-color-light;
    color: $primary-color;
  }

  &.draft,
  &.voided,
  &.canceled {
    background: $text--light-gray-color;
  }
}

.tooltip {
  color: black;
}

.exclamationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  flex-shrink: 0;

  svg {
    width: 17.5px;
    height: 17.5px;
  }
}

.lastEditedActivityContainer {
  margin-right: 8px;
  line-height: 0px;
}

.row {
  display: flex;
}

.name {
  align-items: center;
  gap: 12px;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.tableTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  &:first-child {
    padding-top: 20px;
  }

  &:not(:first-child) {
    margin: setUnit(44) 0 setUnit(22);
  }
}

.tableView {
  border-radius: 1rem;
  background: white;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);

  [class~='ant-table'] {
    border-radius: 1rem;
  }

  overflow-x: auto;

  .inner {
    display: flex;
    justify-content: space-between;
    padding: 0 setUnit(10);
  }
  .header {
    display: flex;
    align-items: center;

    .title {
      font-size: setUnit(20);
      font-weight: 600;
      margin-left: setUnit(12);
    }
  }

  span[class~='ant-table-column-sorter-full'] {
    display: none;
  }

  .view {
    margin-top: setUnit(1);
    div[class~='ant-table-content'] {
      table {
        border-radius: setUnit(16);
        background-color: #fff;
      }
    }
  }

  tbody[class~='ant-table-tbody'] {
    tr {
      height: setUnit(0.5) !important;
    }

    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
      font-size: setUnit(14);
      &.ant-table-cell::before {
        display: none;
      }
    }

    th::before {
      display: none !important;
      width: 0;
    }

    th,
    td {
      align-items: center;
      line-height: setUnit(18);
      letter-spacing: -0.33px;
      font-size: setUnit(16);
      cursor: pointer;

      &:first-child {
        border-right: unset !important;
      }
      &:last-child {
        border-right: unset !important;
      }
      .test {
        display: flex;
        align-items: center;
      }

      .upcoming {
        display: flex;
        justify-content: space-between;

        .upcomingText {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }

      .pending {
        display: flex;
        justify-content: space-between;
        padding-top: 0 !important;

        .pendingText {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &[class~='ant-table-column-sort'] {
        background-color: transparent;
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
      font-size: setUnit(16);
    }

    th {
      & [class*='ant-table-cell']::before {
        display: none !important;
      }
    }

    th {
      &.ant-table-cell::before {
        display: none !important;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
    }

    td {
      padding: setUnit(16.5) setUnit(24) !important;
    }

    table {
      border: unset !important;
    }

    .formAvatarIcon {
      line-height: 0;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  thead[class~='ant-table-thead'] {
    th {
      background-color: transparent;
      color: $dark-grey-color;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding: setUnit(16.5) setUnit(24) !important;
    }

    &.ant-table-cell::before {
      display: none;
    }
  }

  th::before {
    display: none !important;
    width: 0;
  }

  @media (max-width: $mobile) {
    margin-top: setUnit(20);
  }
}
.modalBtn {
  margin-left: 16rem !important;
  margin-top: 9% !important;
}

.inlineRowContent {
  display: flex;
  flex-direction: row;

  label {
    padding-left: 16px;
  }
}

.bold {
  font-weight: 600;
}

.pendingText {
  font-weight: 600;
}

.pendingSignature {
  font-weight: 500;
}

.broker {
  color: $dark-color;
  font-size: setUnit(14);
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 30px;
}

.feedBackbuttonContainer {
  display: flex;
  flex-direction: column;

  button {
    margin-left: 115px;
  }
}
.newFeedback {
  background: transparent;
  border: none;
  color: transparent;
}

.youText {
  color: $error-color;
}

.formName {
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.addressContainer {
  display: flex;
  flex-direction: column;
  .line1 {
    font-size: setUnit(16);
    font-weight: 500;
    line-height: setUnit(16);
    letter-spacing: -0.33px;
    margin-bottom: setUnit(4);
  }
  .line2 {
    font-size: setUnit(16);
    font-weight: 400;
    line-height: setUnit(16);
    letter-spacing: -0.33px;
    color: $text-gray-color;
  }
}

.widthFitContent {
  width: fit-content;
  border-radius: 50px;
}

.visibleFlex {
  display: flex !important;
}

.displayNone {
  display: none !important;
}

.clientWrapper,
.addressContainer {
  min-width: 75px;
  min-height: 20px;
  width: fit-content;
  border-radius: 50px;

  &:hover {
    .linkButton {
      display: flex;
    }
    .placeholder {
      display: none;
    }
  }
  .linkButton {
    width: 70px;
    font-size: 14px !important;
    border: none !important;
    border-radius: 50px !important;
    display: none;
    &:hover {
      background: $light-gray;
    }
  }
}

.lastActivityContainer {
  display: flex;
  flex-direction: column;
}
.waitingOnOthers,
.waitingOnYou,
.lastActivity {
  display: flex;
  align-items: center;
  gap: 5px;
}

.textRed {
  color: $primary-color;
}

.lastActivityContainer > div > div,
.lastActivityContainer > span {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    background: $white;
    border-radius: 16px;
    color: $text-color;
  }

  .container {
    min-width: 230px;
    padding: 10px;
    padding-bottom: 0px;

    .title {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    .row {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .userName {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0px !important;
    }

    .sequenceBox {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $text--light-gray-color;
      border: 1px solid $text--light-gray-color;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      display: inline-block;
      text-align: center;
    }
    .editorRow {
      width: 100%;
    }
    .sequenceBoxColumn {
      align-self: baseline;
      margin-right: 5px;
    }

    .formStatus {
      font-family: $font-family;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }

    .column {
      width: 80% !important;
    }
  }
}

.transactionPopupOverlay {
  [class*='ant-popover-inner'] {
    border-radius: 24px;
  }
}

.transactionPopup {
  padding: 2rem;
  width: 400px;

  .content {
    display: flex;
    gap: setUnit(16);
  }
  svg {
    min-width: 32px;
  }
  [class*='fieldLabel'] {
    margin-bottom: 10px !important;
  }

  .multipleSelectInput {
    .listBoxInput {
      width: 100%;
      max-width: 380px;
      margin-top: setUnit(7);
      [class~='ant-select-selector'] {
        padding-left: 4px !important;
        [class~='ant-select-selection-search'] {
          left: 8px;
          margin-inline-start: 3px;
        }
      }

      [class~='ant-select-selection-placeholder'] {
        left: 12px;
      }
    }

    .buttonsContainer {
      margin: setUnit(20) 0;
      display: flex;
      gap: 12px;
      button {
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  .transactionListInput {
    width: 100% !important;
  }
  .options {
    [class*='title'] {
      width: 32px !important;
      height: 32px !important;
      border-radius: 50% !important;
    }
  }

  .buttonsContainer {
    margin-top: setUnit(20);
    display: flex;
    gap: 12px;

    button {
      border-radius: 8px;
      width: 120px;
    }
  }
}

.checkbox {
  [class*='ant-checkbox'] {
    [class*='ant-checkbox-inner'] {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    &:hover,
    &:focus,
    &:active {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color;
      }
    }

    [class~='ant-checkbox-input'] {
      &:focus + [class*='ant-checkbox-inner'] {
        border-color: $primary-color;
      }
    }
  }

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    [class*='ant-checkbox-inner']::after {
      width: 7px;
      height: 12px;
      top: 46%;
      left: 25%;
    }

    &::after {
      border-color: $primary-color;
      border-radius: 4px;
    }
  }

  &:not([class~='ant-checkbox-wrapper-disabled']) {
    &:hover,
    &:focus,
    &:active {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color !important;
      }
    }
  }
}

.sortedColumnTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .sorterIcon {
    margin-left: setUnit(8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: setUnit(24);
    height: setUnit(24);
    background-color: $background-grey-2;
    border-radius: setUnit(100);
    cursor: pointer;
  }

  .sorterUp {
    transform: rotate(180deg);
  }
}

.transactionOptionDropdown {
  border-radius: 12px;

  :is([class~='ant-select-item-option-active'], [class~='ant-select-item-option-selected']) {
    background-color: #f5f5f5 !important;

    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }

  [class~='rc-virtual-list-holder'] {
    overflow-y: auto !important;
  }
}

.transactionOption {
  padding-top: 13px;
  padding-bottom: 0px;
  padding-left: 20px;

  [class~='ant-select-item-option-content'] {
    padding-bottom: 14px;
    border-bottom: 1px solid $background-ligh-gray-4;
  }

  .transactionOptionStatus {
    background-color: $background-grey-2;
    color: $dark-color;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.18px;
    text-align: left;
    border-radius: 100px;

    padding: 6px 7px;
    width: fit-content;

    text-transform: capitalize;
    margin-top: 3px;
  }

  &:hover {
    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }
}
