@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.selectedPartners {
  $height: 100;
  @include box-shadow;

  position: fixed;
  bottom: setUnit(-$height);
  width: 100%;
  background: #ffffff;
  height: setUnit($height);
  transition: bottom 0.1s ease;
  display: flex;
  align-items: center;

  &.active {
    bottom: 0;
  }

  .inner {
    max-width: 612px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .numSelected {
      color: $dark-color;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
    }

    .partners {
      width: calc(100% - #{setUnit(153)});
      overflow: auto;
      display: flex;
      align-items: center;

      .partner {
        margin-right: setUnit(8);
        position: relative;
        .removeIcon {
          position: absolute;
          right: 0;
          top: 0;
          width: setUnit(16);
          height: setUnit(16);
          display: flex;
          align-items: center;
          justify-content: center;
          background: $secondary-color;
          border-radius: 50%;
          border: 2px solid #ffffff;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }

          svg {
            width: setUnit(15);
            height: setUnit(15);

            path {
              fill: #ffffff;
            }
          }
        }

        .logo {
          width: setUnit(56);
          height: setUnit(56);
          border: 1px solid $border-light-gray;

          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
          }
        }
      }
    }

    .continueBtn {
      max-width: 134px;
      width: 100%;
      height: 52px;
      border-radius: 8px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $white;
      }
      &:hover {
        background-color: $secondary-color-hover;
        .title {
          color: $white;
        }
      }
    }
  }
}
