@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.border {
  border-radius: 8px 0 0 8px !important;
  .placeholder {
    border-radius: 8px 0 0 8px !important;
  }
  input {
    border-radius: 8px 0 0 8px !important;
  }
}

.hoaContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.checkboxContainer {
  min-width: 100px;
  margin-top: 60px;
  .label {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
}

.searchInstanceForm {
  .formContainer {
    padding: 20px 40px;
    position: relative;
    max-height: calc(100vh - 400px);
    min-height: 345px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-height: 1000px) {
      max-height: calc(100vh - 270px);
    }

    &.isCriteriaOpened {
      padding: 48px 0px 0px;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      color: $text-color;
      margin-bottom: 20px;
    }
  }

  .divider {
    border-color: $border-input-middle-gray;
    margin: 0px;
    width: 96%;
    min-width: 96%;
  }

  .modalContainer {
    padding: 0.25rem;
    .boundaryBadge {
      display: flex;
      p {
        color: blue;
        font-weight: 700;
        margin-left: setUnit(8);
        margin-bottom: 0;
      }
    }
    .label {
      font-weight: 600 !important;
      font-size: 14px !important;
      color: $text-color !important;
    }
    .locationToggle {
      text-align: right;
      .toggleButton {
        background-color: #ffffff;
        margin: setUnit(0);
        padding: setUnit(3) setUnit(7);
        border: setUnit(2) solid #d6d6d7;
        cursor: pointer;
      }
      .toggleButton:first-child {
        border-radius: setUnit(10) setUnit(0) setUnit(0) setUnit(10);
      }
      .toggleButton:last-child {
        border-radius: setUnit(0) setUnit(10) setUnit(10) setUnit(0);
      }
      .active {
        background-color: #fff2f3;
        border-color: #ff576d;
      }
    }
    .modalScrollHeight {
      max-height: calc(100vh - 300px);
      overflow: hidden;
      overflow-y: auto;
      padding-right: 24px;
    }

    .locationInput {
      input {
        font-size: 16px;
        margin-top: -3px;
        border-bottom: none !important;

        &::placeholder {
          font-size: 16px;
        }
      }
    }

    .valuesWrapper {
      padding-left: 0;
    }

    .customLabel {
      margin-bottom: 0;
    }

    .customSelectWrapper {
      margin: 1.5rem 0 0;
    }

    .selectInput {
      padding-left: 0 !important;
    }
  }

  .field {
    margin-top: 20px;
  }

  .selectWrapper {
    width: 100%;
  }

  .suiteUnit {
    width: 50%;
  }

  .selectWrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  .horizontalOptions {
    display: flex;
    align-items: center;
    margin-bottom: setUnit(20);

    .separator {
      margin: 0 setUnit(15);
      font-size: setUnit(14);
      color: $border-middle-gray;
    }

    div {
      flex: 1;
    }
  }

  .baths {
    margin-top: setUnit(32);
  }

  .submit {
    width: 100%;
    margin-top: setUnit(50);
    margin-bottom: setUnit(50);
  }

  .footNote {
    font-size: setUnit(12);
    text-align: center;
    color: $border-middle-gray;
    margin-bottom: setUnit(35);
  }

  .agentNotes {
    margin-top: 40px;
    margin-bottom: 56px;

    label {
      margin-bottom: 0 !important;
    }

    .altLabel {
      font-weight: normal;
      color: $border-middle-gray;
      margin-right: auto;
      margin-left: setUnit(8);
    }
  }

  .addMoreButton {
    font-size: setUnit(16);
    color: $text-color;
    font-weight: 500;
    margin-top: 50px;
    cursor: pointer;
    display: flex;
    height: 25px;
    width: fit-content;

    p {
      margin-left: 12px;
    }
  }

  .addMoreButton {
    font-size: setUnit(16);
    color: $text-color;
    font-weight: 500;
    margin-top: 50px;
    cursor: pointer;
    display: flex;

    p {
      margin-left: 12px;
    }
  }

  .searchCriteria {
    margin-top: setUnit(24);
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: -12px;
    margin-right: 24px;

    .reset {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $dark-color;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      margin: 0;
      height: 52px;
      width: 64px;

      &:hover {
        cursor: pointer;
        background-color: $background-grey-2;
      }
    }

    .saveChangesBtn {
      background-color: $secondary-color;
      color: $white;
      display: block;
      margin: 0 !important;
      width: 168px !important;
      height: 52px !important;
      font-size: 16px !important;
    }
  }

  .footer {
    button {
      margin: 0 !important;
    }
  }
}
