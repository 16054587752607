@import 'styles/functions';
@import 'styles/variables';

.itemContainer {
  > div:nth-child(1) > span {
    border-left: 1px solid $border-input-middle-gray;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  > div:nth-child(1) > span[class*='selectedItem'] {
    border-left: 2px solid $primary-color;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  > div:last-child > span {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  & [class*='selectedItem'] {
    background: $primary-color-light;
    border-color: $primary-color;
    border-width: 2px;
  }
}

.item {
  display: inline-block;
  padding: 16px 0px;
  width: 77px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $text-color;
  text-align: center;
  border: 1px solid $border-input-middle-gray;
  margin: 0px 0px;
}
