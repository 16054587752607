@import 'styles/variables';
@import '../../mixins.module.scss';

.agentInvitationModal {
  & [class*='ant-modal-content'] {
    margin: 2rem 0;
    padding-top: 4rem;
    text-align: center;
    box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }

  & [class*='ant-modal-close-x'] {
    display: none;
  }

  .defaultAvatar {
    position: relative;
    margin-left: -1.5rem !important;

    img {
      width: 44px;
      padding-left: 0.5rem;
    }
  }

  h1 {
    font-weight: bold;
  }

  h3 {
    font-weight: 400;
  }

  .bold {
    font-weight: 600;
  }

  .acceptBtn {
    width: 240px;
    height: 56px;
    padding: 1rem;
    margin: 2rem 0;
  }

  .declineBtn {
    font-weight: bold !important;
    border: unset !important;
  }
}
