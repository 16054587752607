@import 'styles/functions';
@import 'styles/variables';

.head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 34px 0 54px;
  .closeIcon {
    border-radius: 50%;
    border: 2px solid $light-gray;
    background: none;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 2px solid $text--light-gray-color;
      svg {
        opacity: 1;
        path {
          fill: $text-color;
        }
      }
    }
  }
}

.inner {
  .remove {
    display: flex;
    cursor: pointer;
    margin-left: auto;
  }
}

.partners {
  .bottom {
    height: $responsive-footer-height;
    max-width: 612px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .continueBtn {
      margin-left: auto;
      font-size: 16px !important;
      border: none !important;
      display: flex !important;
      width: 138px !important;
      height: 52px !important;
      justify-content: center;
      border-radius: 8px;
      span {
        align-self: center;
      }

      div {
        align-self: center;
        margin-left: 13px;

        svg {
          path {
            stroke: $white;
          }
        }
      }

      &:disabled {
        opacity: 0.35;
      }
    }

    .loadingBtn {
      width: auto !important;
      cursor: not-allowed;
    }
  }
}
