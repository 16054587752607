@import 'styles/variables';
@import 'styles/functions';

.transactionCreationAccess {
  display: flex;
  align-items: center;
  gap: 8px;

  .switch {
    height: 24px;
    width: 44px;

    [class*='ant-switch-handle'] {
      top: 2px;
      left: 3px;
      width: 20px;
      height: 20px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  [class*='ant-switch-checked'] {
    background-color: $primary-color;

    [class*='ant-switch-handle'] {
      left: calc(100% - 20px - 3px) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .text {
    color: $text-gray-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.33px;
  }

  .enabledText {
    color: $text-color;
  }
}

.transactionAccessConfirmationModal {
  [class~='ant-modal-header'] {
    display: none;
  }

  [class~='ant-btn-primary'] {
    margin-left: 16px !important;
  }
}
