@import 'styles/functions';
@import 'styles/variables';

div:has(> .timeline) {
  padding: 0 !important;
}

.timeline {
  .addMode {
    display: flex;
    align-items: center;
    margin-top: setUnit(20);
    .addDateInputClass {
      &::placeholder {
        font-size: 0.875rem !important;
        font-weight: 400 !important;
      }
    }
    .pointWrapper {
      position: relative;

      .line {
        position: absolute;
        left: calc(50% - #{setUnit(1)});
        top: setUnit(-76);
        height: setUnit(51);
        width: setUnit(2);
        background: $border-light-gray;
      }
    }
    .name {
      // max-width: setUnit(200);
      // width: inherit;
      padding-right: 1.25rem;
      input {
        height: 2.5rem;
        font-size: 1rem;
        &::placeholder {
          font-size: setUnit(14);
        }
      }
    }

    .lockIconContainer {
      margin-right: 20px;
      min-width: 10px;
    }

    .deadline {
      padding-left: 24px;
      margin: 0;
      min-width: 18.4%;
      max-width: 18.4%;

      input {
        height: 2.5rem;
        padding-left: 0;
        font-size: setUnit(14);
        &::placeholder {
          font-size: setUnit(14);
        }
      }
    }

    .point {
      margin: 0 setUnit(28);
      pointer-events: none;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.header {
  display: flex;
  align-items: center;

  .title {
    font-size: setUnit(20);
    font-weight: 600;
  }
}

.modalBtn {
  margin-left: 16rem !important;
  margin-top: 9% !important;
}
