@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.signFormSuccessPage {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  .successPageContent {
    text-align: center;

    width: clamp(min(10vw, 20rem), 700px, max(90vw, 55rem));
    margin: 10vh auto 0px;

    flex: 1;

    @media screen and (max-width: $mobile-small) {
      width: unset !important;
    }

    .title {
      align-self: center;
      font-weight: 600;
      font-size: 32px !important;
      color: $secondary-color !important;
      margin-bottom: 40px !important;
      margin-top: 40px;
      display: block;

      @media (max-width: $mobile) {
        font-size: 22px;
      }
    }

    .subTitle {
      font-size: 16px;
      color: $dark-color;
      font-weight: 400;
      line-height: 20.8px;
      letter-spacing: -0.18px;
      text-align: center;
    }

    .buttonsContainer {
      width: 400px;
      margin: 0 auto;

      @media screen and (max-width: $mobile-small) {
        width: 100% !important;
      }
    }

    .button {
      width: 100%;
      height: 52px;
      border-radius: 10px !important;

      color: $dark-color;

      font-size: setUnit(16);
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;
    }

    .downloadButton {
      margin-bottom: 24px;
      justify-content: center;
      gap: 6px;
    }

    .mainButton {
      border: 2px solid $secondary-color;
      color: $secondary-color;

      &:hover {
        border-color: $secondary-color;
      }
    }
  }
}
