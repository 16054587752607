@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';
@import '../../styles.module';

.propertySize {
  margin-top: 48px;
}

.to {
  font-size: 18px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.09px;
  margin: 0 24px !important;
  color: $text-color !important;
}
