@import 'styles/functions';
@import 'styles/variables';

.analyzingAnimation {
  width: 64px;
  height: 64px;
  position: relative;

  .quadrant {
    width: 50%;
    height: 50%;
    position: absolute;

    .rotate {
      animation: rotateAnimation 3s ease-in-out infinite;
    }
  }

  .quadrant1 {
    top: 0;
    left: 0;
    animation: switchAnimation1 3s ease-in-out infinite;
  }

  .quadrant2 {
    top: 0;
    right: 0;
    animation: switchAnimation2 3s ease-in-out infinite;
  }

  .quadrant3 {
    bottom: 0;
    left: 0;
    animation: switchAnimation3 3s ease-in-out infinite;
  }

  .quadrant4 {
    bottom: 0;
    right: 0;
    animation: switchAnimation4 3s ease-in-out infinite;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-90deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  30% {
    transform: rotate(-180deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  55% {
    transform: rotate(-270deg);
  }
  75% {
    transform: rotate(-270deg);
  }
  80% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes switchAnimation4 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0%, -100%);
  }
  25% {
    transform: translate(0%, -100%);
  }
  30% {
    transform: translate(-100%, -100%);
  }
  50% {
    transform: translate(-100%, -100%);
  }
  55% {
    transform: translate(-100%, 0%);
  }
  75% {
    transform: translate(-100%, 0%);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes switchAnimation3 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(100%, 0%);
  }
  25% {
    transform: translate(100%, 0%);
  }
  35% {
    transform: translate(100%, -100%);
  }
  50% {
    transform: translate(100%, -100%);
  }
  55% {
    transform: translate(0%, -100%);
  }
  75% {
    transform: translate(0%, -100%);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes switchAnimation2 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(-100%, 0);
  }
  25% {
    transform: translate(-100%, 0);
  }
  30% {
    transform: translate(-100%, 100%);
  }
  50% {
    transform: translate(-100%, 100%);
  }
  55% {
    transform: translate(0%, 100%);
  }
  75% {
    transform: translate(0%, 100%);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes switchAnimation1 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(0%, 100%);
  }
  25% {
    transform: translate(0%, 100%);
  }
  30% {
    transform: translate(100%, 100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  55% {
    transform: translate(100%, 0%);
  }
  75% {
    transform: translate(100%, 0%);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
