@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.notes {
  .contentWrapper {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    margin-top: setUnit(18);
    .title {
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-color;

      .notesIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
    }

    .container {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .checkbox {
        margin: 0;
      }

      .textArea {
        margin: 1.5rem 0 0;
        resize: none;
        border-radius: setUnit(8);
      }
    }

    .checkboxesWrapper {
      margin-top: setUnit(32);
      padding: setUnit(24);
      border: 1px solid $light-gray;
      border-radius: 8px;

      h5 {
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: -0.18px;
      }

      p {
        font-size: setUnit(14);
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        color: $text-gray-color;
        margin: 0;
      }
    }
  }
  .checboxSquare {
    background-color: #fff;
    & [class*='ant-checkbox'] {
      &:after {
        border-radius: setUnit(4);
      }
      & [class*='ant-checkbox-inner'] {
        border-radius: setUnit(4);
        &:after {
          border-radius: 0;
        }
      }
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}
