@import 'styles/functions';
@import 'styles/variables';

.filterDrawer {
  .drawerInner {
    position: relative;

    .tabs {
      margin: 0 setUnit(32);
      justify-content: space-between;

      .tab {
        margin-right: 0;
        text-align: start;
        padding: setUnit(17) 0;

        &:hover {
          background: none;
        }
      }
    }
  }
}

.allSubFeedDrawer {
  padding: 1rem 2rem 2rem;
}
