@import 'styles/functions';
@import 'styles/variables';

.submissionActivity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 10px;

  &Log {
    display: flex;
    align-items: center;

    [class*='avatar'] {
      width: 2rem;
      height: 2rem;
      margin-right: 12px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #252d44;
      border-radius: 100px;
      color: #ffffff;
    }
    p {
      margin: 0;
      max-width: 230px;
    }

    span {
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $text-color;
    }

    .userName,
    .offerNo {
      font-weight: 600;
    }

    .text {
      font-weight: 400;
    }
  }

  &Time {
    font-weight: 400;
    font-size: setUnit(12);
    line-height: setUnit(16);
    font-family: $font-family;
    letter-spacing: setUnit(-0.09);
    color: $text-gray-color;
  }
}
