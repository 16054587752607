@import 'styles/functions';
@import 'styles/variables';

.searchWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: setUnit(220);
  border: 1px solid transparent;
  border-radius: setUnit(100);
  padding: setUnit(8) setUnit(12);
  width: setUnit(44);
  height: setUnit(44);
  cursor: pointer;
  background-color: $background-grey-2;
  &.expanded {
    width: 100%;
    cursor: initial;
    background-color: #fff;
    border-color: $text--light-gray-color;

    input {
      display: flex;
      opacity: 1;
      visibility: visible;
    }
    .closeIcon {
      display: flex;
      opacity: 1;
      visibility: visible;
    }
  }
  input {
    border: 0;
    outline: 0;
    font-size: setUnit(16);
    margin-left: setUnit(8);
    width: calc(100% - #{setUnit(48)});
    display: none;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .icon {
    display: flex;
    svg {
      width: setUnit(20);
      height: setUnit(20);
    }
  }
  .closeIcon {
    display: none;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    svg {
      path {
        fill: $text-gray-color;
      }
    }
  }

  .searchIcon {
    svg {
      path {
        stroke: $text-gray-color;
      }
    }
  }
}
