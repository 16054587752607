@import "styles/functions";
@import "styles/variables";

@mixin addRemoveButtons() {
    width: setUnit(48);
    height: 100%;
    border: none;
    outline: none;

    &:hover {
        opacity: .9;
    }

    svg {
        vertical-align: middle;
    }
}

.parkingSpaces {

    .holder {
        width: 100%;
        height: setUnit(46);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $border-middle-gray;
        border-radius: setUnit(8);
        overflow: hidden;

        .count {
            font-size: setUnit(16);
            font-weight: 400;
        }
    }
}
