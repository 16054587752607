@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$hover-color: lighten($secondary-color, 10);

.archive {
  font-size: setUnit(14);
  font-weight: 500;
  height: setUnit(44);
  border: 2px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 setUnit(16);
  border-radius: setUnit(100);
  cursor: pointer;

  svg {
    path {
      fill: $dark-color;
      stroke-width: 1px;
      stroke: $dark-color;
    }
  }

  &.active {
    background: $secondary-color;
    color: #ffffff;
    border-color: $secondary-color;

    svg {
      path {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }

    &:hover {
      background-color: $hover-color;
      border-color: $hover-color;
    }
  }

  &:hover {
    background-color: $background-ligh-gray;
  }
}

.icon {
  position: relative;
  svg {
    vertical-align: middle;
    width: setUnit(20);
    height: setUnit(20);
  }
}
