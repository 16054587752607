@import 'styles/functions';
@import 'styles/variables';

.criteriaTypeContainer {
  margin: setUnit(15) 0;

  .criteriaBtn {
    height: setUnit(42);
    font-size: setUnit(14);
    background-color: $white !important;
    color: $dark-color !important;
    border: 1.5px solid $light-gray !important;
    padding: 0 !important;
    border-radius: setUnit(8);
  }

  .basicOptionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .titleContainer {
      display: flex;
      align-items: center;
      gap: setUnit(10);
      .title {
        font-size: setUnit(16);
        font-weight: 400;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: setUnit(10);
    }
  }

  .additionalOptions {
    background-color: $white;
    padding: setUnit(20) setUnit(24);
    border: 1.5px solid $light-gray;
    border-radius: setUnit(8);
    margin-top: setUnit(15);
    .label {
      margin-bottom: setUnit(10);
      &.full {
        width: 100%;
      }
    }
    .wrapperCheckbox {
      display: flex;
    }
    .checkboxLabel {
      margin-bottom: 0;
      gap: setUnit(10);
    }
    .optionBtn {
      @extend .criteriaBtn;
      min-width: 80px;
      margin-bottom: 12px;
      width: 100%;
      &.selected {
        background-color: #f0f4fb !important;
        border-color: $confetti-blue !important;
      }
    }
  }
}

.criteriaButton {
  width: setUnit(68);
  height: setUnit(42) !important;
  font-size: setUnit(14) !important;
  background-color: $white !important;
  color: $dark-color !important;
  border: 1.5px solid $light-gray !important;
  padding: 0 !important;
  border-radius: 8px !important;

  @media (max-width: 400px) {
    width: setUnit(40);
    font-size: setUnit(12) !important;
    height: setUnit(35) !important;
    padding-top: 3px !important;
  }

  &.selected {
    background-color: #f0f4fb !important;
    border-color: $confetti-blue !important;
    color: $text-color !important;
  }
}
