@import 'styles/functions';
@import 'styles/variables';

.inviteBg {
  background-color: #f4f7fc;
}

.contactTab {
  padding: 24px 30px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: setUnit(24);

    .participant {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        .avatar {
          margin-right: setUnit(12);
          width: setUnit(18);
          height: setUnit(18);
          font-size: 14px;
          @media (min-width: $tablet) {
            width: setUnit(32);
            height: setUnit(32);
          }
        }
        .contactDetails {
          display: flex;
          flex-direction: column;
          margin-left: 0.1rem;

          .name {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 600;
            color: $text-color;
            margin-bottom: 0.15rem;
            width: 240px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .role {
            font-weight: 400;
          }
          .email,
          .phone {
            font-weight: 300;
          }
          .role,
          .email,
          .phone {
            color: $dark-color;
            line-height: 16px;
            margin-bottom: 0.1rem;
          }
        }
      }

      .right {
        .accessText {
          color: $link-blue;
        }
      }
    }
  }
}
