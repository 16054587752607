@import 'styles/functions';
@import 'styles/variables';

.filterPopover {
  padding-top: 0;
}
.clientActivityCardFilterPopover {
  padding-top: 0;
  & [class*='ant-popover-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(12);
  }
  .filterHeader {
    display: flex;
    vertical-align: middle;
  }
  .filterIcon {
    width: setUnit(18);
  }
  .titleText {
    margin-left: setUnit(11);
    margin-top: setUnit(2);
  }
  .dateRangePickerWrapperClientActivity {
    margin-left: 0;
    margin-right: 0;
    & [class~='ant-picker'] {
      border: unset !important;
      border-bottom: 2px solid $border-input-middle-gray !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
    }
    & [class~='ant-picker-input'] {
      border: none !important;
      input {
        color: #262626;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: -0.09px;
        &::placeholder {
        }
      }
    }
    & [class~='ant-picker-suffix'] {
      margin-left: 1px;
      margin-right: 10px;
      order: -1;
      color: #515151;
    }
  }
}

.filterPopoverContainer {
  width: setUnit(364);
  .clientActivityMultiselect {
    margin-left: 0;
    margin-right: 0;
    .activityDrawerMultiselect {
      & [class~='ant-select-selector'] {
        min-height: setUnit(40);
        height: auto;
        border: unset !important;
        border-bottom: 2px solid $border-input-middle-gray !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        & [class~='ant-select-selection-placeholder'] {
          left: 0;
        }
      }
    }
  }
}
.submitButton {
  width: setUnit(81) !important;
  margin-left: auto;
  height: setUnit(40) !important;
  float: right;
}

.resetButton {
  width: setUnit(44) !important;
  margin-left: auto;
  height: setUnit(40) !important;
  border: none;
  padding: 0;
  text-align: left;
  &:hover {
    color: $hover-blue;
  }
}
