@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contactModal {
  padding-left: 1rem;
  padding-right: 1rem;

  [class='ant-modal-content'] {
    padding: 2rem 2.5rem;
    margin: 4rem 0;
  }
}
