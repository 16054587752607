@import 'styles/functions';
@import 'styles/variables';

.card {
  // 23px/19px instead of 24px/20px because of the 1px border
  padding: setUnit(19) setUnit(23) setUnit(23);
  border-radius: setUnit(16);
  border: setUnit(1) solid $background-grey-2;
  background: $white;
  box-shadow: setUnit(0) setUnit(2) setUnit(16) setUnit(0) rgba(48, 48, 48, 0.03);
  display: flex;
  flex-direction: column;
  gap: setUnit(12);

  .header {
    display: flex;
    justify-content: space-between;

    .profile {
      display: flex;
      align-items: center;
      gap: setUnit(8);

      .profileAvatar {
        width: setUnit(24);
        height: setUnit(24);
        border-radius: 100%;
      }

      .profileName {
        color: $text-color;
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: setUnit(15);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
      }

      .profileRole {
        color: $text-gray-color;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
      }
    }

    .timeFromNow {
      color: $text-gray-color;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.09px;
      text-align: right;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: setUnit(8);

    .title {
      color: $text-color;
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: setUnit(18);
      line-height: setUnit(20);
      letter-spacing: -0.09px;
    }

    .description {
      color: $text-color;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(18);
      letter-spacing: -0.09px;
    }
  }

  .publishedTo {
    display: flex;
    align-items: center;
    gap: setUnit(6);

    & svg {
      width: 16px;
      height: 16px;
    }
    & svg path {
      stroke: $text--light-gray-color;
      stroke-width: 2px;
    }

    .text {
      color: $text-gray-color;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(18);
      letter-spacing: -0.09px;
    }
  }
}
