@import 'styles/functions';
@import 'styles/variables';

@import 'styles/functions';
@import 'styles/variables';

.inviteModal {
  & [class*='ant-modal-content'] {
    padding: 40px 16px 32px 40px;
  }
  .wrapper {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.crossButton {
  height: setUnit(16);
  width: setUnit(16);
  color: $text-color;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  .title {
    font-size: setUnit(18);
    font-weight: 700;
  }
  textarea {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    &:hover,
    &:focus {
      border-color: grey;
      outline: none;
    }
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
      height: 40px;
    }
  }
}

.gptSubmit {
  z-index: 1;
  height: setUnit(54);
  width: setUnit(54);
  background: $primary-color;
  border: 0;
  outline: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.1s;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  margin-left: setUnit(5);
  margin-bottom: setUnit(15);
  @media (min-width: $mobile) {
    margin-bottom: 0;
  }
  &:hover {
    background: lighten($primary-color, 5%);
  }
  .gptIcon {
    color: $white;
  }
}
