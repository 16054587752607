@import 'styles/variables';

.statusContent {
  .selectDisabled {
    [class*='ant-select-selector'] {
      cursor: default !important;
    }
    [class*='ant-select-selection-item'] {
      justify-content: center;
    }
  }

  .formStatus {
    height: 40px;
    line-height: 40px;
  }

  .statusText {
    cursor: default;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    text-align: center;

    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    border-radius: 100px;
    color: $white;
  }

  .pendingStatus {
    background: $primary-color-light;
    color: $primary-color !important;
  }

  .waitinOnYou {
    background: $primary-color;
  }
  .complete {
    background: $green-color;
  }
  .finalizing {
    background: $confetti-blue;
  }
  .waitinOnOthers {
    background: $orange;
  }
  .draft,
  .voided,
  .canceled {
    background: $text--light-gray-color;
  }

  .activeTick {
    opacity: 0 !important;
    margin-left: 25%;
  }
  .activeDot {
    margin-right: 5%;
  }
}
