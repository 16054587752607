.info {
  color: rgba(214, 214, 215, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-top: 8px;

  a {
    color: #b9d0ff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
  }
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  margin-top: -8px;
  .btn {
    border-radius: 12px;
    background: linear-gradient(90deg, #ff5d69 13.49%, #fc8545 138.32%);
    height: initial;
    min-width: 136px;
    min-height: 52px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .spinner {
    font-size: 20px;
    color: antiquewhite;
  }

  .disabled {
    opacity: 0.5;
  }
}
