@import 'styles/variables';
@import 'styles/functions';

.header {
  display: flex;
  justify-content: space-between;
}

.clickable {
  cursor: pointer;
}

.wrapper {
  margin-top: setUnit(32);
  padding: setUnit(10);
  border: 1px solid $light-gray;
  border-radius: 16px;
}

.recentQuoteTitle {
  font-size: setUnit(16);
  font-weight: 600;
}

.recentQuoteDate {
  font-size: setUnit(14);
  font-weight: 400;
  color: $text-gray-color;
}

.disclaimerContainer {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .disclaimer {
    font-size: setUnit(16);
    font-weight: 100;
    color: $text--light-gray-color;
  }
}

.blackWrapper {
  font-size: setUnit(16);
  line-height: setUnit(20);
  color: $white;
  margin-top: setUnit(8);
  display: flex;
  justify-content: center;

  div {
    padding: setUnit(12) setUnit(24);
    background: $black-light;
    border-radius: setUnit(8);
  }
}
