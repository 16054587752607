@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 9px 32px;
  min-height: 44px;
  .iconLabel {
    display: flex;
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $light-black;
      margin-left: 12px;
    }
  }

  .address {
    text-align: end;
    p {
      margin: 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .statusWrapper {
    margin: setUnit(0) setUnit(-12) setUnit(0) setUnit(0) !important;
    padding: 0;
    div[class*='selectHolder'] {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        height: setUnit(32) !important;
        width: setUnit(132) !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        color: $white !important;
        text-align: right;
        border: 0 !important;
        padding: 0 !important;
        padding-right: setUnit(30) !important;
      }
      .arrowIcon {
        right: 8px;
      }
      svg {
        stroke: $white;
        path {
          stroke: $white;
        }
      }
    }
  }
}

.value {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.18px;
  text-align: right;
  color: $white;
  a {
    color: $link-blue;
  }
}

.pendingContainer {
  position: relative;
  display: flex;
  cursor: pointer;
  .pendingStatus {
    font-weight: 500;
    color: $white;
    text-align: right;
  }
  .lastInvite {
    font-size: 14px;
    color: $text-gray-color;
  }
  span {
    display: block;
  }
  .arrowIcon {
    margin: 0.2rem 0 0 0.2rem;
  }

  .options {
    @include box-shadow;
    position: absolute;
    top: setUnit(28);
    right: 0;
    background: $white;
    border-radius: 8px;
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;

    ul {
      border-radius: 8px;
      background-color: $white;
      list-style: none;
      margin-bottom: 0.5rem;
      overflow: hidden;
    }

    .item {
      padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
      }

      &:hover {
        background: $background-ligh-gray;
      }

      span {
        font-size: 14px;
        color: $text-color;
        text-align: left;
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}

.expanded {
  padding: setUnit(14) 0;
}

.userStatusConfirmText {
  text-align: center;
  h1 {
    font-weight: 600;
  }

  p {
    font-size: 20px;
    line-height: 22px;
    color: $text-color;
  }
}
