@import 'styles/functions';
@import 'styles/variables';

.masterControl {
  display: flex;
  border: 2px solid $background-ligh-gray-4;
  height: setUnit(44);
  width: setUnit(160);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-left: setUnit(8);
  padding-right: setUnit(8);
  margin-right: setUnit(8);

  &Label {
    margin-right: setUnit(6);
  }
}
