@import 'styles/variables';

.onBoardingStages {
  min-height: unset !important;
  height: calc(100vh - $header-height) !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: auto;
  &.client {
    height: auto !important;
  }
  .onBoardingProgress {
    position: absolute !important;
    width: 100%;
  }
}
