@import 'styles/variables';

.commentIndicator {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 99999;
  position: absolute;
  [class*='ant-avatar-group'] {
    box-shadow: 4px 10px 60px 6px rgba(0, 0, 0, 0.12);
    background: white;
    height: 28px;
    border-radius: 16px;
    position: relative;
    .commentIcon {
      border-radius: 50%;
      background-color: white;
      position: absolute;
      right: -10px;
      top: 15px;
      width: 24px;
      height: 24px;
      padding: 2px;
    }
  }
  &:hover {
    z-index: 10000;
  }
}

.avatarNameTooltip {
  [class*='ant-tooltip-inner'] {
    background-color: white !important;
    color: black !important;
    border-radius: 8px !important;
  }

  [class*='ant-tooltip-arrow-content']::before {
    background: white !important;
  }
}

.popoverContent {
  padding: 0px !important;

  [class*='ant-popover-placement'] {
    padding: 0px !important;
  }

  [class*='ant-popover-inner'] {
    border-radius: 16px !important;
  }

  [class*='ant-popover-inner-content'] {
    max-height: 500px;
    overflow: auto;
    padding: 20px !important;

    [class~='ant-collapse-content-box'] {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
