@import 'styles/variables';

.checkbox {
  margin-right: 10px !important;

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }

  [class*='ant-checkbox-inner'] {
    border-radius: 4px;
    width: 24px !important;
    height: 24px !important;
  }

  [class*='ant-checkbox-inner']::after {
    width: 8px !important;
    height: 13px !important;
  }
}

.optionalQuestionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 640px;
  width: 100%;
  height: 52px;
}

.optionalQuestionLabel {
  display: flex;
  align-items: center;

  .optionalQuestionName {
    width: 480px;
  }
}

.postFormFooter {
  height: $responsive-footer-height;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $white;
  border-top: 1px solid $light-gray;
  justify-content: space-between;
  .actionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 auto;
    max-width: 640px;
    width: 100%;
  }

  .svgContinue {
    margin-left: 13px;
  }

  .postFormFooterButton {
    width: 142px;
    height: 52px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
}

.progressBar {
  position: fixed !important;
  z-index: 5;
}

.postDynamicFormContainer {
  height: 100% !important;
}

.postFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100vh - $header-height - $responsive-footer-height);
}

.postFormRow {
  display: flex !important;
  flex-direction: column !important;
  margin: auto;
  gap: 100px;
}

.dynamicFormRow {
  // margin: unset !important;

  width: 100%;
  height: 100%;

  display: block !important;
  margin-top: 50px !important;
}

.dynamicFormRow .listContainer {
  padding-bottom: 80px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 660px;
  padding: 0 16px;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
  }
}

@media (max-width: $tablet) {
  .listContainer {
    max-width: 660px;
    width: 100%;
  }
}

.navigationInfoRow {
  background-color: transparent;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin-top: 30px;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  justify-content: space-between !important;

  @media (min-width: 992px) {
    position: absolute;
    height: 67px;
  }

  .previewForm {
    display: flex;
    width: 112px;
    align-items: center;
    height: 44px;
    justify-content: center;
    z-index: 5;
    border-radius: 100px;
    background-color: $white;
    border: 2px solid $background-ligh-gray-4;

    svg {
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;
      border-color: $text--light-gray-color;
    }
  }

  .navigationIcons {
    display: flex;
    z-index: 5;
    gap: 0 8px;
    margin-left: 10px;

    .navigationButton {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 8px;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      border: 2px solid $light-gray;
      z-index: 5;

      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }

  @media (min-width: $mobile) {
    .sideControls {
      min-width: 158px;
    }
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}

// .questionHeader {
//   font-size: 28px !important;
//   color: $secondary-color !important;
//   margin-bottom: 40px !important;
//   display: block;
// }

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;
  text-align: center;
  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.responseInput {
  margin-top: 40px !important;
  border-bottom: 2px solid #d6d6d6 !important;
  border-radius: 0px !important;
  font-size: 18px !important;
  color: #303030 !important;
  line-height: 24px !important;
}

.isDraggingOver {
  background: $hover-light-gray;
  padding: 10px 0;
}

.recipientContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .completeText {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: $text-color;
  }

  .toAction {
    margin-right: 5px;
  }

  .recipient {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .recipientPicture {
      line-height: 0px;

      .numberSequence {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        min-width: 22px;
        border: 2px solid $text-gray-color;
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;
        color: $text-gray-color;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
      }

      margin-right: 12px;
    }

    .recipientInfo {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .recipientName {
        font-weight: 500;
        font-size: 18px;
        color: $text-color;
      }

      .recipientEmail,
      .recipientRole {
        font-weight: 400;
        font-size: 14px;
        color: $dark-color;
      }
    }
  }

  .recipientAction {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    color: $dark-color;

    :hover {
      cursor: pointer;
    }
  }
}

.toSign {
  font-weight: 600;
  font-size: 16px;
  color: $text-color;
  margin-top: 32px;
  margin-bottom: 12px;
}

.button {
  width: 400px;
  height: 52px !important;
  border-radius: 8px !important;

  margin: 40px auto 0px;

  @media (max-width: $mobile-small) {
    width: 100%;
  }
}
