@import 'styles/functions';
@import 'styles/variables';

.clientTable {
  margin-left: setUnit(32);
  margin-right: setUnit(32);
  margin-bottom: setUnit(32);
  border-radius: setUnit(16);
  box-shadow: 0px 2px 16px 0px #30303008;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .table {
    border-radius: setUnit(16);
    flex: 1;

    tr {
      height: setUnit(46);
      transition: all 0.2s;

      &:first-child {
        height: setUnit(38);
      }

      &:not(:first-child):hover {
        background-color: $background-grey-2;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $background-ligh-gray-4;
      }

      td {
        overflow-wrap: anywhere;

        &:not(:last-child) {
          border-right: 1px solid $background-ligh-gray-4;
        }

        div {
          padding-left: setUnit(16);
        }

        .heading {
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $dark-grey-color;
        }

        .avatar {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.33);
          color: $text-color;
          cursor: pointer;
          width: setUnit(300);

          @media screen and (max-width: $mobile) {
            width: setUnit(224);
          }
        }

        .text {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.33);
          color: $text-color;
        }

        .addressBox {
          margin-top: setUnit(8);
          margin-bottom: setUnit(8);
          padding: 0;

          .address {
            font-weight: 600;
            font-size: setUnit(14);
            line-height: setUnit(20);
            font-family: $font-family;
            letter-spacing: setUnit(-0.33);
            color: $text-color;
            padding: 0;
          }

          .addressText {
            font-weight: 400;
            font-size: setUnit(14);
            line-height: setUnit(20);
            font-family: $font-family;
            letter-spacing: setUnit(-0.33);
            color: $text-color;
            padding: 0;
          }
        }
      }
    }
  }
}
