@import 'styles/functions';
@import 'styles/variables';

.kitsRowData {
  height: setUnit(46);
  transition: all 0.2s;

  &:not(:first-child):hover {
    background-color: $background-grey-2;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $background-ligh-gray-4;
  }

  td {
    overflow-wrap: anywhere;

    &:not(:last-child) {
      border-right: 1px solid $background-ligh-gray-4;
    }

    div {
      padding-left: setUnit(16);
    }

    .heading {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $dark-grey-color;
    }

    .kitName {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      padding-bottom: setUnit(8);
      cursor: pointer;
      display: flex;

      div {
        border-bottom: 1px solid transparent;
        padding: 0;
        transition: all 0.2s;

        &:hover {
          border-bottom: 1px solid $text-color;
        }
      }
    }

    .text {
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
    }

    .options {
      .select {
        [class*='ant-select-selector'] {
          border-radius: setUnit(16);
          width: 140px;
        }
      }
    }

    .optionText {
      border: 1px solid $light-grey-color;
      border-radius: setUnit(16);
      padding-top: setUnit(4);
      margin-left: setUnit(32);
      max-width: setUnit(140);
      height: setUnit(32);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .switchCell {
      @media screen and (max-width: $desktop) {
        padding-left: setUnit(8);
      }
    }
  }
}
