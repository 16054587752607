@import 'styles/functions';
@import 'styles/variables';

.dashboard {
  height: calc(100vh - #{setUnit(80)});

  .container {
    min-height: calc(100vh - #{setUnit(80)}); // header height
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
  }

  .contentContainer {
    margin-left: setUnit(260);
    padding: setUnit(40);
  }

  .formContentContainer {
    display: flex;
    width: 100%;
  }

  .sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: auto;
  }
  .formSlider {
    overflow: visible !important;
  }
}
