@import 'styles/functions';
@import 'styles/variables';

.commissionContainer {
  padding: setUnit(12);
  .title {
    font-weight: 600;
    font-size: setUnit(20);
  }
  .headerText {
    color: $dark-grey-color;
  }
  .seperatorMargin {
    margin: setUnit(15) 0;
  }
}
