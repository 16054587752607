@import 'styles/functions';
@import 'styles/variables';

.pageHeader {
  width: 100%;
  display: flex;
  background: $white !important;
  padding: 0px !important;
  z-index: 98;
  top: 0;
  height: $header-height !important;
  align-items: center;
  .headerContainer {
    height: $header-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px !important;
    border-bottom: 1px solid $light-gray;
    background: $white !important;
    @media screen and (max-width: $responsive-menu) {
      padding: setUnit(16) !important;
    }
    @media (min-width: $tablet) {
      position: fixed;
    }
  }
}
