@import 'styles/functions';
@import 'styles/variables';

.closingDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: -0.09px;
  .date {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(20);
  }

  .editIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: setUnit(16);
    display: none;
  }

  &:hover {
    .editIcon {
      display: block;
    }
  }
}
