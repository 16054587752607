@import 'styles/functions';
@import 'styles/variables';

.taskModalHeader {
  margin-bottom: 40px;
  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.47px;
    color: $text-color;
    &.headingTemplate {
      margin-bottom: setUnit(6);
    }
    margin: 0 !important;
  }
}
