@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.permissions {
  margin-top: 2rem;

  .item {
    label {
      color: $dark-color;
      font-family: Inter;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-weight: 600;
      margin-bottom: setUnit(16);
      display: inline-block;
      small {
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-weight: 400;
        color: $text--light-gray-color;
      }
    }
    .select {
      display: block;
      [class*='ant-select-selector'] {
        border: 0;
        border-bottom: 2px solid $border-light-gray-2 !important;
        font-family: Inter;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-weight: 400;
        padding: 0 0 setUnit(10) 0;
        width: 100%;
        box-shadow: none !important;
        [class*='ant-select-selection-placeholder'],
        [class*='ant-select-selection-item'] {
          line-height: setUnit(19);
        }
      }
      [class*='ant-select-selection-placeholder'] {
        left: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: setUnit(32);
    }
  }

  .itemPlain {
    @extend .item;

    input {
      border-bottom: none !important;
      padding: 0px !important;
    }
  }
}
