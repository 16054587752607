@import 'styles/functions';
@import 'styles/variables';

.contactModalHeader {
  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.47px;
    color: $text-color;
    margin-bottom: setUnit(6);
  }
}
