@import 'styles/functions';
@import 'styles/variables';

.timeInput {
  display: inline-block;
  margin-right: 0.25rem;

  input {
    width: 2.75rem;
    border: 0;
    border-radius: 0 !important;
    border-bottom: 2px solid $border-input-middle-gray;
    margin-right: 0.25rem;
    padding: 0.25rem 0;

    &:focus,
    &:hover {
      border-color: $border-input-middle-gray;
      box-shadow: none;
    }
  }

  [class*='ant-input-disabled'] {
    background-color: unset !important;
  }

  [class*='ant-select'] {
    [class*='ant-select-selector'] {
      width: 3.1rem !important;
      padding: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }

  [class*='ant-select-disabled'] {
    [class*='ant-select-selector'] {
      background: unset !important;
    }
  }
}
