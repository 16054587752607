@import 'styles/variables';

.editMode {
  cursor: crosshair;
}

.drawArea {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%) rotate(0deg);

  width: 100%;
  height: 100%;
  .polyLineContainer {
    width: 100%;
    height: 100%;
  }
}

.polyLine {
  cursor: pointer !important;
}
