@import 'styles/functions';
@import 'styles/variables';

.item {
  display: flex;
  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.autocomplete {
  position: relative;
  .iconWrapper {
    transition: all 0.15s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .icon {
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }

  .autocompleteWrapper {
    position: absolute;
    left: 0;
    bottom: setUnit(102);
    z-index: 100;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 14%);
    border-radius: 8px;
    background: #ffffff;
    padding: 0.3rem;

    .autocompleteContainer {
      width: setUnit(312);

      .autocompleteInput {
        .searchIcon {
          margin-right: setUnit(10);
        }
      }
      :global {
        .ant-select-selector {
          border-style: none;
          font-weight: setUnit(400);
          font-size: 14px;
          line-height: 16px;
        }

        .ant-input-affix-wrapper {
          border: none;
          box-shadow: none;
          :hover,
          :focus {
            border: none;
          }
        }
      }
    }

    .autoCompleteTyping {
      [class*='ant-select'] {
        pointer-events: none !important;
        background-color: $background-ligh-gray-4 !important;
      }
      [class*='ant-input'] {
        background-color: $background-ligh-gray-4 !important;
      }
    }
  }
}

.suggestionPopupContainer {
  padding: 0 !important;

  & [class*='ant-select-item-option-content'] {
    padding: setUnit(6) setUnit(4);
  }
}
