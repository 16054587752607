@import 'styles/variables';
@import 'styles/functions';

.container {
  min-height: 70px;
  width: calc(35vw - 38px);
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  padding: 2px 20px !important;
  border-radius: 12px !important;
  border: 1.5px solid $light-gray !important;
  position: relative;
  margin-bottom: 12px !important;
  display: flex;
  align-items: center;

  [class*='ant-collapse-item-active'] {
    padding-top: 10px !important;
    width: 100% !important;
  }

  &.darkBlue {
    background-color: $background-ligh-gray-2;
    border-color: $confetti-blue !important;
  }
  &.red {
    background-color: $background-ligh-gray-2;
    border-color: $primary-color !important;
  }
  &.yellow {
    background-color: $background-ligh-gray-2;
    border-color: $orange !important;
  }
  &.purple {
    background-color: $background-ligh-gray-2;
    border-color: $purple !important;
  }
  &.blue {
    background-color: $background-ligh-gray-2;
    border-color: $light-blue !important;
  }

  &:hover {
    background: $background-ligh-gray-2;
  }
  .header {
    width: calc(35vw - 75px);
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 3px;

    .span {
      width: calc(35vw - 155px);
      @media only screen and (max-width: 700px) {
        min-width: calc(100vw - 180px);
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      min-width: 48px;
      min-height: 48px;
      .svg {
        padding-top: 6px;
      }
      &.darkBlue {
        background-color: $confetti-blue-lighter;
      }
      &.red {
        background-color: $primary-color-lighter;
      }
      &.yellow {
        background-color: $orange-lighter;
      }
      &.purple {
        background-color: $purple-lighter;
      }
      &.blue {
        background-color: $light-blue-lighter;
      }
    }
    .label {
      color: $dark-color;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.09px;
      margin: 0;
    }
    .subtitle {
      color: $dark-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      margin: 0;
      max-width: calc(50vw - 300px);
      @media only screen and (max-width: 700px) {
        min-width: calc(100vw - 200px);
      }
    }
  }

  .itemsWrapper {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .item {
      height: 60px;
      width: calc(50% - 6px);
      padding: 20px 16px;
      border-radius: 12px;
      border: 1.5px solid;
      border-color: $light-gray;
      margin-bottom: 12px;
      background-color: $white;
      cursor: pointer;
      &.darkBlue {
        background-color: $confetti-blue-lighter;
        border-color: $confetti-blue;
        .checkbox {
          background: $confetti-blue !important;
        }
      }
      &.red {
        background-color: $primary-color-lighter;
        border-color: $primary-color;
        .checkbox {
          background: $primary-color !important;
        }
      }
      &.yellow {
        background-color: $orange-lighter;
        border-color: $orange;
        .checkbox {
          background: $orange !important;
        }
      }
      &.purple {
        background-color: $purple-lighter;
        border-color: $purple;
        .checkbox {
          background: $purple !important;
        }
      }
      &.blue {
        background-color: $light-blue-lighter;
        border-color: $light-blue;
        .checkbox {
          background: #51bfe1 !important;
        }
      }
    }
  }
}
