@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.tasksList {
  padding-bottom: setUnit(20);

  .spinnerWrap {
    min-height: calc(100vh - $header-height - $footer-height - $workshop-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatarRow {
    display: flex;
    align-items: center;
    .avatar {
      width: setUnit(24);
      height: setUnit(24);
      font-size: setUnit(11);
      font-weight: 600;
      line-height: setUnit(26);
      letter-spacing: setUnit(-0.33);
      margin-right: setUnit(10);
    }
  }

  .taskTable {
    [class*='ant-spin-spinning'] {
      top: unset;
      bottom: 0 !important;
    }

    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
    }

    td {
      p {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.33);
        color: $text-color;
        margin-bottom: 0;
      }
    }

    th,
    td {
      cursor: pointer;
      &:last-child {
        border-right: unset !important;
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
    }

    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
    }

    table {
      border: unset !important;
    }

    .taskTitle {
      font-size: setUnit(14);
      font-weight: 600;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.33);
      color: $text-color;
    }
    .avatarWrapper {
      display: inline-block;
      margin-right: 0.5rem;
      .avatar {
        width: 1.5rem;
        height: 1.5rem;
        .avatarPlaceholder {
          font-size: 10px;
        }
      }
    }

    .assigneeName {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      margin-bottom: 0;
    }

    .tagContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category {
        .statusText {
          color: $text-gray-color;
          text-transform: capitalize;
        }
      }

      .actionSelectContainer {
        display: flex;
        align-items: center;

        .actions {
          .optionDots {
            width: 2rem;
            height: 2rem;
          }
        }

        .multiSelect {
          margin: 6px 16px;

          &:hover,
          &:focus {
            [class*='ant-checkbox-inner'] {
              border-color: $primary-color;
            }
          }
        }
      }
    }

    & [class*='ant-pagination'] {
      display: none;
    }
  }
}

.taskWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .transactionTask {
    display: flex;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: setUnit(10);
      color: $dark-color;
      border-radius: 0.6rem;
      svg {
        width: 32px;
        height: 32px;
      }
      &:hover {
        svg {
          border-radius: 50%;
          background-color: $light-grey-color;
        }
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .line1 {
        font-weight: 600;
      }
      .line2 {
        color: $dark-color;
        font-size: setUnit(14);
      }
    }
    svg {
      width: 24px;
      height: 24px;
      opacity: 1;
      path {
        stroke: $dark-color;
      }
    }
  }

  .commentWrapper {
    margin-left: setUnit(8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .count {
      font-size: setUnit(11);
      font-weight: 700;
      color: $white;
      position: absolute;
      top: setUnit(-2.5);
      right: setUnit(2.5);
    }
    .icon {
      display: flex;
      svg {
        fill: $dark-color;
      }
    }

    &.isUnread {
      .icon {
        &:before {
          content: '';
          width: setUnit(18);
          height: setUnit(18);
          border-radius: setUnit(10);
          border: 2px solid $white;
          background: $primary-color;
          position: absolute;
          top: setUnit(-3.5);
          right: setUnit(-2.5);
        }
      }
    }
    .ninePlus {
      @extend .count;
      font-size: setUnit(10);
      top: setUnit(-3);
      margin-right: setUnit(-3.8);
    }
  }
}

.statusOnly {
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px !important;
  text-align: center;
  color: $white !important;
  font-size: 14px;
  font-weight: 600 !important;

  .statusText {
    white-space: nowrap;
    overflow: hidden;
  }

  &.new {
    background-color: $confetti-blue !important;
  }

  &.inProgress {
    background-color: $orange !important;
  }

  &.stuck {
    background-color: $primary-color !important;
  }

  &.done {
    background-color: $hover-green !important;
  }

  &.overdue {
    background: $error-color !important;
  }
}

.formModal {
  .title {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
  }
}

.datePrimary {
  color: $error-color;
  font-weight: 500;
}
