@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: 100%;
  min-height: calc(100vh - $header-height - $footer-height);
  color: $text-color;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  width: 72vw;
  height: 100%;
  max-width: $tablet-horizontal;
  margin: 0 auto;
  display: flex;
  flex: auto;
  justify-content: center;
  @media screen and (max-width: $desktop) {
    min-width: 85vw;
  }

  @media screen and (max-width: $tablet-horizontal) {
    min-width: 90vw;
  }

  @media screen and (max-width: $tablet) {
    min-width: 100vw;
  }
}
