@import 'styles/functions';
@import 'styles/variables';

.container {
  padding-bottom: setUnit(0);
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .price {
    color: $text-color;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(24);
    letter-spacing: setUnit(-0.47);
    margin-bottom: setUnit(8);
  }
  .activityStatus {
    font-size: setUnit(16);
    font-weight: 600;
    line-height: setUnit(21);
    letter-spacing: setUnit(-0.18);
    padding: setUnit(6) setUnit(8);
    border-radius: setUnit(30);
    &.Active {
      color: $green-color;
      background-color: rgba(0, 177, 82, 0.1);
    }
    &.Pending,
    &.ComingSoon,
    &.Incomplete,
    &.Hold {
      color: $primary-color;
      background-color: rgba(255, 87, 109, 0.1);
    }
    &.ActiveUnderContract,
    &.Contingent,
    &.AcceptingBackups {
      color: $orange;
      background-color: rgba(251, 145, 58, 0.1);
    }
    &.Closed,
    &.Sold,
    &.Withdrawn,
    &.Canceled,
    &.Expired,
    &.Delete {
      color: $text-gray-color;
      background: rgba(116, 116, 117, 0.1);
    }
  }
}

.address {
  font-size: setUnit(16);
  line-height: setUnit(21);
  letter-spacing: -0.18px;
  .lineOne {
    font-weight: 500;
    color: $dark-color;
    margin-bottom: 0;
  }
  .lineTwo {
    font-weight: 400;
    color: $dark-grey-color;
    margin-bottom: 0;
  }
}

.shortNumbers {
  display: flex;
  align-items: center;
  margin-bottom: setUnit(8);
  font-size: setUnit(16);
  .seperator {
    margin: 0 setUnit(12);
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
  }
  span {
    b {
      font-weight: 600;
      &::after {
        content: ' ';
      }
    }
  }
}

.descriptionInfo {
  margin-top: setUnit(10);
  font-size: setUnit(12);
  line-height: setUnit(16);
  color: $dark-color;
}

.mlsLastUpdate {
  font-size: setUnit(12);
  font-style: italic;
  line-height: setUnit(16);
  color: $dark-color;
}

.seperatorSmall {
  margin: 0 4px;
}
