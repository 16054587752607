@import 'styles/variables';
@import 'styles/mixins';

.errorMessage {
  font-size: 28px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.preFormFooterButton {
  margin-top: 44px;
  width: 100%;
  height: 52px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;

  max-width: 400px;
  margin: 40px auto 0 auto;
}

.progressBar {
  position: fixed !important;
  z-index: 5;
  top: 79px !important;
}

.preFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100dvh - $header-height - $responsive-footer-height);
  overflow: auto;
}

.listContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
  width: 100%;
  max-width: 80ch;
  padding: 0 24px;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
  }

  > div {
    width: 100%;
  }

  @media screen and (max-width: $mobile-small) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.signFormListContainer {
  max-width: 107.5ch;
  margin-left: unset;
  margin-right: unset;
}

.navigationInfoRow {
  background-color: transparent;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  padding: 33px 40px;
  justify-content: space-between !important;
  z-index: 1;

  .navigationIcons {
    display: flex;
    position: relative;

    z-index: 5;

    .navigationButton {
      background-color: $white;
      @include flexCenter;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      border: 2px solid $light-gray;
      z-index: 5;
      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}

.formPill {
  .preFormPdfInfo {
    border: unset;

    .pdfNameInformation {
      color: $dark-color;
      max-width: 400px;
    }

    &:hover {
      background-color: $background-grey-2;
      cursor: pointer;
      .pdfNameInformation {
        color: $text-color;
      }
    }
  }

  .editablePdfInfo {
    border: 1px solid $text-gray-color;

    &:hover {
      background-color: #fff;
    }

    input {
      text-align: left;
      max-width: 400px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }

    svg {
      width: 24px;
    }
  }
}
