@import "styles/functions";
@import "styles/variables";

.attachmentsPreview {
    width: 100%;
    cursor: pointer;

    .attachmentOuter {
        margin: setUnit(2) 0;
    }
}
