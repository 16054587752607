@import 'styles/functions';
@import 'styles/variables';

.container {
  max-width: 600px;
}

.question {
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.8px;
}

.answersWrapper {
  margin: setUnit(32) 0 setUnit(24) 0 !important;
  max-width: initial !important;
  .answer {
    border-radius: setUnit(8) !important;
    box-shadow: none !important;
    border: 2px solid $light-gray !important;
    cursor: pointer;
    margin-bottom: 1rem !important;
    .label {
      font-weight: 400 !important;
      color: $dark-grey-color !important;
      font-size: 18px !important;
      line-height: 24px !important;
      margin-top: -2px !important;
    }
    &:hover {
      border: 2px solid $text--light-gray-color !important;
      color: $text-color !important;
    }
  }
  .answerActive {
    background-color: $primary-color-light !important;
    border: 1px solid $primary-color !important;
    .label {
      font-weight: 500 !important;
      color: $text-color !important;
    }
    &:hover {
      border: 1px solid $primary-color-hover !important;
      background-color: #ffebed !important;
    }
  }
}
