@import 'styles/functions';
@import 'styles/variables';

@import 'styles/mixins';

.wrapper_editableDiv {
  div {
    padding: 24px 0 0 24px !important;
  }

  p {
    padding: 24px 0 0 24px !important;
  }
}

.editableDiv {
  border: none !important;
  font-weight: 400;
  font-size: setUnit(14);
  line-height: setUnit(16);
  font-family: $font-family;
  letter-spacing: setUnit(-0.18);
  outline: none;
  margin: 0;
  width: 500px;
  vertical-align: middle;
  color: #262626;
  font-size: 1.125rem;
  font-weight: 400;
  top: 0;
  padding-top: 2px !important;
  line-height: 1.2;
  overflow: scroll;
  position: sticky;
  z-index: 10;
}

.placeholder {
  top: 0;
  position: absolute;
  color: $text--light-gray-color;
  line-height: setUnit(16);
  letter-spacing: -0.18px;
  border: none !important;
  font-size: setUnit(16);
  font-family: $font-family;
  letter-spacing: setUnit(-0.18);
  outline: none;
  margin: 0;
  height: 50px;
  font-weight: 300;
  padding-top: 2px !important;
}

.tagHyperLink {
  color: #2b59da;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #2b59da;
  }
}
