@import 'styles/variables';
@import 'styles/functions';

.fieldContainer {
  label,
  .fieldLabel {
    color: $text-color;
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    margin-bottom: 12px;
  }

  .selectInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .inputIcon {
      line-height: 0;
      position: absolute;
      top: 30%;
      left: 14px;
      z-index: 2;
    }

    [class~='ant-select-selector'] {
      border: 1px solid $border-light-gray-2 !important;
      box-shadow: none !important;

      border-radius: 8px !important;
      font-size: setUnit(16) !important;
      height: setUnit(48) !important;

      padding: 4px setUnit(13) 4px setUnit(40) !important;

      [class~='ant-select-selection-search'] {
        left: 44px;
      }

      [class~='ant-select-selection-placeholder'] {
        color: $text--light-gray-color;
      }

      :is([class~='ant-select-selection-placeholder'], [class~='ant-select-selection-item']) {
        padding-left: 4px;
      }

      &:focus {
        border-color: $text--light-gray-color !important;
      }

      input {
        height: 100% !important;
      }
    }

    [class~='ant-select-open'] {
      [class~='ant-select-selector'] {
        border-color: $text--light-gray-color !important;
      }
    }
  }
}
