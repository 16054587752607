@import 'styles/functions';
@import 'styles/variables';

.closeWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: right;
  padding: 36px 40px 0;
  position: relative;
  p {
    color: $text-color;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.47px;
    margin: 0;
  }
  .close {
    position: absolute;
    top: 33px;
    right: 40px;
    cursor: pointer;
    transition: $transition;
    padding-top: 6px;
    z-index: 999;
    svg {
      width: setUnit(32);
      height: setUnit(32);
    }
  }
}
