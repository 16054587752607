@import 'styles/variables';
@import 'styles/functions';

.toursWrapper {
  padding: setUnit(0) 0;
  width: 100%;
  .tourCardsWrapper {
    padding: setUnit(24);
    background-color: $background-ligh-gray-3;
    .tourCards {
      max-width: setUnit(600);
      width: 100%;
      margin: auto;
      min-height: calc(100vh - 14rem);
    }
  }
}
