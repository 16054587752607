@import 'styles/mixins';
@import 'styles/variables';

.datePickerInput {
  &.light {
    .inputField {
      @include inputLightInnerInput();
    }

    .required {
      color: $primary-color;
    }

    .icon {
      position: absolute;
      left: 4px;
      top: calc(50% - 12px);
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      svg {
        width: setUnit(24);
        height: setUnit(24);
        vertical-align: middle;
      }
    }

    .circledIcon {
      background-color: $grey-color;
      width: setUnit(32);
      height: setUnit(32);
      border-radius: setUnit(100);

      svg {
        width: setUnit(20);
        height: setUnit(20);
        vertical-align: middle;
      }
    }

    .inputField {
      cursor: pointer;
      padding-left: setUnit(36);
    }

    .inputInner {
      position: relative;
      [class*='flatpickr-wrapper'] {
        width: 100%;
      }
    }
  }

  &.rounded {
    .inputField {
      @include lightRoundDate();
    }

    label {
      color: $text-color;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.09px;
    }

    .required {
      color: $primary-color;
    }

    .icon {
      position: absolute;
      left: 4px;
      top: calc(50% - 12px);
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      margin-left: setUnit(10);
      margin-top: setUnit(2);

      svg {
        width: setUnit(24);
        height: setUnit(24);
        vertical-align: middle;
      }
    }

    .circledIcon {
      background-color: $grey-color;
      width: setUnit(32);
      height: setUnit(32);
      border-radius: setUnit(100);

      svg {
        width: setUnit(20);
        height: setUnit(20);
        vertical-align: middle;
      }
    }

    .inputField {
      cursor: pointer;
      padding-left: setUnit(36);
    }

    .inputInner {
      position: relative;
      margin-top: 12px;
      [class*='flatpickr-wrapper'] {
        width: 100%;
      }
    }
  }

  &.monthOnly {
    .flatpickr-rContainer > div {
      display: grid; /* Use grid layout */
      grid-template-columns: repeat(3, 1fr); /* Two columns */
      gap: 14px !important; /* Optional: space between buttons */
      padding: 24px !important; /* Optional: space between buttons */
    }

    .flatpickr-monthSelect-month {
      font-size: 16px;
      font-weight: bold;
      color: #252d44;
      background-color: white;
      border: 1px solid #252d44;
      padding: 10px;
      border-radius: 5px;
      transition: background-color 0.3s ease;
      text-align: center;
      cursor: pointer;
    }

    .flatpickr-monthSelect-month:hover {
      background-color: rgba(37, 45, 68, 0.098);
    }

    .flatpickr-monthSelect-month.selected {
      border: 1px solid #252d44;
      background-color: #252d44;
      color: white;
    }
  }

  .error {
    @include inputError;
  }
}

.datePickerUnderline {
  border-bottom: 2px solid $border-light-gray-2;
}

.datePickerPopoverOverlay {
  [class*='ant-popover-content'] {
    top: -2rem;
    left: 2rem;
    width: 0;

    [class*='ant-popover-inner'] {
      background: transparent;
      box-shadow: unset;
    }
  }
}

.datePickerPopover > div > input {
  display: none !important;
}

.modalFlatPicker {
  @media screen and (max-width: 400px) {
    ~ [class~='flatpickr-calendar'] {
      width: 300px;
      [class~='cur-month'] {
        margin-left: 0px !important;
      }
    }
  }

  @media screen and (max-width: 350px) {
    ~ [class~='flatpickr-calendar'] {
      left: -20px;
    }
  }
}
