@import 'styles/functions';
@import 'styles/variables';

.notificationsMediumsWrap {
  border-bottom: 1px solid $light-gray;
  padding: setUnit(24) 0;
  .generalBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: setUnit(32);
    }
    .contentWrap {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 calc(100% - #{setUnit(64)});
      margin-right: setUnit(32);
      .icon {
        flex: 0 0 24px;
        margin-right: setUnit(16);
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 calc(100% - #{setUnit(40)});
        h4 {
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-weight: 600;
          letter-spacing: -0.18px;
          margin-bottom: setUnit(4);
          color: $text-color;
          flex: 0 0 100%;
        }
        h5 {
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-weight: 500;
          letter-spacing: -0.09px;
          color: $text-gray-color;
          margin-bottom: 0;
          flex: 0 0 100%;
          .phone {
            display: inline-block;
            margin-left: setUnit(8);
          }
        }
      }
    }
    .edit {
      width: setUnit(32);
      height: setUnit(32);
      border-radius: setUnit(100);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
