@import 'styles/functions';
@import 'styles/variables';

.right {
  overflow: auto;
  width: 100%;
  @media (min-width: $tablet) {
    width: 50%;
    min-height: calc(100vh - $header-height);
  }
}
