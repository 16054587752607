@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$hover-color: lighten($secondary-color, 10);

.titleWrapper {
  border-bottom: 2px solid $light-gray;
  padding-bottom: setUnit(28);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 600;
    font-size: setUnit(24);
    line-height: setUnit(29);
    letter-spacing: -0.47px;
    color: $text-color;
    margin: 0;
  }

  .saveBtn {
    width: setUnit(78);
    height: setUnit(44);
    border-radius: 100px !important;
  }
}

.flexCol {
  flex-direction: column;
}

.sectionWrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  border-bottom: 1px solid $grey-color;

  &.tableSection {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &.sectionHeader {
    padding: 24px 0 38px 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }

  .transactionsTable {
    width: 100%;

    .row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      padding: 11px 14px 9px 14px;

      color: var(--Grayscale-80, #515151);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.18px;
    }
    .row:nth-of-type(even) {
      background: #f4f5f6;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex-grow: 1;

    .planDetails {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.09px;

        cursor: pointer;
      }

      .details {
        color: var(--Secondary-Link-Blue, #2b59da);
      }

      .downgrade {
        color: var(--Tertiary-Error-Red, #ec455e);
        cursor: pointer;
      }
    }

    .legacyPlans {
      display: flex;
      align-items: center;
      column-gap: 20px;
      & > div {
        display: flex;
        align-items: center;
        column-gap: 15px;
        padding: 28px 24px;
        flex-grow: 1;

        border-radius: 16px;
        border: 2px solid var(--Grayscale-20, #e8e9ea);
        background: #fff;

        &.selected {
          border: 2px solid var(--primary-primary-red-100-main, #ff576d);
          background: var(--Primary-Primary-Red-10, #fff2f3);
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 24px;
          height: 24px;

          border-radius: 50%;
          cursor: pointer;

          &.active {
            border: 1px solid var(--REBRAND-Primary-Red, #ff576d);
            background: var(--REBRAND-Primary-Red, #ff576d);
          }
          &.inactive {
            border: 2px solid #aaabab;
          }
        }
      }
    }
  }

  .currentPlanTitle {
    font-weight: 400;
    font-size: setUnit(18);
    line-height: setUnit(20);
    letter-spacing: -0.33px;
    color: $text-color;
    strong {
      font-weight: 600;
    }
  }

  .upgradeBtn {
    padding: 12px 20px;
    border-radius: 8px;
    background: var(--REBRAND-Primary-Navy, #252d44);
    height: initial;
  }

  .paymentInfo {
    display: flex;
    column-gap: 12px;

    .paymentTypeDetails {
      margin-left: 1rem;
    }

    .paymentInfoTitle {
      display: flex;
      align-items: center;
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      color: $text-color;
      &__icon {
        // margin-right: setUnit(8);
      }
    }

    .paymentInfoSubtitle {
      color: $dark-grey-color;
      padding-right: 1rem;
      line-height: 18px;
    }

    .paymentInfoPrice {
      color: $dark-color;
      font-weight: 500;
    }
  }

  .modifyBtn,
  .currentPlanBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: setUnit(8) setUnit(12);
    border-radius: setUnit(100);
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: -0.09px;
    height: setUnit(36);
    min-width: setUnit(80);
    border: 2px solid $border-light-gray-2;
    color: $text-color;
  }

  .modifyBtn {
    cursor: pointer;

    span {
      color: var(--Grayscale-50, #747475);
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.09px;
    }

    svg {
      path {
        fill: $dark-color;
        stroke-width: 1px;
        stroke: $dark-color;
      }
    }

    &.active {
      background: $secondary-color;
      color: #ffffff;
      border-color: $secondary-color;

      svg {
        path {
          fill: #ffffff;
          stroke: #ffffff;
        }
      }

      &:hover {
        background-color: $hover-color;
        border-color: $hover-color;
      }
    }

    &:hover {
      background-color: $background-ligh-gray;
    }
  }

  .selectedBtn {
    background: $primary-color-light;
    border: 1px solid $primary-color;

    &:hover {
      background-color: $primary-color-light;
    }
  }

  .currentPlanBtn {
    min-width: 8rem;
    background: $background-gray;
    border-color: $background-gray;
    font-weight: 600;

    .checkmarkIcon {
      margin-left: -0.2rem;
      margin-right: 0.25rem;
    }
  }
}

.disclaimer {
  margin-top: setUnit(24);

  .cancelPlan {
    color: $dark-grey-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.33px;
    cursor: pointer;
    display: inline;
  }

  .downgrade {
    color: var(--Grayscale-40, #aaabab);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.33px;
    display: inline;
    cursor: pointer;
  }

  span {
    text-decoration: underline;
  }
}

.filterWrapper {
  border-radius: setUnit(100);
  padding: 0.5rem 0.75rem;
  width: fit-content;
  cursor: pointer;
  background-color: $background-grey-2;
  .icon {
    display: flex;
    margin-top: 0.1rem;
    margin-right: 0.5rem;
  }
}

.aggregateWrapper {
  height: setUnit(44);
  background-color: $white;
  border: 2px solid $light-gray;
  border-radius: 100px;
  padding: 0.5rem 0.6rem;
  &[class~='ant-popover-open'] {
    border-color: $text-gray-color !important;
    background-color: $background-grey-2 !important;
  }
  &:hover {
    border-color: $text--light-gray-color;
  }
  .icon svg path {
    stroke: $dark-color;
  }
}

.seatsCount {
  padding: 20px 24px;
  max-width: setUnit(240);

  &__titleAndValue {
    display: flex;
    justify-content: space-between;
    font-size: setUnit(16);
    font-weight: 600;
  }

  &__disclaimer {
    margin-top: setUnit(8);
    font-size: setUnit(12);
    color: $text-gray-color;
  }
}

.separator {
  margin: setUnit(16) 0;
  border-top: 1px solid $light-gray; /* Light gray border */
}

.popupClassName {
  :global {
    .ant-popover-inner {
      border-radius: 8px;
    }
  }
}

.subscriptionInactiveLabel {
  color: $error-color;
}

.renewButton {
  font-size: setUnit(14) !important;
  font-weight: 400 !important;
  border-radius: 100px !important;
  height: setUnit(36) !important;
  min-width: setUnit(92) !important;
}

.overdueNote {
  font-size: setUnit(16);
  color: $error-color;
  margin-top: setUnit(8);
}

.cancelBanner {
  border-radius: 8px;
  background: var(--Primary-Red-10, #fff2f3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
  flex-grow: 1;
  margin-top: 24px;

  .text {
    color: var(--Tertiary-Error-Red, #ec455e);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.18px;
    max-width: 345px;

    span {
      color: var(--Tertiary-Error-Red, #ec455e);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border-radius: 8px;
      background: var(--REBRAND-Secondary-White, #fff);
      box-shadow: 0px 0px 32px 0px rgba(79, 79, 79, 0.13);
      padding: 10px 16px;
      color: var(--REBRAND-Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.09px;
      border: none;
      cursor: pointer;

      width: 142px;
      height: 40px;
    }
  }
}

.nextBillingInfo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  color: var(--Grayscale-60, #676767);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.09px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  column-gap: 8px;

  .addTeamMembers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;

    border-radius: 100px;
    background: var(--grayscale-10-background, #f4f5f6);
    padding: 8px 16px 8px 12px;
    cursor: pointer;

    color: var(--Grayscale-80, #515151);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
