@import "styles/functions";
@import "styles/variables";

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
        font-size: setUnit(20);
        font-weight: 400;
    }
}
