@import 'styles/variables';

.commonSidebar {
  box-shadow: 0px 2px 16px 0px #3030300f;

  & [class*='ant-menu-title-content'] {
    line-height: 56px;
  }

  & [class*='ant-menu-item'] {
    height: 56px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  & [class*='ant-menu-item-selected'] {
    background-color: $primary-color-light !important;
  }

  & [class*='ant-menu-item-active'] {
    background-color: $background-ligh-gray !important;
  }
}
