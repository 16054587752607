@import 'styles/functions';
@import 'styles/variables';

.filterWrapper {
  width: fit-content;
  padding: 0 1rem;
  height: setUnit(44);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background: $white;
  border: 2px solid $light-gray;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: $dark-color;

  .icon {
    line-height: 0;
  }

  &:hover {
    border-color: $text--light-gray-color;
  }
}

.openedFilterWrapper {
  background-color: $background-grey-2;
  border-color: $text-gray-color !important;
  color: $text-color;

  .icon {
    svg {
      path {
        stroke: $text-color;
      }
    }
  }
}

.appliedFilterWrapper {
  border-color: $primary-color !important;
  background-color: $primary-color-light;
  color: $text-color;

  .icon {
    svg {
      path {
        stroke: $text-color;
      }
    }
  }
}
