@import 'styles/variables';

.sectionContainer {
  height: 32px;
  background-color: $background-grey-2;
  .letter {
    padding: 8px 32px;
    line-height: 16px;
    margin-bottom: 0;
  }
}
