@import 'styles/functions';
@import 'styles/variables';

.flexTooltip {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    white-space: nowrap;
  }
}
.whiteArrowTop {
  position: relative;
  top: 2px;
  svg {
    path {
      stroke: white !important;
    }
  }
}
.whiteArrow {
  position: relative;
  bottom: 4px;
  svg {
    path {
      stroke: white !important;
    }
  }
}

.compareOfferTooltip {
  div[class~='ant-tooltip-content'] {
    width: auto !important;
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 50vw;

      p {
        color: white !important;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.propertyCard {
  width: 200px;
  height: 198px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid var(--Grayscale-20, #e8e9ea);
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .propertyImageWrapper {
    flex-grow: 1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    width: 100%;
    height: 104px;
    background-color: #f4f5f6;

    .propertyImage {
      width: 100%;
      height: 104px;
      object-fit: cover;
    }
  }

  .propertyDetails {
    padding: 10px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .addressMain {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.09px;
      margin-bottom: 4px;
    }
    .addressSecondary {
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      margin-bottom: 8px;
    }
  }

  .matchPercentage {
    height: 24px;
    display: inline-flex;
    padding: 4px 6px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    &.green {
      background: $green-color;
    }
    &.orange {
      background: $orange;
    }
    &.gray {
      background: rgba(0, 0, 0, 0.4);
    }
    overflow: hidden;
    color: var(--White, #fff);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
  }

  .overFlowText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .position {
    position: relative;
    bottom: 3px;
  }

  .agentHighlights {
    height: 24px;
    max-width: 176px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #fff1b1;
    color: #643a17;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.09px;
  }

  .openHouses {
    height: 24px;
    max-width: 176px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: rgba(81, 191, 225, 0.12);
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.09px;
  }
  .flex {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .priceReductions {
    height: 24px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 2px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: rgba(81, 191, 225, 0.12);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    // line-height: 16px; /* 123.077% */
    letter-spacing: -0.09px;
  }

  .priceFrom {
    overflow: hidden;
    color: rgba(38, 38, 38, 0.5);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    // line-height: 16px; /* 123.077% */
    letter-spacing: -0.09px;
  }

  .priceTo {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    // line-height: 16px;
    letter-spacing: -1px;
  }

  .grayText {
    color: $text-color;
    opacity: 0.5;
    margin-bottom: 0 !important;
    .black {
      color: black;
    }
  }
  .arrow {
    color: $text-color;
    opacity: 0.5;
    margin-bottom: 0 !important;
    position: relative;
    left: 2px;
    top: 4px;
  }

  .commentWrapper {
    cursor: pointer;
    margin: 0 setUnit(4);
    height: setUnit(32);
    min-width: setUnit(32);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    .count {
      color: var(--Grayscale-80, #515151);
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.09px;
    }
    .icon {
      svg {
        vertical-align: middle;
        fill: var(--Grayscale-80, #515151);
      }
    }
  }
}
