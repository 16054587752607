@import 'styles/variables';
@import 'styles/functions';

.container {
  height: auto;
  width: 100%;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1.5px solid $light-gray;
  margin-bottom: 15px;
  position: relative;
  .componentWrapper {
  }
  &.active {
    background: #fcfcfd;
  }
  &:hover {
    background: #fcfcfd;
    .edit {
      display: flex;
    }
  }
  .edit {
    padding-top: 4px;
    z-index: 1;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    border: 2px solid $background-ligh-gray-4;
    background: #fff;
    &.active {
      display: flex;
    }
  }
  .label {
    color: $dark-grey-color;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.09px;
    white-space: nowrap;
  }

  .placeholder {
    color: $text-gray-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.09px;
    white-space: nowrap;
    margin: 0;
  }
}
