@import 'styles/variables';

.pageContainer {
  width: 100%;
  height: 100%;

  .pageContainerDrop {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.droppablePage {
  opacity: 0.8;
  background-color: $primary-color !important;

  [class*='react-pdf__Page__canvas'] {
    opacity: 0.8;
  }
}

.documentPage {
  margin-bottom: 10px;
}
