@import 'styles/functions';
@import 'styles/variables';

.propertiesHeader {
  position: relative;
  padding: setUnit(20) setUnit(40);
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    margin: 0;
    font-weight: 700;
    font-size: setUnit(32);
  }

  .headerActions {
    display: flex;
    align-items: center;
    gap: setUnit(16);
    position: absolute;
    right: setUnit(32);

    .closeBtn {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .closeBtn,
    .haButton {
      width: 44px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 2px solid $light-gray;
      border-radius: 100px;
      transition: $transition;
      background-color: transparent !important;

      &:hover {
        border-color: $text-gray-color;
      }
      .icon {
        svg {
          path {
            stroke: $dark-color;
          }
        }
      }
    }
    .activeButton {
      width: 44px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 2px solid $light-gray;
      border-radius: 100px;
      transition: $transition;
      background-color: $background-blue !important;
      border-color: $text-gray-color;

      .icon {
        svg {
          path {
            stroke: $dark-color;
          }
        }
      }
    }
  }
}
