@import 'styles/variables';

.onBoardingContainer {
  .middle {
    flex: 0 0 100%;
    height: 100vh;
  }
  &.greyBackground {
    background-color: $background-ligh-gray-3;
  }
}
