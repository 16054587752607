@import "styles/functions";
@import "styles/variables";

.docusign {
    cursor: pointer;

    .icon {
        margin-right: setUnit(20);
    }
}
