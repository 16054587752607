@import 'styles/functions';
@import 'styles/variables';

.document {
  background-color: $background-gray;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0.5rem 0;

  .docIcon {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .info {
    display: inline-block;

    h4 {
      margin: 0;
      line-height: 1rem;
    }

    P {
      color: $dark-color;
      margin: 0;
      font-size: 13px;
    }
  }

  .dropdown {
    float: right;

    .moreIcon {
      margin-top: 0.25rem;
      cursor: pointer;
    }
  }
}

.preApprovalMenu {
  border-radius: 8px !important;
  padding: 0px !important;
  width: 160px !important;
}

.preApprovalMenu .action {
  padding: setUnit(14) setUnit(16) !important;

  &:first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  &:last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  [class~='ant-dropdown-menu-item-icon'] {
    line-height: 0;
  }

  [class~='ant-dropdown-menu-title-content'] {
    color: $text-color;
  }
}

@media screen and (max-width: 576px) {
  .document {
    padding: 1rem;

    .time {
      display: none;
    }
  }
}
