@import 'styles/variables';

.container {
  height: calc(100dvh - $header-height);
}

.dynamicFormContainer {
  height: calc(100dvh - $header-height - $forms-footer-height) !important;

  @media screen and (max-width: $tablet) {
    height: unset !important;
  }
}
