@import 'styles/functions';
@import 'styles/variables';

$sell-label-color: $light-blue-2;
$buy-label-color: $green-2;
$buy-and-sell-label-color: $orange;

.OffersTableWrapper {
  min-height: calc(100vh - $header-height - $footer-height - $workshop-header-height - 2px);
  .OffersTable {
    div[class~='ant-table-content'] {
      table {
        border-radius: setUnit(16);
        background-color: #fff;
      }
    }

    [class='ant-table'] {
      border-radius: 1rem;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
    }

    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
      font-size: setUnit(14);
      letter-spacing: -0.18px;
      height: 48px !important;
      padding: setUnit(13) setUnit(24) !important;

      &.ant-table-cell::before {
        display: none;
      }
      & [class*='ant-table-cell']::before {
        display: none !important;
      }
      &.ant-table-cell::before {
        display: none !important;
      }
    }

    th::before {
      display: none !important;
      width: 0;
    }

    th,
    td {
      line-height: setUnit(16);
      font-size: setUnit(16);

      cursor: pointer;

      &:last-child {
        border-right: unset !important;
        padding-left: 0px !important;
      }
    }

    td {
      font-weight: 400;
      letter-spacing: -0.33px;

      padding: setUnit(17.5) setUnit(24) !important;

      .avatarRow {
        display: flex;
        align-items: center;
        max-width: setUnit(152);
      }

      .avatarWrapper {
        display: inline-block;
        margin-right: 0.5rem;
        .avatar {
          width: 1.5rem;
          height: 1.5rem;
          .avatarPlaceholder {
            font-size: 10px;
          }
        }
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
      font-size: setUnit(16);
    }

    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
    }

    table {
      border: unset !important;
      font-size: setUnit(16);
    }

    .title {
      font-weight: 600;
    }

    .offerColumn {
      display: flex;
      align-items: center;
      .icon {
        height: 32px;
      }
      span {
        font-size: 1rem;
        font-weight: 600;
        margin-left: setUnit(10);
      }
    }

    .childColumn {
      margin-left: 1.5rem;
    }

    .offerTreeLine {
      height: 97px;
      width: 19px;
      left: 38px;
      bottom: 43px;
      border-bottom: 1px solid lightgray;
      border-left: 1px solid lightgray;
      border-bottom-left-radius: 6px;
      position: absolute;
    }

    .datePrimary {
      color: $error-color;
      font-weight: 500;
    }

    .category {
      p {
        display: inline-block;
        margin-top: 0.5rem;
      }

      .actions {
        float: right;
        margin-left: 1rem;

        .optionDots {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    & [class*='ant-pagination'] {
      display: none;
    }

    .transactionsData {
      .address {
        font-weight: 600;
        line-height: setUnit(20);
        letter-spacing: -0.33px;
        margin-bottom: 0;
      }
    }

    .client {
      display: flex;
      align-items: center;
      span {
        font-weight: 600;
        line-height: setUnit(20);
        letter-spacing: -0.33px;
        display: flex;
        margin-left: 12px;
      }
    }
    .type {
      float: left;
      padding: 0 0.5rem 0.25rem;
      border-radius: 16px;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .sell {
      background: rgba($sell-label-color, 0.1);

      span {
        color: $sell-label-color;
      }
    }

    .buy {
      background: rgba($buy-label-color, 0.1);

      span {
        color: $buy-label-color;
      }
    }

    .buyAndSell {
      background: rgba($buy-and-sell-label-color, 0.1);

      span {
        color: $buy-and-sell-label-color;
      }
    }

    .closeClosingDate {
      color: #ff0000;
    }

    .status {
      text-transform: capitalize;
    }

    .transactionContentWrapper {
      display: inline-flex !important;
      div {
        svg {
          margin-right: 12px;
        }
      }
    }

    .transactionContentFirstRow {
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(16);
      letter-spacing: -0.33px;
      text-align: left;
      color: $text-color !important;
    }

    .contentSecondRow {
      font-size: setUnit(16);
      line-height: setUnit(16);
      text-align: left;
      color: $text-gray-color;
      margin-top: 4px;
      display: block;
      letter-spacing: -0.33px;
    }
  }

  .sortedColumnTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sorterIcon {
      margin-left: setUnit(8);
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(20);
      height: setUnit(20);
      background-color: $background-grey-2;
      border-radius: setUnit(100);
      cursor: pointer;
      transform: rotate(0deg);
    }

    .sorterUp {
      transform: rotate(180deg);
    }
  }

  .actionSelectContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .actions {
      .optionDots {
        width: 2rem;
        height: 2rem;
      }
    }

    .multiSelect {
      .checkbox {
        [class*='ant-checkbox'] {
          [class*='ant-checkbox-inner'] {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border-color: $text--light-gray-color;
          }
        }

        [class*='ant-checkbox-checked'] {
          [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color;
            border-radius: 4px;
          }
          [class*='ant-checkbox-inner']::after {
            width: 7px;
            height: 12px;
            left: 25%;
            top: 45%;
          }

          &::after {
            border-color: $primary-color;
            border-radius: 4px;
          }
        }
      }

      &:hover,
      &:focus {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color;
        }
      }
    }
  }
}

.bold {
  font-weight: 600;
}

.submittedBy {
  width: setUnit(180) !important;
}

.received {
  width: setUnit(160) !important;
}

.expiredText,
.rejectedText {
  width: setUnit(186.4) !important;
}

.expiredText {
  color: $error-color;
  display: flex;
  gap: 5px;
  line-height: 18px;
}

.offerExpirationTimer {
  line-height: 20px;
}

.offerSmallText {
  margin-left: 3px;
}
