@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  min-height: calc(100vh - #{setUnit(192)});
  background: url('./search-bg-new.png') no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
}

.newSearch {
  font-weight: 600;
  font-size: setUnit(24);
  line-height: setUnit(29);
  color: $text-color;
  position: relative;
  margin-top: 60px;
  .newSearchTitle {
    text-align: center;
  }

  .newSearchInput {
    margin-top: setUnit(40);
    position: relative;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .locations {
      box-sizing: border-box;
      max-width: setUnit(502);
      width: calc(100% - 59px);
      font-weight: normal;
      font-size: setUnit(16);
      line-height: setUnit(21);
      border-radius: setUnit(10);
      margin-bottom: setUnit(15);
      @media (min-width: $mobile) {
        margin-bottom: 0;
      }
      .locationsValues {
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
        border: 1px solid #fff;
        height: setUnit(56);
        border-radius: setUnit(100);
        min-width: setUnit(400);
        & > div + div {
          height: 56px;
          border: 0;
          padding: 14px 16px 14px 44px !important;
        }
      }

      .locationsPopup {
        .locationsDropdown {
          margin-top: setUnit(8);
          border-radius: setUnit(8);
          border: none;
        }
        input {
          border-radius: setUnit(100);
          height: setUnit(56);
          padding: 14px 16px 14px 44px;
        }
      }
      .locationsIcon {
        position: absolute;
        top: 1rem;
        left: 1.25rem;
      }
    }

    .newSearchSubmit {
      z-index: 1;
      height: setUnit(54);
      width: setUnit(54);
      background: $primary-color;
      border: 0;
      outline: none;
      border-radius: 100%;
      cursor: pointer;
      transition: all 0.1s;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      margin-left: setUnit(5);
      margin-bottom: setUnit(15);
      @media (min-width: $mobile) {
        margin-bottom: 0;
      }
      &:hover {
        background: lighten($primary-color, 5%);
      }
      .newSearchSubmitIcon {
        height: setUnit(24);
        svg {
          width: setUnit(24);
          height: setUnit(24);
        }
      }
    }

    .disabledBtn {
      background: $white;
      &:hover {
        background: lighten($white, 5%);
        cursor: default;
      }
    }
  }

  .agentSearchInput {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .locations {
      flex: 0 0 100%;
      @media (min-width: 768px) {
        flex: 0 0 setUnit(400);
      }
    }

    .locationContainer {
      margin-right: 1.1rem;
    }

    .criteriaBtn {
      padding: 0.5rem 1.25rem 1.25rem;
      border-radius: 64px !important;
      background-color: $white !important;
      color: $dark-color !important;
      font-weight: 600 !important;
      height: auto;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      margin-left: setUnit(5);
      margin-bottom: setUnit(15);
      @media (min-width: $mobile) {
        margin-bottom: 0;
      }
      &:hover,
      &:focus {
        border-color: $primary-color;
      }
      .filterIcon {
        top: 0.5rem;
        margin-right: 0.25rem;
        position: relative;
        display: inline-block;
        background-color: transparent;
      }
    }

    .editBtn {
      border-color: $primary-color;
      background-color: $primary-color-light !important;
    }
  }
}
