@import 'styles/functions';
@import 'styles/variables';

.assignedTo {
  margin-bottom: 2rem;
  position: relative;

  .assignToWrapper {
    margin: 0 !important;
  }

  .assignToInputLabel > div > label {
    margin-bottom: 0.5rem;
  }

  .taskCC {
    position: absolute;
    top: -5px;
    right: -5px;
    button {
      padding: 0 4px;
      font-weight: 700;
      font-size: 14px;
      color: #515151;
    }
  }

  .label,
  .assignToInputLabel > div > label > span,
  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}

.milestoneOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;

  .date {
    color: $text--light-gray-color;
    font-size: 14px;
  }
}
