@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.offerLink {
  padding: 1.5rem 0 0;
  margin-right: setUnit(24);
  border-top: 1px solid $light-gray;

  .iconText {
    margin-bottom: 16px;
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    margin-bottom: 12px;

    padding: setUnit(24);
    background-color: $background-ligh-gray-5;

    .container {
      margin-bottom: 0.8rem;

      :is(.for, .from) {
        margin-left: 4px;
      }

      [class*='ant-checkbox'] {
        top: 0;
      }

      label[class='ant-checkbox-wrapper-checked'] {
        color: $text-color;
      }

      label[class='ant-checkbox-wrapper']:not(.ant-checkbox-wrapper-checked) {
        color: $dark-color;
      }

      .for {
        margin-right: 0.5rem;
      }

      .static {
        margin-left: 0.5rem;
      }

      .activeDays {
        input {
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          color: $text-color;
          &::placeholder {
            color: $text-gray-color;
          }
        }
      }

      [class*='ant-input-number'] {
        border: 0;
        border-radius: 0;
        width: 45px;
        text-align: center;
        input {
          border-bottom: 2px solid $border-input-middle-gray;
          &::placeholder {
            color: $text--light-gray-color;
          }
        }
        &[class*='ant-input-number-focused'] {
          input {
            border-bottom-color: $text-gray-color;
          }
        }

        [class*='ant-input-number-handler-wrap'] {
          display: none;
        }
      }
    }

    .container:last-child {
      margin-bottom: 0;
    }

    .rangePicker {
      border: 0;
      padding: 0 setUnit(8);
      width: 232px;
      @include customRangePicker;
      input {
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        color: $text-color;
        &::placeholder {
          color: $text--light-gray-color;
        }
      }
      [class*='ant-picker-input'] {
        padding: 0.5rem 0px;
      }
      [class='ant-picker-range-separator'] {
        font-size: setUnit(16);
      }

      [class*='ant-picker-cell-inner'] {
        font-weight: 500 !important;
      }

      [class*='ant-picker-cell-today'] {
        [class*='ant-picker-cell-inner'] {
          font-weight: 700 !important;
        }
      }
    }

    [class*='ant-picker-focused'],
    [class*='ant-input-number-focused'] {
      box-shadow: none;
    }

    [class*='ant-picker-suffix'],
    [class*='ant-picker-clear'],
    [class*='ant-picker-active-bar'] {
      display: none;
    }
  }
}
