@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  font-weight: 600;
  font-size: setUnit(14);
  line-height: setUnit(16);
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: setUnit(8);
}

.labelBlock {
  display: flex;
  align-items: center;
  .avatar {
    margin-right: setUnit(8);
  }
}
