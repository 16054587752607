@import 'styles/functions';
@import 'styles/variables';

.timeZoneArea {
  display: flex;
  position: relative;
  text-align: left;
  cursor: pointer;

  .accent {
    font-weight: 600;
    display: inline-block;
    margin-left: setUnit(4);
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .arrowIcon {
      margin-left: setUnit(4);
    }

    .static {
      font-weight: 600;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: $dark-color;
    }
  }
}

.listWrapper {
  position: absolute;
  top: 2rem;
  text-align: left;
  left: 8px;

  ul {
    list-style: none;
  }

  .item {
    padding: 0.2rem 0.5rem;

    &:hover {
      background: $background-ligh-gray;
    }
  }
}
