@import 'styles/functions';
@import 'styles/variables';

.marginTop {
  margin-top: 14px;
}

.tooltip {
  z-index: 9999 !important;
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 260px;

      p {
        color: white !important;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.cancelContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  .cancel {
    margin: 0;
    color: $error-color;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.33px;
  }
}

.adjustmentFactor {
  position: relative !important;
  .adjustmentFactorInput {
    padding-left: 80px !important;
  }
  .factorIcon {
    display: flex;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 56px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    .icon {
      cursor: pointer;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text--light-gray-color;
      font-size: 18px;
      font-weight: 700;
      &:first-child {
        border-right: 1px solid $light-gray;
      }
      &.active {
        background-color: $background-grey-2;
        color: $text-color;
      }
    }
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  .infoIcon {
    position: relative;
    top: 3px;
  }
}

.drawerInner {
  position: relative;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .line1 {
      color: $text-color;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.47px;
      margin: 0;
    }
    .line2 {
      color: $dark-gray-3;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.33px;
      margin: 0;
    }
    .content {
      width: 100%;
      padding: 13px 0px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      .checkboxes {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        width: 100%;
      }
      .datePicker {
        width: 100%;
        & [class*='flatpickr-calendar'] {
          width: fit-content;
          min-height: fit-content;

          [class~='flatpickr-monthSelect-months'] {
            position: static;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 14px;
            padding: 14px;

            [class~='flatpickr-monthSelect-month'][class~='selected'] {
              background-color: $secondary-color !important;
              color: $white !important;
              font-weight: 600 !important;
            }

            [class~='flatpickr-monthSelect-month'][class~='disabled'] {
              color: $grey-color !important;
              pointer-events: none !important;
            }

            [class~='flatpickr-monthSelect-month'] {
              transition: $transition;
              cursor: pointer;
              width: 80px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              &:hover {
                font-weight: 700;
              }
            }

            @media screen and (min-width: 425px) and (max-width: 767px) {
              grid-gap: 50px;
            }

            @media screen and (min-width: 375px) and (max-width: 424px) {
              grid-gap: 40px;
            }

            @media screen and (max-width: 374px) {
              grid-gap: 25px;
            }
          }
        }
      }
      .button {
        width: 180px;
        height: 52px;
        margin-bottom: 19px;
        margin-top: 42px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.opacityLayer {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 0.8) 0%,
    rgba(244, 245, 246, 0.4) 50%,
    rgba(244, 245, 246, 0.6) 75%,
    rgba(244, 245, 246, 0.9) 100%
  );
  backdrop-filter: blur(20px);
  background-color: transparent !important;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: 700px !important;
  max-height: 85vh !important;
  @media screen and (max-height: 1000px) {
    max-height: 95vh !important;
  }
}

.innerHolder {
  padding: 0 35px !important;
  overflow: hidden !important;
}
