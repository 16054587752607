@import 'styles/functions';
@import 'styles/variables';

.attachment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $background-ligh-gray;
  padding: setUnit(8) setUnit(22);
  border-radius: setUnit(8);
  margin-top: setUnit(8);
  height: auto;
  @media (min-width: $mobile-small) {
    height: setUnit(60);
    padding: 0 setUnit(22);
  }
  .info {
    flex: 1;

    .filename {
      font-weight: 600;
      font-size: setUnit(14);
    }

    .date {
      font-size: setUnit(12);
      margin-top: setUnit(4);
    }
  }

  .icon {
    margin-right: 0.875rem;

    svg {
      vertical-align: middle;
    }
  }

  &.submitted {
    padding: 0 setUnit(9);

    .icon {
      margin-right: setUnit(8);
    }
  }
}
