@import 'styles/functions';
@import 'styles/variables';

.templateHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: setUnit(40);
  .title {
    font-size: setUnit(24);
    font-weight: 600;
    line-height: setUnit(29);
    margin: 0;
  }
  .thRight {
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    .searchHeader {
      margin-right: setUnit(8);
    }
    .newTemplate {
      background-color: $secondary-color !important;
      border: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      padding: setUnit(12);
      max-width: setUnit(91);
      width: 100%;
      border-radius: setUnit(100) !important;
      height: auto;
      .iconAdd {
        display: flex;
        margin-right: setUnit(5);
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      margin-right: setUnit(8);
      label {
        margin-bottom: 0;

        span {
          margin-left: setUnit(4);
        }
      }
    }

    .agentTemplateBtn {
      max-width: fit-content;
    }
  }
}

.checkboxLabel {
  text-wrap: nowrap;
}
