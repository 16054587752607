@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: calc(100vh - #{setUnit(162)});
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
