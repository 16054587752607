.headerContanierInvite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  padding-bottom: 1.25rem;

  .inviteHeading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 0px;
    margin-left: 10px;
  }

  .headerInvite {
    flex: 1;
    display: flex;
    justify-content: center;
    padding-right: 32px;
  }

  .arrowLeft {
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 2px;
  }
}
