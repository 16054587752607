@import 'styles/variables';
@import 'styles/mixins';

.headerTitle {
  display: flex;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.permissionTitle {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 24px;
  display: block;
}

.hr {
  border-bottom: 1px solid $tertiary-grey;
  margin-top: 32px;
  margin-bottom: 24px;
  display: block;
}

.formModal {
  .form {
    display: block;
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  [class*='ant-col ant-form-item-label'] > label {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .formName,
  [class*='ant-input-number-group-addon'],
  [class*='ant-input-number-lg'],
  [class*='ant-select-selector'] {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
  }

  [class*='ant-input-number-group-addon'] {
    padding: 0 3px !important;
    background-color: $white !important;
  }

  .submitButton {
    margin-top: 30px;
    height: 56px;
    width: 277px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
  }
}

.loadingSpin {
  margin-left: 10px !important;
}

.errorText {
  @include inputError;
}
