@import 'styles/functions';
@import 'styles/variables';

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: setUnit(32);
}

.searchButton {
  width: setUnit(46);
  height: setUnit(46);
  margin-left: setUnit(32);
  background-color: $background-ligh-gray !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(24);

    path {
      stroke: $text-color;
    }
  }
}

.input {
  max-width: setUnit(200);
}

.tableWrapper {
  overflow-x: auto;
}

.tabWrapper {
  display: flex;
  align-items: center;

  .tab {
    position: relative;
    padding: 14px 32px;
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
  }

  .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: #ff576d;
  }
}
