@import 'styles/variables';
@import 'styles/functions';

.wrapper {
}

.title {
    font-size: setUnit(20);
    font-weight: 600;
    color: $text-color;
    margin-bottom: setUnit(4);
}

.description {
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-weight: normal;
    color: $text-color;
    margin-bottom: setUnit(40);
}

.inputBlock {
    display: flex;
    min-height: setUnit(44);
    align-items: center;
    margin-bottom: setUnit(10);

    .editLabel {
        flex: 1;
        font-size: setUnit(14);
        color: $dark-grey-color;
        align-self: flex-start;
        padding-top: setUnit(30);
        font-weight: normal !important;
    }

    .rightBlock {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding-top: setUnit(10);

        .input {
            margin: 0 0 setUnit(10) 0 !important;
        }
    }

    .infoBlockForOneLine {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding: setUnit(10) 0;
        border-bottom: 2px $light-gray solid;
    }

    .infoBlockForMultipleLines {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        border-bottom: 2px $light-gray solid;
    }

    a {
        color: $text-color;
        transition: all .1s;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.label {
    flex: 1;
    font-size: setUnit(14);
    color: $dark-grey-color;
    align-self: flex-start;
    padding-top: setUnit(14);
    padding-right: setUnit(8);
}

.inputsList {
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: flex-start;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
}

.saveBtn {
    height: setUnit(40) !important;
    width: setUnit(100) !important;
    transition: all .1s;
    border-radius: setUnit(100);
    display: flex;
    justify-content: center;
    align-items: center;
}
