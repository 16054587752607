@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.login {
  margin: auto 0 !important;
  max-width: setUnit(403) !important;
}

.forgotPasswordLink {
  text-decoration: none;
  font-size: setUnit(14);
  line-height: setUnit(24);
  color: $link-blue !important;
  font-weight: 500 !important;
  display: block;
  text-align: right;
  @media (min-width: 992px) {
    text-align: left;
  }
}

.authMessage {
  font-size: setUnit(14);
  padding-top: setUnit(24);
  color: red;
}

.contextMessage {
  font-size: setUnit(14);
  padding-top: setUnit(24);
  color: $link-blue;
}

.submit {
  width: 100%;
  height: setUnit(52) !important;
  margin-top: setUnit(40);
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(24);
  border: 2px solid $secondary-color !important;
  @include transition;
  &:hover {
    background-color: $white !important;
    color: $secondary-color !important;
  }
  @media (min-width: 768px) {
    max-width: setUnit(143);
  }
  &:hover {
    .loader {
      color: $secondary-color;
    }
  }
}

.title {
  font-weight: 500 !important;
  letter-spacing: -0.4px;
  color: $secondary-color !important;
  font-size: setUnit(26) !important;
  line-height: setUnit(32);
  @media (min-width: $mobile) {
    font-size: setUnit(36) !important;
    line-height: setUnit(40);
  }
}

.inputWrapper {
  margin-bottom: setUnit(48) !important;

  &:last-child {
    margin-bottom: setUnit(12) !important;
  }
}
