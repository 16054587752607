@import 'styles/variables';

.bottonContainer {
  display: flex;
  margin-top: 2.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .button {
    width: 100px;
    height: 45px;
    font-size: 16px !important;
    font-weight: 600 !important;
    &.cancel {
      background-color: $white;
      color: $text-color;
      width: fit-content !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
