@import 'styles/variables';
@import 'styles/functions';

.header {
  padding: 40px;
  display: flex;

  // position: absolute;
  // top: 0;
  // z-index: 2;

  @media screen and (max-width: $mobile) {
    padding: 40px 25px;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
  }
}
