@import 'styles/variables';

.optionIcon {
  display: flex;
  align-items: center;
}

.downloadOption {
  display: flex;
  align-items: center;
  width: 100%;
  color: $text-color;

  .downloadText {
    margin-left: 5%;
  }

  &:hover {
    color: $text-color;
  }
}

.entityOptions > p {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.09px;
  color: $text-color;
  display: flex;
  padding: 8% 5% 8% 10%;
  cursor: pointer;
  margin: 0;
  &:hover {
    background-color: $background-grey-2;
  }
}

.entityOptions > p > span:nth-child(2) {
  margin-left: 5%;
}

.buttonDot {
  background: none !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $background-ligh-gray-4 !important;
    width: 30px;
    height: 30px;
    border-radius: 100%;

    svg {
      circle {
        fill: $text-color;
      }
    }
  }
}

.openedButtonDot {
  background-color: $background-ligh-gray-4 !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  svg {
    circle {
      fill: $text-color;
    }
  }
}

.antdCustomPopover {
  [class*='ant-popover-inner-content'] {
    width: 180px !important;
    border-radius: 8px !important;
    padding: 0 !important;
  }

  [class*='ant-popover-inner'] {
    border-radius: 8px;
  }
}
