@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  .contentWrapper {
    width: 100%;
    overflow-x: hidden;
  }

  &.horizontal {
    .tabs {
      display: flex;
    }
  }

  &.vertical {
    display: flex;

    .tabs {
      border-right: 1px solid $grey-color;
    }
  }
}

.iconCheckmark {
  svg {
    width: 13px;
  }
}
