@import 'styles/functions';
@import 'styles/variables';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: setUnit(35);

  .heading {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
    letter-spacing: -0.4px;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    text-align: start;

    .inputWrapper {
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > [class*='StripeElement--focus'] {
        border-color: #000 !important;
      }

      .cardElement {
        background: transparent;
        padding: 16px 14px;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
        border: 1px solid var(--Grayscale-20, #e8e9ea);
        border-radius: 4px;
        &:visited {
          border-color: #000 !important;
        }
        &:focus {
          border-color: #000 !important;
        }
        &:placeholder {
          color: var(--Grayscale-40, #aaabab);
        }
        [class='StripeElement--focus'] {
          border-color: #000 !important;
        }
      }

      .formLabel {
        color: var(--Grayscale-50, #747475);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }
    }
  }

  .payBtn {
    width: 136px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Primary-Navy, #252d44);
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .loader {
      color: antiquewhite;
      fill: antiquewhite;

      .spinner {
        font-size: 20px;
        color: antiquewhite;
      }
    }
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    gap: 17px;
    .inputWrapper {
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > [class*='StripeElement--focus'] {
        border-color: #000 !important;
      }

      .cardElement {
        background: transparent;
        padding: 16px 14px;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
        border: 1px solid var(--Grayscale-20, #e8e9ea);
        border-radius: 4px;
        &:visited {
          border-color: #000 !important;
        }
        &:focus {
          border-color: #000 !important;
        }
        &:placeholder {
          color: var(--Grayscale-40, #aaabab);
        }
        [class='StripeElement--focus'] {
          border-color: #000 !important;
        }
      }

      .formLabel {
        color: var(--Grayscale-50, #747475);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }
    }
  }
  .divider {
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .stripeSecure {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
      opacity: 0.5;
      color: var(--Grayscale-50, #747475);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 164.286% */
      letter-spacing: -0.1px;

      .stripeIcon {
        margin-top: 10px;
      }
    }
  }
}

.price {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}
