@import 'styles/functions';
@import 'styles/variables';

.header {
  margin-bottom: 1rem;
  width: 100%;

  .heading {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .documentIcon {
      background: $background-grey-2;
      border-radius: 1.5rem;
      display: inline-block;
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 1.375rem;

      svg {
        height: 2.25rem;
        margin-top: 0.2rem;
        margin-left: 0.4rem;
      }
    }

    .headingLeft {
      p {
        span {
          color: #515151;
        }
      }
    }

    .offer {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: $text-color;
      margin-right: 0;
      display: flex;
    }
  }

  .tooltip {
    margin-left: 0.5rem;
    position: relative;
    transform: rotate(180deg);
    bottom: 0.25rem;
  }

  .actions {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .commentIcon {
      svg {
        height: 32px;
        width: 32px;
        margin: 4px 0 0 10px;
      }
    }

    .status {
      padding: 0.5rem 0.875rem;
      background: $background-grey-2;
      border-radius: 6.25rem;
      margin-right: 0.5rem;
      display: inline-block;
      height: 2.5rem;

      span {
        color: $dark-color;
        font-weight: 500;
      }
    }

    .pending {
      background: $primary-color;

      span {
        color: #fff;
      }
    }

    .upcoming {
      background: rgba(0, 177, 82, 0.1);

      span {
        color: $green-color;
      }
    }

    .megaPhoneIcon {
      cursor: pointer;
      background: $background-grey-2;
      border-radius: 6.25rem;
      width: 2.5rem;
      height: 2.5rem;
      display: inline-block;
      position: relative;

      svg {
        position: relative;
        top: 50%;
        left: 48%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}

.tooltipOverlay {
  & [class~='ant-tooltip-arrow-content'] {
    background-color: $text-color;
    --antd-arrow-background-color: $text-color;
  }

  & [class~='ant-tooltip-inner'] {
    border-radius: 16px;
    background-color: $text-color;
    padding: 12px 16px 16px;
    min-width: 203px;

    .tooltipInnerContainer {
      div {
        line-height: 20px;
        letter-spacing: -0.33px;
        font-size: setUnit(16);
      }

      .tooltipAddress {
        font-weight: 400;
        font-size: setUnit(14);
      }
    }
  }
}
