@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.noReminders {
  display: flex;
  align-items: center;
  height: 100%;
  color: $text-gray-color;
  width: 100%;
  padding: 0 25px;
  font-size: 12px;
}

.remindersCard {
  max-width: 356px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--grayscale-10-background, #f4f5f6);
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding-bottom: 24px;

  &.large {
    max-width: 600px;
  }

  .header {
    padding: 24px 24px 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-style: normal;

    .heading {
      color: var(--Grayscale-100, #262626);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.33px;
    }

    .viewAll {
      color: var(--REBRAND-Secondary-Link-Blue, #2b59da);
      font-size: 14px;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.18px;
      cursor: pointer;
    }
  }

  .reminderItem {
    display: flex;
    padding: 12px 20px 12px 24px;
    gap: 12px;
    align-items: center;

    .days {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      border-radius: 50%;
      background: rgba(4, 164, 81, 0.1);

      color: #04a451;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 122.222% */
      letter-spacing: -0.18px;
      text-transform: uppercase;

      &.overdue {
        background: rgba(236, 69, 94, 0.1);
        color: #ec455e;
      }
    }

    .description {
      color: var(--REBRAND-Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.18px;
      p {
        margin-bottom: 0;
      }

      width: 100%;
      flex-grow: 1;

      &.large {
        font-size: 16px;
      }

      span {
        font-weight: 600;
      }

      .date {
        margin-top: 4px;
        color: var(--Grayscale-80, #515151);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
    }
  }
}
