@import 'styles/functions';
@import 'styles/variables';

.focusedProperty {
  border: 2px solid $primary-color;
  border-radius: setUnit(8);
}

.normalBorder {
  border: 1px solid $tertiary-50-hover;
  border-radius: setUnit(8);
}

.notAllowed {
  cursor: not-allowed !important;
}

.propertyWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: setUnit(12);

  .status {
    position: absolute;
    top: setUnit(16);
    left: setUnit(8);
    max-width: calc(100% - #{setUnit(16)});
  }

  .photos {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: setUnit(202);
    @media (min-width: $mobile) {
      max-width: setUnit(210);
    }
    .photosHolder {
      height: 100%;
    }
  }

  .inner {
    display: flex;
    padding: setUnit(24);
    width: 100%;
    @media (min-width: $mobile) {
      width: 100%;
      max-width: calc(100% - #{setUnit(210)});
    }
    .info {
      width: calc(100% - 5.5rem);
      padding-right: 0.25rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .statusHistory {
        font-size: 14px;

        .oldStatus {
          text-decoration: line-through;
          color: grey;
        }
        .arrowRightIcon {
          margin: 0 5px;
          color: grey;
          width: 15px;
          height: 26px;
          line-height: 24px;
          display: inline-block;
        }
        .newStatus {
          font-weight: 600;
        }
      }

      .price {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.33px;
        display: flex;
        align-items: center;

        .oldPrice {
          text-decoration: line-through;
          font-size: 16px;
          font-weight: 600;
          color: $text-gray-color;
        }
        .arrowRightIcon {
          margin: 0 5px;
          width: 15px;
          height: 26px;
          line-height: 24px;
          svg {
            path {
              stroke: $text-gray-color;
            }
          }
        }

        .auction {
          font-size: 14px;
          font-weight: 400;
          margin-left: 0.3125rem;
        }
      }

      .location {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        color: $text-gray-color;
      }

      .matchDate {
        margin-top: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }

    .metaInfo {
      width: 5.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .comFavMetaInfo {
        display: flex;
        align-items: center;
      }

      .agentFavorite {
        background: none;
        border: 1px solid $border-light-gray-2;
        margin-top: 0.5rem;

        &:hover {
          border-color: $text--light-gray-color;
        }
      }
    }

    .feature {
      margin: setUnit(4) 0;
    }

    .featureWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 8px 0 12px;
      li {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &:not(:last-child) {
          &:after {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            margin: 0 6px;
            border-radius: 50%;
            background: $text-color;
          }
        }
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        .amount {
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }

    .line1 {
      min-height: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      margin-bottom: 4px;
    }
  }
}

.matchContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  .checkboxContainer {
    padding-top: 10px;
    .checkbox {
      border-color: $text-color;
      border-width: 2px;
    }
  }

  .pointerEventsNone {
    pointer-events: none;
  }
}

.tooltip {
  max-width: 20px;
  position: relative;
  top: 2px;
  left: 4px;
}
