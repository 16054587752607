@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 468px;
  height: 100%;
  background: $white;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
}

.btn {
  max-width: 292px;
  width: 100%;
  height: 52px;
  cursor: pointer;
  background: #252d44;
  margin-top: 42px;
  border-radius: 8px;
  border: 0;
  .btnTxt {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: #ffffff;
  }

  @media (max-width: $mobile-small) {
    width: 85%;
    position: absolute;
    bottom: 40px;
    max-width: unset;
  }
}

@media (max-width: 767px) {
  .wrapper {
    min-height: calc(100vh - 270px);
  }
}
