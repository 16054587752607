@import 'styles/functions';
@import 'styles/variables';

.container {
  padding: setUnit(12) setUnit(8);
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }
}

.images {
  margin-right: setUnit(16);
  height: setUnit(48);
  width: setUnit(48);
  border-radius: setUnit(8);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: $hover-light-gray;
  position: relative;

  .noImages {
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $dark-grey-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    &:nth-of-type(1) {
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }
    &:nth-of-type(2) {
      border-bottom: 1px solid transparent;
    }
    &:nth-of-type(3) {
      border-right: 1px solid transparent;
    }

    height: setUnit(24);
    width: setUnit(24);
  }
}

.descTitle {
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(21);
  padding-bottom: setUnit(8);
  color: $text-color;
}

.descInfo {
  font-size: setUnit(14);
  line-height: setUnit(16);
  color: $dark-grey-color;
}

.actions {
  margin-left: auto;
}
