@import 'styles/variables';

.hidden {
  display: none !important;
}

.uploadContainer {
  height: calc(100vh - $header-height - $footer-height);
  margin: 0px 40px;
  padding: 40px 0px;

  .uploadText {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: $dark-color;
    margin-bottom: 8px;
  }

  .uploadHint {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $text-gray-color;
  }
}
