@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.listingAgent {
  margin-right: setUnit(24);

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    padding: setUnit(24);
    margin-bottom: 12px;

    background-color: $background-ligh-gray-5;

    .loadingSpinner {
      font-size: 0.5em;
      margin: 50px auto;
    }

    .agentQuestionContainer {
      .agentQuestion {
        .question {
          font-weight: 500;
          font-size: setUnit(14);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);
          color: $dark-color;
          margin-bottom: 0;
        }

        .questionSelector {
          .search {
            .value {
              &:after {
                border: none;
              }
            }
          }

          .searchIcon {
            position: absolute;
            top: setUnit(13);
          }

          .option {
            height: setUnit(52);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border: none;
            cursor: pointer;
            background-color: transparent;
            text-align: left;
            font-size: setUnit(16);
            line-height: setUnit(20);

            padding: setUnit(10) setUnit(15);

            .optionInnerHolder {
              display: flex;
              align-items: center;
            }

            .avatar {
              width: setUnit(32);
              height: setUnit(32);
              margin-right: setUnit(15);

              .avatarPlaceholder {
                font-size: setUnit(14);
              }
            }

            &:hover {
              background-color: $grey-color;
            }

            &.is-highlighted {
              background-color: $grey-color;
            }

            &.is-selected {
              background-color: $grey-color;
            }
          }

          .inputHolder {
            width: 100%;

            [class~='ant-select-selector'] {
              border: unset;
              border-bottom: 2px solid $border-light-gray-2 !important;
              box-shadow: none !important;
              font-size: setUnit(16) !important;
              height: setUnit(48) !important;
              background-color: transparent;
              border-radius: 0px;

              &:focus {
                border-bottom-color: $text--light-gray-color !important;
              }

              [class~='ant-select-selection-placeholder'] {
                color: $light-gray-2;
              }

              &::after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM0NzE5XzQ0MTM2KSI+CjxwYXRoIGQ9Ik02IDlMMTIgMTVMMTggOSIgc3Ryb2tlPSIjNzQ3NDc1IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzM0NzE5XzQ0MTM2Ij4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
                background-size: 25px;
                background-repeat: no-repeat;

                width: 24px;
                transition: all 0.2s;
                position: relative;
                right: 9px;
              }
            }

            [class~='ant-select-open'] {
              [class~='ant-select-selector'] {
                border-color: $text--light-gray-color !important;
              }
            }

            .inputHolderDropdown {
              left: 0px !important;
              [class~='ant-select-item-option'] {
                padding: 16px 24px !important;
                height: setUnit(48);
                display: flex;
                align-items: center;
                flex-flow: row;

                [class~='ant-select-item-option-content'] {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: -0.09px;
                  color: $dark-color;
                  padding: 0;
                  justify-content: space-between;

                  &::before {
                    display: none;
                  }

                  &::after {
                    content: '';
                    border: 1.5px solid #aaabab;
                    border-radius: 4px;
                    width: 24px;
                    height: 24px;
                  }
                }

                &[class~='ant-select-item-option-selected'] {
                  background-color: $background-grey-2 !important;

                  [class~='ant-select-item-option-content'] {
                    &::after {
                      display: none;
                    }
                  }

                  [class~='ant-select-item-option-state'] {
                    margin-right: 0px;
                  }
                }
              }
            }
          }

          [class~='ant-select-open'] {
            [class~='ant-select-selector'] {
              &::after {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }
  }
}
