@import 'styles/functions';
@import 'styles/variables';

.disable {
  opacity: 0.3;
  pointer-events: none;
}

.summaryPage {
  .head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 34px 0 54px;
    .closeIcon {
      border-radius: 50%;
      border: 2px solid $light-gray;
      background: none;
      height: 44px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        border: 2px solid $text--light-gray-color;

        svg {
          opacity: 1;

          path {
            fill: $text-color;
          }
        }
      }
    }
  }
  .buttonsContainer {
    .button {
      height: 52px;
      width: 100%;
      transition: $transition;
      .buttonTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.09px;
        transition: $transition;
      }
      &.startOver {
        background-color: transparent;
        border: 2px solid transparent;
        .buttonTitle {
          color: $dark-color;
        }
        &:hover {
          border-color: $text-gray-color;
          .buttonTitle {
            color: $text-color;
          }
        }
      }
    }
  }
}
