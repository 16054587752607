@import 'styles/mixins';
@import 'styles/variables';

.draftFormModal {
  .emptyDraft {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
  }

  [class*='ant-typography'] {
    text-align: center;
  }
  [class*='ant-modal-content'] {
    padding-left: 0 !important;
    padding-right: 0 !important;

    [class*='ant-modal-close'] {
      top: 28px !important;

      [class*='ant-modal-close-x'] {
        top: 0px !important;
      }
    }
  }
  .formsRow {
    padding: 18px 40px !important;
  }
  .draftFormBody {
    height: 500px;
    overflow-y: auto;
  }
  .NoSpacing {
    margin: 0;
  }
  [class*='ant-row'] {
    &:hover {
      .IconStyles > rect {
        fill: $background-grey-2 !important;
      }
      background: $background-grey-2;
      cursor: pointer;
    }
  }
  [class*='ant-modal-title'] {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .draftFormInfo p {
    font-family: $font-family;
    font-style: normal;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 1%;
  }
  .draftFormInfo > p:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .draftFormInfo > p:not(:first-child) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  @media only screen and (max-width: $mobile) {
    .formsRow {
      padding: 18px 20px !important;
    }
  }

  .noForms {
    text-align: center;
  }
}
