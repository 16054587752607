@import "styles/functions";
@import "styles/variables";

.label {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $text-color;
    margin: 0 setUnit(32) 0 setUnit(32);
    padding-bottom: setUnit(16);
    border-bottom: 1px solid $border-light-gray;
}