@import 'styles/functions';
@import 'styles/variables';

.field {
  margin-top: setUnit(32);

  &:first-child {
    margin-top: 0;
  }

  .label {
    font-size: setUnit(16);
    font-weight: 600;
    color: $text-color;
    margin-bottom: setUnit(10);
  }

  .input {
    width: 100%;
    min-height: setUnit(43);
  }
}
