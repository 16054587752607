@import 'styles/functions';
@import 'styles/variables';

.loading {
  color: $dark-grey-color;
  margin-left: 8px;
}

.itemContainer {
  @media (min-width: $mobile-small) {
    height: setUnit(48);
  }
  display: flex;
  justify-content: space-between;
  padding: 0 setUnit(32);
  border-bottom: 1px solid $light-gray;

  .connectionNameContainer {
    display: flex;
    align-items: center;
    // overflow: hidden;
    .connectionName {
      display: inline-block;
      width: fit-content;
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.18px;
      margin-bottom: 0;
      max-width: 160px;
      min-width: 160px;
      width: 100%;
      white-space: normal;
      overflow: auto;
    }
    .connectionName::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Webkit browsers (Chrome, Safari) */
    }

    .tagContainer {
      display: flex;
      align-items: center;
      .tag {
        display: inline-block;
        padding: 0.125rem 0.5rem;
        background: rgba(116, 116, 117, 0.08);
        border-radius: 40px;
        color: $dark-color;
        font-weight: 600;
        font-size: 12px;
        margin-left: 0.5rem;
      }
    }
  }

  .additionalInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .roleSeparator {
      width: setUnit(4);
      height: setUnit(4);
      background-color: $border-middle-gray;
      border-radius: 50%;
      margin-right: setUnit(8);
      margin-left: setUnit(8);
    }
    .additionalInfoText {
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.18px;
      color: $border-middle-gray;
      margin-bottom: 0;
      text-align: right;
    }
  }

  &:hover {
    background-color: $background-grey-2;
    cursor: pointer;
  }
}

.pendingAdditionalInfoContainer {
  .button,
  .sentIconContainer {
    width: 75px;
    margin-left: 23px;
  }

  .button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    color: $secondary-color;
    cursor: pointer;
    transition: all 0.1s;
  }

  .text {
    margin-left: 8px;
    font-size: 16px;
    text-align: center;
  }

  .sentIconContainer {
    display: flex;
    color: $hover-green;

    .text {
      line-height: 22px;
    }
  }
}
