@import 'styles/variables';

$question-header-height: 68px;

.rightSideBarContainer {
  background: $white !important;
  width: 268px !important;
  max-width: unset !important;
  min-width: unset !important;
  flex: 0 0 268px !important;

  padding: 24px !important;

  [class*='ant-space-vertical'] {
    height: 100%;
    justify-content: space-between;
  }

  .requiredRow {
    margin-top: 12px;
  }

  .questionContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    .questionIcon {
      display: flex;
      margin-right: 10px;
    }

    .questionText {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;

      color: $error-color;
    }
  }

  .requiredCheck {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09000000357627869px;
  }

  .groupLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: $text-color;
    display: block;
    margin-bottom: 12px;
  }

  .selectRole {
    width: 220px;
    height: 46px;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .groupSection {
    margin-bottom: 24px;
  }

  .item {
    cursor: grab;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $text-color;
    padding: 12px 0px;

    &:hover {
      background-color: $tertiary-50;
    }

    .iconItem {
      margin-right: 12px;
    }
  }
}
