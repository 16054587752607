@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.content {
  .checkbox {
    display: inline-block;
    margin-top: 2rem;
  }

  .checkboxLabel {
    font-size: setUnit(16);
    color: $text-color;
    font-weight: 500;
    margin-left: setUnit(12);
  }
  .inviteAutoComplete {
    [class~='ant-select'] {
      display: flex !important;
      align-items: center !important;
      @include lightRounded;
      padding: 0rem !important;

      [class*='ant-select-selector'] {
        [class*='ant-select-selection-search'] {
          left: 0.75rem !important;
        }
        [class*='ant-select-selection-placeholder'] {
          font-size: 16px;
          padding: 0 0.75rem !important;
        }
      }
    }
  }
}

.nameError {
  font-size: 0.875rem;
  color: $error-color;
  margin-top: 0.1875rem;
}
