@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.footer {
  display: flex;
  justify-content: flex-end;
  padding: setUnit(20);

  .submitButton {
    border-radius: 8px;
    width: 81px;
    height: 40px;
    flex-shrink: 0;
    border: none;
    span {
      color: $white;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
    }
  }
}
