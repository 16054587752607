@import 'styles/functions';
@import 'styles/variables';

.container {
  .question {
    margin: setUnit(24) 0 setUnit(64);
  }
  .contentWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    .content {
      flex: 1 100%;
      max-width: setUnit(612);
      width: 100%;
    }
  }
}

.modalContent {
  margin-top: setUnit(40);
  max-height: calc(100vh - #{setUnit(210)});
  overflow: auto;
}
