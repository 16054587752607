@import 'styles/functions';
@import 'styles/variables';

.cancellationDetailsContainer {
  margin-top: setUnit(24);
  padding: setUnit(16) 0;
  margin-bottom: setUnit(16);
  background: #fb913a0f;
  border-radius: setUnit(8);

  .heading {
    font-size: setUnit(20);
    line-height: setUnit(26);
    padding: 0 setUnit(16);
    font-weight: 600;
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 setUnit(16) setUnit(20);
  }

  .subHeading {
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-weight: 400;
  }

  .details {
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-weight: 600;
  }
}
