@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: setUnit(15);
  margin-bottom: setUnit(15);

  .label {
    font-weight: 400;
    font-size: setUnit(14);
    color: $dark-grey-color;
  }

  &.right {
    flex-direction: row-reverse;
  }

  label {
    @include label;

    margin-bottom: 0;
    display: inline-flex;
  }

  .disabled {
    cursor: not-allowed;
  }
}

.inputDom {
  position: absolute;
  left: -10000px;
  display: none;

  & + .toggleWrapper {
    position: relative;
    width: setUnit(44);
    height: setUnit(24);
    background: $border-middle-gray;
    border-radius: setUnit(12);
    transition: all 0.15s;

    .toggle {
      width: setUnit(20);
      height: setUnit(20);
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: setUnit(2);
      right: calc(100% - #{setUnit(22)});
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.15s;

      .loader {
        font-size: setUnit(2);
        margin: 0;
        color: $border-middle-gray;

        &.isChecked {
          color: $primary-color;
        }
      }
    }
  }

  &:checked {
    & + .toggleWrapper {
      background: $primary-color;

      .toggle {
        right: setUnit(2);
        left: auto;
      }
    }
  }
}
