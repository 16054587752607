@import 'styles/functions';
@import 'styles/variables';

.photoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: setUnit(47);
  border-radius: 50%;

  .photo {
    position: relative;
    .add {
      position: absolute;
      right: 0;
      bottom: 0;
      //display: none;
      //pointer-events: none;
      justify-content: center;
      align-items: center;
      background: $white;
      border: 2px solid $white;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      svg {
        width: setUnit(40);
        height: setUnit(40);

        path {
          fill: $secondary-color;
        }
      }

      // &.display {
      //   display: flex;
      // }
    }

    .avatar {
      width: 136px;
      height: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      &:hover ~ .add {
        display: flex !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .nativeInput {
      position: absolute;
      left: -99999;
      display: none;
    }
  }

  &.full {
    .avatar {
      background: $background-ligh-gray;
    }
  }

  &.light {
    .avatar {
      background: transparent;
      border: 2px solid $light-gray;

      .innerAvatar {
        background: none !important;
        height: 64px;
        width: 64px;

        &.hasValue {
          width: 100%;
          height: 100%;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease all;
          }
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      svg {
        fill: white;
      }
    }
  }
}
