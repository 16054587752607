@import 'styles/variables';

.autoCompleteWrapper {
  .autoCompleteBase {
    width: 100%;
    border-bottom: 2px solid $border-light-gray-2;
    transition: $transition;
    [class*='ant-select-selector'] {
      padding: 0 !important;

      [class*='ant-select-selection-search'] {
        left: 0 !important;

        [class*='ant-select-selection-search-input'] {
          text-overflow: ellipsis;
        }
      }

      [class*='ant-select-selection-placeholder'] {
        font-size: 18px;
        color: $text-gray-color;
      }
    }
  }

  [class*='ant-select-focused'] {
    border-bottom: 2px solid $border-focused !important;
  }
}
