@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: calc(100% - #{setUnit(72)});
  overflow: auto;

  .title {
    display: flex;
    align-items: center;
    color: $text-color;
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(26);
    padding: 0 setUnit(32) setUnit(25);
    height: setUnit(56);
    margin-top: setUnit(16);
  }

  .item {
    height: setUnit(80);
    transition: all 0.1s;
    display: flex;
    padding: setUnit(12) setUnit(32);
    cursor: pointer;
    color: $text-color;
    position: relative;

    &:hover {
      background-color: $hover-light-gray;
    }

    .itemContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .itemContentTitle {
        font-size: setUnit(16);
        line-height: setUnit(21);
        display: flex;

        .itemContentTitleName {
          font-weight: 600;
        }
      }

      .itemContentText {
        padding-top: setUnit(5);
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $dark-grey-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .itemContentTextUnread {
          font-weight: 600 !important;
        }

        span {
          font-weight: normal !important;
        }
      }
    }

    .itemImage {
      width: setUnit(56);
      height: setUnit(56);
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      margin-right: setUnit(16);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: setUnit(32);
        height: setUnit(32);
      }

      .itemImageAvatar {
        width: setUnit(56);
        height: setUnit(56);

        img {
          width: setUnit(56);
          height: setUnit(56);
        }
      }
    }

    .itemRight {
      margin-left: auto;

      .itemRightIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        svg {
          width: setUnit(16);
          height: setUnit(16);
        }
      }
    }
  }
}
