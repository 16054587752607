@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.reviewersBox {
  margin-bottom: 20px;
}

.box {
  padding: 20px;
  background: #fff;
  border-radius: 16px;
}

.label {
  font-size: setUnit(16);
  font-weight: 600;
}

div {
  .permissionClientSelectWrapper {
    margin: 0px 0px 4px !important;

    .permissionClientMultiSelect {
      border-radius: 12px !important;
      [class~='ant-select-selector'] {
        border-radius: 12px !important;
      }
    }
  }
}

.buttonContainer {
  .button {
    border-radius: 12px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    height: setUnit(52);
    width: setUnit(180);
    margin: 36px auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonEnabled {
    background-color: $secondary-color !important;
    opacity: 1;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }

    &:focus {
      border-color: $secondary-color;
    }
  }

  .buttonDisabled {
    background-color: $secondary-color !important;
    opacity: 0.5;

    &:hover {
      background-color: $secondary-color !important;
    }
  }
}
