@import 'styles/functions';
@import 'styles/variables';

.inviteTeamMemberModal {
  & [class*='ant-modal-content'] {
    padding: 2.5rem 2.5rem 2rem;
  }

  .heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 2rem;
    text-align: center;
    letter-spacing: -0.47px;
    color: $text-color;
    position: relative;
    top: -1rem;
  }

  .inputRow {
    margin-bottom: 32px;
  }

  .formContainer {
    .title {
      font-weight: 600;
      font-size: 20px;
      color: $text-color;
      margin-bottom: setUnit(16);
    }

    .subtitle {
      font-weight: 600;
      font-size: 16px;
      color: $text-color;
    }

    .inputContainer {
      input {
        border: 0px;
        border-bottom: 2px solid $border-light-gray-3;
        border-radius: 0;
        padding-left: 0;
      }

      label {
        margin: 0 setUnit(45) 0 0;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $text-color;
        }
      }
    }

    .inputContainerEmail {
      @extend .inputContainer;
      input {
        width: 100%;
      }
    }

    .memberRole {
      width: 100%;
      display: block;
      .subHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $text-color;
      }

      .selectType {
        margin: 16px -12px 0;
        display: flex;
        flex-wrap: wrap;
      }
      .selectOption {
        width: 100%;
        height: setUnit(69);
        color: $dark-color;
        border: 2px solid $light-gray;
        border-radius: 8px;
        padding: 20px 0 20px 20px;
        margin: 0 12px;
        @media (min-width: $mobile) {
          width: calc((100% / 2) - 24px);
        }
        &:hover {
          cursor: pointer;
          color: $text-color;
          border: 2px solid $border-light-gray-2;
        }

        &:not(:last-of-type) {
          margin-bottom: 16px;
          @media (min-width: $mobile) {
            margin-bottom: 0;
          }
        }

        label {
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .selectChecked {
        background-color: $primary-color-light;
        border: 1px solid $primary-color !important;
        color: $text-color;
        padding: 21px 1px 21px 21px;
      }
    }
  }

  .footer {
    text-align: center;
    .button {
      background-color: $background-blue;
      padding: setUnit(5) setUnit(40);
      min-width: setUnit(210);
      height: setUnit(52);
      font-size: 16px;
      font-weight: 600;
      opacity: 0.35;
      position: relative;
    }
    .active {
      opacity: 1;
    }
  }
}
