@import 'styles/functions';
@import 'styles/variables';

.tasks {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 40px 32px 0 32px;
  }

  .taskList {
    padding: 0 32px;
  }

  .multiSelectTaskList {
    height: calc(100vh - $header-height - 108px - 80px);
    overflow: auto;
    padding: 0 32px 32px;
  }

  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  .taskHeaderAndContent {
    padding: setUnit(32) setUnit(40) 0 setUnit(32);
    .taskTable {
      padding: 0;
    }
  }
}

.includeFooter {
  height: calc(100vh - $header-height - $footer-height);
}
