@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $background-ligh-gray-4;

  padding: setUnit(20) 0 setUnit(24);
  margin: 0px setUnit(24);

  .loadingSpinner {
    font-size: 0.4em;
    margin: 20px auto;
  }

  .flexButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .resetText {
      font-size: setUnit(16);
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: $dark-color;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .nextBtn {
    height: 52px;
    min-width: 160px;
    background-color: $secondary-color;
    margin-left: auto;
    border-radius: 8px;
    border: 0;
    &:hover {
      background: $secondary-color-hover;
    }

    @media (max-width: $mobile-small) {
      min-width: 132px;
    }
  }

  :is(.nextBtnDisabled, .submitBtnDisabled) {
    background-color: $secondary-color !important;
    opacity: 0.8;
  }

  .submitBtn {
    border-radius: 8px;
    border: 0;
    transition: $transition;
    cursor: pointer;
  }
}
