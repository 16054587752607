@import 'styles/functions';
@import 'styles/variables';

.pendingWrapper {
  .wrapper {
    height: calc(100vh - #{setUnit(223)});
  }
}

.pseudo {
  position: relative;

  .pseudoPlaceholder {
    position: absolute;
    top: setUnit(43);
    right: setUnit(16);
    font-weight: 400;
    color: $border-middle-gray;
  }

  .hoa {
    input {
      padding-right: setUnit(100);
    }
  }
}

.buttonGroup {
  margin: 0 !important;
}

.field input,
.locationsValues,
.parkingSpaces > div {
  height: setUnit(48);
}

.field {
  &:not(:last-child) {
    margin-bottom: setUnit(32);
  }
  .statusField {
    margin-top: 0;
  }
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.propertyWrapper {
  margin: 0;
  label {
    margin-bottom: setUnit(12) !important;
  }
  .filterPropertyWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filterItem {
      flex: 0 0 50%;
      &:first-child {
        border-top-left-radius: setUnit(8);
      }
      &:last-child {
        border-bottom-right-radius: setUnit(8);
      }
      &:nth-child(2) {
        border-top-right-radius: setUnit(8);
      }
      &:nth-child(9) {
        border-bottom-right-radius: setUnit(8);
        border-bottom-left-radius: setUnit(8);
        flex: 0 0 100%;
      }
      @media (min-width: $mobile) {
        flex: 0 0 33.3333%;
        &:nth-child(2) {
          border-top-right-radius: setUnit(0);
        }
        &:nth-child(3) {
          border-top-right-radius: setUnit(8);
        }
        &:nth-child(4) {
          border-bottom-left-radius: setUnit(0);
        }
        &:nth-child(7) {
          border-bottom-left-radius: setUnit(8);
        }
        &:nth-child(9) {
          border-bottom-left-radius: setUnit(0);
          flex: 0 0 33.333%;
        }
      }
      & > div {
        min-height: setUnit(80);
      }
    }
  }
}

.footer {
  @media (max-width: 991.99px) {
    position: fixed;
    bottom: 0px;
    max-width: setUnit(480);
    width: 100%;
  }
}
