@import "styles/functions";
@import "styles/variables";

.file {
    padding: 0;
    .holder {
        height: 100%;
        width: 100%;
    }

    .inner {
        border: none;
        height: 100%;
        padding: 0 setUnit(20);
    }

    .icon {
        margin-right: setUnit(22);
    }
}
