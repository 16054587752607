@import 'styles/functions';
@import 'styles/variables';

.table {
  margin-top: 16px;
  .header {
    display: flex;
    justify-content: space-between;
    .headerText {
      .title {
        color: $text-color;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.33px;
        margin: 0;
        padding-bottom: 12px;
      }
      .value {
        color: $text-color;
        font-size: 28px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.09px;
        margin: 0;
        padding-bottom: 12px;
        span {
          &.increased {
            color: $green-color;
          }
          &.decreased {
            color: $error-color;
          }
        }
      }
      .change {
        color: $dark-color;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin: 0;
        padding-bottom: 12px;
        span {
          color: $hover-green;
          font-weight: 600;
        }
      }
    }
    .headerActions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .dropdown {
        border: none !important;
        padding: 0 10px !important;
        height: 35px !important;
        label[class~='ant-radio-wrapper'] {
          padding: 14px 16px !important;
          height: 44px !important;
        }
        span {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          letter-spacing: -0.09px !important;
          color: $text-color !important;
        }
      }
      .addButton {
        margin-top: 5px;
        cursor: pointer;
        color: $link-blue;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.33px;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: rgba(43, 89, 218, 0.08);
        span {
          font-size: 16px;
        }
      }
    }
  }
  .tableRow {
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    &:nth-child(even) {
      background: $background-grey-2;
    }
    .column {
      flex: 1;
      .labelHeader {
        color: $text-gray-color;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin: 0;
      }
      .label {
        margin: 0;
        color: $dark-color;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
      .value {
        margin: 0;
        color: $text-color;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
        span {
          color: $green-color;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.18px;
          margin-left: 10px;
        }
      }
    }
  }
}
