@import 'styles/variables';
@import 'mixins.module.scss';

.confirmationWithReasonModalForAccept {
  & [class*='ant-modal-close-x'] {
    display: none;
  }

  & [class*='ant-btn-primary'] {
    @include modalButton($secondary-color, $secondary-color-hover, $white, 600);
    text-transform: capitalize;
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }

  & [class*='ant-typography'] {
    text-align: center;
    font-weight: 600;
    margin: 0px;
    line-height: 26px;
    letter-spacing: -0.33px;
    color: $text-color;
  }

  & [class*='ant-modal-footer'] {
    justify-content: space-between;
  }

  .titleText {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.33px;
  }

  .subTitle {
    font-size: setUnit(16);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $dark-color;
    margin: 24px 0 40px;
  }

  .reasonInput {
    height: 3rem;
  }

  .btn {
    max-width: 184px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    background: #252d44;
    border-radius: 8px;
    border: 0;
    margin: 0 auto;
    display: block;
    .btnTxt {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: #ffffff;
    }
  }
}
