@import 'styles/functions';
@import 'styles/variables';

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 7px);
  padding: 20px 40px;
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0;
  background: $background-ligh-gray-3;
  .row {
    width: 100%;
    max-width: 1260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    .wrapper {
      width: 100%;
      input {
        border-radius: 12px !important;
        background: $white !important;
        box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08) !important;
        height: 56px !important;
        border: none !important;
        &:active {
          border: none !important;
        }
      }
    }
    .btn {
      height: 56px;
      padding: 10px 20px;
      width: fit-content;
      border-radius: 12px;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $white;
      color: $dark-color;
      &.iconOnly {
        display: grid;
        border-radius: 12px;
        width: 56px;
        height: 56px;
        svg {
          position: relative;
          top: 4px;
        }
      }
      p {
        margin: 0;
        padding: 0;
        margin-left: 6px;
        color: #16161a;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.18px;
        white-space: nowrap;
      }
    }
  }
}

.clientActivityLogSection {
  margin-top: 75px;
  height: calc(100vh - 225px);
  padding: 24px 40px;
  .clientActivityLogWrap {
    .noActivityFound {
      height: 150px;
      margin: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: $text--light-gray-color;
    }
    .clientActivityCard {
      padding: 32px 0;
      border-radius: 16px;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
      &:first-child {
      }
      div[class~='ant-card-head'] {
        padding-left: 24px;
      }
    }
  }
}
