@import 'styles/functions';
@import 'styles/variables';

.header {
  .heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .avatarWrapper {
    height: 40px;
    width: 40px;
  }

  .avatar {
    height: 40px;
    width: 40px;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 12px;
  }

  .condoIcon {
    border-radius: 0.5rem;
    display: inline-block;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .address {
    display: inline-block;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: $text-color;
      margin: 0;
    }

    span {
      font-weight: 400;
      color: $text-color;
    }
  }
}
