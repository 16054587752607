@import 'styles/functions';
@import 'styles/variables';

.renameModal {
  [class*='ant-modal-close'] {
    display: none;
  }
  [class*='ant-form-item'] {
    max-width: 100% !important;
  }

  .header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.33px;
      color: $text-color;
      padding-bottom: 6;
    }
  }

  .inputField {
    [class~='ant-form-item-label'] {
      padding-bottom: 12px;
      label {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09px;
      }
    }

    .input {
      border: 1px solid $border-light-gray-2 !important;
      border-radius: 8px !important;
      padding-left: 16px !important;
      &:focus {
        border-color: $text-gray-color !important;
      }
    }
  }
}
