@import 'styles/variables';
.requestQuoteContainer {
  padding-bottom: 1.5rem;
  margin-top: 20px;
}

.quoteAttachment {
  border: 2px solid $light-gray;
  background: $white !important;
  margin-bottom: 10px;
  padding: 0 1rem;
  height: 68px !important;

  .fileName {
    font-size: 16px !important;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 270px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .quoteDate {
    font-size: 14px !important;
    line-height: 16px;
    margin-top: 0.25rem !important;
    color: $dark-color;
  }
}

.showMore {
  display: flex;
  align-items: center;
  margin: 1rem 0 0.25rem;
  width: fit-content;

  .showMoreIcon {
    height: 32px;
  }

  .showAllText {
    margin-left: 1rem;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &:hover {
    .showMoreIcon {
      svg {
        circle {
          fill: $light-gray;
        }
      }
    }
  }
}

.disclaimerContainer {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .disclaimer {
    font-size: setUnit(16);
    font-weight: 100;
    color: $text--light-gray-color;
  }
}
