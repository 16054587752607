@import 'styles/functions';
@import 'styles/variables';

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 40px 0 40px;
  width: 100%;
  height: auto;

  button {
    margin: 0 !important;
  }

  .titleClassName {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .disabled {
    opacity: 0.8;
    cursor: default;

    &:hover {
      text-decoration: none !important;
    }
  }

  .arrowBackContainer {
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    margin-left: setUnit(48);
    margin-top: setUnit(25);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .text {
      margin-left: setUnit(13);
    }
  }

  .button {
    margin: setUnit(16) setUnit(32);
    background-color: $background-blue;
    padding: 5px 20px;
    min-width: 140px;
    height: setUnit(52);
    font-size: 16px;
    font-weight: 600;
    opacity: 0.35;
  }

  .active {
    opacity: 1;
  }
}
