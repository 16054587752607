@import 'styles/functions';
@import 'styles/variables';

.searchInput {
  border: none !important;
  width: 100%;
  border-radius: 8px !important;
  background-color: $background-grey-2 !important;
  box-shadow: none !important;

  [class*='ant-input-prefix'] {
    margin-right: 12px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  input {
    background-color: transparent !important;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: $text-color;
    outline: none !important;
  }

  padding: 14px 14px 14px 16px;
  height: 52px;
}
