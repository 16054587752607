@import 'styles/functions';
@import 'styles/variables';

.content {
  height: calc(100% - #{setUnit(153)});
  padding: 0 0 setUnit(32);

  .footerOff {
    height: calc(100vh - #{setUnit(277)});
  }

  &.isPending {
    height: 100%;
  }

  .avatar {
    width: setUnit(96);
    height: setUnit(96);
  }

  .avatarPlaceholder {
    font-size: setUnit(32);
  }

  .contactInfo {
    padding: 0 setUnit(32) setUnit(32);
    .phone {
      input {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-color;
        text-align: right;
      }
    }

    .link {
      color: $link-blue;
    }
  }

  .head {
    text-align: center;
    max-width: setUnit(300);
    margin: 0 auto;
    .avatarWrapper {
      margin: 0 auto setUnit(12);
    }

    .name {
      font-size: setUnit(20);
      font-weight: 600;
      line-height: setUnit(26);
      letter-spacing: setUnit(-0.33);
      margin-bottom: setUnit(4);
    }

    .role {
      color: $text-gray-color;
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.18);
      margin-bottom: setUnit(16);
    }
    .newMessage {
      margin: auto;
    }
  }
}
