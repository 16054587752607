@import 'styles/functions';
@import 'styles/variables';

.question {
  margin-bottom: 12px;
}
.subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  text-align: center;
  color: $dark-color;
  margin-bottom: 40px;
}

.modalAnswersContainer {
  max-width: 100%;
}

.modalSaveButton {
  max-width: 154px;
  width: 100% !important;
  height: 52px !important;
  display: block;
  margin: 48px auto 0;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
}
