@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parties {
  .contentWrapper {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(25);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    .availabilityHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;

      .calendarIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
      .title {
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin: 0;
        font-size: setUnit(16);
      }

      .timezone {
        margin: 0;
        font-size: setUnit(14);
        line-height: 20px;
        color: $text-gray-color;
        letter-spacing: -0.18px;
      }

      .availabilityWrapper {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    .addNewWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: setUnit(44);
      padding: setUnit(16) 0 setUnit(24) 0;
      border-bottom: 1px solid $light-gray;
      margin-bottom: setUnit(24);
      .addNew {
        cursor: pointer;
        display: flex;
        align-items: center;
        h4 {
          color: $text-color;
          margin-bottom: 0;
          margin-right: setUnit(8);
          font-size: setUnit(16);
          font-weight: 500;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.18);
        }
      }
    }
  }

  .showingsDuration {
    background-color: $background-ligh-gray-5;
    padding: setUnit(16) setUnit(24);
    border: 1px solid $light-gray;
    border-radius: setUnit(8);
    .duration {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: setUnit(24);
      }
      p {
        margin-bottom: 0;
        font-size: setUnit(16);
        font-weight: 500;
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        color: $text-gray-color;
        margin-left: setUnit(12);
        transition: $transition;
        .input {
          border: 0;
          border-bottom: 2px solid $border-input-middle-gray;
          max-width: setUnit(40);
          width: 100%;
          font-size: setUnit(16);
          font-weight: 400;
          line-height: setUnit(20);
          letter-spacing: setUnit(-0.18);
          &[class~='ant-input-number-focused'] {
            box-shadow: none;
          }
          &[class~='ant-input-number-disabled'] {
            background-color: $white;
          }
          div[class~='ant-input-number-handler-wrap'] {
            display: none;
          }
          input[class~='ant-input-number-input'] {
            padding: 0;
          }
        }
        &.active {
          color: $text-color;
        }
      }
    }
  }
}
