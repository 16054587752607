@import 'styles/functions';
@import 'styles/variables';

.container {
  min-height: calc(100vh - 115px);
}

.listingDetail {
  padding-top: setUnit(32);
  position: relative;
  .back {
    position: absolute;
    top: setUnit(32);
    left: 0;
    margin-left: 2rem;
    z-index: 1;
    overflow: hidden;
    padding: 0;
    width: setUnit(44);
    height: setUnit(44);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $light-gray;
    background: #fff;
    transition: $transition;
    &:hover {
      background-color: $light-gray;
      opacity: 1;
    }
    &:focus {
      background-color: $border-light-gray-2;
    }
    div {
      margin-right: 0;
      display: flex;
      svg {
        width: setUnit(8);
        height: setUnit(14);
        path {
          stroke: $dark-color;
        }
      }
    }
    span {
      display: none;
    }
    &:hover {
      color: $text-color;
    }
  }
  .loading {
    width: 100%;
    padding-right: 0.5rem;
  }
}
