@import 'styles/functions';
@import 'styles/variables';

.titleContainer {
  display: flex;
  align-items: center;
  svg {
    margin-right: setUnit(12);
  }
  .title {
    margin-right: setUnit(20);
    margin-bottom: 0;
    font-weight: bold;
    font-size: setUnit(20);
  }

  .insights {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
    }

    .container {
      cursor: pointer;
      width: setUnit(38);
      height: setUnit(28);
      padding: 0.25rem 0.5rem;
      background-color: $background-grey-2;
      display: inline-flex;
      border-radius: 0.5rem;

      span {
        font-weight: 600;
        color: $text-color;
      }
    }
  }
}

.accordion {
  border-top: 1px solid $light-gray;
}

.matchContainer {
  margin-top: setUnit(32);

  .matchContent {
    margin-top: setUnit(27);

    .matchItemsTitle {
      font-weight: 600;
      color: $text-color;
      margin-bottom: setUnit(18);
    }
  }

  .grayText {
    font-weight: normal;
    font-size: setUnit(14);
    color: $dark-grey-color;
  }
}

.needsContainer,
.wantsContainer {
  margin-top: setUnit(24);
}

.buyersList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: setUnit(24);
}

.noMatches {
  text-align: center;
  flex-grow: 1;
  margin-bottom: setUnit(40);
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  letter-spacing: setUnit(-0.18);
  color: $text--light-gray-color;
}
