@import 'styles/variables';
@import 'styles/mixins';

.errorMessage {
  font-size: 28px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.formTemplateWizardFooterButton {
  margin-top: 44px;
  width: 100%;
  height: 52px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;

  max-width: 400px;
  margin: 40px auto 0 auto;
}

.progressBar {
  position: fixed !important;
  z-index: 5;
}

.formTemplateWizardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100dvh - $header-height - $responsive-footer-height);
  overflow: auto;
}

.listContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
  width: 100%;
  max-width: 80ch;
  padding: 0 24px;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
  }

  > div {
    width: 100%;
  }

  @media screen and (max-width: $mobile-small) {
    margin-left: 0px;
    margin-right: 0px;
  }
}
