@import 'styles/variables';
@import '../../mixins.module.scss';

.confirmationModal {
  .icon {
    @include modalIcon(#fcefef);
  }

  & [class*='ant-btn-primary'] {
    @include modalButton($primary-color, $primary-color-hover, $white, 600);
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & [class*='ant-typography'] {
    text-align: center;
    font-weight: 600;
    margin: 0px;
  }
  & [class*='ant-modal-title'] {
    .warningIcon {
      svg {
        color: $yellow-color;
      }
    }
    .confirmationIcon {
      svg {
        color: $secondary-color;
      }
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }

  .confirmDescription {
    margin: 0.75rem 0rem;
    font-size: 14px;
    line-height: 16px;
    color: $dark-grey-color;
    text-align: center;
  }

  &.primary {
    & [class*='ant-btn-primary'] {
      @include modalButton($background-blue, $secondary-color-hover, $white, 600);
    }
  }

  &.hideTitleIcon {
    [class*='ant-typography'] {
      margin-top: 36px;
    }
  }
}
