@import 'styles/functions';
@import 'styles/variables';

.collapsibleTable {
  .tableTitle {
    font-size: setUnit(18);
    font-weight: 700;
    cursor: pointer;
    width: fit-content;
    margin: 4px 0 setUnit(20);
    &:hover {
      color: $dark-grey-color;
    }
  }

  &:not(:first-child) {
    .tableTitle {
      margin: setUnit(44) 0 setUnit(20);
    }
  }

  .countText {
    color: $text--light-gray-color;
    margin-left: setUnit(2);
  }

  .collapseIcon {
    margin-left: setUnit(4);
    transition: transform 0.3s ease-in-out;

    &.iconRotate {
      transform: rotate(180deg);
    }
    svg {
      color: $text--light-gray-color;
    }
  }

  .expandableIcon {
    z-index: 10;
    line-height: 0px;
  }
}
