.baseCascader {
  width: 100% !important;

  [class*='ant-select-selector'] {
    align-items: center;
    border-radius: 8px !important;
  }
}

.largeCascader {
  height: 48px !important;
  line-height: 48px !important;

  [class*='ant-select-selector'] {
    height: 48px !important;
  }

  [class*='ant-select-selector-input'],
  [class*='ant-select-selection-item'] {
    height: 48px !important;
    line-height: 48px !important;
  }
}
