@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.footer {
  .reschedule {
    height: setUnit(52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(16);
    border-radius: setUnit(10);
    cursor: pointer;
    background-color: transparent;
    margin-bottom: 15px;
    border: 2px solid $secondary-color;
    transition: $transition;
    .text {
      color: $secondary-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;
      transition: $transition;
    }
    svg {
      path {
        transition: $transition;
        fill: $secondary-color;
        stroke-width: 1px;
        stroke: $secondary-color;
      }
      rect {
        transition: $transition;
        stroke: $secondary-color;
      }
    }
    &:hover {
      background-color: $secondary-color;
      .text {
        color: $white;
      }
      svg {
        path {
          fill: $white;
          stroke: $white;
        }
        rect {
          stroke: $white;
        }
      }
    }
  }

  .rescheduleIcon {
    margin-right: setUnit(8);
    position: relative;
    left: setUnit(-2);

    svg {
      vertical-align: middle;
      width: setUnit(20);
      height: setUnit(20);
    }
  }

  .cancel {
    height: setUnit(52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(16);
    border-radius: setUnit(10);
    cursor: pointer;
    background-color: transparent;
    border: 2px solid $error-color;
    transition: $transition;
    .text {
      color: $error-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;
      transition: $transition;
    }
    svg {
      path {
        transition: $transition;
        fill: $error-color;
        stroke-width: 1px;
        stroke: $error-color;
      }
    }
    &:hover {
      background-color: $error-color;
      .text {
        color: $white;
      }
      svg {
        path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }

  .cancelIcon {
    margin-right: setUnit(8);
    position: relative;
    left: setUnit(-2);

    svg {
      vertical-align: middle;
      width: setUnit(20);
      height: setUnit(20);
    }
  }
}
