@import 'styles/functions';
@import 'styles/variables';

.MySearches {
  position: relative;
  padding: setUnit(15);
  @media (min-width: $tablet) {
    margin-top: 20px;
    padding: 0 setUnit(64);
  }

  .accordion {
    border-top: 0 !important;
    border-bottom: unset;
  }

  & [class*='title'] {
    font-size: 16px;
    font-weight: bold;
  }

  .activeSearch {
    @media (min-width: $tablet) {
      margin-top: 30px;
    }
    margin-bottom: 30px;
  }

  .title {
    margin: auto 25px;
    font-size: 16px;
    color: $text-color;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .accordion {
    overflow: auto;
    padding: 0 setUnit(32);
  }
}
