@import 'styles/functions';
@import 'styles/variables';

.userHeader {
  text-align: center;
  border-bottom: 1px solid $grey-color;
  padding: setUnit(40) 0 setUnit(52);

  .logo {
    width: setUnit(96);
    height: setUnit(96);
    border-radius: 50%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: setUnit(30);
    font-weight: 700;
    margin: setUnit(12) auto;
    max-width: setUnit(500);
  }

  .category {
    color: $text-gray-color;
  }
}
