@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
}

.inputWrapper {
  width: setUnit(85);
  height: setUnit(40);
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  input {
    padding-top: setUnit(10) !important;
    padding-bottom: setUnit(10) !important;
    padding-right: setUnit(16) !important;
    height: setUnit(40);
  }
}

.inputHolder {
  input {
    text-align: center;
  }
}

.content {
  z-index: 999999999999999;
  background: #ffffff;
  border-top-left-radius: setUnit(8);
  border-top-right-radius: setUnit(8);
  width: 100%;
  padding: setUnit(0) setUnit(24) 0 setUnit(24);
}

.innerWrapper {
  border-bottom: 1px solid $border-middle-gray;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: setUnit(24);
}

.orWrapper {
  background: #ffffff;

  .or {
    background: #ffffff;
    font-size: setUnit(12);
    font-weight: 500;
    position: relative;
    top: setUnit(-12);
    width: setUnit(50);
    color: $border-middle-gray;
    margin: 0 auto setUnit(-13);
  }
}

.label {
  font-size: setUnit(16);
  color: $text-color;
  font-weight: 600;
}
