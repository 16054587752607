@import 'styles/functions';
@import 'styles/variables';

.addressInfo {
  .addressItem {
    margin-bottom: setUnit(8);
    &:last-child {
      margin-bottom: 0;
    }
  }

  .address {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    text-align: right;
  }
}
