@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.sort {
    @include action-icon();
    width: setUnit(24);
    height: setUnit(24);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: setUnit(8);

    .sortIcon {
        svg {
            width: setUnit(16);
            vertical-align: middle;

            path {
                stroke: $text-color-light !important;
                stroke-width: 4px !important;
            }
        }
    }

    &.active {
        background-color: $secondary-color;
        color: $white;

        svg {
            path {
                stroke: $white !important;
            }
        }

        &:hover {
            background-color: $hover-blue;
        }
    }
}

.iconsGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}
