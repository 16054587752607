@import 'styles/variables';

.minMax {
  .to {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin: 0 24px;
  }
}
