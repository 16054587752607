@import "styles/variables";

.skip {
    font-weight: 600;
    color: $secondary-color;
    text-align: left;

    .text {
        cursor: pointer;
    }
}
