@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  width: 100%;

  .dividerWrapper {
    border-top: 1px solid $light-gray;
    padding-top: 28px;
    padding-bottom: 20px;
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.18px;
    margin-inline: 12px;
  }

  .showMoreButtonWrapper {
    margin-bottom: 80px;

    .showMoreButton {
      padding: 16px 18px 16px 16px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 100px;
      border: 2px solid $light-gray;
      background: $white;

      & div {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        color: $dark-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.09px;
        font-size: 16px;
        font-style: normal;
      }
    }
  }
}
