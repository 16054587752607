@import 'styles/variables';
@import 'styles/functions';

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 9px;
  .buttonWrapper {
    width: setUnit(300);
    .homeActionsButton {
      flex-grow: 1;
      background: $white;
      border: 1px solid $border-middle-gray;
      color: $text-color;
      padding: setUnit(12) setUnit(24);
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      @media (min-width: $mobile-small) {
        padding: setUnit(12);
      }
      @media (min-width: $tablet) {
        width: 100%;
        padding: setUnit(12) setUnit(24);
      }
      &:hover {
        border-color: $text-color;
      }
      .buttonTitle {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        color: $dark-color;
        @media (min-width: $mobile-small) {
          font-size: setUnit(14);
          line-height: setUnit(19);
        }
        @media (min-width: $tablet) {
          font-size: setUnit(16);
          line-height: setUnit(21);
        }
        & > .buttonIcon {
          display: flex;
          margin-right: setUnit(12);
          @media (min-width: $mobile-small) {
            margin-right: setUnit(8);
          }
          @media (min-width: $tablet) {
            margin-right: setUnit(12);
          }
          svg {
            width: setUnit(24);
            height: setUnit(24);
            @media (min-width: $mobile-small) {
              width: setUnit(20);
              height: setUnit(20);
            }
            @media (min-width: $tablet) {
              width: setUnit(24);
              height: setUnit(24);
            }
          }
        }
      }
      &.lastButton {
        background: $background-blue;
        padding-left: setUnit(22);
        border: unset;
        .buttonTitle {
          color: $white;
        }
        &:hover {
          border-color: unset;
        }
      }
    }
  }
}

.innerHolder {
  margin: 0 auto;
  width: 100%;
  order: 3;
  margin-top: 24px;
  height: calc(100vh - 160px);
  @media (min-width: $desktop) {
    order: 2;
    width: calc(100% - 240px);
    height: calc(100vh - 80px);
  }
  div[class~='image-gallery'] {
    height: 100%;
    div[class~='image-gallery-content'] {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      height: 100%;
      div[class~='image-gallery-slide-wrapper'] {
        height: calc(100% - 79px);
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        @media (min-width: $desktop) {
        }
        div[class~='image-gallery-swipe'] {
          height: auto;
          @media (min-width: $desktop) {
            height: calc(100% - 53px);
          }
          div[class~='image-gallery-slides'] {
            height: 100%;
          }
        }
      }
      div[class~='image-gallery-thumbnails-wrapper'] {
        align-self: end;
        flex: 0 0 100%;
      }
    }
  }
  .imageOriginalClass {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 8px;
      width: 100%;
      max-height: 100%;
      height: auto;
      @media (min-width: $desktop) {
        width: auto;
      }
    }
  }
  .middleBlock {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: setUnit(16) setUnit(40);
    font-size: setUnit(16);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;
    font-weight: 400;
    span {
      &:not(:last-child) {
        &::after {
          content: '-';
          font-weight: 400;
          margin: 0 setUnit(12);
          color: $text-color;
        }
      }
    }
    .address {
      font-weight: 600;
    }
    .homeNumbers {
      display: flex;
      margin-bottom: 0;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          &::after {
            content: '';
            width: setUnit(4);
            height: setUnit(4);
            border-radius: setUnit(50);
            background-color: $text-gray-color;
            margin: 0 setUnit(12);
          }
        }
      }
    }
  }
  div[class~='image-gallery-thumbnails'] {
    padding: 0;
    .thumbnailClass {
      cursor: pointer;
      border-radius: 6px;
      border-width: 2px !important;
      width: auto;
      overflow: hidden;
      img {
        max-height: setUnit(75);
        min-height: setUnit(75);
        width: auto;
      }
    }
  }
}

.container {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  gap: 10px;
  margin-top: 10px;
  .leftColumn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    width: 400px;
    @media (max-width: 800px) {
      display: none;
    }
  }
  .rightColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: calc(100% - 400px);
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.counterClient {
  bottom: setUnit(86) !important;
}

.counter {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  bottom: setUnit(20);
  right: setUnit(40);
  height: setUnit(36);
  display: inline-flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 48px;
  padding: 0 setUnit(10);
  order: 1;
  .counterIcon {
    height: setUnit(22);
    width: setUnit(24);
    margin-right: setUnit(6);
  }
  .count {
    font-size: setUnit(16);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    font-weight: 500;
  }
}

.nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
  transition: all 0.1s;
  width: setUnit(44);
  height: setUnit(44);
  background: $white;
  border: 1px solid $border-middle-gray;
  box-sizing: border-box;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  .navIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: setUnit(24);
      width: setUnit(24);
      path {
        stroke: $dark-grey-color;
      }
    }
  }
  &:hover {
    border-color: $text-color;
  }
  &.leftNav {
    left: setUnit(40);
    transform: scaleX(-1) translateY(-50%);
  }
  &.rightNav {
    right: setUnit(40);
  }
}

.navFullScreenClient {
  top: calc(50% - 68px);
}

.navFullScreen {
  z-index: 999;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.1s;
  width: setUnit(44);
  height: setUnit(44);
  background: rgba(38, 38, 38, 0.8);
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  .navIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: setUnit(24);
      width: setUnit(24);
      path {
        stroke: $white;
      }
    }
  }
  &:hover {
    border-color: $text-color;
  }
  &.leftNavFullScreen {
    left: setUnit(40);
    transform: scaleX(-1) translateY(-50%);
  }
  &.rightNavFullScreen {
    right: setUnit(40);
  }
}

.closeNav {
  z-index: 999;
  position: fixed;
  top: 60px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.1s;
  width: setUnit(44);
  height: setUnit(44);
  background: rgba(38, 38, 38, 0.8);
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  transform: translateY(-50%);
  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: setUnit(24);
      width: setUnit(24);
    }
  }
  &:hover {
    border-color: $text-color;
  }
  &.close {
    right: setUnit(40);
  }
}

.activeImage {
  border: 2px solid #000;
}

.leftColumn img {
  cursor: pointer;
  border-radius: 6px;
  object-fit: cover; /* Preserve aspect ratio */
  height: 114px;
  width: calc(50% - 20px);
  margin: 10px;
  box-sizing: border-box;
}

.leftColumn img:nth-child(3n) {
  height: 128px;
  width: 100%;
}

.rightColumnImg {
  cursor: pointer;
  border-radius: setUnit(6);
  max-height: calc(100vh - 165px);
  max-width: 100%;
  object-fit: cover;
}

.fullScreen {
  z-index: 99;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: setUnit(20);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightColumnImgFullScreen {
  border-radius: setUnit(6);
  max-height: 100%;
  max-width: 100%;
}
