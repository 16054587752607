@import 'styles/functions';
@import 'styles/variables';

.myLibraryModal {
  [class='ant-modal-content'] {
    padding-top: setUnit(36);
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }

  .header {
    position: relative;
    .previousIcon {
      position: absolute;
      top: 0;
      line-height: 0;
      cursor: pointer;
    }

    .heading {
      text-align: center;
      font-weight: 700;
      font-size: setUnit(24);
      line-height: setUnit(32);
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: setUnit(36);
      color: $text-color;
      letter-spacing: -0.47px;
    }
  }

  .progressBar {
    display: none !important;
  }
}
