@import 'styles/functions';
@import 'styles/variables';

.noteDetail {
  margin-bottom: 0;

  .note {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: setUnit(8);
    text-align: left;

    .readMoreLess {
      color: $link-blue;
      font-size: setUnit(14);
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.18px;
      margin-left: 2px;
    }

    .readMoreLess:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .noteDateTime {
    color: $text-gray-color;
    font-weight: 400;
    font-size: setUnit(12);
    line-height: setUnit(16);
    letter-spacing: -0.09px;
    margin-bottom: 0;
    text-align: left;
  }
}
