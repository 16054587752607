@import 'styles/functions';
@import 'styles/variables';

.connectionExpandedModal {
  .contentWrapper {
    margin-top: 40px;
  }
  .crossButton {
    // margin-top: 40px !important;
    margin-right: 32px !important;
  }
  .InstancesWrapper {
    padding: 0 12px;
  }
  [class='ant-modal-content'] {
    padding: setUnit(32) setUnit(40) setUnit(32) setUnit(40);
    margin: 4rem 0;
    background-color: $white;
  }

  .modalScrollHeight {
    padding-right: 2rem;
  }

  .expandedProfile {
    & [class*='ant-tabs-ink-bar'] {
      border-bottom: 3px solid $primary-color !important;
    }

    .tabsIconProfile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $text-color;
      width: 90px;
    }

    .clientTabIconProfile {
      width: 124px;
    }

    .tabName {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }

    .inactive {
      color: $text--light-gray-color;
    }
  }

  .tabWrap {
    max-height: setUnit(460);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: setUnit(24);

    margin-right: -2.5rem;
    padding-right: 2.5rem;
  }

  .agentProfile {
    & [class*='ant-tabs-tab'] {
      margin-left: 0;

      &:nth-child(4),
      &:nth-child(6) {
        margin-left: 1rem;
      }

      &:nth-child(5) {
        margin-left: 2rem;
      }
    }

    & [class*='ant-tabs-content-holder'] {
      & [class*='ant-tabs-tabpane'] {
        margin-left: 0 !important;
      }
    }

    & [class*='ant-tabs-nav-wrap'] {
      overflow: unset;
    }
  }
}
.hideModal {
  [class*='ant-modal'] {
    opacity: 0;
  }
}

.editHeader {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.47px;
}

.editInputWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.editBtnWrapper {
  text-align: center;
  margin-top: 12px;

  button {
    padding: 14px 45px;
  }
}
