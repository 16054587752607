@import 'styles/functions';
@import 'styles/variables';

.footer {
  padding-top: setUnit(32);
  display: flex;
  justify-content: center;
  .nextBtn {
    border-radius: 8px !important;
    background-color: $secondary-color !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    padding: setUnit(12) setUnit(32);
    height: auto;
    &:hover {
      background: $secondary-color-hover !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .submitBtn {
    width: auto;
  }

  .arrowIcon {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
}
