@import 'styles/functions';
@import 'styles/variables';

.verifyEmail {
  .header {
    padding: 25px 40px;
    border-bottom: 1px solid $light-gray;
    height: $header-height;
    display: flex;
    align-items: center;
    .logo {
      display: flex;
      svg {
        max-width: 136px;
        width: 100%;
      }
    }
  }

  .pendingWrapper {
    height: calc(100vh - $header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
}
