@import 'styles/variables';
@import 'styles/functions';

.groupedItems {
  text-align: left;
  border: 0 !important;
  div[class~='ant-card-head'] {
    border-block-start: 0;
    div[class~='ant-card-head-wrapper'] {
      div[class~='ant-card-head-title'] {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(24);
        letter-spacing: setUnit(-0.33);
        color: $text-color;
      }
    }
  }
  div[class~='ant-card-body'] {
    padding: 0;
  }
}

.clientInfoWrapper {
  flex-basis: calc(100% - 188px) !important;
}

.buttonReject {
  border-radius: 100px !important;
  border-width: 1px !important;
  .icon {
    display: flex;
  }
}

.cancelled {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.09px;
  color: $dark-grey-color;
  padding: 6px 8px;
  border-radius: 100px;
  background-color: $background-grey-2;
}
