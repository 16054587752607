@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.transactionsCollapse {
  background-color: transparent;
  border: 1px solid $light-gray;
  border-radius: setUnit(8);
  &:not(:last-child) {
    margin-bottom: setUnit(16);
  }
  div[class='ant-collapse-item'] {
    div[class='ant-collapse-expand-icon'] {
      .expandIconWrap {
        svg {
          transform: rotate(0deg);
          @include transition;
        }
      }
    }
  }
  div[class='ant-collapse-item ant-collapse-item-active'] {
    div[class='ant-collapse-expand-icon'] {
      .expandIconWrap {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  div[class*='ant-collapse-item'] {
    border-bottom: 0;
    div[class='ant-collapse-header'] {
      padding: setUnit(24) !important;
      div[class='ant-collapse-expand-icon'] {
        .expandIconWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: setUnit(32);
          height: setUnit(32);
          border-radius: setUnit(100);
          background-color: $background-grey-2;
          right: setUnit(24);
        }
      }
      span[class='ant-collapse-header-text'] {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: -0.09px;
        color: $text-color;
      }
    }
  }

  div[class*='ant-collapse-content'] {
    border-top: 0;
    div[class='ant-collapse-content-box'] {
      padding: 0 setUnit(24) setUnit(24) !important;
    }
  }
}
