@import "styles/variables";
@import "styles/functions";

.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    transition: all .2s;
    z-index: 9999;

    &.isOpen {
        pointer-events: auto;
        opacity: 1;

        .opacityLayer {
            opacity: 1;
        }
    }
}

.opacityLayer {
    background-color: rgba(#000000,.45);
    opacity: 0;
    transition: opacity .2s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9998;
}

.contentWrapper {
    display: flex;
    justify-content: center;
    height: 100%;
}

.content {
    background-color: #ffffff;
    max-width: 90%;
    max-height: 95%;
    overflow: auto;
    position: relative;
    z-index: 9999;
    transition: all .3s;
    margin: auto setUnit(10);
}

.innerHolder {
    max-height: 100vh;
    overflow: hidden;

    &.scroll {
        overflow: auto;
    }
}

