@import 'styles/functions';
@import 'styles/variables';

.name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: $text-color;
}
