@import "styles/functions";
@import "styles/variables";

.wrapper {
    padding: setUnit(32);
    display: flex;

    .leftBlock {
        min-width: setUnit(80);

        img {
            width: setUnit(120);
            height: setUnit(120);
            border-radius: 50%;
        }
    }

    .rightBlock {
        padding-left: setUnit(40);
        width: 100%;

        .name {
            font-weight: 600;
            font-size: setUnit(16);
            padding-bottom: setUnit(19);
        }

        .description {
            padding-bottom: setUnit(32);
            font-size: setUnit(14);
        }
    }
}

.icon {
    width: setUnit(80);
    height: setUnit(80);

    div {
        font-size: setUnit(28);
        width: setUnit(80);
        height: setUnit(80);
    }
}

.inputBlock {
    display: flex;
    min-height: setUnit(44);
    align-items: center;
    margin-bottom: setUnit(10);

    .rightBlock {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding-top: setUnit(10);

        .input {
            margin: 0 0 setUnit(10) 0 !important;
        }
    }

    .infoBlockForOneLine {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding: setUnit(10) 0;
        border-bottom: 2px $light-gray solid;
    }

    .infoBlockForMultipleLines {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        border-bottom: 2px $light-gray solid;
    }

    a {
        color: $text-color;
        transition: all .1s;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.label {
    flex: 1;
    font-size: setUnit(14);
    color: $dark-grey-color;
    align-self: flex-start;
    padding-top: setUnit(14);
    padding-right: setUnit(8);
}

.isActiveMarker {
    border: 1px $green-color solid;
    background-color: rgba($green-color, .05);
    color: $green-color;
    margin-right: setUnit(8);
    margin-left: setUnit(8);

    &.deactivated {
        border-color: $dark-grey-color;
        color: $text-color;
        background-color: $background-ligh-gray;
    }
}

.marker {
    margin-bottom: setUnit(19);
    height: setUnit(32);
    font-size: setUnit(14);
    line-height: setUnit(16);
    border-radius: setUnit(100);
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0 setUnit(8);
}

.flexWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar {
    width: setUnit(80);
    height: setUnit(80);

    img {
        width: setUnit(60) !important;
        height: setUnit(60) !important;
    }
}
