@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.singleList {
  display: flex;
  flex-direction: column;
  color: $text-color;
  max-height: 100%;
  height: fit-content;
  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.09px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.09px;
    margin-bottom: 0;
  }
  .componentWrapper {
    margin-top: 10px;
  }
  &:hover {
    .edit {
      display: flex;
    }
  }
  .edit {
    padding-top: 4px;
    z-index: 1;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    top: -10px;
    border-radius: 50%;
    border: 2px solid $background-ligh-gray-4;
    background: #fff;
    &.active {
      display: flex;
    }
  }
}
