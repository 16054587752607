@import 'styles/functions';
@import 'styles/variables';

.commissionRespContainer {
  margin-top: setUnit(16);
  padding: setUnit(12);
  border-radius: 8px;
  background-color: #f4f5f6cc;

  .heading {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
    color: $text-gray-color;
  }

  .responsibilityItem {
    margin-top: 0.5rem;
    .labelContainer {
      display: flex;
      align-items: center;
      gap: 6px;

      .color {
        width: 6px;
        height: 6px;
        border-radius: setUnit(2);

        &.blue {
          background-color: $confetti-blue;
        }
        &.lightBlue {
          background-color: $light-blue;
        }
      }
    }

    span {
      position: relative;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
