@import 'styles/functions';
@import 'styles/variables';

.needsAttentionContainer {
  margin-top: setUnit(24);
  padding: setUnit(16) 0;
  margin-bottom: setUnit(16);
  background: #fb913a0f;
  border-radius: setUnit(8);

  .heading {
    font-size: setUnit(20);
    line-height: setUnit(26);
    padding: 0 setUnit(16);
    font-weight: 600;
  }

  .tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: setUnit(16);
    cursor: pointer;

    .textContainer {
      display: flex;
      align-items: center;
      .text {
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-weight: 600;
        margin-left: setUnit(10);
        margin-top: setUnit(2);
      }
    }

    .count {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(32);
      height: setUnit(32);
      font-size: setUnit(18);
      font-weight: 600;
      color: $orange-2;
      background-color: #e282340f;

      &.overdue {
        color: $error-color !important;
        background-color: #ec455e0f !important;
      }
    }

    &:hover {
      background-color: #fb913a0f;
    }
  }

  .emptyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: setUnit(180);
    font-size: setUnit(16);
    font-weight: 400;
    color: $text--light-gray-color;
  }
}
