@import 'styles/functions';
@import 'styles/variables';

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
  transition: padding 0.3s;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 40px;

  .error {
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: $primary-color-light;
    color: $error-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    margin-top: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    color: $text-color;
    margin-bottom: setUnit(9);
  }

  .contactInformation {
    margin-top: 20px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    color: $text-color;
  }

  .clientType {
    width: 100%;
    margin: 10px 0 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s, margin 0.3s, display 0.3s;
    display: none;

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: $text-color;
    }

    &.expand {
      margin: 40px 0 10px;
      visibility: visible;
      opacity: 1;
      display: block;
    }

    .title {
      margin-bottom: setUnit(0);
    }

    $options-gap: setUnit(15);

    .selectType {
      margin: setUnit(16) 0 0;
      display: flex;
      flex-wrap: wrap;
      gap: $options-gap;
      width: 100%;
    }

    .selectOption {
      height: setUnit(64);
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: setUnit(20) setUnit(16) setUnit(20) setUnit(15);
      @media (min-width: $mobile) {
        min-width: calc((100% / 4) - $options-gap);
      }

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
        @media (min-width: $mobile) {
          margin-bottom: 0;
        }
      }

      label {
        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $text-color;
          letter-spacing: setUnit(-0.09);
        }
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      span {
        font-weight: 500 !important;
      }
    }
  }
}

.header {
  margin-bottom: 20px;
}

.holder {
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
}

.checkboxOption {
  width: 200px !important;
}
