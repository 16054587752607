@import 'styles/variables';

.billingHistoryModal {
  .modalContent {
    height: 685px;
    .billingHistoryModalHeader {
      font-size: 24px;
    }
  }
}
