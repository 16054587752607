.filterSection {
  padding: 4px 0;
  [class*='ant-collapse-item-active'] {
    padding-top: 0 !important;
  }
  .title {
    color: var(--Black-100, #303030);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.18px;
  }

  .dataRangeWrapper {
    margin: 0 !important;
  }

  .multiSelectWrapper {
    margin: 0 !important;

    [class='ant-select-selector'] {
      border-radius: 8px !important;
      border: 1px solid var(--grayscale-25-outline, #d6d6d7) !important;
    }

    h4 {
      display: none !important;
    }
  }
}
