@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.declineConfirmation {
  text-align: center;

  .informationText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      margin-bottom: 32px;
    }

    color: $dark-color;
  }

  .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.09px;
    text-align: center;
  }

  .button {
    width: 172px;
    height: 52px;
    border-radius: 10px;
    display: block;
  }

  .greyButton {
    background-color: $background-grey-2;
    color: $dark-color;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 32px 0px 0px;
    gap: 40px;
  }

  @media screen and (max-width: $mobile-small) {
    .button {
      width: 190px;
    }

    .informationText {
      width: 100%;
    }

    .buttonContainer {
      gap: 20px;
    }
  }
}
