@import 'styles/functions';
@import 'styles/variables';

.container {
  .manualEntrySwitch {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 52px;
    .manualEntryText {
      font-size: 16px;
      font-weight: 500;
      color: $dark-color;
      line-height: 24px;
      letter-spacing: -0.09px;
    }
  }
  .searchWrapper,
  .propertyUnit {
    input {
      height: 52px !important;
      padding: 12px 0 16px !important;
      font-size: 18px !important;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: $text-color;
      margin-bottom: 0.5rem !important;
      &::placeholder {
        color: $text-gray-color;
      }
      &:focus {
        border-bottom-color: $text-gray-color;
      }
      &::placeholder {
        font-size: 18px !important;
        color: $text-gray-color;
      }
    }
  }
  .valuesWrapper {
    height: 52px !important;
    padding: 12px 0 16px !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $text-color;
  }
  .propertyUnit {
    max-width: 200px !important;
  }
}
