@import 'styles/functions';
@import 'styles/variables';

.cardContainers {
  border-radius: 10px !important;
  & [class*='ant-card-body'] {
    padding: setUnit(24) setUnit(0);
  }
}

.cardStyles {
  display: flex !important;
  align-items: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #262626;
  letter-spacing: -0.09px;
  margin-top: setUnit(15);
  margin-bottom: setUnit(15);
}

.clarityLink {
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  color: $text-color;
  display: block;
  padding: setUnit(0) setUnit(24);

  &:hover {
    background-color: $background-grey-2;
  }
}

.leftMenuText {
  font-size: 16px;
  font-weight: bold;
}

.arrow {
  font-size: 16px;
  font-weight: bold;
  margin-left: auto !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #262626;
}

.icon {
  display: flex;
  height: setUnit(32);
  margin-right: setUnit(10);
  justify-content: center;
  text-align: center;
}

.navigationText {
  margin-left: setUnit(10);
}

.drillDown {
  padding: setUnit(0) setUnit(24);
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #262626;
}
