@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.placesAutocompleteWrapper {
  position: absolute;
  width: 100%;
  .wrapper {
    width: 100%;
    border-radius: setUnit(8);
    .dropdownWrapper {
      @include box-shadow();
      position: relative;
      top: setUnit(2);
      z-index: 999;
      border-radius: setUnit(8);
      overflow: hidden;
      background: #ffffff;
      .values {
        background-color: #ffffff;
        padding: setUnit(20) setUnit(20) setUnit(12) setUnit(20);
        flex-wrap: wrap;

        & > div {
          margin-bottom: setUnit(8);
        }
      }
    }

    .suggestions {
      position: relative;
      background: #ffffff;
      border-top: 1px solid $light-gray;
    }

    .option {
      display: flex;
      align-items: center;

      .icon {
        margin-right: setUnit(11);
      }
    }

    &.light {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }

    &.lightFull {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }

    &.lightRound {
      @include lightRound;
    }

    &.full {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }
  }
}
