@import 'styles/functions';
@import 'styles/variables';

.addPartner {
  padding: 12px 0;
  .inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
  }
  .addWrapper {
    margin-right: 16px;
    .add {
      width: setUnit(48);
      height: setUnit(48);
      background-color: $background-grey-2;
      svg {
        path {
          stroke: #000;
        }
      }
    }
  }
  .plusIcon {
    width: setUnit(20);
    height: setUnit(20);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      path {
        stroke: $white;
      }
    }
  }
}
