@import 'styles/functions';
@import 'styles/variables';

.summaries {
  height: calc(100vh - $header-height);
  overflow: auto;
}

.collapse {
  [class='ant-collapse-header'] {
    padding-right: 68px !important;
  }

  [class='ant-collapse-arrow'] {
    display: flex !important;
    align-items: center;
  }
}

.btnWrapper {
  border-radius: 100%;
  background: $background-grey-2;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 12px;
}

.counter {
  color: $text--light-gray-color !important;
}
