@import 'styles/functions';
@import 'styles/variables';

.headerCol {
  display: flex;
  align-items: center;

  .icon {
    background: unset !important;
    width: setUnit(40) !important;
    height: setUnit(40) !important;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .clientIcon {
    width: setUnit(40) !important;
    height: setUnit(40) !important;
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .info {
    span {
      padding-left: setUnit(10);
      font-size: setUnit(16);
      font-weight: 600;

      @media screen and (max-width: $tablet) {
        padding-left: 0;
      }
    }
  }
}

.newCount {
  font-weight: 600;
  color: $primary-color;
}
