@import 'styles/variables';

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.09px;
  color: $dark-color;
  transition: $transition;
  margin-bottom: 0;
}
