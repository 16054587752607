@import 'styles/functions';
@import 'styles/variables';

.highlights {
  max-height: auto;
  overflow-y: auto;
  padding-bottom: 1rem;
  border-bottom: 1px solid $light-gray;

  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .disclaimer {
    text-align: center;
  }

  .valueItemWidth {
    [class*='value'] {
      max-width: 270px;
    }
  }
}
