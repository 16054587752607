@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/functions';

.editMenuWrapper {
  [class~='ant-popover-inner'] {
    border-radius: 8px;
  }

  [class~='ant-popover-placement-bottomRight'] {
    padding-top: 0px;
  }

  .editMenu {
    width: 160px;

    .menuLink {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      gap: 8px;

      .menuIcon {
        line-height: 0;
      }

      &:hover {
        background-color: $hover-light-gray;
        cursor: pointer;
      }

      &:first-child {
        &:hover {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }

      &:last-child {
        &:hover {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      .text {
        font-size: setUnits(14);
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }
    }
  }
  .editIcon {
    cursor: pointer;
    line-height: 0;
  }
}
