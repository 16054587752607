@import 'styles/functions';
@import 'styles/variables';

.titleTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 200px;

      p {
        color: white !important;
        margin: 0;
        white-space: normal;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}

.criteriaTypeContainer {
  margin: 20px 0;

  .basicOptionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .titleContainer {
      display: flex;
      align-items: center;
      gap: setUnit(10);
      .title {
        font-size: setUnit(16);
        font-weight: 600;
      }
    }

    .addContainer {
      color: $confetti-blue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.18px;
      margin: 0;
      white-space: nowrap;
      cursor: pointer;
      span {
        font-size: 20px;
      }
    }
  }

  .additionalOptions {
    background-color: $white;
    padding: setUnit(20) setUnit(24);
    border: 1.5px solid $light-gray;
    border-radius: setUnit(8);
    margin-top: setUnit(15);
    .wrapperCheckbox {
      display: flex;
    }
    .checkboxLabel {
      margin-bottom: 0;
      gap: setUnit(10);
    }
  }
}

.icon {
  svg {
    position: relative;
    top: 6px;
  }
}

.card {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid $light-gray;
  background: $white;
  padding: 10px;
  margin: setUnit(15) 0;
  position: relative;
  &:hover {
    background-color: #f8f8f8;
    .remove {
      display: flex;
    }
  }

  .remove {
    display: none;
    position: absolute;
    left: -10px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-color: $primary-color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      position: relative;
      top: 3px;
    }
  }

  .basicOptionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .titleContainer {
      margin-left: 10px;
      max-width: calc(100% - 180px);
      .title {
        color: $text-color;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.09px;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
      }
      .subtitleContainer {
        display: flex;
        gap: 5px;
        .subtitle {
          color: $dark-grey-color;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.09px;
          margin: 0;
        }
      }
    }

    .addContainer {
      color: $confetti-blue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.18px;
      margin: 0;
      cursor: pointer;
      white-space: nowrap;
      span {
        font-size: 20px;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: setUnit(10);

      .criteriaButton {
        width: setUnit(68);
        height: setUnit(42);
        font-size: setUnit(14);
        background-color: $white !important;
        color: $dark-color !important;
        border: 1.5px solid $light-gray !important;
        padding: 0 !important;
        border-radius: setUnit(8);

        &.confettiBlue {
          background-color: #f0f4fb !important;
          border-color: $confetti-blue !important;
        }
        &.primary {
          background-color: #fff2f3 !important;
          border-color: $primary-color !important;
        }
        &.orange {
          background-color: #fb913a14 !important;
          border-color: $orange !important;
        }
        &.lightBlue {
          background-color: #51bfe114 !important;
          border-color: $light-blue !important;
        }
        &.purple {
          background-color: #f5f4fa !important;
          border-color: $purple !important;
        }
      }
    }
  }

  .additionalOptions {
    background-color: $white;
    padding: setUnit(20) setUnit(24);
    border: 1.5px solid $light-gray;
    border-radius: setUnit(8);
    margin-top: setUnit(15);
    .wrapperCheckbox {
      display: flex;
    }
    .checkboxLabel {
      margin-bottom: 0;
      gap: setUnit(10);
    }
  }
}
