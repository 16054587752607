.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  padding-top: 24px;

  .section {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      color: var(--REBRAND-Grayscale-100, #262626);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Mobile/Desktop/H6/Bold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 130% */
      letter-spacing: -0.33px;
      margin-bottom: 24px;

      span {
        color: var(--REBRAND-Grayscale-40, #aaabab);
        font-feature-settings: 'liga' off, 'clig' off;

        /* Mobile/Desktop/H6/Bold */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.33px;
      }
    }
  }
}

.back {
  position: absolute;
  top: 115px;
  left: 40px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #e8e9ea;
  background: #fff;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    position: relative;
    right: 1px;
  }
}
