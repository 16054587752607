@import 'styles/functions';
@import 'styles/variables';

.agentHeaderContainer {
  min-height: 3.75rem;
  padding: 2.375rem 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .headerTitle {
    color: $text-color;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
    margin-bottom: 0;
    text-align: center;

    .emoji {
      width: 32px;
      height: auto;
      margin-bottom: 9px;

      @media screen and (max-width: $mobile) {
        width: 24px;
      }
    }

    @media screen and (max-width: $mobile) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .teamFilter {
    position: absolute;
    right: 35px;
  }
}
