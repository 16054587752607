@import 'styles/functions';
@import 'styles/variables';

.transactionAccess {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;

  .label {
    display: inline-block;
  }

  .accessSelect {
    float: right;
    position: relative;
    right: -0.5rem;
    top: -0.25rem;

    & [class*='ant-select-selection-item'] {
      text-align: end;
      color: $confetti-blue;
    }
    & [class*='ant-select-arrow'] {
      svg {
        path {
          stroke: $confetti-blue;
        }
      }
    }
  }
}
.accessText {
  color: $confetti-blue;
  margin-right: 1rem;
}

.transactionAccessSelect {
  .dropdownLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon {
    visibility: hidden;
  }
  [class*='ant-select-item-option-selected'] {
    background-color: #fff !important;
    .icon {
      visibility: visible;
    }
  }
}
