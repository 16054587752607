@import 'styles/variables';

.addService {
  display: flex;
  margin-top: 24px;
  width: fit-content;
  cursor: pointer;

  .plus {
    margin-right: 2px;

    svg {
      width: 13px;
    }
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $dark-color;
    font-style: normal;
    font-family: Inter;
  }
}
