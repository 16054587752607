@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.spinnerWrapper {
  margin-right: setUnit(24);

  .loader {
    font-size: setUnit(3);
    margin: 0;
  }
}

.actions {
  cursor: pointer;
  position: relative;

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(18);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    z-index: 9;
    overflow: hidden;
    min-width: setUnit(100);

    .list {
      list-style: none;

      .item {
        padding: setUnit(10) setUnit(15);
        font-size: setUnit(15);

        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}

.previewIcon {
  margin-right: 10px;

  svg {
    path {
      stroke-width: 1.5 !important;
    }
  }
}

.downloadIcon {
  margin-right: 10px;
}

.dotsImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  width: 32px;

  &:hover {
    background: $background-grey-2;
  }
}

.popoverOverlay {
  width: 196px;
  border-radius: 8px;
  padding: 0px !important;

  [class*='ant-popover-inner'] {
    border-radius: 8px;
  }

  [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }
}
