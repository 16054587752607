@import 'styles/functions';
@import 'styles/variables';

.contactWrapper {
  .expanded {
    padding: setUnit(14) 0;
  }

  .assignedTo {
    margin-top: 1.5rem;
    padding: 0;
  }
}
