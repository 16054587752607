@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.noData {
  width: 100%;
  font-weight: 500;
  font-size: setUnit(16);
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  color: $text-color;
  margin-bottom: setUnit(4);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.documentsCollapse {
  background-color: transparent;
  border: 0;
  min-height: 200px;

  * {
    font-family: $font-family;
  }

  div[class='ant-collapse-item'] {
    div[class='ant-collapse-expand-icon'] {
      .expandIconWrap {
        svg {
          transform: rotate(0deg);
          @include transition;
        }
      }
    }
  }

  div[class='ant-collapse-item ant-collapse-item-active'] {
    div[class='ant-collapse-expand-icon'] {
      .expandIconWrap {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  div[class*='ant-collapse-item'] {
    border-bottom: 0;

    div[class='ant-collapse-expand-icon'] {
      .expandIconWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(100);
        background-color: $background-grey-2;
        right: 0;
      }
    }

    div[class='ant-collapse-header'] {
      padding: setUnit(24) 0;

      .headerLeft {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        svg {
          margin-right: setUnit(8);
        }

        h3 {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          margin-bottom: 0;
          color: $text-color;
        }
      }
    }
  }

  div[class*='ant-collapse-content'] {
    border-top: 0;

    div[class='ant-collapse-content-box'] {
      padding: 0;
    }
  }
}
