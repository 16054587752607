@import 'styles/functions';
@import 'styles/variables';

.flooringOptions {
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin: 0.5rem 0 1rem;

  padding: 20px;

  .wrapper {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .selectInput {
    padding-left: 0 !important;
  }

  .backyardToggle {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $text-color;
      margin: 0.1rem 0.75rem 0 0;
    }

    button {
      margin: 0 !important;
    }
  }
}
