@import 'styles/variables';

.commentBoxSpacing {
  [class*='ant-collapse-item-active'] {
    padding-top: 0px !important;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.threadWindow {
  background-color: white !important;
  border: unset !important;
  border-radius: unset !important;

  [class*='ant-collapse-no-arrow'] {
    [class*='ant-collapse-header'] {
      padding: 0px !important;
    }

    .popoverHeaderCommentBox {
      padding-bottom: 24px !important;
    }
  }

  [class~='ant-collapse-item'] {
    border-bottom: unset !important;
  }

  [class*='ant-collapse-content'] {
    border-top: none;
  }

  [class*='ant-collapse-content-box'] {
    padding: 0px !important;
  }

  .commentsWrapper {
    border-bottom: 1px solid $light-gray;
  }

  .commentBoxPadding {
    padding: 0px 20px 24px;
  }
}

.replyBox {
  padding: 20px 20px 0px;
}
