@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../styles.module';

.wrap {
  width: 100%;
  height: 100%;
  .formInput {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.teamheader {
  margin-bottom: setUnit(28);
  .title {
    margin-bottom: setUnit(20);
  }
  .subTitle {
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(20);
    letter-spacing: -0.09px;
    color: $text-color;
    text-align: center;
    margin-bottom: 0;
  }
}
