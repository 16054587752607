@import 'styles/functions';
@import 'styles/variables';

@import 'styles/functions';
@import 'styles/variables';

.container {
  .answerContainer {
    max-width: 496px;
  }
  .templatesBtn {
    background: none;
    border: 2px solid $light-gray;
    color: $dark-color;
    font-weight: 600;
    padding: setUnit(4) setUnit(16);
    height: setUnit(52);
    width: 100%;
    transition: $transition;
    &:hover {
      background: none;
      color: $text-color;
      border-color: $text--light-gray-color;
    }
  }
}

.modalContent {
  padding: 0 !important;
  height: setUnit(506);
}

.backBtn {
  height: setUnit(32);
  width: setUnit(32);
  cursor: pointer;
}
