@import "styles/functions";
@import "styles/variables";

.suggestion {
    display: flex;
    align-items: center;
    padding: setUnit(10);
    cursor: pointer;

    &.active {
        background: $background-ligh-gray;
    }

    &:first-child {
        padding-top: setUnit(15);
    }

    &:last-child {
        padding-bottom: setUnit(15);
    }

    .icon {
        margin-right: setUnit(10);
        svg {
            vertical-align: middle;
        }
    }
}
