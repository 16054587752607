@import "styles/functions";

.wrapper {
    width: setUnit(82);
    height: setUnit(14);

    img {
        width: setUnit(82);
        height: setUnit(14);
    }
}