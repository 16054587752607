@import 'styles/functions';
@import 'styles/variables';

.altLabel {
  font-size: setUnit(14);
  color: $border-middle-gray;
  font-weight: 400;
}

.horizontalOptions {
  display: flex;
  align-items: center;

  .selectWrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  .separator {
    margin: 0 32px;
    font-size: 16px;
    color: $text-gray-color;
    @media screen and (max-width: 700px) {
      margin: 0 12px !important;
    }
    &.error {
      padding-bottom: 25px;
    }
  }
}
