@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.pageContent {
  max-width: 480px;
  margin: 0 auto;

  .linkListContainer {
    width: 100%;

    & [class~='ant-space-item'] {
      align-self: center;
      position: relative;
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;

      .listBoxInput {
        width: 100%;
      }

      .transactionBoxInput[class~='ant-select-open'] {
        [class~='ant-select-arrow'] {
          transform: rotate(180deg);
        }
      }

      .transactionBoxInput {
        [class~='ant-select-arrow'] {
          transition: all 0.25s ease-in-out;
        }
      }

      .multipleSelectInput {
        .listBoxInput {
          [class~='ant-select-selector'] {
            [class~='ant-select-selection-search'] {
              left: 0px;
              margin-inline-start: 3px;
            }
          }

          [class~='ant-select-selection-placeholder'] {
            left: 40px;
          }
        }
      }

      .addressSelectInput {
        .addressInputIcon {
          top: 45px;
        }

        .addressSvgBtn {
          top: 45px;
        }

        .propertyAddress {
          width: 100%;
          .addressSearchWrapper {
            position: static;

            input {
              border: 1px solid $border-light-gray-2 !important;
              box-shadow: none !important;
              padding: setUnit(7) setUnit(13) setUnit(7) setUnit(44) !important;
              border-radius: 8px !important;
              font-size: setUnit(16) !important;
              margin-top: 10px !important;
              height: setUnit(48) !important;

              &:focus {
                border-color: $text--light-gray-color !important;
              }
            }

            [class~='loader'] {
              top: 55px !important;
            }
          }
        }
      }

      .svgBtn {
        cursor: pointer;

        position: absolute;
        top: 53%;
        right: 0;
        display: none;
      }

      &:hover {
        .svgBtn {
          display: block;
        }
      }
    }
  }

  .customButton {
    max-width: 400px;
    margin: 40px auto 0 auto;
    display: block;
  }
}

.transactionOptionDropdown {
  border-radius: 12px;

  :is([class~='ant-select-item-option-active'], [class~='ant-select-item-option-selected']) {
    background-color: #f5f5f5 !important;

    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }

  [class~='rc-virtual-list-holder'] {
    overflow-y: auto !important;
  }
}

.transactionOption {
  padding-top: 13px;
  padding-bottom: 0px;
  padding-left: 20px;

  [class~='ant-select-item-option-content'] {
    padding-bottom: 14px;
    border-bottom: 1px solid $background-ligh-gray-4;
  }

  .transactionOptionStatus {
    background-color: $background-grey-2;
    color: $dark-color;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.18px;
    text-align: left;
    border-radius: 100px;

    padding: 6px 7px;
    width: fit-content;

    text-transform: capitalize;
    margin-top: 3px;
  }

  &:hover {
    .transactionOptionStatus {
      border: 1px solid #ccc;
    }
  }
}
