@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.divider {
  margin: 0 20px !important;
  padding: 0 20px;
  min-width: 30% !important;
  width: 75% !important;
  border-width: 2px !important;
}

.dropdown {
  background: #ffffff;
  width: setUnit(148);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
  left: auto !important;
  right: 4px;
  ul[class~='ant-dropdown-menu'] {
    border-radius: setUnit(8);
    padding: 0;
    .option {
      padding: setUnit(14) setUnit(16);
      cursor: pointer;
      transition: $transition;
      &:hover {
        background: $light-gray;
      }
      span[class~='ant-dropdown-menu-title-content'] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > span {
          font-size: setUnit(14);
          font-weight: 400;
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.09);
          color: $text-color;
        }
        .icon {
          display: inline-flex;
          svg {
            path {
              stroke: $hover-green;
            }
          }
        }
      }
    }
  }
}

.optionIcon {
  margin-right: 10px;
  color: blue;
  display: inline-flex;
  svg {
    path {
      stroke: $dark-color;
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions {
  position: relative;
  .edit {
    @include action-icon();
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition;
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;
      }
    }
  }
}
