@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.statusItem {
  .pendingContainer {
    position: relative;
    display: flex;
    cursor: pointer;
    .pendingStatus {
      font-weight: 500;
      color: $text-color;
      text-align: right;
    }
    .lastInvite {
      font-size: 14px;
      color: $text-gray-color;
    }
    span {
      display: block;
    }
    .arrowIcon {
      margin: 0.2rem 0 0 0.2rem;
    }

    .options {
      @include box-shadow;

      position: absolute;
      top: setUnit(28);
      right: 0;
      background: $white;
      border-radius: setUnit(8);
      min-width: setUnit(180);
      padding: 0;
      z-index: 10;

      ul {
        list-style: none;
        margin-bottom: 0.5rem;
      }

      .item {
        padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
        }

        &:hover {
          background: $background-ligh-gray;
        }

        span {
          font-size: 14px;
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}

.expanded {
  padding: setUnit(14) 0;
}

.userStatusConfirmText {
  text-align: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
  }
}
