@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parties {
  max-height: calc(100vh - #{setUnit(432)});
  overflow-y: auto;
  padding-right: setUnit(32);
  @include customScrollbar;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }
  .content {
    margin-top: setUnit(18);
    .title {
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 1.5rem;
    }

    .container {
      &:not(:last-child) {
        margin-bottom: setUnit(16);
      }

      .selectorContainer {
        margin-top: 0.5rem;
        margin-left: 1.5rem;

        .selector {
          margin-bottom: 0.5rem;
          width: 100%;
        }

        & [class*='ant-select-selector'] {
          border: unset;
          border-bottom: 2px solid $border-input-middle-gray;
          box-shadow: none !important;
        }
      }

      .infoIcon {
        display: inline-block;
        position: relative;
        top: -0.45rem;
      }
    }
  }
  .footer {
    justify-content: flex-end;
  }
}
