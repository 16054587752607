@import 'styles/functions';
@import 'styles/variables';

.container {
  padding: 0 40px;
  .title {
    font-weight: 600;
    font-size: 20px;
    color: $text-color;
    margin-bottom: 20px;
  }
}
