@import 'styles/functions';
@import 'styles/variables';

.byStatus {
  // display: flex;
  // justify-content: center;

  // @media screen and (max-width: 1150px) {
  //   flex-wrap: wrap;
  //   justify-content: center;
  // }

  // @media screen and (max-width: 811px) {
  //   display: block;
  // }

  .title {
    font-size: 16px;
    margin-bottom: setUnit(24);
  }

  .statusBlock {
    padding-bottom: 0.5rem;

    @media screen and (max-width: 811px) {
      margin-right: 0;
      margin-bottom: setUnit(60);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .card {
    .actionsReverse {
      flex-direction: row-reverse;
    }
  }
}

.quoteTabs {
  width: 100%;
  padding-bottom: setUnit(32) !important;
  div[class~='ant-tabs-nav'] {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
    margin: setUnit(24) 0;
    &:before {
      content: none;
    }
    div[class~='ant-tabs-nav-wrap'] {
      justify-content: center;
      div[class~='ant-tabs-tab'] {
        .tabtitle {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.18);
          color: $text--light-gray-color;
          transition: $transition;
          .count {
            margin-left: setUnit(4);
            color: $text--light-gray-color;
          }
        }
        &[class~='ant-tabs-tab-active'] {
          .tabtitle {
            color: $text-color;
          }
        }
      }
    }
  }
}

$sell-label-color: $light-blue-2;
$buy-label-color: $green-2;
$buy-and-sell-label-color: $orange;

.transactionsList {
  .sortedColumnTitle {
    display: flex;
    align-items: center;
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.18);
    color: $text-gray-color;
    .sorterIcon {
      margin-left: setUnit(8);
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(24);
      height: setUnit(24);
      background-color: $background-grey-2;
      border-radius: setUnit(100);
      cursor: pointer;
      transform: rotate(0deg);
    }

    .sorterUp {
      transform: rotate(180deg);
    }
  }

  .tableTitle {
    font-size: setUnit(18);
    font-weight: 700;
    margin: setUnit(20) 0;
    cursor: pointer;
    width: fit-content;

    &:hover {
      color: $dark-grey-color;
    }
  }

  .countText {
    color: $text--light-gray-color;
    margin-left: setUnit(2);
  }

  .transactionsTable {
    margin-bottom: setUnit(20);

    div[class~='ant-table-content'] {
      table {
        border-radius: setUnit(16);
        background-color: #fff;
      }
    }

    [class~='ant-table'] {
      border-radius: 1rem;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
    }

    .clientField {
      display: flex;
      align-items: center;

      .avatar {
        width: setUnit(24);
        height: setUnit(24);
        font-size: setUnit(11);
        font-weight: 600;
        line-height: setUnit(26);
        letter-spacing: setUnit(-0.33);
        margin-right: setUnit(10);
        &.buy {
          border: 0;
        }
        &.sell {
          border: 0;
        }
      }
    }

    span[class~='ant-table-column-sorter-full'] {
      display: none;
    }
    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
      font-size: setUnit(14);
      &.ant-table-cell::before {
        display: none;
      }
    }

    th::before {
      display: none !important;
      width: 0;
    }

    th,
    td {
      line-height: setUnit(20);
      letter-spacing: -0.33px;
      font-size: setUnit(16);
      font-weight: 400;
      padding: setUnit(24);

      cursor: pointer;

      &:last-child {
        border-right: unset !important;
      }

      &[class~='ant-table-column-sort'] {
        background-color: transparent;
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
      font-size: setUnit(16);
    }

    th {
      & [class*='ant-table-cell']::before {
        display: none !important;
      }
    }

    th {
      &.ant-table-cell::before {
        display: none !important;
      }
    }

    tr {
      td {
        &:nth-child(2) {
          width: 150px;
        }
        &:last-child {
          width: 150px;
        }
      }
      &:last-child {
        td {
          border-bottom: unset;
          &:first-child {
            border-bottom-left-radius: 1rem;
          }
        }
      }
      &:first-child {
        th {
          border-top-left-radius: 1rem !important;
        }
      }
    }

    table {
      border: unset !important;
    }

    .title {
      font-weight: 600;
    }

    .datePrimary {
      color: $error-color;
      font-weight: 500;
    }

    .category {
      p {
        display: inline-block;
        margin-top: 0.5rem;
      }

      .actions {
        float: right;
        margin-left: 1rem;

        .optionDots {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    & [class*='ant-pagination'] {
      display: none;
    }

    .transactionsData {
      .address {
        font-weight: 600;
        line-height: setUnit(20);
        letter-spacing: -0.33px;
        margin-bottom: 0;
      }
    }

    .type {
      float: left;
      padding: 0 0.5rem 0.25rem;
      border-radius: 16px;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .closeClosingDate {
      color: $error-color;
    }

    .dateType {
      color: $text-gray-color;
    }
    .projectDate {
      display: flex;
      flex-direction: column;

      .projectDateLabel {
        color: $dark-grey-color;
      }
    }

    .roleCategory {
      margin-bottom: setUnit(3);
    }

    .status {
      text-transform: capitalize;
      color: $text-gray-color;
      margin-bottom: 0;
    }

    .notifications {
      font-size: setUnit(14);
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: left;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: setUnit(8);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .closingInfo {
        color: $error-color;
      }

      .newOffersInfo {
        color: $orange;
        svg {
          width: 16px;
          height: 16px;
          path {
            stroke: $orange;
          }
        }
      }
      .enabledInfo,
      .doubleEnabledInfo {
        color: $text-gray-color;
        svg {
          width: 18px;
          height: 18px;
          path {
            stroke: $hover-green;
          }
        }
      }

      .enabledInfo {
        path {
          width: 10px;
          height: 7px;
        }
      }
    }

    .notifications > div {
      display: flex;
      min-height: 18px;
    }

    .transaction {
      display: flex;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: setUnit(10);
        color: $dark-color;
        border-radius: 0.6rem;
        svg {
          width: 32px;
          height: 32px;
        }
      }
      .pIcon {
        border-radius: 50%;
      }

      .info {
        display: flex;
        flex-direction: column;
        .line1 {
          font-weight: 600;
        }
        .line2 {
          color: $dark-color;
          font-size: setUnit(14);
        }
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .line1 {
          font-weight: 600;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        opacity: 1;
        path {
          stroke: $dark-color;
        }
      }
    }

    @media (max-width: $mobile) {
      div[class='ant-table-content'] {
        overflow-x: scroll;
      }
    }
  }
}
