@import 'styles/functions';
@import 'styles/variables';

.containerActiveSearch {
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
  .searchName {
    font-weight: 500;
    font-size: 16px;
    margin-left: 16px;
    .inactive {
      font-size: 12px;
      margin-left: 12px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
  .footer {
    color: #747475;
    font-size: 14px;
    font-weight: 400;
  }
}
.clickable {
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    opacity: 0.95;
  }
}

.images {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: $hover-light-gray;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .noImages {
    display: flex;
    height: 100%;
    svg {
      width: 24px;
      height: 24px;
      margin: auto;
      path {
        stroke: $dark-color;
      }
    }
  }

  img {
    width: 25%;
    height: 50%;
    border-left: 1px solid transparent;
    object-fit: cover;

    &:nth-of-type(odd) {
      border-top: 1px solid transparent;
    }

    &:first-of-type {
      width: 50%;
      height: 100%;
      border: 0;
    }
  }
}

.infoBlock {
  padding: setUnit(16) setUnit(24) setUnit(12);
  background-color: $background-blue;
  color: $light-grey-color;
}

.searchTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.18px;
  margin-bottom: 4px;
}

.descInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.09px;
  color: $dark-color;
}

.emptyDescInfo {
  height: 14px;
}

.descTitle {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.1rem;
  color: $white;
  display: inline-block;
}

.descStatus {
  float: right;
  color: $white;
  font-size: 14px;
  background-color: #468bff33;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
}

.descAgentName {
  font-size: 14px;
  padding-bottom: 0.5rem;
  color: $white;
}

.descFooter {
  margin-top: setUnit(18);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
}
.menuActions {
  display: block;
  margin-left: auto;
}
