@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.schoolDrawer {
  .title {
    @include step-title;
    text-align: left !important;
    left: 30%;

    font-size: setUnit(32);
  }

  .backText {
    margin: 16px;
    display: flex;
    color: $text-color;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 24px 16px;
  }

  .leftIcon {
    margin-right: 8px;
    font-weight: 600;
  }

  .scrollWrapper {
    height: calc(100vh - #{setUnit(180)});
  }

  .wrapper {
    margin-bottom: 20px !important;
    bottom: 50px;
    height: calc(100vh - #{setUnit(20)});
    overflow-y: auto;
    padding: 0 25px;

    .subtitle {
      color: $text-color;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      text-align: justify;
    }

    .subtext {
      margin-bottom: 2px;
      font-size: 18px;
    }

    .typetext {
      margin: 3rem 0 1.5rem;
    }

    .schoolsDistrictInputWrapper {
      margin-top: 0;
    }

    .answersWrapper {
      margin: setUnit(32) 0 setUnit(24) 0 !important;
      max-width: initial !important;
    }

    .answer {
      border-radius: setUnit(8) !important;
      box-shadow: none !important;
      border: 2px solid $light-gray !important;
      cursor: pointer;
      margin-bottom: 1rem !important;

      .label {
        font-weight: 400 !important;
        color: $dark-grey-color !important;
        font-size: 18px !important;
        line-height: 24px !important;
        margin-top: -2px !important;
      }

      &:hover {
        border: 2px solid $text--light-gray-color !important;
        color: $text-color !important;
      }
    }

    .answerActive {
      background-color: $primary-color-light !important;
      border: 1px solid $primary-color !important;

      .label {
        font-weight: 500 !important;
        color: $text-color !important;
      }

      &:hover {
        border: 1px solid $primary-color-hover !important;
        background-color: #ffebed !important;
      }
    }
  }
  .drawerInner {
    position: relative;
    .tabs {
      margin: 0 setUnit(32);
      justify-content: space-between;
      border-bottom: 2px solid $light-gray;
      .tab {
        margin-right: 0;
        text-align: start;
        padding: setUnit(16) 0;
        margin-bottom: setUnit(-2);
        &:hover {
          background: none;
        }
      }
    }
  }
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  gap: setUnit(16) 0;
  & [class*='ant-checkbox-wrapper'] {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    margin-right: 0;
    & [class*='ant-checkbox'] {
      top: 10;
      & [class*='ant-checkbox-inner'] {
        border: 1.5px solid $text--light-gray-color;
        border-radius: 4px;
        width: setUnit(24);
        height: setUnit(24);
        background-color: #fff;
        &:after {
          transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
          left: 30%;
        }
      }
      & + span {
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-weight: 400;
        letter-spacing: -0.09px;
        color: $dark-grey-color;
        padding-right: 0;
      }
    }
    & [class*='ant-checkbox-checked'] {
      &:after {
        border-color: $primary-color;
      }
      & [class*='ant-checkbox-inner'] {
        background-color: $primary-color;
        border-color: $primary-color;
      }
      & + span {
        color: $text-color;
      }
    }
  }
}

.footer {
  @media (max-width: 991.99px) {
    position: fixed;
    bottom: 0px;
    max-width: setUnit(480);
    width: 100%;
  }
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.tagItemsContainer {
  display: flex;
  flex-wrap: wrap;
  .tagItems {
    margin-top: 10px;
    background-color: $primary-color-light !important;
    border: 1px solid $primary-color !important;
    border-radius: 24px;
    background-color: #f6f7f7;
    padding: setUnit(0) setUnit(6);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.18px;
  }
}
