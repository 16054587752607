@import 'styles/functions';
@import 'styles/variables';

.pageWrapper {
  background-color: $background-ligh-gray-3;
  &.bgWhite {
    background-color: $white;
  }

  .pageWrapperContainer {
    min-height: calc(100vh - $header-height - $footer-height - 2px);
    padding: 16px 0;

    div[class~='ant-table-wrapper'] {
      div[class~='ant-table-content'] {
        table {
          border-radius: setUnit(16);
          background-color: #fff;
        }
      }

      th {
        background-color: unset;
        color: $text-gray-color;
        font-weight: 600;
        font-size: setUnit(14);
        height: 55px;
        padding: 1rem 1.5rem;
        &.ant-table-cell::before {
          display: none;
        }
        span[class='ant-table-column-title'] {
          color: $text-gray-color;
        }
      }

      [class~='ant-table'] {
        border-radius: 1rem;
        box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
      }

      [class~='ant-table-column-sort'] {
        background-color: transparent;
      }

      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 1rem;
            }
          }
        }
        th {
          &:first-child {
            border-top-left-radius: 1rem !important;
          }
          &:hover {
            background-color: #fafafa;
          }
        }
        td {
          padding: 1.5rem;
        }
        td,
        th {
          border-right: unset !important;
        }
        &:hover {
          td {
            background-color: #fafafa;
          }
        }
      }
    }

    @media (max-width: $mobile) {
      padding: setUnit(20);
    }
  }

  @media (max-width: $mobile) {
    div[class='ant-table-content'] {
      overflow-x: scroll;
    }
  }
}
