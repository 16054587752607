@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.actions {
    display: flex;
    justify-content: flex-start;
}

.footer {
    margin-top: setUnit(16);
}

.headerColContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
