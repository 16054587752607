@import 'styles/functions';
@import 'styles/variables';

.container {
  max-width: 480px;
  margin-top: 42px;
  .celebrate {
    margin-bottom: 50px;
    text-align: center;
    svg {
      height: 64px;
    }
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.8px;
    span {
      display: block;
    }
  }
}
