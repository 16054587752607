@import 'styles/functions';
@import 'styles/variables';

.dateRangePickerWrapper {
  margin-top: setUnit(24);
  margin-bottom: setUnit(24);
  margin-left: setUnit(32);
  margin-right: setUnit(32);

  & [class*='ant-typography'] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }
}

.colContainer {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;

  .subtitle {
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
  }
}
.dateRangeToText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: -0.18px;

  /* REBRAND/Grayscale/40 */
  text-transform: lowercase;
  color: #aaabab;
}
.datepicker {
  width: 100%;
  & [class*='ant-picker-input'] {
    ::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: setUnit(16);
    }
  }
}

.icon {
  background: $background-grey-2;
  border-radius: setUnit(100);
  display: flex;
  align-items: center;
  justify-content: center;
  width: setUnit(24);
  height: setUnit(24);
}
