.chartContainer {
  width: 100%;

  div[class~='apexcharts-canvas'] {
    width: 100% !important;

    svg {
      width: 100% !important;
      foreignObject {
        width: 100% !important;
      }
    }
  }
}
