@import 'styles/functions';
@import 'styles/variables';

.copyrightWrap {
  margin-right: setUnit(12);
  .copyrightSwitch {
    background: $white;
    box-shadow: 0 setUnit(8) setUnit(16) rgba(61, 61, 61, 0.1);
    border-radius: setUnit(8);
    padding: setUnit(10);
    display: block;
    .icon {
      display: flex;
      svg {
        width: setUnit(24);
        height: setUnit(24);
        path {
          stroke: $text-color;
        }
      }
    }
  }
  .tooltipContent {
    div[class~='ant-popover-inner'] {
      box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);
      border-radius: setUnit(8);
      .content {
        font-weight: 400;
        font-size: setUnit(12);
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        color: $light-black;
        margin-bottom: 0;
      }
    }
  }
}
