@import 'styles/functions';
@import 'styles/variables';

.aside {
  background: url('../../../images/pattern-mobile.png') no-repeat center/cover;
  width: 100%;
  height: setUnit(100);
  @media (min-width: $tablet) {
    background: url('../../../images/pattern.png') no-repeat center/cover;
    width: setUnit(320);
    height: 100vh;
  }
}
