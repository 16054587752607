@import 'styles/functions';
@import 'styles/variables';

.headerTitle {
  display: flex;
  justify-content: center;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.newKitModal {
  .content {
    height: 500px;
    overflow-y: auto;
    padding: setUnit(24);

    .name,
    .category,
    .frequency,
    .propertyTypes,
    .inAppReminder,
    .eligibleLocations,
    .restrictedLocations {
      margin-bottom: setUnit(16);

      .sectionLabel {
        margin-right: setUnit(16);
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: setUnit(-0.18);
        color: $text-color;
      }

      .error {
        color: $hover-red;
      }

      .selector {
        width: setUnit(288);
      }
    }

    .createButton {
      display: flex;
      justify-content: center;
      align-items: center;

      .submitButton {
        margin-top: 30px;
        height: 56px;
        width: 277px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
  }
}

.reminderEditor {
  [class*='ql-editor'] {
    min-height: 130px !important;
  }
}

.errorText {
  color: red;
  font-size: 10px;
}
.allowedVariables {
  margin-top: 10px;
  .allowedVariablesInner {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
  .inputFieldWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .inputField {
    .ant-input {
      border-radius: 20px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
      flex: 1;
    }
  }

  .iconButton {
    &.remove {
      background-color: transparent;
      color: #ff4d4f;
      border: none;

      &:hover {
        color: #ff7875;
        background-color: transparent;
        border-color: transparent;
      }
    }

    &.add {
      background-color: transparent;
      color: #00995e;
      border: none;
      padding: 0;
      margin-top: 5px;

      &:hover {
        background-color: transparent;
        color: #00995e;
        border-color: transparent;
      }
    }
  }
}
