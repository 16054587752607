@import "styles/variables";
@import "styles/functions";

.sliderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumb {
    cursor: pointer;
    width: setUnit(29);
    height: setUnit(29);
    border-radius: 50%;
    box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);
    top: setUnit(-10);
    z-index: 99;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.value {
    text-align: center;
    margin-bottom: setUnit(20);
    font-weight: 400;
}

.sliderHolder {
    width: setUnit(400);
}
