@import 'styles/functions';
@import 'styles/variables';

.details {
  .attachments {
    .block {
      margin-bottom: setUnit(40);
    }
  }
}
