@import 'styles/functions';
@import 'styles/variables';

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: setUnit(32);
}

.searchButton {
  width: setUnit(46);
  height: setUnit(46);
  margin-left: setUnit(32);
  background-color: $background-ligh-gray !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(24);

    path {
      stroke: $text-color;
    }
  }
}

.input {
  max-width: setUnit(200);
}

.tableWrapper {
  overflow-x: auto;
}

.tableText {
  font-size: setUnit(15);
  font-weight: 400;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
}
