@import "styles/functions";
@import "styles/variables";

.archiveModal {
    .inner {
        padding: setUnit(40) setUnit(32) setUnit(32);
        overflow: visible;
    }

    .content {
        border-radius: setUnit(24);
        width: 100%;
        max-width: setUnit(370);
        overflow: visible;
    }

    .icon {
        width: setUnit(56);
        height: setUnit(56);
        background: $background-ligh-gray;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        svg {
            width: setUnit(30);
            height: setUnit(30);
            vertical-align: middle;
        }
    }

    .title {
        font-size: setUnit(20);
        font-weight: 600;
        text-align: center;
        margin-bottom: setUnit(24);
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: setUnit(32);

        .btn {
            margin-left: setUnit(10);
            width: setUnit(137);
            height: setUnit(39);

            &.cancel {
                background: $background-ligh-gray;
                color: $text-color;

                &:hover {
                    background: darken($background-ligh-gray, 5);
                }
            }

            &.yes {
                font-weight: 600;

                .loader {
                    font-size: setUnit(3);
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
