@import 'styles/variables';

.groupingPane {
  height: 100%;

  [class*='ant-select-selection-overflow-item-suffix'] {
    min-width: 5px !important;
  }

  .groupingHeader {
    font-weight: 600;
  }
  .fieldSelect {
    [class*='ant-select-selection-item'] {
      align-items: center;
    }
  }

  .clearText {
    width: 100%;
    text-align: right;
    display: block;
    margin: 2px 0px 5px 0px;
    color: $link-blue;
    &:hover {
      cursor: pointer;
    }
  }

  .label {
    color: $text-color;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .toggleRow {
    margin-top: 44px;
    align-items: center;
  }

  .formRow {
    margin-top: 24px;
  }

  .checkBoxIcon {
    width: 24px !important;
    height: 24px !important;
  }

  .questionTypeOption {
    display: flex;
    align-items: center;

    .questionLogo {
      margin-right: 10px;
      display: flex;
    }
  }

  .selectBox {
    width: 100%;
    margin-top: 5px;

    [class*='ant-select-item-option-content'] {
      display: flex;
      align-items: center;
      flex-direction: row;

      div {
        margin-right: 5px;
      }
    }

    [class*='ant-select-item-option-state'] {
      display: flex;
      align-items: center;

      svg {
        fill: $green-color;
      }
    }

    [class*='ant-select-selection-overflow'] {
      max-height: 200px;
      overflow-y: auto;
    }

    .pdfIcon {
      display: flex;
    }
  }
}

.groupingTag {
  overflow: hidden;
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: $white !important;
  padding: 4px 8px !important;
  margin: 3px !important;
  align-items: center !important;
  display: flex !important;
}

.tagRender {
  .pdfIcon {
    display: none !important;
  }
}
