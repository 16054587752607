@import 'styles/functions';
@import 'styles/variables';

.limitedLinesContainer {
  display: flex;
  align-items: center;

  .tooltipContainer {
    margin-left: setUnit(10);
    margin-bottom: setUnit(4);
  }
}
