@import 'styles/functions';
@import 'styles/variables';

.agentFeedWrapper {
  position: relative;
  z-index: 1;
  height: calc(100dvh - 80px);
  overflow: hidden;

  .mapSwitchers {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    display: flex;
    gap: 10px;
  }
}

.agentFeed {
  .backdropBlur {
    position: absolute;
    width: 100%;
    height: calc(100vh - 11.2rem);
    z-index: 100;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
  }

  .propertiesContainer {
    height: calc(100vh - 11.2rem);
    overflow-y: scroll;

    &:hover {
      scrollbar-width: auto;
    }
  }
  .mapContainer {
    height: calc(100vh - 11.2rem);
  }
}
