@import 'styles/functions';
@import 'styles/variables';

.messages {
  .innerHolder {
    padding: setUnit(16) 0 0 0;
    min-height: calc(100% - #{setUnit(40)});
  }

  .innerHolderPadd {
    padding: setUnit(16) 0 17px 0 !important;
  }
  .message {
    margin-top: setUnit(2);
    margin-bottom: setUnit(2);
  }
}

.messagesTransactionPane {
  height: setUnit(204);
  min-height: setUnit(204);
}
