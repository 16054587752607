@import 'styles/functions';
@import 'styles/variables';

.description {
  font-weight: 400;
  font-size: setUnit(12);
  line-height: setUnit(16);
  letter-spacing: setUnit(-0.09);
  color: $dark-color;
  margin-bottom: setUnit(20);
}
