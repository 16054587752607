@import 'styles/functions';
@import 'styles/variables';

.splitItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.disabled {
    opacity: 0.3;
  }

  &.splitItemEdit {
    &:hover {
      .editIcon {
        display: flex;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
  }

  .editIcon {
    position: absolute;
    cursor: pointer;
    top: setUnit(-4);
    right: setUnit(0);
    width: 55px;
    display: none;
    align-items: center;
    justify-content: flex-end;

    .fadeEffect {
      opacity: 0.5;
      width: 25px;
      height: 38px;
      background: linear-gradient(90deg, rgba(252, 252, 252, 0) 0%, #fcfcfc 32.35%);

      &.largeHeight {
        height: 50px;
      }
    }

    .icon {
      display: flex;
      background-color: #fffcfa;
    }
  }
  .labelContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .labelTitleContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .label {
      font-size: setUnit(16);
      font-weight: 500;
      margin: 0;
    }
    .icon {
      display: flex;
    }
  }

  .amounts {
    display: flex;
    align-items: center;
    gap: setUnit(5);

    .amount,
    .percentage {
      font-size: setUnit(16);
      position: relative;
      padding-bottom: 5px;
    }

    .amount {
      font-weight: 600;
    }

    .percentage {
      color: $dark-color;
    }
  }
}
