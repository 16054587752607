@import 'styles/variables';

.highlightField {
  div > div:nth-child(5),
  div > div:nth-child(6),
  div > div:nth-child(7),
  div > div:nth-child(8) {
    &::before {
      content: '\f111';
      width: 10px;
      height: 10px;
      position: absolute;
      background: white;
      border: 1px solid black;
      border-radius: 100%;
      color: transparent;
      right: 5px;
      bottom: 5px;
    }
  }
}
