@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.footContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  &.justifyBetween {
    justify-content: space-between;
  }

  & :first-child {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.18px;
  }
}

.backBtn {
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 12%;
  left: 40px;
  cursor: pointer;

  &:hover {
    border: 1.5px solid rgba(255, 255, 255, 0.6);
  }
}

.inputGroups {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .inputContainer {
    display: flex;
    column-gap: 17px;

    .input {
      position: relative;

      label {
        color: rgba(255, 255, 255, 0.9);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }

      input {
        background: transparent;
        color: $white;

        border-radius: 8px;
        border: 1px solid rgba(78, 83, 107, 0.8);
        background: rgba(255, 255, 255, 0);

        &:focus {
          border-radius: 8px;
          border: 1px solid #a0a3b0;
          background: rgba(255, 255, 255, 0);
          box-shadow: 0px 0px 0px 3px rgba(224, 229, 233, 0.12);
        }
      }
    }

    .error {
      position: absolute;
      bottom: -18px;
      left: 0;

      font-size: 12px;
      line-height: 16px;
    }
  }
}

.selectorWrapper {
  width: 100%;
  margin-bottom: 24px;
  position: relative;

  [class~='ant-select-selection-search-input'] {
    max-width: 80%;
    text-overflow: ellipsis;
  }

  &.inActive {
    [class~='ant-select'] {
      [class~='ant-select-selector'] {
        background: #3f4453 !important;
      }
    }
  }

  .error {
    position: absolute;
    bottom: -18px;
    color: #ec455e;
    font-size: 12px;
    line-height: 16px;
  }

  .actionBtns {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 20px;
    display: flex;
    align-items: center;
    column-gap: 16px;

    .iconBtn {
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.08);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
    }
  }

  [class~='ant-select'] {
    width: 100%;

    [class~='ant-select-selector'] {
      background: transparent !important;

      border-radius: 8px !important;
      border: 1px solid rgba(78, 83, 107, 0.8) !important;
      padding: 14px 16px !important;
      height: initial !important;

      [class~='ant-select-selection-placeholder'] {
        color: rgba(170, 171, 171, 0.8);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;

        display: flex;
        align-items: center;
      }

      [class~='ant-select-selection-search'] {
        display: flex;
        align-items: center;
        color: white;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
      }
    }
  }
}

.addAnother {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.18px;
}

.dropdownWrapper {
  border-radius: 10px !important;

  [class~='ant-select-item'] {
    padding: 16px 24px !important;
  }
}
