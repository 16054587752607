@import 'styles/functions';
@import 'styles/variables';

.byUser {
  .content {
    height: calc(100vh - #{setUnit(199)});
    overflow: auto;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .head {
    display: flex;
    align-items: center;
    padding: 0 setUnit(32) setUnit(24) setUnit(32);
    border-bottom: 1px solid $border-light-gray;
    .name {
      margin-left: setUnit(16);
      font-weight: 600;
      font-size: setUnit(20);
    }
    .avatarPlaceholder {
      font-size: setUnit(30);
    }
  }
}
