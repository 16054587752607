@import 'styles/functions';
@import 'styles/variables';

.headerWrap {
  margin: setUnit(32);
  .header {
    margin-bottom: setUnit(20);
    .goBack {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      padding: setUnit(12) 0;
      .icon {
        margin-right: setUnit(4);
        display: flex;
        svg {
          width: setUnit(8);
        }
      }
      .text {
        font-size: setUnit(14);
        font-weight: 500;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        color: $text-color;
      }
    }
  }
  .subHeader {
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(26);
    letter-spacing: setUnit(-0.33);
    color: $text-color;
    margin-bottom: 0;
  }
}
