@import 'styles/functions';
@import 'styles/variables';

.headerArea {
  width: 100%;
  padding: 46px 0px 24px;
  margin: 0 auto;
  background-color: $white;

  .headWrapper {
    display: block;
    text-align: center;
    width: 100%;

    .heading {
      font-weight: 700;
      font-size: setUnit(28);
      color: $text-color;
      margin-bottom: 24px;
      line-height: 24px; /* 85.714% */
      letter-spacing: -0.18px;
    }

    .addressTitle {
      font-size: setUnit(20);
      color: $text-color;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
    }

    .listedByDescripion {
      font-size: setUnit(18);
      color: $text-color;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
  }

  .agentInfo {
    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.18px;
      color: #fff;
      margin-bottom: setUnit(4);
    }
  }
  .content {
    list-style-type: none;
    margin-bottom: 0;
    li {
      font-weight: 400;
      font-size: setUnit(14);
      line-height: 16px;
      letter-spacing: -0.18px;
      color: #fff;

      span {
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: setUnit(4);
      }
    }

    .listingPrice {
      margin-top: 12px;
    }
  }
}
