@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.transactionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;

  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $light-black;
  }

  .switch {
    height: 24px;
    width: 44px;

    [class*='ant-switch-handle'] {
      top: 2px;
      left: 3px;
      width: 20px;
      height: 20px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  [class*='ant-switch-checked'] {
    background-color: $primary-color;

    [class*='ant-switch-handle'] {
      left: calc(100% - 20px - 3px) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
