@import 'styles/functions';
@import 'styles/variables';

.name {
  display: flex;
  align-items: center;
  .icon {
    width: setUnit(32);
    height: setUnit(32);
  }
  .info {
    span {
      padding-left: setUnit(10);
      font-size: setUnit(16);
      font-weight: 600;
    }
  }
}

.client {
  display: flex;
  align-items: center;
  .icon {
    width: setUnit(24);
    height: setUnit(24);
  }
  .info {
    span {
      padding-left: setUnit(10);
      font-size: setUnit(16);
      font-weight: 400;
    }
  }
}

.dateWrapper {
  .contentFirstRow {
    font-weight: 500;
  }
  .contentSecondRow {
    text-align: center;
    color: $text-gray-color;
  }
}

.signBtn {
  width: setUnit(70);
  height: setUnit(40);
  border-radius: 12px;
  padding: setUnit(10) setUnit(20);
  span {
    font-size: setUnit(14);
    font-weight: 600;
  }
}
