@import 'styles/variables';

.questionContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .questionIcon {
    display: flex;
    margin-right: 10px;
  }
  .questionText {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: $error-color;
  }
}
