@import 'styles/functions';
@import 'styles/variables';

.propsCompareTable {
  .propsCompareRow {
    &Even {
      background-color: $background-grey-2;
      border-radius: setUnit(32);
    }

    .column {
      width: setUnit(224);
      min-width: max-content;
      padding-top: setUnit(12);
      padding-bottom: setUnit(12);
      vertical-align: top;
      overflow-wrap: anywhere;

      &.Two {
        &:not(:first-child) {
          @media screen and (min-width: $desktop) {
            width: setUnit(410);
          }
        }
      }

      .divBlock {
        display: block;

        .multiItem {
          margin-bottom: setUnit(5);
        }

        .text {
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: -0.09px;

          @media screen and (max-width: $mobile) {
            font-size: setUnit(14);
          }
        }

        .multiItem {
          margin-bottom: setUnit(5);
        }

        .text {
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: -0.09px;

          @media screen and (max-width: $mobile) {
            font-size: setUnit(14);
          }
        }
      }

      .child {
        &Heading {
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;

          @media screen and (max-width: $mobile) {
            font-size: setUnit(14);
          }

          @media screen and (max-width: $mobile) {
            font-size: setUnit(14);
          }
        }

        &Cell {
          margin-top: setUnit(10);
          display: block;
        }
      }

      div {
        display: flex;

        svg {
          cursor: pointer;
          margin-right: setUnit(10);
        }
      }

      &Heading {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;

        @media screen and (max-width: $mobile) {
          font-size: setUnit(14);
        }

        @media screen and (max-width: $mobile) {
          font-size: setUnit(14);
        }
      }

      &:first-child {
        padding-left: setUnit(16);
        border-top-left-radius: setUnit(8);
        border-bottom-left-radius: setUnit(8);
      }

      &:last-child {
        border-top-right-radius: setUnit(8);
        border-bottom-right-radius: setUnit(8);
      }
    }
  }
}
