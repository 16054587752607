@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  position: relative;

  .actionButton {
    @include action-icon();
    border-radius: 50%;
    width: setUnit(32);
    height: setUnit(32);
    position: relative;
    font-size: setUnit(20);
    outline: none;
    border: none;
    cursor: pointer;

    .dots {
      line-height: 0;
      position: absolute;
      top: setUnit(9);
      left: setUnit(5.5);
      font-size: setUnit(26);
    }
  }

  .popup {
    @include box-shadow;

    position: absolute;
    top: setUnit(35);
    right: 0;
    width: setUnit(176);
    height: setUnit(80);
    background: #ffffff;
    z-index: 99;
    border-radius: setUnit(8);

    .list {
      list-style: none;

      .item {
        cursor: pointer;
        height: setUnit(40);
        display: flex;
        align-items: center;
        padding: 0 setUnit(16);
        font-size: setUnit(14);
        font-weight: 400;

        .text {
          margin-left: setUnit(10);
        }

        svg {
          vertical-align: middle;
        }

        &:hover {
          background: $hover-light-gray;
        }
      }
    }
  }
}
