@import 'styles/functions';
@import 'styles/variables';

.searchFilterBody {
  width: 370px;
  border-radius: 8px !important;
  background: $white;
  font-family: $font-family;
  font-style: normal;

  & [class*='ant-form-item-label'] {
    color: $text-color !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  & [class*='ant-form-item-control-input-content'] > input {
    padding: 8px;
    height: 48px;
    border-radius: 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
  }
}

.formFilter {
  & [class*='ant-form-vertical'] {
    margin-top: 24px;
  }

  padding: 0px 24px;
  overflow-y: auto;
  max-height: calc(100vh - 176px);
  min-height: 150px;
}

.filterFormHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.filterFormReset {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $link-blue;
}

.checkBoxIcon {
  width: 24px !important;
  height: 24px !important;
}

.applyFiltersButton {
  width: 100% !important;
  height: 48px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 24px;
}

.formButtonRow {
  margin-bottom: 0px !important;
}

.header {
  padding: 24px 24px;
}
