@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: 100%;
  min-height: calc(100vh - $header-height - $footer-height);
  color: $text-color;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $background-ligh-gray-3;
}

.contentContainer {
  min-height: calc(100vh - $header-height - $footer-height - 2px);
  margin: 0 auto;
  padding: setUnit(40) 0;
  display: flex;
  flex: auto;
}
@media (min-width: 476px) {
  .contentContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .contentContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .contentContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .contentContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .contentContainer {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .contentContainer {
    max-width: 720px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .contentContainer {
    max-width: 960px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .contentContainer {
    max-width: 1140px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .contentContainer {
    max-width: 1340px;
    width: 100%;
  }
}
