@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.footer {
  display: flex;
  justify-content: space-between;

  .reschedule {
    font-size: setUnit(14);
    font-weight: 500;
    height: setUnit(44);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(16);
    border-radius: setUnit(10);
    cursor: pointer;
    background-color: transparent;
    color: $secondary-color;
    margin-bottom: 15px;
    svg {
      path {
        fill: $secondary-color;
        stroke-width: 1px;
        stroke: $secondary-color;
      }
    }

    &:hover {
      border: 2px solid $secondary-color;
    }
  }

  .rescheduleIcon {
    margin-right: setUnit(8);
    position: relative;
    left: setUnit(-2);

    svg {
      vertical-align: middle;
      width: setUnit(20);
      height: setUnit(20);
    }
  }

  .cancel {
    font-size: setUnit(14);
    font-weight: 500;
    height: setUnit(44);
    display: flex;
    align-items: center;
    padding: 0 setUnit(16);
    border-radius: setUnit(10);
    cursor: pointer;
    gap: 8px;
    background-color: transparent;
    color: $error-color;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    svg {
      path {
        fill: $error-color;
        stroke-width: 1px;
        stroke: $error-color;
      }
    }

    &:hover {
      border: 2px solid $error-color;
    }
  }

  .approve {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.09px;
    cursor: pointer;
    width: 200px;
    height: 52px;
    justify-content: center;
    gap: 8px;
    color: $hover-green;
    box-sizing: border-box;
    border: 2px solid $hover-green;
    border-radius: 8px;

    &:hover {
      border: 3px solid $hover-green;
    }
  }

  .decline {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.09px;
    width: 200px;
    height: 52px;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
    color: $error-color;
    box-sizing: border-box;
    border: 2px solid $error-color;
    border-radius: 8px;
    overflow: hidden;
    top: 817px;

    &:hover {
      border: 3px solid $error-color;
    }
  }

  .cancelIcon {
    margin-right: setUnit(8);
    position: relative;
    left: setUnit(-2);

    svg {
      vertical-align: middle;
      width: setUnit(20);
      height: setUnit(20);
    }
  }
}

.appointmentDisclaimer {
  text-align: center;
  color: $primary-color;
}
