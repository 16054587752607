@import 'styles/variables';

.historyContent {
  .marginLeft {
    margin-left: 32px;
  }
  .user {
    display: flex;
    align-items: center;

    .userProfile {
      width: 60%;
      display: flex;
      align-items: center;
    }

    .userAvatar {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }

  .accordionUser {
    @extend .user;
    justify-content: space-between;

    .time {
      margin-right: 25px;
    }
  }

  .contentRow {
    margin-bottom: 12px;
  }

  .contentRowSimple {
    @extend .contentRow;
    justify-content: space-between;
  }

  .buttonDot {
    background: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.09px;
    color: $text-color;
  }

  .simpleHeading {
    @extend .heading;
    width: 80%;

    span {
      line-height: 18px;
    }
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: right;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.09px;
    color: $text-gray-color;
  }

  .historyPanel > div:nth-child(2) > div {
    padding-top: 13px !important;
    padding-bottom: 1px !important;
    padding-right: 16px !important;
    padding-left: 42px !important;
  }

  @media only screen and (max-width: $mobile) {
    .buttonDot {
      justify-content: flex-end;
    }
  }

  .updatedValues {
    color: $text-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;

    .previousValue {
      max-width: 50%;
      overflow: auto;
      margin-left: 8px;
      color: $text--light-gray-color;
      text-decoration: line-through;
    }

    .newValue {
      max-width: 50%;
      overflow: auto;
      margin-left: 8px;
    }
  }
}
