@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.pageContent {
  max-width: 400px;
  margin: 0 auto;
}
