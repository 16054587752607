@import 'styles/functions';
@import 'styles/variables';

.needsAttentionContent {
  display: flex;
  justify-content: space-around;
  overflow: auto;
  padding-right: 5px;
  min-height: 0px;
  max-height: 438px;
  width: 100%;

  .stackColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 440px;
    margin: 0px 4px;

    .stackHeaderItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 440px;
      width: 100%;
      height: 80px;
      position: sticky;
      top: 0;
      background: #ffffff;

      .stackIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }

      & span {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.33px;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .stackItem {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 80px;
      border-radius: 8px;
      font-family: 'Inter';
      letter-spacing: -0.33px;
      text-align: center;
      margin-bottom: 8px;
      padding: 10px;

      .stackValue {
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .stackKey {
        font-size: 13px;
        font-weight: 600;
        line-height: 14px;
      }
    }
    .clickable {
      cursor: pointer;
    }
  }

  .flexCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .checkMark {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #04a45114;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    & p {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.33px;
      text-align: center;
      color: #aaabab;
    }
  }
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.pendingWrapper {
  height: 360px;
  display: flex;
  justify-content: center;
}

.stackHeaderRow {
  position: absolute;
  height: 80px;
  width: 101%;
  background: #ffffff;
}

.minHeight {
  height: 240px;
}
.noDataWrapper {
  height: 360px;
}

.cardBody {
  position: relative;
}

.cardHeader {
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(24);
    letter-spacing: -0.33px;
    color: #262626;
  }
}
