@import 'styles/functions';
@import 'styles/variables';

.headerTabs {
  div[class~='ant-tabs-nav-wrap'] {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
    justify-content: center;
  }

  div[class~='ant-tabs-content-holder'] {
    background-color: $background-ligh-gray-3;
    padding: 24px 24px 24px 24px;
  }

  div[class~='ant-tabs-tab'] {
    display: flex;
    width: 140px;
    height: 56px;
    justify-content: center;
  }

  div[class~='ant-tabs-content'] {
    height: calc(100dvh - 80px - 96px - 56px - 48px);
    min-height: fit-content;
  }
}

.mainContentWrapper {
  max-width: 100vw;
  background-color: $background-ligh-gray-3;
  padding: 16px 40px;

  @media screen and (max-width: $mobile) {
    padding: 16px 12px;
  }
}
