@import 'styles/variables';
@import 'styles/functions';

.basicPlanCreate {
  padding: 0 40px;
  .head {
    flex: 0 0 100%;
    margin: 34px 0 54px;
  }
}
