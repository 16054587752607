@import 'styles/functions';
@import 'styles/variables';

.modalContent {
  max-width: 420px !important;
  width: 100%;
  background: $white;
  box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);
  border-radius: setUnit(24);
  padding: 40px 40px 32px;

  .modalContentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .modalContentAvatar {
    position: relative;
    width: setUnit(56);
    height: setUnit(56);
    margin-bottom: setUnit(24);

    img {
      width: setUnit(56);
      height: setUnit(56);
      border-radius: 100%;
      object-fit: cover;
    }

    .modalContentAvatarRecommended {
      width: setUnit(32);
      height: setUnit(32);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      position: absolute;
      bottom: -#{setUnit(7)};
      right: -#{setUnit(7)};

      svg {
        width: setUnit(21);
        height: setUnit(21);
      }
    }
  }

  .modalContentTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $text-color;
    margin-bottom: 12px;
  }

  .modalContentDesc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.33px;
    color: $dark-color;
    text-align: center;
    margin-bottom: setUnit(32);
  }

  .modalContentButtons {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0 24px;

    button {
      border-radius: setUnit(8);
      height: setUnit(52);
      display: block;
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: 600;
      flex: 1;
      transition: $transition;
    }

    .remove {
      background-color: $error-color;
      &:hover {
        background-color: $primary-color-hover;
      }
    }

    .cancelButton {
      font-size: setUnit(16);
      line-height: setUnit(21);
      color: $text-color;
      background-color: $background-grey-2;
      font-weight: normal;

      &:hover {
        background-color: $light-gray;
      }
    }

    .yesButton {
      background-color: $background-blue;
      &:hover {
        background-color: $secondary-color-hover;
      }
    }
  }
}
