@import 'styles/functions';
@import 'styles/variables';

.noPartners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem auto 0;

  .noPartnersText {
    margin-top: 1.25rem;
    color: $text--light-gray-color;
    font-size: 16px;
  }
}

.wrapper {
  padding: 0 setUnit(15);
}

.partners {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.requestQuoteWrapper {
  position: fixed;
  bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  left: 0;
  background: $white;
  justify-content: center;

  & > div {
    max-width: 612px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actionBtn {
      padding: 16px 32px;
      height: initial !important;
      border-radius: 8px;
      margin-left: auto;

      color: var(--REBRAND-Secondary-White, $white);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
    }
  }

  .selectedAvatar {
    width: 60px;
    height: 60px;
  }

  .flex {
    display: flex;
    width: 100%;
  }
}

.recommendStar,
.multipleCheckbox {
  position: absolute;
  top: setUnit(24);
  right: setUnit(24);
  width: setUnit(24);
  height: setUnit(24);

  svg {
    width: setUnit(24);
    height: setUnit(24);
  }
}

.block {
  margin-bottom: setUnit(40);

  .title {
    color: $text-color;
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(20);
    margin-bottom: setUnit(12);
  }

  .blockItems {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: setUnit(44);
  }
}

.card {
  .cardContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;
    box-shadow: 0 setUnit(2) setUnit(16) rgba(48, 48, 48, 0.1);
    border-radius: setUnit(16);
    min-height: 112px;
    text-decoration: none;
    transition: 0.1s all;
    padding: setUnit(24);
    position: relative;

    @media screen and (max-width: $mobile-small) {
      flex-direction: column;
    }

    .infoWrapper {
      display: flex;
      column-gap: 16px;
      align-items: center;

      .details {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        cursor: pointer;

        .cardTitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: $text-color;
          text-decoration: none;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .cardDescription {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          .partnerInfoDesc {
            color: $dark-color;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.09px;
            max-width: 449px;
            display: flex;
            column-gap: 4px;
            flex-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .descTitle {
              display: flex;
              align-items: flex-start;
              column-gap: 4px;
            }

            .itemsWrapper {
              display: flex;
              align-items: center;
              column-gap: 8px;
            }

            .servicesOffered {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 225px;
              display: inline-block;
            }
          }
        }
      }

      .cardImage {
        font-size: setUnit(20);
        line-height: setUnit(26);
        color: $text-color;
        min-width: 64px;
        height: 64px;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 64px;

        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
        }
      }
    }

    .cardButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
      @media (min-width: $mobile) {
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 0;
        margin-left: 16px;
      }

      .messages {
        display: inline-flex;
        padding: 8px 12px 8px 10px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 20px;
        border: 2px solid $background-ligh-gray-4;
        background: $white;
        color: $text-gray-color;
        height: initial !important;

        @media screen and (max-width: $mobile-small) {
          margin-top: setUnit(10);
        }

        div {
          display: flex;
          align-items: center;
          column-gap: 4px;

          svg {
            height: 24px;
            width: 24px;
            color: $dark-color;
          }

          span {
            color: $dark-color;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: -0.18px;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .cardButtonRequest {
        box-sizing: border-box;
        border-radius: setUnit(8);
        height: setUnit(40);
        font-weight: 500;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-color;

        transition: all 0.1s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
