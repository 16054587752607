@import 'styles/functions';
@import 'styles/variables';

.signConfirmationDialog {
  .signConfirmationInnerHolder {
    max-height: unset;
  }
}

.missingFormRolesModal {
  text-align: center;

  .heading {
    font-size: setUnit(20);
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;

    margin-bottom: 12px;
  }

  .list {
    .listItem {
      font-size: setUnit(16);
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.33px;
      text-align: center;
    }

    margin-bottom: 12px;
  }

  .bottomText {
    color: $dark-color;
    margin-bottom: 32px;

    font-size: setUnit(16);
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.33px;
  }
}
