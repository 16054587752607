@import 'styles/functions';
@import 'styles/variables';

.schoolOptions {
  border: 2px solid $light-gray;
  border-radius: 8px;
  margin: 0.5rem 0 1rem;
  padding: 20px;

  .wrapper {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .selectInput {
    padding-left: 0 !important;
  }

  .yesCheckbox {
    margin-right: 1.5rem;
  }

  .specificSchoolHeading {
    margin-top: 1.5rem;
  }

  .schoolTypesHeading {
    margin: 1.5rem 0 0;
  }

  [class*='ant-checkbox'] {
    [class*='ant-checkbox-inner'] {
      width: setUnit(24);
      height: setUnit(24);
      border-radius: setUnit(100);
      &:after {
        width: setUnit(6);
        height: setUnit(12);
        left: 25%;
      }
    }
  }

  [class*='ant-checkbox-checked'] {
    &::after {
      border-color: $primary-color;
      border-radius: 100%;
    }

    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color !important;
    }
  }
}
