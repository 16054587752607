@import "styles/functions";
@import "styles/variables";

.tooltip {
    box-shadow: setUnit(6) setUnit(6) setUnit(22) rgba(#000000, 0.15);

    border-radius: setUnit(16);
    padding: setUnit(12) setUnit(12);
    background: #ffffff;
    line-height: setUnit(22);
    min-width: setUnit(220);
    max-width: setUnit(600);
}
