@import "styles/functions";
@import "styles/variables";

.links {
    margin-top: setUnit(40);
    padding-bottom: setUnit(54);

    .add {
        width: fit-content;
        display: flex;
        align-items: center;
        margin-top: setUnit(24);
        color: $secondary-color;
        cursor: pointer;

        .text {
            margin-left: setUnit(16);
        }

        svg {
            vertical-align: middle;
        }
    }

    .inputWrapper {
        position: relative;
        margin-top: setUnit(40);

        .removeIcon {
            position: absolute;
            right: 0;
            bottom: setUnit(5);
            cursor: pointer;
            transition: all .1s ease;

            &:hover {
                transform: scale(1.1);
            }
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
