.card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding: 24px;
  height: fit-content;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;

    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.33px;

    .addBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      color: var(--Secondary-Link-Blue, #2b59da);
      font-size: 14px;
      line-height: 16px; /* 114.286% */
      .iconWrapper {
        display: flex;
        justify-self: center;
        align-items: center;
      }
    }
  }

  .cardContent {
    .emptyCard {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 124px;
      color: var(--Grayscale-40, #aaabab);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: -0.33px;
    }
  }
}
