@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .closeClientDrawerIcon,
  .expandClientDrawerIcon {
    cursor: pointer;
    height: 40px;
    width: 40px;
    margin-left: 1rem;
    background-color: $background-grey-2;
    border-radius: 50%;
    padding: 0.5rem;

    &:hover {
      background-color: $light-gray;
    }
  }

  .button {
    width: setUnit(40);
    height: setUnit(40);
    font-size: setUnit(20);
    font-weight: 600;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;

    .dots {
      position: relative;
      top: setUnit(-5);
    }
  }

  .popup {
    @include box-shadow;

    position: absolute;
    z-index: 99;
    top: setUnit(42);
    right: 0;
    width: setUnit(196);
    display: none;
    background: #ffffff;
    border-radius: setUnit(8);

    &.open {
      display: block;
    }

    .list {
      list-style: none;

      .action {
        padding: setUnit(14) setUnit(16);
        font-size: setUnit(14);
        font-weight: 400;
        cursor: pointer;

        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
