@import 'styles/variables';
@import 'styles/functions';

.activityPage {
  background-color: $background-ligh-gray-3;
  .wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}
