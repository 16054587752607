@import 'styles/functions';
@import 'styles/variables';

.metaItem {
  display: flex;
  align-items: center;
  margin-top: setUnit(19);

  .value {
    word-break: break-all;
    line-height: setUnit(22);
  }

  .icon {
    width: setUnit(24);
    margin-right: setUnit(20);

    svg {
      vertical-align: middle;
    }
  }
}
