@import 'styles/functions';
@import 'styles/variables';

.dropdown {
  background-color: $background-grey-2;
  width: setUnit(40);
  height: setUnit(40);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(100);
  transition: $transition;
  &:hover {
    background-color: $light-gray;
  }
  &[class~='ant-popover-open'] {
    background-color: $border-light-gray-2;
  }
  .icon {
    display: flex;
  }
}
.filterDropdown {
  background: $white;
  box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.06), 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: setUnit(8);
  max-width: setUnit(280);
  width: 100%;
  overflow: hidden;
  padding-top: 0;
  div[class~='ant-popover-content'] {
    padding: setUnit(12) 0;
    div[class~='ant-popover-inner'] {
      background-color: transparent;
      box-shadow: none;
      div[class~='ant-popover-title'] {
        border-bottom: 0;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: setUnit(-0.18);
        color: $text-color;
        padding: setUnit(8) setUnit(24);
      }
      div[class~='ant-popover-inner-content'] {
        padding: 0;
        .filterOptions {
          margin: 0;
          list-style-type: none;
          .sortCheckbox {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: setUnit(11) setUnit(24);
            background-color: $white;
            transition: $transition;
            &:hover {
              background-color: $background-grey-2;
            }
            span[class~='ant-checkbox'] {
              span[class~='ant-checkbox-inner'] {
                width: setUnit(24);
                height: setUnit(24);
                border: 0;
                background-color: transparent;
                &:after {
                  width: setUnit(6.67);
                  height: setUnit(10);
                }
              }
              &[class~='ant-checkbox-checked'] {
                span[class~='ant-checkbox-inner'] {
                  border: 0;
                  background-color: transparent;
                  &:after {
                    border-color: $hover-green;
                    width: setUnit(6.67);
                    height: setUnit(10);
                  }
                }
              }
              &:after {
                content: none;
              }
            }
            span[class~='ant-checkbox'] + span {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-weight: 400;
              font-size: setUnit(16);
              line-height: setUnit(20);
              letter-spacing: setUnit(-0.18);
              color: $text-color;
              margin-right: auto;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
