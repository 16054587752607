@import 'styles/variables';
@import 'styles/functions';

.tab {
    padding: setUnit(10);
    cursor: pointer;
    font-weight: 400;
    color: $text-color;

    &:hover {
        background-color: $grey-color;
    }

    &.horizontal {
        border-bottom: 3px solid transparent;
        cursor: pointer;
        margin-right: setUnit(15);
        color: $border-middle-gray;
        font-weight: 600;

        &.active {
            border-bottom-color: $primary-color;
            color: $text-color;
        }
    }

    &.vertical {
        &.active {
            background-color: $grey-color;
        }
    }
}
