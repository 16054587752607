@import 'styles/functions';
@import 'styles/variables';

.container {
  background-color: $background-ligh-gray-2;
  border-radius: setUnit(20);
  height: setUnit(460);
  overflow: hidden;
  position: relative;
  padding: 0 setUnit(15);
  @media (min-width: $mobile) {
    padding: 0;
  }
}

.noPhotos {
  font-size: setUnit(24);
  color: $dark-grey-color;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.openPhotos {
  position: absolute;
  bottom: setUnit(16);
  right: setUnit(18);
  display: inline-flex;
  text-decoration: none;
  outline: none;
  font-size: setUnit(14);
  align-items: center;
  cursor: pointer;
  border: 0;
  background: $white;
  padding: setUnit(10) setUnit(14);
  border-radius: setUnit(48);
  transition: all 0.1s;
  font-weight: 600;

  .openPhotosIcon {
    margin-right: setUnit(7);
    height: setUnit(24);
    width: setUnit(24);
  }

  &:hover {
    filter: brightness(80%);
  }
}

.viewButtons {
  position: absolute;
  bottom: setUnit(16);
  left: setUnit(18);
  display: inline-flex;
  gap: setUnit(5);
}

.openViewButton {
  display: inline-flex;
  text-decoration: none;
  outline: none;
  font-size: setUnit(14);
  align-items: center;
  cursor: pointer;
  border: 0;
  background: $white;
  padding: setUnit(10) setUnit(14);
  border-radius: setUnit(48);
  transition: all 0.1s;
  font-weight: 600;

  .openViewButtonIcon {
    margin-right: setUnit(7);
    height: setUnit(24);
    width: setUnit(24);
  }

  span {
    margin-left: setUnit(7);
  }

  &:hover {
    filter: brightness(80%);
  }
}
