@import 'styles/functions';
@import 'styles/variables';

.editTransactionDrawer {
  .submitButton {
    width: setUnit(102);
    min-width: setUnit(102);
    height: setUnit(39);
    margin-left: auto;
    display: block;
    font-weight: 600;

    .submitButtonLoader {
      font-size: setUnit(3);
    }
  }

  .reviewers {
    margin-top: setUnit(40);
    padding-bottom: setUnit(40);
  }

  .pendingWrapper {
    height: 100%;
  }

  .field {
    margin: 0;
    .inputClassName {
      font-size: 14px;
    }
  }

  .uploadDocuments {
    width: fit-content;
    .fileNew {
      display: flex;
      align-items: center;
      margin-top: setUnit(20);
      cursor: pointer;
      width: fit-content;

      .uploadFileText {
        font-size: setUnit(14);
        margin-left: setUnit(11);
        color: $secondary-color;
      }

      .uploadFileIcon {
        width: setUnit(32);
        height: setUnit(32);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $background-ligh-gray;
      }
    }
  }

  .documents {
    margin-top: setUnit(40);

    .title {
      font-weight: 600;
    }

    .documentItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: setUnit(24);

      .documentTitle {
        font-weight: 600;
      }

      .iconDelete {
        width: setUnit(24);
        height: setUnit(24);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $background-ligh-gray;
        cursor: pointer;
        transition: all 0.1s ease;

        svg {
          vertical-align: middle;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
