@import 'styles/variables';

.pdfInfo {
  background-color: $white;
  z-index: 5;
  text-align: center;
  border: 2px solid $light-gray;
  border-radius: 100px;
  padding: 10px 20px;
  max-width: 500px;
  position: inherit;
  .pdfInformation {
    font-family: $font-family;
    display: block;
  }

  .pdfName {
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
  .nameInput {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    border: none !important;

    box-shadow: unset !important;
    padding-right: 0px;
    [class~='ant-input-clear-icon'] {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNsb3NlMiI+CjxwYXRoIGlkPSJWZWN0b3IgODc4IiBkPSJNNiAxNEwxNCA2IiBzdHJva2U9IiM3NDc0NzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGlkPSJWZWN0b3IgODc5IiBkPSJNMTQgMTRMNiA2IiBzdHJva2U9IiM3NDc0NzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==');
      width: 20px;
      height: 20px;

      background-size: 22px 22px;

      span {
        display: none;
      }
    }
  }

  .editNameContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 8px;

    .pillIcon {
      display: none;
      cursor: pointer;

      &:hover {
        path {
          stroke: $text-color;
        }
      }
    }

    .icon {
      line-height: 0;
      cursor: pointer;
    }
  }

  &:hover {
    .editNameContainer {
      .pillIcon {
        display: block;
      }

      .fileIcon {
        path {
          stroke: $text-color;
        }
      }
    }
  }

  .clienInfo {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    color: $dark-color;
  }
}
