@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.thirdPartyHeader {
  border-bottom: 1px solid $grey-color;
  padding: 0.5rem 0 2rem;

  .inner {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .details {
      display: flex;

      .logo {
        margin-right: 0.75rem;

        .avatar {
          height: 44px;
          width: 44px;
        }
      }

      .title {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        display: flex;
        align-items: center;

        .category {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          color: #4673d1;
          background: rgba(70, 115, 209, 0.1);
          padding: 0.25rem 0.375rem;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-left: 0.5rem;
        }
      }

      .date {
        font-size: 16px;
        line-height: 20px;
        color: $dark-color;
        margin-bottom: 0;
      }
    }

    .actions {
      display: flex;

      .status {
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: $text-color;
        background: $background-ligh-gray;
        padding: 0.625rem 0.875rem;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 0.5rem;
        white-space: pre;

        .statusInner {
          font-weight: 600;
        }

        &.isPending {
          color: $primary-color;
          background: $primary-color-light;
        }

        &.isResponded {
          color: $green;
          background: rgba(33, 150, 83, 0.1);
        }
      }

      .commentWrapper,
      .archiveWrapper {
        @include action-icon();
        border-radius: 50%;
        cursor: pointer;
        margin-left: 0.5rem;
        height: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          position: relative;
          display: flex;
          align-items: center;

          svg {
            vertical-align: middle;
          }
        }
      }

      .commentWrapper {
        .icon {
          svg {
            path {
              fill: $text-color;
            }
          }
        }
      }
    }
  }
}
