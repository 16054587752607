@import 'styles/mixins';
@import 'styles/variables';

.button {
  border: 2px solid #e8e9ea;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: #515151 !important;
    }
  }
  &:hover {
    cursor: pointer;
    border-color: #aaabab;
  }
}
