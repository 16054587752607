@import 'styles/functions';
@import 'styles/variables';

.centerControlsWrapper {
  position: relative;
  bottom: 35px;
  .centerControls {
    display: flex;
    flex-direction: column;
    border-radius: setUnit(8);
    background-color: white;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(61, 61, 61, 0.1);
    padding: 2px 0;
    .control {
      cursor: pointer;
    }

    hr {
      margin: 0 setUnit(6);
      border: 0;
      border-top: 1px solid $border-light-gray;
    }

    button {
      background-color: white;
      border: none;
      height: setUnit(32);
      width: setUnit(32);
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition;
      &:hover {
        background-color: $background-grey-2;
      }
    }
  }
}
