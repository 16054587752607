@import 'styles/functions';
@import 'styles/variables';

.connectionsList {
  .label {
    font-size: setUnit(16);
    font-weight: 600;
  }

  .assignClientsList {
    .meConnection {
      margin-top: 2.5rem;
    }

    .someoneElse {
      margin-top: 2rem;

      .title {
        justify-content: flex-start;
        font-size: 1rem;
      }

      .count {
        margin-left: 0.25rem;
        font-weight: 600;
        font-size: 1rem;
      }

      .iconContainer {
        background-color: $background-gray;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.1s;
        margin-top: -0.5rem;

        &:hover {
          background: $border-light-gray;
        }

        button {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          margin-top: 3px;
        }
      }
    }

    .entireList {
      overflow-y: auto;
      max-height: 30vh;
      padding-right: 1rem;
      margin-right: -1.5rem;
    }
  }
}
