@import 'styles/functions';
@import 'styles/variables';

.inner {
  text-align: center;
  .icon {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chooseFileBtn {
    width: setUnit(142);
    height: setUnit(52);
    background: #ffffff;
    color: $secondary-color;
    border: 2px solid $secondary-color;
    font-weight: 600;
    margin-top: setUnit(16);

    &:hover {
      background: #ffffff;
    }
  }

  .text {
    color: $dark-grey-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    &.main {
      margin-bottom: 12px;
    }
  }
}
