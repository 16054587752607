@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  &.disabled {
    cursor: not-allowed;
  }
}

.sort {
  width: setUnit(44);
  height: setUnit(44);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 2px solid $light-gray;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: $text--light-gray-color;
  }

  &.disabled {
    opacity: 0.9;
    pointer-events: none;
  }

  .icon {
    transition: transform 0.15s;
    &.asc {
      transform: rotate(180deg);
    }

    &.desc {
      transform: rotate(0deg);
    }

    svg {
      vertical-align: middle;
    }
  }
}

.popup {
  padding: 0 !important;
  left: 29rem;

  .popupInner {
    padding: setUnit(20) setUnit(24) 0;
  }
}

@media (max-width: 767px) {
  .sortingPopup {
    left: 50% !important;
    transform: translate(-50%);
  }
}
