@import 'styles/functions';
@import 'styles/variables';

.searchesScroll {
  height: calc(100vh - #{setUnit(94)});

  .pendingWrapper {
    height: 100%;
  }

  .paddingWrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .buyerLine {
    padding-left: setUnit(32);
    padding-right: setUnit(32);
  }
}
