@import 'styles/functions';
@import 'styles/variables';

.header {
  margin-bottom: 1rem;
  width: 100%;

  .heading {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .documentIcon {
      background: $background-grey-2;
      border-radius: 1.5rem;
      display: inline-block;
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 1.375rem;

      svg {
        height: 2.25rem;
        margin-top: 0.2rem;
        margin-left: 0.4rem;
      }
    }

    .headingLeft {
      .initHeading {
        font-size: setUnit(13);
        font-weight: 600;
      }
      p {
        span {
          color: #515151;
        }
      }
    }

    .offer {
      font-weight: 700;
      font-size: setUnit(24);
      line-height: setUnit(32);
      color: $text-color;
      margin-right: 0;
      display: flex;
    }
  }

  .tooltip {
    margin-left: 0.5rem;
    position: relative;
    transform: rotate(180deg);
    bottom: 0.25rem;
  }

  .actions {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      cursor: pointer;
      height: setUnit(40);
      width: setUnit(40);
      margin-right: setUnit(20);
      svg {
        height: setUnit(40);
        width: setUnit(40);
      }
    }

    .closeIcon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: setUnit(40);
      width: setUnit(40);
      svg {
        height: setUnit(32);
        width: setUnit(32);
      }
    }

    .status {
      padding: 0.5rem 0.875rem;
      background: $background-grey-2;
      border-radius: 6.25rem;
      margin-right: setUnit(12);
      display: inline-flex;
      height: 2.5rem;
      justify-content: center;
      align-items: center;

      span {
        color: $dark-color;
        font-weight: 500;
      }
    }

    .pending {
      background: $primary-color;

      span {
        font-size: setUnit(16);
        font-weight: 600;
        color: #fff;
      }
    }

    .responded {
      background: $green-color;

      span {
        font-size: setUnit(16);
        font-weight: 600;
        color: #fff;
      }
    }

    .megaPhoneIcon {
      cursor: pointer;
      background: $background-grey-2;
      border-radius: 6.25rem;
      width: 2.5rem;
      height: 2.5rem;
      display: inline-block;
      position: relative;

      svg {
        position: relative;
        top: 50%;
        left: 48%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  flex-direction: column;

  .businessName {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
    color: $text-color;
    margin-bottom: 4px;
  }

  .categoriesParagraph {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
    margin-bottom: 0;
    &.isClient {
      margin-bottom: 16px;
    }
  }
}

.avatarWrap {
  margin-bottom: 12px;
  .avatar {
    height: 80px;
    width: 80px;
    font-size: 32px;
    line-height: 36px;
  }
}

.goBackButton {
  position: absolute;
  top: 40px;
  left: 40px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    height: 18px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
}

.messageBtn,
.profileBtn {
  background-color: transparent !important;
  border: 1px solid $border-light-gray-2 !important;
  color: $text-color-middle !important;
  padding: 8px 16px !important;

  border-radius: 20px !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.18px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: initial !important;
  margin-top: setUnit(20);

  &:hover {
    background-color: transparent !important;
    border: 1px solid $text--light-gray-color !important;
  }

  div {
    display: flex;
    height: 24px;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  span {
    margin-left: 0.25rem;
    font-weight: 500;
  }
}

.messageBtn {
  svg {
    path {
      fill: $dark-color;
    }
  }
}

.recommendedBar,
.unRecommendedBar {
  margin-top: 1.5rem !important;
  height: 48px;
  width: 100%;
  border: none !important;
  border-radius: 0.5rem !important;

  & [class*='ant-alert-message'] {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: center !important;
    white-space: pre;
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;

    .recommendedIcon {
      display: flex;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 0.875rem;
        margin-bottom: 2px;
      }
    }
  }

  .recommendedName {
    font-weight: 500;
  }
}

.clickable {
  cursor: pointer;
}
