@import 'styles/variables';

.draggableContainer {
  & > div {
    display: flex;
    flex-direction: row-reverse;
    min-height: 56px;
    align-items: baseline;
  }
  .dragIcon {
    margin: 0px 12px;
  }
}

.activeItem {
  background: $background-grey-2;
}
