@import 'styles/functions';
@import 'styles/variables';

.avatarTableColumn,
.documentTableColumn {
  display: flex;
  gap: 8px;
  align-items: center;
}

.avatarTableColumn {
  .avatar {
    width: setUnit(24);
    height: setUnit(24);
    font-size: setUnit(11);
    font-weight: 600;
    line-height: setUnit(26);
    letter-spacing: setUnit(-0.33);
  }
}

.documentTableColumn {
  .name {
    font-weight: 600;
  }
}

.dateTimeColumn {
  p {
    margin-bottom: 0;
  }
  .time {
    color: $light-gray-2;
  }
}
