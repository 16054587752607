@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.highlights {
  max-height: 39rem;
  overflow-y: hidden;
  padding: 0 setUnit(4.8) setUnit(16) setUnit(32);
  min-width: 470px;
  border-left: 1px solid $light-gray;
  @include customScrollbar;
  &:hover {
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }
  .title {
    color: $text-color;
  }

  .disclaimer {
    text-align: center;
  }

  .content {
    p {
      margin-bottom: 0.1em;
    }

    .valueItemWidth {
      [class*='value'] {
        max-width: 270px;
      }

      line-height: 1rem;
      color: $text-color;
    }
  }
}
