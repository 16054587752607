@import 'styles/functions';
@import 'styles/variables';

.disableFeeCoverContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: not-allowed;
  z-index: 10;
}
