@import 'styles/functions';
@import 'styles/variables';

.documentsContent {
  .rowClick {
    cursor: pointer;
  }

  .avatarText {
    padding: 0;
    margin: 0;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  .container {
    padding: 0px 2rem;
    .tableContainer {
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
      border-radius: setUnit(16);
      margin-bottom: setUnit(40);
      .documentsTable {
        div[class~='ant-table-content'] {
          table {
            border-radius: setUnit(16);
            background-color: #fff;
          }
        }

        [class~='ant-table'] {
          border-radius: 1rem;
        }

        .clientField {
          display: flex;
          align-items: center;

          .avatar {
            width: setUnit(24);
            height: setUnit(24);
            font-size: setUnit(11);
            font-weight: 600;
            line-height: setUnit(26);
            letter-spacing: setUnit(-0.33);
            margin-right: setUnit(10);
            &.buy {
              border: 0;
            }
            &.sell {
              border: 0;
            }
          }
        }

        span[class~='ant-table-column-sorter-full'] {
          display: none;
        }
        th {
          background-color: unset;
          color: $dark-grey-color;
          font-weight: 600;
          font-size: setUnit(14);
          &.ant-table-cell::before {
            display: none;
          }
        }

        th::before {
          display: none !important;
          width: 0;
        }

        th,
        td {
          line-height: setUnit(20);
          letter-spacing: -0.33px;
          font-size: setUnit(16);
          font-weight: 400;
          padding: setUnit(24);

          cursor: pointer;

          &:last-child {
            border-right: unset !important;
          }

          &[class~='ant-table-column-sort'] {
            background-color: transparent;
          }
        }

        & [class*='ant-table-container'] {
          border: unset !important;
          font-size: setUnit(16);
        }

        th {
          & [class*='ant-table-cell']::before {
            display: none !important;
          }
        }

        th {
          &.ant-table-cell::before {
            display: none !important;
          }
        }

        tr {
          td {
            &:first-child {
              width: 343px;
            }
            &:last-child {
              width: 150px;
            }
          }
          &:last-child {
            td {
              border-bottom: unset;
              &:first-child {
                border-bottom-left-radius: 1rem;
              }
            }
          }
          &:first-child {
            th {
              border-top-left-radius: 1rem !important;
            }
          }
        }

        table {
          border: unset !important;
        }

        & [class*='ant-pagination'] {
          display: none;
        }

        @media (max-width: $mobile) {
          div[class='ant-table-content'] {
            overflow-x: scroll;
          }
        }

        .sortedColumnTitle {
          display: flex;
          align-items: center;
          font-size: setUnit(14);
          font-weight: 600;
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.18);
          color: $text-gray-color;
          .sorterIcon {
            margin-left: setUnit(8);
            display: flex;
            align-items: center;
            justify-content: center;
            width: setUnit(24);
            height: setUnit(24);
            background-color: $background-grey-2;
            border-radius: setUnit(100);
            cursor: pointer;
            transform: rotate(0deg);
          }

          .sorterUp {
            transform: rotate(180deg);
          }
        }

        span[class~='ant-table-column-sorter-full'] {
          display: none;
        }

        & [class*='ant-pagination'] {
          margin-right: 3px;
        }

        th {
          background: unset !important;
          color: $dark-grey-color !important;
          font-weight: 600 !important;
        }

        .documentName {
          display: flex;
          align-items: center;
        }

        .wordBreak {
          word-break: break-all;
        }

        .clientField {
          display: flex;
          align-items: center;
          font-size: setUnit(16);
          font-weight: 400;

          .avatar {
            width: setUnit(24);
            height: setUnit(24);
            font-size: setUnit(11);
            font-weight: 600;
            line-height: setUnit(26);
            letter-spacing: setUnit(-0.33);
            margin-right: setUnit(10);
          }
        }
      }
    }
  }
}
