@import 'styles/functions';
@import 'styles/variables';

.taskViewModal {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 1.5rem;
    margin: 4rem 0;
    max-height: calc(100vh - 8.5rem);
    overflow: hidden;
  }

  .view {
    .pendingWrapper {
      height: 100%;
    }

    .notFoundMessage {
      color: $text-gray-color;
      height: 300px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: setUnit(16);
    }

    .heading {
      font-family: $font-family;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.47px;
      color: $text-color;
      margin: 0.5rem auto 0;
      max-width: 75%;
      span {
        position: relative;
        display: inline-block;
        text-decoration: underline;
      }
    }

    .subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.18px;

      .required {
        color: $error-color;
        font-weight: 600;
      }

      .notRequired {
        color: $text-gray-color;
        font-weight: 500;
      }
    }

    .content {
      height: 100%;
      padding: 0 1rem 0 1rem;
      max-height: calc(100vh - 19.5rem);
      padding-right: 1rem;
      margin-top: 2.5rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: $border-light-gray-2;
        }
      }
      .contentTop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .ctLeft {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .ctlData {
            margin-left: setUnit(12);
          }
        }
      }

      .headerInfo {
        padding-left: setUnit(10);
      }

      .flexHeaderInfo {
        display: flex;
        align-items: center;
      }

      .subHeading {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.18px;
        color: $text-color;
        margin-bottom: 0.25rem;
        width: fit-content;
      }

      .subtitle {
        padding-left: setUnit(12);
      }

      .assign {
        display: flex;
        align-items: center;
        margin-top: setUnit(40);
        min-height: setUnit(70);

        .to,
        .by {
          width: 50%;
        }
      }

      .alignStart {
        align-items: flex-start !important;
      }

      .cc {
        margin-top: setUnit(20);
        margin-bottom: setUnit(20);
      }

      .bundleDescription {
        margin-top: 24px;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        letter-spacing: -0.18px;
        color: $text-color;
        font-weight: 600;
        line-height: 20.8px;

        .formNameContainer {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          .docIcon > svg > path {
            stroke: $dark-grey-color;
          }

          .formName {
            font-weight: 400;
            line-height: 16px;
            margin-left: 6px;
          }
        }
      }

      .actionBtn {
        width: 100%;
        height: 52px !important;
        border-radius: 8px;
        display: flex;
        padding: 14px 0px;
        justify-content: center;
        align-items: center;
        margin: 32px 0px;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.09px;
        flex-grow: 1;
      }

      .description {
        line-height: setUnit(21);
        margin-top: setUnit(24);

        .descriptionText {
          font-family: $font-family;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.18px;
          color: $text-color;
          margin-bottom: 0px;
        }
      }

      .linkContainer {
        margin-top: 12px;
        .links {
          margin-top: 0;
        }
      }

      .customLine {
        border-bottom: 1px solid $light-gray;
        margin-top: setUnit(26);
        margin-bottom: setUnit(32);
      }

      .addFilesOption,
      .addCommentsOption {
        display: flex;
        margin-top: 1.5rem;
        cursor: pointer;

        .addLabel {
          font-family: $font-family;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.18px;
          color: $text-color;
          display: flex;
          align-items: center;
        }
      }

      .addCommentsOption {
        margin-bottom: 0.5rem;
      }

      .comments,
      .checklists {
        margin-bottom: 1rem;

        .checklistCount {
          margin-left: 0.25rem;
          font-weight: 600;
          font-size: 1rem;
        }

        .iconContainer {
          background-color: $background-gray;
          width: setUnit(32);
          height: setUnit(32);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.1s;
          margin-top: -0.5rem;

          &:hover {
            background: $border-light-gray;
          }

          button {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            margin-top: 3px;
          }
        }
      }

      .taskViewChecklist {
        max-height: unset;
        overflow-y: unset;
        padding-right: unset;
      }

      .files {
        margin-bottom: setUnit(12);

        .plusIcon {
          width: 2rem;
          height: 2rem;
          background: #f4f6fb;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      .comments {
        .unreadCount {
          color: $error-color;
        }

        .regularCount {
          color: gray;
        }

        margin-bottom: 0;
        .subHeading {
          margin-bottom: 0;
          .count {
            color: $text--light-gray-color;
          }
        }
      }
    }
  }
}
