@import "styles/functions";
@import "styles/variables";

.subtitle {
    font-weight: 500;
    text-align: center;
    margin: setUnit(24) auto setUnit(42);
    max-width: setUnit(470);
    line-height: setUnit(24);
}
