@import 'styles/functions';
@import 'styles/variables';

.container {
  height: calc(100vh - 80px);
  margin-top: setUnit(32);
  width: 100%;
  text-align: center;
}

.title {
  color: $text-color;
  font-size: setUnit(24);
  font-weight: 700;
  line-height: setUnit(38);
  margin-bottom: setUnit(16);
}

.info {
  font-size: setUnit(16);
  font-weight: 400;
  color: $dark-grey-color;
  margin-bottom: setUnit(32);
}

.back {
  display: inline-flex;
  text-decoration: none;
  font-weight: 400;
  outline: none;
  font-size: setUnit(16);
  align-items: center;
  cursor: pointer;
  border: 0;
  background: $secondary-color;
  padding: setUnit(12) setUnit(32);
  border-radius: setUnit(10);
  transition: all 0.1s;
  margin-bottom: setUnit(16);

  text-decoration: none;
  color: $white;

  &:hover {
    opacity: 0.7;
  }

  .arrow {
    margin-right: setUnit(10);

    path {
      stroke: $white;
    }
  }
}

.image {
  margin-bottom: setUnit(24);
  background-color: $background-ligh-gray-2;
  border-radius: setUnit(20);

  img {
    height: setUnit(300);
  }
}
