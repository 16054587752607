@import "styles/functions";
@import "styles/variables";


.fullscreenControlWrapper {
    .fullscreenControl {
        height: setUnit(32);
        width: setUnit(32);
        background-color: white;
        border: none;
        border-radius: setUnit(8);
        box-shadow: 0 8px 16px rgba(61, 61, 61, 0.1);
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .isFullscreen {}
}
