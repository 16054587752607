@import 'styles/functions';
@import 'styles/variables';

.newQuote {
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #262626 !important;
  margin-left: 1rem !important;
  p {
    margin: 0 !important;
  }
}
.newQuoteCount {
  text-align: center !important;
  background-color: #04a4511a !important;
  border-radius: 50% !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  p {
    margin: 0 !important;
    color: green !important;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
}
.pendingQuote {
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #262626 !important;
  margin-left: 1rem !important;
  p {
    margin: 0 !important;
  }
}
.pendingQuoteCount {
  text-align: center !important;
  background-color: #fff2f3 !important;
  border-radius: 50% !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  p {
    margin: 0 !important;
    color: red !important;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
}

.itemRight {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.quoteSpace {
  display: flex !important;
}
