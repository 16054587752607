@import 'styles/functions';
@import 'styles/variables';

.content {
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);
  color: $dark-color;
}

.twoBlocksWrapper {
  display: flex;
  flex-wrap: wrap;

  .twoBlocksBlock {
    width: 50%;
    padding-right: setUnit(20);
  }
}
