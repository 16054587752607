@import 'styles/functions';
@import 'styles/variables';

.documents {
  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .documentUpload {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid $light-gray;
    padding-bottom: 24px;
    [class~='dropzone'] {
      [class~='wrapper'] {
        width: calc(100% - 4px);
        margin: auto;
      }
    }
  }
}
