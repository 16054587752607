@import 'styles/functions';
@import 'styles/variables';

.transactionView {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  $padding: setUnit(40);
  .topSideInfo {
    padding: 0 2.5rem !important;

    .transactionInfoContainer {
      padding: setUnit(16);
      margin-bottom: setUnit(24);
      background-color: #f4f7fc;
      border-radius: setUnit(8);

      .addressStatusRow {
        margin-bottom: setUnit(16);
        overflow-wrap: break-word;
      }

      .value {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(20);
      }

      .colData {
        margin-bottom: setUnit(16);
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }

    @media (min-width: $tablet) {
      padding: $padding;
    }
  }
  .bottomSide {
    padding: setUnit(24);
    @media (min-width: $tablet) {
      padding: $padding;
    }
  }

  .fullAccess {
    cursor: pointer;
  }

  .editIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: setUnit(16);
    visibility: hidden;
  }

  .editWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    label {
      font-weight: 400 !important;
      margin-bottom: 0.25rem !important;
      color: $text-color;
    }
    .marginTop {
      margin-top: 0.25rem;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      border-radius: setUnit(8);
      font-weight: 500;
      border-width: 1px !important;
    }
  }

  .disableField {
    opacity: 0.5;
    &[class*='fullAccess'],
    [class*='fullAccess'] {
      cursor: not-allowed !important;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
