@import 'styles/variables';

.inviteClientButton {
  height: 40px;
  padding: 0 16.55px;
  background-color: $primary-color;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $primary-color-hover;
  }

  span {
    color: $white;
  }
}

.closeClientDrawerIcon {
  height: 40px;
  width: 40px;
  margin-left: 1rem;
  background-color: $background-grey-2;
  border-radius: 50%;
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: $light-gray;
  }
}
