@import 'styles/functions';
@import 'styles/variables';

.addAnother {
  display: flex;
  // margin: 24px 0 44px auto;
  align-items: center;
  cursor: pointer;
  width: 130px;
  .addIcon {
    height: 24px;
  }
  span {
    margin-left: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
}
