.container {
  padding: 32px 0;

  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .heading {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.64px;

      margin-bottom: 20px;

      grid-column: span 2 / span 2;
    }

    .inputContainer {
      grid-column: span 2 / span 2;
      &.grid {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px !important;
      }

      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .inputWrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        .formInput {
          flex-grow: 1;
        }
      }

      .mlsWrapper {
        padding: 22px 22px 35px 22px;
        border-radius: 12px;
        border: 1px solid var(--grayscale-25-outline, #d6d6d7);
        background: #fff;

        .heading {
          color: var(--Grayscale-100, #262626);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.09px;

          margin-bottom: 24px;
        }

        .rowWrapper {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .fieldWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .fieldsContainer {
              display: flex;
              flex-grow: 1;
              flex-direction: column;

              .mlsheading {
                color: var(--Grayscale-40, #aaabab);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                letter-spacing: -0.09px;
              }
              .info {
                color: var(--Grayscale-100, #262626);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.8px; /* 130% */
                letter-spacing: -0.18px;
              }
            }
          }
        }
      }
    }
  }

  .divider {
    background-color: var(--Grayscale-20, #e8e9ea);
    height: 1px;
    margin: 24px 0;
  }
}
