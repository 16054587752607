@import 'styles/functions';
@import 'styles/variables';

.dueDate {
  margin-bottom: 2rem;

  .fieldWrapper > label {
    margin-bottom: 1rem;
  }

  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fieldWrapper {
    .datePicker {
      display: flex;
      position: relative;
      .templateControlPicker {
        border-bottom: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        svg {
          position: relative;
          top: unset;
        }
      }
      .disabledPicker {
        cursor: default;
      }
      .datePickerInput {
        input {
          padding-left: 36px;
        }
      }
      .errorMsg {
        position: absolute;
        bottom: -1.6rem;
        font-size: 1rem;
        right: 0rem;
        width: fit-content;
        color: $error-color;
      }
    }
  }
}
