@import 'styles/functions';
@import 'styles/variables';

.commute {
  border-top: 1px solid $light-gray;
  display: flex;
  align-items: center;
  padding: setUnit(12) 0;

  &:last-child {
    border-bottom: 1px solid $light-gray;
  }

  .icon {
    svg {
      vertical-align: middle;
      path,
      circle,
      rect {
        stroke: $primary-color;
      }
    }
  }

  .info {
    flex-grow: 1;
    padding-left: setUnit(25);
    padding-right: setUnit(10);

    .name {
      font-weight: 600;
    }

    .meta {
      font-size: setUnit(14);
      font-weight: 400;
      color: $dark-grey-color;
      margin-top: setUnit(8);
      line-height: setUnit(18);

      .separator {
        display: inline-block;
        width: setUnit(3);
        height: setUnit(3);
        background: $dark-grey-color;
        vertical-align: middle;
        border-radius: 50%;
        margin: 0 setUnit(8);
      }
    }
  }
}
