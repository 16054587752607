@import 'styles/functions';
@import 'styles/variables';

.actionBtn {
  position: relative;
  transition: all 0.1s;
  padding-top: 2px;

  .actionBtnIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  .actionList {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    width: setUnit(176);
    border-radius: setUnit(8);
    box-shadow: 0 2px setUnit(12) rgba(0, 0, 0, 0.15);
    right: 0;
    z-index: 99;

    overflow: hidden;

    &.top {
      bottom: setUnit(35);
    }

    &.bottom {
      top: setUnit(35);
    }

    .actionListBtn {
      width: 100%;
      display: flex;
      align-items: center;
      outline: none;
      border: 0;
      color: $text-color;
      font-size: setUnit(14);
      line-height: setUnit(16);
      padding: setUnit(12) setUnit(16);
      transition: all 0.1s;
      background-color: $white;
      text-align: left;
      cursor: pointer;

      .icon {
        padding-right: setUnit(8);
        height: setUnit(16);

        svg {
          width: setUnit(16);
          height: setUnit(16);
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $light-gray;
      }

      &:hover {
        background-color: $hover-light-gray;
      }
    }
  }

  &:hover {
    .actionBtnIcon {
      background-color: $secondary-color-hover;
    }

    .actionList {
      opacity: 1;
      visibility: visible;
    }
  }
}
