@import 'styles/functions';
@import 'styles/variables';

$left-menu-width: 467px;
$actionBtnWidth: 34px;
$header-with-spacing: 220px;
$navbar: 80px;
$breakdown-menu-width: 506px;

.clarityContentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(249, 249, 249, 0.6);
  backdrop-filter: blur(60px);
  padding: 0px 40px;

  .headerRows {
    display: flex;
    flex-direction: column;

    .infoRow {
      display: flex;
      justify-content: space-between;
      height: 55px;
      margin-top: 28px;
      margin-bottom: 52px;

      text-align: center;
      font-family: Inter;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 62px; /* 96.875% */
      letter-spacing: -0.35px;

      .filtersContainer {
        display: flex;
        align-items: center;

        .dateRangeFilterWrapper {
          margin-right: 6px;
        }

        .breakdownBtn {
          display: flex;
          height: 44px;
          padding: 11px 16px;
          justify-content: center;
          align-items: center;
          margin-right: 6px;
          border-radius: 100px;
          border: 2px solid $light-gray;
          background-color: $white;
          color: $dark-color;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 17.6px; /* 110% */
          letter-spacing: -0.363px;
          cursor: pointer;

          &.openMenu {
            background-color: $secondary-color;
            color: $white;
            border-color: $secondary-color;
          }
        }
      }
    }
  }

  .bodyRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 548px;

    .graphContainer {
      border-radius: 16px;
      border: 1px solid #e8e9ea;
      background-color: $white;
      padding: 18px 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &.openMenu {
        width: calc(100vw - $breakdown-menu-width);
      }

      &.tableContainer {
        width: calc(100vw - $breakdown-menu-width - 100px);
      }
      .graphContent {
        height: 100%;
        .graphToggleContainer {
          display: flex;
          justify-content: flex-end;

          .grpahToggleBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $light-gray;
            border-radius: 50%;
            padding: 7px;
            height: 42px;
            width: 42px;
            cursor: pointer;
            background-color: $white;

            &.hideToggle {
              display: none;
            }
          }
        }
      }
    }

    .breakdownMenu {
      margin-left: 24px;
      display: none;
      height: 548px;

      &.openMenu {
        display: unset;
      }
    }
  }
}
