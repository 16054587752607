@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.spinnerWrapper {
  margin-right: setUnit(24);
  .loader {
    font-size: setUnit(3);
    margin: 0;
  }
}

.actions {
  cursor: pointer;
  position: relative;
}

.attachmentOptions {
  &.options {
    min-width: setUnit(100);
    padding: 0;
    border-radius: setUnit(8);
    overflow: hidden;

    .list {
      list-style: none;

      .item {
        padding: setUnit(10) setUnit(15);
        font-size: setUnit(15);
        cursor: pointer;

        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
