@import 'styles/functions';
@import 'styles/variables';

.filterWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: setUnit(8);
  background-color: $background-grey-2;
  padding: setUnit(10) setUnit(20);
  margin: 2rem 0 1rem;
  div {
    display: flex;
    svg {
      width: setUnit(18);
      height: setUnit(18);
      path {
        stroke: $text-gray-color;
      }
    }
  }
  .filterInput {
    margin-left: setUnit(12);
    width: calc(100% - 30px);
    border: 0;
    background-color: transparent;
    padding: setUnit(6) 0;
    color: $text-gray-color;
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(16);
    letter-spacing: -0.18px;
    &::placeholder {
      color: $text-gray-color;
    }
    &:focus {
      box-shadow: none;
      border-right-width: 0;
    }
  }
}
