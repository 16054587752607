@import 'styles/functions';
@import 'styles/variables';

.buttonsContainer {
  max-width: 400px;
  width: 100%;
  margin: 48px auto 0;
  button:not(:last-child) {
    margin-bottom: 20px;
  }
}
