.wrapper {
  [class~='ant-modal-content'] {
    box-shadow: none !important;
  }
}

.modalWrapper {
  width: fit-content !important;

  [class*='ant-modal-close-x'] {
    width: fit-content;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 32px 0 0;
  }

  .list {
    row-gap: 0px !important;
    padding: 0px 8px 24px 8px !important;
    max-width: 698px !important;

    .header {
      text-align: left;
      width: 634px;
      margin-bottom: 16px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;
    padding: 64px 33px 24px 33px;

    max-width: 604px;

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-style: normal;

    .header {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      line-height: 26px; /* 108.333% */
      letter-spacing: -0.33px;
    }

    .reasonContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;

      .reasonBtn {
        border-radius: 8px;
        border: 1px solid var(--rebrand-grayscale-25-outline, #d6d6d7);
        background: var(--REBRAND-Secondary-White, #fff);
        width: 100%;
        padding: 14px 16px;
        text-align: left;
        &:hover {
          /* Dropdown shadow */
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
          cursor: pointer;
        }
      }

      .additionalInput {
        color: var(--REBRAND-Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
        margin-bottom: 12px;
      }

      .input {
        width: 100%;
        text-align: left;

        &.error {
          input {
            border: 1px solid var(--Tertiary-Error-Red, #ec455e) !important;
          }
        }

        &.additionalInput {
          border-radius: 8px;
          border: 1px solid var(--rebrand-grayscale-25-outline, #d6d6d7);
          background: var(--REBRAND-Secondary-White, #fff);
          padding: 14px 16px;

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .reason {
      height: 56px;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background: var(--rebrand-grayscale-10-background, #f4f5f6);
        cursor: pointer;
      }
    }

    .step2Subheader {
      max-width: 433px;
    }
    .subHeader {
      color: var(--Grayscale-80, #515151);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;

      strong {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.33px;
      }
    }

    .plans {
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        padding: 20px 32px;
        text-align: left;

        width: 207px;

        & div:first-child {
          color: var(--Grayscale-60, #676767);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.09px;
        }

        & div:last-child {
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */
          letter-spacing: -0.09px;
        }
      }

      .left {
        border: 2px solid #e8e9ea;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        border-right: 1px solid #e8e9ea;

        & div:last-child {
          background: var(--Orange-gradient, linear-gradient(90deg, #f00 -120.45%, #fb913a 99.8%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .right {
        border: 2px solid #e8e9ea;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        border-left: 1px solid #e8e9ea;
        & div:last-child {
          color: #2b59da;
        }
      }
    }

    .secondaryInfo {
      color: var(--Grayscale-80, #515151);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.33px;
      max-width: 506px;

      a {
        color: var(--Secondary-Link-Blue, #2b59da);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.33px;
        text-decoration-line: underline;
      }
    }
    .info {
      border-radius: 12px;
      background: var(--grayscale-10-background, #f4f5f6);
      padding: 16px;
      width: 100%;

      color: var(--Grayscale-100, #262626);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;

      strong {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.18px;
      }

      a {
        color: var(--Secondary-Link-Blue, #2b59da);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
      }

      .red {
        color: var(--Tertiary-Error-Red, #ec455e);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
    }

    .contact {
      margin-top: 24px;
      margin-bottom: 16px;
      color: var(--Grayscale-80, #515151);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.33px;

      a {
        color: var(--Secondary-Link-Blue, #2b59da);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.33px;
        text-decoration-line: underline;
      }
    }

    .cancelBtn {
      border-radius: 8px;
      background: var(--grayscale-10-background, #f4f5f6) !important;
      display: flex;
      min-width: 158px;
      height: initial;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      color: var(--Grayscale-80, #515151) !important;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off !important;
      font-family: Inter !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 20px !important; /* 125% */
      letter-spacing: -0.18px;

      display: inline;
      margin-right: 16px;
    }

    .anchorBtn {
      background: white !important;

      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: 1px solid var(--grayscale-25-outline, #d6d6d7);
    }

    .btnContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .confirmBtn {
        border-radius: 8px;
        background: var(--Primary-Navy, #252d44) !important;
        display: flex;
        min-width: 158px;
        height: 52px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        display: inline;

        color: white !important;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off !important;
        font-family: Inter !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important; /* 125% */
        letter-spacing: -0.18px;

        &.red {
          background: var(--Primary-Red, #ff576d) !important;
        }
      }
    }
  }
}
