@import 'styles/functions';
@import 'styles/variables';

.formsToSignActionMenu {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 10;

  background-color: $background-blue;
  border-radius: 16px;
  min-width: 270px;
  padding: 15px 20px 25px 20px;
  cursor: pointer;
}

.needsAttentionText {
  color: $text--light-gray-color;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.formsToSignActionMenuLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .formsToSignMenuText {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $white;

    .formsCountText {
      background-color: $primary-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-weight: 700;

      display: flex;
      align-items: center;
      justify-content: center;

      ~ div {
        font-weight: 500;
      }
    }
  }
}

.rightIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
