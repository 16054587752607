@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.voidFormReasonModal {
  [class~='ant-modal-title'] {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.46px;
    color: $text-color;
    max-width: 80%;
    margin: 0 auto;
  }

  [class~='ant-modal-content'] {
    [class~='ant-modal-header'] {
      margin-bottom: 32px;
    }

    [class*='ant-modal-close'] {
      [class*='ant-modal-close-x'] {
        top: 3px;
      }
    }
  }

  .label {
    font-family: Inter;
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;
  }

  .textArea {
    margin-top: 12px;
    color: $text-color;
    font-size: setUnit(16);
    line-height: 20.8px;
    letter-spacing: -0.09px;
    border-radius: 8px;

    border-color: $border-input-middle-gray;
    padding: 14px 16px;

    height: 152px !important;
    resize: none;

    &:focus,
    &:hover {
      border-color: $border-focused;
      box-shadow: none;
      outline: 0;
    }

    &::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .voidReasonInformation {
    margin-top: 20px;

    font-size: setUnit(14);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    text-align: left;
    color: $dark-color;
  }

  .voidButton {
    width: 172px;
    height: 52px;
    border-radius: 10px;

    margin: 40px auto 0px;
    display: block;
  }

  .disabledVoidButton {
    opacity: 0.3;
  }
}
