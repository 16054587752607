@import 'styles/variables';
@import 'styles/functions';

.clausesFormContainer {
  border-top: 2px solid $light-gray;

  position: relative;

  [class*='ant-form-item-explain-error'] {
    margin: 5px 0 10px 0 !important;
  }

  .clauseFormHeading {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    text-align: left;
    color: $text-color;

    margin-top: setUnit(32);
    margin-bottom: setUnit(15);
  }
}

.label {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: $dark-grey-color;
}

.row {
  align-items: center;
  margin-bottom: 24px;
}

.formItem {
  margin-bottom: 0 !important;
}

.checkbox {
  align-items: center !important;

  [class~='ant-checkbox'] {
    top: 0px;

    [class*='ant-checkbox-inner'] {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border-color: $text--light-gray-color !important;
    }

    + span {
      padding-left: 10px;
    }
  }

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color !important;
      border-radius: 4px;
    }

    [class*='ant-checkbox-inner']::after {
      width: 7px;
      height: 12px;
      left: 25%;
      top: 45%;
    }

    &::after {
      border-color: $primary-color;
      border-radius: 4px;
    }
  }

  &:hover {
    [class*='ant-checkbox-inner'] {
      border-color: $primary-color !important;
    }
  }
}

.clauseDescription {
  color: $dark-grey-color;
}
