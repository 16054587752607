@import 'styles/functions';
@import 'styles/variables';

.cardContainer {
  position: relative;
  .card {
    width: 100%;
    padding: 20px 20px 24px 20px;
    border-radius: 8px;
    border: 1px solid $light-grey-color;
    background: $background-ligh-gray-5;
    margin-top: 24px;
  }
  .remove {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -14px;
    top: -14px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 1;
    background: $white;
    border: 1px solid $light-grey-color;
    .icon {
      margin-top: 6px;
    }
  }
}
