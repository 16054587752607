@import 'styles/functions';
@import 'styles/variables';

.clientNames {
  display: flex;
  justify-content: space-between;
  min-height: 32px;

  .names {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1rem;

    .value {
      max-width: 175px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .editIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: setUnit(16);
    display: none;
  }

  &:hover {
    .editIcon {
      display: block;
    }
  }
}

.clientNameContainer {
  position: relative;
  display: flex !important;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .clientNameInput {
    display: flex;
    align-items: center;
    gap: 5px;

    input {
      padding-right: 30px;
    }

    .removeClientName {
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
