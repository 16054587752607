@import 'styles/functions';
@import 'styles/variables';

.list {
  list-style: none;
  font-size: setUnit(14);
  font-weight: 400;

  li {
    margin-top: setUnit(10);

    &:first-child {
      margin-top: 0;
    }
  }
}
