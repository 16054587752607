.exportBtn {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 4.4px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1.5px solid var(--Grayscale-20, #e8e9ea);
  background: #fff;
  svg {
    height: 20px;
    width: 20px;
  }
}
