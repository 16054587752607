@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contextSelector {
  width: fit-content;
  max-width: 248px;
  min-width: 200px;
  @media (max-width: 400px) {
    min-width: 100px;
  }
  position: relative;
  padding-left: setUnit(10);
  margin-left: setUnit(10);
  display: flex;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $border-light-gray-2;
  }
  .staticText {
    position: absolute;
    @media (max-width: 400px) {
      display: none;
    }
    font-size: 12px;
    color: $text-gray-color;
    top: setUnit(-16);
    left: setUnit(71);
    z-index: 1;
  }

  div[class~='ant-select-dropdown'] {
    min-width: fit-content !important;
  }
  div[class~='ant-select-single'] {
    width: 100%;
    padding: setUnit(4);
    border-radius: setUnit(8);
    @include transition;
    & > div[class~='ant-select-selector'] {
      height: setUnit(45);
      font-size: 16px;
      & > span[class~='ant-select-selection-item'] {
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
        .multiAvatar {
          position: relative;
          width: setUnit(34);
          height: setUnit(40);
          .avatar {
            width: 100%;
            height: 100%;
            margin-top: 0.2rem;
            span[class~='ant-avatar-string'] {
              flex: 0 0 100%;
            }
          }
          .avatarWrap {
            position: relative;
            width: 28px;
            height: 28px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            &:last-child {
              position: absolute;
              bottom: 0;
              right: 0;
            }
            .overlay {
              font-weight: 700;
              font-size: setUnit(14);
              line-height: setUnit(16);
              color: $white;
              background-color: rgba(0, 0, 0, 0.6);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
            }
          }
        }
        .avatar {
          width: setUnit(32) !important;
          height: setUnit(32) !important;
          margin-top: 0.2rem;
          border-radius: 8px !important;
          span[class~='ant-avatar-string'] {
            top: 50%;
            transform: scale(1) translate(-50%, -50%) !important;
            font-size: setUnit(14);
          }
        }
        .contentArea {
          max-width: calc(100% - 45px);
          margin-left: setUnit(12);
          display: inline-flex;
          align-items: flex-end;

          .info {
            .singleAgentName {
              white-space: nowrap;
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-top: 1rem !important;
            }
          }
          @media (max-width: 400px) {
            display: none;
          }
          .name {
            font-size: setUnit(13);
            line-height: setUnit(18);
            color: $dark-color;
            margin-bottom: setUnit(8);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .teamWrap {
            display: none;
          }
        }
        .icon {
          display: none;
        }
      }
    }
    span[class~='ant-select-arrow'] {
      transform: rotate(0deg);
      transition: $transition;
      right: 10px !important;
    }
  }
  & div[class~='ant-select-open'] {
    background-color: $background-grey-2;
    span[class~='ant-select-arrow'] {
      transform: rotate(180deg);
    }
  }
}

.singleContextSelectorDropdown {
  div[class~='ant-select-item'] {
    &:hover {
      background-color: $white !important;
    }
  }
  div[class~='ant-select-item-option-content'] {
    align-items: start !important;
    .contentArea {
      min-height: 145px;
      .info {
        .buttonContainer {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0.75rem 1.25rem;
          .messageBtn {
            border-radius: 8px;
            height: 48px;
            font-weight: 500 !important;
            font-size: 14px !important;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

.contextSelectorDropdown {
  min-width: setUnit(280) !important;
  max-width: setUnit(280);
  width: 100% !important;
  border-radius: 8px !important;
  div[class~='ant-select-item'] {
    background-color: $white;
    padding: setUnit(12) setUnit(24);
    @include transition;
    &:hover {
      background-color: $background-grey-2;
    }
    div[class~='ant-select-item-option-content'] {
      display: flex;
      align-items: center;
      .multiAvatar {
        position: relative;
        width: setUnit(34);
        height: setUnit(40);
        .avatar {
          width: setUnit(32) !important;
          height: setUnit(32) !important;
          border-radius: 8px !important;
          span[class~='ant-avatar-string'] {
            flex: 0 0 100%;
          }
        }
        .avatarWrap {
          position: relative;
          width: 28px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;
          &:last-child {
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .overlay {
            font-weight: 700;
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $white;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
          }
        }
      }
      .avatar {
        width: setUnit(32) !important;
        height: setUnit(32) !important;
        border-radius: 8px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        span[class~='ant-avatar-string'] {
          font-size: setUnit(18);
        }
      }
      .contentArea {
        margin: 0 10px;
        max-width: calc(100% - 72px);
        letter-spacing: -0.09px;
        color: $text-color;
        line-height: setUnit(16);

        .info {
          .singleAgentName {
            margin-bottom: 0.5rem !important;
            max-width: 155px !important;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin-bottom: 0 !important;
          }
          .email,
          .brokerage,
          .contactNumber {
            font-size: 14px !important;
            font-weight: 400 !important;
            margin-bottom: 0.25rem !important;
          }
          .email {
            color: $link-blue;
            font-weight: 400;
          }
        }

        .name {
          display: block;
          font-size: setUnit(16);
          font-weight: 600;
        }
        .teamWrap {
          display: block;
          white-space: normal;
          margin-top: setUnit(4);
          .teamName {
            font-weight: 400;
            font-size: setUnit(14);
          }
        }
      }
      .icon {
        display: flex;
        svg {
          width: setUnit(20);
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  div[class~='ant-select-item-option-selected'] {
    div[class~='ant-select-item-option-content'] {
      .icon {
        svg {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.contextSelectorClient {
  @media (min-width: $responsive-search) {
    position: absolute;
    left: setUnit(250);
  }
}
