@import 'styles/variables';
@import 'styles/functions';

.headerWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: setUnit(16);
  .headerLeft {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .avatar {
      margin-right: setUnit(12);
    }
    .title {
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(24);
      letter-spacing: setUnit(-0.33);
      margin-bottom: 0;
    }
    .tourCount {
      font-size: setUnit(16);
      line-height: setUnit(24);
      color: $dark-grey-color;
      margin-left: setUnit(6);
      font-weight: 600;
      letter-spacing: setUnit(-0.33);
      margin-bottom: 0;
    }
  }
  .headerRight {
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: $white;
      transition: $transition;
      width: setUnit(32);
      height: setUnit(32);
      &[class~='ant-dropdown-open'] {
        background-color: $light-gray;
      }
      .actionsDropdown {
        ul {
          width: setUnit(236);
          box-shadow: 0px 2px 12px 0px #00000026;
          border-radius: setUnit(8);
          margin: 0;
          .actionItem {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            cursor: pointer;
            padding: setUnit(12) setUnit(16);
            background-color: $white;
            transition: $transition;
            &:hover {
              background-color: $background-grey-2;
            }
            svg {
              margin-right: setUnit(8);
            }
            span {
              font-size: setUnit(14);
              font-weight: 400;
              line-height: setUnit(16);
              letter-spacing: setUnit(-0.09);
            }
          }
        }
      }
    }
  }
}
