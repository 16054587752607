@import 'styles/functions';
@import 'styles/mixins';
@import 'styles/variables';

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: setUnit(32);
}

.searchButton {
  width: setUnit(46);
  height: setUnit(46);
  margin-left: setUnit(32);
  background-color: $background-ligh-gray !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(24);

    path {
      stroke: $text-color;
    }
  }
}

.input {
  max-width: setUnit(200);
}

.tableWrapper {
  overflow-x: auto;
}

.tableText {
  font-size: setUnit(15);
  font-weight: 400;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: setUnit(15) setUnit(0);

  .membersTitle {
    font-size: setUnit(18);
    font-weight: 700;

    .membersCount {
      color: $text--light-gray-color;
    }
  }
}

.deactivateBtn {
  width: 100px;
  background: $primary-color;
  padding: setUnit(12) setUnit(16);
  border-radius: 100px;
  color: $white;
  font-size: setUnit(13);
  font-weight: 600;
  cursor: pointer;
}

.reactivateBtn {
  width: 100px;
  background: $orange;
  padding: setUnit(12) setUnit(16);
  border-radius: 100px;
  color: $white;
  font-size: setUnit(13);
  font-weight: 600;
  cursor: pointer;
}

.userStatusConfirmText {
  text-align: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
  }
}

.mlsCount {
  color: $link-blue;
  font-size: setUnit(15);
  font-weight: 400;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  text-decoration: underline;
}
.actions {
  display: flex;

  .searchWrapper {
    margin-right: setUnit(20);
    .inputWrap {
      background: $white;
      border: 2px solid $border-light-gray-2;
      border-radius: setUnit(26);
      width: setUnit(200) !important;
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .input {
        &:focus {
          background: $white;
          border: none !important;
          box-shadow: none;
        }
      }
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      @media (max-width: 991px) {
        height: setUnit(36);
      }
      &:focus {
        border-width: 2px;
      }
      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }
      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
      }
    }
  }
}

.tcRight {
  margin-left: setUnit(8);
  .dropdownTrigger {
    cursor: pointer;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $background-ligh-gray;
    }
  }
  .transactionMoreOptions {
    @include box-shadow;
    background: $white;
    border-radius: setUnit(8);
    width: 11rem;
    [class*='ant-dropdown-menu'] {
      padding: 0;
      border-radius: setUnit(8);
    }
    .dropdownItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: setUnit(10) setUnit(22);
      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
        svg {
          width: setUnit(24);
          height: setUnit(24);
          path,
          circle {
            stroke: $primary-color;
          }
        }
      }
      &:hover {
        background: $background-ligh-gray;
      }
      svg {
        vertical-align: middle;
      }
    }
  }
}

.loginLink {
  color: $link-blue;
  font-size: setUnit(15);
  font-weight: 400;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
  text-decoration: underline;
  cursor: pointer;
}

.inviteBtn {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.09px;
  padding: 12px 16px;
  border-radius: 100px;
  background: $secondary-color;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  margin-left: 20px;
}
