@import 'styles/functions';
@import 'styles/variables';

.noComments {
  margin-top: setUnit(64);

  .noListing {
    text-align: center;
    font-weight: 600;
    color: $border-middle-gray;
  }

  .newComment {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: setUnit(100);
    border: 2px solid #e8e9ea;
    max-width: setUnit(178);
    width: 100%;
    height: setUnit(52);
    font-weight: 600;
    color: #252d44;
    margin: setUnit(32) auto 0;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    .editIcon {
      position: relative;
      margin-right: setUnit(4);
      svg {
        vertical-align: middle;
        path {
          stroke: $primary-color;
        }
      }
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: setUnit(16);
    }
  }
}
