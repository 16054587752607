@import 'styles/functions';
@import 'styles/variables';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: setUnit(64);

  &:last-child {
    border-bottom: 1px solid $border-light-gray;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: setUnit(16);
    svg {
      width: setUnit(32);
      height: setUnit(32);
      path,
      circle,
      rect {
        stroke: $border-middle-gray;
      }
    }
  }

  .label {
    margin-bottom: 0;
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(20);
    letter-spacing: setUnit(-0.18);
    color: $text-color;
  }
}
