@import 'styles/functions';
@import 'styles/variables';

.mainWrapper {
  position: relative;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  display: flex;
  flex-direction: column;

  .headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleWrapper {
      margin-top: 20px;
      margin-left: 24px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .title {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.33px;
      }
      .count {
        color: var(--Grayscale-40, #aaabab);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.33px;
      }
    }

    .link {
      margin-top: 24px;
      margin-right: 24px;
      color: var(--REBRAND-Secondary-Link-Blue, #2b59da);
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.18px;
    }
  }
}

.cardRightGradient {
  position: absolute;
  height: 220px;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 56px;
  flex-shrink: 0;
  opacity: 0.8;
  background: linear-gradient(270deg, #fff 35.16%, rgba(255, 255, 255, 0) 100%);
}

.sliderContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;

  &.flexEnd {
    justify-content: flex-end;
  }

  &:after {
    content: '';
    position: absolute;
    height: 60%;
    width: 1px;
    background-color: $light-gray;
    top: 20%;
    right: -5px;
  }

  &.noBorder {
    &:after {
      content: none;
    }
  }

  .rightTabSectionBtn {
    flex-direction: row !important;
    gap: setUnit(5);
    margin: 0 0.25rem !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;

    &:hover {
      border-radius: 8px;
      background-color: $background-ligh-gray-3 !important;
    }

    .btnText {
      font-weight: 600 !important;
      margin-bottom: setUnit(3);
    }
  }

  .tabButton {
    border: none !important;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: setUnit(68);
    padding: setUnit(36) setUnit(10);
    margin: 0 setUnit(16);
    font-weight: 500 !important;
    outline: none !important;

    &.active {
      border-bottom: 2px solid $primary-color !important;
      &:hover {
        border-bottom: 2px solid $primary-color !important;
      }
      .btnText {
        color: $text-color !important;
        font-weight: 600 !important;
      }
      svg {
        path {
          stroke: $text-color;
        }
      }
    }

    &:hover,
    &:focus {
      .btnText {
        color: $text-color !important;
      }
      svg {
        path {
          stroke: $text-color;
        }
      }
    }

    .btnText {
      font-size: 13px;
      line-height: 20px;
      margin-top: setUnit(5);
      font-weight: 400;
      color: $dark-color;
    }
  }
  .closeBtn {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.scrollable {
    .arrowSpacing {
      div[class~='slick-prev'] {
        left: 20px !important;
      }
      div[class~='slick-next'] {
        right: 10px !important;
        svg {
          transform: scaleX(-1);
        }
      }
    }

    .subfeedSlider {
      position: relative;
      width: 100%;
      padding-left: 20px;

      .tabButtonContainer {
        display: flex;
        justify-content: center;
      }

      div[class*='slick-slide'] {
        display: flex;
        justify-content: center;
      }

      div[class~='slick-list'] {
        overflow: hidden;
        div[class~='slick-track'] {
          display: flex;
          justify-content: center;
          gap: 0 8px;
        }
      }

      div[class~='slick-arrow'] {
        opacity: 1;
        visibility: visible;
        width: setUnit(40);
        height: setUnit(40);
        border-radius: setUnit(100);
        background-color: $white;
        border: 2px solid $light-gray;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        .icon {
          display: flex;
          svg {
            width: setUnit(16);
            height: setUnit(16);
            path {
              stroke: $dark-color;
            }
          }
        }
      }
      div[class~='slick-prev'] {
        left: 0;
      }
      div[class~='slick-next'] {
        right: 0;
        svg {
          transform: scaleX(-1);
        }
      }
      div[class~='slick-disabled'] {
        opacity: 0;
        visibility: hidden;
        display: none;
        transition: $transition;
      }
      .buttonCheckbox {
        position: relative;
        display: inline-flex !important;
        justify-content: center;
        margin-right: 0;
        span[class~='ant-radio'] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $background-grey-2;
          border-radius: setUnit(18);
          transition: $transition;
          span[class~='ant-radio-inner'] {
            display: none;
          }
          & + span {
            position: relative;
            z-index: 1;
            color: $dark-grey-color;
            font-size: setUnit(12);
            font-weight: 500;
            line-height: setUnit(20);
            letter-spacing: setUnit(-0.09);
            padding: setUnit(8) setUnit(14);
            display: block;
            transition: $transition;
          }
        }
        span[class~='ant-radio-checked'] {
          background-color: $background-blue;
          & + span {
            color: $white;
          }
          &:after {
            content: none;
          }
        }
        &:hover {
          span[class~='ant-radio'] {
            background-color: $background-ligh-gray-4;
            & + span {
              color: $dark-grey-color;
            }
          }
        }
      }
    }
  }
}

.skeletonCard {
  width: 200px;
  height: 198px;
  border-radius: 16px;
  border: 1px solid $light-gray;

  .image {
    width: 100%;
    height: 104px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    > div {
      width: 100% !important;
      height: 100% !important;
      border-radius: 16px;

      > span {
        width: 100% !important;
        height: 100% !important;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }
    }
  }
  .dataContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 0px 10px;
  }
  .data1 {
    width: 80%;

    span {
      width: 80%;
      height: 20px;
      border-radius: 16px;
    }
  }
  .data2 {
    width: 80%;
    span {
      border-radius: 16px;
      width: 80%;
      height: 20px;
    }
  }
}
