@import 'styles/mixins';
@import 'styles/variables';

.propertiesEmptyContainer {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;

  .title {
    font-size: 20px;
    font-weight: 600;
    color: $dark-color;
  }
  .subtitle {
    color: $text-gray-color;
    max-width: 550px;
    text-align: center;
  }
}
