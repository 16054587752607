@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.optionsWrapper {
  position: relative;

  .optionsDots {
    cursor: pointer;
    width: setUnit(40);
    height: setUnit(40);
    border-radius: 50%;
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(48);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;

    ul {
      list-style: none;
      margin-bottom: 0rem;
    }

    .item {
      padding: setUnit(10) setUnit(22) setUnit(8) setUnit(22);
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
      }

      &:hover {
        background: $background-ligh-gray;
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}
