@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.focusedProperty {
  border: 2px solid $primary-color;
  border-radius: 1rem;
}

.propertyWrap {
  max-width: 100%;
  width: 100%;
  height: 400px;
  padding: setUnit(8);
  .property {
    @include box-shadow;
    position: relative;
    cursor: pointer;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    &.checked {
      border: 2px solid $primary-color;
    }

    &.pointerEventsNone {
      cursor: not-allowed;
    }

    .photo,
    .infoBlock {
      height: 50%;
    }

    .infoBlock {
      padding: 0.75rem 1rem;
      background: $white;
    }

    .bottomBlock {
      margin-top: 1rem;
    }

    .status {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }
}
