@import 'styles/functions';
@import 'styles/variables';

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: setUnit(32);
}

.searchButton {
  width: setUnit(46);
  height: setUnit(46);
  margin-left: setUnit(32);
  background-color: $background-ligh-gray !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light-gray !important;
  }

  svg {
    width: setUnit(24);

    path {
      stroke: $text-color;
    }
  }
}

.input {
  max-width: setUnit(200);
}

.tableWrapper {
  overflow-x: auto;
}

.tableText {
  font-size: setUnit(15);
  font-weight: 400;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: setUnit(15) setUnit(0);

  .membersTitle {
    font-size: setUnit(18);
    font-weight: 700;

    .membersCount {
      color: $text--light-gray-color;
    }
  }
}

.deactivateBtn {
  width: 100px;
  background: $primary-color;
  padding: setUnit(12) setUnit(16);
  border-radius: 100px;
  color: $white;
  font-size: setUnit(13);
  font-weight: 600;
  cursor: pointer;
}

.reactivateBtn {
  width: 100px;
  background: $orange;
  padding: setUnit(12) setUnit(16);
  border-radius: 100px;
  color: $white;
  font-size: setUnit(13);
  font-weight: 600;
  cursor: pointer;
}

.mlsCount {
  color: $text-color;
  font-size: setUnit(17);
  font-weight: 700;
  line-height: setUnit(20);
  letter-spacing: -0.18px;
}

.mlsTitle {
  font-size: setUnit(24);
  font-weight: 700;
  margin-top: setUnit(5);
}

.mlsBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: setUnit(20) setUnit(0);
}

.actionBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.filterWrapper {
  display: flex;
  gap: 24px;

  .searchWrapper {
    .inputWrap {
      background: $white;
      border: 2px solid $border-light-gray-2;
      border-radius: setUnit(26);
      width: setUnit(200) !important;
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .input {
        &:focus {
          background: $white;
          border: none !important;
          box-shadow: none;
        }
      }
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      @media (max-width: 991px) {
        height: setUnit(36);
      }
      &:focus {
        border-width: 2px;
      }
      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }
      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
      }
    }
  }
}
