@import 'styles/functions';
@import 'styles/variables';

.mySearchesHeader {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-bottom: setUnit(15);
  @media (min-width: $mobile) {
    position: absolute;
    top: setUnit(-95);
    margin-bottom: 0;
    width: auto;
  }
  .btn {
    height: setUnit(44);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
    position: relative;
    margin-right: 0.5rem;
    padding: 0 1rem;
    font-weight: 600;

    svg {
      margin-top: 0.4rem;
    }

    &:hover {
      border: 2px solid $text--light-gray-color;
    }

    .addIcon {
      margin-left: -0.4rem;
      margin-right: 0.25rem;
    }
  }
}
