@import 'styles/functions';
@import 'styles/variables';

.footer {
  width: 100%;
  padding: setUnit(16) setUnit(32);
  background: $background-ligh-gray;
  border: 1px solid $grey-color;
  @media (max-width: 767.99px) {
    position: fixed;
    bottom: 0px;
    max-width: setUnit(480);
  }
  .submitButton {
    width: setUnit(102);
    margin-left: auto;
    display: block;
  }
}
