@import 'styles/functions';
@import 'styles/variables';

.dropzone {
  .error {
    color: $error-color;
    font-size: setUnit(12);
    padding-top: setUnit(8);
  }
  width: 100%;

  .text {
    color: $dark-grey-color;
  }

  .wrapper {
    width: 100%;
    height: setUnit(260);
    border: 2px dashed $border-input-middle-gray;
    border-radius: setUnit(8);
    cursor: pointer;

    &.noClick {
      cursor: auto;
    }
  }

  .innerHolder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-ligh-gray;
    border-radius: setUnit(7);
  }
}
