@import "styles/functions";
@import "styles/variables";

.header {
    padding: setUnit(32);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        display: flex;
        align-items: center;

        svg {
            vertical-align: middle;
        }

        .editText {
            margin-left: setUnit(16);
            font-size: setUnit(20);
            font-weight: 600;
        }
    }

    .close {
        width: setUnit(40);
        height: setUnit(40);
        border-radius: 50%;
        background: $background-ligh-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
