@import "styles/functions";
@import "styles/variables";

.wrapper {
    width: 100%;
}

.title {
    max-width: setUnit(640);
    padding-bottom: setUnit(33);
    margin-bottom: setUnit(33);

    &> h2 {
        font-size: setUnit(24);
        color: $secondary-color;
        font-weight: 600;
        margin-right: setUnit(156);
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
}

.saveBtn {
    height: setUnit(40) !important;
    width: setUnit(100) !important;
    transition: all .1s;
    border-radius: setUnit(100);
    display: flex;
    justify-content: center;
    align-items: center;
}

.editBtn {
    border: 0;
    outline: none;
    border-radius: 100%;
    width: setUnit(40);
    height: setUnit(40);
    background-color: $primary-color;
    cursor: pointer;
    transition: all .1s;

    &:hover {
        background-color: darken($primary-color, 10%);
    }
}

.label {
    flex: 1;
    font-size: setUnit(14);
    color: $dark-grey-color;
    align-self: flex-start;
    padding-top: setUnit(14);
}

.editLabel {
    flex: 1;
    font-size: setUnit(14);
    color: $dark-grey-color;
    align-self: flex-start;
    padding-top: setUnit(30);
    font-weight: normal !important;
}

.inputBlock {
    display: flex;
    flex-wrap: wrap;
    min-height: setUnit(44);
    align-items: center;
    margin-bottom: setUnit(10);

    .rightBlock {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding-top: setUnit(10);

        .input {
            margin: 0 0 setUnit(10) 0 !important;
        }
    }

    .locationsBlock {
        max-width: 75%;
    }

    .infoBlockForOneLine {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding: setUnit(10) 0;
        border-bottom: 2px $light-gray solid;
    }

    .infoBlockForMultipleLines {
        flex: 3;
        color: $text-color;
        font-size: setUnit(16);
        line-height: setUnit(21);
        border-bottom: 2px $light-gray solid;
    }

    .nonEditableValue {
        padding: setUnit(10) 0;

        &:not(:last-child) {
            margin-bottom: setUnit(2);
        }

        a {
            color: $text-color;
            transition: all .1s;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.selectWrapper {
    margin: 0 !important;
}

.flexWrapper {
    display: flex;
    justify-content: flex-start;
}

.checkbox {
    margin-right: setUnit(40);
}

.checkboxLabel {
    font-size: setUnit(14);
    color: $dark-grey-color;
    margin-left: setUnit(8);
}

.error {
    color: $error-color;
    &:hover {
        text-decoration: none !important;
    }
}
