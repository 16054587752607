@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filterWrap {
  margin-left: 0.625rem;
  position: relative;
  width: fit-content;
  z-index: 1;

  .filterButton {
    height: setUnit(44);
    padding: 0 10px;

    @media screen and (max-width: $mobile) {
      border: unset;
    }

    .optionIcon {
      margin-right: setUnit(4);
      display: flex;
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }
    }
    .icon {
      display: flex;
      transition: $transition;
      margin-left: 5px;

      @media screen and (max-width: $mobile) {
        display: none;
      }

      svg {
        width: setUnit(12);
        height: setUnit(6);
        path {
          stroke: $dark-color;
        }
      }
    }
    .optionValue {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.09px;
      color: #515151;

      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
    &[class~='ant-dropdown-open'] {
      backdrop-filter: blur(12px);
      border-color: $text-gray-color;
      background-color: $background-grey-2;
      .icon {
        transform: rotate(180deg);
      }
    }
  }
  .teamFilterDropdown {
    width: setUnit(240);
    .optionsItems {
      width: 100%;
      @media screen and (max-width: $mobile) {
        width: fit-content;
        position: inherit;
        right: 0;
      }
      ul[class~='ant-dropdown-menu'] {
        border-radius: setUnit(8);
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
        padding: setUnit(8) 0;

        li {
          padding: 0;
          label[class~='ant-radio-wrapper'] {
            display: flex;
            margin-right: 0;
            transition: $transition;
            padding: setUnit(14) setUnit(24);
            background-color: $white;
            height: setUnit(56);
            &:hover {
              background-color: $background-grey-2;
            }
            span[class~='ant-radio'] {
              display: none;
              & + span {
                flex: 0 0 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                .option {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  .dropdownOptionIcon {
                    margin-right: setUnit(8);
                    display: flex;
                  }
                  .optionValue {
                    font-weight: 500;
                    font-size: setUnit(16);
                    line-height: setUnit(16);
                    letter-spacing: -0.09px;
                    color: $text-color;
                  }
                }
                .checkIcon {
                  transition: $transition;
                  height: setUnit(20);
                  color: $green-color;
                  svg {
                    width: setUnit(20);
                    height: setUnit(20);
                    path {
                      stroke: $green-color;
                    }
                  }
                }
              }
            }
            &[class~='ant-radio-wrapper-checked'] {
              background-color: $background-grey-2;
              span[class~='ant-radio-checked'] {
                & + span {
                  .checkIcon {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
