@import 'styles/functions';
@import 'styles/variables';

.selectorContainer {
  margin-top: 0.5rem;
  margin-left: setUnit(10);
  width: 100%;
  .selector {
    margin-bottom: 0.5rem;
    width: 100%;
    div[class~='ant-select-selector'] {
      border: unset;
      border-bottom: 2px solid $border-input-middle-gray;
      box-shadow: none !important;
      span[class~='ant-select-selection-item'] {
        border: 1px solid $border-light-gray-2;
        border-radius: setUnit(100);
        height: setUnit(32);
        padding: setUnit(6) setUnit(12);
        margin: 0 setUnit(8) setUnit(6) 0;
        span[class~='ant-select-selection-item-content'] {
          color: $text-color;
          font-size: setUnit(16);
          font-weight: 400;
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.18);
        }
        span[class~='ant-select-selection-item-remove'] {
          color: $text--light-gray-color;
          font-size: setUnit(16);
          font-weight: 400;
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.18);
        }
      }
    }
    &[class~='ant-select-focused'] {
      div[class~='ant-select-selector'] {
        border-color: $border-focused;
      }
    }
  }
}
.availabilityForm {
  padding: setUnit(20);
  border: 1px solid $light-gray;
  border-left-color: $primary-color;
  border-left-width: 3px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: setUnit(16);
  }
  .iconWrapper:first-child {
    padding-right: setUnit(8);
  }
  .iconWrapper:last-child {
    padding-left: setUnit(8);
  }
  .days {
    padding: 0 setUnit(12) 0 setUnit(8);
  }
  .time {
    padding: 0 setUnit(8) 0 setUnit(12);
  }

  .deleteIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: setUnit(32);
    height: setUnit(32);
  }
  .dayTimeWrap {
    max-width: calc((100% - #{setUnit(40)}));
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .days {
    margin-bottom: setUnit(24);
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  .days,
  .time {
    max-width: 100%;
    width: 100%;
    @media (min-width: 768px) {
      max-width: 50%;
    }
  }
  .approvalWrap,
  .noticeWrap {
    margin-top: setUnit(24);
    .notice {
      input {
        padding-left: 0;
      }
    }
  }

  .time-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .approvalInput {
    height: 1.85rem;
    border-bottom: 2px solid $border-input-middle-gray;
    position: relative;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      top: 0.2rem;
    }
  }

  .timeInput {
    margin-right: 0;
    .timeSelect {
      .timeDropdown {
        padding: 0;
        background: $white;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 71px !important;
        div[class~='ant-select-item-option'] {
          padding: 12px 16px;
          display: flex;
          flex-flow: row-reverse;
          position: relative;
          div[class~='ant-select-item-option-content'] {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.09px;
            color: $text-color;
            display: flex;
            align-items: center;
          }
          &[class~='ant-select-item-option-selected'] {
            background-color: $background-grey-2;
            [class~='ant-select-item-option-state'] {
              border-color: $primary-color;
              background-color: $primary-color;
              color: $white;
            }
            [class~='ant-select-item-option-content'] {
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  .separator {
    position: relative;
    margin: 0 setUnit(8) 0 setUnit(-2);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $dark-color;
    display: flex;
    .required {
      color: $primary-color;
      order: 2;
      margin-left: 4px;
    }
  }

  .selector {
    width: 100%;

    & [class*='ant-select-selector'] {
      border: unset !important;
      border-bottom: 2px solid $border-input-middle-gray !important;
      box-shadow: none !important;
    }

    .customTag {
      position: relative;
      box-sizing: border-box;
      max-width: 100%;
      height: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
      line-height: 22px;
      background: #f5f5f5;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      transition: font-size 0.3s, line-height 0.3s, height 0.3s;
      user-select: none;
      -webkit-margin-end: 4px;
      margin-inline-end: 4px;
      -webkit-padding-start: 8px;
      padding-inline-start: 8px;
      -webkit-padding-end: 4px;
      padding-inline-end: 4px;
      font-size: setUnit(14);
    }
  }

  [class*='ant-input-number'],
  [class*='ant-picker'] {
    border: 0;
    border-radius: 0;
    width: 5.75rem;

    input {
      border-bottom: 2px solid $border-input-middle-gray;
    }
  }

  [class*='ant-picker'] {
    input {
      padding-bottom: 0.25rem;

      &:focus,
      &:hover {
        border-color: $border-input-middle-gray;
      }
    }
  }

  [class*='ant-picker-suffix'],
  [class*='ant-picker-clear'] {
    display: none;
  }

  [class*='ant-picker-active-bar'] {
    background: unset !important;
  }

  [class*='ant-input-number'] {
    width: 100%;

    [class*='ant-input-number-handler-wrap'] {
      display: none;
    }
  }

  [class*='ant-input-number-focused'],
  [class*='ant-picker-focused'] {
    box-shadow: none;
  }
}

.timePicker {
  [class*='ant-picker-now'] {
    display: none;
  }
}

.approvalDropdown {
  min-width: 242px;
  padding: 24px;
  .approvalParticipant {
    max-height: 250px;
    overflow: scroll;
  }
  .radioGroupContainer {
    display: flex;
    flex-direction: column;
    .toggleRadioContainer {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      .radio {
        [class*='ant-checkbox-inner'] {
          border-radius: 50%;
          &:hover {
            border-radius: 50%;
          }
        }
      }
      h4 {
        font-weight: 400;
        display: inline-block;
        margin: 0;
        margin-left: 10px;
      }
    }
  }
  .sequentialApprovalContainer {
    display: flex;
    h4 {
      font-weight: 400;
      display: inline-block;
      margin: 0;
      margin-right: 10px;
    }

    .switch {
      float: left;
      margin-right: 10px;
    }

    [class*='ant-switch-checked'] {
      background-color: $primary-color;
    }
  }
  .toggleContainer {
    display: flex;
    padding-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 1rem;
    h4 {
      font-weight: 400;
      display: inline-block;
      margin: 0;
    }

    .switch {
      float: right;
    }

    [class*='ant-switch-checked'] {
      background-color: $primary-color;
    }
  }

  .subTitle {
    color: $text-color;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
  }

  .info {
    width: 224px;
    font-size: 12px;
    color: $text-gray-color;
    margin: 0.5rem 0 0;
  }

  .disabled {
    opacity: 0.5;
  }
}

[class*='ant-popover-arrow'] {
  display: none !important;
}
.fullAccessTooltip {
  [class*='ant-tooltip-inner'] {
    width: setUnit(368);
    padding: setUnit(20);
    border-radius: 1rem;
    background-color: $text-color;

    .content {
      p {
        margin-bottom: 0;
      }

      span {
        font-weight: 700;
      }

      .inline {
        display: inline-block;
        margin-right: 0.75rem;
      }

      .tasks {
        .taskCheckMark {
          position: relative;
          top: -1.35rem;
        }

        p {
          width: 86%;
        }
      }
    }
  }
}

.collapseWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 15px 24px;

  &:not(:last-child) {
    margin-bottom: setUnit(16);
  }

  h4 {
    margin: 0;
    font-size: setUnit(16);
    line-height: setUnit(20);
    letter-spacing: -0.18px;
    color: $text-color;
  }

  p {
    margin: 2px 0 0;
    line-height: setUnit(20);
    letter-spacing: -0.18px;
    font-size: setUnit(14);
    color: $dark-color;
  }

  .editIcon {
    background-color: $background-grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: setUnit(50);
    cursor: pointer;
  }
}
