@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.enableLink {
  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(25);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    margin-top: setUnit(20);

    .loadingSpinner {
      font-size: 0.5em;
      margin: 50px auto;
    }

    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .title {
      font-weight: 600;
      line-height: 21px;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-color;

      .linkIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
    }

    .subTitle {
      color: $text-gray-color;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin: -0.25rem 0 1.5rem;
      padding: setUnit(15);
      border: 1px solid $light-gray;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .linkContainer {
      margin-bottom: 1rem;
      padding-bottom: 0.75rem;
      border-bottom: 2px solid $border-light-gray-2;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      a {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: setUnit(19);
        max-width: calc(100% - #{setUnit(100)});
      }

      .copyBtn {
        cursor: pointer;
        padding: setUnit(8) setUnit(6);
        border-radius: setUnit(8);
        background-color: transparent;
        transition: $transition;
        &:hover {
          background-color: $background-grey-2;
        }
        &:active {
          background-color: $light-gray;
        }

        .copyIcon {
          display: inline-block;
          margin-right: 0.25rem;
        }

        span {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(20);
          color: $secondary-color;
        }
      }
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}
