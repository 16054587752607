@import 'styles/functions';
@import 'styles/variables';

.baseNotification {
  display: flex;
  padding: setUnit(12) setUnit(32);
  cursor: pointer;
  position: relative;
  transition: all 0.3s;

  .title {
    flex: 1;
    font-size: setUnit(16);
    line-height: setUnit(18);
    letter-spacing: setUnit(-0.18);
    &.isRead {
      font-weight: 400;
    }
    &.markable {
      font-weight: 400;
    }
  }
  .notificationSummaryGroup {
    display: flex;
    margin-bottom: 12px;
  }

  .notificationSummaryTitle {
    flex: 1;
    font-size: setUnit(16);
    line-height: setUnit(18);
    letter-spacing: setUnit(-0.18);
    &.isRead {
      font-weight: 400;
    }
    &.markable {
      font-weight: 400;
    }
  }
  .unreadDot {
    width: setUnit(6);
    height: setUnit(6);
    background: $primary-color;
    border-radius: 50%;
    position: absolute;
    left: setUnit(16);
    top: setUnit(29);
  }

  .arrow {
    display: flex;
    svg {
      width: setUnit(20);
      height: setUnit(20);
    }
  }

  &:hover {
    background: $background-grey-2;
  }

  .block {
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-right: 6px;
    width: 100%;
    .inner {
      display: flex;
    }
    .holder {
      width: 100%;
    }
  }

  .avatarWrapper {
    font-size: setUnit(17);
    line-height: setUnit(23);
    .avatar {
      width: setUnit(40);
      height: setUnit(40);
      img {
        width: setUnit(32);
        height: setUnit(32);
        filter: brightness(0) invert(1);
      }
    }
  }

  .date {
    font-size: setUnit(12);
    font-weight: 400;
    color: $dark-color;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .tag {
    border-radius: 40px;
    padding: setUnit(4) setUnit(8);
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    border: none;
    text-align: center;
    margin-right: 0;
  }

  .tagAlert {
    background-color: rgba(251, 145, 58, 0.14);
    color: $alert;
  }

  .tagUrgent {
    background-color: $error-color;
    color: $white;
  }
}
