@import 'styles/functions';
@import 'styles/variables';

.activityLogWrapper {
  background: $white;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.03);
  border-radius: setUnit(12);
  overflow: hidden;
  .activityLog {
    padding: setUnit(24);
    &:not(:first-child) {
      padding-top: 0;
    }
    .activityDay {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.33px;
      color: $text-color;
    }
    .noActivity {
      color: $text--light-gray-color;
      margin-bottom: 0;
      padding: setUnit(100) 0;
      text-align: center;
    }
  }
}
