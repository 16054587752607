@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.title {
    @include step-title();
    max-width: setUnit(646);
    text-align: center;
    margin: 0 auto setUnit(32);
}
