@import 'styles/mixins';
@import 'styles/variables';

.linkFormsTable {
  margin-top: 24px;

  [class*='ant-table-thead'] {
    tr {
      th {
        background-color: #fff;
        font-size: setUnit(14);
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.18px;
        text-align: left;
        color: $text-gray-color;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0;
        &::before {
          display: none;
        }
      }

      [class~='ant-table-cell-scrollbar'] {
        box-shadow: unset;
      }

      [class~='ant-table-selection-column'] {
        [class~='ant-table-selection'] {
          display: none;
        }
      }
    }
  }

  [class~='ant-table-tbody'] {
    [class~='ant-table-row-selected'] {
      td {
        background-color: $white;
      }

      &:hover {
        td {
          background-color: $primary-color-light;
        }
      }
    }

    tr {
      td {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
      }

      [class~='ant-table-selection-column'] {
        padding-right: 0px;
        text-align: right;
      }
    }
  }

  .formLibraryName {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: setUnit(16);
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
  }

  [class*='ant-checkbox'] {
    [class*='ant-checkbox-inner'] {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border-color: $text--light-gray-color !important;
    }

    &:hover {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color !important;
      }
    }
  }

  [class~='ant-checkbox-indeterminate'] {
    [class*='ant-checkbox-inner'] {
      &::after {
        background-color: $primary-color;
        width: 13px;
        height: 13px;
      }
    }
  }

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color !important;
      border-radius: 4px;
    }
    [class*='ant-checkbox-inner']::after {
      width: 7px;
      height: 12px;
      left: 25%;
      top: 45%;
    }

    &::after {
      border-color: $primary-color;
      border-radius: 4px;
    }
  }

  .tablePagination {
    background-color: #fff;
    margin-bottom: 0px !important;

    [class~='ant-pagination-item-active'] {
      border-color: $primary-color;

      a {
        color: $primary-color;
      }
    }

    [class~='ant-pagination-item'] {
      &:hover {
        border-color: $primary-color;

        a {
          color: $primary-color;
        }
      }
    }

    :is([class~='ant-pagination-next'], [class~='ant-pagination-prev']):not([class~='ant-pagination-disabled']) {
      &:hover {
        [class~='ant-pagination-item-link'] {
          border-color: $primary-color;

          span {
            color: $primary-color;
          }
        }
      }
    }
  }

  [class~='ant-table-container'] {
    &::before,
    &::after {
      box-shadow: unset !important;
    }
  }

  .smartFormCheckbox {
    display: none;
  }
}
