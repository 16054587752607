@import 'styles/variables';
@import 'styles/functions';

.noTemplates {
  padding-top: setUnit(80);
  color: $text--light-gray-color;
  font-size: setUnit(16);
  line-height: setUnit(21);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid $border-light-gray;
  margin-top: -4px;
}

.noTemplatesIcon {
  margin-bottom: setUnit(24);
}
