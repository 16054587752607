@import 'styles/functions';
@import 'styles/variables';

.workshopHeader {
  position: absolute;
  top: setUnit(160);
  left: 0;
  right: 0;
  padding: 12px 40px;

  .workshopHeaderLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;

    @media (min-width: $tablet) {
      justify-content: flex-start;
    }
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  .searchWrapper {
    .inputWrap {
      background: $white;
      border: 2px solid $light-gray;
      border-radius: setUnit(26);
      width: setUnit(44);
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      @media (max-width: 991px) {
        height: setUnit(36);
      }
      &:focus {
        border-width: 2px;
      }
      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }
      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
      }
    }
  }

  .filterWrapper {
    display: flex;
    align-items: center;
  }

  .actionsRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;

    @media screen and (max-width: 991px) {
      position: absolute;
      margin: 0 setUnit(15) 0 0;
      top: setUnit(30);
      right: 0;
      justify-content: flex-end;
    }

    .btn {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border: 2px solid $light-gray;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: $dark-color;

      &.newBtn {
        background-color: $secondary-color;
        color: $white;
        border: none;

        &:hover {
          border: none;
          background-color: 2px solid $secondary-color-hover;
        }
      }

      .addIcon {
        svg {
          path {
            stroke: $white;
          }
        }
      }

      .iconText {
        margin-left: setUnit(5);
      }

      &.filterBtn {
        width: fit-content;
        padding: 0 1rem;
      }

      width: setUnit(36);
      height: setUnit(36);
      @media (min-width: $tablet) {
        height: setUnit(44);
        width: setUnit(44);
      }
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      &.btnText {
        width: auto;
        padding: 0 setUnit(16);
      }
    }

    .selected {
      background-color: $background-blue;
    }

    @media (max-width: $mobile) {
      right: unset;
      justify-content: center;
      width: 100%;
    }
  }
}
