@import 'styles/variables';

.logicPane {
  height: 100%;
  overflow-y: auto;
  .choiceRowFirst {
    margin-top: 12px;
  }
  .choiceRow {
    margin-top: 32px;
  }
  .label {
    color: $text-color;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .requiredRow {
    margin-top: 44px;
    align-items: center;
  }
  .formRow {
    margin-top: 24px;
  }

  .checkBoxIcon {
    width: 24px !important;
    height: 24px !important;
  }
  .questionTypeOption {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    .questionLogo {
      margin-right: 10px;
      display: flex;
    }
  }
  .selectBox {
    width: 100%;
    margin-top: 5px;
  }
  .noOptions {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text--light-gray-color;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0px 44px;
  }
}
