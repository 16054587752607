@import 'styles/functions';
@import 'styles/variables';

.confirmModal {
  .content {
    max-width: setUnit(420);
    width: 100%;
    height: fit-content;
    border-radius: setUnit(24);
    padding: 40px 40px 32px;
  }

  .defaultIcon {
    width: setUnit(56);
    height: setUnit(56);
    border-radius: 50%;
    margin: 0 auto setUnit(24);
    background: rgba($error-color, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: $text-color;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    text-align: center;
  }

  .actions {
    margin-top: setUnit(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 24px;

    .btn {
      width: calc(50% - 12px);
      height: setUnit(52);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.18px;
      transition: $transition;
      &.cancel {
        background-color: $background-grey-2;
        color: $dark-color;
        &:hover {
          background-color: $light-gray;
        }
      }

      &.submit {
        &.primary {
          background-color: $background-blue;
        }

        &.error {
          background-color: $error-color;
        }

        &:hover {
          &.primary {
            background-color: $secondary-color-hover;
          }

          &.error {
            background-color: $primary-color-hover;
          }
        }

        .submitLoader {
          font-size: setUnit(3);
        }
      }
    }
  }
}
