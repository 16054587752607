@import 'styles/functions';
@import 'styles/variables';

#searchProperties {
  &.show-both {
    width: 100%;
    @media (min-width: $tablet) {
      width: 40%;
    }
    div[class*='propertyWrap'] {
      width: 100%;
      @media (min-width: $mobile) {
        width: 50%;
      }
    }
  }
  &.show {
    display: block;
    width: 100%;
    div[class*='propertyWrap'] {
      width: 100%;
      @media (min-width: $mobile) {
        width: 50%;
      }
      @media (min-width: $tablet) {
        width: 33.333%;
      }
    }
  }
  &.hide {
    display: none;
  }
}

#searchMap {
  position: relative;
  &.show-both {
    @media (min-width: $tablet) {
      width: 60%;
    }
    width: 100%;
  }
  &.show {
    display: block;
    width: 100%;
  }
  &.hide {
    display: none;
  }
}
