@import "styles/functions";
@import "styles/variables";

.modal {
    .saveButton {
        width: 100%;
    }

    .deleteButton {
        width: 100%;
        background: #ffffff;
        border: 1px solid $border-middle-gray;
        color: $text-color;
        font-weight: 600;
        margin-top: setUnit(24);

        &:hover {
            background: inherit;
            opacity: .8;
        }
    }

    .deleteIcon {
        margin-right: setUnit(10);
    }

    .deleteButtonTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
    }
}
