@import 'styles/functions';
@import 'styles/variables';

.header {
  display: flex;
  flex-direction: column;
  gap: setUnit(20);
  justify-content: space-between;
  min-height: setUnit(40);
  padding: setUnit(40) setUnit(32) 0 setUnit(32);
  margin-bottom: setUnit(24);

  .folderContainer {
    display: flex;
    align-items: center;
    gap: 12px;

    .backArrowIcon {
      cursor: pointer;
      line-height: 0;
    }
  }
  .actionsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: setUnit(12);

    .formSearchContainer {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: setUnit(12);
    }
  }
}

.btn {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 2px solid $light-gray;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: $dark-color;

  &.newBtn {
    background-color: $secondary-color;
    color: $white;
    border: none;

    &:hover {
      border: none;
      background-color: 2px solid $secondary-color-hover;
    }
  }

  .addIcon {
    svg {
      path {
        stroke: $white;
      }
    }
  }

  .iconText {
    margin-left: setUnit(5);
  }

  &.filterBtn {
    width: fit-content;
    padding: 0 1rem;
  }

  width: setUnit(36);
  height: setUnit(36);
  @media (min-width: $tablet) {
    height: setUnit(44);
    width: setUnit(44);
  }
  svg {
    width: setUnit(20);
    height: setUnit(20);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border: 2px solid $text--light-gray-color;
  }
  &.btnText {
    width: auto;
    padding: 0 setUnit(16);
  }
}
