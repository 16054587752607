@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.propertiesWrapper {
  height: 100%;
  transition: all 0.15s;
  position: relative;
  .loadingPage {
    box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    opacity: 0.85;

    &.isCompares {
      bottom: setUnit(80);
    }
  }

  .loader {
    font-size: setUnit(4);
    margin: setUnit(25) 0;
  }
}
