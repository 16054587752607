@import 'styles/functions';
@import 'styles/variables';

.yearInputLabel {
  margin-bottom: setUnit(12);
}
.altLabel {
  font-size: setUnit(14);
  color: $light-black;
  font-weight: 400;
}

.horizontalOptions {
  display: flex;
  align-items: center;

  .selectWrapper {
    margin-top: 0;
    margin-bottom: 0;
    flex: 1;
    input {
    }
  }

  .separator {
    margin: 0 32px;
    font-size: 16px;
    color: $text-gray-color;
  }
}
