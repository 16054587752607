@import 'styles/variables';
@import 'styles/mixins';

.preFormFooterButton {
  margin-top: 44px;
  width: 100%;
  height: 52px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.disabledButton {
  opacity: 0.6;
}
