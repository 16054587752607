@import 'styles/functions';
@import 'styles/variables';

.private {
  margin-top: 2rem;
  width: fit-content;

  .customLabel {
    margin-left: 0.75rem;
    font-size: 1rem;
    color: $dark-color;
  }
}
