@import 'styles/variables';
@import 'styles/mixins';

.permissionTypeConfirmModal {
  [class~='ant-modal-header'] {
    display: none;
  }
}

.fullWidthContainer {
  width: 100%;
}
