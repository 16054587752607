@import 'styles/mixins';
@import 'styles/variables';

.newTemplateWrapper {
  .newTemplateContent {
    max-height: 70vh;
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 11px;

    .formFileUploader {
      margin-top: 20px;

      .label {
        font-size: setUnit(14);
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
        margin-bottom: 16px;
        color: $text-color;
      }
    }
  }
}
