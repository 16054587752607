@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskModalForm {
  .content {
    max-height: calc(100vh - 22.5rem);
    padding-right: 1rem;
    margin-bottom: 1rem;

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-16);

      .fieldHalf {
        width: 50%;
        padding: 0 setUnit(20);
      }
    }

    .fieldWrapper {
      margin-bottom: 1.675rem;
    }
  }
}

.transactionTaskForm .content {
  max-height: unset;
}

.taskModal {
  .taskModalBody {
    max-height: 80vh;
    overflow: auto;
  }

  [class*='ant-modal-title'] {
    text-align: center !important;
    font-family: $font-family !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }
}

.inlineRowContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
}
