@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.buyerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkbox {
    border-color: $text-color;
    border-width: 2px;
  }
  .pointerEventsNone {
    pointer-events: none;
  }

  .favorite {
    @include action-icon();
  }

  .actions {
    display: flex;
    align-items: center;
  }
}
