@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  padding: setUnit(40) setUnit(40);

  .templatesContainer {
    overflow: auto;
    max-height: 300px;
  }

  .group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .category {
      font-size: setUnit(16);
      font-weight: 600;
      color: $text-color;
      display: flex;
      align-items: center;
      padding: 2rem 0 1rem;
      line-height: setUnit(21);
      border-bottom: 1px solid $light-gray;
      margin: 0;
    }

    .template {
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: 500;
      color: $text-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $light-gray;
      padding: 1rem 0 1rem;
      cursor: pointer;

      .icon {
        display: inline-block;
        margin-right: 0.75rem;
      }

      .checkbox {
        display: inline-flex;
        margin-right: 0.75rem;
      }

      [class*='ant-checkbox-wrapper'] {
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }

      .arrowIcon {
        svg {
          vertical-align: middle;
          height: setUnit(24);
          width: setUnit(24);

          path {
            stroke: $text-gray-color;
          }
        }

        &:hover {
          svg {
            path {
              stroke: $text-color;
            }
          }
        }
      }
    }
  }

  .submitBtn {
    margin-top: 1.5rem;
    min-width: setUnit(168);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .noTemplates {
    padding: 0;
    border: none;
    margin: setUnit(60) 0;
  }

  .multiBtn {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    width: 168px;
    height: 52px;
  }
}
