@import 'styles/functions';
@import 'styles/variables';

.navItem {
  text-decoration: none;
  color: $background-ligh-gray-5;
  font-weight: 400;
  opacity: 0.9;
  display: block;
  margin: setUnit(2) 0;

  .inner {
    display: flex;
    align-items: center;
    padding: setUnit(14) setUnit(25);
  }

  .icon {
    margin-right: setUnit(20);
  }

  .label {
    position: relative;

    &.isUnread {
      &:after {
        content: '';
        width: setUnit(6);
        height: setUnit(6);
        border-radius: 50%;
        background: $primary-color;
        position: absolute;
        right: setUnit(-16);
        top: setUnit(5);
      }
    }
  }

  &:hover,
  &.active {
    opacity: 1;
    color: $background-ligh-gray-5;
    .inner {
      background: $secondary-color;
      border-radius: setUnit(8);
      color: $white;
    }
  }

  &.active {
    font-weight: 600;

    svg {
      path {
        stroke: $white;
      }
    }
  }
}
