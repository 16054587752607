@import 'styles/functions';
@import 'styles/variables';

.propertyInfo {
  display: flex;
  align-items: center;
  margin-bottom: setUnit(40);

  .photo {
    width: setUnit(56);
    height: setUnit(56);
    border-radius: setUnit(8);
    object-fit: cover;
  }

  .info {
    margin-left: setUnit(16);
  }

  .address {
    font-weight: 600;
  }

  .metaInfo {
    font-size: setUnit(14);
    font-weight: setUnit(400);
    color: $text-gray-color;
    margin-top: setUnit(4);
  }
}
