@import "styles/functions";
@import "styles/variables";

.statusTitle {
    font-weight: 600;

    .count {
        color: $border-middle-gray;
        margin-left: setUnit(8);
    }
}
