@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.groupBy {
  position: relative;

  .value {
    font-size: setUnit(14);
    font-weight: 500;
    height: setUnit(44);
    border: 2px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(20);
    border-radius: setUnit(100);
    cursor: pointer;

    &:hover {
      background-color: $background-ligh-gray;
    }
  }

  .arrow {
    transform: rotate(90deg);
    position: relative;
    right: setUnit(-5);
    transition: transform 0.1s ease;

    svg {
      vertical-align: middle;
    }
  }

  .options {
    z-index: 5;
    position: absolute;
    top: setUnit(48);
    width: setUnit(196);
    right: 0;

    ul {
      @include box-shadow;

      border-radius: setUnit(8);
      list-style: none;
      background: #ffffff;
    }

    .option {
      padding: setUnit(14) setUnit(16);
      font-size: setUnit(14);
      cursor: pointer;

      &:hover {
        background: $background-ligh-gray;
      }
    }
  }
}
