@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.card {
    @include box-shadow;

    padding: setUnit(20) setUnit(24);
    border-radius: setUnit(16);
    width: 100%;
    min-height: setUnit(226);
    display: flex;

    .avatarPlaceholder {
        font-size: setUnit(16);
    }

    .icon {
        width: setUnit(40);
        height: setUnit(40);
        object-fit: cover;
        border-radius: 50%;
    }

    .title {
        .category {
            font-size: setUnit(14);
            font-weight: 500;
            padding: setUnit(3) setUnit(6);
            background: $light-gray;
            border-radius: setUnit(17);
            display: inline-block;
            position: relative;
            top: setUnit(-1);
        }
    }

    .logoHolder {
        margin-right: setUnit(16);
    }

    .infoBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .info {
            flex: 1;
            margin: setUnit(6) 0;
        }
    }

    .view {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .actions {
        display: flex;
        align-items: center;
    }
}
