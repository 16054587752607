@import 'styles/functions';
@import 'styles/variables';

.changeQuestion {
  width: 100%;
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  &.show {
    opacity: 1;
    z-index: 1;
    height: inherit;
    display: block;
    visibility: visible;
  }
  &.nonVertical {
    @media (min-width: $mobile) {
      top: 0;
      transform: translateY(0);
      margin-top: setUnit(64);
    }
  }
}
