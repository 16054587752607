@import 'styles/functions';
@import 'styles/variables';

.addressListing {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: setUnit(14);
  .popoverOverlay {
    width: setUnit(462);
    div[class~='ant-popover'] {
      padding: 0;
    }
    div[class~='ant-popover-inner'] {
      border-radius: setUnit(8);
      div[class~='ant-popover-inner-content'] {
        padding: 0px;
      }
    }
  }
  .iconContainer {
    margin-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      display: flex;
      justify-content: center;
      align-self: center;
    }
  }

  .iconLabel {
    display: flex;
    align-items: center;
    .label {
      font-weight: 400;
      color: $dark-color;
      font-size: 16px;
      margin-left: 12px;
    }
  }

  .value {
    font-weight: 400;
    color: $text-color;
    font-size: 16px;
    a {
      color: $text-color;
    }
  }

  .address {
    text-align: end;
    p {
      margin: 0;
    }
  }
}
