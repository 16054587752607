@import 'styles/variables';
@import 'styles/mixins';

.moreQuestionsInformation {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: #747475;
  width: 4.5rem;
  height: 4.5rem;

  &:hover {
    background-color: $tertiary-50-hover;
  }

  .itemContent {
    text-align: center;
    display: block;
  }

  .iconItem {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .title {
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.09000000357627869px;
    text-align: center;
    color: $text-gray-color;
  }
}

.moreQuestionDisabled {
  cursor: not-allowed;
  &:hover {
    background-color: unset;
  }
}

.moreDropdownWrapper {
  position: relative;
}

.morePopover {
  width: setUnit(240);

  .optionsItems {
    width: 100%;

    .insetText {
      display: none;
    }

    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      padding: setUnit(8) 0;

      li {
        padding: 0;

        label[class~='ant-radio-wrapper'] {
          display: flex;
          margin-right: 0;
          align-items: center;
          transition: $transition;
          padding: setUnit(16) setUnit(20);
          background-color: $white;
          height: setUnit(56);

          &:hover {
            background-color: $background-grey-2;

            span[class~='ant-radio'] {
              & + span {
                .option {
                  .optionValue {
                    color: $text-color;
                  }
                }
              }
            }
          }

          span[class~='ant-radio'] {
            display: none;
            & + span {
              flex: 0 0 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              gap: 20px;

              .option {
                display: flex;
                align-items: center;

                .userColorBox {
                  width: 8px;
                  height: 8px;
                  margin-right: 12px;
                  border-radius: 50%;
                }

                .dropdownOptionIcon {
                  margin-right: setUnit(8);
                  display: flex;
                }

                .optionValue {
                  font-weight: 500;
                  font-size: setUnit(16);
                  line-height: setUnit(16);
                  letter-spacing: -0.09px;
                  color: $dark-color;
                }
              }

              .checkIcon {
                transition: $transition;
                height: setUnit(20);
                color: $green-color;

                svg {
                  width: setUnit(20);
                  height: setUnit(20);
                  path {
                    stroke: $green-color;
                  }
                }
              }
            }
          }

          @media screen and (max-width: 768px) {
            padding: setUnit(12) setUnit(16);
            height: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .moreQuestionsInformation {
    height: 4.2rem;
    width: 4.2rem;
  }

  .morePopover {
    width: 12rem;

    .optionsItems {
      ul[class~='ant-dropdown-menu'] {
        padding: 4px;
      }
    }
  }
}

// @media screen and (max-width: 475px) {
//   .morePopover {
//     width: 100vw;

//     .optionsItems {
//       .insetText {
//         display: block;
//         font-size: 12px;
//         font-weight: 500;
//         line-height: 12px;
//         letter-spacing: -0.09px;
//         text-align: left;
//         color: $text-gray-color;
//         margin: 20px 0px 8px 20px;
//       }

//       ul[class~='ant-dropdown-menu'] {
//         border-radius: 0px;
//         li {
//           label[class~='ant-radio-wrapper'] {
//             padding: 12px 20px;
//             height: 48px;
//           }
//         }
//       }
//     }
//   }
// }
