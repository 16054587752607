@import 'styles/functions';
@import 'styles/variables';

.amenity {
  display: flex;
  align-items: center;
  margin-top: setUnit(10);

  &:first-child {
    margin-top: 0;
  }

  .icon {
    margin-right: setUnit(10);
    svg {
      vertical-align: middle;
    }
  }

  .value {
    font-weight: 400;
  }
}
