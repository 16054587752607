@import 'styles/functions';
@import 'styles/variables';

.chatWrapper {
  border-top: $border-light-gray 1px solid;
  padding-top: setUnit(0);
  padding-bottom: setUnit(0);
  height: fit-content;
  overflow: hidden;
}

.tagHyperLink {
  color: #2b59da;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #2b59da;
  }
}

.menuItemSpan {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  .visible {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
}

.commentDropdown {
  .menuItem {
    display: flex;
    align-items: center;
    padding: 12px;

    * {
      font-family: $font-family;
    }

    color: $text-color !important;

    &:hover {
      background: $background-grey-2 !important;
      color: $text-color !important;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .commentDropdownHeader {
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #aaabab;
  }

  width: 264px;
  border-radius: 8px;
}

.comentDivider {
  color: #d6d6d7;
  margin-left: 10px;
}

.messagesForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .content {
    width: 100%;
    flex: 1;
    overflow: auto;
  }

  // don't change this. It is applied to AutoComplete only
  :global {
    .ant-select-dropdown {
      box-shadow: 0px 4px 16px rgb(0 0 0 / 14%);
      border-radius: 8px;
      padding: 0.3rem;

      .ant-select-item-empty {
        padding: setUnit(52) setUnit(52);
      }

      .tagTypePill {
        display: inline-block;
        border-radius: 24px;
        background-color: #e8e9ea;
        margin-left: setUnit(4);
        padding: setUnit(0) setUnit(6);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.18px;
      }

      .dropdownItem {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #262626;
        letter-spacing: -0.09px;
      }

      .propertyTransactionIcon {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .propertyCommentWrapper {
    width: 100%;
    border: solid 1px #d6d6d7;
    padding: 15px 20px 15px;
    position: fixed;
    max-width: setUnit(480);
    bottom: setUnit(121);
    background-color: #ffffff;
    p {
      margin: 0;
      padding: 0;
      font-size: setUnit(16);
    }
    .propertyCommentLink {
      &::before {
        content: '@';
      }
      &:not(:last-child) {
        &::after {
          content: ', ';
        }
      }
      color: $link-blue;
      display: block;
      font-size: setUnit(16);
      font-weight: 400;
    }
  }

  .footer {
    background: #ffffff;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    .smfAvatar {
      margin-right: setUnit(12);

      .avatar {
        width: setUnit(32);
        height: setUnit(32);
      }
    }

    .filterButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 8px;
      gap: 10px;

      .optionIcon {
        margin-right: setUnit(4);
        display: flex;

        svg {
          width: setUnit(20);
          height: setUnit(20);
        }
      }

      p {
        color: #676767;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        letter-spacing: -0.01125rem;
        padding: 0;
        margin: 0;
      }

      .icon {
        display: flex;
        transition: $transition;

        svg {
          width: 0.9rem;
          height: 0.9rem;

          path {
            stroke: $dark-color;
          }
        }
      }

      &:hover {
        background: #f4f5f6;
        border-radius: 8px;
      }

      &[class~='ant-dropdown-open'] {
        .icon {
          transform: rotate(180deg);
        }
      }

      .optionIcon {
        margin-right: setUnit(4);
        display: flex;

        svg {
          width: setUnit(20);
          height: setUnit(20);
        }
      }
    }

    .footerContent {
      width: calc(100% - 44px);

      &.footerContentWithoutAvatar {
        width: 100%;
      }
    }

    .footerInner {
      flex: 0 0 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 0 setUnit(24);
      height: setUnit(57);

      div {
        z-index: 1;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 setUnit(24);
      gap: 5px;
    }

    .actions {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .sendInputWrapper {
      margin: 0;
      height: setUnit(64);
      display: flex;
      align-items: center;

      .inputField {
        background: #ffffff;
        padding: 0;
        border: none !important;
        border-radius: 0;
        max-width: 100%;
        position: relative;
      }
    }
    .chatModalText {
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        width: 34rem;
        overflow: hidden;
        height: 2.25rem;
      }
    }
    @media (max-width: 500px) {
      .chatModalText {
        p {
          width: 18rem;
        }
      }
    }
    @media (min-width: 501px) and (max-width: 768px) {
      .chatModalText {
        p {
          width: 25rem;
        }
      }
    }
    .chatSideBarText {
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        width: 30rem;
        padding: 24px 35px 0px 24px !important;
      }
    }
    @media (max-width: 500px) {
      .chatSideBarText {
        p {
          width: 27rem;
        }
      }
    }
    .chatText {
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        width: 22rem;
        overflow: hidden;
        height: 2.25rem;
      }
    }
    @media (max-width: 500px) {
      .chatText {
        p {
          width: 19rem;
        }
      }
    }
    .inputGroup {
      position: relative;
      width: 100%;

      .subjectInput {
        input {
          width: 100%;
          padding: setUnit(23) setUnit(24);
          border-top: $border-light-gray 1px solid;
        }

        &Text {
          border: none;
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.18);

          &::placeholder {
            font-weight: 400;
            font-size: setUnit(14);
            line-height: setUnit(16);
            font-family: $font-family;
            letter-spacing: setUnit(-0.18);
            color: $border-middle-gray;
          }

          &::-ms-input-placeholder {
            font-weight: 400;
            font-size: setUnit(14);
            line-height: setUnit(2160);
            font-family: $font-family;
            letter-spacing: setUnit(-0.18);
            color: $border-middle-gray;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .inputFieldWrapper {
      height: fit-content;
      overflow: unset;
    }

    .tagItemsContainer {
      .tagItems {
        border-radius: 24px;
        background-color: #f6f7f7;
        padding: setUnit(0) setUnit(6);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
    }

    .sendButton {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      cursor: not-allowed;
      display: flex;
      align-items: center !important;
      justify-content: center;
      transition: all 0.15s;

      &:hover {
        opacity: 0.9;
        transform: scale(1.05);
      }

      svg {
        vertical-align: middle;

        path {
          stroke: $dark-grey-color !important;
        }
      }
    }

    .enabledSendButton {
      cursor: pointer;
      background-color: $background-blue !important;

      svg path {
        stroke: $white !important;
      }
    }
  }

  .extraOptions {
    display: flex;
  }
}

.dropdownMenu {
  z-index: 9999;
}
