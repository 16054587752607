@import 'styles/functions';

.react-hint {
  &--top {
    padding-bottom: setUnit(8);

    &:after {
      content: '';
      display: inline-block;
      border-top: setUnit(10) solid #ffffff;
      border-left: setUnit(8) solid transparent;
      border-right: setUnit(8) solid transparent;
      position: absolute;
      bottom: setUnit(5);
      display: none;
    }
  }

  &--bottom {
    &:after {
      border-bottom: none;
    }
  }

  &--left {
    &:after {
      border-left-color: #ffffff !important;
    }
  }

  &--right {
    &:after {
      border-right-color: #ffffff !important;
    }
  }
}
