@import 'styles/functions';
@import 'styles/variables';

.commutesInner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  .offsetTop {
    margin-top: 23px;
  }

  .offsetLeft {
    margin-left: 23px;
  }

  .addCommute {
    height: 156px;
    min-width: 156px;
    border: 2px dashed $light-gray;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: 2px dashed $confetti-blue;
    }

    .addIcon {
      height: 31px;
      width: 31px;
      border-radius: 50%;
      background-color: $confetti-blue;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 15px;
        width: 15px;

        path {
          stroke: $white;
        }
      }
    }

    .addText {
      color: $text-color;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.09px;
      width: 100px;
      margin: 0;
      margin-top: 10px;
    }
  }
}

.bottom {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .saveButton {
    background-color: $background-blue !important;
    min-width: 154px;
    min-height: 52px;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: block;
    border-radius: 0.5rem;

    &:hover {
      background-color: $secondary-color-hover !important;
    }
  }
}
