@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$hover-color: lighten($secondary-color, 10);

.titleWrapper {
  border-bottom: 2px solid $light-gray;
  padding-bottom: setUnit(33);
  .title {
    font-weight: 600;
    font-size: setUnit(24);
    line-height: setUnit(29);
    letter-spacing: -0.47px;
    color: $text-color;
  }
}

.mlsWrapper {
  height: 100%;
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: $text-color;

  .mlsItemsWrapper {
    padding: setUnit(20) 0;
    max-height: 250px;
    overflow-y: scroll;
  }
  .mlsItem {
    padding: 1rem setUnit(10);
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .avatar {
      width: setUnit(40);
      height: setUnit(40);
      background: $secondary-color;
      color: $white;
    }

    .label {
      margin-left: 0.5rem;
      font-weight: 500;
      font-size: 16px;
      color: $text-color;
      line-height: 20px;

      .info {
        display: block;
        text-transform: capitalize;

        p {
          display: inline-block;
          margin: 0;
          color: $dark-color;
          font-weight: 400;
          font-size: 14px;

          span {
            font-weight: 600;
          }
        }
      }

      .divider {
        display: inline-block;
        margin: 0 0.5rem;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $text-gray-color;
        position: relative;
        top: -0.2rem;
      }
    }

    .avatarPlaceholder {
      font-size: setUnit(14);
    }

    .name {
      display: flex;
      align-items: center;
    }

    .dropdownWrapper {
      .dropdownDots {
        cursor: pointer;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: 50%;
        background: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .dropdownItem {
        margin-left: 0.5rem;
        font-weight: 500;
        font-size: 16px;
        color: $text-color;
        line-height: 20px;
        padding: setUnit(10) setUnit(10) setUnit(5) setUnit(10);
      }
    }
  }
  .addMls {
    padding: setUnit(10);

    .addMlsBtn {
      display: inline-block;
      cursor: pointer;
    }
    span {
      padding-left: setUnit(10);
      vertical-align: super;
    }
  }
}
.title {
  font-weight: 600;
  font-size: setUnit(20);
  line-height: setUnit(29);
  letter-spacing: -0.47px;
  color: $text-color;
}

.section {
  justify-content: space-between;
  align-items: center;
  padding: setUnit(22) 0;
  border-bottom: 1px solid $grey-color;
}

.currentPlanTitle {
  font-weight: 400;
  font-size: setUnit(18);
  line-height: setUnit(20);
  letter-spacing: -0.33px;
  color: $text-color;
  strong {
    font-weight: 600;
  }
}

.thirdPartyWrapper {
  padding-top: setUnit(33);

  .thirdPartyContainer {
    width: 100%;
    display: inline-block;
    text-align: center;
  }

  .disclaimer {
    margin-top: setUnit(24);
    p {
      margin-bottom: 0;
      color: $text-gray-color;
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(18);
      letter-spacing: -0.09px;
      padding-top: setUnit(24);
      a {
        color: $link-blue;
        font-weight: 500;
        @include transition;
        &:hover {
          color: $link-color;
        }
      }
    }
    .icon {
      pointer-events: none;
      svg {
        width: setUnit(68);
        height: setUnit(68);
        path {
          stroke: $text--light-gray-color;
          stroke-width: 1px;
        }
      }
    }
  }
}
