@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.resetPassword {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100vh;
  @media (max-width: $tablet) {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    margin-left: 20px;
  }
  max-width: calc(100% - 360px - 320px);
  width: 100%;
  .resetPasswordInner {
    max-width: 400px;
    width: 100%;
  }
  .question {
    text-align: left !important;
  }
  .input {
    margin-bottom: setUnit(32);
    &:last-child {
      margin-bottom: setUnit(0);
    }
  }
}

.submit {
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
  height: 52px !important;
  padding: 12px 30px !important;
  border: 2px solid $secondary-color !important;
  @include transition;
  &:hover {
    background-color: $white !important;
    color: $secondary-color !important;
  }
}

.description {
  font-size: setUnit(14);
  font-weight: 400;
  color: $dark-grey-color;
  margin-top: setUnit(24);
}
