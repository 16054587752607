@import "styles/functions";
@import "styles/variables";

.editTransactionDrawer {
    .submitButton {
        width: setUnit(102);
        min-width: setUnit(102);
        height: setUnit(39);
        margin-left: auto;
        display: block;
        font-weight: 600;

        .submitButtonLoader {
            font-size: setUnit(3);
        }
    }

    .pendingWrapper {
        height: 100%;
    }
}
