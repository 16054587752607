@import 'styles/functions';
@import 'styles/variables';

.ClientProfileAddAddress {
  .spacing {
    width: 100%;
    [class~='ant-space-item'] {
      width: 100%;
    }

    .addressContainer {
      border: 2px solid $light-gray;
      border-radius: setUnit(16);
      padding: setUnit(16);

      &Header {
        display: flex;
        align-items: center;
        margin-bottom: setUnit(24);

        &Avatar {
        }

        &Name {
          font-weight: 500;
          font-size: setUnit(18);
          line-height: setUnit(24);
          font-family: $font-family;
          letter-spacing: setUnit(-0.9);
          color: $text-color;
          margin-left: setUnit(16);
        }
      }

      &Location {
        margin-bottom: setUnit(16);
      }

      &Primary {
      }
    }
  }

  .clientName {
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.89px;
    color: $secondary-color;
  }
  .clientActions {
    width: 100%;
    .answerBtn {
      height: 56px;
    }
  }
  .addressBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 32px;
    .addressInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .addressLocation {
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.09px;
        color: $text-color;
      }
      .addressState {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.09px;
        color: $dark-color;
      }
    }
  }

  .primaryAddressBlock {
    display: flex;
    align-items: center;
    .primaryAddressText {
      margin-left: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: $text-color;
    }
  }
}
