@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.templateCard {
  display: flex;
  justify-content: space-between;

  padding-bottom: setUnit(20);
  border-bottom: 1px solid $background-ligh-gray-4;
  &:not(:last-child) {
    margin-bottom: setUnit(20);
  }

  .tcLeft {
    display: flex;
    align-items: flex-start;

    .templateInfo {
      .title {
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-weight: 600;
        letter-spacing: -0.18px;
        color: $text-color;
        margin-bottom: setUnit(6);
      }
      .description {
        font-size: setUnit(14);
        line-height: setUnit(18);
        font-weight: 400;
        letter-spacing: -0.18px;
        color: $dark-grey-color;
        margin-bottom: setUnit(6);
      }
      .metaInfo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0;
        list-style-type: none;
        li {
          color: $text-color;
          font-size: setUnit(14);
          line-height: setUnit(18);
          letter-spacing: -0.18px;
          font-weight: 500;
          &:not(:last-child) {
            margin-right: setUnit(20);
          }
          span {
            font-weight: 400;
          }
        }
      }
    }
    .editInfo {
      margin-left: 48px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      color: $error-color;
    }
  }

  .tcRight {
    display: flex;
    align-items: center;
    margin-left: setUnit(8);
    .dropdownTrigger {
      cursor: pointer;
      width: setUnit(32);
      height: setUnit(32);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: $background-ligh-gray;
      }
    }
    .transactionMoreOptions {
      @include box-shadow;
      background: $white;
      border-radius: setUnit(8);
      width: 11rem;
      [class*='ant-dropdown-menu'] {
        padding: 0;
        border-radius: setUnit(8);
      }
      .dropdownItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: setUnit(10) setUnit(22);
        .icon {
          margin-right: setUnit(8);
          position: relative;
          top: setUnit(-2);
          svg {
            width: setUnit(24);
            height: setUnit(24);
            path,
            circle {
              stroke: $primary-color;
            }
          }
        }
        &:hover {
          background: $background-ligh-gray;
        }
        svg {
          vertical-align: middle;
        }
      }
    }
  }
}
