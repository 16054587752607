@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.pageWrapper {}

.header {}

.editButton {
    @include grid-action-button();
}

.deleteButton {
    @include grid-action-button($error-color, $hover-red, $white);
}

.deactivateButton {
    @include grid-action-button($primary-color, $hover-peach, $white);
}

.activateButton {
    @include grid-action-button($secondary-color, $hover-blue, $white);
}

.buttonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.activateButton, .deactivateButton {
    .buttonIcon {
        &> svg {
            path {
                stroke-width: 3px !important;
            }
        }
    }
}

.modalInnerHolder {
    padding: setUnit(32) !important;
}

.modalContent {
    width: setUnit(640);
}

.categoryIconWrapper, .categoryIcon {
    width: setUnit(32) !important;
    height: setUnit(32) !important;
    border-radius: 50%;

    img {
        width: setUnit(28) !important;
        height: setUnit(28) !important;
    }
}
