@import "styles/functions";
@import "styles/variables";

.details {
    .btn {
        font-size: setUnit(14);
        font-weight: 500;
        border: 2px solid $grey-color;
        padding: 0 setUnit(12);
        height: setUnit(40);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: setUnit(20);
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            background-color: $background-ligh-gray;
        }
    }
}
