@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.documents {
  .addDocumentNameSection {
    display: flex;
    width: 100%;
    [class*='ant-select'] {
      margin-left: 10px !important;
    }
    [class*='ant-select-selection-overflow'] {
      margin-left: 0px !important;
    }

    .iconDelete {
      cursor: pointer;
    }
  }
  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .checkboxSquare {
    [class*='ant-checkbox'] {
      [class*='ant-checkbox-inner'] {
        border-radius: setUnit(4);
      }
    }
  }
  .content {
    margin-top: setUnit(20);

    .title {
      font-weight: 600;
      line-height: 21px;
      margin-bottom: setUnit(4);
      color: $text-color;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-color;

      .docsIcon {
        background-color: $background-grey-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: setUnit(32);
        height: setUnit(32);
        border-radius: setUnit(50);
      }
    }
    .checkboxWrapper {
      margin-top: setUnit(32);
      padding: setUnit(24);
      border: 1px solid $light-gray;
      border-radius: 8px;

      .requiredOptions {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        font-size: 14px;
        height: 40px;
        letter-spacing: -0.09px;
        color: $dark-grey-color;
        @media (max-width: 575.99px) {
          flex-direction: row-reverse;
          margin-top: setUnit(8);
        }

        .filterButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          border-radius: 30px;
          padding: 6px 8px 6px 10px;

          gap: 10px;

          .optionIcon {
            margin-right: setUnit(4);
            display: flex;
            svg {
              width: setUnit(20);
              height: setUnit(20);
            }
          }

          p {
            padding: 0;
            margin: 0;
          }

          .icon {
            display: flex;
            transition: $transition;
            svg {
              width: setUnit(12);
              height: setUnit(6);
              path {
                stroke: $dark-color;
              }
            }
          }

          &[class~='ant-dropdown-open'] {
            .icon {
              transform: rotate(180deg);
            }
          }

          .optionIcon {
            margin-right: setUnit(4);
            display: flex;
            svg {
              width: setUnit(20);
              height: setUnit(20);
            }
          }
        }
      }

      .addAnotherWrapper {
        display: flex;
        width: 100%;
        gap: 10px;
      }

      .checkboxContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: setUnit(20);
      }

      .checkboxTitle {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .optionalButton {
        background: #74747514;
        color: $dark-color;
      }

      .requiredButton {
        background: $primary-color-light;
        color: $text-color;
        border: 1px solid $primary-color;
      }
    }

    .subTitle {
      color: $text-gray-color;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-weight: 400;
      letter-spacing: -0.18px;
      margin-bottom: setUnit(26) !important;
    }
    .container {
      .checkbox {
        margin: 0;
      }
      .selector {
        width: calc(100% - 36px);
        margin-left: 36px;
        & [class*='ant-select-selector'] {
          border: unset !important;
          border-bottom: 2px solid $border-input-middle-gray !important;
          box-shadow: none !important;
          padding-left: 0;
          [class~='ant-select-selection-placeholder'] {
            left: 0;
          }
          [class~='ant-select-selection-search'] {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  .footer {
    padding-right: setUnit(32);
  }
}

.menuItemSpan {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  .visible {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
}

.menuDropdown {
  .menuItem {
    display: flex;
    align-items: center;
    padding: 12px;
    * {
      font-family: $font-family;
    }

    color: $text-color !important;

    &:hover {
      background: $background-grey-2 !important;
      color: $text-color !important;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  border-radius: 8px;
}
