@import "styles/functions";
@import "styles/variables";

.wrapper {
    .customCheckbox {
        border-bottom: 1px solid $border-light-gray;
        display: flex;
        align-items: center;
        padding: setUnit(12) 0;

        .customLabel {
            margin-left: setUnit(15);
        }

        label {
            margin-bottom: 0;
        }
    }

    .customText {
        margin-left: setUnit(15);
    }

    .customOption {
        display: flex;
        align-items: center;
        margin-left: setUnit(9);

        .customInput {
            margin-top: setUnit(-6);
            margin-bottom: setUnit(-6);

            input {
                padding: setUnit(5);
                width: setUnit(40);
                height: setUnit(36);
                font-weight: 400;
                border: none;
                background: transparent;

                &:focus {
                    border: 1px solid $border-middle-gray;
                }
            }
        }

        .minutes {
            display: inline-block;
            margin-left: setUnit(2);
        }
    }
}
