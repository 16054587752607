@import 'styles/variables';

.question {
  font-size: 32px;
  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 18px;
  }
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.8px;
  text-align: center;
  color: $secondary-color;
  margin-bottom: 32px;
}
