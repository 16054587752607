@import "styles/functions";
@import "styles/variables";

.wrapper {
    color: $text-color;
    height: 100%;

    .resultNumber {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        padding: 0 setUnit(32) setUnit(8) setUnit(32);
    }

    .searchedString {
        background: $warning-color;
        font-weight: 500;

        &:first-of-type {
            // border-top-left-radius: setUnit(4);
            // border-bottom-left-radius: setUnit(4);
            border-radius: setUnit(4);
        }

        // &:last-child {
        //     border-top-right-radius: setUnit(4);
        //     border-bottom-right-radius: setUnit(4);
        // }
    }

}
