@import "styles/functions";
@import "styles/variables";

.info {
    margin-left: setUnit(10);

    .names {
        font-size: setUnit(20);
        font-weight: 600;
    }

    .address {
        font-size: setUnit(14);
        font-weight: 400;
        margin-top: setUnit(4);
        color: $text-gray-color;
        margin-right: setUnit(8);
    }

    .subheader {
        display: flex;
        align-items: center;
    }

    .chatType {
        font-weight: 500;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
        padding: setUnit(3) setUnit(6);
        border-radius: setUnit(17);
        background-color: $light-gray;
        margin-top: setUnit(4);

        & .offer {
            background-color: $yellow-color;
        }
    }
}
