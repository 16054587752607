@import 'styles/functions';
@import 'styles/variables';

.toolTipContainer {
  [class*='ant-tooltip-inner'] {
    padding: 0px !important;
  }
}

.sketchContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .colorBoxContainer {
    display: flex;

    align-items: center;
    background-color: $white;

    min-height: 44px;
    .colorBox {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      border: 1px solid $border-light-gray-2;
    }
    .dropDownIcon {
      display: flex;
    }
  }
}

.sketchPad {
  color: $text-color;
}
