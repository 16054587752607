@import 'styles/functions';
@import 'styles/variables';

.label {
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(16);
  letter-spacing: setUnit(-0.09);
  color: $text-color;
  margin-bottom: setUnit(4);
}
