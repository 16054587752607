@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.role {
  .fieldWrapper > label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fieldWrapper,
  .label,
  .fieldWrapper > label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fieldWrapper {
    .labelContainer {
      display: flex;
      .asterik {
        color: $error-color;
        margin-left: 2px;
      }
    }
  }

  .error {
    @include inputError;
  }
}
