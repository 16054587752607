@import 'styles/variables';
@import 'styles/functions';

.statusWiseOffers {
  border-top: 1px solid $light-gray;
  [class*='ant-collapse-header'] {
    padding: setUnit(24) 0 !important;
    align-items: center !important;
    justify-content: space-between;
    flex-flow: row-reverse;
    [class*='ant-collapse-header-text'] {
      padding: 0 !important;
    }
    [class*='ant-collapse-expand-icon'] {
      display: flex;
      svg {
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
        transform: translateY(0) !important;
      }
    }
  }

  [class*='ant-collapse-content'] {
    [class*='ant-collapse-content-box'] {
      padding: 0 !important;
    }
  }
  .offereName {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 1%;
  }

  .offerStatusHeading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 1%;
  }

  .offerReceivedAndPrice {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $dark-color;
    margin-bottom: 0;
  }

  .offerInfoWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .iconWrap {
      background: $light-gray;
      border-radius: 100px;
      width: 44px;
      height: 44px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      .incompleteDocumentIcon {
        width: 24px;
        height: 24px;
        path {
          stroke: $dark-color;
        }
      }
    }
    .info {
      flex: 0 0 calc(100% - 92px);
      margin-right: 12px;
    }
  }

  .DocumentDiv {
    padding: setUnit(18) 0;
    align-items: center;
    &:not(:last-child) {
      border-top: 1px solid $light-gray;
    }
  }
  @media only screen and (max-width: $mobile) {
    .compareOffersModalBody {
      padding: 16px 20px 16px 20px;
    }

    .offereName {
      font-size: 16px;
    }

    .offerStatusHeading,
    .offerReceivedAndPrice {
      font-size: 14px;
    }
  }
}
