@import 'styles/functions';
@import 'styles/variables';

.valuationCard {
  border: 1px solid var(--grayscale-10-background, #f4f5f6);
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;

  [class*='apexcharts-tooltip'] {
    border-radius: 16px !important;
  }

  .valuationCardHeader {
    display: flex;
    justify-content: space-between;
    &.isAgent {
      margin-bottom: 20px;
    }

    .headingWrapper {
      .title {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 111.111% */
        letter-spacing: -0.33px;

        margin-bottom: 12px;
      }

      .value {
        color: var(--REBRAND-Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 85.714% */
        letter-spacing: -0.09px;

        margin-bottom: 8px;
      }

      .percChange {
        color: var(--REBRAND-Grayscale-80, #515151);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;

        .increase {
          color: var(--REBRAND-Tertiary-Green-Success-80, #00b152);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 106.667% */
          letter-spacing: -0.09px;
        }

        .decrease {
          color: #ec455e;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 106.667% */
          letter-spacing: -0.09px;
        }
      }
    }

    .optionsWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .iconWrapper {
        text-align: right;
        padding: 4px;
        cursor: pointer;
      }
      .dropdown {
        border: none !important;
        padding: 0 10px !important;
        height: 35px !important;
        label[class~='ant-radio-wrapper'] {
          padding: 14px 16px !important;
          height: 44px !important;
        }
        span {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          letter-spacing: -0.09px !important;
          color: $text-color !important;
        }
      }
      .addButton {
        margin-top: 5px;
        cursor: pointer;
        color: $text-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.33px;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        transition: all 0.3s;
        &:hover {
          background: $background-grey-2;
        }
        span {
          font-size: 16px;
          color: $dark-grey-color;
        }
      }
    }
  }
}
