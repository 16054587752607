@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.formItem {
  padding: setUnit(18) setUnit(12);
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    background: $background-ligh-gray;
    cursor: pointer;

    .itemContent {
      .formTitle {
        font-weight: 600;
        color: $text-color;
      }

      path {
        stroke: $dark-color;
      }
    }
  }

  .itemContent {
    display: flex;
    align-items: center;
    gap: 10px;

    width: 70%;

    .iconsContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      .dragDotsIcon {
        cursor: grab;
      }
    }

    .formTitle {
      margin-left: 2px;
      font-size: setUnit(16);
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.18000000715255737px;
      text-align: left;
      color: $text-gray-color;

      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: $tablet-horizontal) {
      gap: 5px;
      width: 65%;

      .formTitle {
        font-size: setUnit(14);
      }

      ~ div {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    @media screen and (max-width: 475px) {
      gap: 10px;
      width: 90%;

      .formTitle {
        font-size: setUnit(16);
      }

      ~ div {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.selectedForm {
  background: $background-ligh-gray;
  cursor: pointer;

  .itemContent {
    .formTitle {
      font-weight: 600;
      color: $text-color;
    }

    path {
      stroke: $dark-color;
    }
  }
}

.disabledFormItem {
  &:hover {
    cursor: default !important;
  }
  opacity: 0.7;
}
