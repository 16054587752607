@import 'styles/functions';
@import 'styles/variables';

$space-around: setUnit(30);
$drawer-width: 480;
$drawerFooterHeight: 84px;
$drawerHeaderHeight: 88px;

.drawerHeader {
  display: flex;
  justify-content: flex-end;
  padding: $space-around;
  padding-bottom: calc($space-around / 3);

  svg {
    cursor: pointer;
    &:hover {
      rect {
        fill: $light-gray;
      }
    }
  }
}

.drawerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - $drawerFooterHeight - $drawerHeaderHeight);
  overflow-y: auto;
  scrollbar-width: none;

  .addressBar {
    font-weight: 600;
    font-size: setUnit(20);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
    min-height: 32px;
  }

  .address {
    letter-spacing: -0.5px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $text-gray-color;
  }

  .status {
    margin: 1rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    background-color: $background-grey-2;
    border-radius: 100px;
  }

  .detailsRow {
    width: 100%;
    padding: 0 $space-around;
    margin-top: calc($space-around / 2);
    margin-bottom: $space-around;
    .detailField {
      display: flex;
      flex-direction: column;
      .label {
        color: $text-gray-color;
      }
      .value {
        font-weight: 500;
        color: $text-color;
      }
    }

    .projectDate,
    .ownersField {
      text-align: center;
    }
    .projectDate {
      margin-right: 2rem;
    }
    .ownersField {
      margin-left: 2rem;
    }
  }

  .transactionPanes {
    width: 100%;
    [class*='ant-tabs-nav-wrap'] {
      justify-content: center;
      margin: 0 $space-around;
    }
    [class*='ant-tabs-tabpane-active'] {
      height: 100% !important;
    }
    [class*='ant-tabs-ink-bar'] {
      height: 3px !important;
    }
    [class*='ant-tabs-nav']::before {
      border-radius: 14px !important;
      right: 31px !important;
      left: 34px !important;
      border-bottom: 2px solid #f0f0f0 !important;
    }
    [class*='ant-tabs-tab-btn'] {
      text-shadow: none !important;
      min-width: 5rem;
      text-align: center;
    }

    @media (max-width: 500px) {
      [class*='ant-tabs-tab-btn'] {
        min-width: 5rem;
      }
    }

    .drawerTimeline {
      padding: 0;
      [class*='ant-collapse-header'] {
        display: none;
      }
    }

    .drawerDetailsView {
      padding: 0 $space-around;
      [class*='contentSpacing'] {
        margin: 0 !important;
      }
    }

    .drawerNotesView {
      overflow-x: clip;
      padding: 0 0 0 setUnit(30);

      .transactionNotes {
        min-height: 475px;
        [class*='empty'] {
          min-height: 340px;
        }
      }
      [class*='contentSpacing'] {
        margin: 0 !important;
      }
    }
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - $drawerFooterHeight - $drawerHeaderHeight);
}

.drawerFooter {
  position: fixed;
  padding: 20px $space-around;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: setUnit($drawer-width);
  z-index: 10;
  background-color: #ffffffa8;

  button {
    width: 100%;
    height: 52px;
    border-radius: setUnit(8);
    font-size: 16px;
  }
}

.transactionContent {
  max-width: setUnit($drawer-width) !important;
}
