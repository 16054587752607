@import 'styles/functions';
@import 'styles/variables';

.netCardContainer {
  padding: setUnit(16) setUnit(12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .labelContainer {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    .icon {
      display: flex;
    }
  }

  .labelText {
    font-weight: 600;
    font-size: setUnit(20);
  }
  .amountContainer {
    display: flex;
    align-items: center;
    gap: setUnit(7);
    .amount,
    .percentage {
      font-weight: 600;
    }
    .amount {
      font-size: setUnit(20);
    }
    .percentage {
      font-size: setUnit(18);
    }
    &.confettiBlue {
      color: $confetti-blue;
    }
    &.purple {
      color: $purple;
    }
    &.lightBlue {
      color: $light-blue;
    }
  }
}
