@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../styles.module';

.controls {
  width: auto !important;
  position: absolute;
  top: -5px;
  left: 20px;
}

.main {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stageWrap {
  flex-basis: 100%;
  padding: 0 40px;
  height: calc(100vh - $header-height);
  .celebrate {
    height: 5rem;
    font-size: 64px;
    text-align: center;
    margin-bottom: setUnit(40);
  }
  .subtitleContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    .subtitle {
      margin: 0;
      color: $secondary-color;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.8px;
      width: 500px;
    }
  }
}

.title {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  svg {
    position: relative;
    top: 3px;
  }
}

.redirect {
  text-align: center;
  margin: 0;
}
