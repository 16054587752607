@import 'styles/functions';
@import 'styles/variables';

.linkContainer {
  @media screen and (min-width: $responsive-menu) {
    display: block;
    width: calc(100% - 540px);
    min-width: 200px;
  }
}

$border-bottom: 2px solid $primary-color !important;

.linksHorizontal {
  @media screen and (max-width: $responsive-menu) {
    display: none !important;
    visibility: hidden;
  }
  background: transparent !important;
  margin: 0px !important;
  margin-left: -4px !important;
  display: flex !important;
  border-bottom: none !important;
  align-items: center;

  .menuItemContainer {
    height: $header-height;
  }
  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: $text--light-gray-color !important;
  }

  [class*='ant-menu-horizontal'] {
    background: transparent !important;
  }

  li {
    height: 44px;
    line-height: 44px;
  }

  [class*='ant-menu']::after {
    border-bottom: none !important;
  }

  [class*='ant-menu-item-active'],
  [class*='ant-menu-item']:hover {
    span {
      height: 100%;
      display: block;
      border-bottom: $border-bottom;
      transition: none !important;
    }
  }

  [class*='ant-menu-item-active'] {
    a {
      font-weight: bold !important;
      color: $text-color !important;
    }
  }

  [class*='ant-menu-submenu-active']::after {
    border-bottom: $border-bottom;
  }
  [class*='ant-menu-sub'] {
    li {
      background: white !important;
    }

    [class*='ant-menu-item-active'],
    [class*='ant-menu-item']:hover {
      background-color: $hover-light-gray !important;
      span {
        height: unset !important;
        display: unset !important;
        border-bottom: $border-bottom;
      }
    }
  }

  [class*='ant-menu-submenu-title']:hover,
  [class*='ant-menu-submenu-active'] {
    color: $primary-color !important;
    transition: none !important;
  }
}

.linksVertical {
  [class*='ant-menu-item '] {
    padding: 0px !important;
    height: 64px !important;
    line-height: 64px !important;
  }
  a {
    padding: 2px 16px !important;
    line-height: 16px;
  }
  li {
    margin: 0px !important;
    &:hover {
      background-color: $hover-light-gray;
    }
  }
  a,
  a:hover,
  a:active,
  a:visited {
    font-family: $font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }

  [class*='ant-menu-item-selected'] {
    background-color: $white !important;

    &:hover {
      background-color: $hover-light-gray !important;
    }
    a {
      color: $text-color !important;
      border-left: 3px solid $primary-color;
      padding: 0px 13px !important;
    }
  }

  [class*='ant-menu-item']:hover,
  [class*='ant-menu-item-active'],
  [class*='ant-menu-submenu-open'],
  [class*='ant-menu-submenu-active'],
  [class*='ant-menu-submenu-title']:hover {
    a {
      color: $text-color !important;
      border-left: 3px solid $primary-color;
      padding: 0px 13px !important;
    }
  }
}
