@import 'styles/variables';

.questionContainer {
  padding: 0px !important;
  min-width: 900px !important;
}

.pdfDocument {
  width: fit-content;
  margin: auto;
}

.footerStyles {
  max-height: 74px;
  overflow: hidden;
}
