@import 'styles/functions';
@import 'styles/variables';

.amenities {
  min-height: setUnit(57);

  .inner {
    align-items: baseline;
  }

  .amenity {
    font-weight: 600;
  }
}
