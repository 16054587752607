@import "styles/functions";
@import "styles/variables";

.wrapper {
    width: 100%;
}

.subHeader {
    position: relative;
}

.buttons {
    display: flex;
    position: absolute;
    right: setUnit(40);
    top: 0;
    z-index: 9;
}

.breadcrumbs {
    position: relative;
    margin-top: setUnit(40);
    margin-left: setUnit(40);
}

.myQuotesBtn {
    width: setUnit(135);
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(20);
    border-radius: setUnit(100) !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageBtnTitle {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin-left: setUnit(6);
    }
}

.messageBtn {
    background-color: transparent !important;
    border: 2px $border-light-gray-2 solid !important;
    color: $text-color-middle !important;
    width: setUnit(135);
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(20);
    border-radius: setUnit(100) !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: setUnit(8);

    &:hover {
        background-color: $background-ligh-gray !important;
    }

    svg {
        path {
            fill: $dark-color;
        }
    }
}
