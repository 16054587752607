@import 'styles/variables';
@import 'styles/mixins';

.roleInformation {
  padding: 0px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  height: 100%;

  cursor: pointer;

  &:hover {
    background-color: rgba($dark-color, 0.5);
  }

  .userTitle {
    color: $white;

    font-family: Inter;
    font-size: setUnit(12);
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.09000000357627869px;
    text-align: left;
  }

  .userColorBox {
    width: 8px;
    height: 8px;
    margin-right: 6px;
    border-radius: 50%;
  }
}

.rolesDropdownWrapper {
  position: relative;

  @media (max-width: $mobile) {
    margin: setUnit(10) 0;
  }
}

.rolesPopover {
  width: setUnit(240);

  .optionsItems {
    width: 100%;

    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      padding: setUnit(8) 0;
      width: fit-content;

      li {
        padding: 0;

        label[class~='ant-radio-wrapper'] {
          display: flex;
          margin-right: 0;
          align-items: center;
          transition: $transition;
          padding: setUnit(16) setUnit(20);
          background-color: $white;
          height: setUnit(48);

          &:hover {
            background-color: $background-grey-2;

            span[class~='ant-radio'] {
              & + span {
                .option {
                  .optionValue {
                    color: $text-color;
                  }
                }
              }
            }
          }

          span[class~='ant-radio'] {
            display: none;
            & + span {
              flex: 0 0 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              gap: 20px;

              .option {
                display: flex;
                align-items: center;

                .userColorBox {
                  width: 8px;
                  height: 8px;
                  margin-right: 12px;
                  border-radius: 50%;
                }

                .dropdownOptionIcon {
                  margin-right: setUnit(8);
                  display: flex;
                }

                .optionValue {
                  font-weight: 500;
                  font-size: setUnit(16);
                  line-height: setUnit(16);
                  letter-spacing: -0.09px;
                  color: $dark-color;
                }
              }

              .checkIcon {
                transition: $transition;
                height: setUnit(20);
                color: $green-color;

                svg {
                  width: setUnit(20);
                  height: setUnit(20);
                  path {
                    stroke: $green-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
