@import 'styles/variables';
@import 'styles/functions';

.groupedItems {
  border: 0 !important;
  div[class~='ant-card-head'] {
    border-block-start: 0;
    div[class~='ant-card-head-wrapper'] {
      div[class~='ant-card-head-title'] {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(24);
        letter-spacing: setUnit(-0.33);
        color: $text-color;
      }
    }
  }
  div[class~='ant-card-body'] {
    padding: 0;
    .actionsDropdown {
      width: setUnit(176);
      box-shadow: 0px 2px 12px 0px #00000026;
      border-radius: setUnit(8);
      .actionItem {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        padding: setUnit(12) setUnit(16);
        background-color: $white;
        transition: $transition;
        &:hover {
          background-color: $background-grey-2;
        }
        .icon {
          display: flex;
          margin-right: setUnit(8);
          svg {
            width: setUnit(16);
            height: setUnit(16);
            path {
              stroke: $primary-color;
              stroke-width: 2px;
            }
          }
        }
        span {
          font-size: setUnit(14);
          font-weight: 400;
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.09);
        }
      }
    }
  }
}
