@import 'styles/functions';
@import 'styles/variables';

.dangerErrorMessage {
  color: #ec455e;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: normal;
  margin-top: 3px;
  text-transform: none;
}
