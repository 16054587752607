@import 'styles/functions';
@import 'styles/variables';

.customPrefContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: setUnit(16);

  .featureInput {
    width: 300px;
    input {
      height: 42px;
    }
  }
  .savedCustomPref {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;

    .pref {
      font-size: 1rem;
      font-weight: 500;
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .importanceBtns {
    display: flex;
    align-items: center;
    gap: setUnit(10);
    margin-left: 20px;
  }
}
