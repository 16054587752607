@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.pageWrapper {
}

.flexInner {
  display: flex;
  align-items: center;

  .button {
    @include button($secondary-color, $white, $hover-blue);
    border-radius: setUnit(25) !important;
    min-width: setUnit(104);
    height: 2.5rem !important;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: setUnit(14);
    line-height: setUnit(21);
    font-weight: normal;
    text-decoration: none;

    & svg {
      width: setUnit(24);
      margin-right: setUnit(8);
      vertical-align: middle;

      path {
        stroke: $white;
        stroke-width: 2px;
      }
    }
  }
}
.editButton {
  @include grid-action-button();
}

.deleteButton {
  @include grid-action-button($error-color, $hover-peach, $white);
}

.deactivateButton {
  @include grid-action-button($primary-color, $hover-peach, $white);
}

.activateButton {
  @include grid-action-button($secondary-color, $hover-blue, $white);
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activateButton,
.deactivateButton {
  .buttonIcon {
    & > svg {
      path {
        stroke-width: 3px !important;
      }
    }
  }
}

.link {
  a {
    color: $text-color;
    transition: all 0.1s;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.partnerLogo,
.partnerLogoWrapper {
  width: setUnit(32) !important;
  height: setUnit(32) !important;
  border-radius: 50%;
}

.partnerInfo {
  padding: setUnit(32);
}

.modalInnerHolder {
  padding: setUnit(32) !important;
}

.modalContent {
  width: auto !important;
}

.filter {
  max-width: setUnit(280);
  font-size: setUnit(16);
  height: setUnit(40);
  margin-left: auto !important;
}

.inputHolder {
  input {
    height: setUnit(40) !important;
  }
}

.multilineText {
  white-space: normal;
  min-width: setUnit(190);
  padding: setUnit(10) setUnit(0);
}

@media screen and (max-width: $tablet) {
  .modalContent {
    div:first-child {
      right: setUnit(8);
      top: setUnit(8);
    }
  }
}

.phoneNumber {
  min-width: setUnit(110);
}
