@import 'styles/mixins';
@import 'styles/functions';
@import 'styles/variables';

.calendarArea {
  color: $text-color;
  font-size: 16px;
  display: inline-table;
  position: relative;

  background-color: $white;
  padding: 20px 0;
  border-radius: 16px;

  .dateTimeHeader {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 20px;

    svg {
      height: 40px;
      width: 40px;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.33px;
      text-align: left;
      margin-bottom: 0;
    }

    .iconPointer {
      cursor: pointer;
    }
  }

  .bookingCalendarWrapper {
    display: flex;
    width: 600px;

    .timeZone {
      margin-left: 1.5rem;
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 1rem;
    display: inline-flex;
  }

  .showingsCalendar {
    width: 100%;

    [class~='ant-picker-content'] {
      tbody {
        [class~='ant-picker-cell-in-view'] {
          &::before {
            left: 0px !important;
            width: 40px !important;
            height: 40px !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
          }

          [class~='ant-picker-cell-inner'] {
            &::before {
              border: 0px;
            }
          }
        }
      }
    }
  }

  .maxWidth400 {
    max-width: 400px;
  }

  .maxWidthContent {
    max-width: max-content;
  }
}

.timeListArea {
  width: 13.5rem;
  overflow: auto;
  position: relative;
  text-align: center;
  font-size: 16px;
  display: inline-table;
  font-weight: 600;
}

@media (max-width: 991px) {
  .calendarArea {
    max-width: 100%;
    text-align: center;
  }

  .timeListArea {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: $mobile) {
  .bookingCalendarWrapper {
    width: 100% !important;

    gap: 10px;

    .selectedDateCalendar {
      width: 60%;

      .maxWidth400 {
        width: 100%;
      }
    }

    .timeListArea {
      width: 11rem;
      flex-grow: 1;
      padding-left: 20px;
      padding-right: 20px;
    }

    .timeZone {
      margin-left: 20px !important;
    }
  }

  .calendarArea {
    width: 100%;
  }
}

@media screen and (max-width: $mobile-small) {
  .bookingCalendarWrapper {
    display: block !important;
    width: 100% !important;

    .selectedDateCalendar {
      width: 100%;

      .maxWidth400 {
        width: 100%;
        max-width: unset;
      }
    }

    .timeListArea {
      width: 100%;
    }

    .hideDateCalendar {
      display: none;
    }
  }
}

@media screen and (max-width: 395px) {
  .bookingCalendarWrapper {
    .timeListArea {
      padding-left: 10px;
      padding-right: 10px;
    }

    .timeZone {
      margin-left: 10px !important;
    }
  }
}

@media screen and (max-width: 330px) {
  .bookingCalendarWrapper {
    [class~='ant-picker-panel'] {
      width: 90%;

      [class~='ant-picker-content'] {
        table-layout: unset;
      }
    }
  }
}
