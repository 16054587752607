@import 'styles/variables';
@import 'styles/functions';

.multiAvatar {
  position: relative;
  width: setUnit(40);
  height: setUnit(40);
  .avatar {
    width: 100%;
    height: 100%;
    span[class~='ant-avatar-string'] {
      flex: 0 0 100%;
      transform: scale(0.55) translate(-50%, -85%) !important;
    }
  }
  .avatarWrap {
    position: relative;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    &:last-child {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .overlay {
      font-weight: 700;
      font-size: setUnit(14);
      line-height: setUnit(16);
      color: $white;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
}
.avatar {
  width: setUnit(40);
  height: setUnit(40);
  span[class~='ant-avatar-string'] {
    top: 50%;
    transform: scale(1) translate(-50%, -50%) !important;
  }
}
