@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  min-height: calc(100vh - $header-height) !important;
}

.servicesContainer {
  max-width: setUnit(1000) !important;
  height: 100%;
}

.headWrapper {
  display: block;
  padding: 2rem 0.75rem 3.5rem 0.75rem;
  text-align: center;
  width: 100%;

  .headTitle {
    font-weight: bold;
    font-size: setUnit(30);
    color: $text-color;
  }

  .headTitleDescription {
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $dark-grey-color;
    max-width: 500px;
    margin: 0 auto;
  }
}

.pageWrapper {
  display: block;
  width: 100%;
}

.locationArea {
  display: inline-flex;
  width: 256px;
}

.formArea {
  color: $text-color;
  font-size: 16px;
  width: 400px;
  margin: 0 auto;
  font-weight: 600;

  @media screen and (max-width: $mobile-small) {
    width: 100% !important;
    padding: 10px;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.questionArea {
  margin: 20px 0px;

  .question {
    margin-bottom: 15px;
  }

  .optionsArea {
    text-align: center;
    padding: 15px 0px;

    .ant-radio-button-wrapper {
      height: 40px !important;
      padding: 4px 20px !important;

      .ant-radio-button {
        height: 40px !important;
        padding: 4px 20px !important;

        &:hover {
          background-color: #252d44 !important;
          color: #fff !important;
        }
      }
    }
  }
}

.footerArea {
  text-align: center;

  .submitBtn {
    height: setUnit(40) !important;
    width: setUnit(200) !important;
    transition: all 0.1s;
    margin-top: setUnit(40);
    background-color: #252d44;
    color: #fff;
    border: none;
    padding: 0.3125rem 0.9375rem;
    border-radius: 0.5rem;
    cursor: pointer;
    outline: none;

    &:hover {
      color: #fff;
      background-color: #1d4f76;
    }
  }
}
