@import 'styles/variables';

.commonTabs {
  & [class*='ant-tabs-tab-btn'] {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    font-family: $font-family !important;
    color: $text--light-gray-color !important;
  }

  > div {
    margin: 0 !important;
  }

  & [class*='ant-tabs-tab-btn']:hover {
    color: $text-color !important;
  }

  & [class*='ant-tabs-tab-active'] {
    & [class*='ant-tabs-tab-btn'] {
      color: $text-color !important;
    }
  }

  & [class*='ant-tabs-ink-bar'] {
    background: $primary-color !important;
  }
}
