@import 'styles/variables';
@import 'styles/functions';

.taskCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  border-radius: 8px;
  border: 1px solid $light-gray;
  padding: 20px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .menuIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .taskData {
    display: flex;
    justify-content: center;
    align-items: center;

    .taskIcon {
      margin-right: 20px;
    }

    .taskName {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $text-color;
      display: block;
      margin-bottom: 5px;
    }

    .taskMeta {
      display: block;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-color;
    }
  }
}

.entityOptionsDropDown {
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .icon {
    margin-right: 10px;
    svg {
      path,
      circle {
        stroke: $primary-color;
      }
    }
  }

  .entityOption {
    &:hover {
      cursor: pointer;
      background-color: $background-ligh-gray-3;
    }

    width: 176px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    padding: 10px 15px;

    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
