.cardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .optionBtn {
    cursor: pointer;
  }

  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 16px 0;
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: rgba(146, 140, 218, 0.12);

    color: #928cda;
  }

  .title {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 6px;

    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.18px;

    .dot {
      width: 4px;
      height: 4px;

      background: #d6d6d7;
      border-radius: 50%;
    }
  }
}

.frequenceWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltipEllipsis {
  display: inline-flex;
  position: relative;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipEllipsis::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1000;
}

.tooltipEllipsis:hover::before {
  visibility: visible;
  opacity: 1;
}
