@import 'styles/functions';
@import 'styles/variables';

.documentsContentModal {
  [class='ant-modal-content'] {
    padding-top: setUnit(32) !important;
    background-color: $background-gray;
    box-shadow: 0px 24px 80px 0px #00000033;

    @media screen and (width <= 768px) {
      padding: setUnit(24) !important;
    }

    [class='ant-modal-close'] {
      top: setUnit(32);

      @media screen and (max-width: $mobile) {
        right: 26px;
        top: setUnit(28);

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  .progressBar {
    display: none !important;
  }

  @media screen and (width <= 768px) {
    width: 90% !important;
  }
}
