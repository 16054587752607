@import 'styles/functions';
@import 'styles/variables';

.ClientSearches {
  .headerButtonsLeft {
    position: absolute;
    top: 115px;
    left: 40px;
  }
  .content {
    width: 100%;
    min-height: calc(100vh - 340px);
    display: flex;
    justify-content: center;
    .widthStyle {
      width: 50%;
    }
  }
  margin: setUnit(15);
  @media (min-width: $tablet) {
    margin: 2rem 4rem;
  }
  .title {
    font-weight: 600;
    margin-bottom: 2rem;
  }
}
.teamFilter {
  width: max-content;
  float: left;
}
.headerButtonsRight {
  position: absolute;
  top: 115px;
  right: 40px;
  display: flex;
}

// SEARCH BAR STYLES
.searchBarContainer {
  display: flex;
  justify-content: center;
  margin-bottom: setUnit(24);
  .barStyles {
    height: 52px;
    border-radius: 100px !important;
    border-width: 2px;
    border-right-width: 2px !important;
    border-color: #e8e9ea;
    width: 100%;
    padding: 5px 10px;
    box-shadow: none !important;

    [class*='ant-input-prefix'] {
      color: grey;
      cursor: pointer;
    }
  }
  [class*='ant-input-affix-wrapper']:focus {
    border-color: $text--light-gray-color !important;
  }
  [class*='ant-input-affix-wrapper']:hover {
    border-color: $text--light-gray-color !important;
  }
}

.footer {
  margin-top: 30px;
}

.icon {
  padding: 0 setUnit(5);
  svg {
    width: setUnit(20) !important;
    height: setUnit(20) !important;
    path {
      stroke: $dark-color;
    }
  }
}

.searchIcon {
  z-index: 1;
  svg {
    vertical-align: middle;
    width: setUnit(16);
    height: setUnit(16);
  }
}
