@import 'styles/functions';
@import 'styles/variables';

.addresses {
  margin-top: setUnit(16);
  margin-bottom: setUnit(16);
  border-top: none;
  padding: 0;

  .line1 {
    font-size: setUnit(14);
    color: $text-color;
  }

  .city {
    font-size: setUnit(14);
    color: $dark-grey-color;
    margin-top: setUnit(4);
  }
}
