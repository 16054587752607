@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contactAutoComplete {
  display: flex !important;
  align-items: center !important;
  @include lightRounded;
  padding: 0rem !important;
}

.autoCompleteDropdown {
  margin-bottom: 1rem;

  .autoCompleteOption {
    display: flex;
    align-items: center;

    .name {
      font-size: 16px;
    }
    .email {
      font-size: 14px;
      color: $border-middle-gray;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    margin-right: 15px;

    .avatarPlaceholder {
      font-size: 14px;
    }
  }

  .contactField {
    .label {
      margin-bottom: 0;
      font-size: 16px !important;
    }

    .fieldWrapper > label > span {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }

    .fieldWrapper {
      margin-bottom: 1.675rem;
    }

    .error {
      color: #ec455e;
      font-size: 0.875rem;
      margin-top: -0.5rem;
    }
  }
}
