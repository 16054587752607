@import 'styles/functions';
@import 'styles/variables';

.agentRoleWrapper {
  margin: 0 !important;
  padding: 0 8px;
  div[class*='selectHolder'] {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      background: $background-grey-2;
      height: setUnit(44) !important;
      width: setUnit(114) !important;
      border-radius: 100px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      color: $dark-color !important;
      text-align: center;
      border: 0 !important;
      padding: 0 !important;
    }
    .arrowIcon {
      right: 8px;
    }
  }
}

.agentRoleConfirmText {
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.33px;
    color: $text-color;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
    text-align: left;
    margin: 0;
  }
}
