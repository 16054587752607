@import 'styles/functions';
@import 'styles/variables';

.subtitle {
  font-size: setUnit(14);
  font-weight: 400;
  color: $text-gray-color;
  &_noMargin {
    margin-top: 0;
  }
}
