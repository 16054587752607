@import 'styles/functions';
@import 'styles/variables';

.documentCard {
  border-radius: setUnit(16);
  margin-bottom: setUnit(24);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 228px;
  cursor: pointer;
  transition: $transition;

  &:hover {
    opacity: 0.7;
  }

  &.orange {
    background-color: #fb913a1a;
  }
  &.green {
    background-color: #04a4511a;
  }
  &.purple {
    background-color: #928cda1a;
  }
  &.white {
    background-color: $white;
    border: 2px solid $light-gray;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContainer {
      margin-bottom: setUnit(16);
    }

    img {
      width: 52px;
      height: 52px;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: setUnit(8);
    }

    .count {
      font-size: 14px;
      color: $light-gray-2;
      margin: 0;
    }
  }
}
