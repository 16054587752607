@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  margin-bottom: 40px;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: setUnit(675) !important;
  width: 100%;
  .innerHolder {
    padding: 40px !important;
    padding-top: 10px !important;
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .addContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    .add {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $background-grey-2;
      svg {
        position: relative;
        top: 3px;
      }
    }
    .label {
      color: $dark-color;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.33px;
      margin: 0;
    }
  }
  .bottonContainer {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .saveButton {
      min-width: 120px;
    }
    .cancelButton {
      min-width: 120px;
      border: 1.5px solid $border-light-gray-2;
      background: $white;
      color: $text-color;
    }
  }
}
