@import 'styles/functions';
@import 'styles/variables';

.attachment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $background-grey-2;
  padding: setUnit(16);
  border-radius: setUnit(8);
  &:not(:last-child) {
    margin-bottom: setUnit(12);
  }
  .info {
    flex: 1;

    .filename {
      font-size: setUnit(16);
      font-weight: 600;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.09);
      color: $text-color;
      margin-bottom: setUnit(4);
    }

    .justFileName {
      @extend .filename;
      margin-bottom: 0 !important;
    }

    .date {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.09);
      color: $dark-color;
      margin-bottom: 0;
    }
  }

  .icon {
    margin-right: setUnit(12);

    svg {
      vertical-align: middle;
    }
  }
}
