@import 'styles/functions';
@import 'styles/variables';

.uploadDocuments {
  .dropzoneWrapper {
    width: 100%;
  }
  .uploadIcon {
    width: setUnit(32);
    height: setUnit(32);
    background: $background-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: $border-light-gray;
    }
  }

  .uploadWrapper {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: 0;
    height: fit-content;
    margin-right: setUnit(12);

    .loader {
      font-size: setUnit(2.5);
      margin: 0;
    }

    .wrapper {
    }
  }
}
