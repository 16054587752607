@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.partnerCard {
  width: 100%;
  font-size: setUnit(20);
  font-weight: 600;
  margin-bottom: 20px;
  border: 1px solid $border-light-gray;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $border-light-gray;
    margin-bottom: 10px;
    align-items: baseline;
    padding: 10px;

    .title {
      margin-right: auto;
      p {
        font-size: setUnit(16);
        font-weight: 600;
        span {
          font-weight: normal;
        }
      }
    }

    .actionContainer {
      .actionBtn {
        @include button($secondary-color, $white, $hover-blue);
        width: fit-content;
        border-radius: 50%;
        margin-left: 10px;
        vertical-align: middle;
        & svg {
          width: setUnit(24);
          vertical-align: middle;

          path {
            stroke: $white;
            stroke-width: 2px;
          }
        }

        .icon {
          display: inline-flex;
          background-color: $secondary-color;
          color: $white;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            color: $white;
          }
        }
        &:hover {
          div {
            background-color: $hover-blue !important;
          }
        }
      }
    }
  }

  .cardBody {
    padding: 10px;
    .topRow {
      margin-bottom: 20px;
    }
    .columnName {
      font-size: setUnit(14);
      font-weight: 600;
    }
    .columnValue {
      font-size: setUnit(16);
      font-weight: normal;
    }
    .bottomRow {
      margin-top: 20px;
    }
  }

  .error {
    color: $error-color;
  }
}
