@import 'styles/functions';
@import 'styles/variables';

.multiple {
  cursor: pointer;
  width: setUnit(44);
  height: setUnit(44);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: 2px solid $light-gray;
  transition: $transition;
  &:hover {
    border-color: $text--light-gray-color;
  }
  svg {
    vertical-align: middle;
  }
}
