@import 'styles/functions';
@import 'styles/mixins';
@import 'styles/variables';

.accordionContainer {
  border-bottom: 1px solid $light-gray;
  overflow: hidden;
}
.collapse {
  overflow: visible !important;
}
.preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: setUnit(16) 0;
  .expandWrapper {
    display: flex;
    align-items: center;
  }

  .iconContainer {
    background-color: $background-gray;
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background: $border-light-gray;
    }

    button {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      margin-top: 3px;
    }
  }

  .title {
    margin: auto 0;
    font-weight: 600;
  }
}
