@import 'styles/functions';
@import 'styles/variables';

.heading {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.47px;
  color: $text-color;
  margin-bottom: setUnit(6);
}

.subHeading {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.18px;
  color: $text-color;
}

.modalFooter {
  .stepOneBtn {
    text-align: right;
    margin: setUnit(48) 0 0 0;
  }
  .stepTwoBtn {
    text-align: center;
    margin: setUnit(48) 0 0 0;
  }
  .saveBtn {
    color: $white;
    background-color: $background-blue;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(24);
    text-align: center;
    border-radius: setUnit(8);
    padding: 0.3125rem 0.9375rem;
    width: 8.6875rem;
    height: 3.5rem;
    border: 0;
    cursor: pointer;
    &:hover {
      background-color: #1d4f76;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.stepOne {
  .timelineName {
    margin-bottom: setUnit(32);
  }
  .descriptionInput {
    margin: 0;
  }
  .inputBlock {
    .label {
      margin-bottom: setUnit(16);
    }
  }
  .item {
    label {
      color: $dark-color;
      font-family: Inter;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-weight: 600;
      margin-bottom: setUnit(16);
      display: inline-block;
      small {
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-weight: 400;
        color: $text--light-gray-color;
      }
    }
    .select {
      display: block;
      [class*='ant-select-selector'] {
        border: 0;
        border-bottom: 2px solid $border-light-gray-2 !important;
        font-family: Inter;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-weight: 400;
        padding: 0 0 setUnit(10) 0;
        width: 100%;
        box-shadow: none !important;
        [class*='ant-select-selection-placeholder'],
        [class*='ant-select-selection-item'] {
          line-height: setUnit(19);
        }
      }
      [class*='ant-select-selection-placeholder'] {
        left: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: setUnit(32);
    }
  }
  .people {
    margin-top: 2rem;
  }

  .itemPlain {
    @extend .item;

    input {
      border-bottom: none !important;
      padding: 0px !important;
    }
  }
}

.stepTwo {
  .stepTwoHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    padding-bottom: setUnit(18);
    margin-bottom: setUnit(18);
    .STHLeft {
      h3 {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: setUnit(21);
        margin-bottom: setUnit(3);
        color: $text-color;
      }
      h4 {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(20);
        margin-bottom: 0;
        color: $text-color;
      }
    }
    .editIcon {
      cursor: pointer;
    }
  }
  .milestonePoint {
    .timelineName {
      margin-right: setUnit(32);
    }
    .deadline {
      width: 34%;
      margin-left: 0;
      border-bottom: 2px solid $border-light-gray-2;
      padding-bottom: 1px;
      .numberInput {
        padding-left: setUnit(8) !important;
        border-bottom: 0;
      }
      .icon {
        position: relative;
        width: setUnit(31);
        bottom: 0.4rem;
      }
    }
  }
  .stTooltip {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: setUnit(30);
    h4 {
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: 600;
      margin-right: setUnit(10);
      margin-bottom: 0;
    }
  }
}
