@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.componentFooter {
  height: 92px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .submitButton {
    height: 52px !important;
    width: 152px !important;
  }
}
