@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.backyardSelector {
  padding: setUnit(8) 0 setUnit(28) setUnit(48);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0;
    font-size: setUnit(14);
    font-weight: 400;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
    color: $dark-color;
  }
}
