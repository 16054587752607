@import "styles/functions";
@import "styles/variables";

.drawer {
    .drawerInner {
        height: calc(100vh - #{setUnit(93)});
        overflow: auto;
    }

    .autocompleteInputHolder {
        margin: 0 setUnit(32);
        position: relative;
    }

    .participantsValues {
        height: calc(100vh - #{setUnit(314)});
        overflow: auto;
    }

    .participantsValue {
        padding-left: setUnit(32);
        padding-right: setUnit(32);
    }

    .scrollWrapper {
        flex: 1;
    }

    .searchInput {
        margin: 0;
    }
}
