@import 'styles/functions';
@import 'styles/variables';

.left {
  border-bottom: 1px solid $border-light-gray;
  overflow: auto;
  width: 100%;
  @media (min-width: $tablet) {
    width: 50%;
    border-right: 1px solid $border-light-gray;
    border-bottom: 0;
    min-height: calc(100vh - $header-height);
  }
}
