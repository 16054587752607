@import 'styles/functions';
@import 'styles/variables';

.notesModal {
  [class*='ant-modal-content'] {
    padding: 40px 0px 40px 32px !important;

    [class~='ant-modal-close'] {
      right: setUnit(24);
    }
  }

  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.47px;
    color: $text-color;
    margin: 0.1rem auto 0;
    padding-right: 24px;

    max-width: 75%;
    span {
      position: relative;
      display: inline-block;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: setUnit(80);

    &.emptyModal {
      height: -webkit-fill-available;
    }

    .message {
      font-size: setUnit(16);
      font-weight: 100;
      color: $text--light-gray-color;
    }
  }
}
