@import 'styles/functions';
@import 'styles/variables';

.propertiesHeader {
  margin: setUnit(16) auto;
  justify-content: center;
  @media (min-width: $tablet) {
    min-height: setUnit(80);
    margin: setUnit(16);
  }
  & [class*='ant-select-arrow'] {
    font-size: 14px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.textCenter {
  text-align: center;
}

.propertiesHeaderToggle {
  display: flex !important;
  align-items: center;
  justify-content: center;
  & [class*='ant-select-selector'] {
    & [class*='ant-select-selection-item'] {
      font-size: setUnit(22);
      @media (min-width: $mobile) {
        font-size: setUnit(32);
      }
    }
  }
}
