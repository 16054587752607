@import 'styles/functions';
@import 'styles/variables';

.action {
  font-size: setUnit(14);
  font-weight: 500;
  height: setUnit(40);
  padding: 0 setUnit(16);
  min-width: setUnit(74);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-middle-gray;
  border-radius: setUnit(100);
  background: #ffffff;

  .checkmark {
    margin-right: setUnit(9);
    svg {
      vertical-align: middle;
      width: setUnit(16);
      height: setUnit(16);

      path {
        stroke: $secondary-color;
      }
    }
  }

  &.selected {
    border-color: $secondary-color;
  }
}
