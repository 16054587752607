@import 'styles/functions';
@import 'styles/variables';

.matchItemsListContainer {
  display: flex;

  .matchContainer {
    flex-basis: 50%;
  }

  .fullLengthContainer {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .noData {
    color: $dark-grey-color;
    padding-bottom: setUnit(12);
  }
}
