@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  .subtitle {
    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.47px;
  }
  [class='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
  }

  [class='ant-modal-header'] {
    background: #f4f6fb;
    padding-top: 32px;
    padding-bottom: 2px;
  }

  [class='ant-modal-content'] {
    background: #f4f6fb;
    padding: 0 40px 32px 40px;
    border-radius: 24px;
    box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.2);
  }

  [class='ant-modal-close'] {
    top: 20px;
    right: 20px;
  }

  [class='ant-modal-body'] {
    padding: 0 !important;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .content {
    margin-top: 24px;
    border-radius: 16px;
    background: #fff;
    white-space: pre-line;
    padding: 20px;
    margin-bottom: 20px;

    .inputWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 14px;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .inputContainer {
        flex-grow: 1;

        .input {
          padding: 0px !important;
          border: none !important;
          border-radius: 0 !important;

          color: var(--Grayscale-50, #747475);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 137.5% */
          letter-spacing: -0.18px;

          &:focus {
            border: none !important;
            box-shadow: none !important;
          }

          &::placeholder {
            color: var(--Grayscale-50, #747475);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            letter-spacing: -0.18px;
          }
        }
      }
    }
  }
}

.cellWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  .multiSelect {
    .checkbox {
      [class*='ant-checkbox'] {
        [class*='ant-checkbox-inner'] {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border-color: $text--light-gray-color;
        }
      }

      [class*='ant-checkbox-checked'] {
        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color;
          border-radius: 4px;
        }
        [class*='ant-checkbox-inner']::after {
          width: 7px;
          height: 12px;
          left: 25%;
          top: 45%;
        }

        &::after {
          border-color: $primary-color;
          border-radius: 4px;
        }
      }
    }

    &:hover,
    &:focus {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color;
      }
    }
  }

  .name {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.33px;
  }
}

.actionIcons {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tableContainer {
  height: 434px;
  overflow-y: auto;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    width: 180px;
  }
}

.subtitle {
  color: var(--Grayscale-100, #262626);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.47px;
}

.mt {
  margin-top: 6px;
}

.alignRight {
  text-align: right !important;
}
