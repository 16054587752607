@import 'styles/functions';
@import 'styles/variables';

.document {
  display: flex;
  justify-content: space-between;
  background-color: $background-gray;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0.5rem 0;
  .mainHeading {
    color: #262626;
    font-size: 16px;
    margin-bottom: 4px;
  }
  .infoIconWrapper {
    display: flex;
  }
  .sideLabel {
    color: $text--light-gray-color;
    margin-left: 2px;
  }
  .headingLabelWrapper {
    display: flex;
    align-items: center;
  }
  .infoMenuWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .docIcon {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .info {
    display: inline-block;
    margin-left: 10px;
    .displayInline {
      display: inline;
    }
    h4 {
      margin: 0;
      line-height: 1rem;
      margin-left: 2px;
      margin-right: 2px;
    }

    P {
      margin: 0;
      font-size: 14px;
    }
    .fileName {
    }
  }

  .dropdown {
    float: right;

    .moreIcon {
      margin-top: 0.25rem;
      cursor: pointer;
    }
  }
}

.preApprovalMenu .action {
  padding: 0.5rem 1.75rem !important;
}
