@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.offerLink {
  padding: 1.5rem 0 0;
  margin-right: setUnit(24);
  border-top: 1px solid $light-gray;

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    padding: setUnit(24);
    margin-bottom: 12px;

    background-color: $background-ligh-gray-5;

    .radioButtons {
      width: 100%;

      .container {
        width: inherit;

        .indefinitely {
          margin-left: 4px;
        }

        .static {
          margin-left: 0.5rem;
        }

        [class*='ant-input-number'] {
          border: 0;
          border-radius: 0;
          width: 45px;
          text-align: center;
          input {
            border-bottom: 2px solid $border-input-middle-gray;
            &::placeholder {
              color: $text--light-gray-color;
            }
          }
          &[class*='ant-input-number-focused'] {
            input {
              border-bottom-color: $text-gray-color;
            }
          }

          [class*='ant-input-number-handler-wrap'] {
            display: none;
          }
        }

        [class*='ant-radio-group'] {
          width: 100%;
        }

        [class*='ant-radio-wrapper'] {
          width: fit-content;

          font-size: setUnit(16);
          font-weight: 400;
          line-height: 0;
          letter-spacing: -0.18px;
          align-items: center;
          display: flex;

          [class*='ant-radio'] {
            top: 0px;

            & + span {
              padding-left: setUnit(12);
              padding-right: 0;
            }
            &[class*='ant-radio-checked'] {
              [class*='ant-radio-inner'] {
                border-color: $primary-color !important;
              }
              ~ span {
                color: $text-color;
              }
            }

            [class*='ant-radio-inner'] {
              padding-left: 0;
              width: setUnit(24);
              height: setUnit(24);
              border-radius: setUnit(100);
              border: 1.5px solid $text--light-gray-color;
              box-shadow: unset;
              &:after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzMyNTIgNC45OTk4NEw0LjY2NTg1IDguMzMzMTdMMTEuMzMyNSAxLjY2NjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                width: 100%;
                height: 100%;
                background-color: $primary-color;
                border-radius: 16px;
                transform: scale(1);
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                top: 0;
                left: 0;
                margin-top: 0;
                margin-left: 0;
              }
            }
            ~ span {
              color: $text-gray-color;
              margin-top: 2px;
              width: 100%;
            }
          }
          &:hover {
            [class*='ant-radio-inner'] {
              border-color: $primary-color !important;
            }
          }
        }
      }
    }

    .notRadio {
      margin-top: 24px;
    }

    .deadlineDate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      gap: 1rem;

      padding-left: setUnit(32);
      .picker {
        border: 0;
        border-bottom: 2px solid $border-input-middle-gray;
        padding: 0.25rem;
        width: setUnit(100);
        @include customDatepicker;
      }

      .inputTime {
        border-bottom: 2px solid $border-input-middle-gray;
        margin-right: setUnit(8);
        margin-left: 0px;
      }

      .offerTimeZone {
        margin-top: 0;

        span {
          font-weight: 400;
        }
        span > span {
          font-weight: 600;
        }
      }

      .static {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: setUnit(16);
        color: $text-gray-color;
      }

      [class*='ant-picker-cell-inner'] {
        font-weight: 500 !important;
      }

      [class*='ant-picker-cell-today'] {
        [class*='ant-picker-cell-inner'] {
          font-weight: 700 !important;
        }
      }
    }

    [class*='ant-picker-focused'],
    [class*='ant-input-number-focused'] {
      box-shadow: none;
    }

    [class*='ant-picker-suffix'],
    [class*='ant-picker-clear'],
    [class*='ant-picker-active-bar'] {
      display: none;
    }
  }
}

.timeInput {
  margin-right: 0;
  .timeSelect {
    .timeDropdown {
      padding: 0;
      background: $white;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      width: 71px !important;
      div[class~='ant-select-item-option'] {
        padding: 12px 16px;
        display: flex;
        flex-flow: row-reverse;
        position: relative;
        div[class~='ant-select-item-option-content'] {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: $text-color;
          display: flex;
          align-items: center;
        }
        &[class~='ant-select-item-option-selected'] {
          background-color: $background-grey-2;
          [class~='ant-select-item-option-state'] {
            border-color: $primary-color;
            background-color: $primary-color;
            color: $white;
          }
          [class~='ant-select-item-option-content'] {
            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
}
