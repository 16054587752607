@import 'styles/functions';
@import 'styles/variables';

.autoCompleteDropdown {
  .autoCompleteOption {
    display: flex;
    align-items: center;

    .userContainer {
      width: auto;
    }

    .name {
      font-size: 16px;
    }
    .email {
      font-size: 14px;
      color: $border-middle-gray;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    margin-right: 15px;

    .avatarPlaceholder {
      font-size: 14px;
    }
  }
}
