@import 'styles/functions';
@import 'styles/variables';

.tableTitle {
  font-size: setUnit(18);
  font-weight: 700;
  margin: setUnit(20) 0;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: $dark-grey-color;
  }
}

.countText {
  color: $text--light-gray-color;
  margin-left: setUnit(2);
}

.collapseIcon {
  margin-left: setUnit(4);
  transition: transform 0.3s ease-in-out;

  &.iconRotate {
    transform: rotate(180deg);
  }
  svg {
    color: $text--light-gray-color;
  }
}
