@import 'styles/functions';
@import 'styles/variables';

.description {
  .spacing {
    margin-bottom: 2rem;
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0.5rem;
  }

  .textarea {
    border-top-width: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-color: $white !important;
    margin-top: 0.675rem;
    border-radius: 0 !important;
    border-bottom: 2px solid $border-light-gray-2;
    font-size: 16px;
    padding: 0;

    &:hover {
      border-color: $border-light-gray-2 !important;
    }
    &:focus {
      border-color: $text-gray-color !important;
      box-shadow: none !important;
      border-right-width: 0px !important;
      outline: 0;
    }
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }
}
