@import 'styles/variables';
@import 'styles/functions';

.matchItemContainer {
  display: flex;
  margin-bottom: 0.25rem;
  padding-right: 1rem;
  align-items: flex-start;
  flex-basis: 50%;

  .criteriaInsight {
    font-size: 13px;
    color: $light-black;
    margin-top: 4px;
  }

  .itemName {
    line-height: 1rem;
    margin-bottom: 0.5rem;
    > span {
      font-size: 10px;
    }
  }

  .matched {
    font-weight: 600;
    font-size: setUnit(14);
    color: $text-color;
  }

  .partial {
    position: relative;
    top: 0.2rem;
  }

  .unmatched {
    font-weight: 600;
    font-size: setUnit(14);
    color: $text-color;
    position: relative;
    top: 0.25rem;
  }

  .content {
    display: inline-block;
    width: calc(100% - 24px);
    margin-left: 0.5rem;
  }
}

.matchLevelIcon {
  display: inline-block;
  width: 18px;
  &.partial {
    svg {
      position: relative;
      right: 4px;
      bottom: 3px;
    }
  }
}
