@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filterWrap {
  position: relative;
  float: right;
  .filterButton {
    display: flex;
    align-items: center;
    border: 2px solid $light-gray;
    border-radius: setUnit(100);
    color: $dark-color;
    font-weight: 600;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: -0.09px;
    padding: setUnit(10) setUnit(14);
    width: fit-content;
    cursor: pointer;
    user-select: none;
    .icon {
      margin-left: setUnit(4);
      display: flex;
      transform: rotate(0deg);
      @include transition;
      &.open {
        transform: rotate(180deg);
      }
      svg {
        width: setUnit(12);
        height: setUnit(6);
        path {
          stroke: $dark-color;
        }
      }
    }
  }
  .options {
    padding: 0;
    width: 176px;
    height: 184px;
    background: $white;
    box-shadow: setUnit(0) setUnit(32) setUnit(80) rgba(0, 0, 0, 0.06),
      setUnit(0) setUnit(8) setUnit(30) rgba(0, 0, 0, 0.08);
    border-radius: setUnit(8);
    position: absolute;
    right: 0;
    top: setUnit(52);
    z-index: 1;
    .optionsItems {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      label[class~='ant-radio-wrapper'] {
        flex: 0 0 100%;
        display: flex;
        background-color: $white;
        margin-right: 0;
        padding: setUnit(20) setUnit(24);
        height: setUnit(56);
        @include transition;
        span[class~='ant-radio'] {
          display: none;
        }
        span:last-child {
          flex: 0 0 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(16);
          letter-spacing: -0.09px;
          color: $text-color;
        }
        &:hover {
          background-color: $background-grey-2;
        }
        .icon {
          opacity: 0;
          visibility: hidden;
          @include transition;
          svg {
            width: setUnit(12);
            height: setUnit(8);
          }
        }
      }
      label[class='ant-radio-wrapper ant-radio-wrapper-checked'] {
        .icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
