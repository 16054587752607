@import 'styles/functions';
@import 'styles/variables';

$drawer-header-height: 64px;
.hamburgerContainer {
  @media screen and (min-width: $responsive-menu + 1px) {
    display: none !important;
    visibility: hidden;
  }
  .iconWrap {
    display: flex;
  }
}

.navLinks {
  padding: 8px 0px;
  margin-bottom: 8px;
  border-bottom: 1px solid $light-gray;
}

.headerDrawer {
  position: fixed !important;
}
