@import 'styles/functions';
@import 'styles/variables';

.reassignShareModal {
  [class='ant-modal-content'] {
    padding: setUnit(32);
    margin: 4rem 0;
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(32);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
  }

  .content {
    .clients {
      display: flex;
      align-items: center;
      width: 100%;
      height: 72px;
      padding: 0px 148px 0px 24px;
      border-radius: 12px;
      background: $background-grey-2;
      font-size: 16px;
      font-weight: 500;
      color: $dark-color;
      span {
        color: $text-color;
        margin-left: 8px;
        font-weight: 600;
      }
    }

    .card {
      padding: 20px 24px;
      border-radius: 12px;
      background: $background-grey-2;
      margin-bottom: 12px;
      .heading {
        font-size: 16px;
        font-weight: 500;
        color: $dark-color;
        text-align: left;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
      }
    }
    .subHeading {
      margin-top: 2.125rem !important;
    }

    .user {
      display: flex;
      align-items: center;
      padding: setUnit(10) 0;

      .avatar {
        width: setUnit(24);
        height: setUnit(24);
        background: $secondary-color;
        color: $white;
      }

      .avatarPlaceholder {
        font-size: setUnit(10);
      }

      .label {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 16px;
        color: $text-color;
      }
    }

    .multiSelectWrapper {
      margin: 0.25rem 0 1.5rem 0;

      .multiSelect {
        & [class*='ant-select-selector'] {
          min-height: setUnit(40);
          height: auto;
          border: unset !important;
          border-bottom: 2px solid $border-input-middle-gray !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          padding-left: 0;
        }

        & [class='ant-select-selection-placeholder'] {
          left: 0;
        }

        & [class='ant-select-selection-item'] {
          border-radius: 30px;
          padding-right: 0.5rem;
          border: 1px solid $border-light-gray-2;
        }
      }
    }

    .submitBtn {
      width: 160px;
      height: 52px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 2.5rem;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
}

.reassignShareConfirmText {
  text-align: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.33px;
    text-align: center;
    color: $dark-color;
    margin-bottom: 0;
  }
}

.loadingSpinner {
  margin: 50px auto;
  font-size: 0.3rem;
}
