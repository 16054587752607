@import "styles/functions";
@import "styles/variables";

.wrapper {
    height: 100%;
    position: relative;

    .sendMessageForm {
        height: 100%;
    }
}