@import 'styles/variables';

.permissionSelector {
  width: 100% !important;

  [class*='ant-select-selection-placeholder'] {
    color: black;
    left: 0px !important;
  }

  [class*='ant-select-selector'] {
    box-shadow: none !important;
    font-size: 16px;
    border: none !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
    padding: 0px !important;
    display: flex !important;
    align-items: center;
  }

  [class*='ant-select-selection-search'] {
    margin-left: 0px !important;
  }

  [class*='ant-select-selection-item'] {
    padding-left: 0px !important;
  }
}

.permissionOptions {
  z-index: 9999 !important;

  [class*='ant-select-item-option-content'],
  [class*='ant-select-item-option-state'] {
    display: flex !important;
    align-items: center !important;
  }
  [class*='ant-select-item-option-selected'] {
    background: transparent !important;
  }
}
