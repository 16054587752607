@import 'styles/functions';
@import 'styles/variables';

.aside {
  width: 100%;
  padding: setUnit(20);
  @media (min-width: 992px) {
    padding: setUnit(20) 0 setUnit(20) setUnit(40);
    max-width: setUnit(360);
  }
}
