@import 'styles/functions';
@import 'styles/variables';

.heading {
  margin-top: 56px;
  margin-bottom: 18px;
  font-size: setUnit(20);
  font-weight: 600;
  line-height: setUnit(26);
  letter-spacing: setUnit(-0.33);
}

.propertyPrefs {
  margin-bottom: 1rem;
}

.softCriteriaCollapse {
  .softCriteriaTitle,
  .softCriteriaSelected {
    margin-bottom: 0;
  }
  .softCriteriaSelected {
    margin-left: 8px;
    color: $text--light-gray-color;
  }
  .softCollapse {
    font-weight: 600;
    font-size: 16px;
    color: $text-color;
    margin-top: 0.5rem;

    & [class*='ant-collapse-content-box'] {
      padding: 0 0 0.75rem !important;
    }

    & [class*='ant-collapse-header'] {
      padding: 0.75rem 0 0.5rem !important;

      &:hover {
        & [class*='ant-collapse-arrow'] {
          svg {
            circle {
              fill: $light-gray !important;
            }
          }
        }
      }

      & [class*='ant-collapse-header-text'] {
        display: flex;
      }

      & [class*='ant-collapse-arrow'] {
        display: flex;
        transition: all 0.3s, visibility 0s;
        height: 32px;
        width: 32px;
        right: 0;

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}
