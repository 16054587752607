@import "styles/functions";
@import "styles/variables";

.carousel {
    overflow: hidden;

    hr {
        margin: 0 setUnit(18);
        border: 0;
        border-top: 1px solid $border-light-gray;
    }

    .controls {
        display: flex;
        justify-content: space-between;
        padding: setUnit(4) setUnit(8) setUnit(8) setUnit(8);

        .pages {
            font-size: setUnit(12);
            font-weight: 500;
            color: $dark-grey-color;
            display: flex;
            align-items: center;
        }

        .arrow {
            cursor: pointer;
            background: none;
            outline: none;
            border: none;
            line-height: setUnit(4);
            width: setUnit(24);
            height: setUnit(24);
            display: flex;
            align-items: center;
            justify-content: center;

            .arrowLeft {
                transform: rotate(-180deg);
            }
        }
    }

    .slides {
        display: flex;
        justify-content: flex-start;
        transition: 300ms ease-out transform;
    }
}
