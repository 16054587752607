@import 'styles/variables';

$question-header-height: 68px;

.smartLeftSidebar {
  background: $white !important;

  & [class*='ant-layout-sider-children'] {
    width: 240px;
  }
  & [class*='ant-popover-inner-content'] {
    padding: 0px !important;
  }

  .collapseSpan {
    display: block;
    width: 100%;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey-color;
  }

  .addQuestionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $question-header-height;

    .questionText {
      margin-left: 24px;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .addBtn {
      margin-right: 24px;
    }
  }

  .noEntries {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text--light-gray-color;
    height: calc(100vh - $header-height - $question-header-height);
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0px 44px;
  }

  .dragMenuList {
    height: calc(100vh - $header-height - $question-header-height);
    overflow: auto;
  }
}

.questionMenuItem {
  display: flex;
  align-items: center;
  min-height: 56px;
  width: 100%;

  .questionIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    min-height: 56px;
  }

  .questionName {
    width: 150px;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    border: none;
    min-height: unset !important;
  }
}

.questionTextArea {
  width: 100%;
  display: flex;
  align-items: center;

  .nameField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $text-color;

      width: 135px;
      word-wrap: break-word;
    }
  }
}

.fields {
  width: 100%;
  display: block;

  .fieldName {
    width: 110px;
    word-wrap: break-word;

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $text-color;
    margin-left: 10px;
  }
}

.questionBox {
  .spanSpace {
    width: 27px;
  }

  [class*='ant-collapse'] {
    border: none;
    border-bottom: none;
    background-color: transparent;

    [class*='ant-collapse-item'] {
      border-bottom: none;
    }
  }

  [class*='ant-collapse-header'] {
    cursor: auto !important;

    display: flex;
    align-items: center !important;
    padding: 12px 0px !important;

    [class*='ant-collapse-arrow'] {
      margin: 0px 7.5px !important;

      svg path {
        stroke: $dark-color;
        stroke-width: 50px;
      }
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .fieldsList {
    margin-bottom: 0px;
    margin-left: 20px;
    list-style: none;
    color: white;

    .dropdownItems {
      display: flex;
      align-items: center;
      padding: 10px 0px;
    }
  }
}

.dragIcon {
  opacity: 0;
  cursor: grab !important;
}

.listItem {
  &:hover {
    .dragIcon {
      opacity: 1 !important;
    }
  }
}

.fieldListItem {
  &:hover {
    cursor: pointer;
  }
}
