@import 'styles/functions';
@import 'styles/variables';

.nameText {
  max-width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.namesTooltip {
  cursor: pointer;
  width: fit-content;
  color: $text-gray-color;
  .text {
    text-decoration: underline;
  }
}
