@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  width: 100%;
  position: relative;
  padding: setUnit(16) setUnit(0);
  @media (min-width: $tablet) {
    padding: 0;
  }
}

.messagesItemTabs {
  border-bottom: none !important;
  margin-bottom: setUnit(20);
  justify-content: center;
  border-bottom: 1px solid #e8e9ea !important;
}

.messagesItemTab {
  padding: setUnit(12) 0;
  font-weight: 600;
  color: $light-gray-2 !important;
  transition: $transition;
  font-size: setUnit(14);
  line-height: setUnit(19);
  width: 100%;
  margin-right: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  @media (min-width: $mobile) {
    font-size: setUnit(16);
    line-height: setUnit(21);
  }
  &:hover {
    color: $text-color;
    background-color: transparent !important;
    border-bottom-color: $primary-color;
  }

  & svg {
    color: $light-gray-2;
  }
}

.messagesActiveItemTab {
  color: $text-color !important;
  border-bottom-color: $primary-color !important;
  & svg {
    color: $primary-color;
  }
}

.agentPartnerTab {
  overflow: auto;
}
