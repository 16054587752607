@import 'styles/variables';

.uploadTasksContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .actionBtn {
    width: fit-content;
    height: 44px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    background-color: $white;
    color: $dark-color;
    margin-left: 13px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 28px;
    border: 2px solid $light-gray;

    &:hover {
      cursor: pointer;
      background-color: $background-ligh-gray-3;
    }

    & svg {
      display: flex;
      vertical-align: middle;

      path {
        stroke: $dark-color;
        stroke-width: 2px;
      }
    }
    .uploadIcon {
      margin-right: 13px;
    }
  }

  input[type='file'] {
    display: none !important;
  }
}
