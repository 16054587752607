@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.pageContent {
  overflow-y: hidden !important;
}

.tabLabel {
  display: flex;
  align-items: center;
  gap: 6px;

  &.active {
    color: $text-color !important;
    &:hover {
      color: $text-color !important;
    }
    svg {
      path {
        stroke: #ff576d;
      }
    }
  }
}

.mainPageContent {
  padding: 0px !important;
  display: flex;
  max-width: 100vw;
  overflow-x: hidden;

  .content {
    width: calc(100vw - 367px);
    .tabs {
      background: $background-ligh-gray-3;
      justify-content: space-between;

      div[class~='ant-tabs-nav'] {
        padding: 24px 40px;
        padding-bottom: 0px !important;
        background-color: $background-ligh-gray-3;
        justify-content: center;

        &:before {
          content: none;
        }
        div[class~='ant-tabs-nav-wrap'] {
          border-radius: 12px;
          z-index: 1;
          width: 100%;
          max-width: 1340px;
          height: 60px;
          background-color: $white;
          box-shadow: -4px 0px 12px 0px rgba(48, 48, 48, 0.1);
          display: flex;
          justify-content: center;

          div[class~='ant-tabs-nav-list'] {
            height: 100%;
            width: 100%;
            // justify-content: space-between;
          }

          div[class~='ant-tabs-ink-bar'] {
            height: 2.5px !important;
          }

          div[class~='ant-tabs-tab'] {
            min-width: 125px;
            justify-content: center;
            flex-grow: 1;
            margin: 0px;

            [class*='ant-tabs-tab-btn'] {
              color: $text-gray-color !important;

              &:hover {
                color: $text-gray-color !important;
              }
            }
          }
        }
      }
    }
  }
}
