@import 'styles/variables';
@import 'styles/functions';

.notifications {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: setUnit(12);

  .icon {
    position: relative;
    width: setUnit(32);
    height: setUnit(32);
    display: flex;
    align-items: center;
  }

  .iconPopOver {
    position: relative;
    height: setUnit(32);
    display: flex;
    align-items: center;
  }

  .alert {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: setUnit(4);
    grid-template-rows: setUnit(18);
    align-items: center;

    .alertLabelContainer {
      position: absolute;
      top: setUnit(-16);
      right: setUnit(-2);
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $error-color;
      width: setUnit(22);
      height: setUnit(22);
      display: flex;
      justify-content: center;
      align-items: center;

      .alertLabel {
        color: $white;
        font-weight: 700;
        font-size: setUnit(11);
      }
    }
  }

  .emptyContainer {
    width: 2.5rem;
  }
}

.notifications_popover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: setUnit(8);

  & > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: setUnit(6);
  }

  .icon {
    display: flex;
    align-items: center;
  }

  .newOffersInfo {
    .icon {
      path {
        stroke: $white;
      }
    }
  }
}
