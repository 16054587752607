@import 'styles/variables';
@import 'styles/functions';

.container {
  height: 70px;
  width: calc(50% - 6px);
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  padding: 2px 20px;
  border-radius: 12px;
  border: 1.5px solid $light-gray;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  &.darkBlue {
    background-color: $background-ligh-gray-2;
    border-color: $confetti-blue;
  }
  &.red {
    background-color: $background-ligh-gray-2;
    border-color: $primary-color;
  }
  &.yellow {
    background-color: $background-ligh-gray-2;
    border-color: $orange;
  }
  &.purple {
    background-color: $background-ligh-gray-2;
    border-color: $purple;
  }
  &.blue {
    background-color: $background-ligh-gray-2;
    border-color: $light-blue;
  }

  &:hover {
    background: $background-ligh-gray-2;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    .svg {
      padding-top: 6px;
    }
    &.darkBlue {
      background-color: #4673d114;
    }
    &.red {
      background-color: $primary-color-lighter;
    }
    &.yellow {
      background-color: $orange-lighter;
    }
    &.purple {
      background-color: #928cda14;
    }
    &.blue {
      background-color: #51bfe114;
    }
  }
  .label {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.09px;
    margin: 0;
  }
  .subtitle {
    color: $dark-color;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 400px;
    margin: 0;
  }
}
