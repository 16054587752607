@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  background-color: $background-ligh-gray;
  min-height: calc(100vh - $header-height);
  color: $text-color;
}

.container {
  height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.contentContainer {
  width: 100%;
  max-width: setUnit(1200);
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  @media (min-width: $mobile) {
    flex: auto;
  }
  @media screen and (max-width: $desktop) {
    min-width: 85vw;
  }
  @media screen and (max-width: $tablet-horizontal) {
    min-width: 90vw;
  }

  @media screen and (max-width: $tablet) {
    min-width: 95vw;
  }
}

.leftMenu {
  flex: 1 0 100%;
  position: sticky;
  top: 80px;
  background-color: #fff;
  padding: setUnit(16) setUnit(30);
  margin: setUnit(15) 0;
  border: 2px solid $border-light-gray;
  box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 0.2);
  z-index: 1;
  @media (min-width: $mobile) {
    flex: 1;
    position: relative;
    top: 0;
    background-color: transparent;
    margin: 0;
    box-shadow: none;
    border: 0;
  }
}

.rightContent {
  flex: 1 0 100%;
  @media (min-width: $mobile) {
    flex: 2;
  }
  padding: setUnit(40);
  border: 1px solid $background-grey-2;
  box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.1);
  background-color: $white;
  width: 100%;
}

.title {
  font-size: setUnit(24);
  font-weight: bold;
  color: $text-color;
}
