@import 'styles/functions';
@import 'styles/variables';

.preFormContainer {
  padding: 0 40px;
  .head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 34px 0 54px;
    .closeIcon {
      border-radius: 50%;
      border: 2px solid $light-gray;
      background: none;
      height: 44px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        border: 2px solid $text--light-gray-color;

        svg {
          opacity: 1;

          path {
            fill: $text-color;
          }
        }
      }
    }
  }
}
