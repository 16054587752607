@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  .question {
    margin-bottom: 20px;
  }
  .redirectText {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $dark-grey-color;
    margin-bottom: 0;
    text-align: center;
  }
  .submit {
    width: 100%;
    height: 52px !important;
    border-radius: 8px !important;
  }
}

.successModalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: setUnit(40);

  .stepHeader {
    font-family: $font-family;
    font-weight: 700;
  }

  .text {
    font-family: $font-family;
    color: $dark-color;
  }

  .continueButton {
    padding: setUnit(14) setUnit(16);
    margin-top: setUnit(40);
    height: unset;
  }
}
