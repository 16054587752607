@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.transactionsWrap {
  padding: setUnit(14) setUnit(32);
  .sectionTitle {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(20);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
  }
  .infoCardWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
