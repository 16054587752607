@import "styles/functions";
@import "styles/variables";

.upload {
    border: 1px solid $border-middle-gray;
    height: setUnit(44);
    min-width: setUnit(122);
    display: flex;
    align-items: center;
    border-radius: setUnit(10);
    padding: 0 setUnit(18);
    cursor: pointer;

    .pendingWrapper {
        display: flex;
        align-items: center;
    }

    .loader {
        font-size: setUnit(3);
        margin: 0;
    }

    .icon {
        margin-right: setUnit(10);
        svg {
            vertical-align: middle;
        }
    }

    .nativeFileInput {
        display: none;
    }

    .fileInput {
        cursor: pointer;
        display: flex;
    }
}
