@import 'styles/variables';
@import 'styles/functions';

.emptyLenders {
  text-align: center;
  font-size: setUnit(20);
  margin-top: setUnit(40);
  color: $dark-grey-color;
  font-weight: 500;
}
