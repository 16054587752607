@import 'styles/variables';

.pdfSignFieldEmpty {
  opacity: 0.7;
}

.clickToSignContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.hideBorder {
  border: none !important;
}

.pdfSignField {
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid $black;
  border-radius: 8px;

  &:hover {
    opacity: 1;
  }

  .editIcon {
    position: absolute;
    right: 10px;
  }

  .signTextContainer {
    overflow: hidden;
    font-size: xx-large;
    width: 100%;
    text-align: left;
    padding: 0px 5px;
    color: $text-color;
  }

  .signatureContainerText {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .signatureContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;

    .signText {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      text-align: left;
      margin-left: 4px;
      color: $text-color;
    }
  }
}

.signModalContainer {
  .signIcon {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .hideFitContainer {
    position: absolute;
    bottom: 100vh;
  }

  .errorList {
    padding-left: 20px;
    margin-top: 20px;
    color: $error-color;
  }

  [class*='ant-modal-content'] {
    padding-bottom: 0px !important;
  }

  [class*='ant-modal-title'] {
    text-align: center;
  }

  width: 675px !important;

  .tabs {
    width: 100% !important;
  }

  .headerTitle {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  .modalSubmit {
    padding: 14px 50px !important;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin: 30px auto !important;
    width: 242px !important;
    height: 52px !important;
    border-radius: 8px;
  }

  .label {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: $text-color;
    display: block;
    margin: 12px 0px;
  }

  .input {
    padding: 58px 5px;
    text-align: center;
    height: 218px;
    font-size: 64px;
  }

  .fontSelect {
    width: 100%;
  }

  .dropdownRender {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: $text-gray-color;
    padding: 16px 0px 8px 22px;
  }

  .selectOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  [class*='ant-select-selection-item'] > .selectOption > span {
    width: 100%;
    font-size: xx-large;
    text-align: center;
  }

  .signatureCanvas {
    background-color: $background-grey-2;
    height: 320px;
    width: 100%;
  }

  .drawPane {
    position: relative;

    .deleteIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  .tabRow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
      filter: grayscale(100%);
      opacity: 0.7;
    }
  }

  [class*='ant-tabs-nav-list'] {
    width: 100%;

    [class*='ant-tabs-tab'] {
      width: 50%;
      margin: 0px !important;
    }

    [class*='ant-tabs-tab-btn'] {
      width: 100% !important;
    }
  }

  [class*='ant-tabs-tab-active'] {
    svg {
      filter: unset !important;
      opacity: 1 !important;
    }
  }

  [class*='ant-tabs-nav-operations'] {
    display: none !important;
  }
}

.legalText {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-gray-color;
  margin: 23px 0 5px 0;
}
