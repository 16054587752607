@import 'styles/functions';
@import 'styles/variables';

.notificationPopUp {
  background: #181d2c !important;
  border: 1px solid #e8e9ea !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14) !important;
  border-radius: 12px !important;
  color: white;

  & [class*='ant-notification-notice-icon'] {
    position: absolute;
    margin-left: -10px;
    font-size: 24px;
    line-height: 24px;
  }
}

.notificationTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.18px;
  color: #ffffff;
}

.notificationDescription {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.09px;
  color: #ffffff;
}

.avatarWrapper {
  margin-top: setUnit(24);
  margin-right: setUnit(8);
  position: relative;
  bottom: setUnit(24);
  font-size: setUnit(14);
}

.avatar {
  width: setUnit(50) !important;
  height: setUnit(50) !important;
}
