@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .iconWrapper {
    position: relative;
  }
  .iconBackground {
    background-color: $hover-red;
    width: setUnit(56);
    height: setUnit(56);
    border-radius: 50%;
    opacity: 10%;
  }
  .icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      path {
        stroke: $hover-red;
      }
    }
  }
  .title {
    font-size: setUnit(20);
    font-weight: 600;
    color: $text-color;
    text-align: center;
    margin-top: setUnit(24);
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    gap: setUnit(24);
    width: 100%;
    margin-top: setUnit(32);

    .button {
      max-width: setUnit(158);
      width: 100%;
      padding: setUnit(16), setUnit(54);
      border-radius: setUnit(8);
      font-size: setUnit(16);
      font-weight: 600;
    }
    .cancelButton {
      background-color: $background-grey-2;
      color: $text-color-light;
    }

    .deleteButton {
      background-color: $hover-red;
      color: $white;
    }
  }
}
