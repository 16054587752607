@import "styles/functions";
@import "styles/variables";

.info {
    margin-left: setUnit(10);

    .names {
        font-size: setUnit(20);
        font-weight: 600;
    }

    .address {
        font-size: setUnit(14);
        font-weight: 400;
        margin-top: setUnit(4);
    }
}
