@import 'styles/functions';
@import 'styles/variables';

.byDateWrapper {
  .notificationsByDate {
    height: calc(100vh - #{setUnit(94)});
    overflow: auto;
    .title {
      padding: 0 setUnit(32);
    }
    .part {
      margin-top: 0;
      margin-bottom: setUnit(32);
    }
  }
}
