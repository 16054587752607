@import 'styles/functions';
@import 'styles/variables';

.contentWrapper {
  display: flex;
  height: calc(100vh - #{setUnit(294)});
  @media (min-width: $tablet) {
    height: calc(100vh - #{setUnit(192)});
  }
}
