@import 'styles/functions';
@import 'styles/variables';

.propertyIcon {
  svg {
    vertical-align: middle;
    width: setUnit(24);
    height: setUnit(24);
    path {
      stroke: $white;
    }
  }
}
