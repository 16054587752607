@import 'styles/functions';
@import 'styles/variables';

.multiSelectWrapper {
  margin-top: setUnit(24);
  margin-bottom: setUnit(24);
  margin-left: setUnit(32);
  margin-right: setUnit(32);

  & [class~='ant-typography'] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  & [class~='ant-select-arrow'] {
    color: $text-gray-color !important;
    font-size: medium;
    font-weight: bold;
  }

  .multiSelect {
    & [class~='ant-select-selector'] {
      border-radius: 5px !important;
      font-weight: 600;
      font-size: 14px;
      height: setUnit(48);
    }

    & [class~='ant-select-clear'] {
      width: setUnit(18);
    }

    .multiSelectPlaceholder {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $text-color;

      span {
        margin-left: 0 !important;
      }
    }
  }
}

.multiSelectDropdown {
  & [class~='ant-select-arrow'] {
    color: $text-gray-color !important;
  }

  & [class~='ant-select-item-option-content'] {
    padding: 0.25rem;
  }

  & [class~='ant-select-item-option-selected']:not(.ant-select-item-option-disabled) {
    background-color: unset;

    & [class~='ant-select-item-option-state'] {
      color: $green;
    }
  }
}
