@import 'styles/functions';
@import 'styles/variables';

.commute {
  display: flex;
  align-items: center;
  height: setUnit(64);
  &:last-child {
    border-bottom: 1px solid $light-gray;
  }
  .icon {
    display: flex;
    margin-right: setUnit(16);
    svg {
      width: setUnit(32);
      height: setUnit(32);
    }
  }

  .info {
    flex: 1;
    .name {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .prefType {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      margin-bottom: 0;
      color: $dark-color;
    }
  }
}
