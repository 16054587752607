@import 'styles/functions';
@import 'styles/variables';

@import 'styles/functions';
@import 'styles/variables';

.container {
  .answerBtn {
    width: 100%;
    height: 56px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    color: $dark-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.09px;
    &:not(:last-child) {
      margin-bottom: setUnit(16);
    }
    &:hover {
      background-color: transparent;
      border-color: $text--light-gray-color;
    }
    &.active,
    &:focus {
      background-color: $primary-color-light;
      border-color: $primary-color;
      color: $text-color;
    }
  }
}

.modalContent {
  padding: 0 !important;
}

.previewModal {
  .modalHeader {
    align-items: flex-start;
    padding: 36px 0 24px !important;
    margin: 0 40px 24px;
    border-bottom: 1px solid $light-gray;
  }
  .modalContent {
    max-height: unset !important;
    overflow: unset !important;
    .modalWrapper {
      padding: 0 40px;
      margin-bottom: 40px;
    }
  }
}
.modalTitleLeft {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .backBtn {
    height: setUnit(32);
    width: setUnit(32);
    cursor: pointer;
  }
  .content {
    width: calc(100% - 32px);
    text-align: center;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.46px;
      color: $text-color;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.18px;
      color: $dark-color;
      margin-bottom: 0;
    }
  }
}
