@import 'styles/variables';
@import 'styles/mixins';

.downloadWidgetContainer {
  width: 40px;
  position: sticky;
  bottom: 112px;
  left: calc(100% - 80px);
  text-align: center;
  z-index: 999;
  background: transparent;

  .downloadWidget {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: 8px;
  }

  .downloadWidget:hover {
    background-color: $light-gray;
    cursor: pointer;
  }

  @media screen and (max-width: $mobile) {
    bottom: 20px;
  }
}
