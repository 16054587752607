@import 'styles/functions';
@import 'styles/variables';

.checkboxWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .customCheckbox {
    display: flex;
    align-items: center;
    flex: 0 0 setUnit(24);
    margin-right: setUnit(8);
    & [class*='ant-checkbox'] {
      top: 0;
      & [class*='ant-checkbox-inner'] {
        border: 1.5px solid $text--light-gray-color;
        border-radius: 4px;
        width: setUnit(24);
        height: setUnit(24);
        background-color: #fff;
        &:after {
          transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
          left: 30%;
        }
      }
      & + span {
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-weight: 400;
        letter-spacing: -0.09px;
        color: $dark-grey-color;
        padding-right: 0;
      }
    }
    & [class*='ant-checkbox-checked'] {
      &:after {
        border-color: $primary-color;
      }
      & [class*='ant-checkbox-inner'] {
        background-color: $primary-color;
        border-color: $primary-color;
      }
      & + span {
        color: $text-color;
      }
    }
  }
  .inputWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      input {
        border-bottom-color: $border-light-gray;
      }
      p {
        color: $text--light-gray-color;
      }
    }
    input {
      border: 0;
      border-bottom: 2px solid $border-light-gray-2;
      width: setUnit(43);
      margin-right: setUnit(4);
      &:focus {
        box-shadow: none;
        border-bottom-color: $primary-color;
      }
    }
    p {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(16);
      letter-spacing: -0.09px;
      color: $dark-grey-color;
      margin-bottom: 0;
    }
    .customSelect {
      & [class*='ant-select'] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & [class*='ant-select-selector'] {
          border: 0;
          flex: 0;
          & [class*='ant-select-selection-item'] {
            flex: 0 auto;
            padding-right: 0 !important;
          }
        }
        & [class*='ant-select-arrow'] {
          position: relative;
          top: auto;
          vertical-align: middle;
          margin-top: 0;
          margin-left: 8px;
        }
      }
      & [class*='ant-select-focused'] {
        & [class*='ant-select-selector'] {
          box-shadow: none !important;
          border: 0 !important;
        }
      }
    }
  }
}
