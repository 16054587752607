@import 'styles/functions';
@import 'styles/variables';

.sliderWrapper {
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
  .propertyImage {
    width: 100%;
    height: 100%;
    background: $background-grey-2;
  }
}
