@import 'styles/variables';

.yesNoQuestion {
  margin: 16px 0px;

  .answerBtn {
    width: 100%;
    height: 4rem;
    border: 2px solid $light-gray;
    border-radius: 8px;
    color: $dark-color;
    font-size: 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.09px;
  }

  .active,
  &:focus {
    background-color: $primary-color-light !important;
    border-color: $primary-color !important;
  }

  .spacing {
    width: 100%;
  }
}
