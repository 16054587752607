@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.searchWrapper {
  border: none;
  border-radius: setUnit(8);
  min-height: 48px;
}

.block {
  position: relative;
  height: 100%;
}

.valuesWrapper {
  min-height: setUnit(46);
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background: #ffffff;
  transition: all 0.1s;

  .geocoder {
    position: absolute;
    top: 0;
  }

  &.light {
    padding: setUnit(6) setUnit(6) setUnit(6) setUnit(15);
    border-bottom: 1px solid $border-input-middle-gray;
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;

    .placeholder {
      font-size: setUnit(14);
      font-weight: 400;
      color: $border-middle-gray;
      width: 100%;
    }

    .searchIcon {
      position: relative;
      top: setUnit(-1);
      z-index: 1;
      margin-right: setUnit(10);

      svg {
        vertical-align: middle;
      }
    }
  }

  &.lightFull {
    padding: setUnit(6) setUnit(6) setUnit(6) setUnit(15);
    border: 1px solid $border-input-middle-gray;
    border-radius: setUnit(8);
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    position: relative;

    .placeholder {
      font-size: setUnit(14);
      font-weight: 400;
      color: $border-middle-gray;
      width: 100%;
    }

    .searchIcon {
      position: relative;
      top: setUnit(-1);
      z-index: 1;
      margin-right: setUnit(10);

      svg {
        vertical-align: middle;
      }
    }
  }

  &.lightRound {
    @include lightRound;
  }

  &.full {
    height: setUnit(56);
    padding: 0 setUnit(15);
    border-radius: setUnit(8);
    background: $background-ligh-gray;
    outline: none;
    margin: 0;
    width: 100%;
    vertical-align: middle;
    transition: 0.3s background;
    font-weight: 400;

    &::placeholder {
      font-weight: 400;
      color: $border-middle-gray;
    }

    .placeholder {
      font-size: setUnit(14);
      font-weight: 400;
      color: $border-middle-gray;
      width: 100%;
    }

    .searchIcon {
      position: relative;
      top: setUnit(-1);
      z-index: 1;
      margin-right: setUnit(10);

      svg {
        vertical-align: middle;
      }
    }
  }
}

.error {
  @include inputError;
}
