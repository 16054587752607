@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 468px;
  background: $white;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
}

.inactiveText {
  font-size: setUnit(16);
  font-weight: 600;
  line-height: 20.8px; /* 130% */
  letter-spacing: -0.18px;
  color: $dark-color;
  margin-top: setUnit(32);
}
