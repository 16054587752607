@import 'styles/functions';
@import 'styles/variables';

.footer {
  padding: setUnit(16) setUnit(32);
  background: $background-grey-2;
  border: 1px solid $grey-color;
  position: fixed;
  bottom: 0px;
  max-width: setUnit(480);
  width: 100%;
  .submitButton {
    width: setUnit(102);
    margin-left: auto;
    display: block;
  }
}
