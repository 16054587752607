@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.fileNameWrapContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 20%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 20%;
  min-width: 20%;
  border-right: 1px solid $light-gray;
  position: relative;

  .fileNameWrap {
    display: flex;
    align-items: center;
    overflow: hidden;

    .fileIconBundle {
      line-height: 0;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    svg {
      flex-shrink: 0;
    }

    .documentNameTooltip {
      height: auto;
      .documentName {
        white-space: nowrap;
        overflow: hidden;
        max-width: 90%;
        text-overflow: ellipsis;
      }
    }

    .documentName {
      margin-left: 0.75rem;
      margin-bottom: 0;
      color: $text-color;
      font-family: Inter;
      font-size: setUnit(14);
      font-style: normal;
      font-weight: 600;
      line-height: 1.3rem; /* 148.571% */
      letter-spacing: -0.01125rem;

      width: fit-content;
    }
  }

  .arrowIconWrap {
    line-height: 0px;
  }
}

.addNewFileWrap {
  cursor: pointer;
}

.openedWrapContainer {
  background-color: $white;
}

.fileDropdown {
  z-index: 999 !important;
  .addForm {
    padding: 5px;
  }
}

@media screen and (max-width: 475px) {
  .fileNameWrapContainer {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    gap: 5px;
  }

  .documentName {
    display: none !important;
  }
}
