@import 'styles/functions';
@import 'styles/variables';

.auditLogCardWrapper {
  min-width: 100% !important;
  max-width: setUnit(600);
  border: 0 !important;
  .alignitemsStart {
    align-items: start;
  }

  .alignitemsCenter {
    align-items: center;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  & [class*='ant-card-head'] {
    border-bottom: setUnit(0);
    display: flex;
    align-items: center;
  }

  & [class*='ant-card-body'] {
    padding: 0;
  }

  & [class*='ant-card-head-title'] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(24);
    letter-spacing: -0.33px;
    color: $text-color;
    padding: 0;
  }
  & [class*='ant-timeline-item-label'] {
    // width: calc(50% - 35px) !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: setUnit(14px);
    line-height: setUnit(16px);
    /* identical to box height, or 114% */

    letter-spacing: -0.09px;

    /* REBRAND/Grayscale/80 */
    color: $dark-color;
  }

  & [class*='ant-avatar-circle'] {
    display: flex;
    align-items: center;
    justify-content: center;
    & [class*='ant-avatar-string'] {
      font-weight: 700;
      font-size: setUnit(10);
      line-height: setUnit(14) !important;
      color: $white;
      letter-spacing: setUnit(-0.8);
    }
  }
  & [class*='ant-timeline-item-tail'] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(20);
    /* or 125% */

    letter-spacing: -0.18px;

    /* REBRAND/Grayscale/100 */

    color: $text-color;
  }

  & [class*='ant-timeline-item-content'] {
    font-family: 'Inter';
    font-style: normal;
    font-size: setUnit(16);
    line-height: setUnit(20);
    /* or 125% */

    letter-spacing: -0.18px;

    /* REBRAND/Grayscale/100 */

    color: $text-color;
  }
  & [class*='ant-timeline-item'] {
    min-height: setUnit(72) !important;
  }
  & [class*='ant-col-3'] {
    max-width: 8% !important;
  }

  .timelineItemWrapper {
    padding: setUnit(14) setUnit(24);
    background-color: $white;
    transition: $transition;
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px;
    &:hover {
      background-color: $background-grey-2;
    }
    .timelineItemTime {
      width: 80px;
    }

    .timelineTimeLabel {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $dark-color;
      margin-bottom: 3px;
      max-width: 70px;
      width: 100%;
    }
    &:not(:last-child) {
      .avatarWrapper {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: setUnit(24);
          transform: translateX(-50%);
          border-left: setUnit(2) solid $light-gray;
          width: 1px;
          height: calc(100% + 24px);
        }
      }
    }
    .timelineContent {
      width: calc(100% - 150px);
    }
  }

  .avatarWrapperNoBorder {
    border-left: none;
  }

  .overDueText {
    color: $hover-red;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
  }
  .dot {
    width: 8px; /* Adjust the width of the dot */
    height: 8px; /* Adjust the height of the dot */
    background-color: black; /* Color of the dot */
    border-radius: 50%; /* Create a circular dot */
    display: inline-block; /* Make the dot inline to align horizontally */
    margin-right: 8px; /* Adjust the spacing between dots */
  }
}

.linkStyle {
  color: #2b59da;

  &:hover {
    text-decoration: underline;
    color: #2b59da;
  }
}
.auditCardOverflow {
  overflow: hidden;
  overflow-y: scroll;
}

.beforeAfterSpacing {
  display: flex;
  width: 100%;
}

.genericAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.strongListtitle {
  margin-right: setUnit(5);
}
