@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.next {
  margin: 0 auto;
}

.counter {
  margin: setUnit(68) 0;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: setUnit(675) !important;
  width: 100%;
  .innerHolder {
    padding: 40px !important;
    padding-top: 10px !important;
  }
}

.opacityLayer {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 0.8) 0%,
    rgba(244, 245, 246, 0.4) 50%,
    rgba(244, 245, 246, 0.6) 75%,
    rgba(244, 245, 246, 0.9) 100%
  );
  backdrop-filter: blur(20px);
  background-color: transparent !important;
}
