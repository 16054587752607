@import 'styles/functions';
@import 'styles/variables';

.dropView {
  padding: setUnit(0) setUnit(40) setUnit(38);
}

.options {
  padding: 0 setUnit(32) setUnit(32);
  margin-top: setUnit(40);
}
