@import 'styles/functions';
@import 'styles/variables';

.listingItemTabs {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  .listingItemTab {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    padding: setUnit(16) 0;
    margin-right: unset !important;
    border-bottom: 4px solid transparent !important;
    font-weight: 600;
    color: $border-middle-gray;
    width: 33%;
    transition: $transition;
  }

  .activeListingItemTab {
    border-bottom: 4px solid $primary-color !important;
    color: $text-color !important;
    div {
      svg {
        path,
        circle {
          stroke: $text-color;
        }
      }
    }
  }

  .tabIcon {
    display: flex;
    margin-bottom: setUnit(8);
    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }
}
