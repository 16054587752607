@import 'styles/functions';
@import 'styles/variables';

.metaInfo {
  .phone {
    input {
      font-size: setUnit(16);
    }
  }

  .button {
    width: 100%;
    height: setUnit(44);

    &.messages {
      .messageButtonTitle {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          vertical-align: middle;
          position: relative;
          left: setUnit(-12);
        }
      }
    }

    &.viewProfile {
      background: none;
      border: 1px solid $border-middle-gray;
      color: $text-color;

      &:hover {
        background-color: $background-ligh-gray;
      }
    }
  }

  .accordion {
    border-bottom: none;
  }
}
