@mixin modalButton($backgroundColor, $backgroundColorHover, $textColor, $fontWeight) {
  background: $backgroundColor !important;
  font-weight: $fontWeight !important;
  color: $textColor !important;

  &:hover,
  &:focus,
  &:active {
    background: $backgroundColorHover !important;
    color: $textColor !important;
  }
}

@mixin modalIcon($backgroundColor) {
  display: flex;
  justify-content: center;
  align-items: center;

  background: $backgroundColor;
  border-radius: 100%;
  margin-top: 8px;
  width: 56px;
  height: 56px;

  .svg {
    width: 32px;
    height: 32px;
  }
}
