@import 'styles/functions';
@import 'styles/variables';

.actions {
  width: 330px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: setUnit(10);

  @media only screen and (max-width: $tablet) {
    width: 200px;
  }

  @media only screen and (max-width: $mobile-small) {
    width: auto;
  }
}
