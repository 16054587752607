@import 'styles/functions';
@import 'styles/variables';

.commute {
  height: 156px;
  min-width: 156px;
  border: 2px solid $light-gray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    border: 2px solid $text--light-gray-color;

    .info {
      .name {
        text-decoration: underline;
      }
    }

    .icon {
      svg {
        path,
        circle {
          stroke: $text-color;
        }
      }
    }

    .removeIcon {
      display: flex;
    }
  }

  .icon {
    svg {
      height: 56px;
      width: 56px;

      path,
      circle {
        stroke: $dark-color;
      }
    }
  }

  .info {
    max-width: 156px;
    text-align: center;
    margin-bottom: 16px;
    white-space: nowrap;

    .name {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: $text-color;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.5rem;
    }

    .commuteTime {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $dark-grey-color;
      margin-bottom: 0;
    }
  }

  .removeIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    width: 32px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $primary-color;
    margin-right: -12px;
    margin-top: -12px;

    svg {
      path {
        fill: $white;
      }
    }
  }
}
