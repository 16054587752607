@import 'styles/functions';
@import 'styles/variables';

.taskChecklists {
  max-height: 270px;
  overflow-y: auto;
  padding-right: 1rem;

  .checklist {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0.75rem;
    }

    h3,
    p {
      font-weight: 400;
    }

    h3 {
      margin: 0 1rem;
      font-size: 16px;
      color: $text-color;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: $text-gray-color;
    }

    .marked {
      color: $text--light-gray-color;
    }

    .checkContainer {
      display: flex;
      align-items: flex-start;

      [class*='ant-checkbox-wrapper'] {
        margin-top: setUnit(2);
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        align-items: center;
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-disabled'] {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color !important;
        }
      }

      [class*='ant-checkbox'] {
        top: 0;
        [class*='ant-checkbox-inner'] {
          width: setUnit(24);
          height: setUnit(24);
          border-radius: setUnit(100);
          &:after {
            width: setUnit(6);
            height: setUnit(12);
            border-radius: 0;
            top: 45%;
            left: 28%;
          }
        }
        &::after {
          border-radius: setUnit(100);
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }
    }
  }
}
