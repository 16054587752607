@import 'styles/functions';
@import 'styles/variables';

.dynamicSplit {
  .buttonsContainer {
    margin: setUnit(20) 0;
    display: flex;
    gap: 12px;
    button {
      border-radius: 8px;
      width: 100%;
    }
  }

  .labelSplit {
    color: $text-color;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .customAgentWrapper {
    position: relative;
    display: flex;
    align-items: center;

    .removeAgentIcon {
      background-color: $white;
      position: absolute;
      right: 10px;
    }
  }

  .addAttributeBtn {
    color: $dark-color !important;
    font-weight: 600;
  }

  .applyLabel {
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: $text-color;
    font-size: 0.875rem;
  }

  .inputHeight {
    height: 48px !important;
  }

  .checkboxCont {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
  .errorContainer {
    margin-bottom: setUnit(10);
  }
}
