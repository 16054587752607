@import 'styles/functions';
@import 'styles/variables';

.favHeader {
  position: relative;
  padding: setUnit(20) setUnit(40);
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    margin: 0;
    font-weight: 700;
    font-size: setUnit(32);
  }
  .closeButton {
    position: absolute;
    right: setUnit(32);
    cursor: pointer;
    &:hover {
      svg {
        fill: $background-ligh-gray-2;
      }
    }
  }
}
