@import 'styles/variables';

.tooltip {
  & [class*='ant-tooltip-content'] {
    background-color: $white;
    border-radius: 8px;
  }

  & [class*='ant-tooltip-inner'] {
    background-color: $white;
    color: $black;
    font-weight: 500;
    border-radius: 8px;
  }

  & [class*='ant-tooltip-arrow-content'] {
    background-color: $white;
  }
}
