@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.listWrapper {
  @include box-shadow;
  width: setUnit(200);
  position: absolute;
  top: setUnit(44);
  right: 0;
  background: #ffffff;
  border-radius: setUnit(8);
  z-index: 1;
  ul {
    list-style: none;
    margin: setUnit(8) 0;
  }
  .item {
    padding: setUnit(10) setUnit(15);
    display: flex;
    justify-content: space-between;

    &:hover {
      background: $background-ligh-gray;
    }

    img {
      height: 18px;
      width: 18px;
    }
  }
}
