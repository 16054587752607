@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.quotationsCategory {
  height: setUnit(40);
  padding: 0 setUnit(8);
  border-radius: setUnit(100);
  background: rgba(255, 87, 109, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $primary-color;
  width: 176px;
  white-space: nowrap;

  .categoryText {
    font-size: setUnit(14);
    font-weight: 600;
  }

  &.confettiBlue {
    background-color: rgba(70, 115, 209, 0.08);
    color: $confetti-blue;
  }

  &.purple {
    background-color: rgba(146, 140, 218, 0.08);
    color: $purple;
  }

  .lightBlue {
    background-color: rgba(81, 191, 225, 0.08);
    color: $light-blue;
  }

  .green {
    background-color: rgba(4, 164, 81, 0.08);
    color: $green-color;
  }

  .mosaikRed {
    background-color: rgba(255, 87, 109, 0.08);
    color: $primary-color /* define your color */;
  }

  .orange {
    background-color: rgba(211, 122, 49, 0.08);
    color: #d37a31 /* define your color */;
  }
}
