@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: #f4f5f6;
  width: 100%;

  .pageContent {
    padding: 24px;
    width: 100%;
    max-width: 1360px;
    .placeholder {
      display: flex;
      width: 100%;
      height: 275px;
      padding-bottom: 8px;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      border: 1px solid $background-grey-2;
      background: $white;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.06);
      color: $text--light-gray-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      letter-spacing: -0.33px;
    }
  }
}
