@import 'styles/functions';
@import 'styles/variables';

.progress {
  position: absolute;
  top: 0;
  z-index: 999;
  height: 4px;
  background: $primary-color;
  transition: width 0.3s;
  border-top-right-radius: setUnit(4);
  border-bottom-right-radius: setUnit(4);
}
