@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.main {
  .actionButtons {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    gap: 8px;
    .cancel {
      width: 104px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: white !important;
      border: 1px solid #d6d6d7;
      color: #515151 !important;
    }
    .update {
      width: 104px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: #161b29;
      color: white !important;
    }
  }
}
