@import 'styles/functions';
@import 'styles/variables';

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: setUnit(40);

  .stepHeader {
    font-family: $font-family;
    font-weight: 700;
  }

  .text {
    font-family: $font-family;
    color: $dark-color;
  }

  .highlightedEmail {
    font-weight: 500;
  }

  .continueButton {
    padding: setUnit(14) setUnit(16);
    font-size: 16px;
    opacity: 0.3;
    margin-top: setUnit(40);
    height: unset;
  }
}
