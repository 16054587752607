@import 'styles/functions';
@import 'styles/variables';

.documents {
  position: relative;
  height: 100%;

  .title {
    margin: 0 0 setUnit(16) 0;
    color: $text-color;
    font-weight: bold;
    font-size: setUnit(16);
    font-weight: 600;
    line-height: 20.8px; /* 130% */
    letter-spacing: -0.18px;
  }

  .disclaimer {
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.09px;
    margin-bottom: 0;
    color: $text--light-gray-color;
    text-align: center;

    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
