@import 'styles/functions';
@import 'styles/variables';

.clientsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  height: 44px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $text-color;
    margin: 0;
  }

  .icon {
    width: 44px;
    height: 44px;
    background: $background-grey-2;
    border: 2px solid $background-grey-2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: setUnit(10);
  }
}
