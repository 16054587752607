@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.declineFormReason {
  .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;
  }

  .textArea {
    margin-top: 12px;
    color: $text-color;
    font-size: setUnit(16);
    line-height: 20.8px;
    letter-spacing: -0.09px;
    border-radius: 8px;

    border-color: $border-input-middle-gray;
    padding: 14px 16px;

    height: 152px !important;
    resize: none;

    &:focus,
    &:hover {
      border-color: $border-focused;
      box-shadow: none;
      outline: 0;
    }

    &::placeholder {
      color: $text--light-gray-color;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 40px 0px 0px;
    gap: 40px;
  }

  .button {
    width: 172px;
    height: 52px;
    border-radius: 10px;
    display: block;
  }

  .greyButton {
    background-color: $background-grey-2;
    color: $dark-color;
  }
}
