@import 'styles/functions';
@import 'styles/variables';

.container {
  .answerBtn {
    width: 100%;
    height: 56px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    color: $dark-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.09px;
    &:not(:last-child) {
      margin-bottom: setUnit(16);
    }
    &:hover {
      background-color: transparent;
      border-color: $text--light-gray-color;
    }
    &.active,
    &:focus {
      background-color: $primary-color-light;
      border-color: $primary-color;
      color: $text-color;
    }
  }
}
