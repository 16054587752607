@import 'styles/functions';
@import 'styles/variables';

.contentWrapper {
  padding: 36px 40px;
}

.header {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.47px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 32px;
    height: 32px;
    color: $primary-color;
  }
}

.contentBody {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  .emailDisclaimer {
    background-color: $background-gray;
    border-radius: 12px;
    padding: 1rem;
    gap: 12px;
    display: flex;
    flex-direction: column;
    .disclaimerTitle {
      font-size: 15px;
      line-height: 20px;
    }
    .disclaimerContent {
      font-size: 14px;
      line-height: 20px;
      color: $dark-color;
      font-weight: 400;
      .mosaikHighlight {
        font-weight: 600;
      }
      .emailHighlight {
        font-weight: 500;
        color: $confetti-blue;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    & > div {
      display: flex;
      & > button {
        flex: 1;
      }
      .emailButton {
        border: 1.5px solid $light-grey-color;
        background-color: $white;
        color: $text-color;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
      }
    }
  }
}

.inputContainer {
  position: relative !important;
  input {
    border: 0px;
    border-bottom: 2px solid $border-light-gray-3;
    border-radius: 0;
    padding-left: 0;
    padding: 12px 0 !important;
    font-size: 16px !important;
    height: initial !important;
    line-height: 20px !important;
  }

  label {
    margin-bottom: 0 !important;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.submitButton {
  padding: 14px 47px;
  min-width: 210px;
}

.label {
  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.8px; /* 130% */
  letter-spacing: -0.18px;
}

.error {
  position: absolute;
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}

.successIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  max-width: 501px;
  color: $text-color;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
.feedbackbtnWrapper {
  margin-top: 16px;
}

.templateInputContainer {
  margin: 0 !important;
  input,
  textarea {
    border: 0px;
    padding: 20px 24px !important;
    font-size: 16px !important;
    height: min(64px, 100%) !important;
    line-height: 24px !important;
    border-radius: 12px !important;
    font-weight: 400;
    max-width: 538px !important;
  }
  .inputHolder {
    .copyIcon {
      background-color: transparent !important;
    }
  }
  label {
    margin-bottom: 8px !important;
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20.8px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }
}
.emailTemplateInput {
  color: $text-color;
  background-color: $background-grey-2;
  border-width: 0px !important;
  padding: 24px !important;
}

.emailTemplateTextArea {
  color: $text-color;
  background-color: $background-grey-2;
  min-height: 426px;
  padding: 24px !important;
  border-width: 0px !important;
  margin-top: 8px;
  border-radius: 12px;
  position: relative;
  .copyIcon {
    position: absolute;
  }
  li {
    margin-left: 12px;
  }
  p,
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.47px;
  }
}

.copyIcon {
  top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px !important;
  height: 64px;
}
