.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .planWrapper {
      border: 1.5px solid rgba(78, 83, 107, 0.6);
      border-radius: 8px;
      padding: 22px 20px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      cursor: pointer;

      &:hover {
        background: #3f4454;
        color: white !important;
      }

      &.selected {
        background: #324f8d;
      }

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
      }

      .title {
        color: rgba(255, 255, 255, 0.8);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Sora, Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.09px;
      }
    }
  }

  .link {
    color: #b9d0ff;
    font-weight: 500;
  }
}
