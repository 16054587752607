@import "styles/functions";
@import "styles/variables";

.myAgent {
    .myAgentTitle {
        color: $text-color;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        border-bottom: 1px solid $light-gray;
        padding: setUnit(12) 0 setUnit(16) 0;   
        margin: 0 setUnit(32);
    }

    .myAgentTitleData {
        margin-bottom: setUnit(12);
    }
}
