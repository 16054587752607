@import 'styles/functions';
@import 'styles/variables';

.activityFilterDrawer {
  .activityFilterContainer {
    height: calc(100vh - 162px);
    padding: setUnit(10);
  }

  .submitButton {
    max-width: setUnit(105);
    width: 100% !important;
    margin-left: auto;
    height: setUnit(44) !important;
    display: block !important;
    border-radius: 0.5rem !important;
  }

  .resetSpan {
    display: flex;
    align-items: center;

    .resetIcon {
      margin-right: 8px;
    }

    .resetAllText {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: $dark-color;
    }

    :hover {
      color: $hover-blue;
    }
  }

  .activityDrawerMultiselect {
    & [class~='ant-select-selector'] {
      min-height: setUnit(48);
      height: auto;
    }
  }
}
