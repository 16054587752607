@import 'styles/functions';
@import 'styles/variables';

.searchIcon {
  display: flex;
  margin-right: setUnit(8);
}

.schoolsDistrictSelect {
  & [class*='ant-select-selector'] {
    height: 48px;
    border-radius: 8px !important;
    border: 1px solid $border-light-gray-2 !important;
    padding: 10px 0 10px 40px !important;
    box-shadow: none !important;
    height: fit-content !important;
    & [class*='ant-select-selection-placeholder'] {
      left: 0;
    }
  }
}

.placeholder {
  padding: 18px 18px 0px 50px;
}

.container {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    left: 10px;
  }
}
