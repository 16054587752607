@import 'styles/functions';
@import 'styles/variables';

.container {
  .question {
    margin: 0 0 12px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.09px;
    text-align: center;
    margin-bottom: 0;
  }
}
