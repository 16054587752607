@import 'styles/functions';
@import 'styles/variables';

.workshopTableWrapper {
  padding: setUnit(10);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 1rem;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  min-height: setUnit(275);
  color: $text--light-gray-color;
  font-size: setUnit(16);
  letter-spacing: -0.33px;
  margin-top: setUnit(20);
}
