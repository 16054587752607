@import 'styles/functions';
@import 'styles/variables';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  .nextBtn {
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    padding: setUnit(14) setUnit(54);
    height: auto;
    margin-top: 40px;
  }
  .transactionBtn {
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    padding: setUnit(12) setUnit(32);
    height: auto;
    margin-top: 40px;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }
  }

  .nextBtnEnabled {
    background-color: $secondary-color !important;
    opacity: 1;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }
  }

  .nextBtnDisabled {
    background-color: $secondary-color !important;
    opacity: 0.8;
  }

  .submitBtn {
    width: auto;
    background-color: $secondary-color !important;

    &:hover {
      background: $secondary-color-hover !important;
    }
  }
}
