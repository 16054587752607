@import 'styles/functions';
@import 'styles/variables';

.agentDetails {
  .headTitle {
    color: $text-gray-color;
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.18px;

    margin-bottom: 6px;
  }

  .agentName {
    font-size: setUnit(16);
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    margin-bottom: 2px;
  }

  .subTitle,
  .email {
    font-size: setUnit(14);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.18px;
  }

  .phoneInput {
    [class*='react-tel-input'] {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.18px;

      input {
        height: 100%;
      }
    }
  }

  .subTitle {
    color: $text-color;
    margin-bottom: 2px;
  }

  .email {
    a {
      color: $link-blue;
      word-wrap: break-word;
    }
  }

  .email:hover {
    text-decoration: underline;
  }
}
