@import "styles/functions";
@import "styles/variables";

.wrapper {
    font-size: 14px;
    line-height: setUnit(16);
    color: $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: setUnit(195);
    max-width: setUnit(300);

    .avatar {
        width: setUnit(32);
        height: setUnit(32);
        margin-right: setUnit(12);
        font-size: setUnit(14);

        img {
            width: setUnit(32);
            height: setUnit(32);
            object-fit: cover;
        }
    }
}
