@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  text-align: center !important;
  margin-bottom: setUnit(40);
}

.answersWrapper {
  margin: 32px auto 40px !important;
}

.option {
  height: setUnit(64) !important;
  border-radius: setUnit(8) !important;
  box-shadow: none;
}

.next {
  margin: setUnit(40) auto setUnit(0) auto;
  width: 212px !important;
  height: 52px !important;
}

.counter {
  margin: setUnit(68) 0;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: setUnit(675);
  width: 100%;
  .innerHolder {
    padding: setUnit(20);
    max-height: setUnit(600);
    @media (min-width: $mobile-small) {
      padding: setUnit(60) setUnit(80) setUnit(40) setUnit(80) !important;
    }
  }
}

.opacityLayer {
  background-color: rgba(white, 0.8) !important;
}

.backyardToggle {
  display: flex;
  margin-bottom: 0.75rem;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $text-color;
    margin-right: 0.75rem;
  }
}
