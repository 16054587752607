@import 'styles/functions';
@import 'styles/variables';

.scrollWrapper {
  height: calc(100vh - #{setUnit(232)});
  .fieldWrap {
    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
    .locationsPlaceholder {
      border-width: 1px;
    }
    .locationsSearchInput {
      input {
        border-width: 1px;
      }
    }
    .transportOption {
      width: 100%;
      div[class~='ant-select-selector'] {
        border-radius: setUnit(8);
        border: 1px solid $border-light-gray-2;
        height: setUnit(48);
        display: flex;
        align-items: center;
        padding: 0 setUnit(16);
        span[class~='ant-select-selection-item'] {
          font-size: setUnit(16);
          font-weight: 400;
          line-height: setUnit(21);
          letter-spacing: setUnit(-0.18);
        }
      }
      &[class~='ant-select-open'] {
        div[class~='ant-select-selector'] {
          box-shadow: none;
          border-color: $border-light-gray-2;
        }
      }
    }
    .preferenceWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .innerLabel {
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(21);
        letter-spacing: setUnit(-0.18);
        margin-right: setUnit(16);
      }
      .inputFieldMinutes {
        max-width: setUnit(104);
        margin: 0 setUnit(16) 0 0;
        border-radius: setUnit(8);
        border: 1px solid $border-light-gray-2;
        height: setUnit(48);
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(21);
        letter-spacing: setUnit(-0.18);
        background-color: transparent;
        padding: 0 setUnit(16);
        &:focus {
          box-shadow: none;
        }
      }
      .timeSelectWrapper {
        width: calc(100% - #{setUnit(187)});
        div[class~='ant-select-selector'] {
          border-radius: setUnit(8);
          border: 1px solid $border-light-gray-2;
          height: setUnit(48);
          display: flex;
          align-items: center;
          padding: 0 setUnit(16);
          span[class~='ant-select-selection-item'] {
            font-size: setUnit(16);
            font-weight: 400;
            line-height: setUnit(21);
            letter-spacing: setUnit(-0.18);
          }
        }
        &[class~='ant-select-open'] {
          div[class~='ant-select-selector'] {
            box-shadow: none;
            border-color: $border-light-gray-2;
          }
        }
      }
    }
    .labelField {
      border-radius: setUnit(8);
      border: 1px solid $border-light-gray-2;
      height: setUnit(48);
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(21);
      letter-spacing: setUnit(-0.18);
      background-color: transparent;
      padding: 0 setUnit(16);
      &:focus {
        box-shadow: none;
      }
    }
    &.rushFieldWrap {
      display: flex;
      justify-content: flex-end;
      .rushFieldLabel {
        margin-right: setUnit(8);
        margin-bottom: 0;
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        color: $dark-grey-color;
      }
    }
  }
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}
