@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.container {
  position: relative;

  .title {
    font-weight: 400 !important;
    letter-spacing: -0.89px;
    color: $secondary-color !important;
    font-size: setUnit(26) !important;
    line-height: setUnit(32);
    @media (min-width: $mobile) {
      font-size: setUnit(32) !important;
    }
  }

  .signupWrapper {
    width: 100%;
    height: calc(100vh - #{setUnit(218)});
    padding: 0 setUnit(24);
    @media (min-width: $tablet) {
      padding: 0;
      width: calc(100% - #{setUnit(680)});
      height: 100vh;
    }
    .formGroup {
      border-radius: setUnit(12);
      background: #ffffff;
      max-width: setUnit(400);
      margin: auto 0;
      .description {
        @include label;
      }

      .option {
        border-bottom: 1px solid $grey-color;
        padding: setUnit(12) 0;

        .label {
          display: inline-flex;
          margin-bottom: 0;

          .labelText {
            margin-left: setUnit(15);
          }
        }
      }
      .continue {
        width: 100%;
        margin-top: setUnit(40);
        height: setUnit(56);
        font-weight: 600;
      }
      .error {
        @include inputError;
      }
    }
  }

  .aside {
    .logo {
      text-align: center;
      display: flex;
      justify-content: center;
      max-width: setUnit(360);
    }
  }

  @media (min-width: $mobile) {
    .aside {
      padding: setUnit(60) setUnit(15);
      width: setUnit(360);
      background: url('../../images/login-left-light-bg-new.png') no-repeat;
      background-size: cover;
      .text {
        font-weight: 600;
        font-size: setUnit(24);
        line-height: setUnit(29);
        color: #ffffff;
        margin-top: setUnit(51);
        width: 100%;
        max-width: setUnit(250);
      }
    }
  }
}
