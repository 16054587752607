@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
  width: 100%;
  label {
    @include label;
  }
  .buttons {
    display: flex;
    white-space: nowrap;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
