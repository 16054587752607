@import 'styles/functions';
@import 'styles/variables';
.clientSection {
  display: flex;
  align-items: center;

  .avatar {
    width: 2rem;
    height: 2rem;
    background: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0.9375rem;
    color: $white !important;
    font-weight: 600;
  }

  .notAvailable {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .nameWrapper {
    margin: 0 setUnit(8);
    .name {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.18px;
      color: $text-color;
      align-self: center;
    }
    .address {
      color: $text-gray-color;
      text-transform: capitalize;
    }
  }

  & [class*='ant-avatar'] {
    &:not(:first-child) {
      margin-left: -1.25rem !important;
      margin-top: 0.5rem;
    }
  }
}
