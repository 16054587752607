@import 'styles/variables';
@import 'styles/functions';

.transactionActivity {
  .transactionActivityWrapper {
    display: flex;
    justify-content: center;
    padding: 1.5rem 2rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    width: 100%;
    @media (min-width: $tablet) {
      padding: 1.5rem 2rem;
    }
  }
}
