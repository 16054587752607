@import 'styles/functions';
@import 'styles/variables';

.container {
  .celebrate {
    height: 5rem;
    margin-bottom: 2.5rem;
    font-size: 64px;
    text-align: center;
  }
  .question {
    margin-bottom: 0;
  }
  .completeBtn {
    width: 100%;
    height: setUnit(52);
    .buttonTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.09px;
    }
  }
}
