@import 'styles/functions';
@import 'styles/variables';

.cardContainer {
  position: relative;
  border-radius: setUnit(16);
  border: 1px solid $background-grey-2;
  padding: setUnit(10);
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  margin-bottom: setUnit(20);

  &.noBoxShadow {
    box-shadow: none !important;
  }

  &.orange {
    background: #fb913a08 !important;
    border-color: #fb913a33 !important;
  }

  &.lightGray {
    background: #f4f5f64d !important;
    border-color: #e8e9ea !important;
  }

  &.confettiBlue {
    background: #4673d10f !important;
    border-color: transparent !important;
  }

  &.purple {
    background: #928cda0f !important;
    border-color: transparent !important;
  }

  &.lightBlue {
    background: #51bfe10f !important;
    border-color: transparent !important;
  }
}
