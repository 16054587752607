@import 'styles/functions';
@import 'styles/variables';

.comparison {
  display: flex;
  flex-direction: column;

  .stickyHeader {
    width: 100vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: $white;
    z-index: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: setUnit(24);

    .backComparison {
      margin-left: 2rem;
      z-index: 1;
      overflow: hidden;
      padding: 0;
      width: setUnit(44);
      height: setUnit(44);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $light-gray;
      background: #fff;
      transition: $transition;
      &:hover {
        background-color: $light-gray;
        opacity: 1;
      }
      &:focus {
        background-color: $border-light-gray-2;
      }
      div {
        margin-right: 0;
        display: flex;
        svg {
          width: setUnit(8);
          height: setUnit(14);
          path {
            stroke: $dark-color;
          }
        }
      }
      span {
        display: none;
      }
      &:hover {
        color: $text-color;
      }
    }

    .heading {
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(32);
      font-family: $font-family;
    }

    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: setUnit(32);

      div {
        cursor: pointer;

        &:not(:first-child) {
          margin-left: setUnit(16);
        }
      }
    }
  }

  .sections {
    padding: 3rem;
    padding-top: 0 !important;
    align-self: center;

    .searchSection {
      min-height: setUnit(400);
    }

    .tableContainer {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tableSection {
        padding-bottom: setUnit(32);
        margin-top: setUnit(16);

        .preferencesError {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
          letter-spacing: -0.09px;
          color: $hover-red;
          margin-top: setUnit(48);
        }

        @media screen and (max-width: $desktop) {
          margin-left: setUnit(16);
          margin-right: setUnit(16);
        }

        &:not(:last-child) {
          border-bottom: setUnit(1) solid $light-gray;
        }

        .tableHeading {
          margin-left: setUnit(16);
          margin-bottom: setUnit(16);
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(20);
          font-family: $font-family;
        }
      }
    }
  }
}

.Footer {
  border-top: setUnit(1) solid $light-gray;
  margin-top: setUnit(16);
}
