.verifyEmail {
  max-width: 400px;
}

.heading {
  color: var(--Grayscale-100, #262626);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 112.5% */
  letter-spacing: -0.4px;

  margin-bottom: 16px;
}

.subHeading {
  color: var(--Grayscale-80, #515151);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.18px;
  margin-bottom: 24px;

  span {
    color: var(--Secondary-Link-Blue, #2b59da);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.18px;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 24px;

  button {
    width: 242px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Primary-Navy, #252d44);
  }
}

.inputOtpContainer {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  margin-bottom: 32px;

  .input {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    //   box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.2);
    background: white;
    height: 56px;
    width: 48px;
    text-align: center;

    color: var(--Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
    caret-color: transparent;

    transition: all;
    transition-duration: 0.3s;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input:focus,
  .input:focus-visible {
    outline: none !important;
    border: 1px solid var(--Grayscale-50, #747475);
  }

  .invalid {
    border: 1.5px solid rgba(255, 134, 150, 0.8);
  }

  .invalidCodeText {
    color: rgba(255, 134, 150);
  }
}

.resendContainer {
  color: var(--Grayscale-50, #747475);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.18px;
}
