@import 'styles/functions';
@import 'styles/variables';

.pageContent {
  padding-top: 100px;
  padding-right: 112px;
  padding-left: 70px;
  @media (max-width: $mobile) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.pagecontentStyles {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}

.vaultTabs {
  [class='ant-tabs-nav'] {
    background-color: $white;
    position: absolute !important;
    top: 11rem;
    left: 0;
    right: 0;
    padding: 0 160px 0 112px;
    z-index: 10;
  }
  [class*='ant-tabs-content'] {
    padding: setUnit(12) 0;
    margin-top: setUnit(32);
  }
  [class*='ant-tabs-nav-wrap'] {
    display: inline-block !important;
    width: 100%;
  }
  [class*='ant-tabs-nav-list'] {
    display: flex;
    justify-content: center;
    [class*='ant-tabs-tab'] {
      &:not(:first-child) {
        margin-left: setUnit(40) !important;
      }
    }
  }
  [class*='ant-tabs-tab-active'] {
    .vaultTabTitle {
      color: $black;
      text-shadow: none;
      svg {
        circle,
        path {
          stroke: $black;
        }
      }
    }
  }
  .vaultTabTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $text-gray-color;
    text-shadow: none;

    .icon {
      display: flex;
    }
  }

  .optionsColumn {
    display: flex;
    justify-content: flex-end;
    .bottomOffset {
      bottom: -9rem;
    }
  }

  @media (max-width: $mobile) {
    [class='ant-tabs-nav'] {
      padding: 0 !important;
    }
    [class*='ant-tabs-nav-wrap'] {
      display: flex !important;
    }
  }
}
