@import 'styles/variables';

.fileSizeError {
  background-color: $primary-color-light;
  border-radius: 8px;
  color: $error-color;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.09px;

  padding: 12px 0px 12px 12px;
  margin-top: 20px;
  margin-bottom: -10px;

  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 0px;
}
