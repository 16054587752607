@import 'styles/functions';
@import 'styles/variables';

.attachments {
  transition: all 0.15s;
  margin-right: setUnit(12);
  &:hover {
    transform: scale(1.1);
  }
  .icon {
    svg {
      vertical-align: middle;
    }
  }
  .nativeFileInput {
    display: none;
  }
  .fileInput {
    cursor: pointer;
  }
}
