@import 'styles/functions';
@import 'styles/variables';

.kitsRowData {
  height: setUnit(46);
  transition: all 0.2s;

  &:not(:first-child):hover {
    background-color: $background-grey-2;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $background-ligh-gray-4;
  }

  td {
    overflow-wrap: anywhere;

    &:not(:last-child) {
      border-right: 1px solid $background-ligh-gray-4;
    }

    div {
      padding-left: setUnit(16);
    }

    .heading {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $dark-grey-color;
    }

    .kitName {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      display: flex;
      justify-content: space-between;

      .kitOptions {
        display: flex;
        justify-content: center;
        align-items: center;

        .kitPermission,
        .kitEdit {
          opacity: 0;
          transition: opacity 0.3s;
          cursor: pointer;
          margin-right: setUnit(10);
        }
      }

      div {
        border-bottom: 1px solid transparent;
        padding: 0;
        transition: all 0.2s;
      }
    }

    .text {
      font-weight: 400;
      font-size: setUnit(14);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
    }

    .notes {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: setUnit(140);
    }

    .locations {
      max-width: setUnit(250);
      word-break: break-word;
    }

    .options {
      width: setUnit(200);

      .selector {
        border: none;
        outline: none;
        background-color: $background-grey-2;
        border-radius: setUnit(30);
        height: setUnit(32);
        padding: 7px;
      }
    }

    .switchCell {
      @media screen and (max-width: $desktop) {
        padding-left: setUnit(8);
      }
    }
  }

  &:hover .kitName .kitOptions .kitEdit {
    opacity: 1;
  }

  &:hover .kitName .kitOptions .kitPermission {
    opacity: 1;
  }
}
