@import 'styles/variables';

.row {
  display: flex;
}

.name {
  align-items: center;
  gap: 12px;
}

.formAvatarIcon {
  line-height: 0;
  svg {
    width: 32px;
    height: 32px;
  }
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.formName {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.18px;
  text-align: left;
  color: $dark-gray-3;
}
