@import 'styles/functions';
@import 'styles/variables';

.projectContainer {
  padding-left: setUnit(25);

  .Project {
    height: setUnit(40);
    margin: setUnit(30) 0;
    color: #ffffff;
    display: flex;
    align-items: center;

    .photo {
      width: setUnit(40);
      height: setUnit(40);
      object-fit: cover;
      border-radius: setUnit(7);
    }

    .address {
      font-size: setUnit(14);
      font-weight: 500;
      margin-left: setUnit(12);
      line-height: setUnit(18);
    }
    .secondLabel {
      color: #d6d6d7;
      font-size: setUnit(14);
      font-weight: 400;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      background-color: #252d44;
      color: #515151;
      border-radius: 0.6rem;
      padding: 0 0.2rem;
      top: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .projectDetails {
    .label {
      font-weight: 400;
      letter-spacing: -0.09000000357627869px;
    }
    .value {
      font-weight: 600;
    }
    .label,
    .value {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.09000000357627869px;
      text-align: left;
      color: $light-grey-color;
      margin-bottom: 0.25rem;
    }
  }
}

.divider {
  margin: setUnit(20) 0;
  height: 1px;
  background-color: $border-light-gray-2;
  opacity: 0.15;
}
