@import 'styles/functions';
@import 'styles/variables';

.editForm {
  padding: 1.25rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 0.6;

  .title {
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 0;
  }

  .info {
    font-weight: 400;
    color: $text-color;
    margin-bottom: 0;
    font-size: 13px;
  }

  .editIcon {
    background-color: $background-grey-2;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: $transition;
    &:hover {
      background-color: $light-gray;
    }
  }

  &:hover {
    opacity: 1;
  }

  .editFormInfo {
    [class*='text'] {
      line-height: 1.25rem;
    }
  }
}
