@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.iconImage {
  height: 48px;
  width: 48px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: top;
  overflow: hidden;

  .icon {
    height: 48px;
    width: 48px;
    border-radius: 100%;
  }
}

.details {
  display: inline-block;
  text-align: left;

  p {
    margin-bottom: 0.25rem;
  }
}

.title {
  font-weight: 600;
}

.subTitle {
  font-weight: 400;
}

.viewMore {
  color: $dark-color;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: $link-blue;
  }
}

.phone {
  font-weight: 400;
  font-size: 14px;
}

.email {
  color: $link-blue;
  font-size: 14px;
}

.email:hover {
  text-decoration: underline;
}

.sendMessages {
  vertical-align: sub;
  width: 140px;
  text-align: center;
  margin: 10px auto;
  display: block;
  float: none;
}

.messages {
  margin-left: setUnit(12);
  font-size: setUnit(14);
  font-weight: 500;
  height: setUnit(44);
  border: 1px solid $border-light-gray-2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 setUnit(16);
  border-radius: setUnit(100);
  cursor: pointer;
  background-color: transparent;

  svg {
    path {
      fill: $dark-color;
      stroke-width: 1px;
      stroke: $dark-color;
    }
  }

  &:hover {
    background-color: $background-ligh-gray;
  }
}

.messagesIcon {
  margin-right: setUnit(8);
  position: relative;
  left: setUnit(-2);

  svg {
    vertical-align: middle;
    width: setUnit(20);
    height: setUnit(20);
  }
}
