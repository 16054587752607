@import 'styles/functions';
@import 'styles/variables';

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: setUnit(8);
  padding-bottom: setUnit(8);
  position: relative;

  &:hover &MenuIcon {
    visibility: visible;
  }

  &Label {
    width: setUnit(112);
    display: flex;
    align-items: center;

    .label {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $dark-grey-color;
    }

    .lockIcon {
      margin-left: setUnit(8);
    }
  }

  &Input {
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-family: $font-family;
    letter-spacing: setUnit(-0.18);
    color: $text-color;
    margin-bottom: 0;
    flex-grow: 1;
    outline: none;
    border: none;
    border-bottom: setUnit(2) solid $background-ligh-gray-4;
    padding-bottom: setUnit(10);
    padding-left: setUnit(6);
    padding-top: setUnit(6);

    &::placeholder {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $text--light-gray-color;
    }

    &::-ms-input-placeholder {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $text--light-gray-color;
    }
  }

  .viewMode {
    background-color: #f5f5f5;
    border-radius: setUnit(8);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &MenuIcon {
    cursor: pointer;
    margin-left: setUnit(16);
    visibility: hidden;
  }

  .menu {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px #00000024;
    border-radius: setUnit(8);
    right: 0;
    top: setUnit(45);
    z-index: 100;

    &Item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-top: setUnit(16);
      padding-bottom: setUnit(16);
      padding-left: setUnit(24);
      padding-right: setUnit(48);

      &:hover {
        background-color: #f4f5f6;
      }

      &Text {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $text-color;
        margin-left: setUnit(8);
        margin-bottom: 0;
      }

      &:last-child,
      &:first-child {
        border-radius: setUnit(8);
      }
    }
  }
}
