@import 'styles/functions';
@import 'styles/variables';

.notificationsForm {
  .formContainer {
    padding: 40px 64px 40px 40px;
    position: relative;
    transition: padding 0.3s;

    .title {
      font-weight: 600;
      font-size: 20px;
      color: $text-color;
      margin-bottom: setUnit(16);
    }

    $options-gap: setUnit(15);

    .selectType {
      margin: setUnit(16) 0 0;
      display: flex;
      flex-direction: row;
      gap: $options-gap;
      width: 100%;
    }

    .selectOption {
      height: setUnit(56);
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: setUnit(16) setUnit(16) setUnit(16) setUnit(16);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      @media (min-width: $mobile) {
        min-width: calc((100% / 4) - $options-gap);
      }

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
        @media (min-width: $mobile) {
          margin-bottom: 0;
        }
      }

      label span:last-child {
        display: none;
      }

      .labelText {
        margin-left: 0;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      span {
        font-weight: 500 !important;
      }
    }

    .notificationItemsWrapper {
      margin-top: setUnit(40);
    }
  }
}
