@import 'styles/variables';
@import 'styles/functions';

.breakdownWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 16px;
  border: 1px solid #e8e9ea;
  height: inherit;

  .breakdownHeader {
    display: flex;
    justify-content: space-between;
    color: $text-color;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.33px;
    margin-bottom: 20px;
    font-family: Inter;
    padding: 27px 20px 0px 20px;
    position: sticky;
    top: 0;
    background-color: $white;
    border-radius: 16px;
    align-items: center;
    z-index: 4;

    .rightSide {
      display: flex;
      align-items: center;
      gap: setUnit(14);
    }

    .closeBtn {
      cursor: pointer;
    }
  }

  .breakdownContent {
    display: none;
    width: 467px;
    height: 548px;
    overflow: auto;
    padding: 0px 6px 5px 20px;
    margin-right: 14px;

    &.openMenuContent {
      display: unset;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      padding: 12px 20px 12px 16px;
      margin-bottom: 8px;
      align-items: center;
      border-radius: 12px;
      border: 1.5px solid $light-gray;
      height: 64px;

      cursor: pointer;

      .profileAvatar {
        width: 36px;
        height: 36px;
      }

      .agentName {
        display: flex;
        align-items: center;
        color: $dark-grey-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        margin-left: 8px;
        margin-bottom: 0;

        &.selectedAgent {
          font-weight: 500;
          color: $text-color;
        }
      }

      .stats {
        margin-left: auto;
        display: flex;
        margin-left: 16px;
        align-items: baseline;

        .amount {
          color: $text-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
        .requests {
          color: $text-gray-color;
          text-align: right;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: -0.8px;
          margin-left: 2px;

          &.selectedAgent {
            color: $dark-color;
          }
        }
        .percentage {
          color: $text--light-gray-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 2px 0px 0px 12px;
          padding: 0px 6px;
          border-radius: 17px;
          background: $background-grey-2;
          height: 20px;
        }
      }
    }
  }

  .placeholder {
    height: 548px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-gray-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.6px; /* 125.714% */
    letter-spacing: -0.363px;
    padding-bottom: 71px;
  }

  .agentName {
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
    margin-bottom: 0px;
  }
  .amount {
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
  }
  .requests {
    color: $dark-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
    color: $confetti-blue;

    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
  .percentage {
    color: $dark-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
  }
}

.BreakdownTable {
  & [class*='ant-table-thead'] {
    background-color: $white;
    border-bottom: 1px solid $light-grey-color;
    position: sticky;
    top: 0;
    background-color: white;
    height: 20px;
    z-index: 1;

    & tr > th {
      background-color: $white;
      color: $text-gray-color;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.18px;
    }
    &
      tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none !important;
    }
  }

  tr > td {
    color: $dark-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
  }

  .rowWrapper {
    border-radius: 4px;
    border: 1.5px solid;
    cursor: pointer;
  }
}
.customLine {
  border-bottom: 1px solid $light-gray;
  margin: 0px 20px 12px 20px;
}
