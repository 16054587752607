@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.appointmentDetailsModal {
  [class='ant-modal-content'] {
    padding: 2rem;
    margin: 4rem 0;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.heading {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 2rem;
}

.activeIteration {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.iterations {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}
.content {
  // max-height: calc(100vh - 16.5rem);
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  @include customScrollbar;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }

  .action {
    border-top: 1px solid $light-gray;
    padding: 1.5rem 0 0;
    text-align: center;

    .cancelBtn {
      width: 100%;

      .icon {
        display: inline-block;
        position: relative;
        top: 0.3rem;
        margin-right: 0.5rem;
      }

      span {
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .detailsContainer {
    flex-grow: 1;
    min-width: 206px;
  }

  .tabRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tabIcon {
    display: flex;
    margin-bottom: setUnit(8);
    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }
}

@media screen and (max-width: 576px) {
  .appointmentDetailsModal {
    [class='ant-modal-content'] {
      padding: 2rem 0rem 2rem 1rem;
    }

    .heading {
      margin-right: 2rem;
    }

    .content {
      padding-right: 1rem;
    }
  }
}

.messageBtn {
  width: 100%;
  background-color: transparent !important;
  border: 2px solid $border-light-gray-2 !important;
  color: $text-color-middle !important;
  padding: 8px 16px !important;

  border-radius: 8px !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.18px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: initial !important;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: transparent !important;
    border: 2px solid $text--light-gray-color !important;
  }

  div {
    height: 24px;
    width: 24px;
  }

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: $dark-color;
    }
  }

  span {
    margin-left: 0.25rem;
    font-weight: 500;
  }
}
.tabs {
  .ant-tabs {
    &-nav {
      display: flex;

      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.basePartnerProfile {
  min-height: 622px !important;
  // height: 622px !important;
  width: 675px !important;

  & [class*='ant-modal-content'] {
    padding: 0;
    overflow: hidden;
  }

  & [class*='ant-modal-header'] {
    margin-top: 32px;
  }
}

.avatarWrap {
  margin-bottom: 12px;
  .avatar {
    height: 80px;
    width: 80px;
    font-size: 32px;
    line-height: 36px;
  }
}

.clickable {
  cursor: pointer;
}

.partnerBody {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-height: 800px;
  overflow-y: auto;
  padding: 40px 0;
  row-gap: 24px;

  > div {
    padding: 0 40px;
  }

  .bodyHeader {
    color: $text-color;
    line-height: 20px;
    letter-spacing: -0.18px;
    margin-bottom: 12px !important;
  }

  .noMargin {
    margin-bottom: 0 !important;
  }
}

.contactContainer {
  // margin: 24px 0 0;

  .contactParagraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    word-break: break-word !important;
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;
    padding: 12px 0;
    margin-bottom: 0;
    .contactInner {
      display: flex;
      align-items: center;
      flex: 0 0 setUnit(100);
      margin-right: setUnit(15);
      .contactIcon {
        height: 20px;
        width: 20px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $dark-color;
      }
    }

    .contactPhoneText {
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $text-color;
        @media (min-width: $mobile) {
          text-align: right;
        }
      }
    }

    .contactAddressText {
      text-align: left;
      color: $text-color;
      @media (min-width: $mobile) {
        text-align: right;
      }
    }
  }
  .cpDetail {
    flex: 0 0 100%;
    margin-top: 10px;
    a {
      font-size: 16px;
      color: $text-color;
      letter-spacing: -0.18px;
      line-height: 20px;
    }
    @media (min-width: $mobile) {
      flex: 0 0 auto;
      margin-top: 0;
    }
  }
}

.descriptionContainer {
  width: 100%;

  .descriptionParagraph {
    max-height: 360px;
    overflow: auto;
    font-size: 16px;
    color: $text-color;
    line-height: 24px;
    letter-spacing: -0.18px;
    margin-bottom: 0;

    &.descriptionEmpty {
      color: $text-gray-color;
    }
  }

  .requestHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }
}

.areasServerContainer {
  .areasTags {
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;

    span:not(:first-of-type) {
      &:before {
        color: $link-blue;
        content: ', ';
      }
    }
  }
}

.servicesContainer {
  margin-inline: 24px;
  padding: 16px !important;
  border-radius: 12px;
  background: $background-gray;

  .servicesTags {
    font-size: 16px;
    color: $text-color;
    letter-spacing: -0.18px;
    line-height: 20px;

    span:not(:first-of-type) {
      &:before {
        color: $link-blue;
        content: ', ';
      }
    }
  }
}

.bodyHeaderSmallMargin {
  margin-top: 0.25rem;
}

.contentRight {
  min-height: setUnit(540);
  overflow-y: auto;
  padding: 0 setUnit(16) setUnit(16) setUnit(32);
  min-width: 470px;
  border-left: 1px solid $light-gray;
  @include customScrollbar;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $text--light-gray-color;
    }
  }
  .contentRightIframe {
    border: 0 !important;
  }
}

.disclaimerContainer {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .disclaimer {
    font-size: setUnit(16);
    font-weight: 100;
    color: $text--light-gray-color;
  }
}

.recentQuoteTitle {
  font-size: setUnit(16);
  font-weight: 600;
}

.recentQuoteDate {
  font-size: setUnit(14);
  font-weight: 400;
  color: $text-gray-color;
}

.tabs {
  [class='ant-tabs-nav-list'] {
    width: 100%;
  }

  [class~='ant-tabs-tab'] {
    width: 50%;
    display: unset;
  }
  [class~='ant-tabs-tab-active'] {
    width: 50%;
    display: unset;
  }
}
.quoteDetailsUploadButton {
  border-top: 1px solid $grey-color;
  padding-top: 1.5rem;
  margin-bottom: 20px;

  .quoteDetailsUpload {
    label {
      height: 52px;
      font-weight: 600;
      font-size: 16px;
      color: $white;
      background: $background-blue;
      border: none;
      border-radius: 0.5rem;
      margin-left: 0;

      &:hover {
        background: $secondary-color-hover;
      }
    }
  }

  .quoteDetailsUploadIcon {
    display: flex;

    svg {
      path {
        stroke: $white;
      }
    }
  }
}

.quotesBlock {
  margin-top: setUnit(32);
  .title {
    font-size: setUnit(16);
    font-weight: 600;
  }
}

.popup {
  & ul {
    & div {
      display: flex !important;
      flex-wrap: nowrap !important;
    }
  }
}
