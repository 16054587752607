@import 'styles/variables';
@import 'styles/functions';

.inputSelectModal {
  .formTitle {
    text-align: center;

    .formHeaderIcon {
      line-height: 0;
      margin-bottom: 24px;
    }

    h4 {
      margin-bottom: 24px;
    }
  }

  & [class*='ant-btn-primary'] {
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    height: auto;

    background-color: $secondary-color !important;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }

    &:focus {
      border-color: $secondary-color;
    }
  }

  & [class~='ant-btn-default'] {
    font-weight: 500 !important;
    color: $dark-color !important;

    span {
      font-weight: 500 !important;
    }
  }

  .spinner {
    width: 100%;
    height: 60px;
  }

  .loadingSpinner {
    font-size: 0.5em;
  }
  .inputBlock {
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: setUnit(20);
    }

    .label {
      font-size: setUnit(14);
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;
      margin-bottom: 12px;
      color: $text-color;
    }

    .inputHolder {
      width: 100%;
      [class*='inputField'] {
        padding-left: 0px !important;
      }

      input {
        font-size: setUnit(16);
      }
    }
  }
}
