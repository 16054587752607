@import 'styles/functions';
@import 'styles/variables';

.activeSearchInstances {
  width: 100%;
  .question {
    min-height: setUnit(114);
    max-width: setUnit(600);
    display: block;
    margin: auto;
  }
  .content {
    height: calc(100vh - #{setUnit(361)});
    overflow-y: auto;
    padding-right: setUnit(16);
    .answers {
      margin: 0;
    }
  }

  .clientSearches {
    margin: setUnit(32) setUnit(8);
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    padding: setUnit(16) 0;
    max-width: setUnit(600);
    width: 100%;
    margin: auto;
    .submit {
      background-color: $background-blue;
      min-width: 142px;
      height: setUnit(52);
      font-size: 16px;
      font-weight: 600;
      display: block;
      position: relative;
      bottom: 0;
      right: 0;
      &:hover {
        background-color: $secondary-color-hover;
      }
      &.celebrateSubmit {
        min-width: 220px;
      }
      .submitTitle {
        display: flex;
        justify-content: center;
        svg {
          margin-left: setUnit(12);

          path {
            stroke: $white;
          }
        }
      }
    }
  }
}
