@import 'styles/functions';
@import 'styles/variables';

.icon {
  svg {
    position: relative;
    top: 3px;
    left: 16px;
  }
}

.propertyAttributesPanel {
  .panel {
    border-radius: 10px !important;
    margin: 10px 0;
    [class*='ant-collapse-header'],
    [class*='ant-collapse-content-box'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .panelHeader {
      display: flex;
      align-items: center;
      gap: setUnit(10);
      .title,
      .count {
        font-size: setUnit(16);
      }
      .title {
        font-weight: 600;
      }
      .count {
        color: $light-gray-2;
      }
    }
  }
}

.preferencesContainer {
  padding-top: 10px;
  .addButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 48px !important;
    background-color: $white !important;
    color: $dark-color !important;
    border-radius: 8px;
    border: 1.5px solid $light-gray !important;
    &:hover {
      border: 1px solid $border-middle-gray !important;
    }
  }
}
