@import 'styles/functions';
@import 'styles/variables';

.loading {
  color: $dark-grey-color;
}

.itemContainer {
  height: setUnit(80);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 setUnit(32);
  cursor: pointer;
  border-bottom: 1px solid $light-gray;
  &:last-child {
    border-bottom: none;
  }
  .inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    .nameHolder {
      margin-left: setUnit(16);
      .name {
        font-weight: 600;
        width: fit-content;
        margin-bottom: 0;
      }
      .favorites {
        font-size: setUnit(12);
        font-weight: 400;
        margin-bottom: 0;
        color: $dark-grey-color;
      }
    }

    .avatar {
      width: setUnit(56);
      height: setUnit(56);
      border-radius: 50%;
      vertical-align: middle;
      background: $secondary-color;
      object-fit: cover;
    }
  }
  &:hover {
    background: #f6f7f7;
  }
}
