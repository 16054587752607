@import 'styles/functions';
@import 'styles/variables';

.summaryItem {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 28px;
  }
  .left {
    width: calc(100% - 72px);
    .question {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.09px;
      color: $dark-grey-color;
      margin-bottom: 4px;
    }

    .answer {
      display: flex;
      position: relative;
      width: 100%;
      cursor: pointer;
      .answerHolder {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.09px;
        color: $text-color;
        &:hover {
          color: $secondary-color;
        }
      }
      .signature {
        max-width: 200px;
        width: 100%;
        height: auto;
      }
    }
  }

  .right {
    margin-left: 32px;
    .edit {
      height: 40px;
      min-width: 40px;
      border: 2px solid $light-gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: $transition;
      cursor: pointer;
      &:hover {
        border-color: $text-gray-color;
      }
      svg {
        vertical-align: middle;
        width: setUnit(20);
        path {
          stroke: $secondary-color;
          stroke-width: 2px;
        }
      }
    }
  }

  &:hover {
    .edit {
      opacity: 1;
    }
  }
}
