@import "styles/functions";
@import "styles/variables";

.list {
    .header {
        padding: setUnit(32) 0 setUnit(16);
        margin-left: setUnit(32);
        margin-right: setUnit(32);
        border-bottom: 1px solid $border-light-gray;

        .text {
            font-weight: 600;
        }
    }
}
