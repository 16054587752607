@import 'styles/functions';
@import 'styles/variables';

.tableView {
  margin-bottom: setUnit(20);
  border-radius: 1rem;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 setUnit(20);
  }
  .header {
    display: flex;
    align-items: center;

    .title {
      font-size: setUnit(20);
      font-weight: 600;
      margin-left: setUnit(12);
    }
  }

  span[class~='ant-table-column-sorter-full'] {
    display: none;
  }

  .sortedColumnTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sorterIcon {
      margin-left: setUnit(8);
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(24);
      height: setUnit(24);
      background-color: $background-grey-2;
      border-radius: setUnit(100);
      cursor: pointer;
      transform: rotate(0deg);
    }

    .sorterUp {
      transform: rotate(180deg);
    }
  }

  .view {
    margin-top: setUnit(30);
  }

  tbody[class~='ant-table-tbody'] {
    td {
      font-size: setUnit(16);
      line-height: setUnit(18);
      font-weight: 400;
      &:first-child {
        font-weight: 600;
        line-height: setUnit(20);
      }
      &[class~='ant-table-column-sort'] {
        background-color: transparent;
      }
    }
    .rowChecked {
      td[class~='ant-table-cell'] {
        color: $text--light-gray-color;
        span {
          color: $text--light-gray-color;
        }
      }
    }
  }

  thead[class~='ant-table-thead'] {
    th {
      background-color: transparent;
    }
  }

  .transactionsData {
    cursor: pointer;
    .address {
      font-weight: 500;
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      margin-bottom: 0;
    }
    .addressLine2 {
      margin-top: setUnit(2);
      color: $text-gray-color;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.modalBtn {
  margin-left: 16rem !important;
  margin-top: 9% !important;
}

.inlineRowContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .text {
    padding-left: 16px;
  }
}

.dateText {
  margin-left: setUnit(10);
  font-size: setUnit(16);
  font-weight: 600;
  line-height: setUnit(16);
  letter-spacing: -0.33000001311302185px;
  text-align: left;
}

.overDueText {
  color: $error-color;
}

.roleCategory {
  margin-bottom: setUnit(3);
}

.status {
  text-transform: capitalize;
  color: $text-gray-color;
  margin-bottom: 0;
}

.filterArrow {
  svg {
    transform: rotate(180deg);
    path {
      stroke: $text-color;
    }
  }
}

.transactionContentFirstRow {
  font-size: setUnit(16);
  font-weight: 400;
  line-height: setUnit(18);
  letter-spacing: -0.33000001311302185px;
  text-align: left;
  color: $text-color !important;
}
.transactionContentSecondRow {
  font-size: setUnit(14);
  font-weight: 400;
  line-height: setUnit(18);
  letter-spacing: -0.33000001311302185px;
  text-align: left;
  color: $dark-color !important;
}
