@import 'styles/functions';
@import 'styles/variables';
.modal {
  [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    p {
      margin-bottom: 0;
      .price {
        font-weight: 400;
      }
      .separator {
        margin: 0 16px;
      }
    }
  }
}
.imageGrid {
  position: relative;
  display: grid;
  gap: setUnit(4);
  width: 100%;
  height: 260px;
  .gridItem {
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.rounded {
      border-radius: setUnit(12);
    }
    &.roundedLeft {
      border-top-left-radius: setUnit(12);
      border-bottom-left-radius: setUnit(12);
    }
    &.roundedRight {
      border-top-right-radius: setUnit(12);
      border-bottom-right-radius: setUnit(12);
    }
    &.roundedTopLeft {
      border-top-left-radius: setUnit(12);
    }
    &.roundedBottomLeft {
      border-bottom-left-radius: setUnit(12);
    }
    &.roundedTopRight {
      border-top-right-radius: setUnit(12);
    }
    &.roundedBottomRight {
      border-bottom-right-radius: setUnit(12);
    }
  }
  .showMore {
    position: absolute;
    bottom: setUnit(12);
    right: setUnit(12);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: setUnit(2);
    padding: setUnit(6) setUnit(12) setUnit(6) setUnit(8);
    background: rgba($white, 0.9);
    backdrop-filter: blur(setUnit(20));
    border-radius: setUnit(12);
    cursor: pointer;
    user-select: none;
    &:hover {
      background: rgba($white, 1);
    }
    svg path {
      stroke: $text-color;
    }
    .length {
      color: $text-color;
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.09px;
    }
  }
}
