@import 'styles/mixins';
@import 'styles/variables';

.actionMenu {
  position: fixed;
  right: setUnit(24);
  bottom: setUnit(24);
  z-index: 10;
  .actionMenuBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: setUnit(4);
    height: 40px;
    width: 40px;
    background: linear-gradient(199.18deg, #ff576d 12.9%, #fb913a 162.35%);
    transform: rotate(45deg);
    transition: scale 0.5s ease;
    cursor: pointer;

    &:hover {
      background: linear-gradient(199.18deg, #ff576d 12.9%, #fb913a 162.35%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }

    .actionPlusIcon {
      display: flex;
      transform: rotate(45deg);
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: $white;
        }
      }
    }
  }

  .actionMenuBtn:hover {
    transform: scale(1.125) rotate(45deg);
  }
}

.quickActionsModal {
  .header {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.4699999988079071px;
    text-align: center;
  }

  .actionsContent {
    .quickActionItem {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: setUnit(16);
      border-radius: 12px;
      padding: setUnit(20) setUnit(24);
      cursor: pointer;

      .actionTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        margin: 0;
      }

      &.orange {
        background: #fb913a14;

        &:hover {
          background: #fa913b1f;
        }
      }

      &.confettiBlue {
        background: #4673d114;
        &:hover {
          background: #4571d11f;
        }
      }

      &.lightBlue {
        background: #51bfe114;
        &:hover {
          background: #51bfe11f;
        }
      }

      &.purple {
        background: #928cda14;
        &:hover {
          background: #928cda1f;
        }
      }
      &.green {
        background: #04a45114;
        &:hover {
          background: #04a4511f;
        }
      }
    }
  }
}
