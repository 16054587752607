@import "styles/functions";
@import "styles/variables";

.item {
    display: flex;
    align-items: center;
    margin-bottom: setUnit(30);

    &:last-child {
        margin-bottom: 0;
    }

    .filename {
        flex: 1;
        padding-left: setUnit(40);
        font-weight: 600;
    }

    .fileSize {
        display: inline-block;
        font-size: setUnit(14);
        margin-left: setUnit(12);
        color: $border-middle-gray;
    }

    .icon {
        min-width: setUnit(56);
        height: setUnit(56);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $background-ligh-gray;
        margin-right: setUnit(16);
    }

    .input {
        max-width: setUnit(204);
    }

    .iconDelete {
        cursor: pointer;
        transition: transform .1s ease;
        margin-right: setUnit(5);

        &:hover {
            transform: scale(1.1);
        }
    }
}
