@import 'styles/functions';
@import 'styles/variables';

@mixin formWidth {
  max-width: 540px;
}

@mixin formControls {
  position: absolute;
  top: 34px;
  right: 40px;
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: setUnit(50) setUnit(10);
  position: relative;

  .inner {
    margin: auto 0;
    max-width: 540px;

    .answersWrapper {
      margin: setUnit(32) 0 setUnit(24) 0 !important;
      max-width: initial !important;
    }

    .answer {
      border-radius: setUnit(8) !important;
      box-shadow: none !important;
      border: 2px solid $light-gray !important;
      cursor: pointer;
      margin-bottom: 1rem !important;

      .label {
        font-weight: 400 !important;
        color: $dark-grey-color !important;
        font-size: 18px !important;
        line-height: 24px !important;
        margin-top: -2px !important;
      }

      &:hover {
        border: 2px solid $text--light-gray-color !important;
        color: $text-color !important;
      }
    }

    .answerActive {
      background-color: $primary-color-light !important;
      border: 1px solid $primary-color !important;

      .label {
        font-weight: 500 !important;
        color: $text-color !important;
      }

      &:hover {
        border: 1px solid $primary-color-hover !important;
        background-color: #ffebed !important;
      }
    }

    .bottom {
      position: absolute;
      bottom: 0;
      left: calc(50% - 15px);
      transform: translateX(-50%);
      height: 116px;
      max-width: 540px;
      width: 100%;
      padding: 1.5rem 0 2.5rem 0;
      background-color: #fff;
      @media (min-width: $mobile) {
        left: 50%;
      }
    }
  }
}

.controls {
  @include formWidth;
  @include formControls;
}
