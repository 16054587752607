@import 'styles/functions';
@import 'styles/variables';

.mapModal {
  width: 100%;

  .bottomBar {
    position: relative;
    height: calc(100vh - setUnit(180));
    border-radius: setUnit(8);
    overflow: hidden;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      margin-left: setUnit(32);
      margin-right: setUnit(32);
    }

    .map {
      height: setUnit(164);

      .mapControls {
        margin: setUnit(18);
      }
    }
  }
}
