@import 'styles/functions';
@import 'styles/variables';

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.pageContent {
  max-width: 400px;
  margin: 0 auto;
}

.copyRecipientsRow {
  width: 100%;
  margin-bottom: 20px;
}

.listBoxInput {
  width: 100%;
  border: 1px solid $border-light-gray-2 !important;
  border-radius: 8px;
  background-color: $white;

  [class*='ant-select-selector'] {
    height: 48px !important;
    align-items: center;
    border: none !important;

    [class*='ant-select-selection-placeholder'] {
      font-weight: 400;
      font-size: setUnit(16);
      color: $text--light-gray-color !important;
    }

    [class*='ant-select-selection-search'] {
      font-weight: 400;
      font-size: setUnit(16);
    }

    [class~='ant-select-selection-item'] {
      color: $text-color;
      font-size: setUnit(16);
    }

    [class*='ant-select-selection-search-input'] {
      height: 48px !important;
      color: $text-color;
    }
  }

  &:focus {
    border-color: $text--light-gray-color !important;
  }
}

.copyHelper {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 32px;

  [class*='ant-typography'] {
    color: $dark-grey-color !important;
  }
}

.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  color: $dark-color;
  height: 2rem;
  padding: 0.5rem 0.125rem;
  width: fit-content;

  .addIcon {
    line-height: 0;
  }

  .addText {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    border-radius: 0.5rem;
    background: $background-grey-2;

    color: $text-color;

    .addIcon {
      path {
        stroke: $text-color;
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: -16px;
  border: 2px solid $light-gray;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  background-color: #fff;

  svg {
    width: 24px;
    height: 24px;
  }
}

.fieldWrapper {
  border: 1px solid $light-gray;
  border-radius: 8px;

  padding: 20px 20px 24px;

  background-color: $background-ligh-gray-5;
  width: 100%;
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.field {
  .label {
    margin-bottom: 12px;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
}
