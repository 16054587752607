@import 'styles/functions';
@import 'styles/variables';

.inviteTransactionModal {
  width: setUnit(675) !important;
  [class='ant-modal-content'] {
    [class='ant-modal-close'] {
      top: setUnit(32);
    }
  }
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(32);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: setUnit(32);
    color: $text-color;
  }

  [class='ant-modal-content'] {
    padding: setUnit(32) setUnit(40);
    margin: 4rem 0;
  }

  .modalFooter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: setUnit(32);
    .submitButton {
      border-radius: 8px !important;
      background-color: $secondary-color !important;
      font-size: setUnit(16) !important;
      font-weight: 600 !important;
      line-height: setUnit(20) !important;
      padding: setUnit(12) setUnit(32);
      height: setUnit(52);
      max-width: setUnit(152);
      width: 100%;
      &:hover {
        background: $secondary-color-hover !important;
      }
      .submitButtonLoader {
        font-size: setUnit(3);
        margin: 0;
      }
    }
  }
}
