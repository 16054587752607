@import 'styles/functions';
@import 'styles/variables';

.ascDesc {
  display: flex;
  align-items: center;
  margin: setUnit(16) 0 setUnit(25);

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    width: 100%;
    padding: setUnit(8) setUnit(16);
    background: none;
    border: 1px solid $border-middle-gray;

    $border-radius: setUnit(8);

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      &:not(.active) {
        border-right-color: transparent;
      }
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      &:not(.active) {
        border-left-color: transparent;
      }
    }

    &.active {
      background: #fff4f3;
      border-color: $primary-color;
    }

    .text {
      font-weight: 500;
      font-size: setUnit(12);
    }

    svg {
      vertical-align: middle;
      position: relative;
      left: setUnit(-7);
    }
  }
}
