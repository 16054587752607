@import 'styles/functions';
@import 'styles/variables';

.widgetHeader {
  display: flex;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.33px;

  .headerText {
    cursor: pointer;
  }
}

.filterDropdown {
  background: $white;
  box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.06), 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: setUnit(8);
  max-width: setUnit(200);
  width: 100%;
  overflow: hidden;
  padding-top: 0 !important;

  div[class~='ant-popover-content'] {
    padding: setUnit(12) 0;
    div[class~='ant-popover-inner'] {
      background-color: transparent;
      box-shadow: none;
      div[class~='ant-popover-title'] {
        border-bottom: 0;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: setUnit(-0.18);
        color: $text-color;
        padding: setUnit(8) setUnit(24);
      }
      div[class~='ant-popover-inner-content'] {
        padding: 0;
        .filterOptions {
          margin: 0;
          list-style-type: none;
          .sortCheckbox {
            display: flex;
            flex-direction: row-reverse;
            padding: setUnit(11) setUnit(24);
            background-color: $white;
            transition: $transition;
            &:hover {
              background-color: $background-grey-2;
            }
            .checkboxHeading {
              font-weight: 600;
              font-size: setUnit(16);
              line-height: setUnit(16);
              color: #262626;
              display: flex;
              justify-content: space-between;
              padding-bottom: setUnit(8);
            }
            .checkboxSubHeading {
              font-weight: 400;
              font-size: setUnit(14);
              line-height: setUnit(16);
              color: #515151;
            }
            span[class~='ant-checkbox'] {
              span[class~='ant-checkbox-inner'] {
                width: setUnit(24);
                height: setUnit(24);
                border: 0;
                background-color: transparent;
                &:after {
                  width: setUnit(6.67);
                  height: setUnit(10);
                }
              }
              &[class~='ant-checkbox-checked'] {
                span[class~='ant-checkbox-inner'] {
                  border: 0;
                  background-color: transparent;
                  &:after {
                    border-color: $hover-green;
                    width: setUnit(6.67);
                    height: setUnit(10);
                  }
                }
              }
              &:after {
                content: none;
              }
            }
            span[class~='ant-checkbox'] + span {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              font-weight: 400;
              font-size: setUnit(16);
              line-height: setUnit(20);
              letter-spacing: setUnit(-0.18);
              color: $text-color;
              margin-right: auto;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.dropdown {
  width: auto;
  height: setUnit(32);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(24);
  transition: $transition;
  padding: 0 setUnit(8);
  &[class~='ant-popover-open'] {
    background-color: $light-gray;
  }
  &:hover {
    background-color: $background-grey-2;
    cursor: pointer;
  }

  .switchButton {
    text-decoration: none;
    color: #262626;
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(24);
    margin-right: setUnit(2);
  }
  .icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    svg {
      width: setUnit(12);
    }
  }
}
.switchButton {
  text-decoration: none;
  color: #262626;
  font-weight: 500;
  font-size: setUnit(14);
  line-height: setUnit(24);
  margin-right: setUnit(2);
}
.icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  svg {
    width: setUnit(12);
  }
}
.chartTooltipValue {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #fff !important;
}
.chartTooltipContainer {
  min-width: 169px;
  min-height: 103px;
  padding: 10px 0px 0px 10px;
}
.chartTooltipYear {
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}
.countContainer {
  font-family: Inter;
  font-weight: 700;
  width: fit-content;

  &.clickable {
    cursor: pointer;
  }

  .totalAmount {
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -0.35px;
    color: #04a451;

    @media screen and (max-width: $mobile) {
      font-size: 34px;
      line-height: 42px;
    }
  }
  .count {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.35px;
    color: #04a451;
    display: flex;
    align-items: flex-end;
    margin-left: 4px;

    @media screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 34px;
    }
  }

  &:hover {
    .totalAmount,
    .count {
      color: #049440;
    }
  }
}

.cardWrapper {
  max-height: 188px;

  &:hover {
    box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.08) !important;
  }
}

.cardHeader {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.33px;
}

.cardBody {
  max-height: 170px;
}

.centerSpinner {
  display: flex;
  align-items: center;
  max-height: 150px;
}
