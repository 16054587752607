.filePreviewer {
  text-align: center;
  div {
    width: 100% !important;
    height: 750px;
  }
  object {
    height: 700px !important;
  }
}
