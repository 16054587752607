@import 'styles/functions';
@import 'styles/variables';

.inactiveShowings {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
  }

  .heading {
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .disclaimer {
    margin-top: 4rem;
    text-align: center;
    width: 100%;
  }

  .content {
    max-height: calc(100vh - 4rem - 13rem);
    margin-top: 2.5rem;
    overflow-y: auto;

    .showing {
      border-top: 1px solid $light-gray;
      padding: 1rem 0;
      margin-right: 2rem;
      width: 100%;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid $light-gray;
      }

      .condoIcon {
        display: inline-block;
        margin-right: 0.75rem;
      }

      .details {
        display: inline-block;

        p {
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          margin: 0;
          color: $text-color;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: $text-color;
        }
      }

      .deleteIcon {
        float: right;
        margin-top: 0.6rem;
        cursor: pointer;
      }
    }
  }
}

[class*='ant-popconfirm'] {
  [class*='ant-btn'] {
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  [class*='ant-btn-primary'] {
    border-color: $primary-color;
    background: $primary-color;

    &:hover {
      border-color: $primary-color;
      background: $primary-color;
      color: $white;
    }
  }
}
