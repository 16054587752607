@import 'styles/functions';
@import 'styles/variables';

.sectionContainer {
  height: setUnit(24);
  background: $background-grey-2;
  display: flex;
  align-items: center;

  .letter {
    padding: 0 setUnit(32);
    font-size: setUnit(14);
    font-weight: 600;
    margin-bottom: 0;
  }
}
