@import 'styles/functions';
@import 'styles/variables';

.workshopHeader {
  justify-content: center;
  padding: 16px 24px;

  .workshopActionsContainer {
    margin-top: setUnit(16);

    @media screen and (max-width: $mobile-small) {
      display: flex;
      flex-direction: column;
      margin-top: setUnit(10);
    }
  }

  @media (min-width: $tablet) {
    padding: 30px 40px;
  }
  & [class*='ant-select-arrow'] {
    font-size: 14px;
  }
  .address {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.25rem;
    color: $text-color;
    margin: 0;
    @media screen and (max-width: $mobile) {
      font-size: setUnit(18);
      line-height: setUnit(24);
    }
  }
  .workshopHeaderLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.625rem;

    @media (max-width: $mobile-small) {
      display: flex;
      flex-direction: column;
    }
  }

  .workshopHeaderRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;
    @media (max-width: $mobile-small) {
      display: flex;
      flex-direction: column;
      margin-top: setUnit(10);
    }
  }

  .searchWrapper {
    .inputWrap {
      background: $white;
      border: 2px solid $border-light-gray-2;
      border-radius: setUnit(26);
      width: setUnit(44);
      height: setUnit(44);
      font-size: setUnit(14);
      font-weight: 500;
      color: $text--light-gray-color;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      @media (max-width: 991px) {
        height: setUnit(36);
      }
      &:focus {
        border-width: 2px;
      }
      .icon {
        display: flex;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $dark-color;
          }
        }
      }
      .searchInput {
        background-color: transparent;
        border: 0;
        padding-left: setUnit(8);
        display: none;
        width: 0;
      }
    }
  }

  // @media (max-width: $mobile) {
  //   min-height: 300px;
  // }

  @media (max-width: $mobile-small) {
    min-height: 150px;
  }
}

.workshopSelector {
  text-align: center;
  margin: setUnit(16) 0;
  @media (min-width: $tablet) {
    margin: 0;
  }
}

.quotesActions {
  justify-content: flex-start !important;
}

.quotesActionsArchieve {
  justify-content: flex-end !important;
}
