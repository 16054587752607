@import 'styles/functions';
@import 'styles/variables';

.header {
  padding: setUnit(36) setUnit(32) setUnit(24);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .titleWrapper {
    display: flex;
    align-items: center;
    .drawerHeaderIcon {
      margin-right: setUnit(8);
      svg {
        width: setUnit(32);
        height: setUnit(32);
        path {
          stroke: $primary-color;
        }
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}
