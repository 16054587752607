@import 'styles/functions';
@import 'styles/variables';

.text {
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  color: $text-color;
}

.mapModal {
  width: 100%;

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: setUnit(32);

    .empty {
      visibility: hidden;
    }

    .close {
      border: 2px $background-ligh-gray-4 solid;
      display: flex;
      justify-content: center;
      align-items: center;
      height: setUnit(32);
      width: setUnit(32);
      border-radius: 50%;
      cursor: pointer;

      @media screen and (max-width: $tablet) {
        margin-right: setUnit(32);
      }
    }

    .propertyInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }

      .dot {
        color: $text-gray-color;
        margin-left: setUnit(10);
        margin-right: setUnit(10);

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      &Address {
        font-weight: 600;
      }

      &Price {
        @media screen and (max-width: $tablet) {
          margin-top: setUnit(10);
          margin-bottom: setUnit(10);
        }
      }

      &Secondary {
        display: flex;

        &Beds,
        &Baths {
          margin-right: setUnit(5);
        }
      }
    }
  }

  .bottomBar {
    position: relative;
    height: calc(100vh - setUnit(180));
    border-radius: setUnit(8);
    overflow: hidden;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      margin-left: setUnit(32);
      margin-right: setUnit(32);
    }

    .map {
      height: setUnit(164);

      .mapControls {
        margin: setUnit(18);
      }
    }
    .notFound {
      width: 100%;
      height: 100%;
      background-color: $dark-gray-3;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      color: $white;
    }
  }
}
