@import 'styles/variables';
@import 'styles/functions';

.checkboxOption {
  width: calc(50% - 5px) !important;
  @media only screen and (max-width: 700px) {
    width: 100% !important;
  }
  &.full {
    width: 100% !important;
  }
  margin-bottom: 0 !important;
  height: 60px !important;
}
