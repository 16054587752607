@import 'styles/mixins';
@import 'styles/variables';

.truncatedContentContainer {
  .content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .expandBtn {
    color: $link-blue;
  }
}
