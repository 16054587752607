@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.parties {
  margin-right: setUnit(24);

  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    padding: setUnit(16) setUnit(24);

    background-color: $background-ligh-gray-5;

    .radioButtons {
      .container {
        [class*='ant-radio-wrapper'] {
          font-size: setUnit(16);
          font-weight: 400;
          line-height: setUnit(20);
          letter-spacing: -0.18px;
          align-items: center;
          [class*='ant-radio'] {
            top: 0px;
            & + span {
              padding-left: setUnit(12);
              padding-right: 0;
            }
            &[class*='ant-radio-checked'] {
              [class*='ant-radio-inner'] {
                border-color: $primary-color !important;
              }
              ~ span {
                color: $text-color;
              }
            }

            [class*='ant-radio-inner'] {
              padding-left: 0;
              width: setUnit(24);
              height: setUnit(24);
              border-radius: setUnit(100);
              border: 1.5px solid $text--light-gray-color;
              box-shadow: unset;
              &:after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzMyNTIgNC45OTk4NEw0LjY2NTg1IDguMzMzMTdMMTEuMzMyNSAxLjY2NjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                width: 100%;
                height: 100%;
                background-color: $primary-color;
                border-radius: 16px;
                transform: scale(1);
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                top: 0;
                left: 0;
                margin-top: 0;
                margin-left: 0;
              }
            }
            ~ span {
              color: $text-gray-color;
            }
          }
          &:hover {
            [class*='ant-radio-inner'] {
              border-color: $primary-color !important;
            }
          }
        }
      }
    }
  }
}
