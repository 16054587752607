@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
  width: 100%;
  flex: 1 1 auto;
  background-color: $secondary-color-hover;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('./components/Background/BG.svg');
  background-size: cover;

  .contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;

    position: relative;
    margin-top: -108px;
    // top: 200px;
    // left: 0;
    // right: 0;

    @media screen and (max-width: $mobile) {
      padding: 40px 0;
    }

    @media screen and (max-height: 800px) {
      margin-top: -80px;
    }
  }

  .backBtn {
    border: 1.5px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 12%;
    left: 40px;
    cursor: pointer;

    &:hover {
      border: rgba(255, 255, 255, 0.6);
    }

    @media screen and (max-width: $mobile) {
      left: 25px;
    }
  }
}
