@import 'styles/functions';
@import 'styles/variables';

.widgetHeader {
  display: flex;
  justify-content: space-between;
  height: 30px;
  .heading {
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.33px;
  }
}

.card {
  [class*='ant-card'] {
    height: 140px !important;
  }
  [class*='ant-card-body'] {
    max-width: 47vw;
  }
}

.countContainer {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-weight: 700;

  .count {
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -0.35px;
    color: $confetti-blue;
    &.lightBlue {
      color: $light-blue;
    }
    &.purple {
      color: $purple;
    }
    &.yellow {
      color: $orange;
    }

    @media screen and (max-width: $mobile) {
      font-size: 34px;
      line-height: 42px;
    }
  }

  .subtext {
    display: flex;
    align-self: center;
    padding-top: 20px;
    padding-left: 5px;
    .subCount {
      color: $green-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.35px;
      margin: 0;
      position: relative;
      bottom: 2px;
      left: 2px;
      @media screen and (max-width: $mobile) {
        font-size: 10px;
      }
    }
  }
}

.centerSpinner {
  display: flex;
  align-items: center;
  max-height: 95px;
}
