@import 'styles/variables';

.avatarRow {
  display: flex;
  align-items: center;

  .avatarName {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.18000000715255737px;
  }
}
