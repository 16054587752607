@import 'styles/functions';
@import 'styles/variables';

h4 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.detailArea {
  overflow: auto;
  padding: 15px 15px;
}

.card {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding: 24px 24px 28px;
}
