@import 'styles/functions';
@import 'styles/variables';

.updatePrefsBlock {
  position: inherit;
  height: 284px;
  justify-content: center;
  display: flex;
  align-items: center;

  .updatePrefsHeading {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.33px;
    color: $dark-color;
  }

  .updatePrefsText {
    position: absolute;
    top: 157px;
    color: $text-gray-color;
    max-width: 30rem;
    text-align: center;
  }

  .divider {
    position: absolute;
    width: 520px;
    height: 1px;
    justify-content: center;
    top: 20rem;
    background: $light-gray;
  }

  .showAllBtn {
    &[class*='ant-btn'] {
      position: absolute;
      display: flex;
      height: 44px;
      top: 28rem;
    }

    border-radius: 64px !important;
    background-color: $white !important;
    color: $dark-color !important;
    font-weight: 600 !important;
    top: 251px;
    align-items: center;

    &:hover {
      background-color: $white !important;
    }
    &:focus {
      border-color: $primary-color;
    }
  }

  .showAllText {
    position: absolute;
    top: 381px;
    color: $text-gray-color;
    max-width: 30rem;
    text-align: center;
  }
}

.criteriaBtn {
  &[class*='ant-btn'] {
    position: absolute;
    display: flex;
    height: 44px;
    top: 14rem;
  }

  border-radius: 64px !important;
  background-color: $white !important;
  color: $dark-color !important;
  font-weight: 600 !important;
  top: 251px;
  align-items: center;

  &:hover {
    background-color: $white !important;
    border-color: $primary-color !important;
  }
  &:focus {
    border-color: $primary-color !important;
  }

  .filterIcon {
    margin-top: 0.15rem;
    margin-right: 0.25rem;
  }
}
