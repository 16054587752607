@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  color: $dark-color;
  font-size: setUnit(14);
  line-height: setUnit(20);
  margin-bottom: setUnit(24);
}

.title {
  color: $text-color;
  font-weight: 600;
  padding-bottom: setUnit(10);
  word-break: normal;
}

.items {
  padding-left: setUnit(24);

  li {
    word-break: break-word;
  }
}

.value {
  word-break: break-word;
}
