@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contactsView {
  margin-top: 40px;

  .contactsHeader {
    margin: 0px 40px;

    .title {
      font-size: setUnit(30);
      font-weight: 700;
      line-height: setUnit(36);
      letter-spacing: -0.64px;
    }
    .subHeading {
      margin-top: 2rem;
      font-size: setUnit(20);
      font-weight: 600;
      line-height: setUnit(26);
      letter-spacing: -0.64px;
    }
  }
}

.cardView {
  margin-top: 20px;
  padding: 0 setUnit(24);

  .noContacts {
    height: calc(100vh - 280px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: $text--light-gray-color;
    margin-bottom: 100px;
    max-height: 248px;
  }

  .contactInfo {
    text-align: right !important;
  }
}
