@import 'styles/functions';
@import 'styles/variables';

.messageFieldWrapper {
  margin: 0px 20px;
}
.messagesForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - #{setUnit(63)});

  .content {
    width: 100%;
    flex: 1;
    overflow: auto;
  }

  .footer {
    padding: setUnit(26) setUnit(32);

    .footerInner {
      display: flex;
      align-items: center;
    }

    .sendInputWrapper {
      margin: 0;

      .inputField {
        background: #ffffff;
        padding: setUnit(15) setUnit(65) setUnit(15) setUnit(15);
        border: none;
        max-width: 100%;
      }
    }

    .inputGroup {
      position: relative;
      width: 100%;
      max-width: 80%;
    }

    .inputFieldWrapper {
      border-radius: setUnit(24);
      border: 1px solid $border-middle-gray;
      height: fit-content;
      overflow: hidden;
      background: #ffffff;
    }

    .sendButton {
      position: absolute;
      bottom: setUnit(4);
      right: setUnit(4);
      width: setUnit(42);
      height: setUnit(42);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s;
      background: $secondary-color;

      &:hover {
        opacity: 0.9;
        transform: scale(1.05);
      }

      svg {
        vertical-align: middle;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}
