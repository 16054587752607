@import 'styles/variables';
@import 'styles/mixins';

.mainPageContainer {
  padding: 0px !important;

  background-color: $background-ligh-gray;
}

.mainContainer {
  @media screen and (max-width: 915px) {
    width: fit-content;
  }
}

.dynamicViewerPageContainer {
  .sendDocumentLayoutContent {
    background-color: $white;
    .sendDocumentPageContent {
      min-height: calc(100dvh - 80px) !important;

      .sendDocument {
        background-color: white !important;
        width: unset;
        min-height: calc(100dvh - 80px) !important;
      }
    }
  }
}

.documentPage {
  margin: 20px 0 16px 0px !important;

  .pdfPage {
    z-index: 1;
    width: fit-content;
    margin: 0 auto;
  }

  canvas {
    border-radius: 0.5rem !important;
  }

  @media screen and (max-width: 768px) {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

.pageScrollwiseDocument {
  margin-bottom: -50px !important;
  padding: 40px 0 20px 0px !important;

  &:first-child {
    margin-top: -50px !important;
  }
}

.dynamicViewerPDFDocument {
  min-height: 100% !important;
}

.pdfDocument {
  z-index: 1;
  position: relative;
}

div > .webViewSendDocumentPageContainer {
  height: 100dvh !important;
}

div > .sendDocumentPageContainer {
  height: 100% !important;
}

div > .webViewPageContainer {
  height: calc(100dvh - 100px) !important;
}
