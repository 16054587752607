@import 'styles/mixins';
@import 'styles/variables';

$addAnotherItemMargin: var(margin);

.buyerPreferenceImportantFeatures {
  margin-top: 0.75rem;
  .collapseStyle {
    div[class~='ant-collapse-header'] {
      padding-left: 0;

      div[class~='ant-collapse-arrow'] {
        right: 0;
      }
    }
    div[class~='ant-collapse-content'] {
      div[class~='ant-collapse-content-box'] {
        padding: 0;
      }
    }
  }

  .heading {
    color: $text-color;
    font: {
      weight: 600;
      size: 16px;
    }
  }
  .addAnother {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: setUnit(20) 0;
    .addIcon {
      height: 24px;
    }
    span {
      margin-left: 12px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .popoverOverlay {
    width: setUnit(156);
    div[class~='ant-popover-inner'] {
      border-radius: setUnit(8);
      div[class~='ant-popover-inner-content'] {
        padding: 0px;
      }
    }
  }

  .softCollapse {
    margin: 0;
    padding: 0;
  }
}
