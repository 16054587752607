@import 'styles/functions';
@import 'styles/variables';

.partner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: setUnit(12) 0;
  .left {
    margin-right: 12px;
    max-width: calc(100% - 86px);
    width: 100%;
    display: flex;
    align-items: center;
    .avatarWrapper {
      margin-right: 16px;
      cursor: pointer;
      .avatar {
        width: setUnit(48);
        height: setUnit(48);
      }
    }
    .businessName {
      cursor: pointer;
    }
  }
}
