@import 'styles/functions';
@import 'styles/variables';

.matchScoreWrapper {
  font-size: setUnit(14);
  font-weight: 600;
  color: #ffffff;
  width: setUnit(98);
  height: setUnit(32);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(16);
  cursor: pointer;
  min-width: setUnit(90);
  .priceReductionsMatch {
    padding: setUnit(10);
    width: auto;
    height: auto;
    min-width: unset;
  }
  &.green {
    background: $green-color;
  }

  &.orange {
    background: $orange;
  }

  &.gray {
    background: rgba(0, 0, 0, 0.4);
  }

  .text {
    font-size: setUnit(12);
    font-weight: 400;
    display: inline-block;
    margin-left: setUnit(4);
  }
}
