@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.lightFull {
    &.locations {
        &.disabled {
            opacity: .8;
            pointer-events: none;
            z-index: 99;
        }
    }
}
