@import 'styles/variables';
@import 'styles/mixins';
@import '../../mixins.module.scss';

.highlights {
  .highlightsBox {
    padding: 0;

    .headerIcon {
      padding: setUnit(24) setUnit(24) 0px;
    }

    .content {
      margin-top: setUnit(28);
      padding-bottom: setUnit(20);

      .inputBlock {
        @include inputBlock;
        padding: 0px setUnit(24);

        .buyerInput {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .iconText {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 12px;

            .addIcon {
              line-height: 0;
            }

            .addText {
              font-size: setUnit(14);
              font-weight: 600;
              line-height: 16px;
              letter-spacing: -0.18px;
              color: $text-gray-color;
            }
          }

          .removeIcon {
            cursor: pointer;
            margin-bottom: 12px;
            line-height: 0;
          }
        }

        .questionInput {
          border: 1px solid $light-gray;
          border-radius: 8px;
          margin-top: 16px;
          padding: setUnit(24);
          background-color: $background-ligh-gray-5;

          .questionInputLabel {
            font-size: setUnit(14);
            font-weight: 600;
            line-height: 16px; /* 114.286% */
            letter-spacing: -0.09px;
            margin-bottom: 12px;
            color: $text-color;
          }

          &LabelSecondary {
            margin-top: 20px;
          }
        }

        .contingencies {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          .checkbox {
            @include checkboxBlock;

            [class~='ant-checkbox'] {
              & + span {
                display: flex;
                align-items: center;

                .static {
                  color: $dark-color;

                  &:first-child {
                    width: 80px;
                  }

                  &:last-child {
                    max-width: 37px;
                  }
                }

                .daysInputHolder {
                  @include daysInputHolder;
                }
              }
            }

            &[class~='ant-checkbox-wrapper-checked'] {
              [class~='ant-checkbox'] {
                & + span {
                  .static {
                    color: $text-color;
                  }
                }
              }
            }
          }
        }

        .waived {
          background: $background-grey-2;
          padding: 8px 10px;
          border-radius: 40px;
          color: $dark-color;

          margin-left: 10px;
          text-transform: capitalize;

          font-size: setUnit(14);
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.18px;
        }

        .inputHolder {
          [class*='inputField'] {
            padding-left: 0px !important;
          }

          .inputHolderDropdown {
            [class~='ant-select-item-option'] {
              padding: 16px 24px !important;
              height: setUnit(48);
              display: flex;
              align-items: center;
              [class~='ant-select-item-option-content'] {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.09px;
                color: $dark-color;
                padding: 0;
              }
              &[class~='ant-select-item-option-selected'] {
                background-color: $background-grey-2 !important;
              }
              [class~='ant-select-item-option-state'] {
                display: none;
              }
            }
          }

          .closingDate {
            input {
              @include inputLightInnerInput();
            }
          }

          .popupCalender {
            [class*='ant-picker-cell-inner'] {
              font-weight: 500 !important;
            }

            [class*='ant-picker-header'] {
              [class*='ant-picker-header-super-next-btn'] {
                display: inline-block;
              }
            }
          }
        }

        .yesNoContainer {
          display: flex;
          align-items: center;
          margin-top: 20px;
          gap: 10rem;

          @media (max-width: $mobile-small) {
            gap: 5rem;
          }
        }

        .yesOptionContainer {
          gap: 2.2rem;

          @media (max-width: $mobile-small) {
            gap: 1.5rem;
          }

          .yesNoOption {
            &:first-child {
              height: auto;
            }
          }
        }

        .yesNoOption {
          margin-bottom: 0px !important;

          .yesNoInputHolder {
            @include daysInputHolder;
          }

          @include checkedRadio;
        }
      }

      .fieldSeparator {
        margin: setUnit(40) setUnit(24) setUnit(24);
        height: 1px;
        background-color: $background-ligh-gray-4;
      }

      .showAllContainer {
        border-top: 1px solid $background-ligh-gray-4;
        border-bottom: 1px solid $background-ligh-gray-4;
        padding: setUnit(24) 0px;
        margin: 0px setUnit(24) setUnit(24);

        display: flex;
        align-items: center;
        gap: 12px;

        cursor: pointer;

        .showAllIcon {
          line-height: 0;
        }

        .showAllText {
          font-size: setUnit(14);
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: $text-color;

          .fieldsCount {
            color: $text--light-gray-color;
            font-weight: 600;
            font-size: setUnit(14);
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}
