@import 'styles/functions';
@import 'styles/variables';

.commutes {
  border: 2px solid $light-gray;
  padding: 20px;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  .commuteHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 20px;
    @media (min-width: $mobile-small) {
      justify-content: space-between;
    }
    .commuteClose {
      flex: 0 0 setUnit(24);
      order: 1;
      @media (min-width: $mobile-small) {
        order: 2;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: setUnit(6);
    flex: 0 0 100%;
    order: 2;
    @media (min-width: $mobile-small) {
      order: 1;
      flex: 0 0 calc(100% - #{setUnit(30)});
    }
  }
  .locationsInput {
    width: 100%;
    margin-bottom: setUnit(24);
  }
  .locationType {
    margin-top: setUnit(24);
    .inputMaxBorderBottom {
      border: 0 !important;
      border-bottom: 2px solid $border-light-gray-2 !important;
      border-radius: setUnit(0) !important;
      background: transparent;
    }
  }
  .activeLocationsInput {
    input {
      font-size: 16px;
      margin-top: -4px;

      &::placeholder {
        font-size: 16px;
      }
    }
  }
  .valuesWrapper {
    padding-left: 0;

    div {
      font-size: 16px !important;
    }
  }
  .transportationSelectWrapper {
    margin: 0;
  }
  .inputWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .innerLabel {
      margin-right: 5px;
      font-weight: normal;
      width: 100%;
      @media (min-width: $mobile-small) {
        width: setUnit(52);
      }
    }
    .inputField {
      margin: 5px;
      label {
        margin-bottom: 0;
      }
    }
    .inputMaxBorderBottom {
      border: 0 !important;
      border-bottom: 2px solid $border-light-gray-2 !important;
      border-radius: setUnit(0) !important;
      background: transparent;
    }
    &.preferenceWrapper {
      .timeSelectWrapper {
        max-width: calc(100% - #{setUnit(96)});
        width: 100%;
        margin: 0;
        @media (min-width: $mobile-small) {
          max-width: calc(100% - #{setUnit(173)});
        }
      }
      .inputFieldMinutes {
        max-width: 80px;
        width: 100%;
        margin: 0;
        margin-right: 16px;
        @media (min-width: $mobile) {
          max-width: 100px;
        }
        input {
          text-align: center;
          padding: 16px 0 12px;
          width: 100% !important;
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    @media (min-width: $mobile) {
      justify-content: space-between;
    }
    .rushHour {
      width: 100%;
      margin-bottom: setUnit(24);
      @media (min-width: $mobile) {
        margin-bottom: 0;
        width: auto;
      }
      span {
        font-weight: normal;
      }
      .rushHourTitle {
        margin-right: 10px;
      }
    }
    .saveButton {
      background-color: $background-blue !important;
      padding: 12px 32px;
      width: auto;
      height: 44px;
      display: block;
      opacity: 0.35;
      @media (min-width: $mobile) {
        width: auto;
      }
    }
    .active {
      opacity: 1;
    }
  }
}
