@import 'styles/functions';
@import 'styles/variables';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .nextBtn {
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    font-weight: 600 !important;
    line-height: setUnit(20) !important;
    padding: setUnit(14) setUnit(54);
    height: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nextBtnEnabled {
    background-color: $secondary-color !important;
    opacity: 1;

    &:hover {
      background: $secondary-color-hover !important;
      border-color: unset;
    }

    &:focus {
      border-color: $secondary-color;
    }
  }

  .nextBtnDisabled {
    background-color: $secondary-color !important;
    opacity: 0.5;

    &:hover {
      background-color: $secondary-color !important;
    }
  }

  .largButton {
    width: 180px;
  }
  .submitBtn {
    width: auto;
    background-color: $secondary-color !important;

    &:hover {
      background: $secondary-color-hover !important;
    }
  }
}
