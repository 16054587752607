@import 'styles/functions';
@import 'styles/variables';

.detailsArea {
  p {
    margin-bottom: 0.25rem;
  }
}

.noteAuthor {
  font-weight: 600;
  font-size: 14px;
}

.noteDateTime {
  color: $text-gray-color;
  font-weight: 400;
  font-size: 12px;
}

.title {
  font-weight: 600;
}

.note {
  font-weight: 400;
  font-size: 14px;
}
