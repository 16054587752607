@import 'styles/variables';

.taskUpdateModal {
  width: 595px !important;

  [class*='ant-modal-header'] {
    margin-top: 8px;

    [class*='ant-modal-title'] {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
    }
  }

  [class*='ant-modal-footer'] {
    margin-top: 24px !important;
  }

  [class*='ant-collapse-header'] {
    padding: 0px !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  [class*='ant-collapse-content'] {
    margin-top: 12px;
  }

  [class*='ant-collapse-content-box'] {
    padding: 0 !important;
  }
}

.panelBox {
  width: 100%;
  height: 100%;
  background-color: $background-ligh-gray-5;
  border: 1px solid $background-ligh-gray-4;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 20px;

  .panelTitle {
    color: $dark-color;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .reassignInputWrapper {
    margin: 8px 0 0 0 !important;
    padding-left: 0 !important;
  }
  .reassignInput {
    padding-left: 0 !important;
  }

  .dueDateInputWrapper {
    margin-bottom: 0 !important;
    margin-top: 18px;
  }

  .statusSelectWrapper {
    margin-top: 10px;

    .statusSelect {
      width: 100%;
      border-bottom: 2px solid $border-light-gray-2;

      [class*='ant-select-arrow'] {
        right: 2px !important;
      }

      [class*='ant-select-selector'] {
        padding: 0 !important;

        [class*='ant-select-selection-search'] {
          left: 0 !important;
        }

        [class*='ant-select-selection-placeholder'] {
          font-size: 18px !important;
          color: $text-gray-color;
        }
      }
    }

    [class*='ant-select-focused'] {
      border-bottom: 2px solid $border-focused !important;
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btnWrapper {
  border-radius: 100%;
  background: $background-grey-2;
  display: flex !important;
  align-items: center;
  width: 32px;
  height: 32px;
  justify-content: center;
}

.trashBtnWrapper {
  @extend .btnWrapper;
  background: $primary-color-light !important;
}

.warningText {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: $error-color;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.divider {
  margin: 18px 0px !important;
  border-top: 1px solid $light-gray !important;
}
