@import 'styles/variables';
@import '../../mixins.module.scss';

.assignConnectionModal {
  & [class*='ant-modal-content'] {
    margin: 2rem 0;
    padding: 2.5rem 3rem 2rem;
    text-align: center;
    box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
  }

  .submitBtn {
    min-width: 13.125rem;
    height: 3.25rem;
    margin-top: 1.5rem;
    border-radius: 8px;
  }
}

.agentRoleConfirmText {
  text-align: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $text-color;
    margin-bottom: 0.75rem;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    color: $dark-color;
    margin: 0;
  }
}
