@import 'styles/variables';
@import 'styles/functions';

.tourCards {
  &.disabled {
    position: relative;
    pointer-events: none;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .tourCard {
    box-shadow: 0px 2px 16px 0px #30303014;
    background-color: $white;
    border-radius: setUnit(16) !important;
    padding-top: setUnit(8);
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    div[class~='ant-collapse-header'] {
      align-items: center;
      flex-wrap: wrap;
      padding: setUnit(13) setUnit(24);
      height: setUnit(64);
      div[class~='ant-collapse-expand-icon'] {
        order: 2;
        div[class~='ant-collapse-arrow'] {
          position: relative;
          top: unset;
          right: unset;
          left: unset;
          transform: translateY(0);
        }
      }
      span[class~='ant-collapse-header-text'] {
        .headerWrap {
          .headerLeft {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .avatar {
              margin-right: setUnit(12);
            }
            .title {
              font-size: setUnit(16);
              font-weight: 600;
              line-height: setUnit(24);
              letter-spacing: setUnit(-0.33);
              margin-bottom: 0;
            }
          }
        }
        .headerRight {
        }
      }
    }
    div[class~='ant-collapse-content'] {
      div[class~='ant-collapse-content-box'] {
        padding: 0 0 setUnit(20);
      }
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .actionsDropdown {
        background-color: $white;
        border-radius: setUnit(8);
        box-shadow: 0px 2px 12px 0px #00000026;
        width: setUnit(176);
        overflow: hidden;
        .actionItem {
          padding: setUnit(12) setUnit(16);
          background-color: #fff;
          transition: $transition;
          &:hover {
            background-color: $background-grey-2;
          }
          span[class~='ant-dropdown-menu-title-content'] {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .icon {
              margin-right: setUnit(8);
              display: flex;
              svg {
                width: setUnit(16);
                height: setUnit(16);
                path {
                  stroke: $primary-color;
                  stroke-width: 2px;
                }
              }
            }
            span {
              font-size: setUnit(14);
              font-weight: 400;
              line-height: setUnit(16);
              letter-spacing: setUnit(-0.09);
              color: $text-color;
            }
          }
        }
      }
    }
  }
}
