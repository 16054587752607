@import 'styles/functions';
@import 'styles/variables';

.description {
  font-weight: 300;
  font-size: setUnit(14);
  text-transform: uppercase;
  color: $border-middle-gray;
  text-align: center;
}
