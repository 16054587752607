@import 'styles/functions';

.sidebar {
  overflow: hidden;
  height: calc(100vh - 230px);
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background: white !important;
}

.sidebarContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.newAreaGroupSectionWrapper {
  margin-top: setUnit(20);
  margin-bottom: setUnit(20);
}

.newAreaGroupButtonWrapper {
  display: flex;
  justify-content: center;
}

.areaGroupsList {
  flex: 1;
  overflow-y: auto;
}
