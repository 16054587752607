@import 'styles/functions';
@import 'styles/variables';

.editTransactionModal {
  width: setUnit(675) !important;

  .heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $text-color;
    margin: 0.5rem 0 1.5rem;
  }

  [class='ant-modal-content'] {
    padding: 2rem 2.5rem;
    margin: 4rem 0;
  }

  .submitButton {
    width: setUnit(152);
    height: setUnit(52);
    font-weight: 600;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .submitButtonLoader {
      font-size: setUnit(3);
    }
  }
}
