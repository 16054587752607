@import 'styles/variables';

.settingPane {
  height: 100%;
  overflow-y: auto;
  .label {
    color: $text-color;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .toggleRow {
    margin-top: 44px;
    align-items: center;
  }
  .formRow {
    margin-top: 24px;
  }

  .checkBoxIcon {
    width: 24px !important;
    height: 24px !important;
  }
  .questionTypeOption {
    display: flex;
    align-items: center;
    .questionLogo {
      margin-right: 10px;
      display: flex;
    }
  }
  .selectBox {
    width: 100%;
    margin-top: 5px;
  }
}
