@import 'styles/functions';
@import 'styles/variables';

.continueBtn {
  width: 100%;
  height: setUnit(52) !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  margin-bottom: 40px;
  .buttonTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.09px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      opacity: 0.5;
    }
  }
}

.skipBtn {
  border: 2px solid $light-gray !important;
  width: 100%;
  height: setUnit(52) !important;
  background-color: $white !important;
  transition: $transition;
  .buttonTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $dark-color;
    transition: $transition;
  }
  &:hover {
    border-color: $text--light-gray-color !important;
    background-color: $white !important;
    .buttonTitle {
      color: $text-color;
    }
  }
}

.skipBtnHollow {
  border: 2px solid transparent !important;
  width: 100%;
  height: setUnit(52) !important;
  background-color: $white !important;
  transition: $transition;
  .buttonTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: $dark-color;
    transition: $transition;
  }
  &:hover {
    border-color: $text--light-gray-color !important;
    background-color: $white !important;
    .buttonTitle {
      color: $text-color;
    }
  }
}
