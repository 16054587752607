@import 'styles/functions';
@import 'styles/variables';

.renameModal {
  [class*='ant-modal-close'] {
    display: none;
  }

  .inputLabel {
    font-size: 14px;
    font-weight: 600;
  }

  [class*='ant-input'] {
    margin-top: 10px;
    width: 16.5rem;
  }

  .inputField {
  }

  .header {
    display: flex;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
  }
}
