@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.status {
  height: setUnit(40);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: setUnit(100);
  position: relative;
  background-color: $background-ligh-gray;
  transition: $transition;
  &:hover {
    background-color: $light-gray;
  }
  &.fullAccess {
    cursor: pointer;
  }

  .accent {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: setUnit(4);
    .statusValue {
      margin-right: 4px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 setUnit(16);
    height: 100%;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    font-weight: 400;
    .arrowIcon {
      margin-left: setUnit(4);
    }
  }
}
