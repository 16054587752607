@import 'styles/variables';

.scheduleWidget {
  padding-bottom: 16px;

  .cardheader {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 11px;

    .leftHeading {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.33px;
      color: #262626;
    }

    .rightHeading {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.33px;
      text-align: center;
      color: #747475;
      display: flex;
      align-items: center;

      .thisWeek {
        width: max-content;
      }
      @media screen and (max-width: $mobile) {
        justify-content: space-between;

        .hideSeparator {
          display: none;
        }

        .thisWeek {
          width: min-content;
          margin-left: 18px;
          margin-right: 14px;
        }
      }
    }
  }

  .emptySchedule {
    height: 292px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .checkMark {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #04a45114;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    & p {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.33px;
      text-align: center;
      color: #aaabab;
    }
  }
}

.cardWrapper {
  height: auto;
  padding: 0;
}

.noDataWrapper {
  height: 360px;
}
