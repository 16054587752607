@import 'styles/functions';

.counter {
  display: flex;
  align-items: center;
  justify-content: center;

  .holder {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .add,
  .remove {
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .number {
    font-size: setUnit(40);
    font-weight: 500;
    min-width: setUnit(83);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &.light {
  }
}
