@import 'styles/functions';
@import 'styles/variables';

.editClient {
  width: 100%;
  .inputWrapper {
    margin: 0 !important;
  }
  .input {
    font-size: setUnit(18) !important;
  }
  .clientInput {
    width: 100%;
  }
}
