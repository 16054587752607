.testClockWrapper {
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner,
.clockContainer {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(45deg, #6a00ff, #ff007e);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 980px;
  font-weight: bold;
}

.fastForwardingBanner {
  width: 100%;
  max-width: 980px;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(45deg, #6a00ff, #ff007e);
  color: white;
  animation: gradientShift 3s infinite alternate;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
.clockInfo {
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
}

@media (max-width: 768px) {
  .clockInfo {
    flex-direction: column;
    gap: 10px;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.fastForwardButton,
.resetButton,
.enableButton {
  background-color: white !important;
  border-color: black;
  color: black !important;
  border-radius: 4px;
}

.fastForwardButton:hover,
.resetButton:hover,
.enableButton:hover {
  background-color: lightgrey !important;
  border-color: black !important;
}

.advancingTime {
  background: linear-gradient(45deg, #6600ff, #ff005e);
  animation: gradientShift 3s infinite alternate;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.campaignFlexCol {
  display: flex;
  flex-direction: column;
}

.campaignFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.clockModal {
  .ant-modal-content {
    padding: 20px;
    text-align: center;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalHeader {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .modalDescription {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .datePickerWrapper {
    width: 100%;
    margin-bottom: 20px;

    .ant-picker {
      width: 100%;
      border-radius: 4px;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .cancelButton {
    border: none;
    color: #555;
  }

  .advanceButton {
    background-color: #722ed1;
    color: #fff;
    border: none;

    &:hover {
      background-color: #531dab;
    }
  }
}
