@import 'styles/variables';

.formPageHeader {
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.64px;
}

.formPageText {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-top: 12px;
  margin-bottom: 40px;
  color: $dark-grey-color;
}
