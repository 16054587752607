.assignedClientWrapper {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  border-radius: 16px;
  border: 1px solid var(--grayscale-10-background, #f4f5f6);
  background: var(--White, #fff);
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  max-width: 964px;
  width: 100%;

  .toggleOuterWrapper {
    display: flex;
    flex-direction: column;
    min-width: 215px;
    align-items: flex-end;
    gap: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .teamPublishText {
    color: var(--Grayscale-80, #515151);
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.18px;
  }

  .toogleWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0px 24px 0px 0px;

    color: var(--Grayscale-100, #262626);
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.33px;

    .toggle {
      margin-left: 8px;

      label {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .assignedClientContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 24px;

    color: var(--Grayscale-80, #515151);
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.33px;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .bluebg {
      background: #252d44;
    }

    .pointer {
      cursor: pointer;
      position: absolute;
      right: -40px;

      opacity: 0;
    }

    .text {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 88.889% */
      letter-spacing: -0.18px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;

      span {
        font-weight: 700;
        margin-left: 4px;
      }

      &:hover .pointer {
        opacity: 1;
      }
    }
  }
}
