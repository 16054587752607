@import 'styles/functions';

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & > * {
    margin-bottom: setUnit(10);
  }
}

.areaRow {
  flex: 1;
  display: grid;
  column-gap: setUnit(10);
  grid-template-columns: 1fr auto auto;
}

.areaTypeSelector {
  flex: 1;

  border: 1px solid #d9d9d9;

  & > .ant-select-selector {
    // Will draw custom border on areaTypeSelector based on area color
    border: none !important;
  }
}

.drawAreaButtonsWrapper {
  min-width: setUnit(70);
}

.addNewAreaButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
