@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  .team {
    .content {
      display: flex;
      align-items: center;

      p {
        width: 250px;
        margin: 0;
      }
    }

    .saveBtn {
      margin-left: 0.5rem;
    }
  }
}

.title {
  font-size: setUnit(20);
  font-weight: 600;
  color: $text-color;
  margin-bottom: setUnit(4);
}

.description {
  font-size: setUnit(14);
  line-height: setUnit(16);
  font-weight: normal;
  color: $text-color;
}

.infoBlock {
  display: flex;
  align-items: center;
  font-size: setUnit(14);
  color: $dark-grey-color;

  .mfaLabel {
    margin-right: 60px;
  }

  .copyLabel {
    margin-right: 38px;
  }

  div label span {
    margin-right: 8px;
    color: $text-color !important;
  }

  .copy {
    display: flex;
    align-items: center;
    flex: 1;
    height: 32px;

    .copyLinkLabel {
      margin-right: 8px;
      color: $text-color;
    }

    .clipboard {
      justify-content: flex-start !important;
      cursor: pointer !important;
    }
  }
}

.marginDiv {
  margin-bottom: 16px;
}

.inputBlock {
  display: flex;
  min-height: setUnit(44);
  align-items: center;
  margin-bottom: setUnit(10);

  .rightBlock {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding-top: setUnit(10);

    .input {
      margin: 0 0 setUnit(10) 0 !important;
    }
  }

  .infoBlockForOneLine {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding: setUnit(10) 0;
    border-bottom: 2px $light-gray solid;
  }

  .infoBlockForMultipleLines {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    border-bottom: 2px $light-gray solid;

    .emailInput {
      top: 0;
      margin: 0;
      margin-left: 0.9375rem;
    }

    .emailInput div {
      top: 0;
    }
  }

  a {
    color: $text-color;
    transition: all 0.1s;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.label {
  flex: 1;
  font-size: setUnit(14);
  color: $dark-grey-color;
  align-self: flex-start;
  padding-top: setUnit(14);
  padding-right: setUnit(8);
}

.inputLabel {
  padding: 0;
  align-self: center;
}

.inputsList {
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: flex-start;
}

.saveBtn {
  height: setUnit(40) !important;
  width: setUnit(100) !important;
  transition: all 0.1s;
  border-radius: setUnit(100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailWrapper {
  display: flex;
  top: 0;
  align-items: center;
  margin-bottom: 0.5rem;
}

.descriptionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(20);
}

.emailVerifyBtn {
  height: setUnit(40) !important;
  width: setUnit(120) !important;
  transition: all 0.1s;
  border-radius: setUnit(100);
  display: flex;
  justify-content: center;
  align-items: center;
}
