@import 'styles/functions';
@import 'styles/variables';

.clientInput {
  .search {
    .value {
      &:after {
        border: none;
      }
    }
  }

  .searchAvatar {
    width: setUnit(32);
    height: setUnit(32);
    position: absolute;
    bottom: setUnit(6);
  }

  .input {
    padding-left: setUnit(40);
  }

  .searchIcon {
    position: absolute;
    top: setUnit(13);
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .option {
    max-height: setUnit(56);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: setUnit(12) setUnit(24);
    transition: $transition;
    text-align: left;

    .optionContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .optionInnerHolder {
      display: flex;
      align-items: center;
      .avatar {
        width: setUnit(32);
        height: setUnit(32);
        margin-right: setUnit(16);
      }
      .name {
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        color: $text-color;
        margin-bottom: setUnit(4);
        .firstName {
          font-weight: 600;
        }
      }
      .role {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        color: $dark-grey-color;
      }
    }
    &:hover,
    &.is-highlighted,
    &.is-selected {
      background-color: $background-grey-2;
    }
  }
}
