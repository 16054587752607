@import 'styles/variables';
@import 'styles/functions';

.basicPlanCreate {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-flow: column wrap;
  @media (min-width: $tablet) {
    position: relative;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px 40px;
    @media (min-width: $tablet) {
      position: sticky;
      background: $white;
      z-index: 1;
      top: 80px;
    }
    @media (max-width: $mobile) {
      padding: setUnit(15) setUnit(15) 0;
    }
  }

  .actions {
    display: inline-block;
  }

  .questionContainer {
    max-width: setUnit(612);
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
      width: 100%;
    }
    [class*='contentWrapper'] {
      height: calc(
        100vh - $header-height - $footer-height - $transaction-creation-header-height - 16px
      );
      & + div {
        padding: 16px 0;
      }
    }
  }
}
