@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.questionHeader {
  align-self: center;
  font-weight: 500;
  font-size: 32px !important;
  color: $secondary-color !important;
  margin-bottom: 40px !important;
  display: block;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.pageContent {
  max-width: 770px;
  margin: 0 auto;
  text-align: center;

  .fieldsDroppable {
    text-align: left;

    .checkboxRow {
      height: 100%;
      .fieldWrapper {
        height: 100%;

        .fieldsContainer {
          display: flex;
          align-items: center;
          gap: 20px;

          .field {
            width: 50%;
            margin-top: 0px;
          }

          @media screen and (max-width: 600px) {
            flex-wrap: wrap;
            gap: 20px;

            .autoCompleteInput,
            .field {
              width: 100%;
            }
          }
        }
      }
    }
  }

  :is(.horizontalListButton, .listSignSequence) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .horizontalListButton {
    display: block;
  }

  .buttonWithoutSequence {
    margin-top: 40px !important;
  }
}

.saveAsTemplatePageContent {
  max-width: 900px;
}

.editSwitch {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.autoCompleteInput {
  width: 100%;
  border: 1px solid $border-light-gray-2 !important;
  border-radius: 8px;
  background-color: $white;

  [class*='ant-select-selector'] {
    height: 48px !important;
    align-items: center;
    border: none !important;

    [class*='ant-select-selection-placeholder'] {
      font-weight: 400;
      font-size: setUnit(16);
      color: $text--light-gray-color !important;
    }

    [class*='ant-select-selection-search'] {
      font-weight: 400;
      font-size: setUnit(16);
    }

    [class~='ant-select-selection-item'] {
      font-weight: 400;
      font-size: setUnit(16);
    }

    [class*='ant-select-selection-search-input'] {
      height: 48px !important;
      color: $text-color;
    }
  }

  &:focus {
    border-color: $text--light-gray-color !important;
  }

  .autoCompleteDropdown {
    z-index: 1;
    [class~='ant-select-item-option'] {
      padding: 16px !important;
      height: setUnit(32);
      display: flex;
      align-items: center;

      [class~='ant-select-item-option-content'] {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.09px;
        color: $dark-color;
        padding: 0;
      }

      &[class~='ant-select-item-option-selected'] {
        background-color: $background-grey-2 !important;
      }

      [class~='ant-select-item-option-state'] {
        display: none;
      }
    }
  }
}

.disabledInput {
  opacity: 50%;
}

.email {
  border-radius: 8px !important;
  outline: none !important;
  height: 48px;
  width: 100%;
  line-height: 52px !important;
  font-weight: 400 !important;
  font-size: setUnit(16) !important;
  color: $text-color !important;
  border-color: $border-light-gray-2 !important;
  background-color: $white;

  &:hover,
  &:focus {
    border-color: $text--light-gray-color !important;
    box-shadow: unset !important;
  }

  &::placeholder {
    font-size: setUnit(16) !important;
    color: $text--light-gray-color !important;
  }

  input {
    font-size: setUnit(16) !important;
  }
}

.checkboxRow {
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    margin-bottom: 25px;
  }
}

.fieldWrapper {
  border: 1px solid $light-gray;
  border-radius: 8px;

  padding: 20px 20px 24px;

  background-color: $background-ligh-gray-5;
  width: 100%;
}

.templateFieldWrapper {
  background-color: unset;
  border: unset;
  padding: unset;
}

.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  color: $dark-color;
  height: 2rem;
  padding: 0.5rem 0.125rem;
  width: fit-content;

  .addIcon {
    line-height: 0;
  }

  .addText {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    border-radius: 0.5rem;
    background: $background-grey-2;

    color: $text-color;

    .addIcon {
      path {
        stroke: $text-color;
      }
    }
  }
}

.iconDelete {
  cursor: pointer;
  margin-left: -25px;
  z-index: 10;
}

.numberTag {
  @include flexCenter;
  background-color: $primary-color;
  color: white;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  position: absolute;
  left: -40px;
  top: 16px;
  font-weight: 700;
  padding: 4px;

  @media screen and (max-width: 600px) {
    top: -10px;
    left: -10px;
  }
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: -16px;
  border: 2px solid $light-gray;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  background-color: #fff;

  svg {
    width: 24px;
    height: 24px;
  }
}

.field {
  .label {
    margin-bottom: 12px;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.signFormField {
  [class~='ant-select-selector'] {
    &::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzc0NzQ3NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
      background-repeat: no-repeat;
      width: 14px !important;
      height: 8px;
      visibility: visible !important;
      position: absolute;
      right: 13px;
    }
  }

  [class~='ant-select-clear'] {
    width: 14px;
    height: 14px;
  }
}

.navigationButton {
  margin-top: 24px !important;
}
