@import 'styles/functions';
@import 'styles/variables';

.propsWrapper {
  position: relative;
  .tabsWrapper {
    height: 100%;
    .tabs {
      border-bottom: none !important;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-bottom: setUnit(30);
      @media (min-width: $tablet) {
        position: absolute;
        margin-top: setUnit(-95);
        padding: 0 setUnit(40);
        height: setUnit(80);
      }
      &.clientTabs {
        @media (min-width: $tablet) {
          margin-top: setUnit(-145);
        }
      }
      .tab {
        height: setUnit(40);
        font-size: setUnit(14);
        font-weight: 400;
        background: $background-ligh-gray;
        padding: setUnit(12) setUnit(24);
        border-radius: setUnit(8);
        color: $text-color;
        &:hover {
          background-color: $light-gray;
        }

        &.tabActive {
          background: $secondary-color;
          color: #ffffff;
        }
      }
      .feedDropdown {
        width: setUnit(200) !important;
      }
    }
    .contentWrapper {
      height: 100%;
      .content {
        height: 100%;
      }
    }
    &.clientTabsWrapper {
      height: calc(100% - #{setUnit(56)});
    }
  }
}
