@import 'styles/variables';

.header {
  background-color: $white;
  padding: 25px 40px;
  border-bottom: 1px solid $light-gray;
  height: $header-height;
  display: flex;
  align-items: center;
  .logo {
    display: flex;
    svg {
      max-width: 136px;
      width: 100%;
    }
  }
}
