@import 'styles/functions';
@import 'styles/variables';

.datePickerContainer {
  position: relative;
  right: 35px;
}

.filterSections {
  & [class*='ant-picker-clear'] {
    svg {
      width: 24px;
      height: 19px;
    }
  }
}

.filterBtn {
  width: fit-content;
  padding: 0 1rem;
  height: setUnit(44);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 2px solid $light-gray;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: $dark-color;

  &.newBtn {
    background-color: $secondary-color;
    color: $white;
    border: none;

    &:hover {
      border: none;
      background-color: 2px solid $secondary-color-hover;
    }
  }

  .addIcon {
    svg {
      path {
        stroke: $white;
      }
    }
  }

  .iconText {
    margin-left: setUnit(5);
  }

  svg {
    width: setUnit(20);
    height: setUnit(20);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border: 2px solid $text--light-gray-color;
  }
  &.btnText {
    width: auto;
    padding: 0 setUnit(16);
  }
}
