@import 'styles/functions';
@import 'styles/variables';

.inner {
  text-align: center;

  .chooseFileBtn {
    width: setUnit(138);
    height: setUnit(56);
    background: #ffffff;
    color: $secondary-color;
    border: 2px solid $secondary-color;
    font-weight: 600;
    margin-top: setUnit(16);

    &:hover {
      background: #ffffff;
    }
  }

  .text {
    color: $dark-grey-color;

    &.main {
      margin: setUnit(20) 0 setUnit(12);
    }
  }
}
.nativeFileInput {
  display: none;
}

.fileInput {
  cursor: pointer;
  display: flex;
}
