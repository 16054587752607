@import 'styles/functions';
@import 'styles/variables';

.autocompleteWrapper {
  width: 100%;
  .autocompleteContainer {
    width: 100%;

    [class*='ant-select-selector'] {
      padding: 0 !important;

      [class*='ant-select-selection-search'] {
        left: 0 !important;

        [class*='ant-select-selection-search-input'] {
          text-overflow: ellipsis;
        }
      }

      [class*='ant-select-selection-placeholder'] {
        font-size: 18px;
        color: $text-gray-color;
      }
    }

    .autocompleteInput {
      padding: 10px !important;
      border-radius: 8px;

      .searchIcon {
        margin-right: 10px;
      }

      box-shadow: none !important;
      outline: none !important;
      border-color: $border-light-gray-2;

      &:hover {
        box-shadow: none !important;
        outline: none !important;
        border-color: $border-light-gray-2;
      }
      &:focus,
      &:active {
        box-shadow: none !important;
        outline: none !important;
        border-color: $text-gray-color;
      }

      input {
        box-shadow: none !important;
        outline: none !important;
        border-color: $border-light-gray-2;

        &:hover {
          box-shadow: none !important;
          outline: none !important;
          border-color: $border-light-gray-2;
        }
        &:focus,
        &:active {
          box-shadow: none !important;
          outline: none !important;
          border-color: $text-gray-color;
        }
      }

      [class*='ant-input'] {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.18px;

        border-color: $border-light-gray-2;
        box-shadow: none;

        &:focus {
          border-color: $text-gray-color;
        }
        &::placeholder {
          color: $text--light-gray-color;
        }

        &:focus {
          box-shadow: none !important;
        }

        input {
          outline: none !important;
        }
      }
    }
  }

  .autoCompleteTyping {
    [class*='ant-select'] {
      pointer-events: none !important;
      background-color: $background-ligh-gray-4 !important;
    }

    [class*='ant-input'] {
      background-color: $background-ligh-gray-4 !important;
    }
  }
}

.suggestionPopupContainer {
  padding: 0 !important;

  & [class*='ant-select-item-option-content'] {
    padding: 6px 4px;
  }
}
