@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: 2rem;
    padding: 0.5rem 0.125rem;
    margin-top: 12px;
    .addIcon {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .addText {
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: -0.01125rem;
      color: $dark-color;
    }
    &:hover {
      border-radius: 0.5rem;
      background: $background-grey-2;
    }
  }

  .disclaimer {
    color: $dark-grey-color;
    margin-top: 2.25rem;
  }

  .customName {
    width: 100%;
  }

  .inviteBtnSpacing {
    margin-bottom: 16px !important;
  }

  .fullAccessContainer {
    .checkbox {
      display: inline-block;
      margin-top: 1.2rem;
      margin-bottom: -5px;
    }
  }

  .roleInputContainer {
    .clientRole {
      margin-top: 12px;
      position: relative;
      input {
        @include input-styles-important;
        @include input-styles;
        @include custom-small-input;
        z-index: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        color: $text-color;
        padding-left: 10px;
        height: 48px !important;
        width: 100%;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          @include input-styles-important;
          @include input-styles;
          color: $text-gray-color !important;
          padding: 10px 0 16px !important;
          margin-left: 10px;
        }
      }
    }

    .inputWrapper {
      margin: 0;
    }

    .inputLabel {
      @include inputLabelContainer;
      color: $text-color;
      margin-bottom: 0;
      font-weight: 600;
    }

    .arrowdownicon {
      right: 0.75rem !important;
      path {
        stroke: $text-gray-color !important;
      }
    }
  }

  .noMarginB {
    margin-bottom: 0 !important;
  }
}

.boxWrapper {
  border: 2px solid $light-gray;
  border-radius: 8px;
  padding: 1.25rem 1.25rem;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .checkboxLabel {
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.00563rem;
    color: $text-color;
  }

  &.marginTop {
    margin-top: setUnit(32);
  }
}

.buttonCon {
  margin-top: 31px !important;
}
