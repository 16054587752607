@import 'styles/variables';
@import 'styles/mixins';

.roleSelectViewWrapper {
  .inputHolder {
    width: 185px;

    [class*='inputField'] {
      padding-left: 0px !important;
    }

    input {
      font-size: setUnit(16);
      height: 100% !important;
    }

    [class~='ant-select-selector'] {
      box-shadow: none !important;
      font-size: setUnit(14) !important;
      height: 40px !important;
      padding: 3px 12px 3px 16px !important;

      border: 2px solid $light-gray !important;
      border-radius: 100px !important;

      &:focus {
        border-color: $text--light-gray-color !important;
      }

      [class~='ant-select-selection-placeholder'] {
        color: $light-gray-2;
        left: 16px;
      }

      [class~='ant-select-selection-search'] {
        left: 16px;
      }

      [class~='ant-select-selection-item'] {
        .optionLabel {
          flex: 1 1;
          overflow: hidden;
          font-weight: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    [class~='ant-select-arrow'] {
      right: 20px;
    }

    [class~='ant-select-open'] {
      [class~='ant-select-selector'] {
        border-color: $text--light-gray-color !important;
      }
    }

    .inputHolderDropdown {
      left: 0px !important;
      min-width: 230px !important;
      width: 100% !important;
      [class~='ant-select-item-option'] {
        padding: 10px !important;
        height: setUnit(48);
        display: flex;
        align-items: center;
        flex-flow: row;

        [class~='ant-select-item-option-content'] {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.09px;
          color: $dark-color;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      [class~='ant-select-item-option-selected']:not(:hover) {
        background-color: unset !important;
      }

      [class~='rc-virtual-list-holder'] {
        overflow-y: auto !important;
      }

      .optionWrapper {
        display: flex;
        align-items: center;
      }

      .checkIcon {
        transition: $transition;
        height: setUnit(20);
        color: $green-color;

        svg {
          width: setUnit(20);
          height: setUnit(20);
          path {
            stroke: $green-color;
          }
        }
      }
    }

    @media screen and (540px < width <= 768px) {
      width: 180px;
    }

    @media screen and (max-width: 540px) {
      width: 150px;
    }
  }

  [class~='ant-select-focused'] {
    [class~='ant-select-selector'] {
      border-color: $text--light-gray-color !important;
    }
  }
}

.roleModal {
  [class~='ant-modal-header'] {
    display: none;
  }

  [class~='ant-btn-primary'] {
    margin-left: 16px !important;
  }
}
