@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.localPopup {
  @include box-shadow;

  border-radius: setUnit(8);
  overflow: hidden;
  position: absolute;
  top: setUnit(48);
  left: 0;
  width: setUnit(464);
  background: #ffffff;
  z-index: 99;

  .content {
    padding: setUnit(32) setUnit(24);
  }
}
