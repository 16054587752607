@import 'styles/functions';
@import 'styles/variables';

.text {
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  color: $text-color;
}

.mapModal {
  width: 100%;

  .bottomBar {
    position: relative;
    height: calc(100vh - setUnit(170));
    border-radius: setUnit(8);
    overflow: hidden;
    justify-content: center;
    align-items: center;

    iframe {
      border-radius: setUnit(8);
    }

    @media screen and (max-width: $tablet) {
      margin-left: setUnit(32);
      margin-right: setUnit(32);
    }

    .map {
      height: setUnit(164);

      .mapControls {
        margin: setUnit(18);
      }
    }
  }
}

.active {
  background: $primary-color !important;
}

.openViewButton {
  display: inline-flex;
  text-decoration: none;
  outline: none;
  font-size: setUnit(14);
  align-items: center;
  cursor: pointer;
  border: 0;
  background: $white;
  padding: setUnit(10) setUnit(14);
  border-radius: setUnit(48);
  transition: all 0.1s;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;

  .openViewButtonIcon {
    margin-right: setUnit(7);
    height: setUnit(24);
    width: setUnit(24);
  }

  span {
    margin-left: setUnit(7);
  }

  &:hover {
    filter: brightness(80%);
  }
}
