@import 'styles/variables';

@mixin subMenu($height, $width, $fontSize, $fontWeight) {
  .menuItemIcon {
    margin-left: 22px;
  }

  :hover,
  :focus {
    background-color: $background-ligh-gray !important;
  }

  & [class*='ant-typography'] {
    font-size: $fontSize;
    font-weight: $fontWeight;
  }

  margin: 0px !important;
  padding: 0px !important;

  width: $height;
  height: $width !important;
  line-height: $width !important;
}
