@import 'styles/variables';
@import 'styles/functions';

.manageRoleAndAccessWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  border-radius: 12px;
  background: $background-grey-2;
  padding: 12px 16px;
  margin-bottom: 20px;

  .flexedItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: $text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: -0.09px;

      ~ div {
        min-width: 198px;

        @media screen and (540px < width <= 768px) {
          min-width: 180px;
        }

        @media screen and (max-width: 540px) {
          min-width: 150px;
        }
      }
    }
  }
}
