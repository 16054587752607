@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.participantWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
  .participant {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .clientInputWrap {
      position: relative;
      display: block;
      .removeButton {
        position: absolute;
        right: 4px;
        top: 74%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
      &:hover {
        .removeButton {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .client {
      [class~='ant-select'] {
        @include custom-small-input;
        margin-top: 12px;

        @media screen and (max-width: 767px) {
          width: setUnit(140);
          min-width: setUnit(140);
        }
      }
      [class~='ant-select-focused'] {
        border: 1px solid $text-gray-color !important;
      }
      [class~='ant-select-selection-placeholder'] {
        @include input-styles-important;
        @include input-styles;
        color: $text-gray-color !important;
        padding: 10px 0 16px !important;
        margin-left: 10px;
      }
      [class~='ant-select-selector'] {
        height: 52px !important;
        input {
          @include input-styles-important;
          @include input-styles;
          color: $text-color;
          padding: 0px 3px 0px 10px;

          &::placeholder {
            font-size: 3px !important;
          }
        }
      }
    }
    .clientRole {
      margin-top: 12px;
      position: relative;
      input {
        @include input-styles-important;
        @include input-styles;
        @include custom-small-input;
        z-index: 0;
        @media screen and (max-width: 767px) {
          width: setUnit(140);
          min-width: setUnit(140);
        }
        color: $text-color;
        padding-left: 10px;
        &:focus {
          border: 1px solid $text-gray-color !important;
        }
        &::placeholder {
          @include input-styles-important;
          @include input-styles;
          color: $text-gray-color !important;
          padding: 10px 0 16px !important;
          margin-left: 10px;
        }
      }
    }

    .inputWrapper {
      margin: 0;
    }
  }
  .iconDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: -16px;
    top: -16px;
    border: 2px solid $light-gray;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.arrowdownicon {
  right: 0.75rem !important;
  path {
    stroke: $text-gray-color !important;
  }
}

.dropdownWrap {
  width: 20% !important;
  min-width: 20% !important;
  @media screen and (max-width: 767px) {
    width: 60% !important;
    min-width: 60% !important;
  }
}

.inputLabel {
  @include inputLabelContainer;
  color: $text-color;
  margin-bottom: 0;
  font-weight: 600;
}
