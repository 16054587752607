@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  z-index: 9999;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: setUnit(252);
  width: 100%;
  @media (max-width: 767.99px) {
    max-width: calc(100% - 240px);
  }
}

.headerAvatar {
  @media screen and (max-width: $responsive-menu) {
    display: none !important;
    visibility: hidden;
  }
}

.headerAvatarName {
  @media screen and (max-width: $responsive-avatar) {
    display: none;
  }
}

.actionElements {
  width: 40px;
  &:not(:last-child) {
    margin-right: setUnit(10);
    @media (min-width: $mobile) {
      margin-right: setUnit(15);
    }
  }
  span[class~='ant-badge'] {
    sup[class~='ant-badge-dot'] {
      top: setUnit(2);
      right: setUnit(2);
    }
    sup[class~='ant-badge-count'] {
      top: setUnit(6);
      right: setUnit(6);
    }
  }
}

.iconElements {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
  height: setUnit(40);
  .icon {
    display: flex;
    align-items: center;
    height: setUnit(32);
    justify-content: center;
    text-align: center;
  }
  .clientsIcon {
    path {
      stroke: $text-color;
    }
  }

  .notificationIcon {
    svg {
      width: setUnit(32);
      height: setUnit(32);
      path {
        stroke: $text-color;
        stroke-width: 1.5px;
      }
    }
  }

  .messagesIcon {
    svg {
      path,
      circle {
        fill: $text-color;
      }
    }
  }

  &:hover {
    background-color: $hover-light-gray;
  }
}

.visitorNavActions {
  @media (max-width: $responsive-menu) {
    display: none;
  }
}
