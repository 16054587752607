@import 'styles/functions';
@import 'styles/variables';

.scrollWrapper {
  height: calc(100vh - #{setUnit(230)});
}

.description {
  margin: setUnit(8) 0 setUnit(12);
}

.submitButton {
  width: setUnit(102);
  margin-left: auto;
  display: block;
}

.block {
  margin-bottom: setUnit(32);

  &:last-child {
    margin-bottom: 0;
  }
}
