@import 'styles/variables';

.compareByYearContainer {
  .title {
    margin-top: 38px;
    margin-bottom: 4px;
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.33px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    padding: 18px 0px;
    .label {
      color: $text-color;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.18px;
    }
  }

  .customLine {
    border-bottom: 1px solid $light-gray;
    margin-top: setUnit(26);
    margin-bottom: setUnit(32);
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .selectMonthsBtn {
      padding: 14px 19px 14px 21px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
}
