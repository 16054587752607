@import 'styles/variables';
@import 'styles/functions';

.transactionActivityLogSection {
  .btnFilterPopover {
    height: 44px;
    width: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-grey-2;
    cursor: pointer;
    position: relative;
    margin-right: 0.5rem;
    font-weight: 600;
    color: $dark-color;
    transition: $transition;
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $light-gray;
    }
    &[class~='ant-popover-open'] {
      background-color: $border-light-gray-2;
    }

    .filterIcon {
      margin-top: 0.25rem;
    }

    .icon {
      transition: transform 0.15s;
      &.asc {
        transform: rotate(180deg);
      }

      &.desc {
        transform: rotate(0deg);
      }

      svg {
        vertical-align: middle;
      }
    }
  }
  .transactionActivityLogCard {
    border-radius: setUnit(16);
    border: 1px solid $background-grey-2 !important;
    box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.08);
    padding: setUnit(20) 0 !important;
    div[class~='ant-card-head'] {
      padding: 0 setUnit(24);
    }
    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
    .transactionActivityLogCardTimelineItem {
      padding: setUnit(12) setUnit(24);
      background-color: $white;
      min-height: setUnit(52);
      transition: $transition;
      &:hover {
        background-color: $background-grey-2;
      }
      .transactionActivityLogCardAvatorWrapper {
        height: calc(100% + 24px);
      }
    }
  }

  .loader {
    height: 150px;
    margin: 15px;
  }
}
