@import 'styles/functions';
@import 'styles/variables';

.form {
  border-radius: 8px;
  border: 1px solid $background-grey-2;
  background: $white;
  padding: 20px;
  .backContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      color: $text-color;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.18px;
      margin: 0;
    }
    svg {
      margin-top: 5px;
    }
  }
  .marginBotton {
    margin-bottom: 20px;
  }
}

.commute {
  background-color: $white !important;
  border: none !important;
  padding: 0 !important;
  padding-top: 20px !important;
}

.criteriaTypeContainer {
  margin: setUnit(15) 0;

  .basicOptionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .titleContainer {
      display: flex;
      align-items: center;
      gap: setUnit(10);
      .title {
        font-size: setUnit(16);
        font-weight: 600;
      }
    }

    .addContainer {
      color: $confetti-blue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.18px;
      margin: 0;
      cursor: pointer;
      span {
        font-size: 20px;
      }
    }
  }

  .additionalOptions {
    background-color: $white;
    padding: setUnit(20) setUnit(24);
    border: 1.5px solid $light-gray;
    border-radius: setUnit(8);
    margin-top: setUnit(15);
    .wrapperCheckbox {
      display: flex;
    }
    .checkboxLabel {
      margin-bottom: 0;
      gap: setUnit(10);
    }
  }
}
