@import 'styles/variables';

.noClient {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 setUnit(32);
  .noClientsText {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    color: $text-gray-color;
    margin-bottom: 0;
  }
}
