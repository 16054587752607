.customRadio {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px solid;
  border-color: var(--Grayscale-20, #e8e9ea);
  background: #fff;
  border-radius: 16px;
  transition: border-color 0.3s;

  &:hover {
    background: var(--Primary-Primary-Red-10, #fff2f3);
    border-color: #ff007f;
  }
}

.customRadioSelected {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px solid;
  background: var(--Primary-Primary-Red-10, #fff2f3) !important;
  border-color: #ff007f !important;
  border-radius: 16px;
  transition: border-color 0.3s;

  &:hover {
    background: var(--Primary-Primary-Red-10, #fff2f3);
    border-color: #ff007f;
  }
}

.customRadioCircle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.customRadioLabel {
  font-size: 16px;
  color: #000;
}
