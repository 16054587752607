@import 'styles/functions';
@import 'styles/variables';

.mainPageContent {
  padding: 0px !important;
  background: $background-ligh-gray-3 !important;
}

.headerRow {
  display: flex;
  align-items: center;
  padding: 38px 40px;
  background: $white;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: $mobile-small) {
    padding: 38px 20px;
  }

  .headerLeft {
    order: 1;
    width: 10%;

    .goBackButton {
      height: 2.75rem !important;
      border: 2px solid $background-ligh-gray-4 !important;
      padding: 0 0.5rem !important;
      &:hover {
        background-color: $background-ligh-gray !important;
      }
    }
    .goBackDisplay {
      display: none;
    }
  }

  .headerTitle {
    font-family: $font-family;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    order: 2;
    text-align: center;
    @media screen and (max-width: $mobile-small) {
      width: setUnit(240);
      margin-left: setUnit(15);
      font-size: 28px;
      text-align: left;
      place-content: unset;
    }
  }

  .headerRight {
    order: 3;
    display: flex;
    align-items: center;
    width: 10%;

    .myQuotes {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.09px;
      padding: 12px 16px;
      border-radius: 100px;
      background: $secondary-color;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 6px;
      right: 40px;
      position: absolute;

      @media screen and (max-width: $mobile-small) {
        right: 20px;
      }

      .myQuotesCountWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        div {
          border-radius: 50%;
          background: $primary-color;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          width: 18px;
          height: 18px;
          display: grid;
          place-items: center;
        }
      }
    }
  }
}

.headerRowWithoutBackBtn {
  justify-content: center;

  .headerTitle {
    margin-left: 10%;
    @media screen and (max-width: $tablet) {
      margin-left: unset;
    }
  }

  @media screen and (max-width: $tablet) {
    justify-content: space-between;
  }
}

.resultRow {
  margin: auto;
  max-width: 810px !important;
  width: 100%;

  @media screen and (max-width: $tablet-horizontal) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
