@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  max-width: setUnit(640);
  min-height: 100vh;
  display: flex;

  .rightBlock {
    padding-left: setUnit(40);
    width: 100%;
  }
}

.title {
  max-width: setUnit(640);
  padding-bottom: setUnit(33);
  margin-bottom: setUnit(33);

  & > h2 {
    font-size: setUnit(24);
    color: $secondary-color;
    font-weight: 600;
    margin-right: setUnit(156);
  }
}

.saveBtn {
  height: setUnit(40) !important;
  width: setUnit(100) !important;
  transition: all 0.1s;
  border-radius: setUnit(100);
}

.editLabel {
  flex: 1;
  font-size: setUnit(14);
  color: $dark-grey-color;
  align-self: flex-start;
  padding-top: setUnit(30);
  font-weight: normal !important;
}

.inputBlock {
  display: flex;
  flex-wrap: wrap;
  min-height: setUnit(44);
  align-items: center;
  margin-bottom: setUnit(10);

  .rightBlock {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding-top: setUnit(10);

    .input {
      margin: 0 0 setUnit(10) 0 !important;
    }

    .tagSelect {
      width: 100%;
      & [class*='ant-select-selector'] {
        border: unset !important;
        border-bottom: 1px solid $border-light-gray-2 !important;
        padding: 0.65rem 0;
        box-shadow: none !important;

        & [class*='ant-select-selection-placeholder'] {
          font-size: 16px;
          color: $text--light-gray-color;
          left: 0;
        }
      }

      & [class*='ant-select-arrow'] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        cursor: pointer;
        path {
          stroke: $dark-color;
        }
      }
    }
  }
}

.selectWrapper {
  margin: 0 !important;
}
