@import 'styles/variables';

.agentName {
  color: $text-color;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;
  margin-bottom: 0px;
}
.amount {
  color: $text-color;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;
}
.requests {
  color: $dark-color;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;
  color: $confetti-blue;

  &:hover {
    cursor: pointer;
    text-decoration-line: underline;
  }
}
.percentage {
  color: $dark-color;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;
}

.BreakdownTable {
  & [class*='ant-table-thead'] {
    background-color: $white;
    border-bottom: 1px solid $light-grey-color;
    position: sticky;
    top: 0;
    background-color: white;
    height: 20px;
    z-index: 1;

    & tr > th {
      background-color: $white;
      color: $text-gray-color;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.18px;
    }
    &
      tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none !important;
    }
  }

  tr > td {
    color: $dark-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.18px;
  }

  .rowWrapper {
    border-radius: 4px;
    border: 1.5px solid;
    cursor: pointer;
  }
}
