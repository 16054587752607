@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.itemVaultContainer {
  display: flex;
  flex-direction: column;
  gap: setUnit(20);

  .contentWrapper {
    border: 2px solid $light-gray;
    border-radius: 8px;
    padding: 1rem;
  }

  .iconDelete {
    svg {
      path {
        stroke: $error-color;
      }
    }
  }

  .uploadedFile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .fileName {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: setUnit(12);
      .fileTitle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 400px;
      }
      .icon {
        width: setUnit(32);
        height: setUnit(32);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $background-grey-2;
        margin-right: setUnit(12);
        svg {
          width: setUnit(24);
          height: setUnit(24);
          path {
            stroke: $dark-color;
          }
        }
      }
      .filename {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        .fileSize {
          margin-left: setUnit(8);
          color: $text--light-gray-color;
          font-weight: 400;
        }
      }
    }
  }

  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.18);
    color: $dark-color;
    margin-bottom: setUnit(12);
  }

  .typeSelectWrapper {
    width: 100%;
    &:hover,
    &:focus,
    &:active {
      border-color: $text-gray-color !important;
      background-color: $light-gray;
    }
    [class~='ant-select-selection-item'] {
      display: flex;
      align-items: center;
      [class*='container'] {
        height: 32px !important;
        width: 32px !important;

        svg {
          width: 24px;
          height: 24px;
        }
      }
      max-width: calc(100% - 17px) !important;
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    [class~='ant-select-selection-placeholder'] {
      color: $text-gray-color !important;
      padding: 17px 0 16px !important;
    }

    [class~='ant-select-focused'] {
      border: 1px solid $text-gray-color !important;
    }

    [class*='ant-select-selector'] {
      border-radius: 8px !important;
      outline: none;
      margin: 0;
      vertical-align: middle !important;
      color: $text-color;
      display: flex;
      align-items: center;
      font-size: 1rem !important;
      font-weight: 400;
      line-height: 1.3rem;
      letter-spacing: -0.09px;
      height: setUnit(48) !important;
      border: 0;
      border: 1px solid $light-grey-color !important;
      transition: $transition;
      &:focus {
        border: 1px solid $text-gray-color !important;
      }
      &::placeholder {
        color: $text-gray-color !important;
        font-weight: 400;
        font-size: 1rem !important;
      }
    }
    &.marginBottom {
      margin-bottom: 1rem;
    }
  }
}

.categoryPopover {
  [class*='ant-select-item-option-selected'] {
    background-color: $background-ligh-gray-3;
  }

  [class*='ant-select-item-option-content'] {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
    [class*='container'] {
      height: 32px !important;
      width: 32px !important;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.permissionSelect {
  [class~='ant-select-focused'] {
    border: 1px solid $text-gray-color !important;
  }
  [class~='ant-select-selection-placeholder'] {
    color: $text-gray-color !important;
    padding: 17px 0 16px !important;
    margin-left: 10px;
  }
  [class~='ant-select-selector'] {
    border-radius: 8px !important;
    padding: setUnit(8) setUnit(13) !important;
    outline: none;
    margin: 0;
    vertical-align: middle;
    color: $text-color;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.3rem;
    letter-spacing: -0.09px;
    height: setUnit(48);
    border: 0;
    border: 1px solid $light-grey-color !important;
    transition: $transition;
    &:focus {
      border: 1px solid $text-gray-color !important;
    }
    &::placeholder {
      color: $text-gray-color !important;
      font-weight: 400;
      font-size: 1rem !important;
    }
  }
  [class~='ant-select-arrow'] {
    right: 0.7rem !important;
    color: $text-gray-color !important;
  }
  [class~='ant-select-selection-overflow'] {
    position: absolute;
    [class~='ant-select-selection-overflow-item'] {
      [class~='ant-select-selection-item'] {
        height: auto;
        margin: 0 setUnit(4) 0 0;
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
      }
    }
  }
}
