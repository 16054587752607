@import 'styles/variables';

.taskSelectionFooterContainer {
  border-top: 1px solid $background-ligh-gray-4;
  display: flex;
  padding: 0px 30px;
  height: 80px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  z-index: 10;

  [class~='container'] {
    padding-left: 0;
    padding-right: 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .taskCountText {
    font-size: 16px !important;
    line-height: 20px;
    font-weight: 600;
  }

  .continueBtn {
    @extend .taskCountText;
    height: 52px;
    width: 134px;
    border-radius: 8px;
  }

  .disabledBtn {
    opacity: 0.5;
  }
}

.transactionTaskFooter {
  padding: 0px 40px 0px 25px;
  left: 288px;
}
