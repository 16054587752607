@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.symphonyPage {
  background-color: $background-grey-2;
  min-height: calc(100vh - 265px);
  .content {
    max-width: 96%;
    width: 100%;
    margin: 0 auto;
    min-height: calc(100vh - $header-height - $footer-height - 230px);
    .symphonyTable {
      position: relative;
      padding: 1.5rem;
      div[class~='ant-table-content'] {
        table {
          border-radius: setUnit(8);
          background-color: #fff;
        }
      }
      th {
        background-color: unset;
        color: $dark-grey-color;
        font-weight: 600;
        span[class~='ant-table-filter-trigger']:hover {
          background-color: transparent;
        }
      }

      th,
      td {
        &:last-child {
          border-right: unset !important;
        }
      }

      & [class*='ant-table-bordered'] {
        border-radius: 1rem;
      }

      & [class*='ant-table-container'] {
        border: unset !important;
      }

      tr {
        &:last-child {
          td {
            border-bottom: unset;
          }
        }
      }

      table {
        border: unset !important;
      }

      .teamField {
        min-width: 250px;
        cursor: pointer;

        .avatar {
          display: inline-block;
          margin-top: -1.75rem;
          margin-right: 0.75rem;
          font-size: 16px !important;
          background-color: $secondary-color !important;
          color: $white !important;
        }

        .details {
          display: inline-block;

          .name {
            p {
              display: inline-block;
              color: $text-color;
              font-weight: 600;
              margin: 0 0.5rem 0 0;
              position: relative;
              top: 0.1rem;
            }

            .admin {
              display: inline-block;
              padding: setUnit(2) setUnit(8);
              background: rgba(116, 116, 117, 0.08);
              border-radius: 40px;

              span {
                color: $dark-color;
                font-weight: 600;
                font-size: 12px;
              }
            }
          }
        }

        .title {
          font-size: 13px;
          font-weight: 400;
          color: $dark-grey-color;
          position: relative;
          top: setUnit(-2);
        }
      }

      .inactive {
        opacity: 0.7;
      }

      .price {
        color: $text-color;
        font-weight: 600;
        cursor: pointer;

        span {
          font-weight: 400;
          color: $dark-grey-color;
        }
      }

      .count {
        font-weight: 500;
        cursor: pointer;
      }

      .tasks {
        p {
          margin-right: 0.75rem;
          display: inline-block;
        }

        .overdue {
          display: inline-block;
          padding: setUnit(2) setUnit(8);
          background: $primary-color-light;
          border-radius: 40px;
          cursor: pointer;

          span {
            color: $hover-red;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }

      & [class*='ant-pagination'] {
        display: none;
      }
    }
  }
}

.filterButton {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $background-grey-2;
  border-radius: setUnit(100);
  padding: setUnit(4) setUnit(6);
  @include transition;
  &.filterOpen {
    background-color: $border-light-gray-2;
    .filterText {
      color: $text-color;
    }
    .filterArrow {
      svg {
        transform: rotate(180deg);
        path {
          stroke: $text-color;
        }
      }
    }
  }
  &:hover {
    background-color: $border-light-gray-2;
    .filterText {
      color: $text-color;
    }
    .filterArrow {
      svg {
        path {
          stroke: $text-color;
        }
      }
    }
  }
  .filterText {
    font-weight: 600;
    font-size: setUnit(12);
    line-height: setUnit(16);
    letter-spacing: -0.18px;
    color: $dark-grey-color;
    margin-right: 2px;
    @include transition;
  }
  .filterArrow {
    display: flex;
    svg {
      width: setUnit(8);
      @include transition;
      path {
        stroke: $dark-grey-color;
      }
    }
  }
}

.underContractFilter {
  padding: setUnit(8) 0px;
  width: setUnit(132);
  background: $white;
  box-shadow: setUnit(0) setUnit(32) setUnit(80) rgba(0, 0, 0, 0.06),
    setUnit(0) setUnit(8) setUnit(30) rgba(0, 0, 0, 0.08);
  border-radius: setUnit(8);
  .filterItems {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    label[class~='ant-radio-wrapper'] {
      flex: 0 0 100%;
      display: flex;
      background-color: $white;
      margin-right: 0;
      padding: setUnit(11) setUnit(16);
      @include transition;
      span[class~='ant-radio'] {
        display: none;
      }
      span:last-child {
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
        color: $text-color;
      }
      &:hover {
        background-color: $background-grey-2;
      }
      .icon {
        opacity: 0;
        visibility: hidden;
        @include transition;
        svg {
          width: setUnit(12);
          height: setUnit(8);
        }
      }
    }
    label[class='ant-radio-wrapper ant-radio-wrapper-checked'] {
      span:last-child {
        font-weight: 500;
      }
      .icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
