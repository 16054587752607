@import 'styles/functions';
@import 'styles/variables';

.checklists {
  .spacing {
    margin-bottom: 2rem;
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
    display: inline-block;
  }

  .addChecklistsIcon {
    float: right;
  }

  .checklistsInput {
    margin: 0 !important;

    .input {
      padding: 0;
      padding-right: 30px;
    }
  }

  .removeBtn {
    right: 0.4rem !important;
  }

  .checklistsInput > div > input {
    border-top-width: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-color: $white !important;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    border-radius: 0 !important;
    border-bottom: 2px solid $border-light-gray-2;
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }

  .checklistItem {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
