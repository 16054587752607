@import 'styles/functions';
@import 'styles/variables';

.inputWrapper {
  position: relative;
  border-radius: setUnit(8);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.hasIcon {
    .geoTagIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    .input {
      padding-left: 36px !important;
    }
  }
  &.light,
  &.lightRound {
    border: none;
    .input {
      padding: 12px 0 16px;
      outline: none;
      margin: 0;
      width: 100%;
      vertical-align: middle;
      color: $text-color;
      font-size: setUnit(18);
      font-weight: 400;
      line-height: setUnit(24);
      letter-spacing: -0.09px;
      height: setUnit(52);
      border: 0;
      border-bottom: 2px solid $border-input-middle-gray;
      transition: $transition;
      &:focus {
        border-bottom-color: $text-gray-color;
      }
      &::placeholder {
        color: $text--light-gray-color;
        font-weight: 400;
        font-size: setUnit(16);
      }
    }

    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: setUnit(20);
      right: setUnit(20);
    }
  }

  &.lightRound {
    .input {
      border: 2px solid $light-gray;
      padding: setUnit(12) setUnit(35) setUnit(12) setUnit(42);
      border: 2px solid $text--light-gray-color;
    }
  }

  &.full {
    .input {
      width: calc(100% - 36px);
      border: none;
      outline: none;
      padding: 12px 0 16px 36px;
      height: 52px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.09px;
    }

    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: setUnit(20);
      right: setUnit(20);
    }
  }

  &.round {
    .loader {
      font-size: 3px !important;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 25px;
    }
  }

  &.square {
    .loader {
      font-size: 3px !important;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 25px;
    }
  }

  .spinner {
    position: absolute !important;
    right: 0 !important;
    top: calc(50% - 23px) !important;
    left: auto !important;
  }

  &.lightFull {
    .input {
      width: calc(100% - 36px);
      border: none;
      outline: none;
      padding: 0;
      border-radius: setUnit(8);
    }

    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: setUnit(20);
      right: setUnit(20);
    }
  }

  &.square {
    .input {
      border-radius: 8px;
      width: 100%;
      border-width: 2px;
      border-style: solid;
      border-color: $border-light-gray-2;
      height: 48px;
      padding: 10px 16px 10px 44px;
      font-size: setUnit(16);
      line-height: setUnit(24);
      letter-spacing: setUnit(-0.09);
      transition: $transition;
      &:focus {
        outline: 0;
        border-color: $text-gray-color;
      }
      &::placeholder {
        font-size: setUnit(16);
        line-height: setUnit(24);
        letter-spacing: setUnit(-0.09);
        color: $border-middle-gray;
      }
    }

    .loader {
      font-size: setUnit(2);
      margin: 0;
      position: absolute;
      top: setUnit(20);
      right: setUnit(20);
    }

    &.hasIcon {
      .geoTagIcon {
        left: 8px;
      }
    }
  }
}
