@import 'styles/functions';
@import 'styles/variables';

.actionBtn {
  position: relative;
  transition: all 0.1s;
  padding-top: 2px;
  float: inline-end;

  .actionBtnIcon {
    width: setUnit(32);
    height: setUnit(32);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }

  &:hover {
    .actionBtnIcon {
      background-color: $light-grey-color;
    }

    .inactiveBtnIcon {
      background-color: $grey-color;
    }
  }
}

.popoverOverlay {
  width: setUnit(156);
  z-index: 99999 !important;
  div[class~='ant-popover-inner'] {
    border-radius: setUnit(8);
    div[class~='ant-popover-inner-content'] {
      padding: 0px;
      .actionList {
        position: absolute;
        // opacity: 0;
        // visibility: hidden;
        background-color: $white;
        width: setUnit(176);
        border-radius: setUnit(8);
        box-shadow: 0 2px setUnit(12) rgba(0, 0, 0, 0.15);
        right: 0;
        z-index: 99;
        overflow: hidden;

        // &.top {
        //   bottom: setUnit(35);
        // }

        // &.bottom {
        //   top: setUnit(45);
        // }

        .actionListBtn {
          width: 100%;
          display: flex;
          align-items: center;
          outline: none;
          border: 0;
          color: $text-color;
          font-size: setUnit(15);
          font-weight: 400;
          line-height: setUnit(16);
          padding: setUnit(15) setUnit(16);
          transition: all 0.1s;
          background-color: $white;
          text-align: left;
          cursor: pointer;

          .icon {
            padding-right: setUnit(8);
            height: setUnit(16);

            svg {
              width: setUnit(16);
              height: setUnit(16);
            }
          }

          &:hover {
            background-color: $hover-light-gray;
          }
        }
      }
    }
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #f4f5f6;
  padding: 0 !important;

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .row {
      display: flex;
      gap: 12px;
      align-items: center;
      .inputWrap {
        background: $white;
        border: 2px solid $light-gray;
        border-radius: setUnit(26);
        max-width: 344px;
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        &:focus {
          border-width: 2px;
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: 40px;
        }
      }
    }
    .archive {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;
      background: $white;
      border-radius: 100px;
      border: 2px solid $light-gray;
      background: $white;
      &.active {
        background: $background-blue;
        border: 2px solid $background-blue;
      }
      svg {
        position: relative;
        top: 3px;
      }
    }
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    padding: 40px 80px;
    gap: 24px;
    .widgetsContainer {
      display: flex;
      gap: 24px;
    }
    .tableContainer {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      width: 100%;
      max-width: 1360px;
      .client {
        display: flex;
        gap: 8px;
        .avatarSmall {
          width: 24px;
          height: 24px;
          font-size: 10.286px;
          font-weight: 600;
          line-height: 13.714px;
          letter-spacing: -0.077px;
          text-transform: uppercase;
        }
      }
      .avatar {
        width: 32px;
        height: 32px;
        font-size: 13.714px;
        font-weight: 600;
        line-height: 18.286px;
        letter-spacing: -0.103px;
        text-transform: uppercase;
      }
      .text {
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.33px;
        margin: 0;
      }
      .value {
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.33px;
        margin: 0;
      }
      .propertyContainer {
        display: flex;
        gap: 12px;
        align-items: center;
        .iconContainer {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $background-grey-2;
          svg {
            position: relative;
            top: 2px;
          }
        }
        .textContainer {
          .line1 {
            color: $text-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: -0.33px;
            margin: 0;
            line-height: 16px;
            margin-bottom: 4px;
          }
          .line2 {
            color: $text-gray-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.33px;
            margin: 0;
            line-height: 16px;
          }
        }
      }
    }
  }
}
