@import 'styles/functions';
@import 'styles/variables';

.agentView {
  .search {
    margin: 14px 16px;

    .searchIcon {
      height: 16px;
      width: 16px;

      position: absolute;
      left: 12px;
    }

    [class*='inputHolder'] {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
    }

    .searchInput {
      border: none !important;
      padding: 10px !important;
      border-radius: 8px;
      padding-left: 38px !important;
      background: $background-grey-2;

      color: $text-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      letter-spacing: -0.18px;

      & input::placeholder {
        color: $text-gray-color;
      }
    }
  }

  .contentHolder {
    max-height: 400px;
    overflow: scroll;

    .blockTitle {
      font-weight: 600;
      padding: 0 setUnit(19);
      margin-bottom: 8px;
    }

    .pendingWrapper {
      height: 100%;
    }
  }
  .partner {
    cursor: pointer;
    padding-left: setUnit(16);
    padding-right: setUnit(16);
    border-radius: setUnit(8);
    transition: $transition;
    .action {
      border: 2px solid $light-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $text-color;
      transition: $transition;
      &.selected {
        font-weight: 600;
        border: 1px solid $primary-color;
        background-color: $primary-color-light;
        transition: $transition;
      }
    }

    &:hover {
      background: $background-ligh-gray;

      .action {
        display: flex;
      }
    }
  }
}
