@import "styles/functions";
@import "styles/variables";

.countInfo {
    color: $dark-grey-color;
    line-height: setUnit(21);

    .count {
        margin-right: setUnit(4);
        font-weight: 600;
        min-width: setUnit(14);
        display: inline-block;
    }

    .received {
        margin-top: setUnit(4);
    }
}
