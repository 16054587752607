@import 'styles/functions';
@import 'styles/variables';

.pdfThumbnail {
  width: 100%;
  height: 100%;

  .loaderSpinner {
    max-height: 180px;
  }

  .pdfThumbnailDocument {
    canvas {
      width: 100% !important;
    }
  }
  .placeholderIcon {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 100px;
      width: 100px;
    }
  }

  .imagePreview {
    width: 100%;
    height: 190px;
    object-fit: cover;
  }
}
