@import 'styles/functions';
@import 'styles/variables';

$steps: 6;

h5 {
  margin: 0 !important;
}

.progressWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .progressWrapInner {
    box-shadow: 0px 2px 16px rgba(48, 48, 48, 0.08);
    border-radius: 16px;
    margin-bottom: 52px;
    padding: 30px 20px 20px;
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    &.truncated {
      .gradientBar {
        background-image: url('../../../../images/bg-truncated.png');
        background-size: cover;
        height: 21px;
        top: 35px;
      }
      .progress {
        min-width: 165px;
      }
    }
    .gradientBar {
      height: 3px;
      background: linear-gradient(90deg, $orange 3.4%, $primary-color 97.96%);
      position: absolute;
      top: 44px;
      left: 100px;
      display: none;
      @media (min-width: 992px) {
        display: block;
      }
    }
    .progress {
      position: relative;
      text-align: center;
      padding: 0 setUnit(20);
      min-width: setUnit(160);
      .iconCheck {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: setUnit(18);
        height: setUnit(18);
        border-radius: 100%;
      }
      .iconCross {
        top: 6px;
        position: relative;
      }
      @for $i from 0 to $steps {
        &:nth-child(#{$i + 1}) {
          .iconCheck {
            background: mix($orange, $primary-color, percentage(calc($i / ($steps - 1))));
          }
        }
      }
    }
    &.accepted {
      .gradientBar {
        background: linear-gradient(90deg, $light-blue 3.4%, #04a451 97.96%);
      }

      .progress {
        @for $i from 0 to $steps {
          &:nth-child(#{$i + 1}) {
            .iconCheck {
              background: mix($light-blue, #04a451, percentage(calc($i / ($steps - 1))));
            }
          }
        }
      }
    }
  }
  .progressNew {
    .gradientBar {
      background-color: $light-gray;
      background-image: none;
    }
    .progress {
      &:nth-child(2) {
        .iconCheck {
          background-image: none;
          background-color: $light-gray;
          svg {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      &:last-child {
        .iconCheck {
          background-image: none;
          background-color: $primary-color;
        }
      }
    }
  }
}

.breadcrumbs {
  text-align: center;
  align-items: center;
  // margin-bottom: 20px;
}

.acceptedStatusColor {
  margin: 0;
  color: #04a451 !important;
}
.rejectedStatusColor {
  margin: 0;
  color: $primary-color !important;
}
