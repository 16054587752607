@import 'styles/functions';
@import 'styles/variables';

.notClient {
  grid-template-columns: 1fr 1fr !important;
}

.disableAgent {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.valuationTable {
  display: grid;
  grid-template-columns: 1fr 120px 120px 120px;
  column-gap: 12px;
  margin-top: 20px;
  .header {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 4;
    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.18px;

    margin-bottom: 8px;
    padding-inline: 12px;
  }
  .data {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 4;

    .row {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span 4;
      padding: 10px 12px;

      &:nth-child(odd) {
        border-radius: 8px;
        background: var(--grayscale-10-background, #f4f5f6);
      }

      .col {
        color: var(--REBRAND-Grayscale-80, #515151);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.18px;
        span {
          color: var(--REBRAND-Grayscale-100, #262626);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 133.333% */
          letter-spacing: -0.18px;
          &.change {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.18px;
          }
          &.increase {
            color: $green-color;
          }
          &.decrease {
            color: $error-color;
          }
        }
      }
    }
  }
}
