@import "styles/functions";
@import "styles/variables";

.close {
    background-color: transparent;
    border: 1px solid $black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .1s;
    width: 3.5rem;
    height: 3.5rem;
    box-sizing: border-box;
    border-radius: 50%;

    .icon {
        height: setUnit(40);

        svg {
            height: setUnit(40);
            width: setUnit(40);
            vertical-align: middle;

            path {
                fill: $white;
            }
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}
