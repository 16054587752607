@import 'styles/functions';
@import 'styles/variables';

.viewClientsContainer {
  height: 100%;
}

.searchLineContainer {
  margin: 0 0 0 setUnit(32);
  max-width: calc(100% - 182px);
  &.fullWidth {
    max-width: calc(100% - 64px) !important;
    margin-bottom: 3rem;
  }
  & [class*='inputWrapper'] {
    margin-bottom: 0 !important;
  }
  input {
    background: $background-grey-2;
    border: none !important;
    border-radius: setUnit(8) !important;
  }
}

.clientTabs {
  border: none;
  padding: 0 setUnit(32) setUnit(36);
  justify-content: space-between;

  .clientTab {
    &:hover {
      background-color: $light-gray;
    }

    font-weight: normal;
    color: $text-color;
    padding: setUnit(12) 0;
    flex-grow: 1;
    text-align: center;
    background: $background-ligh-gray;
    border-radius: 8px;
  }

  .activeTab {
    &:hover {
      background-color: $secondary-color;
    }

    background-color: $secondary-color;
    color: $white;
    font-weight: normal;
    transition: background-color 0.8s;
  }

  :nth-child(3n) {
    margin-right: 0;
  }
}
.clientTabsSelect {
  &.visibilityHidden {
    display: none !important;
  }
  float: right;
  margin-right: setUnit(32);
  margin-top: setUnit(-22);
  margin-bottom: setUnit(24);
  max-width: setUnit(110);
  width: 100%;
  & [class*='ant-select-selector'] {
    height: 44px !important;
    min-height: 44px !important;
    border: none !important;
    color: $text-color;
    display: flex;
    align-items: center;
    border-radius: 0.5rem !important;
    background-color: $background-grey-2 !important;
    box-shadow: none !important;
    padding: 0px 14.9px !important;
    width: setUnit(110) !important;
    &:hover {
      background-color: $light-gray !important;
      border: none !important;
    }

    & [class*='ant-select-selection-item'] {
      color: $text-color;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }
  }

  & [class*='ant-select-arrow'] {
    color: $text-color;
    right: 16px;
    top: 51%;
  }

  & [class*='ant-select-open'] {
    border: none !important;

    & [class*='ant-select-selector'] {
      background-color: $border-input-middle-gray !important;

      &:hover {
        background-color: $border-input-middle-gray !important;
      }
    }

    & [class*='ant-select-arrow'] {
      top: 49%;
      transform: rotate(180deg);
    }
  }
}

.clientTabsDropdown {
  width: 200px !important;
  border-radius: 8px !important;

  & [class*='ant-select-item'],
  & [class*='ant-select-item-option-selected']:not(.ant-select-item-option-disabled) {
    padding: 7.5px;
    background-color: $white;
    border-color: $white !important;

    &:hover {
      background-color: $light-gray;
    }

    & [class*='ant-select-item-option-state'],
    & [class*='ant-select-item-option-content'] {
      background-color: transparent !important;
    }
  }
}

.contentContainer {
  overflow-y: auto;
  height: calc(100vh - #{setUnit(265)});
  padding: unset;
  position: relative;
}

// add media query for mobile 325px
@media (max-width: 325px) {
  .clientTabsSelect {
    margin-right: setUnit(16);
  }

  .searchLineContainer {
    max-width: calc(100% - 146px);
    & [class*='inputWrapper'] {
      max-width: setUnit(150) !important;
      margin-bottom: 0 !important;
    }
  }
}
