@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';
@import '../../styles.module';

.propertySize {
  margin-top: 48px;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  .postfix {
    color: $text-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.18px;
    white-space: nowrap;
    margin: 0;
  }
}

.to {
  font-size: 18px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.09px;
  margin: 0 24px !important;
  color: $text-color !important;
}
