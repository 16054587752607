@import 'styles/functions';
@import 'styles/variables';

.compareList {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  z-index: 99;
  width: 100%;
  padding: setUnit(16) setUnit(24);
  display: none;
  align-items: center;
  justify-content: space-between;
  min-height: setUnit(80);
  transition: all 0.1s;
  box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);

  &.open {
    display: flex;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    border: 0;
    &:hover {
      background: none;
    }
    &.disabled {
      &:hover {
        background: none;
      }
    }

    &:first-child {
      margin: 0;
      padding: 0;
      width: auto;
      font-weight: 400;
      width: setUnit(120);
      height: setUnit(44);
      margin-right: setUnit(16);
      color: $dark-color;
      background: none;
    }

    &:last-child {
      width: setUnit(200);
      height: setUnit(52);
      background: $secondary-color;
      border-radius: setUnit(10);
      color: $white;
      font-weight: 600;
      &:hover {
        background: lighten($secondary-color, 10);
      }
    }
  }

  .hide {
    visibility: hidden;
  }
}
