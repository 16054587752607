@import 'styles/functions';
@import 'styles/variables';

$archive-height: 112px;

.pageContent {
  padding-top: 120px;
  padding-right: 112px;
  padding-left: 70px;

  @media (max-width: $mobile) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 12rem !important;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.pagecontentStyles {
  padding-top: 0 !important;
  padding-bottom: calc(#{$archive-height} + #{$footer-height}) !important;

  @media (max-width: $mobile) {
    padding: 0 !important;
  }
}

.stickyFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
