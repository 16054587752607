@import 'styles/functions';
@import 'styles/variables';

.showingType {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .countContainer {
    background-color: $background-grey-2;
    border-radius: 50%;
    margin-right: setUnit(12);
    width: setUnit(36);
    height: setUnit(36);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: $tablet) {
      width: setUnit(56);
      height: setUnit(56);
    }
    span {
      font-weight: 700;
      color: $dark-grey-color;
      font-size: setUnit(22);
      line-height: setUnit(28);
      @media (min-width: $tablet) {
        font-size: setUnit(26);
        line-height: setUnit(32);
      }
    }
  }

  .primary {
    background-color: $primary-color-light;
    span {
      color: $primary-color;
    }
  }

  .secondary {
    background-color: rgba(34, 70, 170, 0.1);
    span {
      color: $blue;
    }
  }

  .details {
    flex: 0 0 calc(100% - #{setUnit(48)});
    @media (min-width: $tablet) {
      flex: 0 0 calc(100% - #{setUnit(68)});
    }
    h3 {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin-bottom: setUnit(4);
    }
    .review {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 500;
        font-size: setUnit(14);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        color: $dark-color;
      }
      .icon {
        display: flex;
        svg {
          path {
            stroke: $dark-color;
          }
        }
      }
    }
  }
}
