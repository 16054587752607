@import 'styles/functions';
@import 'styles/variables';

$active-border-color: $primary-color;

.optionHolder {
  display: flex;

  .option {
    border-radius: setUnit(8);
    border: 2px solid $light-gray;
    width: 100%;
    height: 124px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: setUnit(8);
    cursor: pointer;
    text-align: center;
    line-height: setUnit(16);
    transition: $transition;
    &:last-child {
      margin-right: 0;
    }

    .edit {
      position: absolute;
      top: setUnit(5);
      right: setUnit(5);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: setUnit(20);
        height: setUnit(20);
        vertical-align: middle;
      }

      &:hover {
        background-color: $active-border-color;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    .optionInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.09px;
        color: $dark-color;
        transition: $transition;
        margin-bottom: 0;
        max-width: 95%;
      }

      .icon {
        height: 63.14px;
        display: flex;
        align-items: center;

        svg {
          height: 56px;
          width: 56px;
        }
      }

      svg {
        vertical-align: middle;

        path,
        rect,
        circle,
        ellipse {
          stroke: $dark-color;
          transition: $transition;
        }
      }
    }

    &:hover {
      background: #ffffff;
    }

    &.active {
      background: $primary-color-light;
      border: 1px solid $active-border-color !important;

      .labelActive {
        color: $text-color;
        font-weight: 500;
      }

      svg {
        vertical-align: middle;

        mask {
          & + path {
            fill: $active-border-color;
          }
        }

        path,
        rect,
        circle,
        ellipse {
          stroke: $active-border-color;
        }
      }

      &:hover {
        border: 1px solid $primary-color-hover !important;
        background-color: #ffebed !important;
      }
    }

    &:hover {
      border: 2px solid $text--light-gray-color !important;
    }
  }
}
