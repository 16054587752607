@import 'styles/functions';
@import 'styles/variables';

.container {
  .propertyType {
    margin: 0;
    margin-bottom: 1.5rem;
    .propertyInput {
      input {
        padding-left: 10px !important;
      }
    }
  }
}
