@import 'styles/functions';
@import 'styles/variables';

.wantsWrapper {
  .noItemWrap {
    padding: setUnit(24) 0;
    .noItemContent {
      font-size: setUnit(16);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      color: $text--light-gray-color;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
