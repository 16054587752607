@import 'styles/functions';
@import 'styles/variables';

.pendingTasks {
  padding-bottom: setUnit(30);

  .pendingTaskAnswerContainer {
    max-width: 80%;
    max-height: 600px;
    overflow-x: scroll;
  }

  .pendingTask {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    .taskData {
      display: flex;
      flex-direction: column;
      width: calc(100% - 160px);
      .taskName {
        width: 310px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $text-color;
        word-wrap: break-word;
      }

      .taskStatus {
        width: 310px;
        word-wrap: break-word;
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $dark-color;
      }
    }

    .taskActions {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
    }
  }

  .checkbox {
    margin: 0;
    display: flex;
    & [class*='ant-checkbox'] {
      top: 0px;
      & [class*='ant-checkbox-inner'] {
        border: 1.5px solid $text--light-gray-color;
        border-radius: 4px;
        width: setUnit(24);
        height: setUnit(24);
        background-color: #fff;
        &:after {
          transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
          left: 25%;
        }
      }
    }
    & [class*='ant-checkbox-checked'] {
      &:after {
        border-color: $primary-color;
        border-radius: 4px;
      }
      & [class*='ant-checkbox-inner'] {
        background-color: $primary-color;
        border-color: $primary-color;
        &::after {
          width: setUnit(6);
          height: setUnit(12);
        }
      }
    }
  }

  & [class*='ant-checkbox-indeterminate'] {
    & [class*='ant-checkbox-inner'] {
      &:after {
        transform: translate(-50%, -50%) scale(1) !important;
        left: 50% !important;
        width: setUnit(13);
        height: setUnit(13);
        background-color: $primary-color;
        border-radius: 2px !important;
      }
    }
  }

  .pendingTaskTable {
    th,
    td {
      cursor: pointer;
    }
  }
  .taskTable {
    [class*='ant-spin-spinning'] {
      top: unset;
      bottom: 0 !important;
    }

    .taskWrap {
      .line1 {
        font-weight: 600;
      }
    }

    th {
      background-color: unset;
      color: $dark-grey-color;
      font-weight: 600;
    }

    td {
      p {
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.33);
        color: $text-color;
        margin-bottom: 0;
      }
    }

    th,
    td {
      &:last-child {
        border-right: unset !important;
      }
    }

    & [class*='ant-table-container'] {
      border: unset !important;
    }

    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
    }

    table {
      border: 1px solid $border-light-gray-2;
      border-radius: 8px;
    }

    .taskTitle {
      font-size: setUnit(14);
      font-weight: 600;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.33);
      color: $text-color;
    }
    .avatarWrapper {
      display: inline-block;
      margin-right: 0.5rem;
      .avatar {
        width: 1.5rem;
        height: 1.5rem;
        .avatarPlaceholder {
          font-size: 10px;
        }
      }
    }

    .assigneeName {
      font-size: setUnit(14);
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.33);
      color: $text-color;
      margin-bottom: 0;
    }

    .datePrimary {
      color: $error-color;
      font-weight: 500;
    }

    .tagContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category {
        .statusText {
          color: $text-gray-color;
        }
      }

      .actionSelectContainer {
        display: flex;
        align-items: center;

        .actions {
          .optionDots {
            width: 2rem;
            height: 2rem;
          }
        }

        .multiSelect {
          margin-left: 16px;

          .checkbox {
            [class*='ant-checkbox'] {
              [class*='ant-checkbox-inner'] {
                width: 24px;
                height: 24px;
                border-radius: 4px;
              }
            }

            [class*='ant-checkbox-checked'] {
              [class*='ant-checkbox-inner'] {
                background-color: $primary-color;
                border-color: $primary-color;
              }
              [class*='ant-checkbox-inner']::after {
                width: 7px;
                height: 12px;
              }
            }
          }

          &:hover,
          &:focus {
            [class*='ant-checkbox-inner'] {
              border-color: $primary-color;
            }
          }
        }
      }
    }

    & [class*='ant-pagination'] {
      display: none;
    }
  }

  .buttonsContainer {
    margin-top: 40px;
  }
}
