@import 'styles/functions';
@import 'styles/variables';

.preApprovalDocuments {
  padding: 0 2rem 2rem;

  .uploadDate {
    font-weight: 600;
    line-height: 21px;
    color: $text-color;
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0.5rem;
    }
  }
}
