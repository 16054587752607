@import 'styles/functions';
@import 'styles/variables';

.favWrapper {
  padding: setUnit(4) setUnit(12) setUnit(4) setUnit(8);
  background: $background-ligh-gray;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;

  &:hover {
    background-color: $light-gray;
  }

  .pendingWrapper {
    display: flex;
    align-items: center;

    .loader {
      margin: 0.6875rem;
      font-size: 2px;
    }
  }

  &.buyer {
    padding: 0;
    height: 2rem;
    min-width: 2rem;
    cursor: pointer;
    margin-right: 0;
  }

  .icon {
    display: inline-flex;
    svg {
      width: setUnit(25);
      height: setUnit(25);
    }
  }

  .count {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
  }
}
