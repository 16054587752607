@import 'styles/functions';
@import 'styles/variables';

.address {
  font-size: 14px;
  font-weight: 400;
  padding-right: 0.625rem;

  .line1 {
    min-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
  }

  .cityState {
    color: $border-middle-gray;
    margin-top: 0.25rem;
    min-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
