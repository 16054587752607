@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.header {
  font-size: setUnit(24);
  color: $dark-gray-2;
  font-weight: 600;
  line-height: setUnit(30);
  margin-bottom: setUnit(32);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: setUnit(15);

  .headerButtons {
    display: flex;
  }
}

.button {
  @include button($secondary-color, $white, $hover-blue);
  border-radius: setUnit(100);
  margin-left: setUnit(16);
  width: fit-content;
  height: setUnit(40);
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: setUnit(16);
  line-height: setUnit(21);
  font-weight: normal;
  text-decoration: none;

  & svg {
    width: setUnit(24);
    margin-right: setUnit(8);
    vertical-align: middle;

    path {
      stroke: $white;
      stroke-width: 2px;
    }
  }
}
