@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.forgotPassword {
  width: 100%;
  align-items: center;
  height: calc(100vh - #{setUnit(186)});
  @media (min-width: $tablet) {
    padding-left: 0 !important;
    margin-left: 0 !important;
    width: calc(100% - #{setUnit(680)}) !important;
    height: 100vh;
  }
  .formGroup {
    margin: 0;
    padding: 0 setUnit(16);
  }
}

.description {
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  letter-spacing: -0.09px;
  margin-top: setUnit(24);
  margin-bottom: 0;
}

.title {
  letter-spacing: -0.4px;
  color: $secondary-color !important;
  font-size: setUnit(26) !important;
  line-height: setUnit(32);
  font-weight: 500;
  margin-bottom: setUnit(40);
  @media (min-width: $mobile) {
    font-size: setUnit(36) !important;
    line-height: setUnit(40);
  }
}

.buttons {
  margin-top: setUnit(60);
  display: flex;
  flex-direction: column;
  gap: 14px;
  .submit {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(24);
    max-width: setUnit(192);
    width: 100%;
    height: auto;
    padding: setUnit(12);
    border: 2px solid $secondary-color;
    height: setUnit(52);
    @include transition;
    &:hover {
      background-color: $white !important;
      color: $secondary-color !important;
    }
  }
  .back {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(24);
    max-width: setUnit(192);
    width: 100%;
    height: auto;
    padding: setUnit(12);
    border: 2px solid $border-light-gray !important;
    color: $secondary-color !important;
    background-color: $white;
    height: setUnit(52);
    @include transition;
  }
}
