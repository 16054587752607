@import 'styles/variables';

.quoteHeaderContainer {
  flex: 1;
  overflow: auto;
  height: $header-height;
  display: flex;

  .slash {
    display: flex;
    margin: auto 5px;
    &::before {
      content: '/';
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: auto 10px;
      color: $dark-color;
    }
  }

  .folderIcon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    svg {
      height: 12.5px !important;
      width: 15px !important;
      path {
        stroke: #262626;
      }
    }
  }

  .folderName {
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: $text-color;
    display: flex;
    align-items: center;
  }

  .formName {
    margin-right: 10px;
    display: flex;
    align-items: center;
    .formNameInput {
      color: $dark-color;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border: none;
      min-height: unset !important;
      padding-left: 0px !important;
      max-height: $header-height !important;
    }
  }

  .icons {
    display: flex;
    align-items: center;

    .tagIcon {
      display: flex;
      align-items: center;
      margin: auto 20px;
    }

    .favIcon {
      display: flex;
      align-items: center;
    }
  }
  .checkBoxIcon {
    width: 24px !important;
    height: 24px !important;
  }

  .wizardViewToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }
    label {
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $text-color;
      margin-left: 5px;
    }
  }
}
