@import 'styles/variables';

.autoCompleteWrapper {
  .autoCompleteBase {
    transition: $transition;
    [class*='ant-select-selector'] {
      padding: 0 !important;

      [class*='ant-select-selection-search'] {
        left: 0.75rem !important;

        [class*='ant-select-selection-search-input'] {
          text-overflow: ellipsis;
        }
      }

      [class*='ant-select-selection-placeholder'] {
        font-size: 16px;
        color: $text-gray-color;
        padding: 0 0.75rem !important;
      }
    }
  }
}
