@import 'styles/functions';
@import 'styles/variables';

.softCriteriaType {
  padding: setUnit(8) 0;
  .logoCol {
    display: flex;
    align-items: center;
    p {
      font-size: setUnit(16);
      font-weight: normal;
      margin: 0;
      color: $text-color;
      margin-left: setUnit(10);
    }
    .softIcon {
      display: flex;
      align-items: center;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  .radioButtonsRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .optionButtons {
      margin: 0;
      border-radius: 6px;
    }
  }
  .softRadioButton {
    color: $dark-color !important;
    border: 2px solid $light-gray !important;
    padding: 0 !important;
    width: 33.333%;
    height: setUnit(48) !important;
    @media (min-width: $mobile) {
      width: setUnit(68) !important;
    }
    &:not(:last-child) {
      margin-right: setUnit(10);
    }

    span {
      color: $dark-color;
    }

    &:hover {
      border: 2px solid $text--light-gray-color !important;
    }
  }
  .softRadioButtonClicked {
    @extend .softRadioButton;
    border: 1px solid $primary-color !important;
    background-color: $primary-color-light;

    &:hover {
      border: 1px solid $primary-color-hover !important;
      background-color: #ffebed !important;
    }

    span {
      color: $text-color;
      font-weight: 500;
    }
  }
}
