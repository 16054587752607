@import 'styles/functions';
@import 'styles/variables';

.editQuestions {
  width: 100%;
  position: relative;
  .currencyInputContainer {
    height: auto;
    position: relative;
    .currencyInputPrefix {
      position: absolute;
      top: 12px;
      left: 0;
      margin-right: unset;
    }
    .currencyInput {
      padding: 12px 0 16px 18px;
      outline: none;
      margin: 0;
      width: 100%;
      vertical-align: middle;
      color: $text-color;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.09px;
      height: 52px;
      border: 0;
      border-radius: 0 !important;
      border-bottom: 2px solid $border-input-middle-gray;
      transition: $transition;
      &:focus,
      &:hover {
        border-bottom-color: $text-gray-color;
        box-shadow: none;
      }
      &::placeholder {
        color: $text--light-gray-color;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
