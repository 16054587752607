@import 'styles/mixins';
@import 'styles/variables';

$active-border-color: $primary-color;

.wrapper {
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
    &.holderRow {
      gap: 10px;
    }
    &.holderColumn {
      .case {
        &:not(:last-child) {
          margin-bottom: setUnit(24);
        }
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    .case {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: setUnit(100);
      min-height: setUnit(56);
      cursor: pointer;
      background: #fff;
      border: 2px solid $light-gray;
      transition: $transition;

      .loaderWrapper {
        height: setUnit(24);
        display: flex;
      }

      .loader {
        font-size: setUnit(4);
        color: $primary-color;
      }

      .caseInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .label {
          color: $dark-color;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.09px;
          text-align: center;
          margin-bottom: 0;
          transition: $transition;
        }
      }

      &.active {
        background-color: $primary-color-light !important;
        border-color: $primary-color !important;
        border: 1px solid;
        &:hover {
          border-color: $primary-color !important;
        }
        .caseInner {
          .label {
            color: $text-color;
          }
        }
      }

      &.darkBlue {
        background-color: #4673d114 !important;
        border-color: #4673d1 !important;
        &:hover {
          border-color: #4673d1 !important;
        }
      }
      &.red {
        background-color: $primary-color-lighter !important;
        border-color: $primary-color !important;
        &:hover {
          border-color: $primary-color !important;
        }
      }
      &.yellow {
        background-color: $orange-lighter !important;
        border-color: $orange !important;
        &:hover {
          border-color: $orange !important;
        }
      }
      &.purple {
        background-color: #928cda14 !important;
        border-color: #928cda !important;
        &:hover {
          border-color: #928cda !important;
        }
      }
      &.blue {
        background-color: $light-blue-lighter !important;
        border-color: $light-blue !important;

        &:hover {
          border-color: $light-blue !important;
        }
      }
    }

    .checkboxOption {
      height: 64px;
      width: 100%;
      border-radius: setUnit(8);
      border: 2px solid $light-gray;
      padding: 20px;
      margin-bottom: 16px;
      font-weight: 500;
      color: $dark-grey-color;
      display: flex;
      cursor: pointer;
      background-color: $white;

      &:hover {
        border: 2px solid $text--light-gray-color;
      }

      &.checked {
        border: 1px solid;
        border-color: $primary-color;
        background-color: $primary-color-light;
        color: $text-color;
        padding: 1.063rem 1.313rem !important;

        &:hover {
          background-color: #ffebed;
          border: 1px solid $primary-color-hover;

          .checkbox {
            background: $primary-color-hover !important;
          }
        }

        .label {
          .labelText {
            color: $text-color;
            font-weight: 500;
          }
        }
      }

      &.darkBlue {
        background-color: #4673d114;
        border-color: #4673d1;
        .checkbox {
          background: #4673d1 !important;
        }
        &:hover {
          background-color: #4673d114;
          border-color: #4673d1;

          .checkbox {
            background: #4673d1 !important;
          }
        }
      }
      &.red {
        background-color: $primary-color-lighter;
        border-color: $primary-color;
        .checkbox {
          background: $primary-color !important;
        }
        &:hover {
          background-color: $primary-color-lighter;
          border-color: $primary-color;

          .checkbox {
            background: $primary-color !important;
          }
        }
      }
      &.yellow {
        background-color: $orange-lighter;
        border-color: $orange;
        .checkbox {
          background: $orange !important;
        }
        &:hover {
          background-color: $orange-lighter;
          border-color: $orange;

          .checkbox {
            background: $orange !important;
          }
        }
      }
      &.purple {
        background-color: #928cda14;
        border-color: #928cda;
        .checkbox {
          background: #928cda !important;
        }
        &:hover {
          background-color: #928cda14;
          border-color: #928cda;

          .checkbox {
            background: #928cda !important;
          }
        }
      }
      &.blue {
        background-color: $light-blue-lighter;
        border-color: $light-blue;
        .checkbox {
          background: $light-blue !important;
        }
        &:hover {
          background-color: $light-blue-lighter;
          border-color: $light-blue;

          .checkbox {
            background: $light-blue !important;
          }
        }
      }

      &:last-child {
        margin-bottom: 0 !important;
      }

      .label {
        display: inline-flex;
        margin-bottom: 0;
        pointer-events: none;

        .labelText {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: $dark-color;
        }
      }
    }

    .checkboxWrapper {
      min-height: 1.5rem;
      min-width: 1.5rem;

      .checkmark {
        display: flex !important;
        justify-content: center;
        align-items: center;
        position: initial !important;
        top: 0 !important;
        left: 0 !important;

        &.unchecked {
          display: none !important;
        }

        svg {
          height: 60% !important;
          width: 60% !important;
        }
      }
    }

    .unchecked {
      border: 2px solid $text--light-gray-color !important;
    }
    &.holderMultiple {
      justify-content: space-between;
      .case {
        width: 48%;
        height: 6%;
      }
    }
  }

  &.light {
    .case {
      border-radius: setUnit(8);
      &:hover {
        border-color: $text--light-gray-color;
      }
    }
  }

  &.fullLight {
    margin-bottom: setUnit(40);
    .case {
      border-radius: setUnit(8);
      border: 2px solid #e8eaeb;
      .caseInner {
        .label {
          font-size: setUnit(18);
          color: $dark-grey-color;
        }
      }
      &.active {
        background: $primary-color-light;
        border: none;
        .caseInner {
          .label {
            font-weight: 600;
            color: $text-color;
          }
        }
      }
    }
  }
}
