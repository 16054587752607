@import 'styles/functions';
@import 'styles/variables';

.savedSearchBtn {
  background-color: #f4f6fb !important;
  border-radius: 8px !important;
}

.tabBtnText {
  display: flex;
  align-items: center;
  gap: 8px;
  .text {
    max-width: 110px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: setUnit(5);
    color: $secondary-color;
    font-weight: 600;
  }
  .icon {
    margin-top: setUnit(5);
  }
  &.rotateIcon {
    .icon {
      transform: rotate(-180deg);
    }
  }
}

.optionsPopoverContent {
  [class*='ant-popover-inner'] {
    border-radius: setUnit(12);
    box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.12);
  }

  .searchesContainer {
    border-radius: setUnit(12);
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .header {
      padding: 0.75rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: $text--light-gray-color;
      }
      .viewAll {
        color: $link-blue;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
    }

    .searchOptionContainer {
      padding: 0.75rem 1.25rem;
      min-width: 350px;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background-color: $background-grey-2;
      }

      .searchOption {
        display: flex;
        align-items: center;
        gap: 8px;

        color: $text-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        letter-spacing: -0.09px;
      }
    }
  }
}
