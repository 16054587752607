@import 'styles/functions';
@import 'styles/variables';

.subfilterStatus {
  background: #51bfe11f;
  padding: 0.25rem 0.5rem;
  border-radius: setUnit(8);
  width: fit-content;
  height: 30px;
  text-overflow: ellipsis;
  max-width: 275px;

  .statusContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: setUnit(4);

    p {
      margin: 0;
      font-size: 13px;
    }
    .oldPrice {
      color: $text--light-gray-color;
    }
  }
}
