@import 'styles/functions';
@import 'styles/variables';

.permissions {
  height: 100%;

  .pageTitle {
    font-size: setUnit(24);
    line-height: setUnit(29);
    font-weight: 600;
    letter-spacing: -0.47px;
    border-bottom: 2px solid $light-gray;
    padding-bottom: setUnit(33);
    margin-top: 0;
    margin-bottom: 26px;
  }

  .contentWrap {
    .contentTitle {
      display: flex;
      align-items: center;
      gap: 12px;

      margin-bottom: 26px;

      color: $text-color;
      font-size: setUnit(18);
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.33px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      line-height: 0;
    }
  }
}
