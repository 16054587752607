@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.clientItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid var(--Grayscale-20, #e8e9ea);

  .leftSection {
    display: flex;
    align-items: center;
    .clientName {
      cursor: pointer;
      .countText {
        color: $text--light-gray-color;
        margin-left: setUnit(2);
      }
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .clientAddress {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .clientName,
    .clientAddress,
    .assignedTo {
      margin-left: setUnit(10);
      margin-bottom: 0;
      line-height: 20px;
    }
    .avatar {
      width: 1.5rem;
      height: 1.5rem;
      .avatarPlaceholder {
        font-size: 10px;
      }
    }
  }
}

.clientItem,
.allOption {
  .checkbox {
    label {
      margin: 0;
    }
    margin-right: 0.25rem;
  }
}

.clientAddressWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.addressList {
  margin-top: 14px;
  .addressRow {
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
  }
}

.rightSection {
  cursor: pointer;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.agentView {
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}
