@import 'styles/mixins';
@import 'styles/variables';

.inProgressFormsWrapper {
  .inProgressFormsContent {
    .inProgressFormsContentWrapper {
      .loadingSpinner {
        font-size: 0.5em;
        margin: 50px auto;
      }

      .inProgressFormsTable {
        .formLibraryName {
          display: flex;
          align-items: center;
          gap: 12px;

          font-size: setUnit(16);
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
          letter-spacing: -0.18px;

          .fileName {
            cursor: pointer;
            word-break: break-all;
          }
        }

        [class~='ant-table-body'] {
          margin-right: -15px;
          padding-right: 10px;

          @media (min-width: $mobile-small) {
            overflow-x: hidden;
          }

          [class~='ant-table-row'] {
            td {
              padding-top: 13.5px !important;
              padding-bottom: 13.5px !important;
            }
          }
        }
      }
    }

    margin-bottom: 20px;
  }

  .navigation {
    button {
      margin-top: 20px;
    }
  }

  [class~='ant-table-selection-col'] {
    width: 3%;
    margin-right: 10px;
  }
}
