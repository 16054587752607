@import 'styles/variables';
@import 'styles/mixins';

.userRoleContainer {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: $white;
  min-width: 180px;
  width: fit-content;
  z-index: 5;

  .userRow {
    padding: 0px 20px;
    display: flex;
    width: 100%;
    margin: 3px 0px;
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  .userColorBox {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .userTitle {
    overflow-wrap: anywhere;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.stickyBottom {
  position: sticky;
  bottom: 20px;
  margin-left: 40px;
  left: 40px;
  width: fit-content;
  z-index: 2;
}

.bundleListSticky {
  margin-left: 40px;
  z-index: 2;
  @media screen and (max-width: $tablet-horizontal) {
    margin-left: 0px;
  }
}
