@import 'styles/functions';
@import 'styles/variables';

.editPropertyAddress {
  width: 100%;
  position: relative;
  .propertyAddressInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .unit {
    max-width: 50%;
    width: 100%;
    & input {
      &::placeholder {
        color: $text-gray-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.09px;
      }

      height: initial !important;
    }
  }
  .valuesWrapper {
    border-bottom: none !important;
  }
  .autocompleteWrapper {
    z-index: 999;
    height: 100%;
    left: 0;
  }
  .popup input {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.09px;
  }
}
