@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.transactionWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: setUnit(12) 0;
  margin-bottom: setUnit(8);
  background-color: $white;
  border-radius: setUnit(8);
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $background-grey-2;
  }
  .homeIcon {
    background-color: $background-grey-2;
    border-radius: setUnit(8);
    padding: setUnit(8);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: setUnit(12);
  }
  .contentArea {
    flex: 0 0 calc(100% - #{setUnit(88)});
    .propertyAddressWrap {
      margin-bottom: setUnit(2);
      .address {
        font-weight: 600;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.18px;
        color: $text-color;
        margin-bottom: setUnit(4);
      }
      .state {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.09px;
        color: $dark-color;
        margin-bottom: 0;
      }
    }
    .transactionInfo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .closingInfo {
        font-weight: 600;
        font-size: setUnit(14);
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        color: $text-color;
        margin-right: setUnit(8);
        margin-bottom: 0;
      }
      .badge {
        background: rgba(70, 115, 209, 0.1);
        border-radius: setUnit(30);
        font-weight: 600;
        font-size: setUnit(14);
        line-height: setUnit(16);
        letter-spacing: -0.18px;
        color: $confetti-blue;
        padding: setUnit(4) setUnit(8);
        &.overdue {
          background-color: #fff2f3;
          color: $primary-color;
        }
      }
    }
  }
  .arrowIcon {
    margin-left: setUnit(12);
    svg {
      width: setUnit(24);
      height: setUnit(24);
      path {
        stroke: $text-gray-color;
      }
    }
  }
}
