@import 'styles/functions';
@import 'styles/variables';

.locationInfoWindow {
  padding-top: setUnit(16);
  padding-left: setUnit(16);
  padding-right: setUnit(16);

  &Header {
    display: flex;

    .name {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $text-color;
      width: setUnit(160);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .distance {
      font-weight: 300;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $dark-grey-color;
      width: setUnit(70);
    }
  }

  &Content {
    display: flex;

    .rating {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $text-color;
    }

    .stars {
      flex-grow: 1;
    }

    .count {
      font-weight: 300;
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-family: $font-family;
      letter-spacing: setUnit(-0.09);
      color: $dark-grey-color;
    }
  }

  .category {
    font-weight: 500;
    font-size: setUnit(12);
    line-height: setUnit(16);
    font-family: $font-family;
    letter-spacing: setUnit(-0.09);
    color: $dark-grey-color;
  }
}
