.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  text-align: center;

  .message {
    margin: 0 auto;
    max-width: 467px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 172.222% */
    letter-spacing: -0.8px;
  }

  .btnContainer a {
    display: inline-block;
    border-radius: 16px;
    padding: 22px 32px;
    border: 1.5px solid #ff576d;
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Sora, Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.1px;
  }
}
