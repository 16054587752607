@import 'styles/functions';
@import 'styles/variables';

.dateControlInfo {
  display: inline-block;
  cursor: pointer;
}

.datControlsTooltip {
  [class*='ant-tooltip-inner'] {
    width: setUnit(458);
    padding: setUnit(20);
    border-radius: 1rem;
    background-color: $text-color;
    padding: 0.7rem !important;

    .content {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }
}
