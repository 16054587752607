@import 'styles/functions';
@import 'styles/variables';

.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 9px 32px;
  min-height: 44px;
  .iconLabel {
    display: flex;
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $light-black;
      margin-left: 12px;
    }
  }

  .value {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    text-align: right;

    color: $text-color;
    a {
      color: $link-blue;
    }
  }

  .address {
    text-align: end;
    p {
      margin: 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .statusWrapper {
    margin: setUnit(0) setUnit(-12) setUnit(0) setUnit(0) !important;
    padding: 0;
    div[class*='selectHolder'] {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        height: setUnit(32) !important;
        width: setUnit(132) !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        color: $dark-color !important;
        text-align: right;
        border: 0 !important;
        padding: 0 !important;
        padding-right: setUnit(30) !important;
      }
      .arrowIcon {
        right: 8px;
      }
    }
  }
}
