@import 'styles/variables';
.iconWrapper {
  background-color: transparent;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.successMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px 6px 8px;
  gap: 4px;
  border-radius: 12px;
  background-color: $green-color;
  color: white;
}
