@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.actions {
  border-radius: setUnit(100);
  background-color: $background-grey-2;
  width: setUnit(32);
  height: setUnit(32);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  &:hover {
    background-color: $light-gray;
  }
  &[class~='ant-dropdown-open'] {
    background-color: $border-light-gray-2;
  }
  .buttonIcon {
    display: flex;
    svg {
      width: setUnit(20);
      height: setUnit(20);
      path {
        stroke: $dark-color;
        stroke-width: 2px;
      }
    }
  }

  .actionsDropdown {
    .dropdown {
      @include box-shadow;
      width: setUnit(148);
      border-radius: setUnit(8);
      padding: 0;
      background-color: #fff;
      .item {
        transition: $transition;
        padding: setUnit(10) setUnit(16);
        height: setUnit(44);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: setUnit(14);
        font-weight: 400;
        line-height: setUnit(16);
        letter-spacing: setUnit(-0.09);
        &:hover {
          background-color: $background-grey-2;
        }
        .menuIcon {
          display: flex;
          margin-right: setUnit(8);
          svg {
            width: setUnit(16);
            height: setUnit(16);
            path {
              stroke: $dark-color;
            }
          }
        }
      }
      .divider {
        background-color: $background-ligh-gray-4;
        height: 1px;
        width: calc(100% - #{setUnit(32)});
        margin: auto;
      }
      .checkboxItemWrap {
        width: 100%;
        .checkboxItem {
          transition: $transition;
          padding: setUnit(10) setUnit(16);
          height: setUnit(44);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-right: 0;
          &:hover {
            background-color: $background-grey-2;
          }
          span[class~='ant-radio'] {
            display: none;
            & + span {
              padding: 0;
              font-size: setUnit(14);
              font-weight: 400;
              line-height: setUnit(16);
              letter-spacing: setUnit(-0.09);
            }
          }
          &:after {
            content: '';
            transform: scaleX(-1) rotate(45deg);
            height: setUnit(5.67);
            width: setUnit(10);
            border-bottom: 2px solid $green-color;
            border-right: 2px solid $green-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
          }
          &[class~='ant-radio-wrapper-checked'] {
            &:after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .popup {
    .list {
      list-style: none;

      .item {
        cursor: pointer;
        height: setUnit(40);
        display: flex;
        align-items: center;
        padding: 0 setUnit(16);
        font-size: setUnit(14);
        font-weight: 400;

        .text {
          margin-left: setUnit(10);
        }

        svg {
          vertical-align: middle;
        }

        &:hover {
          background: $hover-light-gray;
        }
      }
    }
  }
}
