@import 'styles/functions';
@import 'styles/variables';

.propertyType {
  margin: 0;
}

.content {
  position: relative;
  max-height: 50vh;
  overflow-y: auto;
  padding: setUnit(20);

  .field {
    input {
      padding-left: 0 !important;
    }
  }
  .label {
    margin-bottom: 0.75rem;
  }

  .selectWrapper {
    margin: 0;
  }

  .address,
  .city {
    position: relative;
  }

  .city {
    z-index: 1;
  }

  .clientNameContainer {
    position: relative;
    display: flex;

    .clientNameInput {
      input {
        padding-right: 30px;
      }
    }

    .removeClientName {
      cursor: pointer;
      position: absolute;
      bottom: setUnit(13);
      right: setUnit(3);

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
