@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #181d2c;
  box-sizing: border-box;
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}
