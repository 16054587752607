@import 'styles/variables';
@import 'styles/functions';

.templateContent {
  width: 100%;

  .templateFieldsWrapper {
    .inputBlock {
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: setUnit(20);
      }

      .label {
        font-size: setUnit(14);
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
        margin-bottom: 12px;
        color: $text-color;
      }

      .inputHolder {
        width: 100%;
        [class*='inputField'] {
          padding-left: 0px !important;
        }

        input {
          font-size: setUnit(16);
        }

        [class~='ant-select-selector'] {
          border: 1px solid $border-light-gray-2 !important;
          box-shadow: none !important;
          padding: setUnit(7) setUnit(13) !important;
          border-radius: 8px;
          font-size: setUnit(16) !important;
          height: setUnit(48) !important;

          &:focus {
            border-color: $text--light-gray-color !important;
          }

          [class~='ant-select-selection-placeholder'] {
            color: $light-gray-2;
            left: 12px;
          }
        }

        [class~='ant-select-open'] {
          [class~='ant-select-selector'] {
            border-color: $text--light-gray-color !important;
          }
        }

        .inputHolderDropdown {
          left: 0px !important;
          [class~='ant-select-item-option'] {
            padding: 16px 24px !important;
            height: setUnit(48);
            display: flex;
            align-items: center;
            flex-flow: row;

            [class~='ant-select-item-option-content'] {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.09px;
              color: $dark-color;
              padding: 0;
              justify-content: space-between;

              &::before {
                display: none;
              }

              &::after {
                content: '';
                border: 1.5px solid #aaabab;
                border-radius: 4px;
                width: 24px;
                height: 24px;
              }
            }

            &[class~='ant-select-item-option-selected'] {
              background-color: $background-grey-2 !important;

              [class~='ant-select-item-option-content'] {
                &::after {
                  display: none;
                }
              }

              [class~='ant-select-item-option-state'] {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }

    .categorySelect {
      [class~='ant-select-selector'] {
        &::after {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzc0NzQ3NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
          background-repeat: no-repeat;
          width: 14px;
          height: 8px;
        }
      }
    }
  }
}
