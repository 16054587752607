@import 'styles/functions';
@import 'styles/variables';

.userInfo {
  padding: setUnit(0) setUnit(24) setUnit(40);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .infoWrapper {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    .clientInfo {
      flex: 0 0 calc(100% - #{setUnit(130)});
      display: flex;
      align-items: center;
      .text {
        margin-left: setUnit(15);
        display: flex;
        align-items: center;
        .description {
          font-size: setUnit(12);
          font-weight: 300;
          margin-bottom: 0;
        }

        .name {
          font-size: setUnit(24);
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }

  .backButton {
    border: 0;
    display: flex;
    align-items: center;
    max-width: setUnit(30);
    padding: 0;
    width: 100%;
    .icon {
      display: flex;
      svg {
        width: setUnit(24);
        height: setUnit(24);
      }
    }
    .text {
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-weight: 500;
      color: $text-color;
    }
  }
}
