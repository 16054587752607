@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.editModal {
  .heading {
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-color;
    margin-bottom: 2rem;
  }
  .progressBar {
    display: none;
  }
  [class~='ant-modal-content'] {
    padding-right: 0;
    background-color: $background-ligh-gray-3;
    [class~='ant-modal-body'] {
      max-height: 75vh;
      overflow-y: scroll;
      padding-right: 32px;
    }
  }
  .content {
    padding-bottom: 40px;
    margin-bottom: 24px;
    border-bottom: 1px solid $light-gray;
    .condoIcon,
    .details {
      display: inline-block;
    }

    .details {
      margin-left: 0.75rem;
      position: relative;
      top: 0.75rem;

      .address {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: -0.18px;
        color: $text-color;
      }

      .subDetails {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p {
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.18px;
          font-weight: 400;
          color: $text-color;
          margin-bottom: 0;
          span {
            font-weight: 600;
          }
        }
        .separator {
          width: 3px;
          height: 3px;
          margin: 0 8px;
          border-radius: 50%;
          background: $text--light-gray-color;
        }
      }
    }
  }
}

#toastContainerId {
  @include customSuccessToast;
}
