@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 setUnit(-6) setUnit(4);
  padding: setUnit(20) 0;
  border-radius: setUnit(8);
  background-color: $white;
  @include transition;
  cursor: pointer;
  &:hover {
    background-color: $background-ligh-gray;
  }
  .avatarWrap {
    flex: 0 0 setUnit(52);
    width: setUnit(52);
    padding: 0 setUnit(6);
    &.multipleAvatar {
      & div[class='ant-avatar-group'] {
        & span[class*='ant-avatar-circle'] {
          width: setUnit(28);
          height: setUnit(28);
          &:nth-child(2) {
            margin-left: setUnit(-20);
            margin-top: setUnit(8);
          }
          &:nth-child(3) {
            margin-left: setUnit(-20);
            margin-top: setUnit(16);
          }
          & span[class='ant-avatar-string'] {
            font-size: 12px;
            line-height: 12px;
            top: 30%;
          }
        }
      }
    }
    .avatar {
      width: setUnit(40);
      height: setUnit(40);
      overflow: hidden;
    }
  }
  .contentArea {
    padding: 0 setUnit(6);
    flex: 0 0 calc(100% - #{setUnit(162)});
    .taskTitle {
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: setUnit(4);
    }
    .taskSubTitle {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(16);
      letter-spacing: -0.09px;
      color: $dark-color;
      margin-bottom: 0;
    }
  }
  .taskMeta {
    padding: 0 setUnit(6);
    flex: 0 0 setUnit(110);
    .dueDate,
    .taskStatus {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(16);
      text-align: right;
      letter-spacing: -0.09px;
      margin-bottom: setUnit(4);
      color: $dark-color;
    }
    &.isDue {
      .dueDate,
      .taskStatus {
        color: $error-color;
      }
    }
    .taskStatus {
      margin-bottom: 0;
    }
  }
}
