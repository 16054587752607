@import 'styles/functions';
@import 'styles/variables';

.confirmation {
  color: $text-color;
  font-size: 16px;
  width: 480px;
  margin: 0 auto;

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 16px;
  }

  .detailArea {
    overflow: auto;
    padding: 24px 0;
    border-bottom: 1px solid $light-gray;
    .agentName {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: 16px;
    }
    .agentPhone {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.18px;
      color: $text-color;
      margin-bottom: 16px;
    }
    .agentEmail {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.18px;
      color: $link-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footerArea {
    margin-top: 30px;
    overflow: auto;
    padding: 15px 0px;
  }

  .headWrapper {
    display: block;
    padding: 2rem 0.75rem 3.5rem 0.75rem;
    text-align: center;
    width: 100%;

    .headTitle {
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      margin-bottom: setUnit(16);
      color: $text-color;
      @media (max-width: 767px) {
        font-size: setUnit(24);
        line-height: setUnit(30);
      }
    }
    .headTitleDescription {
      font-size: setUnit(16);
      line-height: setUnit(21);
      color: $dark-grey-color;
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .headWrapper {
    padding: 0rem 0.75rem 1.5rem 0.75rem;

    .headTitle {
      font-size: setUnit(20);
    }
  }

  .confirmation {
    width: 100%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .headWrapper {
    padding: 0rem 0.75rem 1.5rem 0.75rem;

    .headTitle {
      font-size: setUnit(20);
    }
  }

  .confirmation {
    width: 100%;
    text-align: center;
  }

  .detailArea {
    text-align: center;
  }
}
