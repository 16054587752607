@import 'styles/functions';
@import 'styles/variables';

.InfoTooltipInfo {
  display: inline-block;
  cursor: pointer;
  margin-left: setUnit(5);
}

.InfoTooltip {
  top: 100px !important;

  [class*='ant-tooltip-inner'] {
    width: 400px;
    padding: setUnit(20);
    border-radius: 1rem;

    @media (max-width: $desktop) {
      width: auto;
    }
  }

  @media (max-width: $tablet) {
    top: unset !important;
  }
}
