@import 'styles/variables';
@import 'styles/functions';

.dateInput {
  text-align: center;
  .picker {
    width: 100%;
    border: 0 !important;
    border-bottom: 2px solid $border-input-middle-gray !important;
    border-radius: 0 !important;
    min-height: unset;
    padding: 0;
    [class~='ant-picker-input'] {
      input {
        padding: 12px 0 16px;
        font-size: setUnit(18) !important;
        font-weight: 400;
        line-height: setUnit(24) !important;
        letter-spacing: -0.09px;
        height: setUnit(52) !important;
        &::placeholder {
          color: $text--light-gray-color !important;
          font-weight: 400;
          font-size: setUnit(16) !important;
        }
      }
    }
    &[class~='ant-picker-focused'] {
      box-shadow: none !important;
      border-bottom-color: $text-gray-color !important;
    }
  }
}
