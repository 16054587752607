@import 'styles/functions';
@import 'styles/variables';

.ClientProfileRemoveAddress {
  .spacing {
    width: 100%;
    [class~='ant-space-item'] {
      width: 100%;
    }
  }

  .clientName {
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.89px;
    color: $secondary-color;
    margin-bottom: 10px;
  }
  .clientActions {
    width: 100%;
  }
  .removeAddress {
    display: flex;
    align-items: center;
    border: 2px solid $light-gray;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;

    .addressBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;

      .addressInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .addressLocation {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.89px;
          color: $text-color;
        }

        .addressState {
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.09px;
          color: $dark-color;
        }
      }
    }

    .actionIconContainer {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .actionBtn {
        cursor: pointer;
      }
    }
  }

  .primaryAddressBlock {
    display: flex;
    align-items: center;

    .primaryAddressText {
      margin-left: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: $text-color;
    }
  }
}
