@import 'styles/functions';
@import 'styles/variables';

.mainContentWrapper {
  max-width: 100vw;
  background-color: $background-ligh-gray-3;
  padding: 16px 40px;

  @media screen and (max-width: $mobile) {
    padding: 16px 12px;
  }

  .graphContainer {
    margin-bottom: 16px;
  }

  .bottomWidgetContainer {
    display: flex;
    justify-content: space-between;
  }
}
.cardWrapperStyles {
  height: setUnit(395) !important;
  margin-top: setUnit(24) !important;
}
