@import 'styles/functions';
@import 'styles/variables';

.agentSelectorModal {
  [class~='ant-modal-content'] {
    border-radius: 24px;
    display: inline-block;
    width: 100%;
    padding: 40px 25px 40px 40px;
  }
  .modalHeader {
    display: flex;
    justify-content: left;

    .modalTitle {
      text-align: center;
      font-weight: 700;
      font-size: setUnit(24);
      line-height: setUnit(32);
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: setUnit(32);
      color: $text-color;
    }
  }
  .modalWrapper {
    .searchWrapper {
      margin-right: 15px;
      .inputWrap {
        display: flex;
        align-items: center;
        width: 100% !important;
        margin-bottom: 10px;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px;
        letter-spacing: -0.18px;
        color: $text--light-gray-color;
        background: $white;
        border: 2px solid $border-light-gray-2;
        border-radius: 8px;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        @media (max-width: 991px) {
          height: setUnit(36);
        }
        &:focus {
          border-width: 2px;
        }
        .icon {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          transform: unset;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $text--light-gray-color;
            }
          }
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: setUnit(8);
          display: none;
          height: 48px;
          max-width: 90% !important;
        }
      }
    }
    .modalContentWrapper {
      height: 530px;
      overflow: auto;
      .agentList {
        display: flex;
        padding: 12px 0px;
        gap: 10px;
        margin-right: 15px;
        align-items: center;
        border-bottom: 1.5px solid $background-grey-2;

        .profileAvatar {
          width: 36px;
          height: 36px;
        }
        .agentName {
          color: $dark-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
          margin: 0;
        }

        .selectedAgentName {
          font-weight: 500;
          color: $text-color;
          letter-spacing: -0.09px;
        }
        .checkIcon {
          transition: $transition;
          height: setUnit(20);
          color: $green-color;
          margin-left: auto;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $green-color;
            }
          }
        }
      }
    }
    .agentModalFooter {
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;

      .submitButton {
        border-radius: 8px;
        background-color: $secondary-color;
        color: $white;
        font-size: setUnit(16);
        font-weight: 600;
        padding: 5px 10px;
        width: 99px;
        height: 52px;
        border-radius: 8px;
        &:hover {
          background: $secondary-color-hover;
        }
      }
    }
  }
}
