@import "styles/functions";
@import "styles/variables";

.values {
    margin-top: setUnit(30);
    overflow: auto;
    border-top: 2px solid $border-light-gray;
    height: calc(100vh - #{setUnit(330)});

    .value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: setUnit(12) 0;

        .inner {
            display: flex;
            align-items: center;
        }

        .close {
            cursor: pointer;
        }

        &:hover {
            background: $background-ligh-gray;
        }

        &:first-child {
            margin-top: setUnit(16);
        }
    }

    .avatar {
        width: setUnit(56);
        height: setUnit(56);
    }

    .name {
        margin-left: setUnit(16);
        font-weight: 600;
    }
}
