@import 'styles/functions';
@import 'styles/variables';

.addCommute {
  width: fit-content;

  .wrapper {
    display: flex;
    align-items: center;
    margin-top: setUnit(26);
  }

  .icon {
    cursor: pointer;
    svg {
      width: setUnit(20);
      height: setUnit(20);
      vertical-align: middle;
    }
  }

  .text {
    font-weight: 400;
    color: $primary-color;
    margin: 0.2rem 1.125rem 0;
    cursor: pointer;
  }
}
