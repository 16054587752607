@import 'styles/variables';

.buttonRow {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.templateButton {
  height: 44px;
  overflow: hidden;

  &.buttonMargin {
    margin-right: 16px;
  }
  &:hover {
    cursor: pointer;
    background-color: $background-ligh-gray-3;
  }

  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 28px;
  border: 2px solid $light-gray;

  .name {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $dark-color;
    margin-left: 13px;
  }
}
