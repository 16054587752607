@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  overflow: hidden;
  display: flex;
  height: setUnit(460);
  flex-wrap: nowrap;

  img {
    max-height: setUnit(460);
    transition: $transition;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }
}

.bigImage {
  flex: 0;
  min-width: 100%;

  img {
    width: 100%;
    height: 460px;
    object-fit: cover;
  }
}

.smallImages {
  max-height: setUnit(460);
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.middleImage {
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-left: 2px solid transparent;

  img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}

.image {
  width: 50%;
  height: setUnit(230);
  overflow: hidden;
  border-left: 2px solid transparent;

  &:nth-of-type(odd) {
    border-bottom: 2px solid transparent;
  }

  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}
