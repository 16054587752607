@import 'styles/functions';
@import 'styles/variables';

.documentReviewers {
  .label {
    font-size: setUnit(16);
    font-weight: 600;
  }

  .userIcon {
    display: flex;

    path {
      stroke-width: 1.5;
    }
  }

  .internalUserIcon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .lockIcon {
    svg {
      width: 21px;
      height: 21px;
    }
  }

  .everyoneReviewerName {
    font-weight: 600;
  }

  .reviewersList {
    max-height: 235px;
    overflow-y: auto;
    padding-right: 14px;
    margin-right: -14px;

    .internalMember {
      font-size: setUnit(14);
      color: $text--light-gray-color;
      font-weight: 400;
      margin-top: 2px;
    }
  }
}
