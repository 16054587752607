@import 'styles/variables';

.baseSelect {
  & [class*='ant-select-selector'] {
    border-radius: 8px;
  }

  & [class*='ant-select-item-option-selected'] {
    background: $white;
  }
}

.largeSelect {
  border-radius: 8px;
  & [class*='ant-select-item-option'] {
    padding: 0px 12px;
    height: 52px;
  }
  & [class*='ant-select-selection-overflow-item'] {
    & [class*='ant-select-selection-item'] {
      height: 34px;
      line-height: 34px;
    }
  }
  & [class*='ant-select-selection-item-content'],
  & [class*='ant-select-selection-placeholder'],
  & [class*='ant-select-item-option-content'],
  & [class*='ant-picker-input'] > input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }

  & [class*='ant-select-item-option-content'] {
    display: flex;
    align-items: center;
    height: 52px;
  }

  & [class*='ant-select-item-option-state'] {
    display: flex;
    align-items: center;
  }

  & [class*='ant-select-selector'] {
    border-radius: 8px !important;
    min-height: 48px;
  }
  & [class*='ant-select-selection-item'] {
    display: flex;
  }

  & [class*='ant-select-selection-search'] {
    display: flex;
    align-items: center;
  }
}
