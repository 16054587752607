@import "styles/functions";
@import "styles/variables";
@import "styles/mixins";

.archived {
    @include box-shadow;

    width: 100%;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: setUnit(40);
    padding: 0 setUnit(10);
    margin: 0 auto;
    background: #ffffff;

    .text {
        font-size: setUnit(14);
        color: $secondary-color;
        margin-left: setUnit(8);
    }

    .svg {
        vertical-align: middle;
    }
}
