@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.memberEmailWrapper {
  margin-top: 19px;
  .memberInput {
    margin-bottom: 19px;
    margin-top: 12px;
    input {
      @include lightRounded;
    }
  }

  .emailError {
    &::before {
      padding: 0px !important;
      font-size: setUnit(10) !important;
    }
  }
}

.displayInputLabelWrap {
  display: flex;
}

.inputLabel {
  @include inputLabelContainer;
  color: $text-color;
  margin-bottom: 0;
  font-weight: 600;
}

.inputLabelOptional {
  @include inputLabelContainer;
  color: $text--light-gray-color;
  margin-bottom: 0;
  font-weight: 400;
  margin-left: 3px;
}

.errorClass {
  font-size: 0.75rem !important;
  color: $error-color;
  padding-top: 0.5rem;

  &::before {
    content: '\0021';
    font-weight: bold;
    color: $white;
    width: setUnit(14);
    height: setUnit(14);
    display: inline-block;
    background: $error-color;
    text-align: center;
    border-radius: 100%;
    font-size: setUnit(10) !important;
    padding: 0px !important;
    margin-right: 0.5em;
  }
}
