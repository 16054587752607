@import 'styles/functions';
@import 'styles/variables';

.calendarHeader {
  padding: 0 1.25rem;

  p {
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
  }

  .actions {
    float: right;

    .prevIcon,
    .nextIcon {
      display: inline-block;
      cursor: pointer;
    }

    .prevIcon {
      margin-right: 2rem;
    }
  }
}

.calendarWrapper {
  [class*='ant-picker-panel'] {
    border-top: none !important;

    [class*='ant-picker-content'] {
      thead {
        th {
          color: $text--light-gray-color;
        }
      }

      tbody {
        [class*='ant-picker-cell-disabled'] {
          &::before {
            background: none;
          }
        }

        [class*='ant-picker-calendar-date-today'] {
          &::before {
            border: none;
            border-bottom: 1px solid $background-blue;
          }
        }

        [class*='ant-picker-cell-selected'] {
          &::before {
            width: 40px;
            background: $background-gray;
            height: 40px;
            border-radius: 48px;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          [class*='ant-picker-calendar-date'] {
            color: $background-blue;
            font-weight: 600;
            background: none;
          }
        }
      }
    }
  }
}
