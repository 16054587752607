@import 'styles/variables';
@import '../../mixins.module.scss';

.deleteModal {
  .deleteIcon {
    @include modalIcon(#fcefef);
  }

  & [class*='ant-btn-primary'] {
    @include modalButton($primary-color, $primary-color-hover, $white, 600);
  }

  & [class*='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & [class*='ant-typography'] {
    text-align: center;
    font-weight: 600;
    margin: 0px;
  }

  [class*='ant-modal-footer'] {
    margin-top: 16px !important;
  }
}
