@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.enableLink {
  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }
  .content {
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
    margin-bottom: setUnit(16);

    .loadingSpinner {
      font-size: 0.5em;
      margin: 50px auto;
    }

    .enableLinkHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: setUnit(24);

      .switch {
        height: 32px;
        width: 56px;

        [class*='ant-switch-handle'] {
          top: 3px;
          left: 3px;
          width: 26px;
          height: 26px;
          &::before {
            border-radius: 50%;
          }
        }
      }

      .switchChecked {
        [class*='ant-switch-handle'] {
          left: calc(100% - 26px - 3px);
        }
      }
    }

    .linkContainer {
      padding-bottom: setUnit(12);
      border-bottom: 2px solid $border-light-gray-2;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      a {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: setUnit(19);
        max-width: calc(100% - #{setUnit(100)});
        color: $link-blue;
      }

      .copyBtn {
        cursor: pointer;
        padding: setUnit(8) setUnit(6);
        border-radius: setUnit(8);
        background-color: transparent;
        transition: $transition;
        &:hover {
          background-color: $background-grey-2;
        }
        &:active {
          background-color: $light-gray;
        }

        .copyIcon {
          display: inline-block;
          margin-right: 0.25rem;
        }

        span {
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(20);
          color: $secondary-color;
        }
      }
    }

    .disableLink {
      color: $text--light-gray-color;
    }
  }
  .footer {
    padding-right: setUnit(16);
  }
}
