@import 'styles/functions';
@import 'styles/variables';

.pendingWrapper {
  height: 100%;
  .field input,
  .locationsValues,
  .parkingSpaces > div {
    height: setUnit(48);
  }
  .field {
    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
  }
}

.pseudo {
  position: relative;
  .pseudoPlaceholder {
    position: absolute;
    top: setUnit(43);
    right: setUnit(16);
    font-weight: 400;
    color: $border-middle-gray;
  }
  .hoa {
    input {
      padding-right: setUnit(100);
    }
  }
}

.buttonGroup {
  margin: 0 !important;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .submitButton {
    max-width: setUnit(102);
    width: 100%;
  }
  .resetBlock {
    display: flex;
    position: relative;
    max-width: setUnit(102);
    width: 100%;
    .resetSpan {
      display: flex;
      align-items: center;
      :hover {
        color: $hover-blue;
      }
      .resetIcon {
        margin-right: 8px;
        display: flex;
      }
      .resetAllText {
        font-family: $font-family;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $dark-color;
      }
    }
  }
}

.filterPropertyWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .filterItem {
    flex: 0 0 50%;
    &:first-child {
      border-top-left-radius: setUnit(8);
    }
    &:last-child {
      border-bottom-right-radius: setUnit(8);
    }
    &:nth-child(2) {
      border-top-right-radius: setUnit(8);
    }
    &:nth-child(9) {
      border-bottom-right-radius: setUnit(8);
      border-bottom-left-radius: setUnit(8);
      flex: 0 0 100%;
    }
    @media (min-width: $mobile) {
      flex: 0 0 33.3333%;
      &:nth-child(2) {
        border-top-right-radius: setUnit(0);
      }
      &:nth-child(3) {
        border-top-right-radius: setUnit(8);
      }
      &:nth-child(4) {
        border-bottom-left-radius: setUnit(0);
      }
      &:nth-child(7) {
        border-bottom-left-radius: setUnit(8);
      }
      &:nth-child(9) {
        border-bottom-left-radius: setUnit(0);
        flex: 0 0 33.333%;
      }
    }
    & > div {
      min-height: setUnit(80);
    }
  }
}
