@import 'styles/variables';

.to {
  font-size: 18px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.09px;
  margin: 0 24px !important;
  color: $text-color !important;
}
.checkboxContainer {
  margin-top: 20px;
  .label {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
}
