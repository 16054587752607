@import 'styles/variables';
@import 'styles/functions';

.declineFormModal {
  .icon {
    display: block;
    margin: 0 auto;
  }

  [class~='ant-modal-title'] {
    text-align: center;
    font-size: setUnit(24);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.46px;
    color: $text-color;
  }

  [class~='ant-modal-content'] {
    [class~='ant-modal-header'] {
      margin-bottom: 32px;
    }

    [class*='ant-modal-close'] {
      [class*='ant-modal-close-x'] {
        top: 3px;
      }
    }
  }

  @media screen and (max-width: $mobile-small) {
    [class~='ant-modal-content'] {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
