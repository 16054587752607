@import 'styles/functions';
@import 'styles/variables';

.preApprovalsModal {
  padding: 2.5rem;

  h2 {
    font-weight: bold;
    margin-bottom: 0;
  }

  h4 {
    color: $black;
    font-weight: 600;
  }

  .myUploads {
    margin: 2rem 0 1rem;
    padding: 1rem 0 1.5rem;
    border-top: 2px solid $light-gray;
    border-bottom: 2px solid $light-gray;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .uploadBtn,
    .requestBtn {
      width: 6.875rem;
    }

    .uploadBtn {
      .uploadIcon {
        display: inline-block;
        margin-right: 0.5rem;
        margin-top: 0.25rem;
      }

      span {
        position: relative;
        top: -0.25rem;
      }
    }
  }
}
