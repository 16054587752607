@import 'styles/functions';
@import 'styles/variables';

.threadCreationSpinner {
  height: 100%;
}

.subheaderWrapper {
  padding-top: 0;
  border-bottom: 2px solid $light-gray;

  &.isClient {
    //padding-bottom: 0;
  }

  .valuesClassName {
    [testid='flat_value'] {
      background: #fff2f3;
      border: 1px solid $primary-color;
      margin-right: 5px;
      max-width: 300px;
    }
  }

  .prefixParticipants {
    top: setUnit(15);
    color: #747475;
  }
}
