@import 'styles/functions';
@import 'styles/variables';

.header {
  background-color: $white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: setUnit(34) setUnit(40);
  .elevate {
    z-index: 9;
  }

  .title {
    position: absolute;
    display: flex;
    justify-content: center;
    color: $text-color;
    text-align: center;

    font-family: Inter, sans-serif;
    font-size: setUnit(32);
    font-weight: 700;
    line-height: setUnit(36);
    letter-spacing: -0.4px;

    width: 100%;
  }

  .backWrapper {
    border-radius: 100%;
    border: 2px solid $background-ligh-gray-4;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: setUnit(44);
    height: setUnit(44);

    cursor: pointer;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .filterBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}
