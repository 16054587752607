@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.cardItem {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-radius: setUnit(8);

  &.hoverEffect {
    background-color: $background-grey-2;
  }

  .options {
    display: flex;
    align-items: center;
    height: fit-content;

    .loader {
      color: gray;
    }

    .privateIcon {
      margin-right: setUnit(8);
    }

    .optionBtn {
      position: relative;
      .btn {
        border-radius: 100px;
        &:hover,
        &.hoverEffect {
          background-color: $light-gray;
        }
      }

      .menuOptions {
        @include box-shadow;

        position: absolute;
        top: setUnit(40);
        right: 0;
        background: #ffffff;
        border-radius: setUnit(8);
        min-width: setUnit(210);
        padding: 0;
        z-index: 10;

        ul {
          list-style: none;
          margin-bottom: 0.5rem;
        }

        .item {
          padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
          cursor: pointer;
          display: flex;
          align-items: center;

          .icon {
            margin-right: setUnit(8);
            position: relative;
            top: setUnit(-2);
          }

          &:hover {
            background: $background-ligh-gray;
          }
        }

        svg {
          vertical-align: middle;
        }
      }
    }
  }
}

.seperator {
  margin: 0 setUnit(40);
  border-bottom: 1px solid $light-gray;
}

.participant {
  display: flex;

  .avatar {
    margin-right: setUnit(12);
    width: setUnit(36);
    height: setUnit(36);
    @media (min-width: $tablet) {
      width: setUnit(48);
      height: setUnit(48);
    }
  }

  .names {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      margin-bottom: setUnit(4);
      color: $text-color;
    }

    .subtitle {
      font-weight: 400;
      line-height: setUnit(20);
      letter-spacing: setUnit(-0.18);
      color: $dark-color;
    }
    .spaceBottom {
      margin-bottom: 0.75rem;
    }
  }
}

.contactConfirmationModal {
  width: 420px !important;

  [class*='ant-modal-header'] {
    margin-bottom: 15px;
  }

  .confirmButton {
    background-color: $error-color !important;
  }

  .cancelButton {
    color: $dark-color !important;
    span {
      font-weight: 500 !important;
    }
  }

  .modalTitleIcon {
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
