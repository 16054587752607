@import 'styles/variables';
@import 'styles/functions';

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .iconBtn {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition;
    &:hover {
      border-color: $text--light-gray-color;
    }
    @media (max-width: $mobile) {
      width: 34px;
      height: 34px;
      svg {
        width: 20px;
      }
    }
    &.closeBtn {
      margin-left: auto;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      border-color: $light-gray;
    }
  }
}
