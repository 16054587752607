@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.propertiesWrapper {
  transition: all 0.15s;
  overflow-y: auto;
  position: relative;
  background: $background-ligh-gray;

  .count {
    margin: 0;
    padding: 1rem 2.5rem;
    background-color: $white;
    font-weight: 600;
  }

  .loadingPage {
    box-shadow: 0 setUnit(2) setUnit(12) rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: 0;
    background: $white;
    width: 100%;
    opacity: 0.85;
  }

  .loader {
    font-size: setUnit(4);
    margin: setUnit(25) 0;
  }
}
