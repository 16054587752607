@import 'styles/functions';
@import 'styles/variables';

.uploaded {
  padding: 0 0 setUnit(32);
  .addAnotherText {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    cursor: pointer;
  }
  .uploadDocument {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .inner {
    display: flex;
    flex-direction: column-reverse;
    gap: setUnit(40);
    padding: 0 setUnit(32) setUnit(32);
    max-height: 460px;
    overflow: auto;
    &.withCategory {
      padding: 0 setUnit(32) setUnit(0);
    }
  }

  .buttonHolder {
    text-align: center;
    justify-content: center;
    padding: 0 setUnit(32);
    margin-top: setUnit(32);
  }

  .saveButton {
    width: setUnit(152);
    height: setUnit(52);
    font-size: setUnit(16);

    &:hover {
      background: $secondary-color-hover;
    }
  }
  .uploadDocument {
    cursor: default;
    .icon {
      width: setUnit(32);
      height: setUnit(32);
      background: $background-ligh-gray;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
