@import 'styles/functions';
@import 'styles/variables';

.statusWrapper {
  padding: setUnit(6) setUnit(10);
  background: $white;
  font-size: setUnit(10);
  font-weight: 600;
  letter-spacing: setUnit(-0.09);
  border-radius: setUnit(100);
  &.Active {
    background: $white;
    color: $text-color;
  }
  &.Pending {
    background: $primary-color;
    color: $white;
  }
  &.ActiveUnderContract {
    background: $primary-color;
    color: $white;
  }
}
