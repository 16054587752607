@import 'styles/functions';
@import 'styles/variables';

.card {
  position: relative;
  width: 100%;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  border-radius: setUnit(16);
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid $background-grey-2;
  transition: $transition;
  &.active {
    border-color: $primary-color;
  }
  @media (min-width: $mobile) {
    min-height: 112px;
  }
  .logo {
    vertical-align: middle;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  .textWrapper {
    width: 100%;
    margin-top: 16px;
    max-width: 100%;
    order: 3;
    @media (min-width: $mobile) {
      max-width: calc(100% - 48px - 24px - 32px);
      margin-top: 0;
      margin-left: 16px;
      text-align: left;
      order: 2;
    }
    .title {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
    }

    .subTitle {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: $dark-grey-color;
      margin: 6px 0 0 0;
      display: -webkit-box;
      max-width: 297px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .selectWrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: 16px;
    order: 2;
    @media (min-width: $mobile) {
      order: 3;
    }
    .selectBtn {
      border: 2px solid $light-gray;
      width: 83px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      cursor: pointer;

      &.active {
        background: $primary-color-light;
        color: $primary-color;
        border: 1px solid $primary-color;

        &:hover {
          border: 1px solid $primary-color-hover !important;
        }
      }

      &:hover {
        border: 2px solid $text--light-gray-color !important;
      }
    }
  }
}
