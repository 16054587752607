@import 'styles/functions';
@import 'styles/variables';

.border {
  border-radius: 8px !important;
  .placeholder {
    border-radius: 8px !important;
  }
  input {
    border-radius: 8px !important;
  }
}

.dropdown {
  position: fixed !important;
  max-width: 620px;
  top: auto !important;
}

.fullWidth {
  width: 100%;
}
.formContainer {
  padding: 20px 40px;
  position: relative;

  .titleHeading {
    font-weight: 600;
    font-size: setUnit(20);
    color: $text-color;
    margin-bottom: 24px;
  }

  .locationInput {
    input {
      font-size: 16px;
      margin-top: -3px;
      &::placeholder {
        font-size: 16px;
      }
    }
  }

  .valuesWrapper {
    padding-left: 0;
  }

  .inputContainer {
    margin-top: 24px;
    margin-bottom: setUnit(8);
    input {
      margin-top: setUnit(10);
      height: setUnit(48);
      width: 100%;
      padding-left: 0;
      @media (min-width: $mobile) {
        width: 100%;
      }
    }
    label {
      margin: 0 setUnit(45) 0 0;
      width: 26%;
      span {
        font-weight: bold;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
      }
    }
  }
  .inputContainerAddress {
    @extend .inputContainer;
    input {
      width: 100%;
    }
  }

  .propertyContract {
    margin: setUnit(48) 0 setUnit(32);

    .selectType {
      margin-top: 16px;
      display: flex;
    }
    .selectOption {
      width: 100%;
      color: $dark-color;
      border: 2px solid $light-gray;
      border-radius: 8px;
      padding: 20px 0 20px 20px;

      &:hover {
        cursor: pointer;
        color: $text-color;
        border: 2px solid $border-light-gray-2;
      }

      &:not(:last-of-type) {
        margin-right: 24px;
      }

      label {
        span {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .selectChecked {
      background-color: $primary-color-light;
      border: 1px solid $primary-color !important;
      color: $text-color;
      padding: 21px 1px 21px 21px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      color: $text-color;
      margin-bottom: 16px;
    }
  }

  .agentNotes {
    margin-top: setUnit(32);
    .title {
      margin-bottom: setUnit(32);
    }
    .altLabel {
      font-weight: normal;
      color: $border-middle-gray;
      margin-right: auto;
      margin-left: setUnit(8);
    }
  }
}
