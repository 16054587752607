@import 'styles/functions';
@import 'styles/variables';

.leftPanel {
  width: 100%;
  .detailArea {
    border-radius: 16px;
    padding: 28px 24px;
    background-color: $white;
    max-width: 280px;
    h4 {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      color: $text-gray-color;
      text-align: left;
    }
  }
  .notesArea {
    border-radius: 16px;
    padding: 28px 24px;
    background-color: $white;
    max-width: 280px;
    word-wrap: break-word;
    h4 {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(20);
      letter-spacing: -0.18px;
      color: $text-gray-color;
      text-align: left;
    }
  }
  .loader {
    font-size: setUnit(4);
    color: #ffffff;
  }
}

.bottomPaddingNotes {
  margin-bottom: setUnit(40);
}
.bottomPaddingNotesScrolledAtBottom {
  margin-bottom: 0px;
}
.bottomPadding {
  margin-bottom: setUnit(8);
}
.bottomPaddingScrolledAtBottom {
  margin-bottom: 0px;
}

.detailAreaScrolledNotAtBottom {
  margin-bottom: setUnit(32);
}

.detailAreaScrolledAtBottom {
  margin-bottom: setUnit(32);
}

@media (max-width: $mobile) {
  .leftPanel {
    text-align: center;
    width: 100%;
  }

  .detailArea {
    display: inline-block;
    width: 30%;
    vertical-align: top;
  }

  .notesArea {
    display: inline-block;
    width: 30%;
    vertical-align: top;
  }
}
@media (max-width: 767px) {
  .detailArea,
  .notesArea {
    width: 33.3333%;
  }
}

@media (max-width: 564.99px) {
  .detailArea {
    width: 50%;
  }
  .notesArea {
    width: 100%;
  }
}
