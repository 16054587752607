@import 'styles/variables';

.datePicker {
  border-radius: 8px !important;
  min-height: 48px;
  border: 1px solid $border-light-gray-2 !important;
  box-shadow: none !important;
  &:hover,
  &:active {
    border: 1px solid $border-light-gray-2 !important;
  }
}

.rangePicker {
  border-radius: 8px !important;
  min-height: 48px;

  & [class*='ant-picker-range-separator'] {
    min-height: unset;
  }
}
