@import 'styles/functions';
@import 'styles/variables';

.clarityHeader {
  justify-content: center;
  padding: 16px 24px;

  @media (min-width: $tablet) {
    padding: 30px 40px;
  }
  & [class*='ant-select-arrow'] {
    font-size: 14px;
  }

  .switcherWrapper {
    text-align: center;
    margin: setUnit(16) 0;
    @media (min-width: $tablet) {
      margin: 0;
    }

    .title {
      color: $text-color;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;
    }
  }

  .filterWrapper {
    display: flex;
    position: absolute;
    right: 35px;

    .closeButton {
      border: 2px solid $light-gray;
      border-radius: 50%;
      padding: 7px;
      height: 42px;
      width: 42px;
      cursor: pointer;
    }
  }
}
