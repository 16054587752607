@import 'styles/variables';

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 34px 0 54px;
  .separator {
    width: 1px;
    height: setUnit(24);
    background: $border-middle-gray;
  }

  .prev {
    border-radius: 50%;
    border: 2px solid $light-gray;
    background: none;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $transition;
    &:hover {
      border-color: $text--light-gray-color;
      svg {
        opacity: 1;
        path {
          stroke: $text-color;
        }
      }
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }
  .prev {
    svg {
      transform: rotate(-90deg);
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.closeIcon {
  border-radius: 50%;
  border: 2px solid $light-gray;
  background: none;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $transition;
  &:hover {
    border: 2px solid $text--light-gray-color;
    svg {
      opacity: 1;
      path {
        fill: $text-color;
      }
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: 40px;
  width: 40px;
}

.bottom {
  height: $responsive-footer-height;
  display: flex;
  align-items: center;
  .continueBtn {
    font-size: 16px !important;
    border: none !important;
    display: flex !important;
    width: 138px !important;
    height: 52px !important;
    justify-content: center;
    border-radius: 8px;
    margin-left: auto;
    span {
      align-self: center;
    }

    div {
      align-self: center;
      margin-left: 13px;

      svg {
        path {
          stroke: $white;
        }
      }
    }

    &:disabled {
      opacity: 0.35;
    }
  }
}
