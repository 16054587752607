@import 'styles/variables';

.permissionButtonsFooterContainer {
  border-top: 1px solid $background-ligh-gray-4;
  display: flex;
  padding: 0px 40px;
  height: 80px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  z-index: 10;

  [class~='container'] {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    max-width: 75rem;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .reset {
      border-radius: 8px;
      background: $white;
      color: $text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
      & > div {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-direction: row-reverse;
      }
    }

    .continueBtn {
      height: 52px;
      width: 157px;
      border-radius: 12px;
      color: $white;
      font-weight: 600;
    }

    .disabledBtn {
      opacity: 0.5;
    }
  }
}

.transactionTaskFooter {
  padding: 0px 40px 0px 25px;
  left: 288px;
}
