@import 'styles/functions';
@import 'styles/variables';

.appointmentDetailsModal {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }

  .heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-color;
    margin: 0;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 80px 20px 0;
  }

  .actions {
    .status {
      padding: 0.5rem 0.875rem;
      background: $background-grey-2;
      border-radius: 6.25rem;
      margin-right: 0.5rem;
      display: inline-block;
      height: 2.5rem;

      span {
        color: $dark-color;
        font-weight: 500;
      }
    }

    .pending {
      background: $primary-color-light;

      span {
        color: $primary-color;
      }
    }

    .upcoming {
      background: rgba(0, 177, 82, 0.1);

      span {
        color: $green-color;
      }
    }

    .megaPhoneIcon {
      cursor: pointer;
      background: $background-grey-2;
      border-radius: 6.25rem;
      width: 2.5rem;
      height: 2.5rem;
      display: inline-block;
      position: relative;
      top: -0.2rem;

      svg {
        position: relative;
        top: 50%;
        left: 48%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }

  .content {
    max-height: calc(100vh - 16.5rem);
    overflow-y: auto;
    padding-right: 2rem;

    .action {
      border-top: 1px solid $light-gray;
      padding: 1.5rem 0 0;
      text-align: center;

      .cancelBtn {
        width: 100%;

        .icon {
          display: inline-block;
          position: relative;
          top: 0.3rem;
          margin-right: 0.5rem;
        }

        span {
          color: $primary-color;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .appointmentDetailsModal {
    [class='ant-modal-content'] {
      padding: 2rem 0rem 2rem 1rem;
    }

    .heading {
      margin-right: 2rem;
    }

    .content {
      padding-right: 1rem;
    }
  }
}
