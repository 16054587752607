@import 'styles/variables';
@import 'styles/functions';

.activityPage {
  max-height: calc(100vh - $header-height - $workshop-header-height);
  overflow-y: auto;
  background-color: $background-ligh-gray-3;
  .wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}
