@import 'styles/variables';

.replaceEntityModal {
  [class*='ant-modal-title'] {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.09px;
    color: $text-color;
  }
  .formRow {
    margin-bottom: 5%;
  }
  .content {
    margin-left: 11px;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.09px;
    color: $text-color;
    margin-bottom: 5px;
  }
  .borderBottom {
    border-bottom: 2px solid #d6d6d7;
  }
  .updateBtnDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    .disabledBtn {
      opacity: 50%;
    }
  }
  .updateBtn {
    width: 210px;
    height: 52px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.09px;
    border-radius: 8px;
  }

  .autoCompleteInput {
    width: 380px !important;
    border-bottom: 2px solid $border-input-middle-gray !important;

    [class*='ant-select-selector'] {
      align-items: center;
      border: none !important;

      [class*='ant-select-selection-placeholder'] {
        font-weight: 400;
        font-size: 16px;
        color: $text-gray-color;
      }

      [class*='ant-select-selection-search'] {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .disabledInput {
    opacity: 50%;
  }
}
