@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  position: relative;
  .searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: setUnit(16);
    z-index: 1;
    svg {
      vertical-align: middle;
      width: setUnit(16);
      height: setUnit(16);
    }
  }
  .input {
    margin: 0;
    height: setUnit(40);
    font-size: setUnit(14);
    line-height: setUnit(16);
    border-radius: setUnit(8);
    padding-left: setUnit(40);
    background: $hover-light-gray;
    width: 100%;
    border: 1px solid $hover-light-gray;
    outline: none;
    transition: 0.3s background;
    vertical-align: middle;
    color: #262626;

    &:focus {
      background: $white;
      border: 1px solid $border-middle-gray;
      box-shadow: none;
    }
    &::placeholder {
      color: #747475;
    }
  }
  &.expanded {
    width: 344px !important;
    padding-left: setUnit(16) !important;
    .inputExpanded {
      max-width: setUnit(344);
      width: 100% !important;
      display: block !important;
    }
  }
}

.spinner {
  width: setUnit(16);
  height: setUnit(16);
  position: absolute;
  top: calc(50% - #{setUnit(8)});
  right: setUnit(11);

  .spinnerLoader {
    font-size: 2px;
    width: 2px;
    height: 2px;
  }
}

.clearInput {
  background: transparent;
  width: 20px;
  height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  transition: $transition;
  &:hover {
    opacity: 0.7;
  }
  .closeIcon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
