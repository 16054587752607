@import 'styles/functions';
@import 'styles/variables';

.detailTemplate {
  height: 100%;

  .header {
    h1 {
      font-weight: 600;
      font-size: setUnit(24);
      line-height: setUnit(29);
      font-family: $font-family;
      letter-spacing: setUnit(-0.5);
      color: $text-color;
      margin-top: 0;
      margin-bottom: setUnit(8);
    }

    h2 {
      font-weight: 400;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $text-color;
      margin: 0;
    }
  }

  .content {
    margin-top: setUnit(16);
    margin-bottom: setUnit(16);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .SaveBtn {
      width: setUnit(96);
      height: setUnit(40);
      border-radius: setUnit(8);
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $secondary-color !important;

      &Text {
        margin-bottom: 0;
        color: #fff;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
      }

      &:hover {
        background-color: $secondary-color-hover !important;
      }

      .whiteLoader {
        font-size: setUnit(4);
        color: #ffffff;
      }
    }

    .btnDisabled {
      cursor: not-allowed !important;
    }

    .add {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      background-color: white;

      span {
        margin-left: setUnit(8);
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $text-color;
      }
    }
  }
}
