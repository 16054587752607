@import "styles/functions";
@import "styles/variables";

.exit {
    display: inline-flex;
    text-decoration: none;
    font-weight: 400;
    font-size: setUnit(16);
    align-items: center;
    cursor: pointer;
    margin-right: setUnit(54);
    color: $dark-grey-color;

    .close {
        position: relative;

        &:after,
        &:before {
            content: '';
            position: absolute;
            right: setUnit(10);
            top: setUnit(-1);
            display: inline-block;
            width: setUnit(18);
            height: setUnit(2);
            background: $dark-grey-color;
            border-radius: setUnit(100);
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &:hover {
        color: $text-color;

        .close {
            &:after,
            &:before {
                background: $text-color;
            }
        }
    }

    .arrow {
        margin-right: setUnit(10);
    }
}
