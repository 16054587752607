@import 'styles/variables';

.cardWrapper {
  min-height: 72px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  padding: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background: $background-grey-2;
  }
  .editIcon {
    display: flex;
    align-items: center;
  }
  .hideIcon {
    visibility: hidden;
  }
}

.detailInputWrapper {
  padding: 14px;

  .detailInput {
    margin-top: 20px;
  }

  .actionBtn {
    border-radius: 8px;
    width: 104px;
    height: 40px;
  }

  .disabledBtn {
    opacity: 0.5;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  width: 100%;

  .fieldName {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    .privateIcon {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }

  .fieldAnswer {
    font-size: 16px;
    margin-top: 6px;
    word-break: break-all;
  }
}
