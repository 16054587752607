@import 'styles/functions';
@import 'styles/variables';

.itemTitle {
  margin-bottom: setUnit(32);
  padding-right: setUnit(10);

  .title {
    font-weight: 600;
  }

  .subtitle {
    font-size: setUnit(14);
    font-weight: 400;
    color: $text-gray-color;
    margin-top: setUnit(4);
  }
}
