@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.addressListingWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .addressWrapper {
    display: flex;
    cursor: pointer;
    color: $text--light-gray-color;
    padding: 16px 20px;
    margin-bottom: 0;
    &:hover {
      background-color: $background-grey-2;
    }
  }
  .iconStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .headingWrapper {
    cursor: pointer;
    color: $text--light-gray-color;
    padding: 16px 20px;
    margin-bottom: 0;
  }
  .addressSection {
    margin-left: 8px;
  }
  .locationWrapper {
    cursor: pointer;
    color: $text--light-gray-color;
    padding: 16px 20px;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .inputFieldLocations {
      width: 100%;
    }
  }

  .addressTextStyle {
    font-weight: 500;
    font-size: 16px;
    color: $text-color;
    margin: 0;
  }
  .addressLabel {
    font-size: 14px;
    font-weight: 400;
    color: $dark-grey-color;
    margin: 0;
  }
}
