@import 'styles/functions';
@import 'styles/variables';

.confirmationModal {
  text-align: center;

  [class*='ant-modal'] {
    text-align: center;
  }

  [class*='ant-modal-title'] {
    font-weight: 600;
    font-size: 20px;
  }

  .actions {
    display: inline-flex;
    margin-top: 2rem;

    .cancelBtn {
      margin-right: 0.25rem;

      .okBtn {
        margin-left: 0.25rem;

        .activateBtn {
          background-color: $blue;
        }
      }
    }
  }

  [class*='ant-btn-primary'] {
    background-color: $primary-color;

    &:hover {
      background-color: $hover-red;
    }
  }

  [class*='ant-modal-body'] {
    & [class*='ant-typography'] {
      font-weight: 400;
      font-size: 16px;
    }
  }

  [class*='ant-modal-close'] {
    display: none;
  }
}
