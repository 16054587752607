@import 'styles/functions';
@import 'styles/variables';

.profile {
  overflow: auto;
  height: calc(100vh - 10.9375rem);
  .profileAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .messageButton {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $light-gray;
    border-radius: 20px !important;
    margin-bottom: 36px;
    color: $dark-color;
    padding: 0.45rem 0.75rem;
    height: 40px;
    &:hover {
      border: 2px solid $border-light-gray-2;
    }

    span {
      margin-left: 6px;
      font-weight: 500;
      color: $dark-color;
    }
  }

  .name {
    text-align: center;
    font-size: setUnit(20);
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .subHeading {
    text-align: center;

    p {
      color: $text-gray-color;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
    }

    .spacer {
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      background: $border-light-gray-2;
      margin: 0 0.5rem;
      border-radius: 50%;
      position: relative;
      top: -0.15rem;
    }

    .tag {
      display: inline-block;
      padding: 0.125rem 0.5rem;
      background: rgba(116, 116, 117, 0.08);
      border-radius: 40px;
      color: $dark-color;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .profileTabs {
    display: flex;

    & > div {
      width: 100%;
      padding: setUnit(14) 0;
      text-align: center;
      border-top: 1px solid $border-light-gray;
      border-left: 1px solid $border-light-gray;
      margin-right: 0;
    }
  }
  .clientLoading {
    padding: 16px;
  }
}
