@import 'styles/functions';
@import 'styles/variables';

.lenders {
  .favorites,
  .allLenders {
    display: block;

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.09px;
      margin: 0 0 4px;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $dark-color;
      margin-bottom: 24px;
    }
  }

  .allLenders {
    margin-top: 2.5rem;
  }

  .optionHolder {
    &:not(:last-child) {
      margin-bottom: setUnit(16);
    }
  }
}
