@import 'styles/variables';
@import 'styles/mixins';

.showingInformationModal {
  [class*='ant-modal-content'] {
    padding: 20px;
  }

  [class*='ant-modal-header'] {
    padding-bottom: 24px;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: 1px solid $light-gray;
  }

  .infoTitle {
    display: flex;
    justify-content: space-between;

    .addressInfo {
      .title {
        font-size: setUnit(18);
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.2px;
      }

      .subTitle {
        font-size: setUnit(16);
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.2px;
      }
    }
  }

  .agentDetails {
    padding: 16px 0px;
    border-bottom: 1px solid $light-gray;
  }

  .submissionNotes {
    padding: 16px 0px 20px;
  }

  :is(.agentDetails, .submissionNotes) h4 {
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: -0.18px;
    color: $text-gray-color;
  }
}

@media (min-width: $mobile-small) and (max-width: $mobile) {
  .showingInformationModal {
    width: 70% !important;
  }
}

@media (min-width: 390px) and (max-width: $mobile-small) {
  .showingInformationModal {
    width: 90% !important;
  }
}
