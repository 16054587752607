@import 'styles/functions';
@import 'styles/variables';

.templates {
  max-height: setUnit(288);
  overflow-y: auto;
  margin-bottom: 54px;
}

.noTemplates {
  padding: 0;
  border: none;
  margin-top: setUnit(40);
  margin-bottom: auto;
}

.searchWrapper {
  width: 100%;
  padding: 0 2.5rem;
  margin-bottom: 20px;

  .inputWrap {
    background: $white;
    border: 2px solid $light-gray !important;
    border-radius: setUnit(100) !important;
    width: 100% !important;
    height: setUnit(44);
    font-size: setUnit(14);
    font-weight: 500;
    color: $text--light-gray-color;
    display: flex;
    align-items: center;

    box-shadow: unset !important;

    &:hover {
      border-color: $text--light-gray-color !important;
    }

    input::placeholder {
      color: $text-gray-color !important;
    }

    @media (max-width: 991px) {
      height: setUnit(36);
    }

    &:focus {
      border-width: 2px;
    }

    .icon {
      display: flex;
      cursor: pointer;
      position: relative;
      top: 0;
      left: 0;
      transform: unset;
      svg {
        width: setUnit(20);
        height: setUnit(20);
        path {
          stroke: $dark-color;
        }
      }
    }

    .searchInput {
      background-color: transparent;
      border: 0;
      padding-left: setUnit(8);
      display: none;
      width: 0;
      max-width: unset;
    }

    & [class~='ant-input-prefix'] {
      margin-right: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
