@import 'styles/functions';
@import 'styles/variables';

.dateRangePickerWrapper {
  margin-top: setUnit(24);
  margin-bottom: setUnit(24);
  margin-left: setUnit(32);
  margin-right: setUnit(32);

  & [class*='ant-typography'] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }
}
.dateRangeToText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: -0.18px;

  /* REBRAND/Grayscale/40 */
  text-transform: lowercase;
  color: #aaabab;
}
.datepicker {
  width: 100%;
}
