@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.modalTitle {
  color: $text-color;
  text-align: center;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: setUnit(24);
  line-height: setUnit(32);
  letter-spacing: -0.47px;
}

.modal {
  & [class*='ant-modal-header'] {
    margin-bottom: setUnit(32) !important;
  }

  & [class*='ant-modal-header'],
  & [class*='ant-modal-content'] {
    background-color: $background-gray;
  }

  & [class*='ant-modal-body'] {
    display: flex;
    flex-direction: column;
    gap: setUnit(32);
  }

  padding-block: setUnit(32);

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: setUnit(20);

    .section {
      width: 100%;
      padding: setUnit(12) setUnit(12) setUnit(16);
      background: $white;
      border-radius: setUnit(16);
      display: flex;
      align-items: center;
      gap: setUnit(4);
      cursor: pointer;
      user-select: none;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: setUnit(8);

        & svg path {
          stroke: $dark-grey-color;
        }
      }

      .text {
        color: $dark-color;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(22);
        letter-spacing: -0.18px;
      }

      .textContainer {
        display: flex;
        flex-direction: column;

        .subject {
          color: $text-color;
          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: setUnit(16);
          line-height: setUnit(22);
          letter-spacing: -0.18px;
        }

        .description {
          color: $dark-color;
          font-family: Inter, sans-serif;
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(18);
          letter-spacing: -0.09px;
        }
      }

      .sectionEditor {
        display: flex;
        flex-direction: column;
        gap: setUnit(16);
        width: 100%;
        padding: setUnit(8);

        .sectionEditorTitle {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.09px;

          color: $text-color;
        }

        .subjectInput {
          padding: 0;

          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          letter-spacing: -0.09px;

          color: $text-color;

          border: none;

          margin-bottom: setUnit(8);

          &::placeholder {
            font-family: Inter, sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            letter-spacing: -0.09px;

            color: $text-gray-color;
          }
        }

        .descriptionInput {
          padding: 0;

          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.18px;

          color: $text-color;

          border: none;
          outline: none;
          resize: none;

          &::placeholder {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.18px;

            color: $text-gray-color;
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background: $light-gray;

          margin-bottom: setUnit(4);
        }

        .toggleOption {
          display: flex;
          align-items: center;
          gap: 12px;

          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.18px;

          color: $dark-color;

          cursor: pointer;
          user-select: none;

          .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border: 2px solid $text--light-gray-color;
            border-radius: 100px;
          }

          &.selected {
            color: $text-color;

            & svg path {
              stroke: $white;
            }

            .circle {
              background: $primary-color;
              border: 2px solid $primary-color;
            }
          }
        }

        .searchWrapper {
          margin-top: 12px;
          margin-bottom: 4px;
        }
      }
    }

    .confirmEmail {
      display: flex;
      align-items: center;
      gap: setUnit(12);
      padding-block: setUnit(11) setUnit(9);

      .switch {
        width: setUnit(44);
        height: setUnit(24);

        & [class*='ant-switch'],
        & [class*='ant-switch-checked'] {
          width: setUnit(44);
          height: setUnit(24);
        }

        & [class*='ant-switch-handle'] {
          width: setUnit(20);
          height: setUnit(20);
          top: setUnit(2);
        }

        & [class*='ant-switch-checked'] [class*='ant-switch-handle'] {
          left: calc(100% - 22px);
        }
      }

      .label {
        color: $text-color;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: center;

    .modalPostButton {
      border-radius: setUnit(12);
      padding: setUnit(14);
      max-width: 180px;
      width: 100%;
      height: auto;

      color: $white;
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(24);
    }
  }
}

.input {
  border: 1px solid $light-grey-color;
  padding: 11px !important;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.18px;

  color: $text-color;
  display: flex;
  align-items: center;

  [class*='ant-input-prefix'] {
    margin-right: 8px !important;
  }

  [class='ant-input'] {
    color: $text-color;

    &::placeholder {
      color: $text--light-gray-color;

      font-family: Inter, sans-serif;
      font-size: setUnit(16);
      font-style: normal;
      font-weight: 400;
      line-height: setUnit(20.8);
      letter-spacing: -0.18px;
    }
  }

  & svg path {
    stroke: $text-gray-color;
  }
}

.tagWrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .tag {
    border-radius: 16px;
    border: 1px solid var(--grayscale-25-outline, #d6d6d7);
    display: flex;
    gap: 2px;
    align-items: center;
    padding: 7px 9px;
    justify-content: center;
    align-items: center;
    color: var(--Grayscale-50, #747475);

    & svg path {
      stroke: var(--Grayscale-50, #747475);
    }

    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.18px;

    cursor: pointer;

    &.deletable {
      padding-right: 7px;
    }

    &.active {
      padding: 8px 10px;
      border: none;

      border-radius: 16px;
      color: var(--White, #fff);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.18px;

      & svg path {
        stroke: var(--White, #fff);
      }

      background: linear-gradient(90deg, #ff576d, #fb913a);
    }
  }
}
