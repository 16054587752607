@import 'styles/functions';
@import 'styles/variables';

.sendingTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .values {
    display: flex;
    align-items: center;
  }

  .label {
    font-size: setUnit(14);
    font-weight: 400;
    margin-right: setUnit(8);
  }

  .value {
    margin-left: setUnit(24);
  }

  .checkboxWrapper {
    margin-bottom: 0;
  }
}
