@import 'styles/variables';

.back {
  position: absolute;
  top: 36px;
  left: 36px;
  margin-left: 0 !important;
  padding: 0 !important;

  div {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0 !important;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: $dark-color;
    }
  }

  &:hover {
    opacity: 1;
  }
}
