@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.separator {
  margin: 0 8px !important;
}

.searchCriteriaSummary {
  padding: 20px;
  border: 2px solid $light-gray;
  border-radius: setUnit(8);
  background: $background-ligh-gray-5;
  overflow-y: auto;
  max-height: calc(100vh - 415px);
  margin-bottom: 20px;

  @media screen and (max-height: 1000px) {
    max-height: calc(100vh - 345px);
  }

  .singleList {
    display: flex;
    flex-wrap: wrap;
    min-height: 24px;
    color: $text-color;
    font-size: setUnit(14);
    line-height: setUnit(24);
    letter-spacing: -0.09px;
    h4 {
      margin: auto 0;
      font-weight: 600;
      color: $text-color;
    }

    p {
      word-break: break-word;
      margin: auto 0;
      font-weight: 400;
    }
  }
  .hardCriteria {
    padding-bottom: setUnit(16);
    margin-bottom: setUnit(16);
    border-bottom: 1px solid $light-gray;
    .singleList {
      display: flex;
      flex-direction: column;
      color: $text-color;
      h4 {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.09px;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.09px;
        white-space: nowrap;
      }
    }
  }
  .softCriteria {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    margin: 0 setUnit(-5);
    h4 {
      margin-right: 8px;
      margin-bottom: 0;
      font-weight: 600;
      font-size: setUnit(14);
      line-height: setUnit(24);
      letter-spacing: -0.09px;
      color: $text-color;
      @media (min-width: $mobile-small) {
        flex: 0 0 50px;
      }
    }
    .wants,
    .needs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 setUnit(5);
    }
    .wantsInner,
    .needsInner {
      display: flex;
      flex-direction: column;
      span {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(24);
        letter-spacing: -0.09px;
        color: $text-color;
      }
      @media (min-width: $mobile-small) {
        flex: 0 0 calc(100% - 58px);
      }
      .commutesValue {
        margin-top: 8px;
        font-weight: 500;
        color: $text-color;
      }
    }
  }
}

.border {
  border-radius: 8px 0 0 8px !important;
  .placeholder {
    border-radius: 8px 0 0 8px !important;
  }
  input {
    border-radius: 8px 0 0 8px !important;
  }
}

.hoaContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.checkboxContainer {
  min-width: 100px;
  .label {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
}

.wrapper {
  margin: 0;
}
.selectWrapperClassname {
  input {
    height: 48px !important;
  }
  position: relative;
  bottom: 24px;
}
