@import 'styles/functions';
@import 'styles/variables';

.containerActiveSearch {
  margin-top: 16px;
  h3 {
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 setUnit(15);
  }
  .expandedTransaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .expandedTransactionHeading {
      display: flex;
      h3 {
        margin-left: 12px;
        margin-bottom: 0;
      }
    }
  }
  .feedSelectDropdown {
    width: 150px !important;
  }
  .noResults {
    min-height: 40px;
    display: flex;
    align-items: center;
    color: #747475;
    justify-content: center;
    text-align: center;
  }
  .CardsContainer {
    padding: 0 setUnit(27);
  }
}
