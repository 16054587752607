@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filterWrap {
  position: relative;
  float: right;

  .filterButton {
    display: flex;
    align-items: center;
    gap: setUnit(4);

    border-radius: setUnit(100);
    font-weight: 600;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: -0.09px;
    padding: setUnit(10) setUnit(10) setUnit(10) setUnit(16);
    width: fit-content;
    cursor: pointer;
    user-select: none;

    background-color: $white;
    border: 2px solid $background-ligh-gray-4;
    color: $dark-color;

    &:hover,
    &:active {
      background-color: $background-grey-2;
      border: 2px solid $text-gray-color;
      color: $text-color;

      .icon {
        svg {
          path {
            stroke: $text-color;
          }
        }
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: setUnit(16);
      height: setUnit(16);

      transform: rotate(0deg);
      @include transition;

      &.open {
        transform: rotate(180deg);
      }

      svg {
        width: 11px;
        height: 6.5px;

        path {
          stroke: $dark-color;
        }
      }
    }
  }

  .options {
    padding: setUnit(8) 0;
    width: 240px;
    background: $white;
    box-shadow: setUnit(0) setUnit(32) setUnit(80) rgba(0, 0, 0, 0.06),
      setUnit(0) setUnit(8) setUnit(30) rgba(0, 0, 0, 0.08);
    border-radius: setUnit(8);
    position: absolute;
    right: 0;
    top: setUnit(48);
    z-index: 1;

    .optionsItems {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      label[class~='ant-radio-wrapper'] {
        flex: 1;
        display: flex;
        background-color: $white;
        padding: setUnit(18) setUnit(24);
        margin: 0;
        max-height: 56px;
        @include transition;

        span[class~='ant-radio'] {
          display: none;
        }

        span:last-child {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          font-weight: 500;
          font-size: setUnit(16);
          line-height: setUnit(16);
          letter-spacing: -0.09px;
          color: $text-color;
        }

        &:hover {
          background-color: $background-grey-2;
        }

        .icon {
          opacity: 0;
          visibility: hidden;

          @include transition;
        }
      }

      label[class='ant-radio-wrapper ant-radio-wrapper-checked'] {
        max-height: 56px;

        .icon {
          opacity: 1;
          visibility: visible;

          width: setUnit(20);
          height: setUnit(20);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
