@import "styles/functions";
@import "styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - #{setUnit(72)});
    width: 100%;

    .scrollWrapper {
        flex: 1;
        overflow: auto;
    }

    .listing {
        padding: setUnit(16) setUnit(32) setUnit(22) setUnit(32);
        border-bottom: 2px solid $light-gray;
        display: flex;
        align-items: center;
        margin-bottom: setUnit(8);

        .listingInfoTitle {
            font-weight: 600;
            font-size: setUnit(20);
            line-height: setUnit(26);
            margin-bottom: setUnit(4);
            color: $text-color;
        }

        .listingInfoRole {
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $text-gray-color;
        }

        .listingIcon {
            overflow: hidden;
            width: setUnit(80);
            height: setUnit(80);
            border-radius: 50%;
            margin-right: setUnit(16);
            background-color: $light-gray;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: setUnit(40);
                height: setUnit(40);
            }
        }
    }

    .scroll {
        overflow-y: auto;
    }
}