@import 'styles/functions';
@import 'styles/variables';

.localHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 setUnit(15);
  margin-bottom: setUnit(15);
  @media (min-width: $desktop) {
    position: absolute;
    margin-top: setUnit(-95);
    padding: 0 setUnit(40);
    height: setUnit(80);
  }
  .searches {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 575px;

    .locationContainer {
      width: 312px;
      margin-right: 0.5rem;
    }

    @media (max-width: $responsive-search) {
      width: 380px;
      justify-content: start;
      .backBtn {
        margin: 5px 0;
      }
      .field {
        margin: 5px 0;
      }
    }
    .active {
      border-color: $primary-color;
      background-color: $primary-color-light;
    }
    .backBtn {
      width: setUnit(44);
      height: setUnit(44);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $white;
      border: 2px solid $light-gray;
      margin-right: 0.5rem;

      &:hover {
        border-color: $text--light-gray-color;
      }

      svg {
        vertical-align: middle;
      }
    }
    .field {
      &:first-child {
        margin-bottom: setUnit(16);
        @media (min-width: $tablet) {
          margin-bottom: 0;
        }
      }
      &:not(:first-child) {
        margin-right: setUnit(8);
        @media (min-width: $tablet) {
          margin-right: setUnit(0);
        }
      }
      &:not(:last-child) {
        @media (min-width: $tablet) {
          margin-right: setUnit(8);
        }
      }
    }
  }

  .locations {
    max-width: 312px;
    width: 100%;
  }

  .locationsValues {
    min-height: 44px;
    background: $white;
    border-radius: 100px;
  }
}
