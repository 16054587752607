@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.nameAutoComplete {
  display: flex !important;
  align-items: center !important;
  @include lightRounded;
  padding: 0rem !important;
}

.autoCompleteDropdown {
  margin-bottom: 1rem;
  z-index: 10000 !important;

  .autoCompleteOption {
    display: flex;
    align-items: center;

    .name {
      font-size: 16px;
    }
    .email {
      font-size: 14px;
      color: $border-middle-gray;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    margin-right: 15px;

    .avatarPlaceholder {
      font-size: 14px;
    }
  }

  .nameField {
    .label {
      margin-bottom: 0;
      font-size: 16px !important;
    }

    .fieldWrapper > label > span {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }

    .fieldWrapper {
      margin-bottom: 1.675rem;
    }

    .error {
      color: #ec455e;
      font-size: 0.875rem;
      margin-top: -0.5rem;
    }
  }
}

.noMemberAutoComplete {
  border-radius: 8px !important;
  box-shadow: 0px 8px 30px 0px #00000014 !important;
  [class~='rc-virtual-list-holder'] {
    max-height: unset !important;
    height: 100px;

    > div {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    [class~='rc-virtual-list-holder-inner'] {
      flex-direction: row !important;
      justify-content: center !important;
    }

    [class~='ant-select-item'] {
      background-color: #fff;
    }

    .autoCompleteOption {
      display: block;
      cursor: default;
    }
  }

  .noResultsText {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: center;

    color: $text-gray-color;
    margin-bottom: 8px;
  }

  .addMemberText {
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: center;
    color: $link-blue;

    cursor: pointer;
  }
}
