@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contactModalForm {
  .content {
    position: relative;
    max-width: 675px;
    margin-bottom: 2rem;

    .phoneLabel {
      font-weight: 600;
      color: $text-color;
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .phoneField {
      @include lightRounded;
    }

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-20);

      .roleField {
        width: 100%;
        padding: 0 setUnit(20);
        position: relative;
        display: inline-block;
        height: setUnit(110);
      }
    }

    .fieldWrapper {
      margin-bottom: 1.675rem;
    }

    .privateCheckbox {
      margin-top: 24px;
    }
  }

  .addFooterButton {
    justify-content: center;
  }

  .footerButton {
    display: flex;

    .footerBtnDiv {
      text-align: center;

      .btn {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        padding: 0.3125rem 0.9375rem;
        width: 8.6875rem;
        height: 3.5rem;
        border: 0;
        cursor: pointer;
      }

      .addBtn {
        color: $white;
        background-color: $background-blue;
        &:hover {
          background-color: $hover-blue;
        }
      }

      .addBtnDisabled {
        cursor: not-allowed;

        &:hover {
          background-color: $background-blue;
        }
      }
    }

    .editBtnDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: setUnit(16);

      .deleteContactDiv {
        cursor: pointer;
        color: $error-color;
        display: flex;
        align-items: center;

        span {
          padding-left: setUnit(8);
        }

        @media (max-width: 390px) {
          span {
            padding-left: 0;
          }
        }
      }

      .deleteIcon {
        margin-top: 2px;
        svg {
          width: setUnit(24);
          height: setUnit(24);
          path,
          circle {
            stroke: $error-color;
          }
        }
      }
    }
  }
}
