@import 'styles/functions';
@import 'styles/variables';

.overdueLabel {
  margin-top: setUnit(24);
  margin-bottom: setUnit(-20);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $error-color;
  height: setUnit(40);
  width: 100%;
  border-radius: setUnit(8);
  background: rgba($error-color, 0.1);

  .icon {
    margin-right: setUnit(10);
    svg {
      vertical-align: middle;
    }
  }

  .text {
    color: $error-color;
    font-weight: 500;
  }
}
