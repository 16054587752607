@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.documents {
  margin-right: setUnit(24);

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .contentWrap {
    max-height: calc(100vh - #{setUnit(432)});
    overflow-y: auto;
    padding-right: setUnit(24);
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;
    margin-bottom: 12px;
    background-color: $background-ligh-gray-5;

    .documentsContainer {
      padding: setUnit(24) setUnit(24) 0px;
      max-height: 200px;
      overflow-y: auto;

      .documentRow {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: setUnit(24);
      }

      .container {
        flex: 0 0 100%;
        @media (min-width: 576px) {
          flex: 1 0 calc(100% - #{setUnit(144)});
        }
      }

      .checkbox {
        align-items: center;
        width: 100%;
        [class*='ant-checkbox'] {
          box-sizing: border-box;
          border-radius: 4px;
          top: 0;
        }
        margin: 0;
        &[class~='ant-checkbox-wrapper-checked'] {
          .selector {
            color: $text-color;
          }
          .documentIcon {
            opacity: 1;
          }
        }

        span:last-child {
          flex-grow: 1;
        }
      }

      .documentInputContainer {
        display: flex;
        align-items: center;
        width: 100%;
        gap: setUnit(8);
        padding-right: 20px;

        .documentIcon {
          opacity: 0.7;
        }

        .selector {
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
          color: $text-gray-color;
          max-width: setUnit(320);
          width: 100%;
          border: 0;
          outline: none;

          @include transition;

          padding-right: 30px;

          &:hover {
            border-bottom: 2px solid $border-input-middle-gray !important;
          }

          &:focus {
            border-bottom: 2px solid $text-gray-color;
          }

          &:is(:hover, :focus) {
            padding: 0 0 setUnit(10) 0;

            ~ .removeIcon {
              opacity: 1;
              position: absolute;
              right: 20px;
              bottom: 0.7rem;
            }
          }

          box-shadow: none !important;
        }

        .emptySelector {
          border-bottom: 2px solid $border-input-middle-gray;
          padding: 0 0 setUnit(10) 0;

          ~ .removeIcon {
            opacity: 1;
            position: absolute;
            right: 20px;
            bottom: 0.7rem;
          }
        }
      }

      .requiredOptions {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        width: '100%';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.09px;
        color: $dark-grey-color;
        @media (max-width: 575.99px) {
          flex-direction: row-reverse;
          margin-top: setUnit(8);
        }

        .filterButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          border-radius: 30px;
          padding: 6px 8px 6px 10px;

          gap: 10px;

          .optionIcon {
            margin-right: setUnit(4);
            display: flex;
            svg {
              width: setUnit(20);
              height: setUnit(20);
            }
          }

          p {
            padding: 0;
            margin: 0;
          }

          .icon {
            display: flex;
            transition: $transition;
            svg {
              width: setUnit(12);
              height: setUnit(6);
              path {
                stroke: $dark-color;
              }
            }
          }

          &[class~='ant-dropdown-open'] {
            .icon {
              transform: rotate(180deg);
            }
          }

          .optionIcon {
            margin-right: setUnit(4);
            display: flex;
            svg {
              width: setUnit(20);
              height: setUnit(20);
            }
          }
        }

        .optionalButton {
          background: #74747514;
          color: $dark-color;
        }

        .requiredButton {
          background: $primary-color-light;
          color: $text-color;
          border: 1px solid $primary-color;
        }
      }

      .removeIcon {
        cursor: pointer;
        opacity: 0;
        svg {
          vertical-align: middle;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .addNew {
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: fit-content;

      pointer-events: all;
      opacity: 1;

      padding: 0px setUnit(24) setUnit(24);

      .addIcon {
        margin-right: setUnit(14);
      }
      h4 {
        color: $text-color;
        font-weight: 500;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        margin-bottom: 0;
      }
    }

    .disableAddNew {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.menuItemSpan {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  .visible {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
}

.menuDropdown {
  .menuItem {
    display: flex;
    align-items: center;
    padding: 12px;
    * {
      font-family: $font-family;
    }

    color: $text-color !important;

    &:hover {
      background: $background-grey-2 !important;
      color: $text-color !important;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  border-radius: 8px;
}
