@import 'styles/functions';
@import 'styles/variables';

.noData {
  color: $dark-color;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.09px;
  text-align: center;
  margin: 40px 0 5px 0;
}

.dropdown {
  height: 40px !important;
  border: none !important;
  padding: 0 10px !important;
  border-radius: 8px !important;
  background: $background-grey-2;

  label[class~='ant-radio-wrapper'] {
    padding: 14px 16px !important;
    height: 44px !important;
    white-space: nowrap;
  }

  span {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: -0.09px !important;
    color: $text-color !important;
  }
}

.card {
  margin-bottom: 24px !important;
  [class~='ant-card-body'] {
    padding: 24px 0 !important;
  }

  .title {
    color: $text-color;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.33px;
    margin: 0;
    padding: 0 24px 20px 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    .row {
      display: flex;
      gap: 8px;
    }

    .toggle {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 8px;
      background: $background-grey-2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .list {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    padding: 0 24px;
    margin-top: 20px;
  }

  .map {
    position: relative;
    border-radius: setUnit(8);
    overflow: hidden;
    position: relative;
    height: 440px !important;
    margin-top: 20px;
    width: calc(100% - 48px);
    position: relative;
    left: 24px;
    @media (min-width: $mobile) {
      height: setUnit(380);
    }
    .activity {
      position: absolute;
      bottom: 20px;
      left: 20px;
      z-index: 9999;
      background-color: $white;
      width: calc(100% - 40px);
      border-radius: 16px;
      padding: 16px;
      box-shadow: 0px 1px 16px 0px rgba(48, 48, 48, 0.08);
    }
  }
}

.listItem {
  display: flex;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #eeefef;
  cursor: pointer;

  &:last-child {
    border-bottom: none !important;
  }

  img {
    width: 116px;
    height: 92px;
    border-radius: 12px;
  }

  .listItemContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;

    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      .statusContainer {
        display: flex;
        align-items: center;
        gap: 4px;

        .status {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: -0.09px;
          margin-bottom: 0;

          &.statusNew {
            color: $confetti-blue;
          }

          &.statusSold {
            color: $purple;
          }

          &.statusStatusChanges {
            color: $orange;
          }

          &.statusPriceReductions {
            color: $green-color;
          }

          &.statusPriceIncrease {
            color: $error-color;
          }

          &.statusBackOnMarket {
            // TODO: verify color
            color: $orange;
          }

          &.statusOpenHouses {
            // TODO: verify color
            color: $orange;
          }
        }
      }

      .time {
        color: $text-gray-color;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 0;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .price {
        color: $text-color;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 0;

        display: flex;
        align-items: center;
        gap: 4px;

        .arrow {
          color: $text--light-gray-color;
          letter-spacing: -0.09px;

          font-feature-settings: 'liga' 1;
        }

        .previousPrice {
          color: $text--light-gray-color;
          text-decoration-line: line-through;
        }
      }

      .subDetails {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 4px;

        .address {
          color: $text-color;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.09px;
          margin-bottom: 0;
        }

        .addressSecondary {
          color: $text-gray-color;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.09px;
          margin-bottom: 0;
        }

        .distanceContainer {
          display: flex;
          justify-content: flex-end;
          gap: 4px;

          .distance {
            color: $text-gray-color;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.09px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
