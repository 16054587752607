@import '../../../../../../../../../styles/functions';
@import '../../../../../../../../../styles/variables';

.buyersList {
  height: setUnit(445);
  overflow: auto;

  .noResults {
    height: 100%;
  }
}
