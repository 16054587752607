@import 'styles/variables';
@import 'styles/functions';

.deleteButton {
  width: 100% !important;
  height: 52px !important;
  border-radius: 10px !important;

  border: 2px solid $light-grey-color !important;
  color: $error-color !important;

  font-size: setUnit(16);
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: -0.09px;

  margin-top: 16px;

  &:hover {
    border-color: $text--light-gray-color !important;
    color: $error-color !important;
  }

  .editButtonContainer,
  .deleteButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  @media screen and (max-width: 650px) {
    margin-top: 0px;
  }
}
