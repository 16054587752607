@import 'styles/variables';
@import 'styles/functions';

.transactionCancel {
  padding: 0 40px;
  .head {
    flex: 0 0 100%;
    margin: 34px 0 54px;
  }
  .cancellationComplete {
    height: calc(100vh - $header-height - 7px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
