@import "styles/functions";
@import "styles/variables";

.close {
    background-color: transparent;
    border: 1px solid $border-middle-gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .1s;
    border-radius: setUnit(8);
    padding: setUnit(13) setUnit(16);
    font-size: setUnit(16);
    line-height: setUnit(21);
    color: $text-color;

    .icon {
        margin-right: setUnit(5);
        height: setUnit(24);
        width: setUnit(24);
    }

    &:hover {
        border-color: $text-color;
    }
}
