@import 'styles/variables';
@import 'styles/functions';

.popoverContent {
  [class*='ant-popover-inner'] {
    border-radius: 8px;

    [class*='ant-popover-inner-content'] {
      padding: 0px !important;
    }
  }

  .popoverOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px !important;
    padding: 0px 16px;

    .optionLabel {
      margin-left: 8px;
    }

    &:hover {
      background-color: $hover-light-gray;
    }
  }
}

.progressBar {
  position: fixed !important;
  z-index: 5;
}

.editFormContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - $header-height - $responsive-footer-height);
  overflow: auto;
}

.editFormContainerPDFMode {
  background-color: $background-grey-2;
}

.editRoles {
  .selectedCheckbox {
    [class*='ant-checkbox-checked '],
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: $white;
  padding: 8px 0px;
  min-width: 180px;
  width: auto;
  z-index: 5;

  .userRow {
    padding: 0px 20px;
    display: flex;
    width: 100%;
    margin: 3px 0px;

    &:hover {
      background-color: $background-grey-2;
      cursor: pointer;
    }
  }

  .userName {
    overflow-wrap: anywhere;
    display: block;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $dark-color;
  }

  .checkbox {
    margin-right: 12px;
  }

  .deferredCheckbox {
    .selectedCheckbox {
      [class*='ant-checkbox-checked '],
      [class*='ant-checkbox-inner'] {
        background-color: $light-green;
        border-color: $light-green;
      }
    }
  }

  .userTitle {
    overflow-wrap: anywhere;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.editFormFooter {
  height: $responsive-footer-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-top: 1px solid $light-gray;

  .editFormFooterColumn {
    margin: 0 auto;
    max-width: 640px;
    width: 100%;
    justify-content: space-between;
  }

  .editFormPDFView {
    justify-content: center;
    .editFormFooterButton {
      max-width: unset;
      width: 400px;
    }
  }

  .svgContinue {
    margin-left: setUnit(12);
  }

  .svgDefer {
    margin-left: setUnit(12);

    path {
      stroke: white;
    }
  }

  .svgMessage {
    margin-right: setUnit(12);
  }

  .editFormFooterButton {
    max-width: 142px;
    width: 100%;
    height: 52px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: setUnit(8);
  }

  .editFormFooterMessage {
    border: 2px solid $light-gray !important;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto;
  max-width: 640px;
  width: 100%;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
    box-shadow: none;
  }
}

.editRow {
  background-color: transparent;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: $transition;
  padding: 16px;

  @media (min-width: $tablet) {
    padding: 15px 40px;
  }

  &.sticky {
    background-color: $white;
    transition: $transition;
    box-shadow: 0px 1px 3px 1px rgba(163, 163, 163, 0.3);
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .switchOptions {
      display: flex;
      width: 108px;
      align-items: center;
      height: 44px;
      justify-content: space-around;
      z-index: 5;
      order: 1;
      .switchOption {
        background-color: $white;
        border: 2px solid $background-ligh-gray-4;
        width: 50%;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        transition: $transition;
        &:hover {
          cursor: pointer;
          border-color: $text--light-gray-color;
        }
      }

      .switchPDF {
        border-radius: 44px 0px 0px 44px;
      }

      .switchList {
        border-radius: 0px 44px 44px 0px;
        border-left: none !important;
      }

      .activeSwitch {
        border: 2px solid $primary-color !important;
        background-color: $primary-color-light;
      }
    }

    .formPill {
      margin: 16px auto 0;
      order: 3;

      @media (min-width: $tablet) {
        margin: 0 auto;
        order: 2;
      }
    }

    .navigationIcons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      z-index: 5;
      order: 2;

      @media (min-width: $tablet) {
        order: 3;
      }

      .navigationButton {
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        border: 2px solid $light-gray;
        z-index: 5;

        &:hover {
          cursor: pointer;
          background-color: $light-gray;
        }
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 100px;
    left: 40px;

    .editForm {
      display: flex;
      width: 95px;
      align-items: center;
      height: 44px;
      justify-content: center;
      z-index: 5;
      border-radius: 100px;
      background-color: $white;
      border: 2px solid $light-gray;
      margin-bottom: 15px;

      svg {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }
}

.navigationFooter {
  display: flex;
  justify-content: right;
}

.formNavigation {
  position: absolute;
  bottom: 50px;
  right: 50px;

  display: flex;
  border: 1px solid $light-gray;
  border-radius: 8px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: 40px;
  width: 40px;
}

.navigationBtn {
  height: 40px;
  width: 40px;
  border-radius: 8px;

  :hover {
    background-color: $light-gray;
  }
}

.continueBtn {
  background-color: $white !important;
  border: none !important;
  display: flex !important;
  margin-top: 40px;
  width: 138px !important;
  height: 52px !important;
  justify-content: center;

  span {
    align-self: center;
  }

  div {
    align-self: center;
    margin-left: 13px;
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}

.deferModal {
  width: 312px !important;
  height: 170px !important;
  margin: 0 !important;

  .disabledBtn {
    opacity: 50%;
  }

  .deferBtn {
    width: 164px;
    height: 52px;
    font-weight: 600 !important;
    font-size: 16px !important;
  }

  [class*='ant-modal-close-x'] {
    top: -13px !important;
  }

  [class*='ant-modal-title'] {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }

  [class*='ant-typography'] {
    text-align: center;
  }

  [class*='ant-modal-content'] {
    padding: 20px !important;
    min-height: 170px;
  }

  .deferModalBody {
    .questions {
      max-height: 400px;
      overflow: auto;
      padding: 0px 40px;
      margin-bottom: 10px;
    }

    .label {
      display: block;
      width: 100%;
      line-height: 52px;
      font-weight: 400;
      font-size: 18px;
      color: $text-color;
      font-family: $font-family;
    }

    .multipleQuestions > .label {
      text-align: left;
      border-bottom: 1px solid $light-gray;
    }

    .listBoxInput {
      width: 275px !important;

      [class*='ant-select-selector'] {
        border: none !important;

        [class*='ant-select-selection-item-remove'] {
          display: none;
        }

        [class*='ant-select-selection-item'] {
          background: transparent !important;
          border: none !important;
          color: $text-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.18px;
          padding: 0px;
        }

        [class*='ant-select-selection-placeholder'] {
          color: $text--light-gray-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all 0.3s;
        }

        [class*='ant-select-selection-search'] {
          font-weight: 400;
          font-size: 16px;
        }

        [class*='ant-select-selection-search-input'] {
          margin-left: -10px;
        }
      }
    }
  }
}

.strikeThroughLeftHeader {
  display: flex;
  align-items: center;

  .strokeSetting {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
}

.strikeActionButton {
  border-radius: 100px !important;
  height: 44px !important;
  &:hover {
    border-color: $text--light-gray-color !important;
  }
  span {
    padding: 0 16px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
}

.cleanAllButton {
  background: $white !important;
  color: $text-color;
}

.strikeCancelButton {
  background: $white !important;
  color: $text-color;
  margin-right: 8px;
}

.strokeToolTipContainer {
  width: 156px !important;

  .strikeLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 24px;
    &:hover {
      cursor: pointer;
      background-color: $background-grey-2;
    }

    .strikeLabelColumn {
      display: flex;
      align-items: center;

      .stroke {
        background-color: $text-color;
        width: 20px;
        margin-right: 12px;
      }

      .strokeNumber {
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
}

.strokeSelect {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;

  &:hover {
    cursor: pointer;
  }
  .dropDownIcon {
    display: flex;
  }
}

.strokeToolTipContainer {
  [class*='ant-tooltip-inner'] {
    padding: 0px !important;
  }
}

.settingSwitch {
  height: 44px !important;
  width: 60px !important;
  border: 2px solid $background-ligh-gray-4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 0px 10px 0px 8px;
  transition: $transition;
  &:hover {
    border-color: $text--light-gray-color;
  }
}

.colorBoxContainer {
  border-bottom-right-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

@media (min-width: $mobile) {
  :is(.sideControls, .leftSideControl) {
    min-width: 158px;
  }
}
.modalText {
  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.09px;
  margin-left: 2px;
}
.modalHeader {
  display: flex;
}
.sendIcon {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
