@import 'styles/variables';
@import '../../../mixins.module.scss';

.localHeader {
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.47px;
    color: $text-color;
    margin: 0 0 0.5rem;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
