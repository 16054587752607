@import 'styles/variables';
@import 'styles/functions';

.values {
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.09px;
}

.chipsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  .chip {
    font-weight: 500;
    color: $text-color;
    padding: 4px 10px;
    border-radius: 100px;
    background: $background-grey-2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.wrapper {
  width: 70vw;
  padding: 32px;
  padding-right: 28px;
  @media only screen and (max-width: 600px) {
    padding: 12px;
    padding-right: 8px;
    width: 90vw;
  }
  background-color: $white;
  border-radius: 12px;
  max-height: calc(100vh - 320px);
  height: fit-content;
  overflow-y: auto;
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  &.full {
  }

  &.meta {
    // max-width: setUnit(280);

    .row {
      margin-bottom: setUnit(8);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      font-weight: 500 !important;
      min-width: setUnit(70);
      margin-right: setUnit(8);
    }

    .value {
      font-weight: 700 !important;
    }

    .addText {
      color: $secondary-color !important;
      font-weight: 700 !important;
      font-size: setUnit(14) !important;
    }

    .addIcon {
      svg {
        width: setUnit(14);
        height: setUnit(14);
      }
    }

    .label,
    .value {
      color: $text--light-gray-color !important;
      font-size: setUnit(14) !important;
      line-height: setUnit(24) !important;
    }

    .row:hover {
      .label,
      .value {
        color: $dark-color !important;
      }

      .addText {
        color: lighten($secondary-color, 10) !important;
      }
    }
  }
}
