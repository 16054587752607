@import 'styles/mixins';
@import 'styles/variables';

.accessLevelModal {
  [class~='ant-modal-header'] {
    display: none;
  }

  [class~='ant-btn-primary'] {
    margin-left: 16px !important;
  }
}

.scopeModal {
  :global {
    .ant-modal-content {
      padding: 40px 40px 32px;
      border-radius: 24px;

      .ant-modal-header {
        display: none;
      }

      .ant-modal-body {
        h4 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.09px;
          color: $text-color;
          margin-bottom: 24px;
        }
      }

      .ant-modal-footer {
        margin-top: 24px;
        padding: 0;
        border-top: none;
        display: flex;
        gap: 12px;

        .ant-btn {
          flex: 1;
          height: 48px;
          font-size: 16px;
          font-weight: 600;
          border-radius: 8px;

          &.ant-btn-default {
            color: $text-color;
            border-color: $light-gray;

            &:hover {
              border-color: $text--light-gray-color;
              background: transparent;
            }

            &:disabled {
              background: #f4f5f6;
              border-color: #f4f5f6;
              color: #6b7280;
              cursor: not-allowed;

              &:hover {
                background: #f4f5f6;
                border-color: #f4f5f6;
              }
            }
          }

          &.ant-btn-primary {
            background: $primary-color;
            border-color: $primary-color;

            &:hover {
              background: $primary-color-hover;
              border-color: $primary-color-hover;
            }

            &:disabled {
              background: #e5e7eb;
              border-color: #e5e7eb;
              color: rgba(0, 0, 0, 0.25);
              cursor: not-allowed;
              opacity: 0.6;

              &:hover {
                background: #e5e7eb;
                border-color: #e5e7eb;
              }
            }
          }
        }
      }
    }
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .checkboxWrapper {
    width: 100%;
    height: 56px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #e8e9ea;
    }

    &.checked {
      background-color: $primary-color-light;
      border-color: $primary-color;
    }

    :global {
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.09px;
        color: #000;
        font-weight: 500;
        padding: 16px 0;
        width: 100%;
        margin-inline-start: 0;

        .ant-checkbox {
          top: 0;

          .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border: 1px solid $dark-color;
            border-radius: 50%;

            &::after {
              width: 6.5px;
              height: 13px;
              inset-inline-start: 8px;
              top: 45%;
              left: 27%;
              border-width: 2px;
              border-radius: 1px;
            }
          }

          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: $primary-color;
              border-color: $primary-color;
            }
            &::after {
              border: unset;
            }
          }
        }

        > span:last-child {
          padding-top: 1px;
        }
      }
    }
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  color: #000;
  font-weight: 600;
}
