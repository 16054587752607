@import "styles/functions";
@import "styles/variables";

.wrapper {
    padding: 0 setUnit(32);
    height: calc(100% - #{setUnit(72)});
    overflow: auto;
    
    .userTitle {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: setUnit(10) 0 setUnit(32) 0;

        .userTitleAvatar {
            width: setUnit(96);
            height: setUnit(96);
            margin-bottom: setUnit(16);
            font-size: setUnit(28);

            img {
                width: setUnit(96);
                height: setUnit(96);
            }
        }

        .userTitleName {
            font-weight: 600;
            font-size: setUnit(20);
            line-height: setUnit(26);
            color: $text-color;
        }

        .userTitleRole {
            padding-top: setUnit(4);
            font-size: setUnit(14);
            line-height: setUnit(16);
            color: $text-gray-color;
        }
    }

    .contactInfo {
        .contactInfoTitle {
            font-weight: 600;
            font-size: setUnit(16);
            line-height: setUnit(21);
            color: $text-color;
            padding-bottom: setUnit(16);
            border-bottom: 2px solid $light-gray;
        }

        .contactInfoBlockBio {
            font-size: setUnit(16);
            line-height: setUnit(21);
            color: $dark-grey-color;
            padding-bottom: setUnit(22);

            .contactInfoTitle {
                margin: setUnit(22) 0;
            }
        }

        .contactInfoBlock {
            display: flex;
            padding: setUnit(14) 0;

            .contactInfoBlockLeft {
                flex: 1;
                color: $text-gray-color;
                font-size: setUnit(16);
                line-height: setUnit(21);
            }

            .contactInfoBlockRight {
                flex: 1;
                text-align: right;
                color: $text-color;
                font-size: setUnit(16);
                line-height: setUnit(21);
                margin-left: auto;

                div:not(:last-of-type) {
                    margin-bottom: setUnit(8);
                }

                a, a:active {
                    text-decoration: none;
                    color: $link-blue;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .contactInfoBlockPhone input {
                    text-align: right;
                    color: $text-color;
                    font-size: setUnit(16);
                }
            }
        }
    }
}