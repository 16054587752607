@import 'styles/variables';

.panelContent {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 0px 32px 32px;
}

.commentBoxSpacing {
  padding: 20px;
  border-bottom: unset;
  background-color: $white;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  [class~='ant-collapse-item'] {
    padding: 0px !important;

    [class*='ant-collapse-header'] {
      padding: 0px !important;
    }

    [class*='ant-collapse-content-box'] {
      margin-left: 0px !important;
    }
  }
}

.resolvedThreads {
  .offerTreeLine {
    &:first-child {
      height: 160px;
    }
  }
}

.threadWindow {
  border-radius: 16px !important;
  background-color: white !important;

  [class*='ant-collapse-item'],
  [class*='ant-collapse-content'] {
    border-radius: 0px 0px 16px 16px !important;
  }

  [class*='ant-collapse-content'] {
    border-top: none;
  }

  [class*='ant-collapse-content-box'] {
    padding: 0px !important;
    margin-left: 51px;
  }

  [class*='ant-collapse-header-text'] {
    width: 100%;
  }

  .commentsWrapper {
    border-bottom: 1px solid $light-gray;
  }

  .commentBoxPadding {
    padding: 32px 0px 0px 44px;

    [class~='ant-space-item'] {
      position: relative;
    }

    .threadSpacing {
      width: 100%;
    }
  }
}

.replyBox {
  padding: 16px 0px 0px 44px;
  display: flex;
  align-items: flex-start;

  .replyBoxAvatar {
    margin-right: 12px;
  }

  textarea {
    margin-top: 5px !important;
  }

  .formPanelCommentPermissionSelector {
    max-width: 170px !important;
    min-height: unset !important;

    [class~='ant-select-selector'] {
      min-height: 40px;
      height: 100%;

      [class~='ant-select-selection-overflow'] {
        flex-wrap: nowrap;
      }
    }
  }
}

.noCommentsNote {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  line-height: 1.3125rem;
  color: $border-middle-gray;
  padding: 3.125rem 0;
}

.resolvedStatus {
  display: flex;
  flex-direction: column;
  margin-left: 44px;
  margin-top: 16px;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.09px;
  color: $text-gray-color;
}

.replyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    border-radius: 8px;
    background: $background-grey-2;
  }
}

.replyBtnWrapper {
  padding-left: 44px;
  margin-top: 16px;
}

.offerTreeLine {
  height: 112px;
  width: 20px;
  left: -28px;
  bottom: 60px;
  border-bottom: 2px solid $light-gray;
  border-left: 2px solid $light-gray;
  border-bottom-left-radius: 10px;
  position: absolute;
}
