@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.contactField {
  .label {
    margin-bottom: 0;
  }

  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fieldWrapper {
    margin-bottom: 1.675rem;
    .labelContainer {
      display: flex;
      .asterik {
        color: $error-color;
        margin-left: 2px;
      }
    }
  }

  .error {
    @include inputError;
    margin-top: 5px !important;
  }
}
