@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.header {
  font-size: setUnit(24);
  color: $dark-gray-2;
  font-weight: 600;
  line-height: setUnit(30);
  margin-bottom: setUnit(32);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerButtons {
    display: flex;

    .searchWrapper {
      margin-right: setUnit(20);
      .inputWrap {
        background: $white;
        border: 2px solid $border-light-gray-2;
        border-radius: setUnit(26);
        width: setUnit(200) !important;
        height: setUnit(44);
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        display: flex;
        align-items: center;
        justify-content: center;

        .input {
          &:focus {
            background: $white;
            border: none !important;
            box-shadow: none;
          }
        }
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        @media (max-width: 991px) {
          height: setUnit(36);
        }
        &:focus {
          border-width: 2px;
        }
        .icon {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          transform: unset;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $dark-color;
            }
          }
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: setUnit(8);
          display: none;
          width: 0;
        }
      }
    }
  }
}

.button {
  @include button($secondary-color, $white, $hover-blue);
  border-radius: setUnit(100);
  margin-left: setUnit(16);
  width: fit-content;
  height: setUnit(40);
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: setUnit(16);
  line-height: setUnit(21);
  font-weight: normal;
  text-decoration: none;

  & svg {
    width: setUnit(24);
    margin-right: setUnit(8);
    vertical-align: middle;

    path {
      stroke: $white;
      stroke-width: 2px;
    }
  }
}
