@import 'styles/variables';
@import 'styles/mixins';

.pageContainer {
  height: calc(100vh - $header-height) !important;

  .pageContent {
    overflow: auto;
    @include responsiveContentLayout();
  }

  .contentContainer {
    height: calc(100vh - $header-height);
  }

  .mainPageContainer {
    @include responsiveContentLayout();
  }
}

.toolBar {
  background: $background-ligh-gray !important;
  position: sticky;
  top: 0;
  z-index: 99999;
  width: 100%;
  border-bottom: 1px solid $light-gray;
}

.bgWhite {
  background-color: $white !important;
  .pageContent {
    background: $white !important;
  }
  .footer {
    [class*='ant-layout-footer'] {
      background: $white !important;
    }
  }
}

.bgGray {
  .pageContent {
    background: $background-ligh-gray !important;
  }
}

.pageContainerV1 {
  background: $background-ligh-gray;
  min-width: $tablet;

  .pageContent {
    background: $background-ligh-gray;
  }

  .footer {
    [class*='ant-layout-footer'] {
      background: $background-ligh-gray !important;
    }
  }

  .mainPageContainer {
    padding: 64px 160px 64px 112px;
  }
}

.pageContainerV2 {
  background: $white;

  .pageContent {
    background: $white;
  }

  .mainPageContainer {
    padding: 34px 40px;
  }
}

.pageContainerV3 {
  height: calc(100dvh - $header-height - $forms-footer-height) !important;

  .pageContent {
    overflow: auto;
    min-height: calc(100dvh - $header-height - $footer-height - $forms-footer-height) !important;
  }

  .contentContainer {
    height: calc(100dvh - $header-height - $forms-footer-height) !important;
  }

  .mainPageContainer {
    min-height: calc(100dvh - $header-height - $footer-height - $forms-footer-height) !important;
  }
}
