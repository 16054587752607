@import 'styles/functions';
@import 'styles/variables';

.addressWrapper {
  cursor: initial;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .header {
    width: 100%;
    position: relative;
    svg {
      position: absolute;
      top: 4px;
      right: 0;
    }
    &:hover {
      text-decoration: underline;
    }
    .title {
      color: $text-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.09px;
      text-align: center;
    }
    .subtitle {
      color: $dark-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.09px;
      text-align: center;
      margin-top: 4px;
    }
  }

  .content {
    display: flex;
    column-gap: 16px;
    width: 100%;

    .plotContainer {
      width: 50%;
      min-height: 148px;

      border-radius: 12px;
      border: 1px solid $light-gray;
      padding: 20px;
      position: relative;

      .title {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.33px;
      }

      .subtitle {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 114.286% */
        letter-spacing: -0.35px;
        display: flex;
        margin-top: 4px;
        svg {
          position: relative;
          top: 1px;
        }

        span {
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 15px; /* 100% */
          letter-spacing: -0.35px;
          margin-left: 6px;
          place-self: flex-end;
          padding-bottom: 5px;
          &.increase {
            color: var(--Tertiary-Green-Success-100, #04a451);
          }
          &.decrease {
            color: var(--Tertiary-Red-Error-100, #d0021b);
          }

          .percentage {
            display: flex;
          }
        }
      }

      .purple {
        color: #827cce;
        .placeholderLine {
          background-color: rgba(#827cce, 0.3);
        }
      }

      .aqua {
        color: #51bfe1;
        .placeholderLine {
          background-color: rgba(#51bfe1, 0.3);
        }
      }

      .placeholderContainer {
        position: relative;
        display: inline-block;

        .placeholderText {
          position: relative;
          z-index: 1;
        }

        .whiteOverlay {
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            #fff 30%,
            rgba(255, 255, 255, 0) 100%
          );

          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          top: 0;

          background-size: 500%;
          -webkit-text-fill-color: transparent;
          animation: overlayMove 3s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
          z-index: 2;
        }
      }

      .placeholderLine {
        height: 2.5px;
        width: auto;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
      }

      .plot {
      }
    }
  }

  .activityWrapper {
    display: flex;
    column-gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;

    .activityCard {
      display: flex;
      padding: 26px 20px 26px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      border-radius: 12px;
      border: 1px solid $light-gray;

      width: 100%;
      max-width: 213px;

      .activityCardTitle {
        color: $text-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.33px;

        white-space: nowrap;
      }

      .orangeTitle {
        color: $orange;
      }

      .greenTitle {
        color: $green-color;
      }

      .redTitle {
        color: $primary-color;
      }

      .activityCardContent {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 114.286% */
        letter-spacing: -0.35px;

        .dots {
          font-size: 28px;
          font-weight: 700;
          line-height: 32px; /* 114.286% */
          letter-spacing: 1px;
          opacity: 0.5;
        }
      }
    }
  }
}

@keyframes overlayMove {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
