@import 'styles/variables';

.formTable {
  & [class*='ant-table-tbody'] {
    background: $white;
  }

  & [class*='ant-table-thead'] {
    & [class*='ant-table-cell'] {
      padding: 16px 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $dark-grey-color;
    }

    &
      tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none !important;
    }
  }

  & [class*='formListStatus'] {
    width: 100px;
    padding: 0px !important;
  }
}

.formTablePrimary {
  & [class*='ant-table-tbody'] {
    & [class*='ant-table-cell'] {
      word-break: break-word;
      padding: 28px 20px;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      color: $text-color;
    }
  }
  margin-top: 32px;
  overflow-y: auto;
}

.formTableNested {
  & [class*='ant-table-tbody'] > tr > td:nth-child(1) {
    border-left: 2px solid $border-light-gray-3 !important;
  }

  & [class*='ant-table-tbody'] > tr > td:nth-child(1)::before {
    content: '';
    width: 12px;
    border-bottom: solid 2px $border-light-gray-3;
    position: absolute;
    border-left: solid 2px $border-light-gray-3;
    left: -2px;
    height: 12px;
    z-index: 1;
    border-bottom-left-radius: 6px;
  }
  & [class*='ant-table-tbody'] > tr:not(:last-child) > td:nth-child(1)::before {
    top: 39%;
  }
  & [class*='ant-table-tbody'] > tr:last-child > td:nth-child(1)::before {
    top: 98%;
  }
}

.textBold {
  font-weight: 600;
}

.subText {
  display: block;
  color: $text-gray-color;
  margin-top: 4px;
}

.formFavoriteIcon > svg:hover {
  cursor: pointer;
}

.formName {
  color: $black;
}
