@import 'styles/functions';
@import 'styles/variables';

.statusWrapper {
  padding: 0.375rem 0.635rem 0.25rem;
  border-radius: 100px;
  font-size: 12px;
  background: $white;
  font-weight: 600;

  &.Active {
    background: $white;
  }

  &.Pending {
    background: $primary-color;
    color: $white;
  }

  &.ActiveUnderContract {
    background: $primary-color;
    color: $white;
  }
}
