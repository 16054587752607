@import 'styles/functions';
@import 'styles/variables';

.kitsActivation {
  .answers {
    max-width: 800px;

    .spacing {
      width: 100%;
      [class~='ant-space-item'] {
        width: 100%;
      }

      .text {
        text-align: center;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $text-color;
        margin: 0;

        .info {
          font-weight: 700;
        }
      }

      .KitsTable {
        border-radius: setUnit(16);
        box-shadow: 0px 2px 16px 0px #30303008;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $light-gray;
        overflow: hidden;

        div {
          width: 100%;
        }

        [class*='ant-table-cell'] {
          background: #fff;
        }
        .nameColumn {
          [class*='ant-table-column-title'] {
            flex: none;
          }
        }

        .table {
          border-radius: setUnit(16);
          flex: 1;
        }
      }
    }
  }
}

.detailsWrapper {
  .title {
    color: var(--Grayscale-100, #262626);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.33px;
  }
  .info {
    color: var(--Grayscale-50, #747475);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.33px;
  }
}
