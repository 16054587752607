@import 'styles/variables';
@import 'styles/functions';

.InitialsWithIconAvatar {
  .commentIndicatorDefer {
    cursor: pointer;
    position: absolute;
    z-index: -1;
    .commentIcon {
      border-radius: 100%;
      background-color: white;
      position: absolute;
      left: 16px;
      top: 22px;
    }
  }

  .avatarNameTooltip {
    [class*='ant-tooltip-inner'] {
      background-color: white !important;
      color: black !important;
      border-radius: 8px !important;
    }

    [class*='ant-tooltip-arrow-content']::before {
      background: white !important;
    }
  }

  .avatar {
    background-color: $secondary-color !important;
    color: $white;
    font-size: setUnit(24) !important;
    line-height: setUnit(32) !important;
    font-weight: 600;
  }
  .initials {
    font-size: setUnit(14) !important;
  }
}
