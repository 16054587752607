@import 'styles/functions';
@import 'styles/variables';

.photoSection {
  display: flex;
  justify-content: flex-end;
  &.containerFive {
    .photoSectionCol {
      @media screen and (max-width: $desktop) {
        width: setUnit(150);
      }

      @media screen and (max-width: $tablet) {
        width: setUnit(130);
      }

      @media screen and (max-width: $mobile) {
        width: setUnit(100);
      }

      @media screen and (max-width: $mobile-small) {
        width: setUnit(70);
      }
    }
  }

  &.containerFour {
    .photoSectionCol {
      @media screen and (max-width: $tablet) {
        width: setUnit(160);
      }

      @media screen and (max-width: $mobile) {
        width: setUnit(130);
      }

      @media screen and (max-width: $mobile-small) {
        width: setUnit(100);
      }
    }
  }

  &.containerThree {
    .photoSectionCol {
      @media screen and (max-width: $mobile) {
        width: setUnit(160);
      }

      @media screen and (max-width: $mobile-small) {
        width: setUnit(130);
      }

      @media screen and (min-width: $desktop) {
        width: setUnit(225);
      }
    }
  }

  &.containerTwo {
    .photoSectionCol {
      @media screen and (min-width: $desktop) {
        width: setUnit(410);
      }
    }
  }

  table {
    tr {
      .cell {
        width: setUnit(224);
      }
    }
  }

  &Col {
    margin-right: setUnit(14);

    &Box {
      margin-bottom: setUnit(8);
      display: flex;
      flex-flow: column;
      & > label {
        overflow-wrap: break-word;
      }
      .photo {
        height: setUnit(210);
        width: setUnit(210);
        border-radius: setUnit(2);
        object-fit: cover;
      }

      .photoFive {
        @media screen and (max-width: $desktop) {
          height: setUnit(150);
          width: setUnit(150);
        }

        @media screen and (max-width: $tablet) {
          height: setUnit(130);
          width: setUnit(130);
        }

        @media screen and (max-width: $mobile) {
          height: setUnit(100);
          width: setUnit(100);
        }

        @media screen and (max-width: $mobile-small) {
          height: setUnit(70);
          width: setUnit(70);
        }
      }

      .photoFour {
        @media screen and (max-width: $tablet) {
          height: setUnit(160);
          width: setUnit(160);
        }

        @media screen and (max-width: $mobile) {
          height: setUnit(130);
          width: setUnit(130);
        }

        @media screen and (max-width: $mobile-small) {
          height: setUnit(100);
          width: setUnit(100);
        }
      }

      .photoThree {
        @media screen and (max-width: $mobile) {
          height: setUnit(160);
          width: setUnit(160);
        }

        @media screen and (max-width: $mobile-small) {
          height: setUnit(130);
          width: setUnit(130);
        }
      }

      .photoTwo {
        @media screen and (min-width: $desktop) {
          width: setUnit(410);
          height: setUnit(310);
        }
      }
    }
  }
}

.noPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  letter-spacing: -0.09px;
  color: $hover-red;
}
