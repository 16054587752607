@import 'styles/functions';
@import 'styles/variables';

.threadOption {
  display: flex;
  column-gap: 9px;
  margin-left: 5px;

  .threadOptionIcon {
    margin-top: 3px;
  }
}
