@import 'styles/mixins';
@import 'styles/variables';

.inputWrapper {
  .phoneInputWrapper {
    .phone {
      width: 100%;
      cursor: default;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      font-size: inherit;
      color: inherit;
      height: auto;
      font-family: inherit;
    }

    .dropdownButton {
      display: none;
    }
    flag-dropdown {
      display: none;
    }

    * {
      border: 0;
    }
  }
}
