@import 'styles/functions';
@import 'styles/variables';

.history {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 18rem;
  overflow-y: auto;

  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .showAllBtn {
    cursor: pointer;
    margin: 8px 0px;

    .showAllIcon {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: #f4f5f6;
      border: none;
      margin-right: 16px;
    }

    .showAllTxt {
      font-weight: 600;
    }
  }

  .disclaimer {
    text-align: center;
    margin-bottom: 0;
    min-height: 80px;
    color: $text--light-gray-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loadingSpinner {
    font-size: 0.4em;
    margin: 50px auto;
  }

  .auditLogItem {
    max-height: unset !important;

    & > div {
      display: block;
      -webkit-box-orient: unset;
      text-overflow: inherit;
      overflow: visible;

      font-size: setUnit(14);
    }
  }
}
