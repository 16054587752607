@import 'styles/functions';
@import 'styles/variables';
.pageHeader {
  font-family: $font-family;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  margin: 0 0 setUnit(24);
}
