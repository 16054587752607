@import 'styles/functions';
@import 'styles/variables';

.noListingsBlock {
  height: 284px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  .noListingHeading {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.33px;
    color: #515151;
  }

  .noListingText {
    color: $text-gray-color;
  }
}

.criteriaBtn {
  &[class*='ant-btn'] {
    position: absolute;
    display: flex;
    height: 44px;
    top: 12.5rem;
  }

  padding: 0.5rem 1.25rem 1.25rem;
  border-radius: 64px !important;
  background-color: $white !important;
  color: $dark-color !important;
  font-weight: 600 !important;
  top: 251px;
  align-items: center;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: $white !important;
  }
  &:focus {
    border-color: $primary-color;
  }

  .filterIcon {
    margin-right: 0.25rem;
  }
}
