@import 'styles/functions';
@import 'styles/variables';

.yesNo {
  .option {
    height: 24px;
    &.hasInput {
      height: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .checkbox {
      .static {
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.18px;
        color: $dark-color;
      }

      &[class~='ant-checkbox'] {
        border: 1.5px solid #aaabab;
        border-radius: 4px;
      }

      .daysInputHolder {
        border: none;
        width: 40px;
        padding: 0px !important;
        margin: 0 8px 0 28px;
        input {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.18px;
          color: $text-color;
          border-bottom: 2px solid $border-light-gray-3;
          padding-left: 0;
          &::placeholder {
            color: $text-gray-color;
          }
        }
        [class~='ant-input-number-handler-wrap'] {
          display: none;
        }
        &[class~='ant-input-number-focused'] {
          box-shadow: none;
          input {
            border-bottom-color: $text-gray-color;
          }
        }
      }

      .inputHidden {
        visibility: hidden;
      }

      [class*='ant-checkbox-wrapper'] {
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }

        &:focus {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class~='ant-checkbox'] {
        [class~='ant-checkbox-inner'] {
          border-radius: 50% !important;
        }
      }

      [class*='ant-checkbox-checked'] {
        border-color: $primary-color !important;

        &:after {
          border-color: $primary-color !important;
          border-radius: 50%;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color !important;
          border-color: $primary-color !important;
        }
      }
    }
  }
}
