@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.highlights {
  border-top: 1px solid $light-gray;
  margin-top: setUnit(40);
  padding-top: setUnit(24);

  .title {
    margin: 0 0 setUnit(16) 0;
    color: $text-color;
    font-weight: bold;
    font-size: setUnit(16);
    font-weight: 600;
    line-height: 20.8px; /* 130% */
    letter-spacing: -0.18px;
  }

  .content {
    @include customScrollbar;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: $text--light-gray-color;
      }
    }
    .highlightsList {
      .highlightItems {
        &:nth-child(odd) {
          background-color: $background-grey-2;
        }

        div:nth-child(2) {
          color: $text-color;
          font-size: setUnit(14);
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.09px;
          max-width: 300px;
        }
      }
    }
  }

  .disclaimer {
    text-align: center;
  }
}
