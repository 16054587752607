@import 'styles/functions';
@import 'styles/variables';

.transactionAccess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: setUnit(14) 0;

  .label {
    color: $text-gray-color;
    display: inline-block;
  }

  .accessSelect {
    float: right;
    position: relative;
    right: -0.5rem;
    top: -0.25rem;

    & [class*='ant-select-selection-item'] {
      text-align: end;
    }
  }
}
