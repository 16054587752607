@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  flex: 1;
  min-height: 100vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  min-width: setUnit(400);

  &.rightBlock {
    margin-left: setUnit(32);
  }
}

.antdSelect {
  width: 100% !important;
}

.antdSelectAgent {
  width: 100% !important;
  & [class*='ant-select-selection-item'] {
    align-items: center !important;
  }

  & [class*='ant-select-selection-placeholder'] {
    align-items: center !important;
    display: flex !important;
  }
}

.pageWrapper {
  display: flex;
}

.avatar {
  width: 100% !important;
  height: 100% !important;
}

.updateAvatar {
  span {
    font-size: setUnit(14);
    display: block;
    margin-top: setUnit(16);
    text-align: center;
    transition: all 0.1s;
  }

  div {
    font-size: setUnit(28);
  }

  &:hover span {
    text-decoration: underline;
  }
}

.title {
  //max-width: setUnit(640);
  padding-bottom: setUnit(33);
  margin-bottom: setUnit(33);

  & > h2 {
    font-size: setUnit(24);
    color: $secondary-color;
    font-weight: 600;
    margin-right: setUnit(156);
  }
}

.subTitle {
  font-size: setUnit(20);
  line-height: setUnit(30);
  color: $secondary-color;
  font-weight: 600;
  margin-right: setUnit(156);
}

.saveBtn {
  height: setUnit(40) !important;
  width: setUnit(100) !important;
  transition: all 0.1s;
  border-radius: setUnit(100) !important;
}

.editBtn {
  border: 0;
  outline: none;
  border-radius: 100%;
  width: setUnit(40);
  height: setUnit(40);
  background-color: $primary-color;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.label {
  flex: 1;
  font-size: setUnit(14);
  color: $dark-grey-color;
  align-self: flex-start;
  padding-top: setUnit(14);
}

.editLabel,
.phone label {
  flex: 1;
  font-size: setUnit(14);
  color: $dark-grey-color;
  align-self: flex-start;
  padding-top: setUnit(30);
  font-weight: normal !important;
}

.inputBlock {
  display: flex;
  flex-wrap: wrap;
  min-height: setUnit(44);
  align-items: center;
  margin-bottom: setUnit(10);

  .rightBlock {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-top: setUnit(10);

    .input {
      margin: 0 0 setUnit(10) 0 !important;
    }
  }

  .locationsBlock {
    max-width: 75%;
  }

  .infoBlockForOneLine {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding: setUnit(10) 0;
    border-bottom: 2px $light-gray solid;
  }

  .infoBlockForMultipleLines {
    flex: 3;
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    border-bottom: 2px $light-gray solid;
  }

  .nonEditableValue {
    padding: setUnit(10) 0;

    &:not(:last-child) {
      margin-bottom: setUnit(2);
    }

    a {
      color: $text-color;
      transition: all 0.1s;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.flexWrapper {
  display: flex;
  justify-content: flex-start;
}

.checkbox {
  margin-right: setUnit(40);
}

.checkboxLabel {
  font-size: setUnit(14);
  color: $dark-grey-color;
  margin-left: setUnit(8);
}

.locationError,
.phonesError {
  flex-basis: 100%;
  padding-left: 25%;
  display: flex;
}

.selectWrapper {
  margin: 0 !important;
}

.formsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.areas {
  display: flex;

  .locationsBlock {
    flex: 4;
  }

  .radiusBlock {
    flex: 1 !important;
    margin-left: setUnit(16);
    display: flex;
    flex-wrap: nowrap;
  }
}

.groupedInputs {
  display: flex;
  flex: 3;
}

@media screen and (max-width: $tablet) {
  .wrapper {
    width: 100%;

    &.rightBlock {
      margin-left: 0;
    }
  }
}
