@import 'styles/functions';
@import 'styles/variables';

.transactionActivityHeader {
  margin-bottom: setUnit(40);
  margin-top: setUnit(8);
  .heading {
    font-weight: 700;
    font-size: setUnit(30);
  }
  .filterColumn {
    display: flex;
    justify-content: flex-end;
    .searchInput {
      border: none !important;
      width: setUnit(278);
      height: setUnit(44);
      border: 1px solid #aaabab;
      border-radius: setUnit(100);
      margin: 0;
      margin-right: 1rem;
      background-color: #f4f5f6;

      .searchInputHolder {
        input {
          border-radius: setUnit(100);
          background-color: #f4f5f6 !important;
          font-size: setUnit(16);
          color: #aaacb2;
          &:focus {
            border: 1px solid;
            background-color: #ffffff !important;
          }
        }
      }
    }
    .icon {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      border-radius: setUnit(100);
      padding: 0.5rem 0.75rem;
      width: setUnit(44);
      height: setUnit(44);
      cursor: pointer;
      background-color: $background-grey-2;
      transition: $transition;

      &:hover {
        background-color: $light-gray;
      }

      svg {
        width: setUnit(20);
        height: setUnit(20);

        path {
          stroke: $text-color;
        }
      }
    }
  }
}
