@import 'styles/functions';
@import 'styles/variables';

.selectTimeLabel {
  color: $text-gray-color;
  text-align: left;
  font-weight: 500;
  font-size: setUnit(14);
  line-height: setUnit(20);
  letter-spacing: -0.18px;
}

.timeListArea {
  max-height: setUnit(424);
  overflow: auto;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0;
  @media (max-width: 991px) {
    height: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
}

.timeBlock,
.timeBlockSelected {
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: opacity 0.2s;
}

.timeAvailable {
  border: 2px solid $light-gray;
  color: $dark-color;
  border-radius: 9px;
  margin: 0 auto;
  padding: 14px 0px;
  transition: all 0.1s;
  &:hover {
    border-color: $text--light-gray-color;
  }
}

.timeAvailableSelected {
  border: 2px solid $light-gray;
  color: $dark-color;
  border-radius: 9px;
  margin: 0 auto;
  padding: 14px 0px;
  transition: all 0.1s;
  background-color: $secondary-color !important;
  color: $white !important;
}

.confirmButton {
  border: 2px solid $secondary-color;
  background-color: $secondary-color;
  color: $white;
  border-radius: 9px;
  margin: 0 auto;
  padding: 14px 0px;
  width: 48%;
  display: none;
  margin-left: 2px;
  transition: all 0.1s;
}

.selected > .timeAvailable {
  border: 2px solid $secondary-color;
  color: $secondary-color;
  cursor: pointer;
  margin: 0 auto;
  padding: 14px 0px;
  width: 48%;
  display: inline-block;
  margin-right: 2px;
}

.selected > .confirmButton {
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .timeAvailable,
  .confirmButton {
    font-size: 14px;
  }
}

@media screen and (max-width: $mobile-small) {
  .timeListArea {
    gap: 10px;

    .timeBlock,
    .timeBlockSelected {
      width: 48%;
    }
  }

  .selectTimeLabel {
    display: none;
  }
}

// @media screen and (min-width: 451) and (max-width: 445px) {
//   .timeBlock {
//     .timeAvailable,
//     .confirmButton {
//       font-size: 14px;
//     }
//   }
// }

@media screen and (max-width: 450px) {
  .timeListArea {
    .timeBlock,
    .timeBlockSelected {
      width: 100%;
    }
  }
}
