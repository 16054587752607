@import 'styles/functions';
@import 'styles/variables';

.inputWrapper {
  position: relative;
  width: 100%;

  .inputHolder {
    position: relative;
    .valuesWrapper {
      padding: 8px 42px 0 42px;
      div[class*='values'] {
        margin-top: 0;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
      }
    }
  }

  .prefix {
    position: absolute;
    top: 0.9rem;
    left: setUnit(16);
    font-size: setUnit(14);
    font-weight: 400;
    z-index: 2;
  }

  .postfix {
    position: absolute;
    top: setUnit(12);
    right: setUnit(16);
    font-size: setUnit(14);
    font-weight: 400;
    z-index: 2;

    .loader {
      font-size: setUnit(2.5);
      margin-top: setUnit(10);
      margin-bottom: 0;
      margin-right: setUnit(10);
    }

    .icon {
      svg {
        vertical-align: middle;
      }
    }
  }

  .search {
    margin: 0;

    input {
      background: $hover-light-gray;
      border-color: $hover-light-gray;
      padding-left: setUnit(45);
      padding-top: 0.9rem;
    }

    .value {
      &:after {
        border: none;
      }
    }
  }

  .role {
    font-weight: 400;
    font-size: setUnit(14);
    color: $border-middle-gray;
    margin-left: setUnit(8);
    display: inline-block;
    text-transform: capitalize;
  }
}
