@import 'styles/functions';

.mapContainer {
  position: relative;
  height: setUnit(164);
  border-radius: setUnit(8);
  overflow: hidden;
  margin-bottom: setUnit(32);

  .map {
    height: setUnit(164);

    .mapControls {
      margin: setUnit(18);
    }
  }

  &Expand {
    position: absolute;
    top: setUnit(8);
    right: setUnit(8);
    cursor: pointer;

    svg {
      background-color: white;
    }
  }
}
