@import 'styles/mixins.scss';

@mixin inputBlock {
  margin-bottom: setUnit(24);
  &:not(:last-child) {
    margin-bottom: setUnit(24);
  }

  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 12px;
    color: $text-color;
  }

  .inputPhoneWrapper {
    margin: 0;

    [class*='react-tel-input'] {
      font-family: $font-family;
      font-size: setUnit(16);

      input {
        border-color: $border-light-gray-2;
        height: setUnit(48) !important;
        font-size: setUnit(16);
      }
    }
  }

  .inputHolder {
    @include lightRound;
    border: none;
    width: 100%;
    padding: 0px !important;

    input {
      border: 1px solid $border-light-gray-2 !important;
      font-size: setUnit(16);
      line-height: 20.8px;
      border-radius: 8px;
      &:focus {
        border-color: $text--light-gray-color !important;
      }
    }
  }

  [class*='ant-picker-input'] {
    border: 1px solid $border-light-gray-2 !important;
    border-radius: 8px;
    height: setUnit(48);
    input {
      border: unset !important;
      order: 1;
      padding: setUnit(8) setUnit(13) setUnit(8) setUnit(8) !important;
      margin-right: 1rem;
    }

    [class*='ant-picker-suffix'] {
      margin-left: setUnit(13);
      span {
        display: block !important;
        color: $text--light-gray-color !important;
      }
    }

    [class*='ant-picker-clear'] {
      span {
        display: block !important;
        margin-right: setUnit(8) !important;
      }
    }
  }

  [class~='ant-picker-focused'] {
    box-shadow: none !important;
    [class*='ant-picker-input'] {
      border: 1px solid $text--light-gray-color !important;
    }
  }

  [class~='ant-select-selector'] {
    border: 1px solid $border-light-gray-2 !important;
    box-shadow: none !important;
    padding: setUnit(7) setUnit(13) !important;
    border-radius: 8px;
    font-size: setUnit(16) !important;
    height: setUnit(48) !important;

    &:focus {
      border-color: $text--light-gray-color !important;
    }
  }

  [class~='ant-select-open'] {
    [class~='ant-select-selector'] {
      border-color: $text--light-gray-color !important;
    }
  }

  textArea {
    border: 1px solid $border-light-gray-2 !important;
    font-size: setUnit(16);
    line-height: 20.8px;
    border-radius: 8px;
    padding: setUnit(8) setUnit(13);
    &:focus {
      border-color: $text--light-gray-color !important;
      box-shadow: unset !important;
    }

    &::placeholder {
      color: $text--light-gray-color;
    }
  }
}

@mixin checkboxBlock {
  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 20px;
    color: $text-color;
  }

  width: fit-content;
  color: $dark-color;

  &:hover [class*='ant-checkbox-inner'] {
    border-color: $primary-color !important;
  }

  & [class*='ant-checkbox'] {
    & [class*='ant-checkbox-inner'] {
      &:after {
        transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
        left: 25%;
        width: setUnit(6) !important;
        height: setUnit(12) !important;
        top: 45%;
      }
    }
  }
  & [class*='ant-checkbox-checked'] {
    &:after {
      border-color: $primary-color;
    }
    & [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

@mixin daysInputHolder {
  border: none !important;
  width: auto !important;
  padding: 0px !important;
  margin: 0 12px 0 15px;

  input {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    text-align: center;

    border: 1px solid $light-grey-color !important;
    font-size: setUnit(16);
    width: 62px;
    height: 40px;
    border-radius: 8px;
    padding: 0 11px;

    &:focus {
      border-color: $text--light-gray-color !important;
    }

    &::placeholder {
      color: $text-gray-color !important;
    }
  }

  [class~='ant-input-number-handler-wrap'] {
    display: none;
  }

  &[class~='ant-input-number-focused'] {
    box-shadow: none;
    input {
      border-bottom-color: $text-gray-color;
    }
  }

  &.daysInputHolderError {
    input {
      border: 1.5px solid $error-color !important;
    }
  }
}

@mixin checkedRadio {
  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      &::after {
        left: calc(50% - 5px);
        width: 6px;
        height: 12px;
        top: 45%;
      }
    }

    & + span {
      span {
        color: $text-color !important;
      }
    }
  }
}

@mixin inputField {
  border: 1px solid $border-light-gray-2 !important;
  font-size: setUnit(16);
  line-height: 20.8px;
  border-radius: 8px;
  &:focus {
    border-color: $text--light-gray-color !important;
  }
}

@mixin datePickerHolder {
  [class~='ant-picker'] {
    border: none;
    padding: 0;
  }

  [class*='ant-picker-input'] {
    border: 1px solid $border-light-gray-2 !important;
    border-radius: 8px;
    height: setUnit(48);
    input {
      border: unset !important;
      order: 1;
      padding: setUnit(8) setUnit(13) setUnit(8) setUnit(8);
      font-size: setUnit(16);
      line-height: 20.8px;
    }

    [class*='ant-picker-suffix'] {
      margin-left: setUnit(13);
      span {
        display: block !important;
        color: $text--light-gray-color !important;
      }
    }

    [class*='ant-picker-clear'] {
      span {
        display: block !important;
        margin-right: setUnit(8) !important;
      }
    }
  }

  [class~='ant-picker-focused'] {
    box-shadow: none !important;
    [class*='ant-picker-input'] {
      border: 1px solid $text--light-gray-color !important;
    }
  }
}
