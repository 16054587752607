@import 'styles/mixins';
@import 'styles/variables';

.submitTitle {
  display: flex;
  justify-content: center;
}

.lightArrow {
  margin-left: 12px;

  path {
    stroke: $white;
  }
}

.button {
  width: 100%;
  height: 52px !important;
  border-radius: 8px !important;
  opacity: 1;
  .submitTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.09px;
  }
  .submitTitle {
    color: $white;
  }
  .loader {
    font-size: 4px;
    color: #ffffff;
  }
  .skipLoader {
    font-size: 4px;
    color: $secondary-color;
  }
  &[disabled] {
    opacity: 0.3;
  }
}
