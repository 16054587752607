@import "styles/functions";
@import "styles/variables";


.wrapper {
    padding: setUnit(32) setUnit(32) 0 setUnit(20);
    height: setUnit(72);

    .allMessagesBtn {
        display: flex;
        align-items: center;
        font-size: setUnit(14);
        line-height: setUnit(16);
        color: $text-color;
        cursor: pointer;
        padding: setUnit(12) setUnit(16);
        border-radius: setUnit(20);
        border: 0;
        outline: none;
        background-color: transparent;
        transition: all .1s;

        .arrow {
            margin-right: setUnit(10);
        }

        &:hover {
            background-color: $hover-light-gray;
        }
    }

    .settingsBtn {
        cursor: pointer;
        background-color: $hover-light-gray;
        border-radius: 50%;
        width: setUnit(40);
        height: setUnit(40);
        border: 0;
        outline: none;

        &:hover {
            background-color: $light-gray;
        }

        svg {
            width: setUnit(24);
            height: setUnit(24);
        }
    }

    .headerBtns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}