@import 'styles/functions';
@import 'styles/variables';

.file {
  .fileDomInput {
    display: none;
  }

  .documentItem {
    margin-top: 20px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    padding: 20px 20px 20px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .icon {
      margin-right: 16px;
      path {
        stroke: $dark-color;
      }
    }
    .info {
      .fileName {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 8px;
      }
      .fileSize {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.09px;
        margin-bottom: 0;
        color: $dark-color;
      }
    }
    .trash {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: $dark-color;
        }
      }
    }
  }
}
