@import 'styles/functions';
@import 'styles/variables';

.homeActionsContainer {
  position: relative;

  .homeActionsWrapper {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: setUnit(24);
    background-color: $white;
  }

  .price {
    font-weight: 700;
    font-size: setUnit(16);
    line-height: setUnit(20);
    margin-bottom: setUnit(11);
  }

  .address {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(20);
    color: $dark-grey-color;
    margin-bottom: setUnit(4);
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: setUnit(24);
    gap: 12px;
    @media (min-width: $mobile-small) {
      flex-direction: row;
      margin: setUnit(24) setUnit(-8) 0;
    }
    @media (min-width: $tablet) {
      flex-direction: column;
      width: 100%;
      margin: setUnit(24) 0 0;
    }
    .homeActionsButton {
      flex-grow: 1;
      background: $white;
      border: 1px solid $border-middle-gray;
      color: $text-color;
      padding: setUnit(12) setUnit(24);
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      width: 100%;
      @media (min-width: $mobile-small) {
        width: 33.333%;
        padding: setUnit(12);
      }
      @media (min-width: $tablet) {
        width: 100%;
        padding: setUnit(12) setUnit(24);
      }
      &:hover {
        border-color: $text-color;
      }
      .buttonTitle {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        color: $dark-color;
        @media (min-width: $mobile-small) {
          font-size: setUnit(14);
          line-height: setUnit(19);
        }
        @media (min-width: $tablet) {
          font-size: setUnit(16);
          line-height: setUnit(21);
        }
        & > .buttonIcon {
          &.recommended {
            svg {
              fill: $orange;
              path {
                stroke: $orange;
              }
            }
          }

          display: flex;
          margin-right: setUnit(12);
          @media (min-width: $mobile-small) {
            margin-right: setUnit(8);
          }
          @media (min-width: $tablet) {
            margin-right: setUnit(12);
          }
          svg {
            width: setUnit(24);
            height: setUnit(24);
            @media (min-width: $mobile-small) {
              width: setUnit(20);
              height: setUnit(20);
            }
            @media (min-width: $tablet) {
              width: setUnit(24);
              height: setUnit(24);
            }
          }
        }
      }
      &.lastButton {
        background: $background-blue;
        padding-left: setUnit(22);
        border: unset;
        .buttonTitle {
          color: $white;
        }
        &:hover {
          border-color: unset;
        }
      }
    }
  }
}

.commentIcon {
  position: relative;
  display: flex;
  align-items: center;

  &.isUnread {
    &:before {
      content: '';
      width: setUnit(6);
      height: setUnit(6);
      border-radius: 50%;
      background: $primary-color;
      position: absolute;
      top: setUnit(-0.7);
      right: setUnit(-0.7);
      border: 2px solid $background-ligh-gray;
    }
  }

  svg {
    width: setUnit(24);
    height: setUnit(24);
  }
}

.clientsIcon {
  svg {
    width: setUnit(24);
    height: setUnit(24);
  }
}

.docIcon {
  svg {
    path {
      stroke: $white;
    }
  }
}
