@import 'styles/functions';
@import 'styles/variables';

.content {
  width: 100%;
  height: 100%;

  .pendingWrapper {
    height: 100%;
  }

  .detailsContent {
    height: 100%;

    .details {
    }

    .metaInfo {
    }
  }
}
