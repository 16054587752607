@import 'styles/functions';
@import 'styles/variables';

.kitNotifications {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: setUnit(20);

  @media (min-width: $mobile) {
    flex-direction: row;
    align-items: center;
  }

  .notificationLabel {
    font-weight: 400;
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-family: $font-family;
    letter-spacing: setUnit(-0.09);
    color: $text-color;
    margin-bottom: setUnit(16);

    @media (min-width: $mobile) {
      flex-direction: row;
      align-items: center;
      margin-bottom: setUnit(0);
    }
  }

  .optionsGroup {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $mobile) {
      justify-content: start;
    }

    .checked {
      color: #262626;
      font-weight: 500;
      border: 2px solid $primary-color;
      background-color: $primary-color-light;
    }

    .unchecked {
      color: $dark-color;
      font-weight: 400;
      border: 2px solid $light-gray;
      background-color: #fff;
    }

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $dark-color;
      width: setUnit(68);
      height: setUnit(47.2);
      border-radius: 8px;
      cursor: pointer;

      &:not(:last-child) {
        opacity: 0.7;
        margin-right: setUnit(8);
      }
    }
  }
}
