@import 'styles/mixins';
@import 'styles/variables';

.insertClauseWrapper {
  .clausesHeader {
    padding-left: 40px;
    padding-right: 40px;
  }

  .insertClauseContent {
    .insertClauseContentWrapper {
      .loadingSpinner {
        font-size: 0.5em;
        margin: 50px auto;
      }

      .clausesSearch {
        padding-left: 40px;
        padding-right: 40px;
      }

      .clausesListContent {
        .clauseTitle {
          margin-top: 32px;
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.18px;

          color: $text-gray-color;
          padding: 0px 40px;
        }

        .clausesList {
          max-height: 60vh;
          overflow-y: auto;

          .clausesListItem {
            display: flex;
            align-items: flex-start;
            gap: 12px;

            border-top: 1px solid $light-gray;

            padding: 20px 40px;

            cursor: pointer;

            .clausesIcon {
              line-height: 0px;
              flex-shrink: 0;
            }

            .clauseItemTitle {
              font-weight: 600;
              margin-bottom: 4px;
            }

            .clauseItemTitle,
            .clauseItemDescription {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.18px;
              text-align: left;
              color: $dark-gray-3;
            }

            &:hover {
              background-color: $background-grey-2;
            }
          }
        }
      }

      .noClausesTemplates {
        border-top: none !important;
        padding-bottom: 5rem;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .clausesHeader,
  .clausesListItem,
  .clausesSearch,
  .clauseTitle {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
