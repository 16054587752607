@import 'styles/variables';
@import 'mixins.module.scss';

.commonModal {
  & [class*='ant-btn-primary'] {
    @include modalButton($secondary-color, $secondary-color-hover, $white, 600);
  }

  .cancelButton {
    @include modalButton($tertiary-50, $tertiary-50-hover, $black, 400);
  }

  & [class*='ant-modal-content'] {
    border-radius: 24px;
    display: inline-block;
    padding: 40px 40px 32px;
    width: 100%;
  }

  & [class*='ant-modal-header'] {
    border-bottom: none;
    border-radius: 24px;
    margin-bottom: 12px;
    padding: 0px;
  }

  & [class*='ant-modal-body'] {
    padding: 0px;
  }

  & [class*='ant-modal-footer'] {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: none;
    padding: 0px;
    [class~='ant-btn'] {
      border: none;
      border-radius: 8px;
      height: 52px;
      flex: 1;
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.18px;
        text-align: center;
      }
      & + [class~='ant-btn']:not(.ant-dropdown-trigger) {
        margin-left: 24px;
      }
    }
  }

  & [class*='ant-modal-close'] {
    top: 40px;
    right: 40px;
    & [class*='ant-modal-close-x'] {
      height: 32px;
      width: 32px;
      top: 0;
      right: 0;
      line-height: 0;
      font-size: 20px;
      position: relative;
      color: $text-color;
      .closeButton {
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}
