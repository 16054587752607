@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.participant {
  display: flex;
  padding: 24px;

  .participantResend {
    filter: brightness(0) saturate(100%) invert(49%) sepia(65%) saturate(2260%) hue-rotate(319deg)
      brightness(100%) contrast(85%);
  }

  &.hoverEffect {
    background: #4673d114;
  }

  .avatar {
    margin-right: setUnit(12);
    width: setUnit(36);
    height: setUnit(36);
    @media (min-width: $tablet) {
      width: setUnit(48);
      height: setUnit(48);
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .names {
      .name {
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        color: $text-color;
      }
      .role {
        margin-bottom: setUnit(6);
      }
      .role,
      .email {
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(20);
        letter-spacing: setUnit(-0.18);
        color: $text-gray-color;
      }

      .name,
      .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 18vw;
      }

      @media (max-width: 1540px) {
        .name,
        .email {
          max-width: 13vw;
        }
      }

      @media (min-width: 1200px) and (max-width: 1260px) {
        .name,
        .email {
          max-width: 9vw;
        }
      }

      @media (min-width: 993px) and (max-width: 1199px) {
        .name,
        .email {
          max-width: 17vw;
        }
      }

      @media (max-width: $tablet) {
        .name,
        .email {
          max-width: 230px;
        }
      }

      @media (max-width: 767px) {
        .name,
        .email {
          max-width: 50vw;
        }
      }
    }

    .options {
      display: flex;
      align-items: center;
      height: fit-content;

      .loader {
        color: gray;
      }

      .privateIcon {
        margin-right: setUnit(8);
      }

      .optionBtn {
        position: relative;
        .btn {
          border-radius: 100px;
          &:hover,
          &.hoverEffect {
            background-color: $light-gray;
          }
        }

        .menuOptions {
          @include box-shadow;

          position: absolute;
          top: setUnit(40);
          right: 0;
          background: #ffffff;
          border-radius: setUnit(8);
          min-width: setUnit(238);
          padding: 0;
          z-index: 10;

          ul {
            list-style: none;
            margin-bottom: 0.5rem;
          }

          .item {
            padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
            cursor: pointer;
            display: flex;
            align-items: center;

            .icon {
              margin-right: setUnit(8);
              position: relative;
              top: setUnit(-2);
            }

            &:hover {
              background: $background-ligh-gray;
            }
          }

          svg {
            vertical-align: middle;
          }
        }
      }
    }

    .rightContainer {
      display: flex;
      align-items: center;
      height: fit-content;

      @media screen and (max-width: $tablet) {
        [class~='ant-select'] {
          right: unset !important;
        }

        [class~='ant-select-selector'] {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 18px;
    .avatar {
      margin-right: setUnit(8);
      height: 30px;
      width: 30px;
      font-size: setUnit(14);
    }
  }

  @media (max-width: $tablet) {
    .content {
      flex-direction: column;
    }
  }
}
