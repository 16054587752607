@import 'styles/variables';

.compareModal {
  .modalContent {
    .modalHeader {
      color: $text-color;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Sora;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
      letter-spacing: -0.5px;
    }
  }
}
