.documentArea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .fileName {
    margin-right: 12px;
    margin-bottom: 0;
  }
}

.menuOverlay {
  display: flex;
  ul[class~='ant-dropdown-menu'] {
    border-radius: 8px;
    li[class~='ant-dropdown-menu-item'] {
      span[class~='ant-dropdown-menu-title-content'] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
