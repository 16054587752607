@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.optionsPopup {
  @include box-shadow;

  border-radius: setUnit(16);
  padding: setUnit(20) setUnit(24);
  position: fixed;
  background: #ffffff;
  min-width: setUnit(180);
  max-width: setUnit(288);
  z-index: 9999;

  @media (max-width: $mobile) {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
