@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.optionGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 setUnit(20);
  .fieldTitle {
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-weight: 400;
    letter-spacing: -0.09px;
    margin-bottom: 0;
    color: $text-color;
    flex: 0 0 100%;
    margin-bottom: setUnit(16);
    @media (min-width: $mobile) {
      flex: 0 0 calc(100% - #{setUnit(232)});
      margin-bottom: 0;
    }
  }
  .optionsWrap {
    flex: 0 0 100%;
    @media (min-width: $mobile) {
      flex: 0 0 setUnit(224);
      text-align: right;
    }
  }
  .boxWrap {
    background: #fdfdfd;
    border: 1px solid #e8e9ea;
    border-radius: setUnit(8);
    padding: setUnit(16) setUnit(20);
    flex: 0 0 100%;
    margin: setUnit(16) 0 setUnit(12);
    .stepOneInputCheckbox {
      flex: none;
    }
    .checkboxInputWrap {
      margin-top: setUnit(28);
      flex: 0 0 10%;
      & [class*='ant-checkbox-wrapper'] {
        flex: none !important;
      }
    }
    .separator {
      border-top: 1px solid $light-gray;
      padding-bottom: setUnit(16);
      margin-top: setUnit(16);
      display: block;
    }
    .boxTitle {
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-weight: 400;
      letter-spacing: -0.18px;
      color: $dark-color;
      margin-bottom: setUnit(18);
    }
    .customCheckbox {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
      margin-right: 0;
      & [class*='ant-checkbox'] {
        top: 0;
        & [class*='ant-checkbox-inner'] {
          border: 1.5px solid $text--light-gray-color;
          border-radius: 4px;
          width: setUnit(24);
          height: setUnit(24);
          background-color: #fff;
          &:after {
            transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
            left: 30%;
          }
        }
        & + span {
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-weight: 400;
          letter-spacing: -0.09px;
          color: $dark-grey-color;
          padding-right: 0;
        }
      }
      & [class*='ant-checkbox-checked'] {
        &:after {
          border-color: $primary-color;
        }
        & [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color;
        }
        & + span {
          color: $text-color;
        }
      }
    }
    .checkboxGroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      user-select: none;
      gap: setUnit(16) 0;
      & [class*='ant-checkbox-wrapper'] {
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        margin-right: 0;
        & [class*='ant-checkbox'] {
          top: 0;
          & [class*='ant-checkbox-inner'] {
            border: 1.5px solid $text--light-gray-color;
            border-radius: 4px;
            width: setUnit(24);
            height: setUnit(24);
            background-color: #fff;
            &:after {
              transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
              left: 30%;
            }
          }
          & + span {
            font-size: setUnit(14);
            line-height: setUnit(16);
            font-weight: 400;
            letter-spacing: -0.09px;
            color: $dark-grey-color;
            padding-right: 0;
          }
        }
        & [class*='ant-checkbox-checked'] {
          &:after {
            border-color: $primary-color;
          }
          & [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color;
          }
          & + span {
            color: $text-color;
          }
        }
      }
    }
  }
}
