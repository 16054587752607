@import 'styles/functions';
@import 'styles/variables';

.infoItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: setUnit(12) 0;

  .iconLabel {
    display: flex;
    flex: 1;
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $light-black;
      margin-left: 12px;
    }
  }

  .value {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.18px;
    text-align: right;
    color: $text-color;
  }
}
