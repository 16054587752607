@import 'styles/functions';
@import 'styles/variables';

.documents {
  .documentsBox {
    padding: 0;

    .headerIcon {
      padding: setUnit(24) setUnit(24) 0px;
    }

    .documentsWrapper {
      padding: 0px setUnit(24) setUnit(32);
      margin-top: setUnit(28);

      .inputBlock {
        &:not(:last-child) {
          margin-bottom: setUnit(40);
        }

        .label {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.18px;
          color: $text-color;
          margin-bottom: 16px;
        }

        .container {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        .offerExpiration {
          display: flex;
          flex-flow: column wrap;
          .checkbox {
            margin-left: 0;
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            [class~='ant-checkbox'] {
              [class~='ant-checkbox-inner'] {
                border-radius: 50px;
              }
              & + span {
                .static {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: -0.18px;
                  color: $dark-color;
                }
                .datePickerHolder {
                  .static {
                    margin: 0 8px;
                    display: flex;
                    align-items: center;
                  }
                  .daysInputHolder {
                    border: none;
                    width: 40px;
                    padding: 0px !important;
                    margin: 0 8px 0 28px;
                    input {
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 20px;
                      letter-spacing: -0.18px;
                      color: $text-color;
                      border-bottom: 2px solid $border-light-gray-3;
                      padding-left: 0;
                      &::placeholder {
                        color: $text-gray-color;
                      }
                    }
                    [class~='ant-input-number-handler-wrap'] {
                      display: none;
                    }
                    &[class~='ant-input-number-focused'] {
                      box-shadow: none;
                      input {
                        border-bottom-color: $text-gray-color;
                      }
                    }
                  }
                }
                .inputHolder {
                  border: 0;
                  padding-left: 0;
                  padding-right: 0;
                  &[class~='ant-picker-focused'] {
                    box-shadow: none;
                  }
                  input {
                    height: 30px;
                  }
                }
              }
              &[class~='ant-checkbox-checked'] {
                &:after {
                  border-radius: 50px;
                }
                & + span {
                  .static {
                    color: $text-color;
                  }
                }
              }
            }
          }
          .datePickerCheckbox {
            [class~='ant-checkbox'] {
              top: -10px;
            }
          }

          [class~='ant-input-number'] {
            border: 0;
            width: 40px;
            margin-right: 8px;
            &[class~='ant-input-number-focused'] {
              box-shadow: none;
              input {
                border-bottom-color: $text-gray-color;
              }
            }
          }
          input {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.18px;
            color: $text-color;
            border: 0;
            border-bottom: 2px solid $border-light-gray-3;
            padding-left: 0;
            &::placeholder {
              color: $text-gray-color;
            }
          }
          [class~='ant-input-number-handler-wrap'] {
            display: none;
          }
        }
      }

      .documentItem {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        border: 1px dashed $border-light-gray-2;
        border-radius: 8px;
        padding: setUnit(20);

        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 375px) {
          gap: 10px;
          flex-flow: wrap;
        }

        .iconTextDetail {
          margin-left: setUnit(12);
        }

        .requiredText {
          color: $hover-red;
        }

        .uploadDocuments {
          width: fit-content;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        .iconButton {
          background-color: $background-grey-2 !important;
          width: 100%;
          height: 40px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0 !important;
          color: $text-color !important;
          font-weight: 500 !important;
          gap: 6px;

          &:hover {
            background-color: $background-grey-2 !important;
            border: 0 !important;
          }
          &:focus {
            border: 0 !important;
          }
        }
      }

      .documentItemError {
        border-color: $hover-red;
        border-width: 1.5px;
      }

      .uploadedDocument {
        background-color: $background-grey-2;
        border: unset;
      }

      .icon {
        line-height: 0;
        cursor: pointer;
      }
    }
  }
}
