@import 'styles/functions';
@import 'styles/variables';

.parentOffer {
  background: #ffffff;
  border: 2px solid #f4f5f6;
  border-radius: 16px;
  padding: 20px;
  width: 48%;
  display: inline-block;
  margin: 0px 5px;
  top: -65px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.18px;
  color: #262626;
}

.subHeading {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.18px;
  color: #747475;
  margin-bottom: 10px;

  span {
    font-weight: 400;
    letter-spacing: -0.18px;
    display: block;
    margin: 10px 0px;

    input {
      border: none !important;
      outline: none !important;
    }
  }
}
