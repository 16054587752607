@import 'styles/functions';
@import 'styles/variables';

.preApprovalRequest {
  margin: 1.25rem 0;
  position: relative;

  & [class*='ant-avatar'] {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .info {
    display: inline-block;
    position: absolute;
    top: 0.1rem;

    h4 {
      margin: 0;
      line-height: 1rem;
    }

    P {
      margin: 0;
      font-size: 13px;
    }
  }

  .actions {
    float: right;

    .viewBtn {
      cursor: pointer;
      background: $background-grey-2;
      border-radius: 100px;
      padding: 0.5rem 1rem;
      margin-right: 0.5rem;
      display: inline-block;

      &:hover {
        background: $light-gray;
      }

      span {
        font-size: 13px;
        font-weight: 500;
      }
    }

    .commentIcon {
      cursor: pointer;
      display: inline-block;
      background: $background-grey-2;
      border-radius: 100px;
      padding: 0.25rem 0.5rem;
      position: relative;
      top: 0.2rem;

      &:hover {
        background: $light-gray;
      }

      svg {
        position: relative;
        top: 0.1rem;
      }
    }
  }
}
