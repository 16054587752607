@import 'styles/mixins';
@import 'styles/variables';

.checkbox {
  margin-left: 0;
  width: fit-content;
  color: $dark-color;

  &:hover [class*='ant-checkbox-inner'] {
    border-color: $primary-color !important;
  }

  & [class*='ant-checkbox'] {
    & [class*='ant-checkbox-inner'] {
      &:after {
        transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
        left: 25%;
        width: setUnit(6);
        height: setUnit(12);
      }
    }
  }
  & [class*='ant-checkbox-checked'] {
    &:after {
      border-color: $primary-color;
    }
    & [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
