@import 'styles/functions';
@import 'styles/variables';

.noPartners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0 7.5rem 0;

  .noPartnersText {
    margin-top: 24px;
    color: $text--light-gray-color;
    font-size: 16px;
    text-align: center;
  }
}

.partners {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.directoryMessage {
  display: flex;
  border-radius: 16px;
  background: rgba(232, 233, 234, 0.5);
  justify-content: space-between;
  padding: 16px;

  color: $dark-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.18px;

  .text {
    max-width: 527px;
  }

  & .closeIcon {
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
