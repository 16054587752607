@import 'styles/variables';

.progressLine {
  line-height: 0px !important;
  font-size: 45px !important;

  [class*='ant-progress-bg'],
  [class*='ant-progress-status-success'] {
    height: 6px !important;
    border-radius: 0px 5px 5px 0px;
    background-color: $primary-color !important;
  }

  [class*='ant-progress-inner'] {
    border-radius: 0px;
  }
}
