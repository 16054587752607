@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: setUnit(150);
  width: 100%;

  border-bottom: 2px solid $light-gray;

  .chatHeaderInfo {
    display: flex;
    align-items: center;
    padding: setUnit(8) setUnit(32) 0 setUnit(32);

    .chatHeaderAvatars {
      position: relative;
      .avatars {
        .avatarWrapper {
          margin-left: setUnit(-35);

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .avatar {
        width: setUnit(56);
        height: setUnit(56);

        &.isNotSingle {
          width: setUnit(50);
          height: setUnit(50);
        }
      }

      .overflow {
        width: setUnit(50);
        height: setUnit(50);
      }
    }

    .chatHeaderTitles {
      margin-left: setUnit(16);
    }

    .chatHeaderTitle {
      font-weight: 600;
      font-size: setUnit(20);
      line-height: setUnit(26);
      color: $text-color;
      display: flex;
      flex-direction: column;

      .chatHeaderUserRole {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: setUnit(14);
        color: $dark-color;
      }
      p {
        margin-bottom: 0px;
      }
    }

    .chatHeaderSubTitle {
      font-size: setUnit(14);
      line-height: setUnit(16);
      color: $text-gray-color;
    }

    .offer {
      background-color: $yellow-color;
    }

    .chatHeaderTitleSpan {
      margin-right: setUnit(8);
      outline: none;

      &:focus {
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .chatHeaderThirdPartyCategory {
      margin-right: setUnit(8);
      outline: none;
    }

    .chatHeaderOnlineStatus {
      position: absolute;
      top: 62%;
      left: 71%;
      display: flex;
      z-index: 2;
      padding: 5px;
      background: white;
      border-radius: 50%;

      div {
        width: setUnit(9);
        height: setUnit(9);
        background-color: $green-color;
        border-radius: 50%;
      }
    }

    .chatHeaderEmptyTitleSpan {
      margin-right: setUnit(8);
      outline: none;
      color: #2b59da;

      &:focus {
        color: $text-gray-color;
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .chatType {
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(16);
      color: $text-color;
      padding: setUnit(3) setUnit(6);
      border-radius: setUnit(17);
      background-color: $light-gray;
    }
  }
}
