@import "styles/functions";
@import "styles/variables";

.scrollWrapper {
    height: calc(100% - #{setUnit(148)});
    overflow: auto;
}

.search {
    padding-top: setUnit(14);
}
