@import 'styles/functions';
@import 'styles/variables';

.sendingTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .values {
    display: flex;
    align-items: center;

    .radioOptions {
      display: flex;
      align-items: center;
    }

    .radioOption {
      border-bottom: none;
      padding: 0;
    }

    .labelText {
      margin-right: setUnit(8);
      font-size: setUnit(14);
      font-weight: 400;
    }

    .customMatchScore {
      display: flex;
      align-items: center;

      .input {
        width: setUnit(47);
        height: setUnit(32);
        margin: 0 setUnit(8);

        input {
          padding: setUnit(8);
        }
      }
    }
  }

  .value {
    margin-left: setUnit(24);
  }

  .checkboxWrapper {
    margin-bottom: 0;
  }
}
