@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.filterWrap {
  display: flex;
  align-items: center;
  .filterButton {
    width: 70px;
    height: 44px;
    border: 2px solid $primary-color;
    background-color: $primary-color-light;
    &:hover {
      border: 2px solid $hover-red;
    }
    sup {
      right: 13px;
      top: 3px;
    }
    .optionIcon {
      margin-right: 10px;
      margin-left: 5px;
      display: flex;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .icon {
      display: flex;
      transition: $transition;
      svg {
        width: setUnit(12);
        height: setUnit(6);
        path {
          stroke: $dark-color;
        }
      }
    }
    &[class~='ant-dropdown-open'] {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
  .selectedProperties {
    width: 330px;
    .container {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 10px;
      padding: 20px;
      background-color: $white;
      cursor: auto;
      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        letter-spacing: -0.18px;
        .title {
          color: #262626;
          font-size: 16px;
          margin: 0;
        }
        .clear {
          color: #2b59da;
          text-align: right;
          font-size: 14px;
          margin: 0;
          cursor: pointer;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
          }
          .infoContainer {
            .line1 {
              color: #262626;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: -0.18px;
              margin: 0;
            }
            .line2 {
              color: #515151;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.09px;
              margin: 0;
            }
          }
        }
        .close {
          cursor: pointer;
          position: relative;
          bottom: 5px;
        }
      }
      .arrow {
        position: relative;
        top: 2px;
      }
      .fullWidth {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .button {
        display: flex;
        align-items: center;
        gap: 15px;
        color: $white;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.18px;
      }
    }
  }

  .propertiesActions {
    width: 290px;
    .container {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 10px;
      background-color: $white;
      padding: 0;
      cursor: auto;
      .list {
        padding: 20px;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 14px 16px;
        cursor: pointer;
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:hover {
          background-color: $background-ligh-gray;
        }
        p {
          color: #262626;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.09px;
          margin: 0;
        }
      }
    }
  }
}
