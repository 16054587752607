.toastContainerSuccess {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 16px;
  display: inline-flex;
  padding: 24px 28px 24px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  margin-left: 10px;
  border: 1.5px solid rgba(4, 144, 71, 0.1);
  background: #f6fdf5;
  box-shadow: 0px 8px 26px 0px rgba(93, 93, 93, 0.16);
  z-index: 1000; /* Ensure the toast appears above other elements */

  @media (min-width: 768px) {
    /* For screens larger than 768px */
    width: 600px;
  }

  .toastTitle {
    color: #049047;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 150% */
    letter-spacing: -0.35px;
    margin-bottom: 0em;
  }

  .toastMessage {
    color: #049047;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.35px;
  }

  .toastButton {
    cursor: pointer;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--grayscale-10-background, #f4f5f6);
    background: var(--REBRAND-Secondary-White, #fff);
    box-shadow: 0px 0px 32px 0px rgba(209, 247, 199, 0.65);
  }
}
