@import "styles/mixins";
@import "styles/variables";

$active-border-color: $primary-color;

.wrapper {
    width: 100%;

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    .holder {
        width: 100%;

        .optionHolder {
            display: flex;

            &:last-child {
                .option {
                    margin-bottom: 0;
                }
            }

            .option {
                $border-radius: setUnit(16);

                width: setUnit(98);
                height: setUnit(98);
                margin-right: setUnit(8);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $border-input-middle-gray;
                border-radius: setUnit(8);
                padding: setUnit(15);
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                .optionInner {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .title {
                        font-weight: 400;
                        font-size: setUnit(14);
                        color: $dark-color;
                    }

                    .icon {
                        margin-bottom: setUnit(10);
                    }

                    svg {
                        vertical-align: middle;
                    }
                }

                &:hover {
                    background: #ffffff;
                }

                &.active {
                    background: #FFF4F3;
                    border-color: $active-border-color;

                    svg {
                        vertical-align: middle;

                        path,
                        circle,
                        rect {
                            stroke: $active-border-color;
                        }
                    }
                }
            }
        }
    }
}
