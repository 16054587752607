@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.clarityPopup {
  padding-left: 1rem;
  padding-right: 1rem;

  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
  }
}
.clockIcon {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 24px;
}
.dFlexAndCenter {
  display: flex;
  justify-content: center;
}

.popupContentText {
  color: #262626;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 447px;
}

.popupButton {
  color: #fff;
  background-color: #252d44;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  padding: 0.3125rem 0.9375rem;
  width: 9.6875rem;
  height: 3.5rem;
  border: 0;
  cursor: pointer;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}
