@import 'styles/functions';
@import 'styles/variables';

.incompleteForms {
  .spacing {
    width: 100%;
    [class~='ant-space-item'] {
      width: 100%;
    }
  }

  .incompleteForm {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 2px solid $light-gray;
    border-radius: 8px;
    .formData {
      display: flex;
      flex-direction: column;
      .formName {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $text-color;
      }
      .formStatus {
        display: flex;
        margin-top: 4px;
        align-items: center;
      }
      .otherStatus {
        margin-left: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $primary-color;
      }

      .initiatedStatus {
        @extend .otherStatus;
        color: $text-gray-color;
      }
    }
    .formActions {
      display: flex;
      align-items: center;
      .pill {
        background: $background-grey-2;
        border-radius: 32px;
        padding: 7px 10px;
        height: 32px;
        width: fit-content;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $text-color;
      }
    }
  }
  .notAllowed {
    opacity: 50%;
    cursor: not-allowed;
  }
}
