@import 'styles/variables';

.deleteIcon {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  text-align: right;
}

.addLocationRow {
  display: flex;
  align-items: center;
}
.addLocation {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 10px;
}
.location {
  background-color: $white !important;
  border-radius: 0px !important;
  padding: unset !important;
  height: unset !important;
}

.activeInputClassName > input {
  background-color: $white !important;
  border-radius: 0px !important;
  height: unset !important;
}

.locationRow {
  margin-bottom: 15px;
}

.deleteCol {
  display: flex !important;
  justify-content: flex-end !important;
}

.miles {
  [class*='ant-input-number-group-addon'],
  [class*='ant-input-number-lg'],
  [class*='ant-select-selector'] {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid $border-light-gray-2 !important;
  }

  [class*='ant-input-number-group-addon'] {
    padding: 0 3px !important;
    background-color: $white !important;
  }
}
