@import 'styles/variables';
@import 'styles/functions';

.tourCards {
  &.disabled {
    position: relative;
    pointer-events: none;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .tourCard {
    box-shadow: 0px 2px 16px 0px #30303014;
    background-color: $white;
    border-radius: setUnit(16) !important;
    padding-top: setUnit(8);
    &:not(:last-child) {
      margin-bottom: setUnit(24);
    }
    div[class~='ant-collapse-header'] {
      align-items: center;
      flex-wrap: wrap;
      padding: setUnit(13) setUnit(24);
      height: setUnit(64);
      div[class~='ant-collapse-expand-icon'] {
        order: 2;
        div[class~='ant-collapse-arrow'] {
          position: relative;
          top: unset;
          right: unset;
          left: unset;
          transform: translateY(0);
          display: flex;
        }
        .selectAll {
          display: flex;
          position: relative;
          top: unset;
          transform: translateY(0);
          right: unset;
        }
      }
      span[class~='ant-collapse-header-text'] {
        .headerWrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-right: setUnit(16);
          .headerLeft {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .avatar {
              margin-right: setUnit(12);
            }
            .title {
              font-size: setUnit(16);
              font-weight: 600;
              line-height: setUnit(24);
              letter-spacing: setUnit(-0.33);
              margin-bottom: 0;
            }
          }
          .headerRight {
            .actions {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px;
              background-color: $white;
              transition: $transition;
              width: setUnit(32);
              height: setUnit(32);
              &[class~='ant-dropdown-open'] {
                background-color: $light-gray;
              }
              .actionsDropdown {
                ul {
                  width: setUnit(236);
                  box-shadow: 0px 2px 12px 0px #00000026;
                  border-radius: setUnit(8);
                  margin: 0;
                  .actionItem {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    cursor: pointer;
                    padding: setUnit(12) setUnit(16);
                    background-color: $white;
                    transition: $transition;
                    &:hover {
                      background-color: $background-grey-2;
                    }
                    svg {
                      margin-right: setUnit(8);
                    }
                    span {
                      font-size: setUnit(14);
                      font-weight: 400;
                      line-height: setUnit(16);
                      letter-spacing: setUnit(-0.09);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    div[class~='ant-collapse-content'] {
      div[class~='ant-collapse-content-box'] {
        padding: 0 0 setUnit(20);
      }
    }
    .requestActions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: setUnit(16);
      .button {
        padding: setUnit(7) setUnit(15);
        border-radius: setUnit(36);
        height: auto;
        background-color: transparent;
        &:not(:last-child) {
          margin-right: setUnit(8);
        }
        .raIcon {
          svg {
            display: flex;
          }
        }
        &.buttonAccept {
          border-color: $hover-green;
        }
        &.buttonReject {
          border-color: $error-color;
        }
      }
    }
  }
}
