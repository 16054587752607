@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  padding: 32px;
  width: 80vw;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  max-height: calc(100vh - 320px);
  height: fit-content;
  overflow-y: auto;
  margin-bottom: 20px;
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 12px;
    padding-right: 8px;
    width: 90vw;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 32px 32px 0;
  .button {
    width: 163px;
  }
  .titleClassName {
    display: flex;
    justify-content: center;
    gap: 10px;
    min-width: 133px;
    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.09px;
      position: relative;
      top: 3px;
    }
  }
}
