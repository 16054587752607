@import 'styles/functions';
@import 'styles/variables';

.saveBtn {
  height: setUnit(40) !important;
  width: setUnit(180) !important;
  transition: all 0.1s;
  margin-top: setUnit(24);
  display: block;
  margin: setUnit(24) auto 0;
  @media (min-width: $mobile) {
    margin: setUnit(24) 0 0;
  }
  span {
    font-size: setUnit(16);
    line-height: setUnit(20);
    font-weight: 600;
    letter-spacing: -0.18px;
  }
}

.buttonDisabled {
  background-color: rgba(37, 45, 68, 0.3) !important;
}

.editLabel {
  font-size: setUnit(14);
  font-weight: 400;
  line-height: setUnit(16);
  color: $dark-grey-color;
  letter-spacing: -0.09px;
  margin-bottom: setUnit(8);
  @media (min-width: $mobile) {
    flex: 1;
    margin-bottom: 0;
  }
}

.inputBlock {
  display: flex;
  flex-wrap: wrap;
  min-height: setUnit(44);
  align-items: center;
  margin-bottom: setUnit(16);
  .rightBlock {
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    flex: 1 0 100%;
    @media (min-width: $mobile) {
      flex: 3;
    }
    .input {
      margin: 0 0 0 0 !important;
      max-width: setUnit(350);
      .inputField {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        border-bottom: 2px solid $background-ligh-gray-4;
        padding: setUnit(12) setUnit(50) setUnit(12) 0;
        height: auto;
      }
    }
  }
}
