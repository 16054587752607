@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.titleWrapper {
  border-bottom: 2px solid $border-light-gray;
  padding-bottom: setUnit(28);
  margin-bottom: setUnit(32);

  .title {
    font-size: setUnit(24);
    line-height: setUnit(29);
    font-weight: 600;
    letter-spacing: -0.47px;
    color: $text-color;
    margin-bottom: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .imageAvatar {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.avatarContainer {
  width: 100%;
  height: 300px;
  border-radius: 16px;
  border: 2px dashed $light-gray;
  background: #fbfbfc;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    margin: 0;
  }
  label {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .upload {
    border-radius: 8px;
    border: 1px solid $light-grey-color !important;
    background: $white;
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
    max-width: fit-content;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: $white;
    padding: 12px;
    cursor: pointer;
  }
  .avatarWrapper {
    border-radius: 96px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 96px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    background: $white !important;
    .avatarClassName {
      background-color: transparent !important;
    }
  }
}

.infoContainer {
  display: inline-flex;
  padding: 12px 39px 12px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: $background-gray;
  color: $dark-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.09px;
  margin-top: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  .reset {
    border-radius: 8px;
    background: $white;
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px; /* 130% */
    letter-spacing: -0.18px;
    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
      flex-direction: row-reverse;
    }
  }
  .publish {
    font-size: 16px !important;
    font-weight: 600 !important;
    min-width: 87px;
  }
}
