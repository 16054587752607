@import 'styles/variables';
@import 'styles/functions';

.inputContainer {
  display: flex;
  flex-direction: column;
}

.listBoxInput {
  [class~='ant-select-selector'] {
    padding: setUnit(12) 0 setUnit(16) !important;
    outline: none;
    margin: 0 !important;
    width: 100% !important;
    vertical-align: middle;
    color: $text-color;
    font-size: setUnit(18) !important;
    font-weight: 400;
    line-height: setUnit(24) !important;
    letter-spacing: -0.09px;
    height: setUnit(52) !important;
    border: 0 !important;
    border-bottom: 2px solid $border-input-middle-gray !important;
    transition: $transition !important;
    overflow: hidden;
    border-radius: 0 !important;
    &::placeholder {
      color: $text--light-gray-color !important;
      font-weight: 400;
      font-size: setUnit(16) !important;
    }
    [class~='ant-select-selection-item'] {
      line-height: 22px !important;
    }
  }
  &[class~='ant-select-focused'] {
    [class~='ant-select-selector'] {
      box-shadow: none !important;
      border-bottom-color: $text-gray-color !important;
    }
  }
  [class~='ant-select-clear'] {
    right: 30px;
  }
}

.addListOptions {
  padding: 0px 8px 4px;
  width: 100%;
  display: inline-block;
}

.addOptionInput {
  width: 80%;
}

.addOptionBtn {
  margin: 0px 5px 5px;
  line-height: 32px;
}

.clearBtn {
  padding: 0px !important;
  align-self: flex-end;
}

.divider {
  margin: 8px 0px !important;
}

.link {
  white-space: nowrap;
}

.closeIcon {
  display: flex;
  align-items: center;
}

.optionContainer {
  [class*='ant-select-item-option-content'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
