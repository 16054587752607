@import 'styles/functions';
@import 'styles/variables';

.InfoTooltipInfo {
  display: inline-block;
  cursor: pointer;
  margin-left: setUnit(5);
}

.InfoTooltip {
  [class*='ant-tooltip-inner'] {
    width: auto;
    padding: setUnit(20);
    border-radius: 1rem;
    background-color: $text-color;
  }
}
