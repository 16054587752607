@import 'styles/functions';
@import 'styles/variables';

.details {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $light-gray;

  .appointmentInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 0;
    border-top: 1px solid $light-gray;
    margin-top: 40px;

    .dateTime,
    .requestor {
      display: inline-block;

      p,
      span {
        color: $text-color;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0.25rem;
      }
    }

    .requestor {
      p,
      span {
        font-weight: 400;
      }

      span {
        color: $link-blue;
      }

      .phone {
        text-align: end;
        input {
          font-size: 16px;
        }
      }
    }
  }

  .pending {
    .approvers {
      background: $primary-color-light;
      padding: 0.75rem 1.5rem;
      text-align: center;
      margin-top: 1.5rem;

      p,
      span {
        color: $primary-color;
        line-height: 21px;
        margin: 0;
      }

      span {
        font-weight: 600;
      }
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;
      gap: 0 16px;
      .actionButton {
        width: calc(50% - 8px);
        height: 52px;
        border-width: 2px;
        border-style: solid;
        border-radius: 8px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          display: inline-flex;
          margin-right: 8px;
        }
        span {
          font-weight: 600;
        }
        &.approve {
          border-color: $hover-green;
          span {
            color: $hover-green;
          }
          &:hover {
            background-color: $hover-green;
            span {
              color: $white;
            }
            .icon {
              svg {
                path {
                  stroke: $white;
                }
              }
            }
          }
        }

        &.decline {
          border-color: $hover-red;
          span {
            color: $hover-red;
          }
          &:hover {
            background-color: $hover-red;
            span {
              color: $white;
            }
            .icon {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .details .appointmentInfo {
    display: block;
    margin-top: 0.5rem;

    .dateTime {
      width: 100%;
    }

    .requestor {
      display: block;
      margin-top: 1rem;
    }
  }
}
