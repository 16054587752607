@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: setUnit(24);
  line-height: setUnit(29);
  font-weight: 600;
  letter-spacing: -0.47px;
  color: $text-color;
  margin-bottom: 0;
}
