@import 'styles/functions';
@import 'styles/variables';

.header {
  padding: 1.375rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    align-items: center;

    svg {
      vertical-align: middle;

      path {
        stroke: $primary-color;
      }
    }
  }
}
