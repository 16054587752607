@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.notifications {
  margin-right: setUnit(24);

  .iconText {
    margin-bottom: setUnit(24);
    padding-top: setUnit(24);
    border-top: 1px solid $light-gray;
  }

  .content {
    border: 1px solid $light-gray;
    border-radius: 8px;

    padding: setUnit(24);
    margin-bottom: 12px;
    background-color: $background-ligh-gray-5;

    .loadingSpinner {
      font-size: 0.5em;
      margin: 50px auto;
    }

    .checkoxes {
      max-height: 150px;
      overflow-y: auto;
    }
    .checkboxGroup {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      user-select: none;
      gap: setUnit(12) 0;
      & [class*='ant-checkbox-wrapper'] {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        margin-right: 0;
        & [class*='ant-checkbox'] {
          top: 0px;
          & [class*='ant-checkbox-inner'] {
            border: 1.5px solid $text--light-gray-color;
            border-radius: 4px;
            width: setUnit(24);
            height: setUnit(24);
            background-color: #fff;
            &:after {
              transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
              left: 25%;
            }
          }
          & + span {
            font-size: setUnit(14);
            line-height: setUnit(16);
            font-weight: 400;
            letter-spacing: -0.09px;
            color: $dark-grey-color;
            padding-right: 0;
          }
        }
        & [class*='ant-checkbox-checked'] {
          &:after {
            border-color: $primary-color;
          }
          & [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color;
          }
          & + span {
            color: $text-color;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
