@import "styles/functions";
@import "styles/variables";

.react-tooltip-lite {
    width: fit-content !important;
    transition: all .3s;
}

.react-tooltip-lite-right-arrow {
    border-right: setUnit(10) solid #ffffff !important;
    border-top: setUnit(8) solid transparent !important;
    border-bottom: setUnit(8) solid transparent !important;
    transform: translateX(#{setUnit(11)});
    transition: all .3s;
}

.react-tooltip-lite-left-arrow {
    border-left: setUnit(10) solid #ffffff !important;
    border-top: setUnit(8) solid transparent !important;
    border-bottom: setUnit(8) solid transparent !important;
    transform: translateX(#{setUnit(-11)});
    transition: all .3s;
}

.react-tooltip-lite-up-arrow {
    border-top: setUnit(10) solid #ffffff !important;
    border-left: setUnit(8) solid transparent !important;
    border-right: setUnit(8) solid transparent !important;
    transform: translateY(#{setUnit(-11)});
    transition: all .3s;
}

.react-tooltip-lite-down-arrow {
    border-bottom: setUnit(10) solid #ffffff !important;
    border-left: setUnit(8) solid transparent !important;
    border-right: setUnit(8) solid transparent !important;
    transform: translateY(#{setUnit(11)});
    transition: all .3s;
}
