@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - #{setUnit(161)});
  @media (min-width: $tablet) {
    height: calc(100vh - #{setUnit(192)});
  }
}
