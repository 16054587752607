@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.addContactModal {
  padding-left: 1rem;
  padding-right: 1rem;

  [class='ant-modal-content'] {
    padding: 2rem 2.5rem;
    margin: 4rem 0;
  }

  .title {
    font-size: setUnit(24);
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 0.75rem;
    margin-bottom: 3rem;
  }

  .content {
    position: relative;
    max-width: 675px;

    .phoneLabel {
      font-weight: 600;
      color: $text-color;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .phoneField {
      @include lightRounded;
    }

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-20);
      margin-top: 1.675rem;

      .roleField {
        width: 100%;
        padding: 0 setUnit(20);
        position: relative;
        display: inline-block;
        height: setUnit(110);
      }
    }
    .checkbox {
      margin-top: 5px;
      [class*='ant-checkbox-checked'] {
        :after {
          border-color: white !important;
        }
      }
    }

    .fieldWrapper {
      margin-bottom: 1.675rem;
    }
  }

  .addFooterButton {
    justify-content: center;
  }

  .footerButton {
    margin-top: 2rem;
    display: flex;

    .footerBtnDiv {
      text-align: center;

      .btn {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        padding: 0.3125rem 0.9375rem;
        width: 8.6875rem;
        height: 3.5rem;
        border: 0;
        cursor: pointer;
      }

      .addBtn {
        color: $white;
        background-color: $background-blue;
        &:hover {
          background-color: $hover-blue;
        }
      }

      .addBtnDisabled {
        cursor: not-allowed;
        opacity: 0.8;

        &:hover {
          background-color: $background-blue;
        }
      }
    }
  }
}
