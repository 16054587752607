@import "styles/functions";
@import "styles/variables";

.option {
    width: 100%;
    height: setUnit(64);
    border: 1px solid $border-light-gray;
    border-radius: setUnit(8);
    padding: 0 setUnit(20);
    cursor: pointer;
    margin-bottom: setUnit(20);
    display: flex;
    align-items: center;
}
