@import 'styles/mixins';

.buttonWrapper {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 10px 12px;
  width: fit-content;
  cursor: pointer;
  background-color: $white;
  border: 2px solid;
  border-color: $light-gray;
  transition: 0.3s ease all;
  &:hover {
    border-color: $text--light-gray-color !important;
  }
  span {
    color: $dark-color;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.09px;
    white-space: nowrap;
  }
  .icon {
    display: flex;
    margin-right: setUnit(6);

    > div {
      line-height: 0;
      svg {
        line-height: 0;
      }
    }
  }
}

.buttonAppliedWrapper {
  border: 2px solid $primary-color !important;
  background-color: $primary-color-light !important;
  border-radius: setUnit(100) !important;
  &:hover {
    border-color: $primary-color !important;
  }
}
