@import 'styles/variables';
@import 'styles/functions';

.locationCol {
  width: 100%;
  [class*='geoTagIcon'] {
    svg {
      position: relative;
      right: 12px;
    }
  }
}

.inputFieldLocations {
  input {
    padding: 12px 42px !important;
  }
}

.locationPlaceholder {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.addAddressContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;

  .addAddress {
    margin-left: 10px;
  }
}

.addressDisabled {
  opacity: 50%;
  cursor: not-allowed;
}

.removeIconPosition {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.locationAndRemoveIcon {
  flex-flow: row;
  align-items: center;
  position: relative;
}

.primaryContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .primaryText {
    margin-left: 8px;
  }

  .primaryCheckbox {
    [class*='ant-checkbox-checked'] {
      [class*='ant-checkbox-inner'] {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
        border-radius: 100%;
      }
    }
    [class*='ant-checkbox-inner'] {
      border-radius: 100% !important;
      width: 20px;
      height: 20px;
    }
    [class*='ant-checkbox-inner']::after {
      width: 6px;
      height: 12px;
    }
  }
}
