@import 'styles/variables';
@import 'styles/functions';

.label {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: $dark-grey-color;
}

.row {
  align-items: center;
  margin-bottom: 24px;
}

.formItem {
  margin-bottom: 0 !important;
}

.taskTemplatesInnerHolder {
  max-height: 600px !important;
  overflow: scroll;
}

.optionalText {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: $text--light-gray-color;
}

.footerContainer {
  width: 100%;

  .footerButton {
    width: 142px;
    height: 52px;
  }

  .separator {
    width: 100%;
    display: block;
    height: 1px;
    background-color: $light-gray;
    margin: 23px auto;
  }
}

.timelinePaneContainer {
  .milestonePoint {
    .timelineName,
    .deadline {
      [class*='error'] {
        &::before {
          content: none !important;
        }

        margin-top: 10px;
        padding-top: 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .offsetIcon {
      margin-bottom: 12px !important;
    }

    .timelineNameInput,
    .numberInput,
    .datePickerInputClass {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: $text-color;
      padding-bottom: 12px !important;
    }

    .datePickerErrorClass {
      margin-top: 10px;
      padding-top: 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.detailPaneContainer {
  [class*='ant-form-item-explain-error'] {
    padding-left: 10px !important;
    margin: 5px 0 10px 0 !important;
  }

  .placeholderClassName {
    border-bottom-color: $light-gray !important;
  }
}

.paneContainer {
  margin-bottom: 30px;
}

.disabled {
  opacity: 0.5;
}

.emptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  margin-top: 88px;

  .emptyListDescription {
    color: $text--light-gray-color;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    margin-top: 24px;
  }
}
