@import 'styles/variables';

.checkbox {
  margin-right: 10px !important;

  [class*='ant-checkbox-checked'] {
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }

  [class*='ant-checkbox-inner'] {
    border-radius: 4px;
    width: 24px !important;
    height: 24px !important;
  }

  [class*='ant-checkbox-inner']::after {
    width: 8px !important;
    height: 13px !important;
  }
}

.sequenceNumber {
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px !important;
  min-width: 24px !important;
  background-color: $primary-color !important;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 700;
  color: $white;
}
