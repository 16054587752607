@import 'styles/functions';
@import 'styles/variables';

.sorts {
  list-style: none;
  padding-bottom: setUnit(16);

  .item {
    padding: 0 setUnit(24);
    justify-content: space-between;
    font-weight: 400;
    cursor: pointer;
    height: setUnit(40);
    display: flex;
    align-items: center;

    .label {
      &.active {
        font-weight: 500;
      }
    }

    &:hover {
      background: $background-ligh-gray;
    }

    svg {
      vertical-align: middle;
    }
  }
}
