@import 'styles/functions';
@import 'styles/variables';

.applicantsWrapper {
  .applicants {
    padding: setUnit(4) setUnit(12) setUnit(4) setUnit(8);
    border: 1px solid $border-light-gray-2;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    &:hover {
      border-color: $text--light-gray-color;
    }

    .loader {
      margin: 0.6875rem;
      font-size: 2px;
    }

    .pendingWrapper {
      display: flex;
      align-items: center;
    }
    .icon {
      display: inline-flex;
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }
    }

    .count {
      font-size: setUnit(14);
      font-weight: 600;
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.09);
    }
  }
}
