@import "styles/functions";
@import "styles/variables";

.back {
    display: flex;
    align-items: center;
    font-size: setUnit(14);
    font-weight: 500;
    cursor: pointer;
    color: #FFFFFF;
    padding-left: setUnit(25);

    .icon {
        margin-right: setUnit(7);
        margin-left: setUnit(-5);
    }
}
