@import 'styles/functions';
@import 'styles/variables';

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .number {
    width: setUnit(48);
    height: setUnit(48);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: setUnit(20);
    line-height: setUnit(26);
    font-weight: 700;
    letter-spacing: -0.33px;
    margin-bottom: setUnit(12);
  }

  .title {
    font-weight: 500;
    font-size: setUnit(14);
    line-height: setUnit(20);
    letter-spacing: -0.18px;
  }

  &.new {
    .number {
      color: $green-color;
      background: rgba($green-color, 0.1);
    }
  }

  &.overdue {
    .number {
      color: $error-color;
      background: rgba($error-color, 0.1);
    }
  }

  &.upcoming {
    .number {
      background: $background-ligh-gray;
    }
  }
}
