@import 'styles/functions';
@import 'styles/variables';

.avatarWrapper {
  width: fit-content;
  height: fit-content;

  color: $white;
  font-weight: 600;
  font-size: setUnit(20);
  line-height: setUnit(26);
}

.avatar {
  background: $secondary-color;
  width: setUnit(80);
  height: setUnit(80);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
