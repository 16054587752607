@import 'styles/functions';
@import 'styles/variables';

.noMlsAccessWrapper {
  position: inherit;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .noMlsText {
    color: $text-gray-color;
    max-width: 30rem;
  }

  .selectButton {
    margin-top: setUnit(24);
    height: auto;
    border-radius: setUnit(64) !important;
    padding: setUnit(16) setUnit(48);
  }
}
