@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.main {
  .phone {
    margin: 0 !important;
    input {
      border-radius: 8px !important;
      padding: 8px 13px !important;
      color: $text-color !important;
      font-size: 16px !important;
      border-top: 4px solid white;
    }
  }
  .actionButtons {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    gap: 8px;
    span {
      color: rgba(255, 255, 255, 0.8);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
    .update {
      width: 90px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.15);
    }
    .cancel {
      width: 90px;
      height: 40px;
      padding: 10px 0;
      gap: 6px;
      border-radius: 8px;
      background: #161b29;
      border: 1px solid #e8e9ea80;
    }
  }
}
