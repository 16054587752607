@import 'styles/functions';
@import 'styles/variables';

.userInfo {
  .avatar {
    [class*='avatar'] {
      height: 52px !important;
      width: 52px !important;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text {
    @extend .infoWrapper;
  }

  .description {
    color: $dark-color;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin: 0.5rem 0 0 0;
  }

  .name {
    color: $secondary-color;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin: 1rem 0 0 0;
  }
}
