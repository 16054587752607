.titleInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Page Title */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.4px;

    margin-bottom: 8px;
  }

  .description {
    text-align: center;
    color: var(--Grayscale-40, #aaabab);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
  }
}

.name {
  color: var(--Grayscale-100, #262626);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.33px;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 12px 16px;
    border-radius: 100px;
    background: var(--Primary-Navy, #252d44);

    color: var(--White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;

    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
