@import 'styles/functions';
@import 'styles/variables';

.headerArea {
  width: 100%;
  padding: 38px 0px 24px;
  margin: 0 auto;
  background-color: $white;

  .headWrapper {
    display: block;
    text-align: center;
    width: 100%;

    .headTitle {
      font-weight: 700;
      font-size: setUnit(32);
      color: $text-color;

      letter-spacing: -0.4px;
    }

    .headSubtitle {
      font-size: setUnit(20);
      color: $text-color;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
      margin-top: 20px;
    }

    .headDescription {
      font-size: setUnit(18);
      color: $text-color;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
  }

  .infoIcon {
    display: none;
  }
}

@media (min-width: $mobile) and (max-width: $tablet-horizontal) {
  .headerArea {
    padding: 24px 0px 14px;

    .headWrapper {
      .headTitle {
        font-size: setUnit(28);
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .headerArea {
    position: relative;

    .infoIcon {
      display: block;
      position: absolute;
      bottom: 30px;
      right: 20px;
    }
  }
}

@media (max-width: $mobile-small) {
  .headerArea {
    padding: 24px 0px 32px;
    position: relative;
    .headWrapper {
      .headTitle {
        font-size: setUnit(28);
        line-height: 32px;
        font-weight: 700;
      }

      .headSubtitle {
        font-size: setUnit(16);
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .headDescription {
        font-size: setUnit(16);
        line-height: 20px;
      }
    }
  }
}
