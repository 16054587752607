@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.headerTop {
  padding: setUnit(24);
  @media (min-width: $tablet) {
    padding: setUnit(34);
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: $text-color;
    margin: 0;
    @media (min-width: $tablet) {
      text-align: center;
    }
  }
}
