@import 'styles/variables';
@import 'styles/functions';

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: $transition;
  z-index: 100;
  .opacityLayer {
    background-color: rgba($white, 0.84);
    backdrop-filter: blur(15px);
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9998;
  }
  &.isOpen {
    pointer-events: auto;
    opacity: 1;
    .opacityLayer {
      opacity: 1;
    }
  }
}

.content {
  min-width: 100vw;
  height: 100vh;
  padding: setUnit(50);
  border-radius: setUnit(20);
  position: relative;
  z-index: 9999;
  transition: all 0.3s;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: -20px;
  padding: setUnit(40);
  @media (min-width: $desktop) {
    align-items: flex-start;
  }
  @media (max-width: 700px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .headerContainer {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    background: transparent;
    .buttonsContainer {
      display: inline-flex;
      gap: setUnit(10);
    }
  }
}
