@import 'styles/functions';
@import 'styles/variables';

.agentCoordinatorPromptModal {
  [class*='ant-modal-close-x'] {
    top: 10px !important;
  }
}

.agentCoordinatorPromptContainer {
  .header {
    display: flex;
    justify-content: center;
    .avatar {
      width: 4rem;
      height: 4rem;
      .avatarPlaceholder {
        font-size: 20px;
      }
    }
  }

  .content {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .questionTitle {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    .checkbox {
      .label {
        font-size: 18px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 2.5rem;

    button {
      height: 60px;
      width: 100%;
      border-radius: 8px;
      font-size: 18px !important;
      &.btnDisabled {
        opacity: 0.8;
      }
    }
  }
}
