@import 'styles/functions';
@import 'styles/variables';

$actions-container-height: 76px;

.actionsContainer {
  z-index: 10;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 32px;
  left: 32px;
  width: 100%;
  padding: 0.875rem 1.5rem;

  .total {
    font-weight: bold;
  }

  .actions {
    display: flex;
    align-items: center;

    .noBorder {
      border: none;
    }

    .multiple {
      border: none;
    }
    .active {
      border: 1px solid;
      border-color: $primary-color;
      background-color: $primary-color-light;
      border-radius: setUnit(12);
    }
  }
}

.clientProperties {
  position: relative;
  padding: setUnit(24);
  padding-top: 0;
  height: calc(100% - #{$actions-container-height});
  scroll-margin: 80px;

  .pendingWrapper {
    height: calc(100vh - #{setUnit(287)});
    @media (min-width: $tablet) {
      height: 100%;
    }
  }
  .pagination {
    padding: setUnit(16) 0;
  }
}
