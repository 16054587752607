@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.favoritesModal {
  .header {
    text-align: center;
    margin-bottom: setUnit(32);
    padding: 0 setUnit(40);

    &.client {
      margin-bottom: setUnit(20);
    }

    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.8px;
      text-align: center;
      color: $secondary-color;
    }

    .close {
      cursor: pointer;
      position: absolute;
      top: 36px;
      right: 36px;

      svg {
        vertical-align: middle;
        width: 32px;
        height: 32px;

        path {
          fill: $dark-color;
        }
      }
    }

    .back {
      margin-left: 0;
    }
  }

  .opacityLayer {
    background-color: rgba(#fff, 0.8);
  }

  .content {
    @include box-shadow;

    border-radius: setUnit(12);
    padding: 2rem 1.25rem 1.25rem;
    width: 100%;
    max-width: setUnit(675);
  }
}
