@import 'styles/functions';
@import 'styles/variables';

.headerTitle {
  display: flex;
  justify-content: center;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.newKitModal {
  .content {
    height: 500px;
    overflow-y: auto;
    padding: setUnit(24);

    .name,
    .category,
    .frequency,
    .propertyTypes,
    .inAppReminder,
    .eligibleLocations,
    .restrictedLocations {
      margin-bottom: setUnit(16);

      .sectionLabel {
        margin-right: setUnit(16);
        font-weight: 600;
        font-size: setUnit(16);
        line-height: setUnit(20);
        font-family: $font-family;
        letter-spacing: setUnit(-0.18);
        color: $text-color;
      }

      .error {
        color: $hover-red;
      }

      .selector {
        width: setUnit(288);
      }
    }

    .createButton {
      display: flex;
      justify-content: center;
      align-items: center;

      .submitButton {
        margin-top: 30px;
        height: 56px;
        width: 277px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
  }
}
