@import 'styles/functions';
@import 'styles/variables';

.termsText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: $dark-color;
}

.termsLink {
  color: $link-blue;
  text-decoration: none;
}
