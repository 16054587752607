@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  overflow: auto;
  height: calc(100% - #{setUnit(148)});
}

.search {
  padding-top: setUnit(14);
}

.tabs {
  min-height: 100%;
}

.messagesItemTabs {
  margin: 0 setUnit(32);
}

.messagesItemTab {
  margin-right: setUnit(32) !important;
  padding: setUnit(14) 0;
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(21);
  color: $border-middle-gray;
  transition: 0.3 ease all;
  &:hover {
    color: $text-color;
    background-color: transparent !important;
    border-bottom-color: $primary-color;
  }
}

.messagesActiveItemTab {
  color: $text-color !important;
  border-bottom-color: $primary-color !important;
}
