@import 'styles/functions';
@import 'styles/variables';

.skeletonRowContainer {
  margin: 24px;
  .skeletonRow {
    margin-bottom: 24px;
    .card {
      border: 1px solid $light-gray;
      border-radius: 16px;
      height: 396px;

      .image {
        width: 100%;
        height: 180px;
        border-radius: 16px;

        > div {
          width: 100% !important;
          height: 100% !important;
          border-radius: 16px;

          > span {
            width: 100% !important;
            height: 100% !important;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
        }
      }
      .infoContainer {
        display: flex;
        justify-content: space-between;
        padding: setUnit(12);
        .data1 {
          display: block;
          margin-bottom: 8px;
          height: 20px;
          width: 120px;
        }
        .data2 {
          display: block;
          margin-bottom: 8px;
          width: 140px;
          height: 16px;
        }
        .data3 {
          display: block;
          margin-bottom: 8px;
          width: 160px;
          height: 14px;
        }
        .data4 {
          display: block;
          margin-bottom: 8px;
          width: 100px;
          height: 14px;
        }
        .data5 {
          width: 95px;
        }
      }
    }
  }
}
