.pageWrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  padding-top: 24px;
  gap: 24px;

  .leftWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    max-width: 600px;
    width: 100%;
  }

  .rightWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    max-width: 356px;
    width: 100%;
  }
}
