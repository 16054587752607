@import 'styles/functions';
@import 'styles/variables';

.container {
  background-color: #04a451;
  padding: 16px 24px;
  margin-bottom: 10px;
  border-radius: 10px 10px;
}

.price {
  color: $white;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  display: inline;
}
.price p {
  margin-bottom: 0px;
}

.oldPrice {
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 600;
  color: white;
  display: inline;
  opacity: 0.8;
}
.arrowRightIcon {
  margin: 0 5px;
  color: white;
  width: 15px;
  height: 26px;
  line-height: 24px;
  display: inline;
}
