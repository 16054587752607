@import 'styles/variables';

.sourceContainer {
  display: flex;
  height: 92px;
  padding: 20.9px 217.599px 20.5px 25.488px;
  align-items: center;
  align-items: flex-start;
  &.selected {
    border-radius: 13.2px;
  }
  &:hover {
    border-radius: 13.2px;
    background: $background-grey-2;

    .title {
      color: $dark-color;
    }
    .total {
      opacity: 0.9;
    }
  }
  .colorBox {
    width: 12px;
    height: 12px;
    margin-right: 12px;
    margin-top: 4px;
  }

  .sourceInfo {
    display: flex;
    flex-direction: column;

    .title {
      color: #909090;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 17.6px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 125% */
      letter-spacing: -0.198px;
      margin-bottom: 6.6px;

      &.selected {
        color: $text-color;
      }
    }

    .infoRow {
      display: flex;
      color: $light-gray-2;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-style: normal;
      line-height: 22px; /* 125% */
      letter-spacing: -0.198px;

      .total {
        font-size: 22px;
        font-weight: 700;
        margin-right: 2px;
      }

      .percentage {
        font-size: 17.6px;
        font-weight: 600;
      }
    }
  }
}
