@import 'styles/functions';
@import 'styles/variables';

.pageContainer {
  height: calc(100vh - #{setUnit(213)});
  @media (min-width: $tablet) {
    height: calc(100vh - #{setUnit(192)});
  }
}

#feedProperties {
  &.show-both {
    width: 100%;
    height: 100%;
    padding: 0 setUnit(16);
    @media (min-width: $mobile) {
      width: 50%;
    }
    @media (min-width: $tablet) {
      width: 600px;
      padding: 0;
    }
  }
  &.show {
    display: block;
    width: 100%;
    padding: 0 setUnit(16);
    @media (min-width: $tablet) {
      padding: 0;
    }
  }
  &.hide {
    display: none;
  }
}

.feedMap {
  height: calc(100vh - 192px) !important;
}

#feedMap {
  position: relative;
  &.show-both {
    @media (min-width: $mobile) {
      width: 50%;
    }
    @media (min-width: $tablet) {
      width: calc(100% - 600px);
    }
    width: 100%;
  }
  &.show {
    display: block;
    width: 100%;
  }
  &.hide {
    display: none;
  }
}

.mapSwitchers {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}
