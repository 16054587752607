@import 'styles/variables';

.baseInput {
  border-radius: 8px !important;
}

.addOnBeforeHOC {
  display: block;
}

.resizableInput {
  width: fit-content !important;
  border-radius: 100px !important;

  [class*='ant-input-group-addon'] {
    background-color: $white !important;
  }
  svg {
    display: block;
  }
}

.focusInput {
  width: 100% !important;
  input {
    border-left: none !important;
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  [class*='ant-input-group-addon'] {
    background-color: $white !important;
    border-radius: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  span > span:nth-child(3) {
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.allowClear {
  input {
    border-right: none !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  span > span:nth-child(3) {
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.hideInput {
  [class*='ant-input-wrapper'] > input {
    display: none;
  }
  .addOnBeforeHOC {
    width: 24px;
    height: 24px;
  }
  [class*='ant-input-wrapper'] {
    width: 32px;
    height: 32px;
    [class*='ant-input-group-addon'] {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      svg {
        width: 24px;
        height: 24px;
      }
      &:first-child {
        border-right-width: 2px;
        border-right-style: solid;
      }
    }
  }
}

.hideOutline {
  &:focus {
    box-shadow: none !important;
  }

  input {
    outline: none !important;
  }
}

.light {
  padding: 12px 0 16px !important;
  outline: none !important;
  margin: 0 !important;
  width: 100% !important;
  vertical-align: middle !important;
  color: $text-color !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.09px !important;
  height: 52px !important;
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid $border-input-middle-gray !important;
  transition: $transition !important;
  &:focus,
  &:hover {
    border-bottom-color: $text-gray-color !important;
    box-shadow: none !important;
  }
  &::placeholder {
    color: $text--light-gray-color !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}

.square {
  padding: 12px 16px 16px !important;
  outline: none !important;
  margin: 0 !important;
  width: 100% !important;
  vertical-align: middle !important;
  color: $text-color !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.09px !important;
  height: 52px !important;
  border-radius: 8px !important;
  border: 2px solid $border-input-middle-gray !important;
  transition: $transition !important;
  &:focus,
  &:hover {
    border-color: $text-gray-color !important;
    box-shadow: none !important;
  }
  &::placeholder {
    color: $text--light-gray-color !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}
