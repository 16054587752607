@import 'styles/mixins';

.button {
  @include button($secondary-color, #fff, $hover-blue);

  .loader {
    font-size: setUnit(4);
    color: #ffffff;
  }

  .loaderInverseColor {
    font-size: setUnit(4);
    color: #515151;
  }

  &.disabled {
    opacity: 0.7;

    &:hover {
      cursor: not-allowed;
    }
  }
}
