@import 'styles/functions';
@import 'styles/variables';

.feedback {
  background: $background-ligh-gray;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;

  h4 {
    color: $text-color;
    font-weight: 700;
    font-size: 16px;
  }

  p {
    color: $text-color;
    font-weight: 600;
    line-height: 21px;
    margin: 0;
  }

  .answer {
    font-weight: 400;
    margin-left: 1.25rem;
    margin-bottom: 0.5rem;
    color: $link-color;
  }
}
