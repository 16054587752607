@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.addAddressWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .rightBlock {
    color: $text-color;
    font-size: setUnit(16);
    line-height: setUnit(21);
    padding: 0;
    flex: 1 0 100%;
    @media (min-width: $mobile) {
      flex: 3;
    }
    .input {
      margin: 0;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    .buttonWrapper {
      display: flex;
    }
    .cancelButton {
      padding: 24px 12px !important;
      margin-right: 4px !important;
      border: 1px solid $border-light-gray-2 !important;
      min-width: 112px !important;
      border-radius: 8px;
      background: transparent !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .doneButton {
      padding: 24px 12px !important;
      border-radius: 8px;
      min-width: 112px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .removeWrapper {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      color: $primary-color;
      .trashIcon {
        margin: 0;
        padding: 0;
        margin-right: 4px;
      }
    }
  }
  .setAsPrimary {
    display: flex;
    margin-left: 16px;
    .checkmark {
      background-color: $background-grey-2 !important;
    }
    .checkbox {
      margin-right: 0.75rem;

      > label {
        margin-bottom: 0;
      }

      .checkmark {
        left: 0% !important;
      }
    }
  }

  .addressWrapper {
    display: flex;
    cursor: pointer;
    color: $text--light-gray-color;
    padding: 0 20px;
    margin-bottom: 0;
    &:hover {
      background-color: $background-grey-2;
    }
  }
  .iconStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .headingWrapper {
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 0px;
    margin-bottom: 0;
  }
  .addressSection {
    margin-left: 8px;
  }
  .locationWrapper {
    cursor: pointer;
    color: $text--light-gray-color;
    padding: 0 20px 16px;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .inputFieldLocations {
      width: 100%;
    }

    .searchIcon {
      left: 0;
      top: 42.5%;
    }
  }
}
