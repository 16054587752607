@import 'styles/functions';
@import 'styles/variables';

.assignedBy {
  .title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
