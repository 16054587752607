@import 'styles/variables';
@import 'styles/functions';

.formContainer {
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(75px);
  display: flex;
  width: 693px;
  padding: 56px 52px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile) {
    width: 90%;
    padding: 25px;
  }
}
