@import 'styles/variables';
@import 'styles/functions';

$header-height: 32px;

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: $transition;
  z-index: 9999;

  &.isOpen {
    pointer-events: auto;
    opacity: 1;

    .opacityLayer {
      opacity: 1;
    }
  }
}

.counter {
  min-width: setUnit(100);
}

.opacityLayer {
  background-color: rgba($black, 0.9);
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9998;
}

.content {
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  position: relative;
  z-index: 9999;
  transition: all 0.3s;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $black;
    height: setUnit(64);
    margin-bottom: $header-height;
    color: $white;
    position: fixed;
    width: 100%;
    z-index: 99;

    .actions {
      display: flex;
      align-items: center;
    }

    .fileName {
      margin-right: 28px;
    }

    .fileNameWithDownload {
      margin-right: 44px;
    }
  }
}

.innerHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: setUnit(120);

  .pdfWrapper {
    height: 100%;
    background: $white;
    border-radius: setUnit(8);
    overflow: hidden;
    padding: setUnit(8);
    margin-bottom: setUnit(56);
  }

  .imagePreview {
    max-width: 100%;
  }

  .blackWrapper {
    font-size: setUnit(16);
    line-height: setUnit(20);
    color: $white;
    margin-top: setUnit(8);
    display: flex;
    justify-content: center;

    div {
      padding: setUnit(12) setUnit(24);
      background: $black-light;
      border-radius: setUnit(8);
    }
  }

  .cohesiveFlowPDFWrapper {
    padding: unset;
    background-color: unset;
    margin-bottom: 2rem;
    border-radius: 0px;

    .cohesiveFlowLoadingWrapper {
      background-color: $white;
      padding: setUnit(2) setUnit(8) setUnit(8);
      border-radius: setUnit(8);
    }

    .documentPage {
      margin-bottom: 30px;

      canvas {
        border-radius: setUnit(8);
        margin-left: auto;
        margin-right: auto;
      }

      .blackWrapper {
        margin-top: -50px;
        position: relative;

        > div {
          font-size: setUnit(14);
        }
      }
    }
  }
}

.leftNav,
.rightNav {
  $width: 56;
  $height: 56;

  position: fixed;
  top: calc(50% + $header-height);
  transform: translateY(-50%);
  z-index: 4;
  cursor: pointer;
  transition: all 0.1s;
  width: setUnit($width);
  height: setUnit($height);
  background: #231f20;
  border: 1px solid #231f20;
  box-sizing: border-box;
  border-radius: 50%;

  &:hover {
    background: $black;
  }
}

.leftNav {
  left: setUnit(56);
}

.rightNav {
  right: setUnit(56);
}

.navIcon {
  height: setUnit(24);

  svg {
    height: setUnit(24);
    width: setUnit(24);

    path {
      stroke: $white;
    }
  }
}

.navIconLeft {
  height: setUnit(15);

  svg {
    height: setUnit(15);
    width: setUnit(15);
    padding-right: 3px;

    path {
      stroke: $white;
    }
  }
}
