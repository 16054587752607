@import 'styles/functions';
@import 'styles/variables';

.confirmationArea {
  color: $text-color;
  font-size: 16px;
  width: 480px;
  margin: 0 auto;

  h4 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .detailArea {
    overflow: auto;
    padding: 15px 15px;
    border-bottom: 1px solid $light-gray;
  }

  .footerArea {
    margin-top: 30px;
    overflow: auto;
    padding: 15px 15px;
  }
  .headWrapper {
    display: block;
    padding: 2rem 0.75rem 2rem 0.75rem;
    text-align: center;
    width: 100%;

    .headTitle {
      font-weight: bold;
      font-size: setUnit(30);
      color: $text-color;
      line-height: 2.25rem;
    }

    .headTitleDescription {
      font-size: setUnit(16);
      line-height: setUnit(21);
      color: $dark-grey-color;
      margin: 1rem auto 0;
    }
  }
}
