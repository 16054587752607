@import 'styles/functions';
@import 'styles/variables';

.question {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.8px;
  text-align: center;
  color: $secondary-color;
  margin-bottom: 40px;
  white-space: pre-line;
}
