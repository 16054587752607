@import 'styles/variables';
@import 'styles/functions';

$space-around: setUnit(20);
$drawer-width: 480;

.iconOnly {
  max-width: 44px;
  max-height: 44px;
  border-radius: 50% !important;
  margin: 3px 10px 0 10px !important;
  padding: 0;
  .icon {
    margin-top: 13px;
    margin-left: 3px;
  }
}

.filterWrapper {
  border-radius: setUnit(100);
  padding: 0.5rem 0.75rem;
  width: fit-content;
  cursor: pointer;
  background-color: $background-grey-2;
  span {
    white-space: nowrap;
  }
  .icon {
    display: flex;
    margin-right: setUnit(6);

    > div {
      line-height: 0;
      svg {
        line-height: 0;
      }
    }
  }
}

.filtersAppliedWrapper {
  border: 2px solid $primary-color !important;
  background-color: $primary-color-light !important;
  border-radius: setUnit(100) !important;
  &:hover {
    border-color: $primary-color !important;
  }
}

.aggregateWrapper {
  height: setUnit(44);
  background-color: $white;
  border: 2px solid $light-gray;
  border-radius: 100px;
  padding: 0.5rem 0.6rem;
  &[class~='ant-popover-open'] {
    border-color: $text-gray-color !important;
    background-color: $background-grey-2 !important;
  }
  &:hover {
    border-color: $text--light-gray-color;
  }
  .icon svg path {
    stroke: $dark-color;
  }
}

.editWrapper {
  border: 2px solid $primary-color;
  background-color: $primary-color-light;
  padding: 0.5rem 0.6rem;

  &:hover {
    border-color: $primary-color;
  }
}

.filterDrawer {
  .drawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-around;

    div {
      display: flex;
      align-items: center;
    }

    .heading {
      font-weight: 600;
      font-size: setUnit(20);
      margin-left: setUnit(5);
    }

    .icon {
      svg {
        height: 29px;
        width: 29px;
        path {
          stroke: $primary-color;
        }
      }
    }

    .closeIcon {
      cursor: pointer;
      &:hover {
        rect {
          fill: $light-gray;
        }
      }
    }
  }

  .drawerContent {
    padding: $space-around;
    max-height: calc(100% - 200px);
    overflow-y: scroll;

    .filterSection {
      border-radius: 8px;
      border: 1px solid $light-gray;
      margin-bottom: calc($space-around / 2);
      padding: 4px 0;
      .btnWrapper {
        border-radius: 100%;
        background: $background-grey-2;
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 7px;
      }
      .title {
        font-size: 1rem;
        font-weight: 600;
        .count {
          color: $text--light-gray-color;
        }
      }
    }
  }

  .drawerFooter {
    position: fixed;
    padding: $space-around;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: setUnit($drawer-width);
    width: 100%;
    bottom: 0;
    background-color: $background-grey-2;
    border-top: 2px solid $light-gray;

    div {
      display: flex;
    }

    .resetBtn {
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      .resetText {
        margin-left: setUnit(5);
        color: $dark-color;
        font-size: 1rem;
      }
    }

    .doneBtn {
      border-radius: 8px;
      height: 44px;
      width: 105px;
      &.disabled {
        opacity: 0.8;
      }
    }
  }
}
