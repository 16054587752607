@import 'styles/functions';
@import 'styles/variables';

.headerWrapper {
  background: white;
  width: 100%;
  .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 40px;
    .title {
      display: flex;
      justify-content: center;
      color: var(--REBRAND-Grayscale-100, #262626);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Page Title */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;

      width: 100%;
    }

    .backWrapper {
      position: absolute;
      top: 30px;
      border-radius: 100px;
      border: 2px solid var(--Grayscale-20, #e8e9ea);
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      cursor: pointer;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .filterBtnWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      // width: 44px;
      // height: 44px;
    }
  }
  .actions {
    padding: 12px 40px;
    width: 100%;
  }
}
