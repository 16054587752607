@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.requestQuote {
  .formEndWrapper {
    height: 100% !important;
  }

  .pendingWrapper {
    height: 100%;
    position: relative;
  }

  .pendingWrapperFlex {
    padding: 0 40px;
  }
}

.spinner {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.progressBar {
  position: absolute !important;
  top: 79px;
}

.preFormContainer {
  height: 100%;
}
