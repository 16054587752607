@import 'styles/variables';
@import 'styles/functions';
@import 'styles/variables';

.controls {
  margin: 32px;
  .buttons {
    display: flex;
    align-items: center;
    background: $background-ligh-gray;
  }
  .separator {
    width: 1px;
    height: setUnit(24);
    background: $border-middle-gray;
  }
  .prev,
  .next,
  .close {
    cursor: pointer;
    width: setUnit(40);
    height: setUnit(40);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    background: $background-ligh-gray;
    transition: $transition;
    &:hover {
      svg {
        transition: $transition;
        opacity: 0.6;
      }
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .next {
    svg {
      transform: rotate(180deg);
    }
  }

  &.lightFull {
    width: 100%;
    .buttons {
      background: none;
      justify-content: space-between;

      .prev,
      .next,
      .close {
        border-radius: 50%;
        border: 2px solid $light-gray;
        background: $white;
        height: 44px;
        width: 44px;
        &:hover {
          border: 2px solid $text--light-gray-color;
          svg {
            opacity: 1;
            path {
              stroke: $text-color;
            }
          }
        }

        svg {
          height: 23px;
          width: 23px;
        }
      }
      .prev {
        svg {
          transform: rotate(-90deg);
        }
      }
      .next {
        margin-left: 0.5rem;

        svg {
          transform: rotate(90deg);
        }
      }
      .close {
        margin-left: 1rem;
      }
    }
  }
}
