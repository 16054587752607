@import 'styles/functions';
@import 'styles/variables';

.filterDrawer {
  .drawerInner {
    position: relative;
    .tabs {
      justify-content: space-between;
      border-bottom: 2px solid $light-gray;
      div[class~='ant-tabs-nav'] {
        &:before {
          content: none;
        }
        div[class~='ant-tabs-nav-wrap'] {
          margin: 0 setUnit(32);
          border-bottom: 2px solid $light-gray;
        }
      }
    }
  }
}
