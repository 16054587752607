@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.fileNameWrap {
  display: flex;
  align-items: center;
  overflow: hidden;

  padding: 1.125rem 0.75rem;
  height: 56px;
  border-bottom: 1px solid $light-gray;

  .fileIconBundle {
    line-height: 0;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .bundleNameTooltip {
    height: auto;
    .documentName {
      white-space: nowrap;
      overflow: hidden;
      max-width: 90%;
      text-overflow: ellipsis;
    }
  }

  .documentName {
    margin-left: 0.75rem;
    margin-bottom: 0;
    color: $text-color;
    font-family: Inter;
    font-size: setUnit(14);
    font-style: normal;
    font-weight: 600;
    line-height: 1.3rem; /* 148.571% */
    letter-spacing: -0.01125rem;

    width: fit-content;
  }

  .bundleNameCount {
    display: flex;
    align-items: center;
    width: 75%;
  }

  .documentsCount {
    color: $text--light-gray-color;
    margin-left: 4px;
    font-weight: 600;
  }

  @media screen and (width > 768px) {
    display: none;
  }
}

.singleFileIcon {
  svg {
    width: 20px;
    height: 20px;
  }
}
