@import 'styles/functions';
@import 'styles/variables';

.pointMark {
  .holder {
    .inner {
      background: #ffffff;
      border-radius: 50%;

      .innerCheckbox {
        margin: 0;

        &.notClickable {
          cursor: auto;
          pointer-events: none;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .checkboxWrapper {
        min-width: setUnit(24);
        height: setUnit(24);
      }

      .checkbox {
        border-radius: 50% !important;
      }

      .checkmark {
        top: 18% !important;
        left: 18% !important;

        svg {
          width: 65% !important;
          height: 65% !important;
        }
      }
      &:hover {
        .checkbox {
          border-color: $text--light-gray-color;
        }
      }
    }
  }
}

.milestonePointTooltip {
  & [class*='ant-tooltip-content'] {
    width: 280px !important;
  }
}
