.signatureBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  [class*='signCanvas'] {
    box-shadow: 0px 2px 20px 5px lightgrey, 0px 2px 20px 5px lightgrey;
  }

  .skipButton {
    margin-top: 30px;
  }
}
