@import 'styles/functions';
@import 'styles/variables';

.propertyAddress {
  .answersContainer {
    & > div {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      width: 100%;
    }
  }
  .searchWrapper {
    margin-bottom: 16px;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .unit {
    max-width: 200px;
    width: 100%;
    & input {
      &::placeholder {
        color: $text-gray-color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.09px;
      }

      height: initial !important;
    }
  }
  .valuesWrapper {
    border-bottom: none !important;
  }
  .autocompleteWrapper {
    z-index: 999;
    height: 100%;
    left: 0;
  }
  .popup input {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.09px;
  }
}
