@import 'styles/variables';
@import 'styles/mixins';

.dynamicDropdownContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 0.75rem;

  border: 2px solid $light-gray;
  border-radius: 100px;
  width: fit-content;
  padding: 8px 12px 8px 16px;
  background-color: $white;
  line-height: 0;

  .itemContent {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;

    .arrowIcon {
      line-height: 0;
      svg {
        transition: all 0.3s;
      }
    }
  }

  .title {
    font-size: setUnit(14);
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: left;
  }

  &:hover {
    border-color: $text--light-gray-color;
  }
}

.disabledDropdown {
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    border-color: $light-gray;
  }
}

.openedDropdown {
  border-color: $text--light-gray-color;

  svg {
    transform: rotate(-180deg);
  }
}

.dropdownOverlay {
  .optionsItems {
    width: 231px;

    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      overflow-y: auto;

      li {
        padding: 0;

        label[class~='ant-radio-wrapper'] {
          display: flex;
          margin-right: 0;
          align-items: center;
          transition: $transition;
          padding: setUnit(14) setUnit(16);
          background-color: $white;

          &:hover {
            background-color: $background-grey-2;
          }

          span[class~='ant-radio'] {
            display: none;
            & + span {
              flex: 0 0 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              gap: 20px;

              .option {
                display: flex;
                align-items: center;
                gap: 12px;
                overflow: hidden;

                .optionIcon {
                  line-height: 0;
                }

                .optionValue {
                  font-weight: 300;
                  font-size: setUnit(16);
                  line-height: setUnit(24);
                  letter-spacing: -0.09px;
                  color: $text-color;
                }
              }

              .checkIcon {
                transition: $transition;
                height: setUnit(20);
                color: $green-color;

                svg {
                  width: setUnit(20);
                  height: setUnit(20);
                  path {
                    stroke: $green-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
