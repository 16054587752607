@import 'styles/variables';

.questionContainer {
  padding: 0px !important;
  min-width: 900px !important;
}
.footerStyles {
  max-height: 74px;
  overflow: hidden;
}
.hidden {
  display: none !important;
}
