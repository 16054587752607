@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.pendingTaskActions {
  .popmenu {
    display: flex;
    justify-content: center;
    align-content: center;

    border: 2px solid $light-gray;
    background-color: $white;
    border-radius: 8px;

    color: $dark-color;
    position: relative;
    width: 100%;

    height: setUnit(52);
    cursor: pointer;
    margin-bottom: 20px;

    .deleteLabel {
      color: $error-color;
    }

    .popmenuButton {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark-color;
      span {
        font-size: setUnit(16);
        line-height: setUnit(24);
        font-weight: 600;
        letter-spacing: -0.09px;
        margin-right: setUnit(8);
      }
      .menuIcon {
        transform: rotate(0deg);
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition;

        &.menuOpen {
          transform: rotate(180deg);
        }
      }
    }

    .actionPopover {
      border-radius: setUnit(8);
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      [class~='ant-popover-inner'] {
        border-radius: 10px;

        [class~='ant-popover-inner-content'] {
          padding: setUnit(8) 0 !important;
          border-radius: inherit;

          [class~='ant-menu'] {
            border-radius: inherit;
            border-right: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            [class~='ant-menu-item'] {
              margin: 0;
              height: unset;
              padding: setUnit(6) setUnit(20);
              width: 100%;
              background-color: #fff;
              color: $dark-color;
              font-size: setUnit(16);

              @include transition;
              &:hover {
                background-color: $background-grey-2;
                color: $text-color;
              }
              .menuIcon {
                display: flex;
                align-items: center;
                span {
                  font-weight: 600;
                  font-size: setUnit(16);
                  line-height: setUnit(16);
                  margin-left: setUnit(12);
                  color: $text-color;
                }
              }
              &[class~='ant-menu-item-disabled'] {
                .menuIcon {
                  span {
                    color: rgba(0, 0, 0, 0.25);
                  }
                }
              }
            }
          }
        }
      }
    }

    .actionBtn {
      svg {
        color: $dark-color;
      }
    }

    &:hover {
      background-color: $background-grey-2;
    }
  }

  .openPopMenu {
    background-color: $background-grey-2;
    border-color: $text-gray-color;
  }

  .disablePopMenu {
    opacity: 0.8;
    pointer-events: none;
    cursor: not-allowed;
  }
  .loadingSpinner {
    font-size: 0.3em;
    margin: 0px auto;
  }
}
