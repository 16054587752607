@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.loginFormWrapper {
  width: 100%;
  height: calc(100vh - #{setUnit(169)});
  padding: 0 setUnit(24);
  @media (min-width: $tablet) {
    padding: 0;
    margin-left: 0;
    width: calc(100% - #{setUnit(680)});
    height: 100vh;
  }
}
