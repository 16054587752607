@import 'styles/functions';
@import 'styles/variables';

.collapsibleRow {
  .rowHeader {
    width: 1440px;
    height: 64px;
    align-items: center;
  }
  .title {
    display: flex;
    width: 1440px;
    height: 64px;
    align-items: center;
    display: flex;
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.33px;
  }

  .leftMargin {
    margin-left: 6px;
    margin-top: -4px;
  }
}
