@import 'styles/variables';

.signBox {
  position: absolute;

  text-align: center;
  height: 52px;
  width: 168px;
  padding: 14px 24px 14px 24px;
  border-radius: 8px;
  gap: 8px;

  background-color: $confetti-blue;
  color: $white;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  z-index: 999;

  cursor: pointer;

  @media screen and (max-width: $mobile-small) {
    height: 40px;
    width: 120px;
    font-size: 12px;
    padding: 8px 10px;
  }
}

.animate {
  -webkit-transition: top 0.5s ease-out;
  transition: top 0.5s ease-out;
}

.startWizard {
  right: -116px;

  @media screen and (max-width: $mobile-small) {
    right: 0;
  }
}
