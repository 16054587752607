@import 'styles/functions';
@import 'styles/variables';

.agentDetails {
  .agentName {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: setUnit(2);
    text-align: left;
  }

  .agentCompany {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: setUnit(2);
    text-align: left;
  }

  .agentPhone {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    color: $text-color;

    input {
      text-align: left;
    }

    @media screen and (min-width: $tablet) {
      margin-bottom: setUnit(2);
    }
  }

  .agentEmail {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(21);
    letter-spacing: -0.18px;
    word-break: break-all;
    margin-bottom: 0;
    text-align: left;

    a {
      color: $link-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
