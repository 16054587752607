@import 'styles/functions';
@import 'styles/variables';

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: setUnit(252);
  width: 100%;

  &.minimizedLogo {
    width: fit-content !important;
  }

  .headerLogoContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .brokerageLogo {
      max-width: 150px;
      max-height: 50px;
    }
    .logoText {
      font-size: setUnit(16);
      font-style: normal;
      font-weight: 600;
      color: $text-color;
      text-decoration: none;
      margin-left: 20px;
    }
    .headerLogo {
      display: flex;
    }

    .secondaryLogo {
      display: none;
    }

    .primaryLogo {
      display: flex;
    }
    @media screen and (max-width: $responsive-menu) {
      .secondaryLogo {
        display: flex;
      }
      .primaryLogo {
        display: none;
      }
    }
  }
}
