@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.offerDetailsModal {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }

  .heading {
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-color;
    h1 {
      margin: 0px;
    }
  }

  .subHeading {
    display: flex;
    justify-content: center;
    gap: 4px;
    color: $text-color;

    .subTitle {
      font-weight: 600;
      font-size: 15px;
    }

    .agentName {
      margin-right: 2px;
    }
  }
  .headingAlignment {
    display: flex;
    flex-direction: column;
  }
  .content {
    max-height: calc(100vh - 16.5rem);
    overflow-y: auto;
    padding-right: 2rem;

    .accordion {
      padding: 1rem 0 1rem 0;
    }

    .overflowVisible {
      overflow: visible !important;
    }
  }

  .action {
    display: grid;
    justify-content: center;
    margin-top: 20px;
    .submitButton {
      width: 152px;
      height: 52px;
      margin-left: auto;
      display: block;
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: 576px) {
  .offerDetailsModal {
    [class='ant-modal-content'] {
      padding: 2rem 0rem 2rem 1rem;
    }

    .heading {
      margin-right: 2rem;
    }

    .content {
      padding-right: 1rem;
    }
  }
}

#toastContainerId {
  @include customSuccessToast;

  .customToast {
    .toastCloseButton {
      margin-top: 0px !important;
    }
  }
}
