@import 'styles/functions';
@import 'styles/variables';

.back {
  width: setUnit(140);
  height: setUnit(44);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-ligh-gray;
  border-radius: setUnit(100);
  cursor: pointer;

  &:hover {
    background-color: $light-gray;
  }

  .arrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    margin-right: setUnit(5);
    margin-left: setUnit(-10);

    svg {
      vertical-align: middle;
    }
  }

  .text {
    font-size: setUnit(14);
    font-weight: 400;
  }
}
