@import 'styles/functions';
@import 'styles/variables';

.documents {
  padding-right: 32px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  .title {
    margin: 1.25rem 0 1rem;
    color: $text-color;
    font-weight: bold;
  }

  .disclaimerContainer {
    height: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .disclaimer {
      font-size: setUnit(16);
      font-weight: 100;
      color: $text--light-gray-color;
    }
  }
}
