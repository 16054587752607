@import 'styles/functions';
@import 'styles/variables';

.details {
  padding-bottom: 1.5rem;
  padding-right: 32px;

  .detailsHd {
    color: #262626;
    margin-bottom: 24px;
  }

  .offerInfo {
    display: flex;
    justify-content: space-between;

    .dateTime {
      display: inline-block;
    }

    .dateTime,
    .requestor {
      h3 {
        font-weight: 600;
        font-size: 14px;
      }
      p,
      span {
        color: $text-color;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0.25rem;
      }
    }

    .requestor {
      width: 55%;
      display: flex;
      justify-content: flex-end;

      span {
        word-break: break-all;
      }
    }

    .dateTime {
      .declinedAppointment {
        color: $text--light-gray-color;
        text-decoration-line: line-through;
      }
    }

    .requestor {
      p,
      span {
        font-weight: 400;
      }

      span {
        color: $link-blue;
      }

      .phone {
        margin-top: -0.2rem;

        input {
          font-size: 16px;
        }
      }
    }
  }

  .pending {
    .approvers {
      background: $primary-color-light;
      padding: 0.75rem 1.5rem;
      text-align: center;
      margin-top: 1.5rem;

      p,
      span {
        color: $primary-color;
        line-height: 21px;
        margin: 0;
      }

      span {
        font-weight: 600;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;

      .icon {
        display: inline-block;
        position: relative;
        top: 0.3rem;
        margin-right: 0.5rem;
      }

      [class*='ant-btn'] {
        width: 17.5rem;
        height: 3.25rem;
      }

      .approve {
        border: 2px solid $hover-green;
        margin-right: 0.5rem;

        span {
          font-weight: 600;
          color: $hover-green;
          position: relative;
          top: -0.15rem;
        }
      }

      .decline {
        border: 2px solid $hover-red;

        span {
          font-weight: 600;
          color: $hover-red;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .details .appointmentInfo {
    display: block;
    margin-top: 0.5rem;

    .dateTime {
      width: 100%;
    }

    .requestor {
      display: block;
      margin-top: 1rem;
    }
  }
}
.expiryText {
  color: var(--Tertiary-Error-Red, #ec455e);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px; /* 130% */
  letter-spacing: -0.18px;
}
