@import 'styles/variables';
@import 'styles/functions';

.previewTitle {
  font-family: $font-family;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: $white;
  text-align: center;
  background-color: $secondary-color-hover;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  span,
  .downloadButton {
    margin-left: auto;
  }

  .downloadButton {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.previewTitleLeftAlign {
  margin-right: auto;
}

.previewFormCompletedContainer {
  position: relative;
  height: calc(100vh - $header-height - $responsive-footer-height);
  overflow: auto;
}

.previewFormContainer {
  position: relative;
  height: calc(100vh - $header-height - $responsive-footer-height);
  overflow: auto;
}

.editFormContainerPDFMode {
  background-color: $background-grey-2;
}
.editForm {
  display: flex;
  width: 95px;
  align-items: center;
  height: 44px;
  justify-content: center;
  z-index: 5;
  border-radius: 100px;
  background-color: $white;
  border: 2px solid $light-gray;
  @media (min-width: $mobile) {
    position: fixed;
    left: 40px;
    top: 160px;
  }
  svg {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: $light-gray;
  }
}
.editRoles {
  .selectedCheckbox {
    [class*='ant-checkbox-checked '],
    [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: $white;
  padding: 8px 0px;
  width: 180px;
  z-index: 5;
  position: fixed;
  bottom: 124px;
  left: 20px;
  .userRow {
    padding: 0px 20px;
    display: flex;
    width: 100%;
    margin: 3px 0px;
    &:hover {
      background-color: $background-grey-2;
      cursor: pointer;
    }
  }
  .userName {
    display: block;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $dark-color;
  }
  .checkbox {
    margin-right: 12px;
  }
  .userTitle {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}
.editFormFooter {
  height: $responsive-footer-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-top: 1px solid $light-gray;
  padding: 0 setUnit(40);
  .editFormFooterColumn {
    margin: 0 auto;
    max-width: 640px;
    width: 100%;
    justify-content: space-between;
  }
  .svgContinue {
    margin-left: setUnit(12);
  }
  .svgDefer {
    margin-left: setUnit(12);

    path {
      stroke: white;
    }
  }

  .editFormFooterButton {
    max-width: 142px;
    width: 100%;
    height: 52px;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: setUnit(8);
  }
  .editFormFooterMessage {
    border: 2px solid $light-gray !important;
  }
}
.listContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto;
  width: 640px;

  [class*='container'] {
    height: auto !important;
    max-height: 488px !important;
    margin: 0px auto !important;
  }
}
.editRow {
  background-color: transparent;
  padding: 15px 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 16px 0;
  position: relative;
  .navigationIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 5;
    .navigationButton {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      border: 2px solid $light-gray;
      z-index: 5;
      @media (min-width: $mobile) {
        position: fixed;
        right: 40px;
        top: 160px;
      }
      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }
}

.navigationFooter {
  display: flex;
  justify-content: right;
}

.formNavigation {
  position: absolute;
  bottom: 50px;
  right: 50px;

  display: flex;
  border: 1px solid $light-gray;
  border-radius: 8px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: 40px;
  width: 40px;
}

.navigationBtn {
  height: 40px;
  width: 40px;
  border-radius: 8px;

  :hover {
    background-color: $light-gray;
  }
}

.continueBtn {
  background-color: $white !important;
  border: none !important;
  display: flex !important;
  margin-top: 40px;
  width: 138px !important;
  height: 52px !important;
  justify-content: center;

  span {
    align-self: center;
  }

  div {
    align-self: center;
    margin-left: 13px;
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 50%;
}

.previewFormContainerWrap {
  height: calc(100vh - $header-height - $responsive-footer-height);
  position: relative;
  overflow: auto;
}

.editPreviewRow {
  background-color: $secondary-color-hover;
  padding: 10px 40px;
  align-items: center;
  justify-content: space-between;
  .previewHeaderWrap {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    .previewHeaderTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.4px;
      color: $white;
    }
    .previewHeaderFile {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: $white;
    }
  }
  .editPreviewForm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      path {
        stroke: $white;
      }
    }
    .text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.09px;
      color: $white;
      user-select: none;
    }
  }
  .closeNavigation {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: $white;
      }
    }
  }
}

.emptyFooterFormContainer {
  height: calc(100vh - $header-height);
}

.closeIcon {
  cursor: pointer;
  line-height: 0px;
  svg {
    width: 34px;
    height: 34px;
    vertical-align: middle;

    &:hover {
      transform: scale(1.1);
    }
  }
}
