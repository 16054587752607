@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.searchInput {
  margin-top: 12px;
  margin-bottom: 34px;
}

.assignToContainer {
  width: 100%;

  .assignToList {
    display: block;
    width: 100%;

    overflow-y: auto;
    max-height: 40vh;

    @media (min-width: $tablet) {
      overflow-x: hidden;
    }

    .assignToRow {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .userData {
        display: flex;
        align-items: center;

        .avatar {
          width: 32px !important;
          height: 32px !important;
          margin-right: 12px;
          font-family: $font-family;
          font-size: 14px !important;
          font-weight: 600 !important;
          line-height: 18px !important;
        }
      }

      [class*='ant-checkbox-wrapper'] {
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }
    }
  }

  .assignToFooter {
    padding-top: 60px;
    padding-bottom: 20px;
    text-align: center;
    width: 100%;

    .submitButton {
      width: 168px;
      height: 52px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      border-radius: 8px !important;
    }
  }
}
