@import 'styles/functions';
@import 'styles/variables';

.client {
  padding: 0.5rem 0.25rem 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .avatar {
    width: setUnit(40);
    height: setUnit(40);
    background: $secondary-color;
    color: $white;
  }

  .label {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 16px;
    color: $text-color;
    line-height: 20px;

    .info {
      display: block;

      p {
        display: inline-block;
        margin: 0;
        color: $dark-color;
        font-weight: 400;
        font-size: 14px;

        span {
          font-weight: 600;
        }
      }
    }

    .divider {
      display: inline-block;
      margin: 0 0.5rem;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: $text-gray-color;
      position: relative;
      top: -0.2rem;
    }
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .name {
    display: flex;
    align-items: center;
  }
}

.multiSelect {
  padding-right: 0.65rem;
}
