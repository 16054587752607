@import 'styles/variables';
@import 'styles/functions';

.responseInput {
  padding: setUnit(12) 0 setUnit(16) !important;
  outline: none;
  margin: 0 !important;
  width: 100% !important;
  vertical-align: middle;
  color: $text-color;
  font-size: setUnit(18) !important;
  font-weight: 400;
  line-height: setUnit(24) !important;
  letter-spacing: -0.09px;
  height: setUnit(52) !important;
  border: 0 !important;
  border-bottom: 2px solid $border-input-middle-gray !important;
  transition: $transition !important;
  overflow: hidden;
  &:focus {
    border-bottom-color: $text-gray-color !important;
  }
  &::placeholder {
    color: $text--light-gray-color !important;
    font-weight: 400;
    font-size: setUnit(16) !important;
  }
}
