@import 'styles/functions';
@import 'styles/variables';

.recommendedTo {
  padding: 0.3rem;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &.hoverEffect {
    &:hover {
      border-radius: 99px;
      background-color: #fb913a1f;
    }
  }

  .tooltipButton {
    background: transparent;
    border: none;
  }
  .recommended {
    svg {
      fill: $orange;
      path {
        stroke: $orange;
      }
    }
  }
}

.recommendedToTooltip {
  [class*='ant-tooltip-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(8);
    color: $text-color;
  }
}
