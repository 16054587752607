@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$hover-color: lighten($secondary-color, 10);

.actionsWrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  gap: 0.625rem;
  justify-content: center;
  @media (min-width: $tablet) {
    justify-content: flex-end;
  }

  .btn {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    color: $dark-color;

    &.newBtn {
      background-color: $secondary-color;
      color: $white;
      border: none;

      &:hover {
        border: none;
        background-color: 2px solid $secondary-color-hover;
      }
    }

    .addIcon {
      svg {
        path {
          stroke: $white;
        }
      }
    }

    .iconText {
      margin-left: setUnit(5);
    }

    &.filterBtn {
      width: fit-content;
      padding: 0 1rem;
    }

    width: setUnit(36);
    height: setUnit(36);
    @media (min-width: $tablet) {
      height: setUnit(44);
      width: setUnit(44);
    }
    svg {
      width: setUnit(20);
      height: setUnit(20);
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      border: 2px solid $text--light-gray-color;
    }
    &.btnText {
      width: auto;
      padding: 0 setUnit(16);
    }
  }

  .selected {
    background-color: $background-blue;
  }
}
