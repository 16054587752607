@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$hover-color: lighten($secondary-color, 10);

.archive {
  margin-left: setUnit(32);
  font-size: setUnit(14);
  font-weight: 500;
  height: setUnit(44);
  border: 2px solid $border-middle-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 setUnit(16);
  border-radius: setUnit(100);
  cursor: pointer;

  &:hover {
    background-color: $background-ligh-gray;
  }
}

.icon {
  margin-right: setUnit(8);
  position: relative;
  left: setUnit(-2);

  svg {
    vertical-align: middle;
    width: setUnit(20);
    height: setUnit(20);
  }
}
