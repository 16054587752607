@import 'styles/functions';
@import 'styles/variables';

.saveSearchCardWrap {
  display: flex;
}
.container {
  background: $background-grey-2;
  border-radius: 8px;
  overflow: hidden;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .savedIcon {
    padding-top: 6px;
  }
  .images {
    background-color: $hover-light-gray;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    .noImages {
      font-size: setUnit(16);
      line-height: setUnit(21);
      color: $dark-grey-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
      border: 1px solid transparent;

      &:nth-of-type(odd) {
        border-right: 1px solid transparent; // Add border in between the images
      }
      &:nth-of-type(even) {
        border-left: none; // Remove left border for even images if required
      }
      &:nth-last-of-type(-n + 2) {
        border-bottom: none; // Remove bottom border for the last row if there are only two images
      }
    }
  }

  .blurred {
    opacity: 0.7;
  }

  .infoBlock {
    padding: setUnit(16) setUnit(24) setUnit(12);
    background-color: $background-blue;
    color: $light-grey-color;
    min-height: setUnit(152);
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    overflow: hidden;

    .infoBlockWrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex: 0 0 100%;
      overflow: hidden;
      .infoBlockLeft {
        margin-right: 8px;
        &.infoBlockLeftInactive {
          flex: 0 0 calc(100% - 112px);
        }
        &.infoBlockLeftActive {
          flex: 0 0 calc(100% - 74px);
        }
      }
    }

    .descStatus {
      float: right;
      color: $white;
      font-size: 14px;
      background-color: rgba(70, 139, 255, 0.2);
      padding: 0.25rem 0.75rem;
      border-radius: 16px;
    }

    .descAgentName {
      font-size: 14px;
      padding-bottom: 0.25rem;
      color: $white;
    }

    .descFooter {
      flex: 0 0 100%;
      margin-top: setUnit(18);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .deactivated {
    background-color: $white;

    .descStatus {
      color: $dark-color;
      background-color: $background-grey-2;
      flex: 0 0 setUnit(104);
    }

    .descAgentName,
    .footerInfo {
      color: $dark-grey-color;
    }
  }
}
.descTitle {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 0.2rem;
  color: $black;
  display: inline-block;
  > span {
    font-size: 12px;
    font-weight: normal;
  }
}
.descInfoWrapper {
  display: flex;
  gap: 8px;
  .descInfo {
    color: $dark-grey-color;
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(16);
  }
  .descInfoWrapped {
    max-width: 25vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .emptyDescInfo {
    height: 14px;
  }
}
.clickable {
  cursor: pointer;
  transition: all 0.1s;
  position: relative;
  right: 5px;
  min-width: 48px;
}

.marginTop {
  margin-top: 10px;
}

.compareOfferTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      padding: 16px 16px 10px 16px;
      max-width: 200px;

      p {
        color: white !important;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;

        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}
