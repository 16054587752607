@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.head {
  @include quoteHeader;

  .recommendedBy {
    display: flex;
    align-items: center;
    font-size: setUnit(14);

    .icon {
      margin-right: setUnit(10);
      svg {
        width: setUnit(20);
        height: setUnit(20);
        vertical-align: middle;
      }
    }

    .name {
      font-weight: 500;
      margin-left: setUnit(4);
    }
  }
}
