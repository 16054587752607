@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.newTask {
  position: relative;
  .btn {
    border-radius: setUnit(100);
    padding: setUnit(10) setUnit(16);
    height: setUnit(44);
    .icon {
      margin-right: setUnit(4);
    }
  }

  .btnTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(48);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;

    ul {
      list-style: none;
      margin-bottom: 0.5rem;
    }

    .item {
      padding: setUnit(10) setUnit(22) setUnit(5) setUnit(22);
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        margin-right: setUnit(8);
        position: relative;
        top: setUnit(-2);
      }

      &:hover {
        background: $background-ligh-gray;
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}
