@import 'styles/functions';
@import 'styles/variables';

.button {
  border: none !important;
  border-radius: 100px;
  background: $white;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  &[class~='ant-dropdown-open'] {
    background-color: $white !important;
  }
  &:hover {
    border: none !important;
  }
}

.main {
  height: calc(100dvh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .containerActiveSearch {
    width: 100%;
    max-width: 1340px;
    .expandedTransaction {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .expandedTransactionHeading {
        display: flex;
        h3 {
          margin: 0;
          color: $text-color;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20.8px; /* 104% */
          letter-spacing: -0.18px;
        }
      }
    }
    .feedSelectDropdown {
      width: 150px !important;
    }
    .noData {
      display: flex;
      width: 100%;
      height: 275px;
      min-height: 275px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 16px;
      color: $text--light-gray-color;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.33px;
    }
    .CardsContainer {
      padding: 32px;
      border-radius: 16px;
      background: $white;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
    }
  }
}
