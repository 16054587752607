@import 'styles/variables';

.commentPopoverSpace {
  gap: 0px !important;
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;

  .setActions {
    display: flex;
    align-items: center;

    .actionDivider {
      height: 24px;
    }

    .permissionSelector {
      min-width: 96px;
      max-width: 200px;
      min-height: 40px;

      [class*='ant-select-selector'] {
        border: none !important;
        box-shadow: unset !important;
        border-radius: 8px;
        min-height: 40px;
        padding-right: 0px;

        &:hover {
          background: $light-gray;

          [class*='ant-select-selection-item'] {
            color: $dark-color;
            cursor: pointer;
          }
        }

        [class*='ant-select-selection-item'] {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          padding: 0px 0px 0px 3px !important;
          color: $dark-grey-color;
        }
      }

      [class~='ant-select-arrow'] {
        transition: all 0.3s;
      }
    }

    .permissionSelector[class~='ant-select-open'] {
      [class*='ant-select-selector'] {
        background: $light-gray;

        [class*='ant-select-selection-item'] {
          color: $dark-color;
          cursor: pointer;
        }
      }

      [class~='ant-select-arrow'] {
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }

    .disabledSelector {
      [class~='ant-select-selector'] {
        &:hover {
          [class*='ant-select-selection-item'] {
            cursor: no-drop;
            color: $dark-grey-color;
          }
        }
        background-color: transparent !important;
      }
    }

    .tagSelector {
      width: 40px;
      height: 40px;

      [class*='ant-select-selector'] {
        border: none !important;
        box-shadow: unset !important;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        position: absolute;
        left: -3px;

        &:hover {
          background: $light-gray;

          & ~ [class~='ant-select-arrow'] {
            path {
              stroke: $dark-color;
            }
          }
        }
      }

      [class*='ant-select-selection-overflow'] {
        width: 1px;
        z-index: -99;
      }
    }

    .tagSelector[class~='ant-select-focused'] {
      [class*='ant-select-selector'] {
        background: $light-gray;

        & ~ [class~='ant-select-arrow'] {
          path {
            stroke: $dark-color;
          }
        }
      }
    }
  }

  .sendIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    cursor: not-allowed;

    &:hover {
      background: $light-gray;
    }
  }

  .sendIconActive {
    @extend .sendIcon;
    background: $background-blue;
    cursor: pointer;

    &:hover {
      background: $background-blue;
    }
  }

  .sendingLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
}

.commentPopoverActionBar {
  margin-top: 16px;
}

.hideActionBar {
  display: none;
}

.permissionOptions {
  border: none;
  border-radius: 8px !important;
  width: 264px !important;
  z-index: 9999 !important;

  [class*='ant-select-item-option-state'] {
    display: flex;
    align-items: center;
  }
}

.notifyOptions {
  border: none;
  border-radius: 8px !important;
  width: 264px !important;
  z-index: 9999 !important;

  [class*='ant-select-item-option-state'],
  [class*='ant-select-item-option-content'] {
    display: flex;
    align-items: center;
  }

  [class~='ant-select-item-option-active']:not([class~='ant-select-item-option-disabled']) {
    background-color: $background-grey-2 !important;
  }

  .tagName {
    margin-left: 8px;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
  }

  .rolePill {
    margin-left: 4px;
    padding: 0px 6px;
    background: $light-gray;
    border-radius: 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $dark-color;
  }
}
