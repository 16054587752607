@import 'styles/functions';
@import 'styles/variables';

.loginText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: $dark-color;
  @media (min-width: 992px) {
    text-align: left;
  }
}

.loginLink {
  margin-left: setUnit(12);
  color: $link-blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}
