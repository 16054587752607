@import 'styles/variables';

.formCommentsPanelContent {
  background-color: $background-gray !important;
}

.commentPanelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  .commentPanelHeading {
    font-size: 20px;
    font-weight: 600;
  }

  .commentPanelActions {
    display: flex;
    align-items: center;

    .formCommentDrawerCancel {
      background-color: $white !important;
    }
  }
}
