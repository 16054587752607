@import 'styles/functions';
@import 'styles/variables';

.bathsWrapper {
  display: flex;
  align-items: center;

  .selectWrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  .option {
    font-size: setUnit(14) !important;
  }
}
.dropdownLabel {
  margin-top: setUnit(20) !important;
}
