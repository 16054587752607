@import 'styles/functions';
@import 'styles/variables';

.parkingSpaces {
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 20px 20px 12px;
  margin: 0.5rem 0 1rem;

  h4 {
    width: 100%;
  }
  .icon {
    width: 4rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background: $background-grey-2;
    }
  }
  .parkingActions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 140px;
    height: 40px;
    .inputMaxBorderBottom {
      border: 0 !important;
      border-bottom: 2px solid $border-light-gray-2 !important;
      border-radius: setUnit(0) !important;
      background: transparent;
    }
    .inputHolderClassName {
      text-align: center;
    }
    .inputFieldMinutes {
      width: 120px;
      margin: -0.25rem 0.5rem 0;
      input {
        height: 40px !important;
        width: 56px !important;
      }
    }
  }
  input {
    text-align: center;
  }
  svg {
    cursor: pointer;
  }
  .switchActions {
    margin-top: -2rem;
    .garageSwitch {
      display: flex;
      justify-content: flex-end;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $text-color;
        margin-right: 0.75rem;
        margin-bottom: 1rem;
      }
    }
    button {
      margin: 0 !important;
    }
  }
}
