@import 'styles/functions';
@import 'styles/variables';

.iconImage {
  height: 48px;
  width: 48px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: top;
  overflow: hidden;

  .icon {
    height: 48px;
    width: 48px;
    border-radius: 100%;
  }
}

.detailsArea {
  display: inline-block;
  line-height: setUnit(21);
  letter-spacing: -0.18px;
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.18px;
    margin-bottom: setUnit(4);
  }
  .subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.18px;
    margin-bottom: setUnit(4);
  }
  .link {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $link-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .iconImage {
    display: inline-flex;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .iconImage {
    display: inline-flex;
  }

  .detailsArea {
    display: block;
  }
}
