@import 'styles/mixins';
@import 'styles/variables';

.fileNameWrap {
  display: flex;
  align-items: flex-end;
}

.documentNameTooltip {
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.documentName {
  margin-bottom: 0;
  color: $text-color;

  font-size: setUnit(14);

  font-weight: 600;
  line-height: 1.3rem; /* 148.571% */
  letter-spacing: -0.01125rem;
}
