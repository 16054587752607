@import 'styles/variables';
@import 'styles/functions';

.strokePopoverWrapper {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popoverContent {
  [class*='ant-popover-inner'] {
    border-radius: 8px;

    [class*='ant-popover-inner-content'] {
      padding: 0px !important;
    }
  }

  .popoverOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px !important;
    padding: 0px 16px;

    .optionLabel {
      margin-left: 8px;
    }

    &:hover {
      background-color: $hover-light-gray;
    }
  }
}

.strokeToolTipContainer {
  [class~='ant-tooltip-inner'] {
    padding: 0px;

    input {
      color: $text-color;
    }
  }
}

.colorBoxContainer {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: rgba($dark-color, 0.5);
  }
}

.colorBox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid $white;
}

.sketchPad {
  [class~='flexbox-fix'] {
    &:nth-last-child(2) {
      display: none !important;
    }
  }
}
