@import 'styles/functions';
@import 'styles/variables';

.item {
  display: flex;
  align-content: center;
  border-bottom: 1px solid $grey-color;
  padding-top: setUnit(12);
  padding-bottom: setUnit(12);

  .phone {
    margin-left: setUnit(8);
  }

  &:last-child {
    border-bottom: none;
  }

  .icon {
    display: flex;
    align-content: center;
    height: fit-content;
  }

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-left: setUnit(15);

    .title {
      font-weight: 600;
      line-height: setUnit(21);
    }

    .subtitle {
      margin-top: setUnit(4);
      font-size: setUnit(14);
      color: $text-gray-color;
      line-height: setUnit(16);
      display: flex;
      align-items: center;
    }
  }

  .toggle {
    min-width: setUnit(71);
    display: flex;
    align-items: center;

    .toggleWrapper {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
